import React, { Component } from "react";
import { View } from "react-native";
import { t } from "../../../services/i18n";

import { SecondaryColorText } from "../../UI/styledComponents";

class UnknownTypeComponent extends Component {
	render() {
		const { device, header } = this.props;

		return (
			<View>
				{header}
				<SecondaryColorText style={{ paddingLeft: 10 }}>
					{t("UNHANDLED_TYPE")} {device.get("typ_komponentu")}
				</SecondaryColorText>
			</View>
		);
	}
}
export default UnknownTypeComponent;
