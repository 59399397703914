import React, { Component } from "react";
import {
  TouchableOpacity,
  View,
  Text,
  Platform,
  I18nManager,
} from "react-native";
import { t } from "../../../services/i18n";
import { connect } from "react-redux";
import CloudIntegration from "../../Initial/CloudIntegration";
import { ScreenView } from "../../../components/UI/screenViewX";
import {
  SwitchStyled,
  PrimaryColorText,
  ComponentContainerTouchable,
  ButtonPrimary,
} from "../../../components/UI/styledComponents";
import mqttClient from "../../../services/mqtt";
import PickerSelect from "../../../components/UI/PickerSelect";

import { ListItemContainer } from "../styled";

import {
  changeAutoLogin,
  changeAutoLoginType,
  changeCloudConnectionAllowance,
  changeProfileAutomaticLogin,
} from "../../../store/actions";

import { Feather } from "@expo/vector-icons";

class Security_AccountScreen extends Component {
  state = {
    allowCloud: this.props.loginData.get("cloudIp") ? true : false,
    autoLoginType: this.props.automaticLoginType
      ? this.props.automaticLoginType
      : this.props.automaticLogin,
    automaticLogin: this.props.automaticLogin,
  };

  onConnect = (source, loginDataForNewCloud) => {
    //console.log("On connect from secuirty");
  };
  addAssociation = () => {
    //console.log("Adding association");
    //<CloudIntegration onConnect={this.onConnect} />;
  };

  changeCloudConnectionAllowanceHandler = (value) => {
    this.setState({ allowCloud: value });
    // this.props.changeCloudConnectionAllowance(value);
    let message = value ? "1" : "0";
    mqttClient.allowCloudConnection(message);
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.loginData.get("cloudIp")) {
      this.setState({ allowCloud: true });
    } else {
      this.setState({ allowCloud: false });
    }
  }
  profileAutomaticLoginChangeHandler = (value) => {
    this.props.onChangeProfileAutomaticLogin(value);
  };

  onAutologinChange = (value) => {
    let autoLog = true;
    if (value === false) {
      this.props.onChangeProfileAutomaticLogin(false);
      autoLog = false;
      this.props.onChangeProfileAutomaticLoginType(null);
    } else if (value === true) {
      this.props.onChangeProfileAutomaticLogin(true);
      this.props.onChangeProfileAutomaticLoginType(null);
    } else if (value) {
      this.props.onChangeProfileAutomaticLogin(true);
      this.props.onChangeProfileAutomaticLoginType(value);
    }

    this.setState({ autoLoginType: value, automaticLogin: autoLog });
  };

  render() {
    const { allowCloud, autoLoginType, automaticLogin } = this.state;
    const { currentProfile, loginData, profilesSettings } = this.props;
    let modes = [
      { label: t("LAST2"), value: true },
      { label: t("conditions:UNACTIVE"), value: false },
    ];

    profilesSettings.forEach((v, k) => {
      if (
        v &&
        k != "demo" &&
        k != "currentProfile" &&
        v.get("loginData") &&
        v.get("loginData").get("name")
      ) {
        modes.push({
          label: v.get("loginData").get("name"),
          value: v.get("loginData").get("name"),
        });
      }
    });
    //console.log(autoLoginType, profilesSettings);

    return (
      <ScreenView>
        <ListItemContainer>
          <PrimaryColorText>
            {t("account:ALLOW_CLOUD_CONNECTION")}
          </PrimaryColorText>
          <SwitchStyled
            value={allowCloud}
            onValueChange={this.changeCloudConnectionAllowanceHandler}
          />
        </ListItemContainer>

        <ListItemContainer>
          <PrimaryColorText style={{ marginRight: 15, flex: 1 }}>
            {t("LOGIN_AUTOMATICALLY")}
          </PrimaryColorText>
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <PickerSelect
              placeholder={{
                label: t("CHOOSE_OPTION"),
              }}
              containerStyle={{
                width: 120,
              }}
              items={modes}
              onValueChange={this.onAutologinChange}
              value={autoLoginType ? autoLoginType : automaticLogin}
            />
          </View>
        </ListItemContainer>

        {currentProfile != "demo" ? (
          <ComponentContainerTouchable
            containerStyle={{ width: "100%" }}
            onPress={() => {
              this.props.navigation.navigate("Password_Security", {
                title: t(`PASSWORD`),
              });
            }}
          >
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                alignContent: "center",
                justifyContent: "center",
              }}
            >
              <PrimaryColorText>{t(`CHANGE_PASSWORD`)}</PrimaryColorText>
            </View>
            <Feather
              name={I18nManager.isRTL ? "chevron-left" : "chevron-right"}
              color={"#999"}
              size={24}
            />
          </ComponentContainerTouchable>
        ) : null}
        <View
          style={{
            width: "100%",
            justifyContent: "center",
            marginTop: 5,
            marginBottom: 15,
          }}
        >
          <CloudIntegration
            onConnect={this.onConnect}
            device_id={loginData.get("device_id")}
            source="security"
          />
        </View>
      </ScreenView>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentProfile: state.profilesSettings.get("currentProfile"),
    loginData: state.profilesSettings
      .get(state.profilesSettings.get("currentProfile"))
      .get("loginData"),
    automaticLogin: state.globalSettings.get("autoLogin"),
    automaticLoginType: state.globalSettings.get("autoLoginType"),
    profilesSettings: state.profilesSettings,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeCloudConnectionAllowance: (cloudConnectionAllowance) =>
      dispatch(changeCloudConnectionAllowance(cloudConnectionAllowance)),
    onChangeProfileAutomaticLogin: (automaticLogin) =>
      dispatch(changeAutoLogin(automaticLogin)),
    onChangeProfileAutomaticLoginType: (automaticLogin) =>
      dispatch(changeAutoLoginType(automaticLogin)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Security_AccountScreen);
