import * as Actions from "../actionTypes";

export const authDataReceived = (authData) => {
  return { type: Actions.AUTH_DATA_RECEIVED, payload: authData };
};

export const associationsDataReceived = (associationsData) => {
  return {
    type: Actions.ASSOCIATIONS_DATA_RECEIVED,
    payload: associationsData,
  };
};

export const metaDataReceived = (metaData) => {
  return {
    type: Actions.META_DATA_RECEIVED,
    payload: metaData,
  };
};

export const iceServersReceived = (serversData) => {
  return {
    type: Actions.ICE_SERVER_RECEIVED,
    payload: serversData,
  };
};
