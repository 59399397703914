import React, { useEffect } from "react";
import { Text, View, ScrollView, ImageBackground, Modal, Pressable, AppState } from "react-native";

import { connect } from "react-redux";
import { t } from "../../services/i18n";
import ModalSimple from "../UI/ModalSimple";
import { useTheme } from "../../../ThemeContext";
import { changeAppLocation } from "../../store/actions";
import mqttClient from "../../services/mqtt";
import ModalWithContent from "./ModalWithContent";
import * as ScreenOrientation from "expo-screen-orientation";
import { FlatList } from "react-native-gesture-handler";
import * as SplashScreen from "expo-splash-screen";
import { DeviceListHandler } from "../../utils";
import { changeAnsweredCall, changePopupObjectId, changeTimeMissmatch, setDuringTalk } from "../../store/actions/statesData";

const handleLogoutOK = async (props) => {
  async function unlockLandscapeOrientation() {
    try {
      await ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock["DEFAULT"]);
    } catch (error) {
      console.log(error, "error in 	ScreenOrientation.OrientationLock");
    }
  }

  unlockLandscapeOrientation();
  mqttClient.handleLogOut();
  props.onChangeAppLocation("auth");
};

const ScreenViewX = (props) => {
  const { theme } = useTheme();

  let { noConnectionCriticalError, noConnectionBarVisible, serverInfo, disableScroll, timeMissmatch, changeTimeMissmatch } = props;

  const content = (
    <>
      {noConnectionBarVisible && (
        <View
          style={{
            backgroundColor: "#b22424",
            height: 30,
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row",
            width: "100%",
          }}
        >
          <Text style={{ color: "#fff" }}>{t("NO_CONNECTION")}</Text>
        </View>
      )}
      {serverInfo && serverInfo.get("userId") == -1 && (
        <View
          style={{
            backgroundColor: "#ff9966",
            height: 30,
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row",
            width: "100%",
          }}
        >
          <Text style={{ color: "#fff" }}>{t("DEBUG_CONNECTION")}</Text>
        </View>
      )}
      {timeMissmatch && (
        <Pressable
          onPress={() => {
            changeTimeMissmatch(false);
            mqttClient.updateTime();
          }}
        >
          <View
            style={{
              backgroundColor: "red",
              height: 30,
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "row",
              width: "100%",
            }}
          >
            <Text style={{ color: "#fff" }}>{t("TIME_MISSMATCH_CLICK_TO_UPDATE")}</Text>
          </View>
        </Pressable>
      )}
      {noConnectionCriticalError != null && (
        <ModalSimple
          isVisible={noConnectionCriticalError != null}
          title={"CANNOT_CONNECT_TO_SERVER"}
          description={noConnectionCriticalError}
          onThirdOption={() => handleLogoutOK(props)}
          thirdOptionText={t("account:LOG_OUT_FROM_PROFILE")}
        />
      )}
      <ScrollView
        keyboardShouldPersistTaps="handled"
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}
        scrollEnabled={disableScroll ? false : true}
        contentContainerStyle={{
          // alignItems: "center",
          // alignContent: "stretch",
          // justifyContent: "center",

          paddingHorizontal: 2.5,
          paddingTop: 5,
          width: "100%",
          flexDirection: "row",
          flexWrap: "wrap",
        }}
      >
        {props.children}
      </ScrollView>
    </>
  );

  return (
    <View
      style={[
        {
          backgroundColor: theme.APP_BACKGROUND_COLOR,
          flex: 1,
        },
        props.style,
      ]}
    >
      {props.bg ? (
        <ImageBackground
          source={props.bg ? { uri: `data:image/png;base64,${props.bg}` } : undefined}
          resizeMode="cover"
          style={{
            flex: 1,
            justifyContent: "center",
          }}
        >
          {content}
        </ImageBackground>
      ) : (
        content
      )}
    </View>
  );
};

const mapStateToProps = (state) => {
  let currentProfile = state.profilesSettings.get("currentProfile");
  return {
    devices: state.smartHomeData && state.smartHomeData.get(currentProfile) ? state.smartHomeData.get(currentProfile).get("devices") : null,
    noConnectionBarVisible: state.statesData.get("noConnectionBarVisible"),
    noConnectionCriticalError: state.statesData.get("noConnectionCriticalError"),
    timeMissmatch: state.statesData.get("timeMissmatch"),
    serverInfo: state.statesData.get("server").get("serverInfo"),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onChangeAppLocation: (appLocation) => dispatch(changeAppLocation(appLocation)),
    changeTimeMissmatch: (timeMissmatch) => dispatch(changeTimeMissmatch(timeMissmatch)),
  };
};

export const ScreenView = connect(mapStateToProps, mapDispatchToProps)(ScreenViewX);
