import React, { Component, useState } from "react";
import { Text, View } from "react-native";
import { t } from "../../../services/i18n";
import { ComponentContainer, ComponentHeaderText } from "../../UI/styledComponents";
import ImageForLinearInput from "../../../utils/ImageForLinearInput";
import ChartComponent from "../chart/ChartComponent";
import ButtonsSelector from "../../UI/ButtonsSelector";
import { WavyBars } from "../water/WavyBars";

const LinearInputComponent = (props) => {
  const { deviceState, params_device, device, header, deviceFromCustom, dimensions, detailContent, deviceFromSketch } = props;
  let imageAndDesc = params_device && (params_device.get("params") & 0x40000) > 0;
  let imageAndValue = params_device && (params_device.get("params") & 0x80000) > 0;
  const [currentTimeRange, setCurrentTimeRange] = useState(0);

  let timeRangeArray = [
    {
      label: t("account:TODAY"),
      value: 0,
    },
    {
      label: t("account:YESTERDAY"),
      value: 1,
    },
    {
      label: t("account:LAST_7_DAYS"),
      value: 2,
    },
    {
      label: t("account:LAST_MONTH"),
      value: 3,
    },
  ];

  const componentToShow = () => {
    const value =
      device.get("typ_komponentu") === "poziom_wody"
        ? (((Number(deviceState.get("state")) - Number(device.get("min"))) * 100) / (Number(device.get("max")) - Number(device.get("min")))).toFixed(
            0
          )
        : deviceState.get("state");

    if (deviceState.get("res")) {
      let url = params_device && params_device.get("url") ? params_device.get("url") : "%";

      return (
        <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "flex-end" }}>
          {imageAndDesc && deviceState.get("desc") && (
            <ComponentHeaderText
              numberOfLines={1}
              style={{
                maxWidth: deviceFromCustom ? "100%" : "100%",
                alignItems: "flex-end",
                paddingLeft: 5,
              }}
            >
              {deviceState.get("desc")}
              {imageAndDesc && imageAndDesc ? "," : ""}
            </ComponentHeaderText>
          )}
          {imageAndValue && (
            <ComponentHeaderText
              style={{
                paddingLeft: 5,
                maxWidth: deviceFromCustom ? "100%" : "50%",
                alignItems: "flex-end",
                justifyContent: "flex-end",
              }}
              numberOfLines={1}
            >
              {value}
              {device.get("typ_komponentu") === "temp" ? "°C" : url}
            </ComponentHeaderText>
          )}

          <ImageForLinearInput id={deviceState.get("res")} deviceFromCustom={deviceFromCustom} />
        </View>
      );
    } else if (deviceState.get("desc")) {
      return (
        <ComponentHeaderText
          numberOfLines={1}
          style={{
            maxWidth: deviceFromCustom ? "100%" : "50%",
            alignItems: "flex-end",
            paddingLeft: 5,
          }}
        >
          {deviceState.get("desc")}
        </ComponentHeaderText>
      );
    } else {
      let url = params_device && params_device.get("url") ? params_device.get("url") : "%";

      return (
        <ComponentHeaderText
          numberOfLines={1}
          style={{
            maxWidth: deviceFromCustom ? "100%" : "50%",
            alignItems: "flex-end",
            paddingLeft: 5,
          }}
        >
          {value}
          {device.get("typ_komponentu") === "temp" ? "°C" : url}
        </ComponentHeaderText>
      );
    }
  };

  return !detailContent ? (
    <View
      style={{
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        height: dimensions.get("smallTiles") && !deviceFromSketch ? 40 : undefined,
      }}
    >
      {!deviceFromCustom && header}
      {componentToShow()}
    </View>
  ) : (
    <View style={{ flexDirection: "column" }}>
      <ComponentContainer numOfColumns={1}>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            height: dimensions.get("smallTiles") && !deviceFromSketch ? 40 : undefined,
          }}
        >
          {!deviceFromCustom && header}
          {componentToShow()}
        </View>
      </ComponentContainer>

      {device.get("typ_komponentu") === "poziom_wody" ? <WavyBars {...props} /> : null}

      {device.get("typ_komponentu") !== "tekst_api" ? (
        <ComponentContainer numOfColumns={1}>
          <ButtonsSelector
            active={currentTimeRange}
            onChangeTab={(value) => {
              setCurrentTimeRange(value);
            }}
            tabs={timeRangeArray}
            fontSize={12}
            style={{ marginBottom: 10 }}
          />

          <ChartComponent {...props} fromDetails={true} currentTimeRange={currentTimeRange} />
        </ComponentContainer>
      ) : null}
    </View>
  );
};

export default LinearInputComponent;
