import { Canvas, Circle, LinearGradient, Path, useClock, useFont, vec, matchFont } from "@shopify/react-native-skia";
import React, { useEffect } from "react";
import { View, Platform } from "react-native";
import { useDerivedValue, useSharedValue } from "react-native-reanimated";
import { Outline } from "./components/Outline";
import { CANVAS_HEIGHT, CANVAS_MIDDLE, MAXIMUM_Y, MINIMUM_Y } from "./utils/constants";
import { createWavePath } from "./utils/createWavePath";
import { useTheme } from "../../../../ThemeContext";
import { ComponentContainer, PrimaryColorText } from "../../UI/styledComponents";

export const WavyBars = ({ deviceState, device }) => {
  if (deviceState === undefined || device === undefined) {
    return null;
  }
  const currentPoint = useSharedValue(0);

  const clock = useClock();
  const { theme } = useTheme();

  const path = useDerivedValue(() => {
    const current = (clock.value / 1000) % 1000;
    const start = createWavePath(current)!;
    const end = createWavePath(Math.PI * current)!;
    return start.interpolate(end, 0.5)!;
  }, [clock]);

  const pathTransform = useDerivedValue(() => {
    const yPosition = currentPoint.value - 20;

    return [{ translateY: yPosition }];
  }, [currentPoint]);

  useEffect(() => {
    if (deviceState) {
      const value = Number(deviceState.get("state"));
      const percentage = ((value - Number(device.get("min"))) * 100) / (Number(device.get("max")) - Number(device.get("min")));
      const yPosition = (CANVAS_HEIGHT * (100 - percentage)) / 100 + (20 * percentage) / 100;
      currentPoint.value = yPosition;
    }
  }, [deviceState]);

  return (
    <ComponentContainer numOfColumns={1}>
      <View
        style={{
          flex: 1,
          justifyContent: "center",
          backgroundColor: theme.COMPONENT_BACKGROUND_COLOR,
          alignItems: "center",
          paddingBottom: 10,
        }}
      >
        <Canvas style={{ width: CANVAS_MIDDLE * 2, height: CANVAS_HEIGHT }}>
          <Path path={path} style="fill" color={"blue"} transform={pathTransform}>
            <LinearGradient start={vec(CANVAS_MIDDLE, 0)} end={vec(CANVAS_MIDDLE, CANVAS_HEIGHT * 2)} colors={["lightblue", "blue"]} />
          </Path>
          <Outline />
        </Canvas>
      </View>
    </ComponentContainer>
  );
};
