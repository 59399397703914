import React, { Component } from "react";
import { connect } from "react-redux";

import { ScreenViewWithoutScroll } from "../../components/UI/styledComponents";

import { DeviceSketchHandler } from "../../utils";

import { View, ImageBackground, StyleSheet, Dimensions, ActivityIndicator, Image } from "react-native";
import { SkottieLoader } from "../../components/animatedComponents/Loader";

class Sketch_PlacesScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      width: 0,
      height: 0,
      wspX: 1,
      wspY: 1,
      offsetX: 0,
      offsetY: 0,
      ratio: 1,
      showImage: false,
    };
  }

  onLayout = ({ nativeEvent }) => {
    let uri = `data:image/png;base64,${this.props.currentGroup.get("zdjecie")}`;

    let { width, height } = nativeEvent.layout;
    let { stretchSketch } = this.props;
    let wspX = width / 704.0;
    let wspY = height / 704.0;
    let offsetX = 0;
    let offsetY = 0;

    Image.getSize(uri, (imageWidth, imageHeight) => {
      let ratio = imageWidth / imageHeight;
      if (stretchSketch) {
      } else {
        let ratioX = imageWidth / width;
        let ratioY = imageHeight / height;
        if (ratioX > ratioY) {
          offsetY = (height - imageHeight / ratioX) / 2;
          wspY = (height - offsetY * 2) / 704;
        } else {
          // wtedy na wyokosc bedzie ok ale boki beda biale czyli offset x trzeba miec
          offsetX = (width - imageWidth / ratioY) / 2;
          wspX = (width - offsetX * 2) / 704;
        }
      }
      this.setState({
        width,
        height,
        wspX,
        wspY,
        ratio,
        showImage: true,
        offsetX,
        offsetY,
      });
    });
  };
  render() {
    const { listOfDevices, devices, stretchSketch, currentGroup, currentConnection, navigation } = this.props;
    const { width, height, wspX, wspY, offsetX, offsetY, ratio, showImage } = this.state;

    let uri = `data:image/png;base64,${this.props.currentGroup.get("zdjecie")}`;

    let devicesForSketch = listOfDevices.map((device) => {
      if (devices.get(device.get("id_obiektu")) && device.get("x") && device.get("y") && device.get("width") && device.get("height")) {
        return (
          <DeviceSketchHandler
            device={devices.get(device.get("id_obiektu"))}
            group_params={device.get("param")}
            key={device.get("id_obiektu")}
            width={width}
            height={height}
            stretchSketch={stretchSketch}
            navigation={navigation}
            styleToPass={{
              justifyContent: "center",
              alignItems: "center",
              width: (device.get("width") * wspX) / ratio,
              height: device.get("height") * wspY,
              position: "absolute",
              left: offsetX + device.get("x") * wspX,
              top: offsetY + device.get("y") * wspY,
              color: device.get("color"),
              fontSize: device.get("textHeight") ? (device.get("textHeight") / 100) * device.get("height") : null,
            }}
          />
        );
      }
    });

    return (
      <ScreenViewWithoutScroll onLayout={this.onLayout} key={stretchSketch}>
        {!showImage ? (
          <View style={{ justifyContent: "center", flex: 1 }}>
            <SkottieLoader style={{ width: 40, height: 40, margin: 5 }} />
          </View>
        ) : uri ? (
          <ImageBackground source={{ uri: uri }} style={{ width, height }} resizeMode={stretchSketch ? "stretch" : "contain"}>
            {devicesForSketch}
          </ImageBackground>
        ) : null}
      </ScreenViewWithoutScroll>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    stretchSketch: state.globalSettings.get("stretchSketch"),
  };
};

export default connect(mapStateToProps)(Sketch_PlacesScreen);
