const calculateSizeMenu = (size) => {
	let menuSize = 70;
	switch (size) {
		case "short":
			menuSize = 70;
			break;
		case "long":
			menuSize = 300;
			break;

		default:
			menuSize = 70;
			break;
	}

	return menuSize;
};

export default calculateSizeMenu;
