import React, { Component } from "react";
import { Text, Image, View } from "react-native";
import { connect } from "react-redux";

import { PrimaryColorText } from "../components/UI/styledComponents";

class ImageForLinearInput extends Component {
  render() {
    const { icon, resizeMode, globalSettings, deviceFromSketch, deviceFromCustom } = this.props;
    const showBGForDeviceIcons = globalSettings.get("showBGForDeviceIcons");

    const theme = globalSettings.get("primaryTheme");
    const iconBGColor = globalSettings.get("iconBGColors") && globalSettings.get("iconBGColors").get(theme);

    let contentImage =
      icon && icon.get("wartoscB") ? (
        <Image
          source={{ uri: `data:image/png;base64,${icon.get("wartoscB")}` }}
          resizeMode={resizeMode ? resizeMode : "contain"}
          style={[{ width: 36, height: 36 }, this.props.style]}
        />
      ) : null;
    return icon ? (
      !deviceFromSketch ? (
        <View
          style={{
            backgroundColor: showBGForDeviceIcons && iconBGColor,
            height: showBGForDeviceIcons ? 45 : null,
            width: showBGForDeviceIcons ? 45 : null,
            marginTop: deviceFromCustom ? (showBGForDeviceIcons ? -2 : -2) : -5,
            marginBottom: deviceFromCustom ? -10 : -5,
            borderRadius: 100,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {contentImage}
        </View>
      ) : (
        contentImage
      )
    ) : (
      <PrimaryColorText>ICON ERROR</PrimaryColorText>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    icon: state.smartHomeData.get(state.profilesSettings.get("currentProfile")).get("icons").get(ownProps.id),
    globalSettings: state.globalSettings,
  };
};

export default connect(mapStateToProps)(ImageForLinearInput);
