import React, { Component, useState, useEffect } from "react";
import { View, TouchableOpacity, Text } from "react-native";
import { connect } from "react-redux";
import { t } from "../../../../services/i18n";

import DraggableFlatList from "react-native-draggable-flatlist";
import {
  ComponentContainerTouchable,
  PrimaryColorText,
  ScreenView,
  StyledIcon,
  TitleWithLines,
  SwitchStyled,
  ButtonPrimary,
} from "../../../../components/UI/styledComponents";
import { KeyboardAvoidingView } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import ModalSimple from "../../../../components/UI/ModalSimple";
import { ListItemContainer } from "../../styled";
import {
  saveOrder,
  changeShowByColumns,
  resetOrder,
} from "../../../../store/actions";

import { useTheme } from "../../../../../ThemeContext";

const General_Order_PersonalizationScreen = (props) => {
  const [generalOrderData, setGeneralOrderData] = useState(null);
  const [resetOrderDialogVisible, setResetOrderDialogVisible] = useState(false);
  const { theme } = useTheme();
  const { order, globalSettings, dimensions } = props;

  useEffect(() => {
    setData();
  }, []);

  useEffect(() => {
    return () => {
      let generalOrderArray =
        generalOrderData && generalOrderData.map((el) => el.key);
      props.onSaveOrder(generalOrderArray, "general");
    };
  }, [generalOrderData]);

  const renderGeneralItem = ({ item, index, drag, isActive }) => {
    return (
      <ComponentContainerTouchable
        containerStyle={{ width: "100%" }}
        onLongPress={drag}
        style={{
          backgroundColor: isActive ? "#ccc" : theme.COMPONENT_BACKGROUND_COLOR,
          alignItems: "center",
          width: "90%",
          alignSelf: "center",
          // height: 30,
        }}
      >
        <PrimaryColorText style={{ fontWeight: "bold" }}>
          {item.label}
        </PrimaryColorText>
      </ComponentContainerTouchable>
    );
  };

  const setData = () => {
    let generalOrderData = [
      {
        key: "scenes",
        label: t("SCENES"),
      },
      {
        key: "groups",
        label: t("GROUPS"),
      },
      {
        key: "devices",
        label: t("DEVICES"),
      },
    ];

    let generalOrder = order && order.get("general");
    if (generalOrder && generalOrder.size) {
      generalOrderData = [];
      generalOrder.map((el) => {
        generalOrderData.push({
          key: el,
          label: t(`${el.toUpperCase()}`),
        });
      });
    }
    setGeneralOrderData(generalOrderData);
  };

  const changeShowByColumnsHandler = (value) => {
    props.changeShowByColumns(value);
  };

  const onResetOrder = () => {
    setResetOrderDialogVisible(false);
    props.onResetOrder();
  };

  const showByColumns =
    globalSettings.get("showByColumns") == undefined
      ? true
      : globalSettings.get("showByColumns");

  const width = dimensions.get("width");

  return (
    <View
      style={{
        flex: 1,
        backgroundColor: theme.APP_BACKGROUND_COLOR,
        // padding: 5,
      }}
    >
      {resetOrderDialogVisible && (
        <ModalSimple
          isVisible={resetOrderDialogVisible}
          title={"account:RESET_ORDER"}
          description={"account:RESET_ORDER_SURE"}
          onOK={onResetOrder}
          onClose={() => setResetOrderDialogVisible(false)}
        />
      )}
      <View
        style={{
          width: "100%",
          justifyContent: "center",
          marginTop: 15,
          // marginBottom: 15,
        }}
      >
        <ButtonPrimary onPress={() => setResetOrderDialogVisible(true)}>
          {t("account:RESET_ORDER")}
        </ButtonPrimary>
      </View>
      {width >= 600 ? (
        <ListItemContainer>
          <PrimaryColorText>{t("account:SHOW_BY_COLUMNS")}</PrimaryColorText>
          <SwitchStyled
            value={showByColumns}
            onValueChange={changeShowByColumnsHandler}
          />
        </ListItemContainer>
      ) : null}

      <TitleWithLines textInside={t("GENERAL")} />
      {generalOrderData ? (
        <View style={{ height: 150 }}>
          <DraggableFlatList
            data={generalOrderData}
            renderItem={renderGeneralItem}
            keyExtractor={(item, index) => `draggable-item-${item.key}`}
            onDragEnd={({ data }) => {
              setGeneralOrderData(data);
            }}
          />
        </View>
      ) : null}

      <ComponentContainerTouchable
        containerStyle={{ width: "100%" }}
        style={{
          width: "90%",
          alignSelf: "center",
          alignItems: "center",
        }}
        onPress={() => {
          props.navigation.navigate("Groups_Order_Personalization", {
            title: t("GROUPS"),
          });
        }}
      >
        <PrimaryColorText style={{ fontWeight: "bold" }}>
          {t("GROUPS")}
        </PrimaryColorText>

        <StyledIcon name={"chevron-right"} size={24} color={"secondary"} />
      </ComponentContainerTouchable>
      <ComponentContainerTouchable
        containerStyle={{ width: "100%" }}
        style={{
          width: "90%",
          alignSelf: "center",
          alignItems: "center",
        }}
        onPress={() => {
          props.navigation.navigate("Scenes_Order_Personalization", {
            title: t("SCENES"),
          });
        }}
      >
        <PrimaryColorText style={{ fontWeight: "bold" }}>
          {t("SCENES")}
        </PrimaryColorText>

        <StyledIcon name={"chevron-right"} size={24} color={"secondary"} />
      </ComponentContainerTouchable>
      <ComponentContainerTouchable
        containerStyle={{ width: "100%" }}
        style={{
          width: "90%",
          alignSelf: "center",
          alignItems: "center",
        }}
        onPress={() => {
          props.navigation.navigate("Favourites_Order_Personalization", {
            title: t("FAVOURITES"),
          });
        }}
      >
        <PrimaryColorText style={{ fontWeight: "bold" }}>
          {t("FAVOURITES")}
        </PrimaryColorText>

        <StyledIcon name={"chevron-right"} size={24} color={"secondary"} />
      </ComponentContainerTouchable>
    </View>
  );
};

const mapStateToProps = (state) => {
  let currentProfile = state.profilesSettings.get("currentProfile");

  return {
    globalSettings: state.globalSettings,
    order: state.profilesSettings.get(currentProfile).get("order"),
    dimensions: state.statesData.get("dimensions"),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeShowByColumns: (showByColumns) =>
      dispatch(changeShowByColumns(showByColumns)),
    onSaveOrder: (order, groupId) => dispatch(saveOrder(order, groupId)),
    onResetOrder: () => dispatch(resetOrder()),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(General_Order_PersonalizationScreen);
