import JanusUtils from "./JanusUtils";

import { store } from "../../../store/configureStore";
import { changeJanus, changeJanusId } from "../../../store/actions";

export default class JanusSocket {
  constructor(address, janus) {
    this.address = address;
    this.transactions = {};
    this.plugins = {};
    this.senders = {};
    this.sessionID = null;
    this.connected = false;
    this.keepAliveTimeoutID = null;
    this.username = null;
    this.password = null;
    this.localToken = null;
    this.janus = janus;
    this.tryToReconnect = true;
    this.errorCounter = 0;
    this.ws = null;
  }

  /**
   *
   * @param {JanusPlugin} plugin
   */
  attachPlugin = (plugin) => {
    this.plugins[plugin.handleID] = plugin;
  };

  /**
   *
   * @param {JanusPlugin} plugin
   */
  detachPlugin = (plugin) => {
    delete this.plugins[plugin.handleID];
  };

  /**
   *
   * @param local_token
   */
  setLocalToken = (localToken) => {
    this.localToken = localToken;
  };

  /**
   *
   * @param username
   * @param password
   */
  setCredentials = (username, password) => {
    this.username = username;
    this.password = password;
  };

  calculateFib = (number) => {
    if (number < 1) {
      return 0;
    }
    let a = 0;
    let b = 1;
    for (let i = 1; i < number; ++i) {
      const c = a + b;
      a = b;
      b = c;
    }
    return b;
  };

  connect = () => {
    return new Promise((resolve, reject) => {
      var client;

      client = new WebSocket(this.address, ["janus-protocol"]);

      let additionalConfig = {};

      if (this.username) {
        additionalConfig["username"] = this.username;
      }
      if (this.password) {
        additionalConfig["password"] = this.password;
      }
      if (this.localToken) {
        additionalConfig["local_token"] = this.localToken;
      }
      const clientId = Date.now();

      client.onopen = () => {
        this.ws = client;
        this.send(
          {
            janus: "create",
            ...additionalConfig,
          },
          async (response) => {
            if (response.janus === "success") {
              console.log("Connected to janus", client._socketId);
              client.connId = clientId;
              this.sessionID = response.data.id;
              this.errorCounter = 0;
              this.janus.connectionID = client._socketId;
              this.connected = true;
              store.dispatch(changeJanus(this.janus));
              store.dispatch(changeJanusId(client._socketId));
              this.setKeepAliveTimeout();
              resolve();
            } else {
              reject(response.error);
            }
          }
        );
      };
      client.onmessage = async (e) => {
        try {
          let message = JSON.parse(e.data);
          switch (message.janus) {
            // general events
            case "keepalive":
            case "ack":
            case "timeout": {
              return await this.onMessage(message);
            }

            // plugin events
            case "trickle":
            case "webrtcup":
            case "hangup":
            case "detached":
            case "media":
            case "slowlink":
            case "event": {
              if (message.transaction) {
                const { transaction } = message;
                if (!this.transactions[transaction]) {
                  return await this.plugins[message.sender].onMessage(message);
                }
                await this.transactions[transaction](message);
                return delete this.transactions[transaction];
              } else {
                return await this.plugins[message.sender].onMessage(message);
              }
            }

            // transaction responses
            case "success":
            case "error": {
              if (message.transaction) {
                const { transaction } = message;
                await this.transactions[transaction](message);
                delete this.transactions[transaction];
                return;
              }
            }
          }

          if (message.janus && message.janus !== "ack" && message.transaction) {
            const { transaction } = message;
            await this.transactions[transaction](message);
            delete this.transactions[transaction];
          } else {
            if (message.sender && this.plugins[message.sender] && this.plugins[message.sender].onMessage) {
              await this.plugins[message.sender].onMessage(message);
            } else {
              await this.onMessage(message);
            }
          }
        } catch (e) {
          JanusUtils.log("message_error", e);
        }
      };

      client.onerror = (e) => {
        JanusUtils.log("socket_error", e);
      };

      client.onclose = (e) => {
        JanusUtils.log("socket_close", e, client._socketId, this.errorCounter);
        for (const [key, value] of Object.entries(this.plugins)) {
          this.plugins[key].deleted = true;
          delete this.plugins[key];
        }

        if (client._socketId === store.getState().statesData.get("janusId")) {
          store.dispatch(changeJanus(null));
          if (e.code === 1000 && this.tryToReconnect) {
            setTimeout(() => {
              this.connect(true);
            }, 5000);
          }
        } else {
          if ((e.code === 1011 || e.code === 1006) && this.tryToReconnect && store.getState().statesData.get("clientId") !== null) {
            this.errorCounter++;
            setTimeout(() => {
              this.connect(true);
            }, this.calculateFib(this.errorCounter) * 5000);
          }
        }
      };
    });
  };

  disconnect = async (reconnect) => {
    for (const [key, value] of Object.entries(this.plugins)) {
      delete this.plugins[key];
    }
    this.tryToReconnect = reconnect;
    this.ws.close();
  };

  onMessage = async (message) => {};

  send = (request, callback = null, transaction = null) => {
    if (!transaction && typeof callback === "function") {
      transaction = JanusUtils.randomString(12);
      this.transactions[transaction] = callback;
    }
    request["transaction"] = transaction;
    try {
      this.ws.send(JSON.stringify(request));
    } catch (ex) {
      JanusUtils.log("Message send error", ex);
    }
  };

  sendAsync = (request) => {
    return new Promise((resolve, reject) => {
      this.send(request, (response) => {
        if (response.janus !== "error") {
          resolve(response);
        } else {
          reject(response);
        }
      });
    });
  };

  keepAlive = () => {
    if (this.connected) {
      this.setKeepAliveTimeout();
      this.send(
        {
          janus: "keepalive",
          session_id: this.sessionID,
        },
        (response) => {
          // JanusUtils.log('keepAlive', response.session_id);
        }
      );
    }
  };

  setKeepAliveTimeout = () => {
    if (this.connected) {
      this.keepAliveTimeoutID = setTimeout(this.keepAlive, 5000);
    }
  };
}
