import type { ColorValue } from "react-native";
import React from "react";
import { StyleSheet, View } from "react-native";
import Animated, { useAnimatedProps } from "react-native-reanimated";
import Svg, { Circle } from "react-native-svg";

import { useTheme } from "../../../../../ThemeContext";

const { PI } = Math;
const AnimatedCircle = Animated.createAnimatedComponent(Circle);

interface CircularProgressProps {
  theta: Animated.SharedValue<number>;
  r: number;
  strokeWidth: number;
  backgroundColor: Animated.SharedValue<string | number>;
}

export const CircularProgress = ({ theta, r, strokeWidth, backgroundColor }: CircularProgressProps) => {
  const radius = r - strokeWidth / 2;

  const strokeWidth2 = strokeWidth / 6;
  const circumference = radius * 2 * PI;
  const { theme } = useTheme();
  const props = useAnimatedProps(() => {
    return {
      strokeDashoffset: theta.value * radius,
    };
  });

  return (
    <View
      style={{
        justifyContent: "center",
        alignItems: "center",
        flex: 1,
      }}
    >
      <Svg style={{ width: "100%", height: "100%" }}>
        <Circle cx={r} cy={r} fill="transparent" stroke="transparent" r={radius} {...{ strokeWidth: strokeWidth2 }} />
        <AnimatedCircle
          animatedProps={props}
          cx={r}
          cy={r}
          fill={theme.COMPONENT_BACKGROUND_COLOR}
          r={radius}
          stroke={theme.BUTTON_BACKGROUND_COLOR_PRIMARY}
          strokeDasharray={`${circumference}, ${circumference}`}
          {...{ strokeWidth: strokeWidth2 }}
        />
      </Svg>
    </View>
  );
};
