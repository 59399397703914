import React, { Component } from "react";
import { Text, View, StyleSheet, TouchableOpacity, Platform } from "react-native";
//THIS SHOUDL BE USED IN WEB
import Slider from "@react-native-community/slider";
import * as Haptics from "expo-haptics";
import { connect } from "react-redux";
import mqttClient from "../../../services/mqtt";

import { ComponentContainer, ComponentHeaderText, PowerStyled, SwitchStyled } from "../../UI/styledComponents";

import { addFavouriteColorToRGBModal, removeFavouriteColorFromRGBModal } from "../../../store/actions";
import LinearGradient from "react-native-linear-gradient";
import ColorPicker from "./RGBModal/ColorPicker";
import ModalWithContent from "../../UI/ModalWithContent";
import { Hue } from "./Hue";
import FavouritesColors from "./RGBModal/FavouritesColors";
import { kelvinToRgb } from "../led/LedWWComponent";
import { Slider as SliderM } from "@miblanchard/react-native-slider";
import ImageFromIkona0_0 from "../../../utils/ImageFromIkona0_0";
import { t } from "../../../services/i18n";
let numberToColor = (num) => {
  let color = {};
  num >>>= 0;
  (color.r = num & 0xff),
    (color.g = ((num & 0xff00) >>> 8) & 0xff),
    (color.b = ((num & 0xff0000) >>> 16) & 0xff),
    (color.w = ((num & 0xff000000) >>> 24) & 0xff);
  return color;
};
let colorToNumber = (color) => {
  let num = ((color.r & 0xff) + ((color.g & 0xff) << 8) + ((color.b & 0xff) << 16) + ((color.w & 0xff) << 24)) & 0xffffffff;
  return num >>> 0;
};

class RGBComponent extends Component {
  state = {
    color: {
      r: 0,
      g: 0,
      b: 0,
      w: 0,
    },
    colorForSlider: null,
    brightness: 255,
    deviceFromScene: false,
    switchValue: false,

    modalVisible: false,
    favouritesColors: null,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    let initialColor;
    if (!nextProps.deviceInScene && !prevState.blockProps && nextProps.deviceState) {
      initialColor = numberToColor(nextProps.deviceState.get("state"));
      return {
        color: initialColor,
        colorForSlider: prevState.colorForSlider ? prevState.colorForSlider : initialColor,
        switchValue: nextProps.deviceState.get("state") == 0 ? false : true,
      };
    } else if (nextProps.deviceInScene && !prevState.deviceFromScene) {
      initialColor = numberToColor(nextProps.deviceInScene.get("value"));
      return {
        deviceFromScene: true,
        color: initialColor,
        colorForSlider: prevState.colorForSlider ? prevState.colorForSlider : initialColor,
        switchValue: nextProps.deviceInScene.get("value") == 0 ? false : true,
      };
    }
    return null;
  }

  componentDidMount() {
    const { deviceInScene, deviceState, favouritesColors } = this.props;

    this.setState({ favouritesColors });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.favouritesColors != this.props.favouritesColors) {
      this.setState({ favouritesColors: this.props.favouritesColors });
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeOut);
    this.setState({ modalVisible: false });
  }

  setModalVisible = (visible) => {
    this.setState({ modalVisible: visible });
  };

  toggleSwitchValue = (value) => {
    const { favouritesColors } = this.props;

    let colorOn =
      favouritesColors && favouritesColors.get("lastColor")
        ? {
            r: favouritesColors.get("lastColor").get("color").get("r"),
            g: favouritesColors.get("lastColor").get("color").get("g"),
            b: favouritesColors.get("lastColor").get("color").get("b"),
            w: favouritesColors.get("lastColor").get("color").get("w"),
          }
        : {
            r: 80,
            g: 80,
            b: 80,
            w: 0,
          };

    let colorOff = {
      r: 0,
      g: 0,
      b: 0,
      w: 0,
    };

    if (value == 0) {
      const { device } = this.props;
      const { color } = this.state;

      const deviceID = device.get("id");
      this.props.onAddFavouriteColorToRGBModal(deviceID, "lastColor", color);
    }

    this.setState(
      {
        switchValue: value,
        color: value ? colorOn : colorOff,
        blockProps: true,
      },
      () => {
        this.sendMessage(this.state.color);
      }
    );
    Platform.OS !== "web" && Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Light);
  };

  onSlidingStartHandler = () => {
    this.setState({ blockProps: true });
  };

  onSlidingChangeHandler = (value, colorKey) => {
    let colorReceived = Object.assign({}, this.state.color);
    colorReceived[colorKey] = value;
    // if (!this.state.blockProps) {
    // 	this.setState({ blockProps: true });
    // }
    this.setState({
      color: colorReceived,
      switchValue: !colorReceived.r && !colorReceived.g && !colorReceived.b && !colorReceived.w ? false : true,
      blockProps: true,
    });
    this.sendMessage(colorReceived, true);
  };

  onSlidingCompleteHandler = (value, colorKey) => {
    let colorReceived = Object.assign({}, this.state.color);
    colorReceived[colorKey] = value;
    this.setState({
      color: colorReceived,
      switchValue: !colorReceived.r && !colorReceived.g && !colorReceived.b && !colorReceived.w ? false : true,
    });
    this.sendMessage(colorReceived);
  };

  runTimeout = () => {
    let updateState = () => {
      const { deviceState } = this.props;
      if (deviceState) {
        let color = numberToColor(deviceState.get("state"));
        this.setState({
          blockProps: false,
          color: color,
          switchValue: deviceState.get("state") == 0 ? false : true,
        });
      }
    };
    this.timeOut = setTimeout(() => {
      this.timeOut = 0;
      updateState();
    }, 5000);
  };

  clearTimeoutMine = () => {
    if (this.timeOut) {
      clearTimeout(this.timeOut);
      this.timeOut = 0;
    }
    this.runTimeout();
  };

  sendMessage = (value, fromSliderMoving) => {
    const { device, deviceInScene, changeScene, activeSceneControl } = this.props;

    !deviceInScene && !fromSliderMoving && this.clearTimeoutMine();

    if (deviceInScene) {
      let infoToUpdate = deviceInScene.set("value", colorToNumber(value));
      changeScene(infoToUpdate, deviceInScene.get("temporary_id"));
    }

    let colorAsNumber = colorToNumber(value);
    let message = `/api/set/${device.get("id")}/setColors/${colorAsNumber}`;
    activeSceneControl != false && mqttClient.stateChangeToSend(message, device.get("id"));
  };

  setColorAndSend = (color, withBrigtness) => {
    let { r, g, b } = color;
    let { w } = this.state.color;

    this.setState(
      {
        color: { r, g, b, w },
        switchValue: !r && !g && !b && !w ? false : true,
        blockProps: true,
        brightness: withBrigtness ? 255 : this.state.brightness,
        colorForSlider: { r, g, b, w },
      },
      () => {
        this.sendMessage(this.state.color);
      }
    );
  };

  setColorFromModal = (colorReceived) => {
    this.setColorAndSend(colorReceived, true);
  };

  onRemoveFavouriteColor = (colorPosition) => {
    const { device } = this.props;
    const deviceID = device.get("id");

    mqttClient.saveFavColor(deviceID, colorPosition, 0);
    this.props.onRemoveFavouriteColorFromRGBModal(deviceID, colorPosition);
  };

  onAddFavouriteColor = (colorPosition) => {
    const { device } = this.props;
    const { color } = this.state;
    const deviceID = device.get("id");
    let colorAsNumber = colorToNumber(color);

    mqttClient.saveFavColor(deviceID, colorPosition, colorAsNumber);
    this.props.onAddFavouriteColorToRGBModal(deviceID, colorPosition, color);
  };

  render() {
    const { device, header, readOnly, customWidth, dimensions, sideMenuShown, sideMenuSize, group_params } = this.props;
    const { color, switchValue, modalVisible, favouritesColors, brightness, colorForSlider } = this.state;
    const { r, g, b, w } = color;
    let landscapeOrientation = dimensions.get("landscapeOrientation");
    let backgroundColorss = `rgb(${r}, ${g}, ${b})`;
    let backgroundColorssBorder = "#ED695C";

    const sliderColorBeginning = "black";
    const sliderColorEnd = colorForSlider ? `rgba(${colorForSlider.r}, ${colorForSlider.g}, ${colorForSlider.b}, 1)` : "black";
    const thumbColor = `rgba(${Math.round((colorForSlider.r * brightness) / 255)}, ${Math.round((colorForSlider.g * brightness) / 255)}, ${Math.round(
      (colorForSlider.b * brightness) / 255
    )}, 1)`;

    let colorsArray = device.get("typ_komponentu") === "rgbw" ? ["white", "red", "green", "blue"] : ["red", "green", "blue"];

    let columnWidth = dimensions.get("columnWidth");
    let width = dimensions.get("width");
    if (customWidth) {
      columnWidth = width;
    }

    const modalContent = (
      <ColorPicker
        deviceID={device.get("id")}
        deviceName={device.get("opis_menu")}
        setColorInComponent={this.setColorFromModal}
        colorRGB={this.state.color}
        favouritesColors={favouritesColors}
        onAddFavouriteColor={this.onAddFavouriteColor}
        onRemoveFavouriteColor={this.onRemoveFavouriteColor}
      />
    );

    let showIcons = false;
    let iconOnlyOnSketch = group_params && (group_params & 0x04) > 0 ? true : false;
    if (
      (device.has("ikona_0") && (device.get("ikona_0") !== null || device.get("ikona_1") !== null)) ||
      (device.has("ikona0") && (device.get("ikona0") !== null || device.get("ikona1") !== null))
    ) {
      if (!iconOnlyOnSketch) {
        showIcons = true;
      }
    }

    let switchContent = showIcons ? (
      <TouchableOpacity onPress={() => this.toggleSwitchValue(this.state.switchValue ? 0 : 255)} disabled={readOnly}>
        <ImageFromIkona0_0 device={device} value={switchValue} />
      </TouchableOpacity>
    ) : this.props.detailContent ? (
      <PowerStyled value={switchValue} onPress={() => this.toggleSwitchValue(this.state.switchValue ? 0 : 255)} disabled={readOnly} />
    ) : (
      <SwitchStyled
        style={{ marginLeft: 10 }}
        value={switchValue}
        onValueChange={() => this.toggleSwitchValue(this.state.switchValue ? 0 : 255)}
        disabled={readOnly}
      />
    );

    let pickerWidth = columnWidth > 320 ? 160 : columnWidth / 2 - 15;
    let innerPickerWidth = pickerWidth / 2.5;
    const rgbSliders = colorsArray.map((el) => {
      let short = el[0];
      return (
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            alignContent: "center",
          }}
          key={el}
        >
          <Slider
            style={{
              flex: 1,
              marginHorizontal: 5,
              alignSelf: "center",
            }}
            step={1}
            value={color[short]}
            maximumValue={255}
            // onSlidingStart={this.onSlidingStartHandler}

            onValueChange={(value) => this.onSlidingChangeHandler(value, short)}
            onSlidingComplete={(value) => this.onSlidingCompleteHandler(value, short)}
            minimumTrackTintColor={el == "white" ? "black" : el}
            maximumTrackTintColor={"#e4e4e4"}
            disabled={readOnly}
            thumbTintColor={"#e4e4e4"}
          />

          <View style={{ marginRight: 10 }}>
            <View style={[styles.colorDot, styles[el]]}>
              <Text style={styles.colorDotText}>{short.toUpperCase()}</Text>
            </View>
          </View>
        </View>
      );
    });

    const colorPicker = (
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <View style={{ width: "48%", alignItems: "center" }}>
          <TouchableOpacity
            hitSlop={{ top: 10, bottom: 10, left: 10, right: 10 }}
            disabled={readOnly}
            style={{
              borderWidth: 1,
              width: pickerWidth,
              height: pickerWidth,
              borderRadius: pickerWidth / 2,
              backgroundColor: backgroundColorss,
              justifyContent: "center",
              alignItems: "center",
            }}
            onPress={() => this.setModalVisible(true)}
          >
            <View
              style={{
                width: innerPickerWidth,
                height: innerPickerWidth,
                borderRadius: innerPickerWidth / 2,
                backgroundColor: "white",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <View
                style={{
                  height: innerPickerWidth,
                  justifyContent: "center",
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                  }}
                >{`PICK \nCOLOR`}</Text>
              </View>
            </View>
          </TouchableOpacity>
        </View>
        <View style={{ width: "4%" }}></View>
        <View style={{ width: "48%" }}>{rgbSliders}</View>
      </View>
    );

    if (this.props.detailContent) {
      return (
        <View>
          <ModalWithContent
            title={device.get("opis_menu")}
            isVisible={modalVisible}
            onClose={() => this.setModalVisible(false)}
            modalHeight={landscapeOrientation ? "90%" : "75%"}
          >
            {modalContent}
          </ModalWithContent>
          <ComponentContainer numOfColumns={1}>
            <View
              flexDirection="row"
              justifyContent={"space-between"}
              style={{ height: 50, paddingLeft: 10, flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}
              alignItems="center"
            >
              <ComponentHeaderText>{t("POWER")}</ComponentHeaderText>
              {switchContent}
            </View>
          </ComponentContainer>
          <ComponentContainer numOfColumns={1}>
            {colorPicker}
            {/* <ColorPickerHue canvasSize={width} /> */}
          </ComponentContainer>

          {device.get("typ_komponentu") === "rgbw" && (
            <ComponentContainer numOfColumns={1}>
              <ComponentHeaderText style={{ textAlign: "center", width: "100%", flex: 1, paddingLeft: 8 }}>{t("WHITE_COLOR")}</ComponentHeaderText>
              <SliderM
                animateTransitions
                value={color["w"]}
                maximumValue={255}
                onValueChange={(value) => this.onSlidingChangeHandler(value[0], "w")}
                onSlidingComplete={(value) => this.onSlidingCompleteHandler(value[0], "w")}
                step={1}
                thumbStyle={{
                  backgroundColor: kelvinToRgb(2700),
                  borderRadius: 20,
                  height: 40,
                  width: 40,
                  borderWidth: 2,
                  borderColor: "white",
                }}
                minimumTrackStyle={{
                  borderRadius: 15,
                  height: 25,
                  backgroundColor: kelvinToRgb(2700),
                }}
                maximumTrackStyle={{
                  borderRadius: 15,
                  height: 25,
                }}
              />
            </ComponentContainer>
          )}
          <ComponentContainer numOfColumns={1}>
            <FavouritesColors
              favouritesColors={favouritesColors}
              onAddFavouriteColor={this.onAddFavouriteColor}
              onRemoveFavouriteColor={this.onRemoveFavouriteColor}
              setColorInComponent={this.setColorFromModal}
            />
          </ComponentContainer>
        </View>
      );
    }

    return (
      <View>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {header}

          {dimensions.get("smallTiles") && (
            <View style={{ flexDirection: "row", alignItems: "center" }}>
              <TouchableOpacity
                hitSlop={{ top: 10, bottom: 10, left: 10, right: 10 }}
                disabled={readOnly}
                onPress={() => this.toggleSwitchValue(this.state.switchValue ? 0 : 255)}
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  width: dimensions.get("smallTiles") ? 40 : 24,
                  height: dimensions.get("smallTiles") ? 40 : 24,
                  borderRadius: 50,
                  padding: 5,
                  marginRight: 0,
                  backgroundColor: backgroundColorss,
                  borderColor: backgroundColorssBorder,
                  borderWidth: 2,
                }}
              ></TouchableOpacity>
            </View>
          )}

          {!dimensions.get("smallTiles") && <SwitchStyled value={switchValue} onValueChange={this.toggleSwitchValue} disabled={readOnly} />}
        </View>

        {!dimensions.get("smallTiles") ? (
          <ModalWithContent
            title={device.get("opis_menu")}
            isVisible={modalVisible}
            onClose={() => this.setModalVisible(false)}
            modalHeight={landscapeOrientation ? "90%" : "75%"}
          >
            {modalContent}
          </ModalWithContent>
        ) : null}

        {!dimensions.get("smallTiles") && colorPicker}
      </View>
    );
  }
}
const styles = StyleSheet.create({
  colorSliderContainer: {
    width: "80%",
    borderWidth: 1,
  },
  colorDot: {
    width: 30,
    height: 30,
    borderRadius: 15,
    justifyContent: "center",
    alignItems: "center",
  },
  colorDotText: {
    color: "white",
    fontWeight: "bold",
  },
  whiteDotText: {
    color: "black",
    fontWeight: "bold",
  },
  red: {
    backgroundColor: "red",
  },
  green: {
    backgroundColor: "green",
  },
  blue: {
    backgroundColor: "blue",
  },
  white: {
    backgroundColor: "black",
  },
});

const mapStateToProps = (state, ownProps) => {
  let favourites = state.profilesSettings.get(state.profilesSettings.get("currentProfile")).get("favouritesColors");
  return {
    favouritesColors: favourites ? favourites.get(ownProps.device.get("id").toString()) : null,
    dimensions: state.statesData.get("dimensions"),
    sideMenuSize: state.globalSettings.get("sideMenuSize"),
    sideMenuShown: state.globalSettings.get("sideMenuShown"),
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onAddFavouriteColorToRGBModal: (deviceID, colorPosition, color) => dispatch(addFavouriteColorToRGBModal(deviceID, colorPosition, color)),
    onRemoveFavouriteColorFromRGBModal: (deviceID, colorPosition) => dispatch(removeFavouriteColorFromRGBModal(deviceID, colorPosition)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RGBComponent);
