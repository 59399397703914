import React, { Component, useEffect, useLayoutEffect } from "react";
import { View, I18nManager, TouchableOpacity, Keyboard } from "react-native";
import { connect } from "react-redux";
import { useNavigation, useRoute } from "@react-navigation/native";
import { ComponentImage, ComponentHeaderText, ComponentContainerTouchable, StyledIcon } from "../../components/UI/styledComponents";
import ModalSimple from "../../components/UI/ModalSimple";
import IconBadge from "../../utils/IconBadge";
import * as LocalAuthentication from "expo-local-authentication";

const PlaceGroupComponent = ({ group, globalSettings, showSketchFirst, profileSettings, overWriteName, fromDevices, dimensions }) => {
  const navigation = useNavigation();
  const route = useRoute();
  const numberOfColumns = dimensions.get("numberOfColumns");

  const showBGForGroupIcons = globalSettings.get("showBGForGroupIcons");

  const theme = globalSettings.get("primaryTheme");
  const iconBGColor = globalSettings.get("iconBGColors") && globalSettings.get("iconBGColors").get(theme);

  const listView = globalSettings.get("listView");

  const [pinDialogVisible, setPinDialogVisible] = React.useState(false);
  const [wrongPinDialogVisible, setWrongPinDialogVisible] = React.useState(false);
  const [pin, setPin] = React.useState("");
  var base64Icon = group ? `data:image/png;base64,${group.get("ikona_menu")}` : null;
  const originalColor = {
    ampio: "rgb(255,255,255)",
    night: "rgb(128,128,128)",
    oled: "rgb(17,17,17)",
  };

  let bgColor = showBGForGroupIcons ? iconBGColor : originalColor[theme];
  let isBig = numberOfColumns == 1 ? false : true;

  useEffect(() => {
    let startingGroup = profileSettings.get("startingPlace");
    if (group && group.get("id") == startingGroup && window.app.getGoToGroup()) {
      if ((group.get("params") & 0x040) > 0 || (group.get("params") & 0x020) > 0) {
        setPinDialogVisible(true);
      } else {
        navigation.push("Devices_Places", {
          group,
          auto: window.app.getGoToGroup(),
        });
      }
    }
  }, []);

  const onPinInputHandler = (text) => {
    setPin(text);
  };

  const handleClose = () => {
    setPinDialogVisible(false);
  };
  const handleOK = () => {
    if (String(pin) == String(group.get("uwagi"))) {
      setPinDialogVisible(false);
      navigation.push("Devices_Places", { group });
    } else {
      setPinDialogVisible(false);
      setWrongPinDialogVisible(true);
    }
  };

  const handleBiometrics = async () => {
    Keyboard.dismiss();
    setPinDialogVisible(false);
    const result = await LocalAuthentication.authenticateAsync({ requireConfirmation: false });
    if (result && result.success) {
      navigation.push("Devices_Places", { group });
    }
  };

  const handleOKwrongPin = () => {
    setWrongPinDialogVisible(false);
  };

  return (
    <ComponentContainerTouchable
      onPress={() => {
        if ((group.get("params") & 0x040) > 0 || (group.get("params") & 0x020) > 0) {
          setPin("");
          setPinDialogVisible(true);
        } else {
          navigation.push("Devices_Places", { group });
        }
      }}
      containerStyle={fromDevices && { width: "100%" }}
      style={{ alignItems: "center", height: dimensions.get("smallTiles") && fromDevices ? 55 : dimensions.get("smallTiles") ? 100 : undefined }}
    >
      <View
        style={{
          flexDirection: dimensions.get("smallTiles") && !fromDevices ? "column" : "row",
          alignItems: "center",
          flex: dimensions.get("smallTiles") ? 1 : undefined,
        }}
      >
        <View
          style={{
            backgroundColor: bgColor,
            height: dimensions.get("smallTiles") && fromDevices ? 40 : dimensions.get("smallTiles") ? 80 : isBig ? 60 : 50,
            width: dimensions.get("smallTiles") && fromDevices ? 40 : dimensions.get("smallTiles") ? 80 : isBig ? 60 : 50,
            borderRadius: isBig ? 120 : 100,
            justifyContent: "center",
            alignItems: "center",
            margin: -5,
          }}
        >
          <IconBadge group={group} isBig={false}>
            {base64Icon && <ComponentImage isBig={dimensions.get("smallTiles") ? false : isBig} source={{ uri: base64Icon }} />}
          </IconBadge>
        </View>

        <ComponentHeaderText
          style={{
            paddingLeft: dimensions.get("smallTiles") && fromDevices ? 20 : dimensions.get("smallTiles") ? 0 : 20,
            paddingTop: dimensions.get("smallTiles") && !fromDevices && bgColor ? 5 : 0,
          }}
        >
          {overWriteName ? overWriteName : group.get("opis_menu")}
        </ComponentHeaderText>
      </View>
      {!dimensions.get("smallTiles") && (
        <StyledIcon name={I18nManager.isRTL ? "chevron-left" : "chevron-right"} color={"secondary"} size={isBig ? 40 : 24} />
      )}
      {pinDialogVisible && (
        <ModalSimple
          isVisible={pinDialogVisible}
          title={"AUTH"}
          description={"PASS_PIN"}
          placeholder={"PASS_PIN"}
          secureTextEntry={true}
          onInputChange={onPinInputHandler}
          keyboardType={"numeric"}
          onClose={handleClose}
          onOK={handleOK}
          showBiometrics={(group.get("params") & 0x040) > 0}
          onThirdOption={handleBiometrics}
        />
      )}
      {wrongPinDialogVisible && <ModalSimple isVisible={wrongPinDialogVisible} title={"AUTH"} description={"PIN_NOT"} onOK={handleOKwrongPin} />}
    </ComponentContainerTouchable>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    globalSettings: state.globalSettings,
    dimensions: state.statesData.get("dimensions"),
    profileSettings: state.profilesSettings.get(state.profilesSettings.get("currentProfile")),
    showSketchFirst: state.globalSettings.get("showSketchFirst"),
    group: state.smartHomeData.get(state.profilesSettings.get("currentProfile")).get("groups").get(ownProps.groupId),
  };
};

export default connect(mapStateToProps)(PlaceGroupComponent);
