import * as React from "react";

// function AmpioIcon(props) {
//   return (
//     <Svg width="50" height="50" viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg" {...props}>
//       <Circle cx="25" cy="24.922" r="22" fill={props.fill ? props.fill : "white"} strokeWidth="0" stroke="rgb(0,0,0)" />
//       <Svg width="40" height="40" viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg" {...props}>
//         <Path
//           d="M4.898 20.922a4.663 4.663 0 00-2.062.594 11.2 11.2 0 00-2.633 2.14 16.981 16.981 0 00.297 6.7 6.372 6.372 0 003.05 3.773 6.291 6.291 0 005.516.21 21.805 21.805 0 005.844-3.89c-.457-.754-.922-1.5-1.394-2.238-.676-.988-1.305-2.016-2.047-2.934A13.445 13.445 0 007.3 21.414a4.576 4.576 0 01-.293-.117 4.94 4.94 0 00-2.11-.375"
//           stroke="none"
//           fillRule="nonzero"
//           fill="#eca05c"
//           fillOpacity={1}
//           x="12"
//           y="12"
//         />
//         <Path
//           d="M18.844 0c-.594.012-1.188.066-1.77.172C9.797 1.832 4.227 10.512 1.957 16.77 1.168 19.027.273 21.313.172 23.723a13.317 13.317 0 013.043-3.563 5.749 5.749 0 014.234-1.113c.078.012.242.031.324.043a13.52 13.52 0 015.004 2.48c.93.649 1.77 1.414 2.649 2.133.91.797 1.793 1.625 2.68 2.445.234.223.472.442.706.665 2.364 2.082 4.715 4.433 7.149 6.039 1.117.8 2.352 1.414 3.664 1.82a6.288 6.288 0 006.504-2.121 8.547 8.547 0 001.559-4.133 20.878 20.878 0 00-1.188-9.566C33.547 10.789 27.402.129 18.844 0"
//           stroke="none"
//           fillRule="nonzero"
//           fill="#ec695c"
//           fillOpacity={1}
//           x="12"
//           y="12"
//         />
//       </Svg>
//     </Svg>
//   );
// }

function AmpioIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50">
      <circle cx="25" cy="24.922" r="22" fill="white" strokeWidth="0" stroke="rgb(0,0,0)" />
      <svg width="40" height="40" viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg">
        <g transform="translate(12, 11.5)">
          <path
            fill="#eda15c"
            d="M4.898 20.922a4.663 4.663 0 00-2.062.594 11.2 11.2 0 00-2.633 2.14 16.981 16.981 0 00.297 6.7 6.372 6.372 0 003.05 3.773 6.291 6.291 0 005.516.21 21.805 21.805 0 005.844-3.89c-.457-.754-.922-1.5-1.394-2.238-.676-.988-1.305-2.016-2.047-2.934A13.445 13.445 0 007.3 21.414a4.576 4.576 0 01-.293-.117 4.94 4.94 0 00-2.11-.375"
          />
          <path
            fill="#ed695c"
            d="M18.844 0c-.594.012-1.188.066-1.77.172C9.797 1.832 4.227 10.512 1.957 16.77 1.168 19.027.273 21.313.172 23.723a13.317 13.317 0 013.043-3.563 5.749 5.749 0 014.234-1.113c.078.012.242.031.324.043a13.52 13.52 0 015.004 2.48c.93.649 1.77 1.414 2.649 2.133.91.797 1.793 1.625 2.68 2.445.234.223.472.442.706.665 2.364 2.082 4.715 4.433 7.149 6.039 1.117.8 2.352 1.414 3.664 1.82a6.288 6.288 0 006.504-2.121 8.547 8.547 0 001.559-4.133 20.878 20.878 0 00-1.188-9.566C33.547 10.789 27.402.129 18.844 0"
          />
        </g>
      </svg>
    </svg>
  );
}

export default AmpioIcon;
