import React, { Component } from "react";
import { Text, View, TouchableOpacity, StyleSheet } from "react-native";
import { t } from "../../services/i18n";

import PickerSelect from "../../components/UI/PickerSelect";
import {
  StyledIcon,
  PrimaryColorText,
  ComponentContainer,
} from "../../components/UI/styledComponents";

import { ThemeContext } from "../../../ThemeContext";

let hrs = [];
for (let i = 0; i <= 48; i++) {
  let label = i % 2 == 0 ? i / 2 + ".00" : (i + 1) / 2 - 1 + ".30";
  if (label < 10) {
    label = "0" + label;
  }
  let value = i / 2;
  let element = {
    label: label,
    value: value,
  };
  hrs.push(element);
}

class HeatingHarmonogramSingle extends Component {
  static contextType = ThemeContext;

  state = {
    daysArray: [
      {
        value: 1,
        label: t("weekdays:MON"),
        active: false,
      },
      {
        value: 2,
        label: t("weekdays:TUE"),
        active: false,
      },
      {
        value: 3,
        label: t("weekdays:WED"),
        active: false,
      },
      {
        value: 4,
        label: t("weekdays:THU"),
        active: false,
      },
      {
        value: 5,
        label: t("weekdays:FRI"),
        active: false,
      },
      {
        value: 6,
        label: t("weekdays:SAT"),
        active: false,
      },
      {
        value: 0,
        label: t("weekdays:SUN"),
        active: false,
      },
    ],
    schedule: this.props.schedule,
  };

  componentDidMount() {
    let days = this.state.daysArray.map((day, index) => {
      day.active =
        this.props.schedule.dayMask & (1 << day.value) ? true : false;
      return day;
    });

    this.setState({
      daysArray: days,
    });
  }

  onScheduleChange = (sendValue, key) => {
    let schedule = Object.assign({}, this.state.schedule); //creating copy of object in state

    if (key == "s" || key == "e") {
      schedule[key] = sendValue;
    } else if (key == "dayMask") {
      let daysArray = [...this.state.daysArray];
      let indexToChange = this.state.daysArray.findIndex(
        (el) => el.value == sendValue
      );

      daysArray[indexToChange].active = !daysArray[indexToChange].active;

      let newDayMask = 0;

      daysArray.forEach((el) => {
        if (el.active) {
          newDayMask |= 1 << el.value;
        }
      });

      schedule.dayMask = newDayMask;
    }

    this.setState({ schedule }, () => {
      this.props.onScheduleChange(this.state.schedule, this.props.index);
    });
  };

  render() {
    let { schedule, daysArray } = this.state;
    let days = daysArray.map((day, index) => {
      return (
        <TouchableOpacity
          onPress={() => this.onScheduleChange(day.value, "dayMask")}
          key={index}
          style={{
            flex: 1,
            alignItems: "center",
          }}
        >
          <PrimaryColorText
            style={{
              paddingBottom: 2,
            }}
          >
            {day.label}
          </PrimaryColorText>
          {day.active ? (
            <StyledIcon name={"check"} color={"primary"} size={20} />
          ) : (
            <StyledIcon name={"x"} color={"secondary"} size={20} />
          )}
        </TouchableOpacity>
      );
    });
    const { theme } = this.context;

    return (
      <View
        style={{
          backgroundColor: theme.COMPONENT_BACKGROUND_COLOR,
          paddingTop: 10,
          paddingHorizontal: 10,
          marginHorizontal: 3,
          marginTop: 3,
          borderWidth: 1,
          borderColor: "gray",
        }}
      >
        <View style={styles.row}>
          <PrimaryColorText
            style={{
              fontWeight: "bold",
              marginRight: 10,
              width: 50,
            }}
          >
            {t("TIME")}:
          </PrimaryColorText>

          <View
            style={{
              flex: 1,
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <View
              style={{
                width: "47%",
              }}
            >
              <PickerSelect
                placeholder={{
                  label: t("scenes:LAUNCH_TIME"),
                  value: null,
                }}
                additionalTextStyle={{ fontSize: 12 }}
                items={hrs}
                onValueChange={(e) => this.onScheduleChange(e, "s")}
                value={schedule.s}
              />
            </View>
            <PrimaryColorText style={{ paddingHorizontal: "3%" }}>
              -
            </PrimaryColorText>
            <View
              style={{
                width: "47%",
                marginRight: "6%",
              }}
            >
              <PickerSelect
                placeholder={{
                  label: t("scenes:END_TIME"),
                  value: null,
                }}
                additionalTextStyle={{ fontSize: 12 }}
                items={hrs}
                onValueChange={(e) => this.onScheduleChange(e, "e")}
                value={schedule.e}
              />
            </View>
          </View>
        </View>

        <View style={styles.row}>
          <PrimaryColorText
            style={{
              fontWeight: "bold",
              marginRight: 10,
              width: 50,
            }}
          >
            {t("DAY")}:
          </PrimaryColorText>
          <View style={styles.days}>{days}</View>
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  // row: {
  // 	flexDirection: "row",
  // 	alignItems: "center",
  // 	justifyContent: "space-between",
  // 	margin: 5,
  // 	borderWidth: 0.3,
  // 	borderColor: "gray",
  // 	padding: 5
  // },
  days: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    flex: 1,
  },
  row: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 10,
  },
  row2: {
    // flex: 1,
    flexDirection: "row",
    alignItems: "center",
    // justifyContent: "center"
  },
  hourText: {
    fontWeight: "bold",
    fontSize: 16,
    margin: 6,
  },
  dayText: {
    fontSize: 12,
    color: "white",
  },
  dayCircle: {
    width: 26,
    height: 26,
    borderRadius: 13,
    margin: 2,
    // borderWidth: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
});
export default HeatingHarmonogramSingle;
