import React, { Component, useEffect, useState } from "react";
import { Text, View, FlatList, Button, TouchableOpacity, StyleSheet, ScrollView, Keyboard, Platform } from "react-native";

import { createSelector } from "reselect";
import { changeSoundStatus, changeSelectedSoruces, receivedDetectedOutputs } from "../../store/actions";
import { t } from "../../services/i18n";
import { Feather } from "@expo/vector-icons";
import { connect } from "react-redux";
import { SliderStyled } from "../../components/UI/styledComponents";
import mqttClient from "../../services/mqtt";
import { store } from "../../store/configureStore";
import { StyledIcon, TitleWithLines, PrimaryColorText } from "../../components/UI/styledComponents";
import { ScreenView } from "../../components/UI/screenViewX";
import { useTheme } from "../../../ThemeContext";

const SourceChooseScreen = ({
  navigation,
  devicesArray,
  changeSelectedSoruces,
  selectedSoruces,
  embedded,
  detectedOutputs,
  receivedDetectedOutputs,
  serverInfo,
  queueInfo,
  playerInfo,
  selected,
  setSelected,
}) => {
  const { theme } = useTheme();

  //console.log(detectedOutputs);

  const [selected2, setSelected2] = useState({});

  if (!selected) selected = selected2;
  if (!setSelected) setSelected = setSelected2;

  const _closeScreen = (clean) => {
    if (clean) {
      changeSelectedSoruces({});
    } else {
      changeSelectedSoruces(selected);
    }
    const status = store.getState().statesData.get("soundStatus");

    if (status) {
      Object.keys(selected).map((key) => {
        if (key !== "local") {
          if (selected[key] === true) {
            let message = `/api/set/${key}/setIpRadioUri/${status.get("uri")}`;

            mqttClient.stateChangeToSend(message, key);
          } else if (selected[key] === false) {
            let message = `/api/set/${key}/setIpRadioButton/pause`;
            mqttClient.stateChangeToSend(message, key);
          }
        }
      });
      detectedOutputs &&
        detectedOutputs.forEach((device) => {
          if (device.selected) {
            const nowPlaying = playerInfo && queueInfo && queueInfo.items ? queueInfo.items.find((item) => item.id === playerInfo.item_id) : null;
            //const nowPlaying = queueInfo && queueInfo.items ? queueInfo.items.find((item) => item.position >= 0) : null;
            if (!(nowPlaying && nowPlaying.uri === status.get("uri"))) {
              mqttClient.requestOt("playUri", "POST", "/api/queue/items/add?playback=start&shuffle=false&uris=" + status.get("uri"));
            }
          }
        });
    }

    Keyboard.dismiss();
    navigation.goBack(null);
  };

  const getOutputs = async () => {
    mqttClient.requestOt("otPlayer", "GET", `/api/player`);
    mqttClient.requestOt("otQueue", "GET", `/api/queue`);
    mqttClient.requestOt("otOutputs", "GET", `/api/outputs`);
  };

  useEffect(() => {
    setSelected2(selectedSoruces);
    getOutputs();
  }, []);

  const autodetectedContent =
    detectedOutputs &&
    detectedOutputs
      .filter((dev) => dev.type.startsWith("AirPlay") || dev.type === "Chromecast")
      .filter((dev) => !dev.requires_auth)
      .map((device) => {
        return (
          <View
            style={{
              flexDirection: "column",
              flex: 1,
              marginHorizontal: 10,
              opacity: !device.selected ? 0.5 : 1.0,
            }}
            key={device.id + "view"}
          >
            <TouchableOpacity
              style={{
                padding: 10,
                flexDirection: "row",
                flex: 1,
                alignItems: "center",

                // justifyContent: "space-between",
              }}
              onPress={async () => {
                if (device.selected) {
                  mqttClient.requestOt("set", "PUT", `/api/outputs/${device.id}`, { selected: false });
                  receivedDetectedOutputs(detectedOutputs.map((dev) => (dev.id === device.id ? { ...dev, selected: false } : dev)));
                } else {
                  mqttClient.requestOt("set", "PUT", `/api/outputs/${device.id}`, { selected: true });
                  receivedDetectedOutputs(detectedOutputs.map((dev) => (dev.id === device.id ? { ...dev, selected: true } : dev)));

                  if (selectedSoruces["local"]) {
                    const nowPlaying =
                      playerInfo && queueInfo && queueInfo.items ? queueInfo.items.find((item) => item.id === playerInfo.item_id) : null;
                  }
                }
              }}
            >
              <Feather name={device.selected ? "check-circle" : "circle"} color={device.selected ? "rgb(50, 200, 80)" : "#ccc"} size={22} />
              <PrimaryColorText style={{ marginHorizontal: 10, fontSize: 18 }}>{device.name}</PrimaryColorText>
              {device.type === "Chromecast" && <StyledIcon name={"cast"} size={15} />}
              {device.type && device.type.startsWith("AirPlay") && <StyledIcon name={"airplay"} size={15} />}
            </TouchableOpacity>

            <View
              key={device.id + "slider"}
              style={{
                flexDirection: "row",
                flex: 1,
                marginHorizontal: 10,
              }}
            >
              <SliderStyled
                value={Number(device.volume)}
                minimumValue={0}
                maximumValue={100}
                onSlidingComplete={(value) => {
                  mqttClient.requestOt("set", "PUT", `/api/outputs/${device.id}`, { volume: value });
                }}
              />
            </View>
          </View>
        );
      });

  const itemSelected = (item, index) => {
    let copy = { ...selected };
    if (copy[item.get("id")]) {
      copy[item.get("id")] = !copy[item.get("id")];
    } else {
      copy[item.get("id")] = true;
    }

    setSelected(copy);
  };

  const localSourceSelected = (id, value) => {
    let copy = { ...selected };
    if (value !== undefined) {
      copy[id] = value;
    } else {
      if (copy[id]) {
        copy[id] = !copy[id];
      } else {
        copy[id] = true;
      }
    }

    setSelected(copy);
    return copy[id];
  };

  const onSlidingCompleteHandler = (volume, device) => {
    let message = `/api/set/${device.get("id")}/setVolume/${volume}`;
    mqttClient.stateChangeToSend(message, device.get("id"));
  };

  let content = (
    <ScrollView style={{ width: "100%", flex: 1 }}>
      {devicesArray.map((item, index) => {
        let state = 0;
        if (item.get("id") && store.getState().statesData.get("states").get(item.get("id"))) {
          state = Number(store.getState().statesData.get("states").get(item.get("id")).get("state"));
        }

        return (
          <View
            key={item.get("id")}
            style={{
              flexDirection: "column",
              flex: 1,
            }}
          >
            <TouchableOpacity
              style={{
                padding: 10,
                flexDirection: "row",
                flex: 1,
                alignItems: "center",
                // justifyContent: "space-between",
              }}
              onPress={() => itemSelected(item, index)}
            >
              <Feather
                name={selected[item.get("id")] ? "check-circle" : "circle"}
                color={selected[item.get("id")] ? "rgb(50, 200, 80)" : "#ccc"}
                size={22}
              />
              <PrimaryColorText style={{ marginLeft: 10, fontSize: 18 }}>{item.get("opis_menu")}</PrimaryColorText>
            </TouchableOpacity>
            <View
              style={{
                flexDirection: "row",
                flex: 1,
                marginHorizontal: 10,
              }}
            >
              <SliderStyled
                value={state}
                maximumValue={item && item.get("param1") == 3 ? 60 : 100}
                onSlidingComplete={(value) => onSlidingCompleteHandler(value, item)}
              />
            </View>
          </View>
        );
      })}
    </ScrollView>
  );

  const fullView = (
    <View style={{ width: "100%" }}>
      <View>
        {<TitleWithLines textInside={t("AUTODETECTED_DEVICES")} />}

        {autodetectedContent}

        {devicesArray && devicesArray.size > 0 && <TitleWithLines textInside={t("LOCAL_DEVICES")} />}
        {content}
        {/* {Platform.OS === "ios" ? (
          <View>
            <TitleWithLines textInside={t("AIRPLAY_DEVICES")} />
            <View flexDirection="row" style={{ alignItems: "center" }}>
              <AirplayButton
                prioritizesVideoDevices={true}
                tintColor={theme.BUTTON_BACKGROUND_COLOR_PRIMARY_DISABLED}
                activeTintColor={theme.BUTTON_BACKGROUND_COLOR_PRIMARY}
                style={{
                  width: 32,
                  height: 32,
                  marginLeft: 5,
                }}
              />
              <TouchableOpacity
                onPress={() => {
                  showRoutePicker({ prioritizesVideoDevices: true });
                }}
              >
                <PrimaryColorText
                  style={{
                    marginLeft: 5,
                    fontSize: 18,
                  }}
                >
                  {t("AIRPLAY_OR_BLUETOOTH")}
                </PrimaryColorText>
              </TouchableOpacity>
            </View>
          </View>
        ) : null} */}
      </View>
    </View>
  );

  useEffect(() => {
    navigation.setOptions({
      headerLeft:
        serverInfo && serverInfo.get("owntone") == "1"
          ? () => (
              <TouchableOpacity
                style={{ padding: 10 }}
                onPress={() => {
                  mqttClient.requestOt("otOutputs", "GET", `/api/outputs`);
                }}
              >
                <StyledIcon name={"refresh-cw"} color={"tabBar"} size={24} />
              </TouchableOpacity>
            )
          : null,
      headerRight: () => (
        <TouchableOpacity
          style={{ padding: 10 }}
          onPress={() => {
            _closeScreen();
          }}
        >
          <StyledIcon name={"x"} color={"tabBar"} size={24} />
        </TouchableOpacity>
      ),
    });
  }, [selected]);

  return embedded ? fullView : <ScreenView>{fullView}</ScreenView>;
};

const selectDevices = (state) => state;

const devices = createSelector(selectDevices, (smartHomeData) => {
  const filteredItems = smartHomeData.filter((device) => {
    if (device) {
      return device.get("typ_komponentu") === "ip_radio";
    } else {
      return false;
    }
  });
  return filteredItems;
});

const mapStateToProps = (state) => {
  let currentProfile = state.profilesSettings.get("currentProfile");

  let possibleDevices = devices(state.smartHomeData.get(currentProfile).get("devices"));

  return {
    expoSound: state.statesData.get("expoSound"),
    selectedSoruces: state.statesData.get("selectedSoruces"),
    detectedOutputs: state.statesData.get("detectedOutputs"),
    serverInfo: state.statesData.get("server").get("serverInfo"),
    queueInfo: state.statesData.get("queueInfo"),
    playerInfo: state.statesData.get("playerInfo"),
    devicesArray: possibleDevices,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeSoundStatus: (status) => dispatch(changeSoundStatus(status)),
    changeSelectedSoruces: (status) => dispatch(changeSelectedSoruces(status)),
    receivedDetectedOutputs: (status) => dispatch(receivedDetectedOutputs(status)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SourceChooseScreen);
