import React, { Component, useContext } from "react";
import { Text, View, TouchableOpacity, StyleSheet } from "react-native";
import { Buffer } from "buffer";
import { useTheme } from "../../../../ThemeContext";

import {
	StyledIcon,
	SecondaryColorText,
	PrimaryColorText,
} from "../../UI/styledComponents";

const RadioButtonsBar = (props) => {
	const { theme } = useTheme();

	const onAction = (action) => {
		// change frequency of radio,
		// 1 - move freq upper,
		// 2 - move freq lower,
		// 4 - autofind radio upper,
		// 5 - autofind radio lower
		props.onActionFromButtons(action);
	};
	const { readOnly } = props;
	return (
		<View
			style={{
				// width: "100%",
				borderRadius: 17,
				height: 34,
				borderColor: theme.PLACEHOLDER_BACKGROUND_COLOR,
				backgroundColor: theme.COMPONENT_BACKGROUND_COLOR_SECONDARY,
				borderWidth: 1,
				flexDirection: "row",
				justifyContent: "space-between",
				alignItems: "center",
				paddingLeft: 10,
				paddingRight: 10,
				// flex: 1
			}}
		>
			<View
				style={{
					flexDirection: "row",
					justifyContent: "space-between",
					width: 105,
				}}
			>
				<TouchableOpacity onPress={() => onAction("5")} disabled={readOnly}>
					<StyledIcon name={"skip-back"} color={"secondary"} size={22} />
				</TouchableOpacity>
				<TouchableOpacity onPress={() => onAction("2")} disabled={readOnly}>
					<StyledIcon
						name={"play"}
						style={{ transform: [{ rotate: "180deg" }] }}
						color={"secondary"}
						size={22}
					/>
				</TouchableOpacity>
				<TouchableOpacity
					onPress={() => onAction("showFavouritesListDialog")}
					disabled={readOnly}
				>
					<StyledIcon name={"list"} color={"secondary"} size={22} />
				</TouchableOpacity>
			</View>
			<View
				style={{
					alignItems: "center",
				}}
			>
				<SecondaryColorText
					style={{
						fontSize: 14,
					}}
				>
					{props.freq}
				</SecondaryColorText>

				<PrimaryColorText style={{ fontSize: 14 }}>
					{Buffer.from(props.rds, "base64").toString("ascii")}
				</PrimaryColorText>
			</View>
			<View
				style={{
					flexDirection: "row",
					justifyContent: "space-between",
					width: 105,
				}}
			>
				<TouchableOpacity
					onPress={() => onAction("showAddFavouriteStationDialog")}
					disabled={readOnly}
				>
					<StyledIcon name={"heart"} color={"secondary"} size={22} />
				</TouchableOpacity>
				<TouchableOpacity onPress={() => onAction("1")} disabled={readOnly}>
					<StyledIcon name={"play"} color={"secondary"} size={22} />
				</TouchableOpacity>
				<TouchableOpacity onPress={() => onAction("4")} disabled={readOnly}>
					<StyledIcon name={"skip-forward"} color={"secondary"} size={22} />
				</TouchableOpacity>
			</View>
		</View>
	);
};

export default RadioButtonsBar;
