import React, { Component } from "react";
import { View, Image, AppState, TouchableOpacity } from "react-native";
import { useIsFocused } from "@react-navigation/native";
import mqttClient from "../../../services/mqtt";
import { connect } from "react-redux";
import { StyledIcon } from "../../UI/styledComponents";
import { SecondaryColorText } from "../../UI/styledComponents";
import { t } from "../../../services/i18n";
class CameraComponentX extends Component {
  state = {
    cameraImage: "",
    width: 0,
    height: 0,
    showImage: false,
    appState: AppState.currentState,
  };

  askForTheNewImage = 0;

  //dobrze
  // static getDerivedStateFromProps(nextProps, prevState) {
  // 	//if exist and if it is not 'same'
  // 	if (
  // 		nextProps.deviceState &&
  // 		nextProps.deviceState.get("cameraImage") &&
  // 		nextProps.deviceState.get("cameraImage").length > 5
  // 	) {
  // 		return { cameraImage: nextProps.deviceState.get("cameraImage") };
  // 	}
  // 	return null;
  // }

  //dobrze
  componentDidMount() {
    mqttClient.askForCamera(this.props.device.get("id"), true);
  }

  //should component update muszę olać, bo jesli zablokuję to nigdy mi nie wejdzie w didupdate i nie zapyta się o state
  shouldComponentUpdate(nextProps, nextState) {
    // if coming back from offline
    //if coming back from different screen

    //wymuś update na błędnym obrazie
    if (this.state.showImage != nextState.showImage) {
      return true;
    } else if (this.state.cameraImage != nextState.cameraImage) {
      return true;
    }
    return false;

    // else if (
    // 	nextProps.deviceState &&
    // 	nextProps.deviceState.get("cameraImage") == "same" &&
    // 	this.props.deviceState &&
    // 	this.props.deviceState.get("cameraImage") == "same"
    // ) {
    // 	return false;
    // }
    // return true;
  }

  //only for the first time
  // componentDidUpdate(prevProps) {
  // 	//if coming back from bg, then ask one more time
  // 	//zastanow się czy Ci to potrzebne
  // 	// if (
  // 	// 	prevProps.deviceState &&
  // 	// 	this.props.deviceState &&
  // 	// 	prevProps.deviceState.get("cameraImage") !=
  // 	// 		this.props.deviceState.get("cameraImage")
  // 	// ) {
  // 	// 	this.setState({ showImage: true });
  // 	// }
  // 	// calculate for the first time height and width, then the same camera so the same values

  // }

  //Image get size służy w tym wypadku to prefetchingu, wg dokumentacji, może to zostac zmeinione za jakiś czas
  //na androidzie, gdy nie ma prefetchu pojawia się białe miganie podczas ładowania
  //gdy chciałam tego użyć w statuc getDer... lub w shouldComponentUpdate,
  //Image.getSize, zwraca Promise i nie umiałam ogarnąć jak zrobić żeby render był call wtedy iedy resolve promise

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { gretingViewHeight, customWidth, params_device, dimensions } = this.props;
    let width = dimensions.get("width");
    let columnWidth = dimensions.get("columnWidth");

    let refreshTime = 500;
    if (params_device && params_device.get("czas")) refreshTime = params_device.get("czas");

    if (
      (nextProps.isFocused && this.props.connectionStatus == 0 && nextProps.connectionStatus == 1) ||
      (!this.props.isFocused && nextProps.isFocused)
    ) {
      mqttClient.askForCamera(this.props.device.get("id"));
    } else if (
      //z tym nie pytało po wejsciu z bg bo initial
      // nextProps.deviceState &&
      // nextProps.deviceState.get("cameraImage") &&
      this.askForTheNewImage == 0 &&
      nextProps.isFocused
    ) {
      this.askForTheNewImage = setTimeout(() => {
        mqttClient.askForCamera(this.props.device.get("id"));
        this.askForTheNewImage = 0;
      }, refreshTime);
    }

    if (
      nextProps.deviceState &&
      nextProps.deviceState.get("cameraImage") &&
      nextProps.deviceState.get("cameraImage").length > 5 &&
      nextProps.deviceState.get("cameraImage") != this.state.cameraImage
    ) {
      let cameraImage = nextProps.deviceState.get("cameraImage");
      let myUri = `data:image/png;base64,${cameraImage}`;

      if (customWidth || dimensions.get("smallTiles")) {
        columnWidth = width;
      }
      let imageWidth = gretingViewHeight ? width / 2 - 20 : columnWidth - 20;
      Image.getSize(
        myUri,
        (width, height) => {
          let imageHeight = (imageWidth * height) / width;
          this.setState({
            cameraImage: cameraImage,
            width: imageWidth,
            height: imageHeight,
            showImage: true,
          });
        },
        () => {
          this.setState({
            cameraImage: cameraImage,
            showImage: false,
            width: 0,
            height: 0,
          });
        }
      );
    }
  }

  _onError = (err) => {
    this.setState({ showImage: false });
  };

  componentWillUnmount() {
    if (this.askForTheNewImage) {
      clearTimeout(this.askForTheNewImage);
      this.askForTheNewImage = 0;
    }
  }

  refresh = () => {
    mqttClient.askForCamera(this.props.device.get("id"));
  };

  render() {
    const { device, header, deviceState, gretingViewHeight } = this.props;
    let { width, height, showImage, cameraImage } = this.state;
    let imageToRender =
      showImage && cameraImage ? (
        <Image
          style={{ width, height }}
          onError={({ nativeEvent: { error } }) => this._onError(error)}
          source={{ uri: `data:image/png;base64,${cameraImage}` }}
        />
      ) : (
        <StyledIcon name={"video-off"} color={"secondary"} size={50} />
      );

    return (
      <View>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            paddingBottom: 0,
            height: 30,
          }}
        >
          {header}
          <TouchableOpacity onPress={this.refresh}>
            <SecondaryColorText style={{ fontStyle: "italic" }}>{t("REFRESH")}</SecondaryColorText>
          </TouchableOpacity>
        </View>
        <View
          style={{
            justifyContent: "center",
            alignItems: "center",
            paddingTop: 10,
          }}
        >
          {imageToRender}
        </View>
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    connectionStatus: state.statesData.get("connectionStatus"),
    dimensions: state.statesData.get("dimensions"),
    sideMenuSize: state.globalSettings.get("sideMenuSize"),
    sideMenuShown: state.globalSettings.get("sideMenuShown"),
    noConnectionCriticalError: state.statesData.get("noConnectionCriticalError"),
  };
};

const CameraComponent = (props) => {
  const isFocused = useIsFocused();

  return <CameraComponentX {...props} isFocused={isFocused} />;
};

export default connect(mapStateToProps, null)(CameraComponent);
