import React, { Component } from "react";
import { ScrollView, View } from "react-native";
import { connect } from "react-redux";

import DeviceListHandler from "../../../utils/smartHomeHandlers/DeviceListHandler";

class CustomCarouselComponent extends Component {
  render() {
    const { device, devices, header, params_device, source, dimmension } = this.props;
    let powiazane = params_device ? params_device.get("powiazane") : "";
    let powiazaneArrayWithPossibleCustom = powiazane ? powiazane.split(",") : [];
    let powiazaneArray = [];
    powiazaneArrayWithPossibleCustom.forEach((el, index) => {
      el = el.split(":").reverse();
      if (devices.get(el[0])) {
        devices.get(el[0]).get("typ_komponentu") != "custom" &&
          powiazaneArray.push({
            index: el[1] ? el[1] : index,
            objectID: el[0],
          });
      }
    });

    powiazaneArray.sort((a, b) => parseFloat(a.index) - parseFloat(b.index));

    let rows = powiazaneArray.map((el, index) => {
      let deviceIndex = Number(el.objectID);
      return (
        devices.get(deviceIndex) && (
          <View
            key={deviceIndex}
            style={{
              flex: 1,
              width: this.props.dimensions.get("columnWidth") * 0.85,
            }}
          >
            <DeviceListHandler device={devices.get(deviceIndex)} />
          </View>
        )
      );
    });

    // let rows = Array.from({ length: device.get("max") }, (v, indexRow) => {
    //   return (
    //     <View
    //       key={`row${indexRow}`}
    //       style={{
    //         display: "flex",
    //         flexDirection: "row",
    //         paddingTop: indexRow > 0 ? 10 : 0,
    //       }}
    //     >
    //       {Array.from({ length: device.get("min") }, (v, indexColumn) => {
    //         let arrayIndex = indexRow * device.get("min") + indexColumn;
    //         if (powiazaneArray[arrayIndex]) {
    //           let deviceIndex = Number(powiazaneArray[arrayIndex].objectID);

    //           return (
    //             devices.get(deviceIndex) && (
    //               <View
    //                 key={deviceIndex}
    //                 style={{
    //                   flex: 1,
    //                 }}
    //               >
    //                 <DeviceListHandler device={devices.get(deviceIndex)} />
    //               </View>
    //             )
    //           );
    //         }
    //       })}
    //     </View>
    //   );
    // });

    return (
      <ScrollView horizontal={true}>
        <View
          style={{
            flexDirection: "row",
          }}
        >
          {rows}
        </View>
      </ScrollView>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    devices: state.smartHomeData.get(state.profilesSettings.get("currentProfile")).get("devices"),
  };
};

const CustomCarousel = connect(mapStateToProps)(CustomCarouselComponent);
CustomCarousel.displayName = "CustomCarousel";
export default CustomCarousel;
