// CLOUD_CODE

let mainHost = "cloud.ampio.com";

let CLOUD_CONSTANTS = {
  //  client_id: "4a6627d4-8104-46fa-a830-16d56f1a11a7",

  client_id: "60d1a988-aad2-49ca-a761-785833f064e7", // WEB

  redirect_uri: "https://uni.ampio.com/", // WEB

  //  redirect_uri: "com.ampio.cloud.mobile.uni://", // expo
  //redirect_uri: "exp://93.175.130.38:19000/", // expo
  assosiationUrl: `https://${mainHost}/api/shepherd/v1/association`,
  deviceInfoUrl: `https://${mainHost}/api/shepherd/v1/device`,
  metaDataUrl: `https://${mainHost}/api/clerk/v1/me`,
  deviceAssociationInit: `https://${mainHost}/associate/`,
  deleteAccountUrl: `https://${mainHost}/profile/delete`,
  tokenUrl: `https://${mainHost}/oauth2/token`,
  localTokenUrl: `https://${mainHost}/api/shepherd/v1/authorization/check`,
  exchangeUrl: `https://${mainHost}/api/bearing/v1/exchange`,
  authorizeUrl: `https://${mainHost}/oauth2/authorize`,
  logoutUrl: `https://${mainHost}/oauth2/logout`,
  //authorizeUrl: `https://${mainHost}/oauth2/interactive`,
  consentUrl: `https://${mainHost}/consent/collect`,
  discovery: {
    authorizationEndpoint: `https://${mainHost}/oauth2/authorize`,
    tokenEndpoint: `https://${mainHost}/oauth2/token`,
  },
};

const updateHost = (newHostName) => {
  mainHost = newHostName;
  CLOUD_CONSTANTS.assosiationUrl = `https://${mainHost}/api/shepherd/v1/association`;
  CLOUD_CONSTANTS.deviceInfoUrl = `https://${mainHost}/api/shepherd/v1/device`;
  CLOUD_CONSTANTS.metaDataUrl = `https://${mainHost}/api/clerk/v1/me`;
  CLOUD_CONSTANTS.deviceAssociationInit = `https://${mainHost}/associate/`;
  CLOUD_CONSTANTS.deleteAccountUrl = `https://${mainHost}/profile/delete`;
  CLOUD_CONSTANTS.localTokenUrl = `https://${mainHost}/api/shepherd/v1/authorization/check`;
  CLOUD_CONSTANTS.exchangeUrl = `https://${mainHost}/api/bearing/v1/exchange`;
  CLOUD_CONSTANTS.tokenUrl = `https://${mainHost}/oauth2/token`;
  CLOUD_CONSTANTS.logoutUrl = `https://${mainHost}/oauth2/logout`;
  CLOUD_CONSTANTS.authorizeUrl = `https://${mainHost}/oauth2/interactive`;
  CLOUD_CONSTANTS.consentUrl = `https://${mainHost}/consent/collect`;
  CLOUD_CONSTANTS.discovery.authorizationEndpoint = `https://${mainHost}/oauth2/interactive`;
  CLOUD_CONSTANTS.discovery.tokenEndpoint = `https://${mainHost}/oauth2/token`;
};

export default CLOUD_CONSTANTS;
export { mainHost, updateHost };
