import React from "react";
import { View, Image, StyleSheet, InteractionManager, I18nManager, PanResponder, Dimensions } from "react-native";
import { LinearGradient } from "expo-linear-gradient";
import tinycolor from "tinycolor2";
import { t } from "../../../../services/i18n";

import FavouritesColors from "./FavouritesColors";
import ModalWithContent from "../../../UI/ModalWithContent";
import { PrimaryColorText } from "../../../UI/styledComponents";
import { connect } from "react-redux";

const fn = () => true;
class ColorPicker extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      color: { h: 0, s: 0, v: 0 },
      colorRGB: { r: 0, g: 0, b: 0 },
      pickerSize: null,
      sliderWidth: null,
    };
    this._pickerLayout = { width: 0, height: 0, x: 0, y: 0 };
    this._sliderLayout = { width: 0, height: 0, x: 0, y: 0 };
    this._pickerPage = { x: 0, y: 0 };
    this._sliderPage = { x: 0, y: 0 };
    this.intervalID = 0;
    this._isRTL = I18nManager.isRTL;
  }

  _createPanResponder = ({ onStart = fn, onMove = fn, onEnd = fn }) => {
    return PanResponder.create({
      onStartShouldSetPanResponder: () => true,
      onStartShouldSetPanResponderCapture: () => true,
      onMoveShouldSetPanResponder: () => true,
      onMoveShouldSetPanResponderCapture: () => true,
      // nie wiem czy to potrzebne
      onPanResponderTerminationRequest: () => true,

      onPanResponderGrant: ({ nativeEvent }) => {
        return onStart(
          {
            x: nativeEvent.pageX,
            y: nativeEvent.pageY,
          },
          "start"
        );
      },
      onPanResponderMove: ({ nativeEvent }) => {
        return onMove(
          {
            x: nativeEvent.pageX,
            y: nativeEvent.pageY,
          },
          "move"
        );
      },
      onPanResponderRelease: ({ nativeEvent }) => {
        return onEnd(
          {
            x: nativeEvent.pageX,
            y: nativeEvent.pageY,
          },
          "end"
        );
      },
    });
  };

  componentDidMount = () => {
    const { colorRGB } = this.props;
    let hsvColor = tinycolor(colorRGB).toHsv();

    this.setState({ color: hsvColor, colorRGB });

    this._pickerResponder = this._createPanResponder({
      onStart: this.handleColorChange,
      onMove: this.handleColorChange,
      onEnd: this.handleColorChange,
    });

    this._sliderResponder = this._createPanResponder({
      onStart: this.handleValueChange,
      onMove: this.handleValueChange,
      onEnd: this.handleValueChange,
    });
  };

  _onLayoutPicker = ({ nativeEvent }) => {
    this._pickerLayout = nativeEvent.layout;
    const { width, height } = this._pickerLayout;
    const pickerSize = Math.min(width, height);
    if (this.state.pickerSize !== pickerSize) {
      this.setState({ pickerSize });
    }

    setTimeout(() => {
      this.refs.pickerContainer &&
        this.refs.pickerContainer.measure((x, y, width, height, pageX, pageY) => {
          this._pickerPage = { x: pageX, y: pageY };
        });
    }, 500);
  };

  _onLayoutSlider = ({ nativeEvent }) => {
    this._sliderLayout = nativeEvent.layout;
    const sliderWidth = this._sliderLayout.width;
    if (this.state.sliderWidth !== sliderWidth) {
      this.setState({ sliderWidth });
    }
    InteractionManager.runAfterInteractions(() => {
      this.refs.slider &&
        this.refs.slider.measure((x, y, width, height, pageX, pageY) => {
          this._sliderPage = { x: pageX, y: pageY };
        });
    });
  };

  componentWillUnmount() {
    clearInterval(this.intervalID);
    this.intervalID = 0;
  }

  handleColorChange = ({ x, y }, option) => {
    const { v } = this.state.color;
    const marginHorizontal = (this._pickerLayout.width - this.state.pickerSize) / 2;
    const marginVertical = (this._pickerLayout.height - this.state.pickerSize) / 2;

    const relativeX = x - this._pickerPage.x - marginHorizontal;
    const relativeY = y - this._pickerPage.y - marginVertical;
    const h = this._computeHValue(relativeX, relativeY);
    const s = this._computeSValue(relativeX, relativeY);
    this.rgbColor = tinycolor({ h, s, v }).toRgb();
    if (option == "start") {
      this.intervalID = setInterval(() => {
        this.props.setColorInComponent(this.rgbColor);
      }, 100);
    } else if (option == "end") {
      clearInterval(this.intervalID);
      this.intervalID = 0;
      this.props.setColorInComponent(this.rgbColor);
    }

    let color = { h, s, v };
    this.setState({ color });
  };

  handleValueChange = ({ x }, option) => {
    const { h, s } = this.state.color;

    const relativeX = x - this._sliderPage.x;
    const value = relativeX / this._sliderLayout.width;
    let v = value;
    if (value < 0) {
      v = 0;
    } else if (value > 1) {
      v = 1;
    }

    let rgbColor = tinycolor({ h, s, v }).toRgb();
    if (option == "start") {
      this.intervalID = setInterval(() => {
        this.props.setColorInComponent(rgbColor);
      }, 100);
    } else if (option == "end") {
      clearInterval(this.intervalID);
      this.intervalID = 0;
      this.props.setColorInComponent(rgbColor);
    }

    let color = { h, s, v };
    this.setState({ color });
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!this.intervalID) {
      let hsvColor = tinycolor(nextProps.colorRGB).toHsv();

      if (
        this.props.modalVisible == false &&
        nextProps.modalVisible == true &&
        nextProps.colorRGB.r === 0 &&
        nextProps.colorRGB.g === 0 &&
        nextProps.colorRGB.b === 0
      ) {
        hsvColor.v = 1;
      }
      this.setState({ color: hsvColor, colorRGB: nextProps.colorRGB });
    }
  }

  _computeHValue = (x, y) => {
    const pickerRadius = this.state.pickerSize / 2;
    const dx = x - pickerRadius;
    const dy = y - pickerRadius;
    const rad = Math.atan2(dx, dy) + Math.PI + Math.PI / 2;
    return ((rad * 180) / Math.PI) % 360;
  };

  _computeSValue = (x, y) => {
    const pickerRadius = this.state.pickerSize / 2;
    const dx = x - pickerRadius;
    const dy = y - pickerRadius;
    const odleglosc = Math.sqrt(Math.pow(dx, 2) + Math.pow(dy, 2));
    const s = odleglosc / pickerRadius;
    return s >= 1 ? 1 : s;
  };

  _hValueToRad = (deg) => {
    const rad = (deg * Math.PI) / 180;
    return rad - Math.PI - Math.PI / 2;
  };

  render() {
    const { pickerSize, color, sliderWidth } = this.state;
    const { favouritesColors, deviceName, modalVisible, onAddFavouriteColor, onRemoveFavouriteColor, dimensions } = this.props;
    const { h, s, v } = color;
    const angle = this._hValueToRad(h);
    const selectedColor = tinycolor(color).toHexString();
    const indicatorColor = tinycolor(color).toHexString();

    let landscapeOrientation = dimensions.get("landscapeOrientation");

    const computed = makeComputedStyles({
      pickerSize,
      sliderWidth,
      selectedColor,
      indicatorColor,
      angle,
      saturation: s,
      value: v,
      isRTL: this._isRTL,
    });

    const sliderColorBeginning = tinycolor({ h, s, v: 0 }).toHexString();
    const sliderColorEnd = tinycolor({ h, s, v: 1 }).toHexString();

    const sliderHandlers = (this._sliderResponder && this._sliderResponder.panHandlers) || {};
    return (
      <View
        style={{
          flexDirection: landscapeOrientation ? "row" : "column",
          alignItems: "center",
          height: "90%",
        }}
      >
        <View
          style={{
            height: landscapeOrientation ? "100%" : "55%",
            width: landscapeOrientation ? "55%" : "90%",
          }}
        >
          <View ref="pickerContainer" style={styles.pickerContainer} onLayout={this._onLayoutPicker}>
            {!pickerSize ? null : (
              <View {...this._pickerResponder.panHandlers} style={[styles.picker, computed.picker]} collapsable={false}>
                <Image source={require("../../../../../assets/color-wheel.png")} resizeMode="contain" style={[styles.pickerImage]} />
                <View style={[styles.indicator, computed.pickerIndicator, computed.selectedColor]} />
              </View>
            )}
          </View>
        </View>

        <View
          style={{
            height: landscapeOrientation ? "100%" : "45%",
            width: landscapeOrientation ? "40%" : "90%",
            justifyContent: "center",
          }}
        >
          <PrimaryColorText
            style={{
              fontSize: 16,
            }}
          >
            {t("VALUE")}
          </PrimaryColorText>
          <View
            onLayout={this._onLayoutSlider}
            {...sliderHandlers}
            ref="slider"
            style={{
              alignItems: "center",
              justifyContent: "center",
              paddingVertical: 10,
            }}
          >
            <LinearGradient colors={[sliderColorBeginning, sliderColorEnd]} style={[computed.slider, styles.slider]} start={[0, 1]} end={[1, 0]} />

            <View style={[styles.indicator, computed.sliderIndicator, computed.selectedColor]} />
          </View>
          <FavouritesColors
            favouritesColors={favouritesColors}
            onAddFavouriteColor={onAddFavouriteColor}
            onRemoveFavouriteColor={onRemoveFavouriteColor}
            setColorInComponent={this.props.setColorInComponent}
          />
        </View>
      </View>
    );
  }
}

const makeComputedStyles = ({ indicatorColor, selectedColor, angle, saturation, value, pickerSize, sliderWidth, isRTL }) => {
  const indicatorPickerRatio = 0.09; // computed from picker image
  const indicatorSize = indicatorPickerRatio * pickerSize;
  const pickerPadding = indicatorSize / 3;
  const indicatorRadius = (pickerSize / 2 - indicatorSize / 2) * saturation;
  const pickerRadius = pickerSize / 2;
  const dx = Math.cos(angle) * indicatorRadius;
  const dy = Math.sin(angle) * indicatorRadius;
  return {
    picker: {
      padding: pickerPadding,
      width: pickerSize,
      height: pickerSize,
    },
    pickerIndicator: {
      top: pickerRadius + dx - indicatorSize / 2,
      [isRTL ? "right" : "left"]: pickerRadius + dy - indicatorSize / 2,
      width: indicatorSize,
      height: indicatorSize,
      borderRadius: indicatorSize / 2,
    },
    slider: {
      padding: indicatorSize / 2,
      borderRadius: indicatorSize / 2,
    },
    sliderIndicator: {
      top: 11,
      [isRTL ? "right" : "left"]: sliderWidth * value - indicatorSize / 2,
      width: indicatorSize - 2,
      height: indicatorSize - 2,
      borderRadius: indicatorSize / 2,
    },
    selectedColor: {
      backgroundColor: selectedColor,
    },
  };
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#ecf0f1",
  },
  paragraph: {
    margin: 24,
    fontSize: 18,
    fontWeight: "bold",
    textAlign: "center",
    color: "#34495e",
  },

  pickerContainer: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },

  pickerImage: {
    flex: 1,
    width: null,
    height: null,
  },
  indicator: {
    position: "absolute",
    // Shadow only works on iOS.
    shadowColor: "black",
    shadowOpacity: 0.3,
    shadowOffset: { width: 3, height: 3 },
    shadowRadius: 4,
    borderColor: "white",
    borderWidth: 0.7,

    // This will elevate the view on Android, causing shadow to be drawn.
    elevation: 5,
  },
  slider: {
    alignItems: "center",
    width: "100%",
  },
  selectedColorPreview: {
    width: 30,
    height: 30,
    borderRadius: 15,
  },
});

const mapStateToProps = (state, ownProps) => {
  return {
    dimensions: state.statesData.get("dimensions"),
  };
};

export default connect(mapStateToProps, null)(ColorPicker);
