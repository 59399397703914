import React from "react";
import { ButtonPrimary } from "../../components/UI/styledComponents";

import { View, Text, TouchableOpacity, TextInput, StyleSheet, ScrollView, Platform } from "react-native";
import { fetchAccessToken, checkTokenNeedRefresh, refreshAccessToken, fetchAssociations, fetchMetaData } from "./CloudTools";
import * as Device from "expo-device";
import { connect } from "react-redux";
import moment from "moment";
import { t } from "../../services/i18n";
import { store, persistor } from "../../store/configureStore";
import {
  associationsDataReceived,
  iceServersReceived,
  authDataReceived,
  metaDataReceived,
  saveProfile,
  changeConsentsTime,
  changeConsentsStatus,
} from "../../store/actions";
import ModalWithContent from "../../components/UI/ModalWithContent";
import CLOUD_CONSTANTS from "../../../CloudConstants";
import { ResponseType, useAuthRequest } from "expo-auth-session";

import * as WebBrowser from "expo-web-browser";
import * as Linking from "expo-linking";
import mqttClient from "../../services/mqtt";
import { useTheme } from "../../../ThemeContext";
const CloudIntegration = (props) => {
  const { theme } = useTheme();
  const { authData, profiles, device_id, source, onOK, profile, askForConsents } = props;
  const [associations, setAssocations] = React.useState(null);
  const [loginData, setLoginData] = React.useState({ login: "", password: "" });
  const [chosenDeviceId, setChosenDeviceId] = React.useState("");

  const [modalVisible, setModalVisible] = React.useState(false);
  React.useState(askForConsents);
  const [action, setAction] = React.useState("");
  const [response, setResponse] = React.useState("");

  let deviceDesc = Device.deviceName ? Device.deviceName : "UNKNOWN";
  let deviceName = Device.modelName ? Device.modelName : Platform.OS;

  const [request, authResponse, promptAsync] = useAuthRequest(
    {
      clientId: CLOUD_CONSTANTS.client_id,
      response_type: ResponseType.Code,
      redirectUri: CLOUD_CONSTANTS.redirect_uri + "redirect",
      scopes: ["offline_access"],
      usePKCE: false,
      extraParams: {
        "metaData.device.name": deviceDesc + " - " + deviceName,
        "metaData.device.type": window.app?.getType() || "MOBILE",
      },
    },
    CLOUD_CONSTANTS.discovery
  );

  React.useEffect(() => {
    if (authResponse?.type === "success") {
      const { code } = authResponse.params;
      handleAuthCode(code);
    }
  }, [authResponse]);

  const _addLinkingListener = () => {
    Linking.addEventListener("url", _handleRedirect);
  };

  const _removeLinkingListener = () => {
    if (Linking.removeEventListener) {
      Linking.removeEventListener("url", _handleRedirect);
    }
  };

  const _handleRedirect = (event) => {
    if (Platform.OS === "ios") {
      WebBrowser.dismissBrowser();
    } else {
      _removeLinkingListener();
    }
    let data = Linking.parse(event.url);

    if (data.hostname === "associate" && data.queryParams.associated === "True") {
      window.app.showToast(t("cloud:ASSOCIATION_ADD_OK"));
    } else if (data.hostname === "associate") {
      window.app.showToast(t("cloud:ASSOCIATION_ADD_ERR"));
    } else if (data.path === "consent" && data.queryParams.status === "ok" && authData) {
      handleConsents(authData.toJS());
    } else if (data.path === "consent" && authData) {
      window.app.showToast(t("cloud:CONSENT_ERR"));
      handleConsents(authData.toJS());
    }

    if (data.hostname === "associate" && authData) {
      fetchAssociations(authData.toJS());
    }
  };

  const handleConsents = async (accessData, retry) => {
    if (accessData && accessData.access_token) {
      let metaData = await fetchMetaData(accessData);
      if (metaData && metaData.user && metaData.user.requiredConsentsGranted === true) {
        store.dispatch(changeConsentsTime(moment().unix()));
        store.dispatch(changeConsentsStatus(0));
        handleCloudConsentOk(accessData);
      } else if (metaData && metaData.user && metaData.user.requiredConsentsGranted === false) {
        store.dispatch(changeConsentsStatus(1));
        if (!retry && source !== "dashboard") {
          let consentUrl = `${CLOUD_CONSTANTS.consentUrl}?access_token=${accessData.access_token}&redirect_uri=${CLOUD_CONSTANTS.redirect_uri}consent`;
          setTimeout(async () => {
            await WebBrowser.openBrowserAsync(consentUrl);
            handleConsents(accessData, true);
          }, 1000);
        } else {
          handleCloudConsentOk(accessData);
        }
        console.log("Consents need to be signed!");
      } else {
        console.log("Missing consents!");
      }
    } else {
      console.log("No access_token to fetch meta data");
    }
  };

  const handleCloudConsentOk = async (accessData) => {
    if (device_id) {
      _addLinkingListener();
      mqttClient.get_association_session();
    } else {
      props.associationsDataReceived(null);
      let associationsData = await fetchAssociations(accessData);
      if (associationsData) {
        setAssocations(associationsData.associations);
      }
      if (props.goToProfiles) {
        props.goToProfiles();
      }
    }
  };

  const handleAuthCode = async (code) => {
    let accessData = await fetchAccessToken(code);
    let access_token = accessData.access_token;
    if (access_token) {
      handleConsents(accessData);
    }
  };

  const handleOpenCloud = async () => {
    let access_token;
    let accessData;
    if (onOK) onOK();
    if (Platform.OS === "web") WebBrowser.maybeCompleteAuthSession();

    if (!authData) {
      promptAsync();
    } else if (authData.get("access_token")) {
      if (checkTokenNeedRefresh(authData)) {
        accessData = await refreshAccessToken(authData);
        if (accessData) {
          access_token = accessData.access_token;
        } else {
          promptAsync();
        }
      } else {
        access_token = authData.get("access_token");
        accessData = authData.toJS();
      }
    }
    if (access_token) {
      handleConsents(accessData);
    }
  };

  const onCloseActionModal = () => {
    setModalVisible(false);
    setAction("");
    setLoginData({ login: "", password: "" });
  };

  const connectWithManualData = () => {
    onCloseActionModal();
    let loginDataToSend = {
      ...loginData,
      name: "Automatic",
      ip: "192.168.1.1",
      device_id: chosenDeviceId,
    };
    props.onSaveProfile("", loginDataToSend, false);
    if (props && props.onConnect) {
      props.onConnect("new_cloud", loginDataToSend);
    }
  };

  const connectWithExistingProfile = (existingLoginData) => {
    onCloseActionModal();

    let loginDataToSend = { ...existingLoginData, device_id: chosenDeviceId };
    if (props && props.onConnect) {
      props.onConnect("new_cloud", loginDataToSend);
    }
  };

  let newArray = [];
  profiles.map((el, key) => {
    if (el && typeof el === "object" && el.get("loginData").get("name") !== "demo") {
      newArray.push(el.get("loginData").toJS());
    }
  });
  let ownInstallations = [];
  let installerInstallations = [];
  if (associations && associations.length > 0) {
    associations.map((el, key) => {
      if (typeof el == "object" && el.type === "installer") {
        installerInstallations.push(el);
      } else if (typeof el == "object" && (el.type === "owner" || el.type === "co-owner")) {
        ownInstallations.push(el);
      }
    });
  }
  return (
    <View>
      {window.location.protocol == "https:" && (source === "init" || source === "support") ? (
        <ButtonPrimary
          style={
            source === "init"
              ? {
                  width: 250,
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: 20,
                  height: 40,
                  margin: 10,
                }
              : { width: "60%", margin: 10 }
          }
          onPress={handleOpenCloud}
        >
          {t("CONNECT_TO_CLOUD")}
        </ButtonPrimary>
      ) : null}
      {source === "security" && device_id && profile ? (
        <ButtonPrimary style={{ marginTop: 20 }} onPress={handleOpenCloud}>
          {t("cloud:ADD_ASSOCIATIONS")}
        </ButtonPrimary>
      ) : null}
      {source === "dashboard" && device_id ? (
        <TouchableOpacity
          style={{
            flex: 1,
            alignItems: "center",
            width: 150,
          }}
          onPress={handleOpenCloud}
        >
          <Text
            style={{
              flex: 1,
              padding: 10,
              fontSize: 16,
              color: theme.TEXT_COLOR_PRIMARY,
            }}
          >
            {t("OK")}
          </Text>
        </TouchableOpacity>
      ) : null}

      {/* cloud - local or new profie modal */}
      <ModalWithContent isVisible={modalVisible} title={t("cloud:NEW_CLOUD_CONNECTION")} onClose={onCloseActionModal}>
        {!action ? (
          <View>
            <Text style={{ marginBottom: 10 }}>{t("cloud:NO_PROFILE_FOUND")}</Text>
            <Text style={{ marginBottom: 10 }}>{t("cloud:WHAT_TO_DO")}</Text>
            <ButtonPrimary style={{ marginBottom: 10 }} onPress={() => setAction("local")}>
              {t("cloud:CONNECT_WITH_LOCAL")}
            </ButtonPrimary>
            <ButtonPrimary style={{ marginBottom: 10 }} onPress={() => setAction("manually")}>
              {t("cloud:ENTER_NEW_LOGIN_DATA")}
            </ButtonPrimary>
          </View>
        ) : null}

        {action === "add" ? (
          <View>
            {response.status == 200 ? (
              <Text style={{ marginBottom: 5 }}>{t("cloud:ADD_ASSOCIATIONS_OK")}</Text>
            ) : (
              <Text style={{ marginBottom: 5 }}>{t("cloud:ADD_ASSOCIATIONS_ERR") + " " + response.detail}</Text>
            )}
          </View>
        ) : null}

        {action === "local" ? (
          <View>
            <Text style={{ marginBottom: 5 }}>{t("cloud:CHOOSE_PROFILE_ASSOCIATED")}</Text>
            {newArray &&
              newArray.map((profile, index) => {
                return (
                  <TouchableOpacity key={index + profile.name} style={{ marginVertical: 4 }} onPress={() => connectWithExistingProfile(profile)}>
                    <Text>
                      {index + 1}. {profile.name}
                    </Text>
                  </TouchableOpacity>
                );
              })}
          </View>
        ) : null}
        {action === "manually" ? (
          <View>
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                marginBottom: 20,
              }}
            >
              <View style={{ width: "30%" }}>
                <Text style={{ paddingRight: 10 }}>{t("LOGIN")}</Text>
              </View>
              <TextInput
                style={styles.textInput}
                autoCapitalize={"none"}
                placeholder={t("LOGIN")}
                value={loginData.login}
                onChangeText={(value) =>
                  setLoginData((prevState) => ({
                    ...prevState,
                    login: value,
                  }))
                }
              />
            </View>
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                marginBottom: 20,
              }}
            >
              <View style={{ width: "30%" }}>
                <Text style={{ paddingRight: 10 }}>{t("PASSWORD")}</Text>
              </View>
              <TextInput
                style={styles.textInput}
                placeholder={t("PASSWORD")}
                secureTextEntry={true}
                value={loginData.password}
                onChangeText={(value) =>
                  setLoginData((prevState) => ({
                    ...prevState,
                    password: value,
                  }))
                }
                autoCapitalize={"none"}
              />
            </View>
            <ButtonPrimary
              onPress={() => {
                connectWithManualData();
              }}
            >
              {t("CONNECT")}
            </ButtonPrimary>
          </View>
        ) : null}
      </ModalWithContent>
    </View>
  );
};

const styles = StyleSheet.create({
  textInput: {
    borderWidth: 1,
    paddingHorizontal: 5,
    paddingVertical: 5,
    borderColor: "#ddd",
    flex: 1,
  },
});

const mapStateToProps = (state) => {
  let currentProfile = state.profilesSettings.get("currentProfile");
  return {
    authData: state.cloudData ? state.cloudData.get("authData") : null,
    profiles: state.profilesSettings,
    profile: state.profilesSettings.get(currentProfile) ? state.profilesSettings.get(currentProfile).get("loginData") : null,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    authDataReceived: (authData) => dispatch(authDataReceived(authData)),
    associationsDataReceived: (associationsData) => dispatch(associationsDataReceived(associationsData)),
    metaDataReceived: (associationsData) => dispatch(metaDataReceived(associationsData)),
    onSaveProfile: (oldName, loginData, automaticLogin) => dispatch(saveProfile(oldName, loginData, automaticLogin)),
    iceServersReceived: (serversData) => dispatch(iceServersReceived(serversData)),
    changeConsentsTime: (time) => dispatch(changeConsentsTime(time)),
    changeConsentsStatus: (status) => dispatch(changeConsentsStatus(status)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CloudIntegration);
