import React, { useContext, useState } from "react";
import { Text, View, Platform, StyleSheet, TouchableOpacity, ScrollView, Platfrom, Image } from "react-native";
import { Feather } from "@expo/vector-icons";

import RNPickerSelect from "react-native-picker-select";
import { useTheme } from "../../../ThemeContext";
import ModalWithContent from "./ModalWithContent";
import { StyledIcon } from "./styledComponents";
import { set } from "immutable";

const PickerSelect = ({
  items,
  onValueChange,
  onFinalChange,
  value,
  placeholder,
  containerStyle,
  onClose,
  textInputProps,
  additionalTextStyle,
  onOpen,
  disabled,
  inputStyle,
}) => {
  const { theme } = useTheme();

  const [modalVisible, setModalVisible] = useState(false);

  const showModal = () => {
    setModalVisible(true);
  };

  const [valueSelected, setValueSelected] = useState(value);

  const onCloseModal = () => {
    if (onFinalChange) {
      onFinalChange(valueSelected);
    }
    setModalVisible(false);
  };

  const onClosePicker = () => {
    if (onFinalChange) {
      onFinalChange(valueSelected);
    }
    if (onClose) {
      onClose();
    }
  };

  const itemSelected = (el) => {
    onValueChange(el);
    setValueSelected(el);
    if (onFinalChange) {
      onFinalChange(el);
    }
    setModalVisible(false);
  };

  const textColor = theme.TEXT_COLOR_PRIMARY;
  const textColorSecondary = theme.TEXT_COLOR_SECONDARY;
  const inputBackgroundColor = theme.APP_BACKGROUND_COLOR;
  const borderColor = theme.PLACEHOLDER_BACKGROUND_COLOR;

  const itemsFilter = items.filter((n) => n);

  let content = (
    <ScrollView style={{ width: "100%" }}>
      {itemsFilter.map((item, index) => {
        return item ? (
          <TouchableOpacity
            style={{
              padding: 10,
              justifyContent: "center",
              alignContent: "center",
              flexDirection: "row",
            }}
            key={item.value + index}
            onPress={() => itemSelected(item.value)}
          >
            <Text style={{ color: textColor, textAlign: "center", verticalAlign: "auto", paddingTop: 5 }}>{item.label}</Text>
            {item.iconBase && (
              <Image
                source={{ uri: "data:image/png;base64," + item.iconBase }}
                resizeMode={"contain"}
                style={[{ width: 30, height: 30, marginLeft: 15 }]}
              />
            )}
          </TouchableOpacity>
        ) : null;
      })}
    </ScrollView>
  );

  let styles = StyleSheet.create({
    inputANDROID: {
      paddingLeft: 8,
      height: 26,
      borderWidth: 1,
      borderColor: borderColor,
      borderRadius: 10,
      width: "100%",
      color: textColor,
      backgroundColor: inputBackgroundColor,
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      ...inputStyle,
    },
  });

  let selectedItem = itemsFilter.find((item) => item.value == value);
  let placeholderText = placeholder ? placeholder.label : "";

  let modalContent = modalVisible && (
    <ModalWithContent isVisible={modalVisible} title={placeholderText} onClose={onCloseModal}>
      {content}
    </ModalWithContent>
  );
  let pickerAndorid = (
    <TouchableOpacity
      style={[
        styles.inputANDROID,
        {
          width: "100%",
          justifyContent: "space-between",
        },
      ]}
      onPress={showModal}
    >
      <View style={{ flex: 1 }}>
        <Text
          numberOfLines={1}
          style={[
            {
              color: selectedItem ? textColor : textColorSecondary,
              paddingRight: 20,
            },
            additionalTextStyle,
          ]}
        >
          {selectedItem ? selectedItem.label : placeholderText}
        </Text>
      </View>
      <View style={{ position: "absolute", right: 0 }}>
        <Feather name="chevron-down" size={18} color={inputStyle && inputStyle.color ? inputStyle.color : theme.ICONS_COLOR_SECONDARY} />
      </View>
    </TouchableOpacity>
  );

  const pickerSelectStyles = StyleSheet.create({
    inputIOS: {
      paddingVertical: 4,
      paddingHorizontal: 8,
      height: 26,
      borderWidth: 1,
      borderColor: borderColor,
      borderRadius: 10,
      color: textColor,
      paddingRight: 25,
      backgroundColor: inputBackgroundColor,
      ...inputStyle,
    },
    iconContainer: {
      top: 3,
      right: 2,
    },
    chevron: {
      display: "none",
    },
  });

  //console.log(itemsFilter);

  let pickerIos =
    Platform.OS === "ios" ? (
      <View style={[containerStyle]}>
        <RNPickerSelect
          textAlign={"center"}
          placeholder={placeholder}
          items={itemsFilter}
          onValueChange={(value, index) => {
            onValueChange(value, index);
            setValueSelected(value);
          }}
          style={{
            ...pickerSelectStyles,
          }}
          placeholderTextColor={"gray"}
          onClose={onClosePicker}
          onOpen={onOpen}
          textInputProps={{ ...textInputProps }}
          value={value}
          Icon={() => {
            return <Feather name="chevron-down" size={18} color={inputStyle && inputStyle.color ? inputStyle.color : theme.ICONS_COLOR_SECONDARY} />;
          }}
        />
      </View>
    ) : null;

  return !disabled ? (
    Platform.OS === "ios" ? (
      pickerIos
    ) : (
      <View style={[containerStyle]}>
        {pickerAndorid}
        {modalContent}
      </View>
    )
  ) : (
    <View>
      <Text
        style={{
          fontSize: 12,
          paddingVertical: 4,
          paddingHorizontal: 8,
          height: 26,
          borderWidth: 1,
          borderColor: borderColor,
          borderRadius: 10,
          color: textColor,
          paddingRight: 25,
          backgroundColor: inputBackgroundColor,
        }}
      >
        {selectedItem ? selectedItem.label : placeholderText}
      </Text>
    </View>
  );
};

export default PickerSelect;
