import React, { useState, useContext } from "react";
import { View, Text, TouchableOpacity, Platform } from "react-native";
import { t } from "../../services/i18n";

import { Feather } from "@expo/vector-icons";
import { useTheme } from "../../../ThemeContext";
import { TextInputStyled, ButtonPrimary } from "../UI/styledComponents";
import ModalWithContent from "../UI/ModalWithContent";
import moment from "moment";

const DateTimePicker = ({ value, onChange, type }) => {
  let mapType = type;
  if (type == "datetime") {
    mapType = "datetime-local";
  }

  return React.createElement("input", {
    type: mapType,
    value: value,
    onInput: onChange,
  });
};

const DateTimePickerX = (props) => {
  const onChange = (e) => {
    if (props.mode === "time") {
      let time = moment(e.target.value, "hh:mm");
      props.onConfirm(time);
    } else if (props.mode == "datetime") {
      let time = moment(e.target.value, "DD-MM-YYYY, HH:mm");
      props.onConfirm(time);
    } else {
      props.onConfirm(e.target.value);
    }
  };

  return (
    <View
      style={{
        flex: 1,
      }}
    >
      <DateTimePicker
        onChange={(value) => onChange(value)}
        value={moment(props.value, "DD-MM-YYYY, HH:mm").format("YYYY-MM-DDTHH:mm")}
        inputStyle={props.style}
        type={props.mode}
      />
    </View>
  );
};

export default DateTimePickerX;
