import React, { Component } from "react";
import { Text, View, TouchableOpacity } from "react-native";
import { t } from "../../services/i18n";

import {
  PrimaryColorText,
  ButtonPrimary,
} from "../../components/UI/styledComponents";
import { devicesForGroups } from "../../utils";
import PickerSelectObjects from "../../components/UI/PickerSelectObjects";
import MultiplePickerSelectObjects from "./MultiplePickerSelectObjects";
import ModalWithContent from "../../components/UI/ModalWithContent";

class AddDeviceModal extends Component {
  state = {
    selectedGroup: 0,
    selectedDevices: [],
    buttonAddDisabled: true,
  };

  onGroupValueChange = (value) => {
    this.setState({
      selectedGroup: value,
      selectedDevices: [],
      buttonAddDisabled: true,
    });
  };

  onDeviceValueChange = (devicesArray) => {
    if (devicesArray) {
      this.setState({
        selectedDevices: devicesArray,
        buttonAddDisabled: false,
      });
    }
  };

  onAddHandler = () => {
    this.props.onAdd(this.state.selectedDevices, this.state.selectedGroup);
  };

  setDevices = () => {
    const { devices, group_devices, typeToShow, params_devices } = this.props;

    let devicesToSend = [];

    let listOfDevicesForGroup = devicesForGroups(
      group_devices,
      this.state.selectedGroup
    );

    listOfDevicesForGroup.map((deviceForGroup) => {
      let deviceID = deviceForGroup.get("id_obiektu");
      if (devices.get(deviceID)) {
        let device = devices.get(deviceID);
        let typKomponentu = device.get("typ_komponentu");
        if (typKomponentu == "custom") {
          let powiazane = params_devices.get(deviceID)
            ? params_devices.get(deviceID).get("powiazane")
            : "";
          let powiazaneArrayWithPossibleCustom = powiazane
            ? powiazane.split(",")
            : [];

          powiazaneArrayWithPossibleCustom.map((el) => {
            el = el.split(":").reverse();
            let deviceFromCustom = devices.get(el[0]);
            if (
              //warunki rózne
              deviceFromCustom &&
              deviceFromCustom.get("typ_komponentu") != "custom" &&
              typeToShow.includes(deviceFromCustom.get("typ_komponentu")) &&
              !deviceFromCustom.get("bezpieczne")
            ) {
              devicesToSend.push({
                label: deviceFromCustom.get("opis_menu"),
                value: deviceFromCustom.get("id"),
                chosen: false,
              });
            }
          });
        }
        //warunlo rozne
        if (typeToShow.includes(typKomponentu) && !device.get("bezpieczne")) {
          devicesToSend.push({
            label: device.get("opis_menu"),
            value: device.get("id"),
            chosen: false,
          });
        }
      }
    });
    //dodatkowa grupa
    if (devicesToSend.length > 1) {
      devicesToSend.push({
        label: t("scenes:ALL"),
        value: "ALL",
        chosen: false,
      });
    }
    return devicesToSend;
  };

  render() {
    const { isVisible, onClose } = this.props;
    const { selectedDevices, selectedGroup, buttonAddDisabled } = this.state;

    let devicesInGroup = this.setDevices();

    return (
      <ModalWithContent
        isVisible={isVisible}
        title={t("ADD_OBJECT")}
        onClose={onClose}
      >
        <View style={{ paddingBottom: 16 }}>
          <PrimaryColorText style={{ paddingBottom: 4 }}>
            {t("OBJECT_PLACE")}
          </PrimaryColorText>
          <PickerSelectObjects
            onValueChange={this.onGroupValueChange}
            value={selectedGroup}
            type={"groups"}
          />
        </View>

        <View style={{ paddingBottom: 20 }}>
          <PrimaryColorText style={{ paddingBottom: 4 }}>
            {t("OBJECT_NAME")}
          </PrimaryColorText>
          <MultiplePickerSelectObjects
            devicesInGroup={devicesInGroup}
            onValueChange={this.onDeviceValueChange}
            selectedDevices={selectedDevices}
            selectedGroup={selectedGroup}
          />
        </View>
        <ButtonPrimary disabled={buttonAddDisabled} onPress={this.onAddHandler}>
          {t("ADD")}
        </ButtonPrimary>
      </ModalWithContent>
    );
  }
}

export default AddDeviceModal;
