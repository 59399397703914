import React from "react";
import { Image, View } from "react-native";
import { ComponentContainer, ComponentContainerTouchable, PrimaryColorText } from "../../../components/UI/styledComponents";
import { t } from "../../../services/i18n";
import { useTheme } from "../../../../ThemeContext";

const Message_DetailsScreen = (props) => {
  let { message, aspectRatio } = props.route.params;
  const { theme } = useTheme();
  const descriptionFromSource = (value) => {
    let description = "";
    switch (value) {
      case 0:
        description = "API";
        break;
      case 1:
        description = t("statistics:SCENE_BY_USER");
        break;
      case 2:
        description = t("statistics:MOBILE_APP");
        break;
      case 3:
        description = t("statistics:LOGIN_UNSUCCESSFUL");
        break;
      case 4:
        description = t("statistics:LOGIN_SUCCESSFUL");
        break;
      case 5:
        description = t("statistics:PRESENCE_SIMULATION");
        break;
      case 6:
        description = t("statistics:CONDITION");
        break;
      case 7:
        description = t("statistics:SCENE_BY_SCHEDULE");
        break;
      case 8:
        description = t("PUSH");
        break;
      case 9:
        description = t("INTERCOM");
        break;
      case 10:
        description = t("SYSTEM");
        break;
      default:
        description = `${t("account:UNKNOWN_EVENT")} ${value}`;
    }
    return description;
  };

  return (
    <View
      style={{
        flex: 1,
        backgroundColor: theme.APP_BACKGROUND_COLOR,
      }}
    >
      <ComponentContainer containerStyle={{ width: "100%", padding: 10 }}>
        <View style={{ flexDirection: "row", paddingBottom: 10 }}>
          <PrimaryColorText bold>{t("statistics:SOURCE")}: </PrimaryColorText>
          <PrimaryColorText>{descriptionFromSource(message.get("source"))}</PrimaryColorText>
        </View>
        <View style={{ flexDirection: "row", paddingBottom: 10 }}>
          <PrimaryColorText bold>{t("DATE")}: </PrimaryColorText>
          <PrimaryColorText>{message.get("date_of_change")}</PrimaryColorText>
        </View>
        {message.get("type") == 0 && (
          <View style={{ flexDirection: "row", paddingBottom: 10 }}>
            <PrimaryColorText bold>{t("statistics:USER")}: </PrimaryColorText>
            <PrimaryColorText>{message.get("user")}</PrimaryColorText>
          </View>
        )}
        {message.get("title") && message.get("type") == 0 ? (
          <View style={{ flexDirection: "row", paddingBottom: 10 }}>
            <PrimaryColorText bold>{message.get("title")}</PrimaryColorText>
          </View>
        ) : null}
        {message.get("type") == 0 && message.get("body") && <PrimaryColorText>{message.get("body")}</PrimaryColorText>}
        {message.get("type") == 1 && message.get("image") && (
          <Image
            style={{
              width: "100%",
              height: undefined,
              aspectRatio: aspectRatio,
            }}
            source={{
              uri: `data:image/jpg;base64,${message.get("image")}`,
            }}
          />
        )}
      </ComponentContainer>
    </View>
  );
};
export default Message_DetailsScreen;
