import React, { Component } from "react";
import { Text, View, TouchableOpacity, Picker } from "react-native";
import { t } from "../../services/i18n";
import { connect } from "react-redux";

import {
	PrimaryColorText,
	ButtonPrimary,
} from "../../components/UI/styledComponents";
import { devicesForGroups } from "../../utils";
import PickerSelect from "../../components/UI/PickerSelect";
import PickerSelectObjects from "../../components/UI/PickerSelectObjects";
import ModalWithContent from "../../components/UI/ModalWithContent";

class AddFavouritesModal extends Component {
	state = {
		selectedGroup: 0,
		selectedDevice: -1,
	};

	onGroupValueChange = (value) => {
		this.setState({ selectedGroup: value, selectedDevice: null });
	};

	onDeviceValueChange = (value) => {
		this.setState({ selectedDevice: value });
	};

	onAddHandler = () => {
		this.props.onAdd(this.state.selectedDevice);
	};

	render() {
		const { isVisible, onClose, groups, devices, group_devices } = this.props;
		const { selectedDevice, selectedGroup } = this.state;

		let objectsGroups = [
			{
				label: t("DASHBOARD"),
				value: 0,
			},
		];

		let devicesInGroup = [];

		groups &&
			groups.map((group) => {
				if (group) {
					objectsGroups.push({
						label: group.get("opis_menu"),
						value: group.get("id"),
					});
				}
			});

		let listOfDevicesForGroup = devicesForGroups(
			group_devices,
			this.state.selectedGroup
		);

		listOfDevicesForGroup.map((deviceForGroup) => {
			if (devices.get(deviceForGroup.get("id_obiektu"))) {
				devicesInGroup.push({
					label: devices.get(deviceForGroup.get("id_obiektu")).get("opis_menu"),
					value: devices.get(deviceForGroup.get("id_obiektu")).get("id"),
				});
			}
		});

		return (
			<ModalWithContent
				isVisible={isVisible}
				title={t("ADD_OBJECT")}
				onClose={onClose}
			>
				<PrimaryColorText style={{ paddingBottom: 4 }}>
					{t("OBJECT_PLACE")}
				</PrimaryColorText>
				<PickerSelectObjects
					onValueChange={this.onGroupValueChange}
					value={selectedGroup}
					type={"groups"}
				/>
				<PrimaryColorText style={{ paddingBottom: 4, paddingTop: 20 }}>
					{t("OBJECT_NAME")}
				</PrimaryColorText>
				<PickerSelectObjects
					items={devicesInGroup}
					onValueChange={this.onDeviceValueChange}
					value={selectedDevice}
					type={"devices"}
				/>

				<ButtonPrimary
					style={{ paddingBottom: 4, marginTop: 20 }}
					onPress={this.onAddHandler}
				>
					{t("ADD")}
				</ButtonPrimary>
			</ModalWithContent>
		);
	}
}

const mapStateToProps = (state) => {
	let currentProfile = state.profilesSettings.get("currentProfile");

	return {
		groups: state.smartHomeData.get(currentProfile).get("groups"),
		group_devices: state.smartHomeData.get(currentProfile).get("group_devices"),
		devices: state.smartHomeData.get(currentProfile).get("devices"),
	};
};

export default connect(mapStateToProps)(AddFavouritesModal);
