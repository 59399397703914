import React, { Component } from "react";
import { View } from "react-native";
import { connect } from "react-redux";
import { t } from "../../../../services/i18n";

import { saveOrder } from "../../../../store/actions";
import DraggableFlatList from "react-native-draggable-flatlist";
import DraggableItem from "./DraggableItem";
import { ThemeContext } from "../../../../../ThemeContext";
import { NoDataScreen } from "../../../../components/UI/styledComponents";

import { scenesForScenes } from "../../../../utils";
class Scenes_Order_PersonalizationScreen extends Component {
	static contextType = ThemeContext;
	state = {
		scenesList: [],
	};

	setData = () => {
		const { scenes, order } = this.props;
		const scenesList = [];
		let listOfScenes = scenesForScenes(scenes, order);

		listOfScenes &&
			listOfScenes.map((scene) => {
				scenesList.push({
					key: `${scene.get("id")}`,
					label: `${scene.get("sceneName")}`,
				});
			});

		this.setState({ scenesList });
	};

	componentDidMount() {
		this.setData();
	}

	componentDidUpdate(prevProps) {
		if (prevProps.scenes != this.props.scenes) {
			this.setData();
		}
	}

	componentWillUnmount() {
		let scenesOrderArray = this.state.scenesList.map((el) => el.key);
		this.props.onSaveOrder(scenesOrderArray, "scenes");
	}

	renderScene = ({ item, index, drag, isActive }) => {
		const { theme } = this.context;

		return (
			<DraggableItem
				theme={theme}
				item={item}
				index={index}
				drag={drag}
				isActive={isActive}
				type={"scene"}
			/>
		);
	};

	render() {
		const { scenesList } = this.state;
		const { theme } = this.context;

		return (
			<View
				style={{
					flex: 1,
					backgroundColor: theme.APP_BACKGROUND_COLOR,
					padding: 5,
				}}
			>
				{scenesList && scenesList.length ? (
					<DraggableFlatList
						data={scenesList}
						renderItem={this.renderScene}
						keyExtractor={(item, index) => `draggable-item-${item.key}`}
						onDragEnd={({ data }) => this.setState({ scenesList: data })}
					/>
				) : (
					<NoDataScreen />
				)}
			</View>
		);
	}
}

const mapStateToProps = (state) => {
	let currentProfile = state.profilesSettings.get("currentProfile");

	return {
		scenes: state.smartHomeData.get(currentProfile).get("scenes"),
		order: state.profilesSettings.get(currentProfile).get("order"),
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onSaveOrder: (order, key) => dispatch(saveOrder(order, key)),
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Scenes_Order_PersonalizationScreen);
