import React, { Component } from "react";
import { View, Image, AppState, ImageBackground, TouchableOpacity, Pressable, Modal, Platform, Dimensions, Text, StatusBar } from "react-native";
import { useIsFocused } from "@react-navigation/native";
import mqttClient from "../../../services/mqtt";
import { connect } from "react-redux";
import { ButtonPrimary, ButtonSecondary, StyledIcon } from "../../UI/styledComponents";
//import { VLCPlayer, VlCPlayerView } from "mercury-player";
import Janus from "../../../services/janus/Janus";
import * as ScreenOrientation from "expo-screen-orientation";
import JanusStreamingPlugin from "../../../services/janus/plugins/streaming/JanusStreamingPlugin";
import { SecondaryColorText, PrimaryColorText } from "../../UI/styledComponents";
import { store } from "../../../store/configureStore";
import { t } from "../../../services/i18n";
import { changeAnsweredCall, changeBlockDimensions, changeCameraConnectionType, changePopupObjectId, setDuringTalk } from "../../../store/actions";
import { mediaDevices, MediaStream, RTCIceCandidate, RTCPeerConnection, RTCSessionDescription, RTCView } from "react-native-webrtc-web-shim";
import ModalWithContent from "../../UI/ModalWithContent";

import base64 from "base-64";
import * as NavigationBar from "expo-navigation-bar";
import { SkottieLoader } from "../../animatedComponents/Loader";
import { set } from "immutable";

Janus.setDependencies({
  RTCPeerConnection,
  RTCSessionDescription,
  RTCIceCandidate,
  MediaStream,
});

class CameraUniversalComponentX extends Component {
  state = {
    cameraImage: "",
    width: 0,
    height: 0,
    showImage: false,
    appState: AppState.currentState,
    vlcError: false,
    webrtcError: false,
    stream: null,
    fullScreen: false,
    error: null,
    showInfo: false,
    errors: [],
    modalVisible:
      this.props.deviceFromSketch &&
      (this.props.onSketchWidth < 100 || !this.props.onSketchWidth) &&
      (this.props.onSketchHeight < 100 || !this.props.onSketchHeight)
        ? true
        : false,
    refreshCounter: false,
    currentOrientation: null,
    currentBarColor: null,
  };

  askForTheNewImage = 0;

  async initJanus(janus) {
    try {
      this.janus = janus;

      if (this.janus) {
        this.videoRoom = new JanusStreamingPlugin(this.janus);
        this.videoRoom.setOnStreamListener((stream, reconnect) => {
          this.setState({
            stream: stream,
            error: null,
            webrtcError: false,
          });
          if (reconnect) {
            this.initJanus(this.props.janus);
          }
        });
        this.videoRoom.setOnWebRTCUpListener(async () => {});
        await this.videoRoom.createPeer();
        await this.videoRoom.connect();
        const watchStreamingResponse = await this.videoRoom.sendAsync({
          request: "watch",
          id: this.props.device.get("id"),
          offer_audio: true,
          offer_video: true,
          offer_data: false,
        });
        if (watchStreamingResponse && watchStreamingResponse.plugindata && watchStreamingResponse.plugindata.data) {
          if (watchStreamingResponse.plugindata.data.error) {
            throw new Error(watchStreamingResponse.plugindata.data.error);
          }
        }
        if (this.videoRoom.pc.connectionState === "closed") {
          throw new Error("Connection closed");
        }
        if (watchStreamingResponse && watchStreamingResponse.jsep) {
          await this.videoRoom.pc.setRemoteDescription(
            new Janus.RTCSessionDescription({
              sdp: watchStreamingResponse.jsep.sdp,
              type: watchStreamingResponse.jsep.type,
            })
          );
          this.videoRoom.isRemoteDescriptionSet = true;
          for (const candidate of this.videoRoom.cachedCandidates) {
            await this.videoRoom.pc.addIceCandidate(candidate);
          }
          this.videoRoom.cachedCandidates = [];

          let answer = await this.videoRoom.pc.createAnswer({
            offerToReceiveAudio: false,
            offerToReceiveVideo: true,
            mandatory: {
              OfferToReceiveAudio: false,
              OfferToReceiveVideo: true,
            },
          });

          await this.videoRoom.pc.setLocalDescription(answer);
          const startResponse = await this.videoRoom.sendAsyncWithJsep(
            {
              request: "start",
            },
            {
              type: answer.type,
              sdp: answer.sdp,
            }
          );
        } else {
          setTimeout(() => {
            this.initJanus(this.props.janus);
          }, 3000);
          throw new Error("No response for watch request");
        }
      } else {
        throw new Error("Janus is null");
      }
    } catch (e) {
      const error = e && e.message ? "WEBRTC: " + e.message : "WEBRTC: UNKNOWN ERROR";
      this.setState(
        {
          error: e && e.message ? e.message : "UNKNOWN ERROR",
          webrtcError: true,
          errors: this.state.errors.includes(error) ? this.state.errors : [...this.state.errors, error],
        },
        () => {
          this.proceedAfterError("webrtc");
        }
      );
    }
  }

  deinitJanus = async () => {
    if (this.janus && this.janus.connectionID === store.getState().statesData.get("janusId")) {
      if (this.state.stream) {
        this.state.stream.getTracks().forEach((track) => track.stop());
      }
      if (this.videoRoom && this.videoRoom.pc) {
        this.videoRoom.pc.close();
      }

      this.setState({
        stream: null,
        error: null,
      });
    }
  };

  componentWillUnmount = async () => {
    this.deinitJanus();
    this.setState({ webrtcError: false, vlcError: false });
  };

  componentDidMount() {
    const cameraType = this.evaluateConnection(this.props.currentConnection);

    if (cameraType === "image") {
      mqttClient.askForCamera(this.props.device.get("id"), true);
    } else if (cameraType === "webrtc") {
      this.initJanus(this.props.janus);
    }

    if (
      this.props.deviceFromSketch &&
      (this.props.onSketchWidth < 100 || !this.props.onSketchWidth) &&
      (this.props.onSketchHeight < 100 || !this.props.onSketchHeight)
    ) {
      this.lockAndSaveOrientation();
    }
  }

  lockAndSaveOrientation = async () => {
    if (Platform.OS === "web") return;
    await this.props.changeBlockDimensions(true);
    let currentOrientation = await ScreenOrientation.getOrientationAsync();

    this.setState({ currentOrientation: currentOrientation }, async () => {
      if (currentOrientation === ScreenOrientation.Orientation.LANDSCAPE_LEFT) {
        await ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock.LANDSCAPE_LEFT);
      } else {
        await ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock.LANDSCAPE_RIGHT);
      }
      if (Platform.OS === "android") {
        const color = await NavigationBar.getBackgroundColorAsync();
        await NavigationBar.setBackgroundColorAsync("black");
        this.setState({ currentBarColor: color });
      }
    });
  };

  unlockAndRestoreOrientation = async () => {
    if (this.state.currentOrientation === ScreenOrientation.Orientation.PORTRAIT_UP) {
      await ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock.PORTRAIT_UP);
    } else if (this.state.currentOrientation === ScreenOrientation.Orientation.PORTRAIT_DOWN) {
      await ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock.PORTRAIT_DOWN);
    } else if (this.state.currentOrientation === ScreenOrientation.Orientation.LANDSCAPE_LEFT) {
      await ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock.LANDSCAPE_LEFT);
    } else if (this.state.currentOrientation === ScreenOrientation.Orientation.LANDSCAPE_RIGHT) {
      await ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock.LANDSCAPE_RIGHT);
    }
    if (Platform.OS === "android") {
      if (this.state.currentBarColor) await NavigationBar.setBackgroundColorAsync(this.state.currentBarColor);
    }
    setTimeout(async () => {
      this.props.changeBlockDimensions(false);
    }, 500);
  };

  shouldComponentUpdate(nextProps, nextState) {
    if (this.state.showImage != nextState.showImage) {
      return true;
    } else if (this.state.cameraImage != nextState.cameraImage) {
      return true;
    } else if (this.state.vlcError != nextState.vlcError) {
      return true;
    } else if (this.state.webrtcError != nextState.webrtcError) {
      return true;
    } else if (this.state.stream != nextState.stream) {
      return true;
    } else if (this.state.showInfo != nextState.showInfo) {
      return true;
    } else if (this.state.modalVisible != nextState.modalVisible) {
      return true;
    } else if (this.state.refreshCounter != nextState.refreshCounter) {
      return true;
    } else if (this.props.isFocused != nextProps.isFocused) {
      return true;
    } else if (this.props.appState != nextProps.appState) {
      return true;
    } else if (this.props.camerasConnectionType != nextProps.camerasConnectionType) {
      return true;
    }

    return false;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { gretingViewHeight, customWidth, params_device, currentConnection, dimensions, sideMenuSize, sideMenuShown, realWidthColumn, showList } =
      this.props;
    let columnWidth = dimensions.get("columnWidth");

    if (customWidth || dimensions.get("smallTiles") || showList) {
      columnWidth = dimensions.get("width");
    } else if (realWidthColumn) {
      columnWidth = realWidthColumn;
    }

    let width = dimensions.get("width");
    const cameraType = this.evaluateConnection(currentConnection);

    let refreshTime = 500;
    if (params_device && params_device.get("czas")) refreshTime = params_device.get("czas");
    if (
      (nextProps.isFocused && this.props.connectionStatus == 0 && nextProps.connectionStatus == 1) ||
      (!this.props.isFocused && nextProps.isFocused)
    ) {
      if (cameraType === "image") {
        mqttClient.askForCamera(this.props.device.get("id"));
      }
    } else if (this.askForTheNewImage == 0 && nextProps.isFocused) {
      this.askForTheNewImage = setTimeout(() => {
        if (cameraType === "image") {
          mqttClient.askForCamera(this.props.device.get("id"));
          this.askForTheNewImage = 0;
        }
      }, refreshTime);
    }

    if (
      nextProps.deviceState &&
      nextProps.deviceState.get("cameraImage") &&
      nextProps.deviceState.get("cameraImage").length > 5 &&
      nextProps.deviceState.get("cameraImage") != this.state.cameraImage
    ) {
      let cameraImage = nextProps.deviceState.get("cameraImage");
      let myUri = `data:image/png;base64,${cameraImage}`;

      if (customWidth || dimensions.get("smallTiles") || showList) {
        columnWidth = dimensions.get("width");
      } else if (realWidthColumn) {
        columnWidth = realWidthColumn;
      }
      let imageWidth = gretingViewHeight ? width / 2 - 20 : columnWidth - 20;
      Image.getSize(
        myUri,
        (width, height) => {
          let imageHeight = (imageWidth * height) / width;
          this.setState({
            cameraImage: cameraImage,
            width: imageWidth,
            height: imageHeight,
            showImage: true,
          });
        },
        () => {
          this.setState({
            cameraImage: cameraImage,
            showImage: false,
            width: 0,
            height: 0,
          });
        }
      );
    }

    if (this.props.janus == null && nextProps.janus) {
      this.setState(
        {
          stream: null,
          error: null,
          webrtcError: false,
        },
        () => {
          this.initJanus(nextProps.janus);
        }
      );
    } else if (nextProps.janus == null) {
      this.setState({
        stream: null,
        error: null,
        webrtcError: true,
      });
    }
  }

  _onError = (err) => {
    this.setState({ showImage: false });
  };

  proceedAfterError = async (err) => {
    const cameraType = this.evaluateConnection(this.props.currentConnection);
    if (cameraType === "image") {
      setTimeout(() => {
        this.askForTheNewImage = 0;
        mqttClient.askForCamera(this.props.device.get("id"));
      }, 500);
    } else if (cameraType === "webrtc" && err !== "webrtc") {
      await this.initJanus(this.props.janus);
    }
  };

  handleError = (err) => {
    if (err === "vlc") {
      this.setState({ vlcError: true }, () => {
        this.proceedAfterError(err);
      });
    } else if (err === "webrtc") {
      this.setState({ webrtcError: true }, () => {
        this.proceedAfterError(err);
      });
    }
  };

  componentWillUnmount() {
    if (this.askForTheNewImage) {
      clearTimeout(this.askForTheNewImage);
      this.askForTheNewImage = 0;
    }
  }

  evaluateConnection = (currentConnection) => {
    let { webrtcError, vlcError } = this.state;
    let { serverInfo } = this.props;

    const forceChangeType =
      this.props.camerasConnectionType && this.props.camerasConnectionType.get(this.props.device.get("id").toString())
        ? this.props.camerasConnectionType.get(this.props.device.get("id").toString()).get("type")
        : "auto";

    if (!forceChangeType || forceChangeType === "auto") {
      if (currentConnection === "new_cloud") {
        if (!webrtcError && serverInfo && serverInfo.get("janusStatus") == "1") {
          return "webrtc";
        } else {
          return "image";
        }
      } else {
        if (!vlcError && currentConnection !== "cloud" && Platform.OS !== "web") {
          return "vlc";
        } else if (!webrtcError && serverInfo && serverInfo.get("janusStatus") == "1") {
          return "webrtc";
        } else {
          return "image";
        }
      }
    } else {
      if (forceChangeType === "vlc" && (currentConnection === "cloud" || currentConnection === "new_cloud")) {
        return "image";
      }
      return forceChangeType;
    }
  };

  getWebRtcView = (webViewWidth, webViewHeight) => {
    let { stream } = this.state;

    return (
      <View
        style={[
          {
            height: webViewHeight,
            flexDirection: "row",
            backgroundColor: "transparent",
            maxHeight: webViewHeight,
            justifyContent: "center",
            alignItems: "center",
          },
          this.props.style,
        ]}
      >
        <RTCView
          style={{
            width: webViewWidth,
            height: webViewHeight,
            justifyContent: "center",
            alignItems: "center",
          }}
          stream={Platform.OS === "web" ? this.state.stream : null}
        />
      </View>
    );
  };

  refresh = () => {
    this.setState(
      {
        webrtcError: false,
        vlcError: false,
        refreshCounter: !this.state.refreshCounter,
        stream: null,
      },
      () => {
        this.proceedAfterError();
      }
    );
  };

  isURL = (str) => {
    return /^(?:\w+:)?\/\/([^\s\.]+\.\S{2}|localhost[\:?\d]*)\S*$/.test(str);
  };

  reencodeRtspUrl = (uri) => {
    try {
      const result = {
        QueryString: "",
        Path: "",
        Protocol: "",
        Host: "",
        Port: "",
        Auth: "",
      };

      if (!uri || uri.length === 0) {
        return result;
      }

      const uriEnd = uri.length;

      // Protocol
      let protocolEnd = uri.indexOf(":");
      if (protocolEnd !== -1 && uri.substr(protocolEnd, 3) === "://") {
        result.Protocol = uri.substring(0, protocolEnd);
        protocolEnd += 3; // Skip "://"
      } else {
        protocolEnd = 0; // No protocol
      }

      const uriWithoutProtocol = uri.substring(protocolEnd, uriEnd);
      // Authentication
      let authEnd = Number(uriWithoutProtocol.lastIndexOf("@"));

      let hostStart;
      if (authEnd !== -1) {
        authEnd = authEnd + protocolEnd;
        result.Auth = uri.substring(protocolEnd, authEnd);
        hostStart = authEnd + 1;
      } else {
        authEnd = protocolEnd + 1; // No auth
        hostStart = protocolEnd;
      }

      // Host
      let pathStart = uri.indexOf("/", hostStart);
      if (pathStart === -1) {
        pathStart = uriEnd;
      }
      const queryStart = uri.indexOf("?", pathStart !== -1 ? pathStart : hostStart);
      const hostEnd = uri.indexOf(":", hostStart);

      if (hostEnd !== -1 && (pathStart === -1 || hostEnd < pathStart)) {
        result.Host = uri.substring(hostStart, hostEnd);
        const portEnd = pathStart !== -1 ? pathStart : queryStart;
        result.Port = uri.substring(hostEnd + 1, portEnd !== -1 ? portEnd : uriEnd);
      } else {
        result.Host = uri.substring(hostStart, pathStart !== -1 ? pathStart : queryStart);
      }
      if (result.Port) {
        result.Port = ":" + result.Port;
      }
      // Path
      if (pathStart !== -1) {
        result.Path = uri.substring(pathStart, queryStart !== -1 ? queryStart : uriEnd);
      }

      if (result.Auth) {
        const passAndUser = result.Auth.split(":", 2);
        if (passAndUser.length > 1) {
          result.Auth = `${encodeURIComponent(passAndUser[0])}:${encodeURIComponent(passAndUser[1])}`;
        }
        result.Auth += "@";
      }
      // Query
      if (queryStart !== -1) {
        result.QueryString = uri.substring(queryStart, uriEnd);
        result.Path += result.QueryString;
      }

      return `${result.Protocol}://${result.Auth}${result.Host}${result.Port}${result.Path}`;
    } catch (e) {
      console.log("Error in reencodeRtspUrl", e);
      return uri;
    }
  };

  getCameraView = (url, cameraType, webViewWidth, webViewHeight, gretingViewHeight, fullScreen) => {
    let { width, height, showImage, cameraImage, stream } = this.state;

    const { currentTheme, device, isFocused, popup, deviceFromSketch, onSketchHeight, onSketchWidth, appState, dimensions } = this.props;

    if (fullScreen) {
      if (webViewHeight > webViewWidth) {
        let tmp = webViewHeight;
        webViewHeight = webViewWidth;
        webViewWidth = tmp;
      }
    }

    if (showImage) {
      if (!width) width = 100;
      if (!height) height = 100;
    }

    let proportionsString = "16:9";
    let proportion = 1.333;
    if (!(device.get("max") === 30 && device.get("min") === 10 && device.get("param1") === 1 && Number(device.get("min")) !== 0)) {
      proportionsString = device.get("max") + ":" + device.get("min");
      let proportionTmp = Number(device.get("max")) / Number(device.get("min"));
      if (proportionTmp > 0.2 && proportionTmp < 5) {
        proportion = proportionTmp;
        proportionsString = "16:9";
      }
    }
    webViewHeight = webViewWidth / proportion;
    let paddingHorizontal = 0;

    if (fullScreen && webViewHeight > dimensions.get("width") && !dimensions.get("landscapeOrientation")) {
      webViewHeight = dimensions.get("width") - StatusBar.currentHeight;
      webViewWidth = webViewHeight * proportion;
      paddingHorizontal = (dimensions.get("height") - webViewWidth) / 2;
    } else if (fullScreen && webViewHeight > dimensions.get("height") && dimensions.get("landscapeOrientation")) {
      webViewHeight = dimensions.get("height") - StatusBar.currentHeight;
      webViewWidth = webViewHeight * proportion;
      paddingHorizontal = (dimensions.get("width") - webViewWidth) / 2;
    }

    if (popup && !dimensions.get("landscapeOrientation")) {
      webViewHeight = webViewHeight * 0.85;
      webViewWidth = webViewWidth * 0.85;
    }

    let imageToRender =
      showImage && cameraImage ? (
        <Image
          style={{ width: webViewWidth, height: webViewHeight }}
          onError={({ nativeEvent: { error } }) => this._onError(error)}
          source={{ uri: `data:image/png;base64,${cameraImage}` }}
        />
      ) : (
        <StyledIcon name={"video-off"} color={"secondary"} size={50} />
      );

    url = this.reencodeRtspUrl(url);

    return this.isURL(url) ? (
      <View
        style={{
          backgroundColor: fullScreen || (deviceFromSketch && onSketchWidth > 100 && onSketchHeight > 100) ? "black" : undefined,
          paddingHorizontal: paddingHorizontal,
          height: webViewHeight,
        }}
      >
        {cameraType === "image" && (
          <View
            style={{
              justifyContent: "center",
              alignItems: "center",
              paddingTop: fullScreen ? 0 : 10,
            }}
          >
            {imageToRender}
          </View>
        )}
        {cameraType === "webrtc" && appState == "active" && (
          <>
            {!stream && (
              <SkottieLoader
                style={{
                  width: webViewWidth * 0.5,
                  height: webViewHeight * 0.5,
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: webViewHeight / 4,
                }}
              />
            )}
            {stream && this.getWebRtcView(webViewWidth, webViewHeight)}
          </>
        )}
      </View>
    ) : (
      <View>
        <Text>{t("NOT_VALID_CAMERA_URL")}</Text>
      </View>
    );
  };

  render() {
    const {
      device,
      header,
      deviceState,
      deviceFromSketch,
      gretingViewHeight,
      params_device,
      currentConnection,
      currentTheme,
      dimensions,
      customWidth,
      changeBlockDimensions,
      isFocused,
      appState,
      popup,
      onSketchWidth,
      onSketchHeight,
      changePopupObjectId,
      changeAnsweredCall,
      setDuringTalk,
      fromSip,
      sideMenuShown,
      sideMenuSize,
      realWidthColumn,
      showList,
    } = this.props;
    let { width, height, showImage, cameraImage, stream, showInfo, errors } = this.state;
    const cameraType = this.evaluateConnection(currentConnection);
    let columnWidth = dimensions.get("columnWidth");
    let bigScreen = dimensions.get("width") > 600;

    if (customWidth || dimensions.get("smallTiles") || showList) {
      columnWidth = dimensions.get("width");
    } else if (realWidthColumn) {
      columnWidth = realWidthColumn;
    }
    let webViewWidth = gretingViewHeight ? width / 2 - 20 : columnWidth - 20;

    if (popup) {
      if (bigScreen) {
        webViewWidth = 560;
      }
    } else if (deviceFromSketch && onSketchWidth > 100 && onSketchHeight > 100) {
      webViewWidth = onSketchWidth;
      webViewHeight = onSketchHeight;
    }

    const proportions = device.get("param1") ? device.get("param1") : 1;
    const forceChangeType =
      this.props.camerasConnectionType && this.props.camerasConnectionType.get(device.get("id").toString())
        ? this.props.camerasConnectionType.get(device.get("id").toString()).get("type")
        : "auto";

    let webViewHeight = proportions === 0 ? webViewWidth : webViewWidth / proportions;

    if (dimensions.get("landscapeOrientation") && webViewHeight > dimensions.get("height") * 0.5) {
      webViewHeight = dimensions.get("height") * 0.5;
      if (fromSip) {
        webViewHeight = webViewHeight - 50;
      }
    }

    let url = params_device ? params_device.get("url") : null;
    try {
      const trimmed = this.props.params_device.get("url").trim();
      if (this.props.params_device.get("url") && trimmed.startsWith("{")) {
        this.sipData = JSON.parse(trimmed);
        if (this.sipData) {
          if (!this.sipData.username.startsWith("sip:")) this.sipData.username = "sip:" + this.sipData.username;

          if (!this.sipData.callee.startsWith("sip:")) this.sipData.callee = "sip:" + this.sipData.callee;

          if (this.sipData.rtsp) {
            url = this.sipData.rtsp.trim();
          }
        }
      }
    } catch (e) {
      console.log("Error on parse", e, this.props.params_device.get("url").trim());
    }

    return (
      <View>
        {this.state.modalVisible && (
          <Modal
            visible={this.state.modalVisible}
            animationType="fade"
            transparent={false}
            presentationStyle="fullScreen"
            supportedOrientations={["landscape", "portrait"]}
            onRequestClose={async () => {
              this.unlockAndRestoreOrientation();
              if (this.props.onBackdropPress) {
                this.props.onBackdropPress();
              }
              this.setState({ modalVisible: false });
            }}
          >
            <View style={{ flex: 1, width: "100%", height: "100%", backgroundColor: "black", justifyContent: "center" }}>
              <Pressable
                onPress={async () => {
                  this.unlockAndRestoreOrientation();
                  if (this.props.onBackdropPress) {
                    this.props.onBackdropPress();
                  }
                  this.setState({ modalVisible: false });
                }}
              >
                {this.getCameraView(
                  url.trim(),
                  cameraType,
                  Platform.OS === "ios" && dimensions.get("landscapeOrientation") ? Dimensions.get("window").width : Dimensions.get("window").height,
                  Platform.OS === "ios" && dimensions.get("landscapeOrientation") ? Dimensions.get("window").height : Dimensions.get("window").width,
                  gretingViewHeight,
                  true
                )}
              </Pressable>
            </View>
          </Modal>
        )}
        <ModalWithContent
          isVisible={showInfo}
          title={t("INFO")}
          onClose={() => {
            this.setState({ showInfo: false });
          }}
        >
          <View
            flexDirection="column"
            style={{
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 20,
            }}
          >
            <SecondaryColorText
              style={{
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {t("CURRENT_CONNECTION_TYPE") + ": " + cameraType}
            </SecondaryColorText>
            <ButtonPrimary
              style={{
                width: 60,
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 20,
                marginLeft: 10,
                marginTop: 10,
              }}
              onPress={() => {
                if (forceChangeType === "auto") {
                  this.initJanus(this.props.janus);
                  store.dispatch(changeCameraConnectionType(device.get("id"), "webrtc"));
                } else if (forceChangeType === "webrtc") {
                  store.dispatch(changeCameraConnectionType(device.get("id"), "image"));
                  setTimeout(() => {
                    mqttClient.askForCamera(this.props.device.get("id"));
                    this.askForTheNewImage = 0;
                  }, 100);
                } else if (forceChangeType === "image") {
                  store.dispatch(changeCameraConnectionType(device.get("id"), "vlc"));
                } else if (forceChangeType === "vlc") {
                  store.dispatch(changeCameraConnectionType(device.get("id"), "auto"));
                } else {
                  store.dispatch(changeCameraConnectionType(device.get("id"), "auto"));
                }
                this.setState({ errors: [] });
              }}
            >
              {!forceChangeType ? t("AUTO") : forceChangeType}
            </ButtonPrimary>
          </View>
          {errors && errors.length > 0 && <PrimaryColorText>{t("ERRORS") + ":"}</PrimaryColorText>}
          {forceChangeType === "vlc" && (currentConnection === "cloud" || currentConnection === "new_cloud") && (
            <SecondaryColorText>FORCE VLC NOT AVAIAIBLE USING CLOUD, USING IMAGE</SecondaryColorText>
          )}
          {errors.map((error, index) => {
            return <SecondaryColorText key={index}>{error}</SecondaryColorText>;
          })}
        </ModalWithContent>

        {header && !this.state.modalVisible && !deviceFromSketch && (
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              paddingBottom: 0,
              height: 30,
            }}
          >
            {header}

            <TouchableOpacity onPress={this.refresh}>
              <SecondaryColorText style={{ fontStyle: "italic" }}>{t("REFRESH")}</SecondaryColorText>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => {
                this.setState({ showInfo: true });
              }}
            >
              <StyledIcon style={{ paddingLeft: 5 }} name={"info"} color={"secondary"} size={22} />
            </TouchableOpacity>
          </View>
        )}

        {isFocused && !this.state.modalVisible && appState === "active" && (
          <Pressable
            onPress={async () => {
              if (popup) {
                changePopupObjectId(null);
                changeAnsweredCall(null);
                setDuringTalk(null);
              } else {
                this.lockAndSaveOrientation();
                this.setState({ modalVisible: true });
              }
            }}
          >
            {this.getCameraView(url.trim(), cameraType, webViewWidth, webViewHeight, gretingViewHeight)}
          </Pressable>
        )}
        {popup && (
          <View
            style={{
              flexDirection: "row",
              paddingBottom: 0,
              height: 30,
              right: 0,
              position: "absolute",
            }}
          >
            <TouchableOpacity onPress={this.refresh}>
              <SecondaryColorText style={{ fontStyle: "italic" }}>{t("REFRESH")}</SecondaryColorText>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => {
                this.setState({ showInfo: true });
              }}
            >
              <StyledIcon style={{ paddingLeft: 5 }} name={"info"} color={"secondary"} size={22} />
            </TouchableOpacity>
          </View>
        )}
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    appState: state.statesData.get("appState"),
    currentConnection: state.statesData.get("currentConnection"),
    currentTheme: state.globalSettings.get("primaryTheme"),
    showList: state.globalSettings.get("showList"),
    connectionStatus: state.statesData.get("connectionStatus"),
    sideMenuSize: state.globalSettings.get("sideMenuSize"),
    sideMenuShown: state.globalSettings.get("sideMenuShown"),
    serverInfo: state.statesData.get("server").get("serverInfo"),
    janus: state.statesData.get("janus"),
    dimensions: state.statesData.get("dimensions"),
    noConnectionCriticalError: state.statesData.get("noConnectionCriticalError"),
    camerasConnectionType: state.profilesSettings.get(state.profilesSettings.get("currentProfile")).get("camerasConnectionType"),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeBlockDimensions: (block) => dispatch(changeBlockDimensions(block)),
    changeCameraConnectionType: (deviceId, type) => dispatch(changeCameraConnectionType(deviceId, type)),
    changePopupObjectId: (id) => dispatch(changePopupObjectId(id)),
    changeAnsweredCall: (id) => dispatch(changeAnsweredCall(id)),
    setDuringTalk: (duringTalk) => dispatch(setDuringTalk(duringTalk)),
  };
};

const CameraUniversalComponent = (props) => {
  const isFocused = useIsFocused();

  return <CameraUniversalComponentX {...props} isFocused={isFocused} />;
};

export default connect(mapStateToProps, mapDispatchToProps)(CameraUniversalComponent);
