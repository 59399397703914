import React, { Component, useContext, useState, useEffect } from "react";

import { View, Text } from "react-native";
import moment from "moment";
import { t } from "../services/i18n";
import * as Localization from "expo-localization";
//ERROR IN WEB FROM BELOW
//WEB to zakomentuj
//import HighchartsReactNative from "@raw2dd4/highcharts-react-native";
//WEB to odkomentuj
import HighchartsReactNative from "../../assets/HighchartsReactNative";
import { connect } from "react-redux";
import { useTheme } from "../../ThemeContext";
import Long from "long";

const HighChartColumnView = ({
  deviceName,
  params_device,
  chartData,
  options,
  deviceType,
  additionalDevice,
  timeRange,
  tzOffset,
  dimensions,
  dimensionsProps,
}) => {
  const { theme } = useTheme();

  const landscapeOrientation = dimensionsProps.get("landscapeOrientation");
  const width = dimensionsProps.get("width");
  const height = dimensionsProps.get("height");

  const power = params_device.get("param1");
  const unit = params_device.get("url").trim();

  const [chartOptionsState, setChartOptions] = useState({
    chart: {
      backgroundColor: theme.COMPONENT_BACKGROUND_COLOR,
      height: dimensions ? dimensions.height - 35 : landscapeOrientation ? height - 200 : width,
      type: "column",
    },
    title: {
      text: deviceName ? `${deviceName}` : null,
      style: {
        color: theme.TEXT_COLOR_PRIMARY,
      },
    },
    legend: {
      itemStyle: { fontSize: 12 },
      itemDistance: 10,
      enabled: false,
    },
    credits: {
      enabled: false,
    },

    plotOptions: {
      series: {
        color: theme.ICONS_COLOR_PRIMARY,
        borderColor: theme.SLIDER_CIRCLE_BORDER,
      },
    },
    tooltip: {
      pointFormat: "<b>{point.y}%</b>",
      shared: true,
      valueDecimals: 2,
    },
    yAxis: {
      title: {
        text: null,
      },
    },
    xAxis: {
      type: "datetime",
    },
    series: [],
  });

  const chooseMomentLocale = (locale) => {
    // make the locale lower case
    // will fix crashes caused by "en-GB" (instead of "en-gb") not being found
    locale = locale.toLowerCase();
    if (moment.locales().includes(locale)) {
      // check if the locale is included in the array returned by `locales()` which (in this case) tells us which locales moment will support
      return locale;
    } else if (moment.locales().includes(locale.substring(0, 2))) {
      // check if the first two letters of the locale are included in the array returned by `locales()` which (in this case) tells us which locales moment will support
      // will fixes crashes caused by "en-US" not being found, as we'll tell moment to load "en" instead
      return locale.substring(0, 2);
    }
    // use "en-gb" (the default language and locale for my app) as a fallback if we can't find any other locale
    return "en-gb";
  };

  useEffect(() => {
    if (chartData && chartData.get("from") && chartData.get("to") && chartData.get("step") && chartData.get("data").size) {
      let chartOptions = Object.assign({}, chartOptionsState); //creating copy of object in state
      chartOptions.series = [];
      let serie = [];
      let maxValue = 1;
      let onChartMaxValue = null;
      let onChartMinValue = null;
      let sum = 0;
      const params =
        params_device && params_device.get("params") ? Long.fromString("0x" + params_device.get("params").toString(16), 16) : new Long(0);

      if (deviceType === "led") maxValue = 255;
      if (deviceType === "led" || deviceType === "przekaznik" || deviceType === "flaga" || deviceType === "wej") {
        onChartMaxValue = 100;
        onChartMinValue = 0;
      }
      chartData.get("data").map((el) => {
        let percent = Math.round((el.get("y") * 100) / chartData.get("step") / maxValue);
        if (!params.and(Long.fromString("0x100000000", 16)).isZero()) {
          // przyrostowa
          serie.push({
            x: el.get("x") * 1000,
            y: el.get("y"),
          });
          sum += el.get("y");
        } else if (power > 1) {
          let kwh = (percent * power * (chartData.get("step") / 3600)) / 1000 / 100;
          serie.push({
            x: el.get("x") * 1000,
            y: kwh,
          });
          sum += kwh;
        } else if (unit === "W") {
          serie.push({
            x: el.get("x") * 1000,
            y: Math.round(el.get("y") / 3600),
          });
          sum += Math.round(el.get("y") / 3600);
        } else if (!onChartMaxValue) {
          serie.push({
            x: el.get("x") * 1000,
            y: percent / 100,
          });
          sum += percent / 100;
        } else {
          serie.push({
            x: el.get("x") * 1000,
            y: percent,
          });
        }
      });
      let data;

      chartOptions.series.push(
        (data = {
          data: serie,
          name: power * chartData.get("step"),
          color: theme.ICONS_COLOR_PRIMARY,
        })
      );
      chartOptions.xAxis.min = chartData.get("from") * 1000;
      chartOptions.xAxis.max = chartData.get("to") * 1000;
      chartOptions.xAxis.tickInterval = chartData.get("step") * 1000;

      if (power > 1) {
        onChartMaxValue = null;
        chartOptions.tooltip.pointFormat = "<b>{point.y} kWh</b>";
      } else if (
        deviceType == "bit32" ||
        deviceType == "bit16" ||
        deviceType == "sbit16" ||
        deviceType == "bit8" ||
        deviceType == "lin_wej" ||
        deviceType == "moc_przyr" ||
        deviceType == "moc_ciagla" ||
        deviceType == "sensor"
      ) {
        chartOptions.tooltip.pointFormat = "<b>{point.y} " + unit;
        +"</b>";
      }

      if (chartData.get("step") >= 27 * 24 * 3600) {
        chartOptions.tooltip.xDateFormat = "%Y-%m";
      } else if (chartData.get("step") >= 24 * 3600) {
        chartOptions.tooltip.xDateFormat = "%Y-%m-%d";
      }
      chartOptions.yAxis.min = onChartMinValue;
      chartOptions.yAxis.max = onChartMaxValue;
      if (sum) {
        chartOptions.title.text = deviceName ? `${deviceName} - ${sum.toFixed(2)} ${unit}` : `${sum.toFixed(2)} ${unit}`;
      }
      //chartOptions.plotOptions.series.pointWidth = ((width * 0.9) / chartData.get("data").size) * 0.5;

      setChartOptions(chartOptions);
    }
  }, [chartData]);

  moment.locale(chooseMomentLocale(Localization.locale));

  return (
    <HighchartsReactNative
      styles={{
        width: "100%",
        height: "100%",
        opacity: 0.99,
      }}
      // THIS IS NEEDED FOR WEB
      // viewStyle={{
      // 	width: "100%",
      // 	height: "100%",
      // }}
      webviewStyles={{
        backgroundColor: "transparent",
        opacity: 0.99,
      }}
      options={chartOptionsState}
      setOptions={{
        lang: {
          months: moment.months(),
          weekdays: moment.weekdays(),
          shortMonths: moment.monthsShort(),
        },
      }}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    tzOffset: state.statesData.get("server").get("serverInfo").get("tzOffset"),
    dimensionsProps: state.statesData.get("dimensions"),
  };
};

export default connect(mapStateToProps)(HighChartColumnView);
