import React, { Component, useState } from "react";
import { View, TouchableOpacity, Text } from "react-native";
import { connect } from "react-redux";
import { fromJS } from "immutable";
import { FontAwesome } from "@expo/vector-icons";
import { t } from "../../services/i18n";
import { scenesReceived } from "../../store/actions";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { createSelector } from "reselect";
import ModalSimple from "../../components/UI/ModalSimple";
import { ComponentContainer, PrimaryColorText, StyledIcon, ButtonPrimary } from "../../components/UI/styledComponents";
import { store } from "../../store/configureStore";
import HeaderWithDotsScenes from "../../utils/HeaderWithDotsScenes";
import PickerSelectObjects from "../../components/UI/PickerSelectObjects";
import { ScreenView } from "../../components/UI/screenViewX";
import { NoDataScreen, TextInputStyled, ComponentContainerTouchable } from "../../components/UI/styledComponents";
import mqttClient from "../../services/mqtt";
import ModalWithContent from "../../components/UI/ModalWithContent";
import { SceneInGroup } from "../../utils";
import { scenesForScenes } from "../../utils";
import ButtonsSelector from "../../components/UI/ButtonsSelector";
import { TabView, SceneMap, TabBar } from "react-native-tab-view";
import { useTheme } from "../../../ThemeContext";

class ScenesScreenChild extends Component {
  constructor(props) {
    super(props);
    this.modes = [
      {
        label: t("FOR_ME"),
        value: 0,
      },
      {
        label: t("FOR_ALL"),
        value: 1,
      },
    ];
  }

  state = {
    sceneDialogVisible: false,
    sceneIdentDialogVisible: false,
    editMode: false,
    comunicatDialogVisible: false,
    sceneName: "",
    selectedGroup: -1,
    editedSceneName: "",
    sceneIdent: "",
    scenes: null,
    selectedMode: this.props.route && this.props.route.key == "mine" ? 0 : 1,
  };

  setNavigationOptions = () => {
    const { scenes, navigation, permission, order, listOfScenes } = this.props;

    if (this.props.index === 0 && this.props.route.key === "mine") {
      navigation.setOptions({
        headerRight: () =>
          (permission & 0x01) == 0 ? (
            <TouchableOpacity style={{ padding: 10 }} onPress={() => this.showDialog()}>
              <StyledIcon name={"plus"} color={"tabBar"} size={24} />
            </TouchableOpacity>
          ) : null,
      });
    } else if (this.props.index === 1 && this.props.route.key === "all") {
      navigation.setOptions({
        headerRight: () =>
          (permission & 0x01) == 0 ? (
            <TouchableOpacity style={{ padding: 10 }} onPress={() => this.showDialog()}>
              <StyledIcon name={"plus"} color={"tabBar"} size={24} />
            </TouchableOpacity>
          ) : null,
      });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    const { scenes, navigation, permission, order, listOfScenes } = this.props;

    this.setNavigationOptions();
  }

  componentDidMount() {
    const { scenes, navigation, permission, order, listOfScenes } = this.props;

    //let listOfScenes = scenesForScenes(scenes, order);
    const devicesStates = store.getState().statesData.get("states");

    devicesStates &&
      listOfScenes.forEach((scene) => {
        if (scene) {
          scene.active = scene.get("Infos").every((sceneDevice) => {
            let deviceState = devicesStates.get(sceneDevice.get("id"));
            if (deviceState && deviceState.get("state") == sceneDevice.get("value")) {
              return true;
            }
            return false;
          });
        }
      });

    this.setState({ scenes: listOfScenes });
    this.setNavigationOptions();
  }

  onChangeSceneNameHandler = (value) => {
    this.setState({
      sceneName: value,
    });
  };

  onChangeSceneIdentHandler = (value) => {
    this.setState({
      sceneIdent: value,
    });
  };

  showDialog = () => {
    this.setState({ sceneDialogVisible: true });
  };

  handleCancel = () => {
    this.setState({
      sceneDialogVisible: false,
      sceneIdentDialogVisible: false,
      editMode: false,
      sceneName: "",
      selectedGroup: 0,
    });
  };

  editScene = (sceneName) => {
    let sceness = this.state.scenes.find((x) => x.get("sceneName") == sceneName);
    if (!sceness) return;
    this.setState({
      selectedGroup: sceness.get("parentId"),
      sceneDialogVisible: true,
      editMode: true,
      sceneName: sceness.get("sceneName"),
      editedSceneName: sceness.get("sceneName"),
    });
  };

  copyScene = (sceneName) => {
    let sceness = this.state.scenes.find((x) => x.get("sceneName") == sceneName);
    const { serverVersion } = this.props;

    let tmpScene = sceness.toJS();
    tmpScene.sceneName = tmpScene.sceneName + " - copy";

    tmpScene.id = -1;

    let newScene = fromJS(tmpScene);
    let newScenes = this.state.scenes.push(newScene);
    this.setState({ scenes: newScenes }, () => {
      if (serverVersion >= 750) {
        mqttClient.sendSingleScene(newScene);
      } else {
        mqttClient.sendScenes(this.state.scenes);
      }
    });
  };

  editSceneIdent = (sceneName) => {
    let sceness = this.state.scenes.find((x) => x.get("sceneName") == sceneName);

    if (!sceness) return;
    this.setState({
      selectedGroup: sceness.get("parentId"),
      sceneIdentDialogVisible: true,
      editMode: true,
      sceneName: sceness.get("sceneName"),
      editedSceneName: sceness.get("sceneName"),
      sceneIdent: sceness.get("sceneIdent"),
    });
  };

  onOKDuplicatedName = () => {
    if (this.state.editMode) {
      this.setState({
        comunicatDialogVisible: false,
      });
    } else {
      this.setState({
        comunicatDialogVisible: false,
        sceneDialogVisible: true,
      });
    }
  };
  removeScene = (sceneID) => {
    const { serverVersion } = this.props;
    let { scenes } = this.state;
    let newScenes = scenes.filter((el) => {
      return el && el.get("id") != sceneID;
    });
    this.setState({ scenes: newScenes }, () => {
      if (serverVersion >= 750) {
        mqttClient.removeSingleScene({ remove: sceneID });
      } else {
        mqttClient.sendScenes(this.state.scenes);
      }
    });
  };

  handleAdd = (mode) => {
    const { sceneName, selectedGroup, scenes, editedSceneName } = this.state;
    const { serverVersion } = this.props;
    this.handleCancel();

    if (mode === "add") {
      if (scenes.some((scene) => scene && scene.get("sceneName") == sceneName)) {
        this.setState({
          comunicatDialogVisible: true,
          sceneDialogVisible: false,
        });
      } else {
        let newScene = fromJS({
          id: null,
          lp: scenes.size,
          parentId: selectedGroup,
          sceneName: sceneName,
          Actions: [],
          Infos: [],
          Schedules: [],
          mode: this.state.selectedMode,
        });

        let newScenes = scenes.push(newScene);
        this.setState({ scenes: newScenes }, () => {
          this.props.navigation.navigate("SceneDetails", {
            sceneName: newScene.get("sceneName"),
            scene: newScene,
            changeScene: this.changeScene,
          });
        });
      }
    } else if (mode == "edit_ident") {
      let indexToUpdate = this.state.scenes.findIndex((x) => x.get("sceneName") == this.state.editedSceneName);
      let newInfos = this.state.scenes;
      newInfos = newInfos.setIn([indexToUpdate, "sceneIdent"], this.state.sceneIdent);
      this.setState({ scenes: newInfos }, () => {
        if (serverVersion >= 750) {
          mqttClient.sendSingleScene(newInfos.get(indexToUpdate));
        } else {
          mqttClient.sendScenes(this.state.scenes);
        }
      });
    } else {
      if (editedSceneName != sceneName && scenes.some((scene) => scene && scene.get("sceneName") == sceneName)) {
        this.setState({
          comunicatDialogVisible: true,
          sceneDialogVisible: false,
        });
      } else {
        let indexToUpdate = this.state.scenes.findIndex((x) => x.get("sceneName") == this.state.editedSceneName);
        let newInfos = this.state.scenes;
        newInfos = newInfos.setIn([indexToUpdate, "sceneName"], this.state.sceneName).setIn([indexToUpdate, "parentId"], this.state.selectedGroup);
        this.setState({ scenes: newInfos }, () => {
          if (serverVersion >= 750) {
            mqttClient.sendSingleScene(newInfos.get(indexToUpdate));
          } else {
            mqttClient.sendScenes(this.state.scenes);
          }
        });
      }
    }
  };

  changeScene = (sceneName, data) => {
    const { serverVersion } = this.props;
    let indexToUpdate = this.state.scenes.findIndex((x) => x.get("sceneName") == sceneName);
    let newInfos = this.state.scenes;
    let newScenes = newInfos.set(indexToUpdate, data);
    this.setState({ scenes: newScenes }, () => {
      if (serverVersion >= 750) {
        mqttClient.sendSingleScene(data);
      } else {
        mqttClient.sendScenes(newScenes);
      }
    });
  };

  onGroupValueChange = (value) => {
    this.setState({ selectedGroup: value });
  };

  controlScene = (sceneID, option) => {
    mqttClient.controlScene(sceneID, option);
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.scenes != this.props.scenes || nextProps.order != this.props.order) {
      let onlyForUser = false;
      if (this.props.route && this.props.route.key == "mine") {
        onlyForUser = true;
      }
      let listOfScenes = onlyForUser
        ? scenesForScenes(nextProps.scenes, nextProps.order, this.props.userName)
        : scenesForScenes(nextProps.scenes, nextProps.order);

      this.setState({ scenes: listOfScenes });
    }
  }
  render() {
    const { permission, globalSettings, dimensions, serverInfo } = this.props;
    let permissionForEditScene = (permission & 0x01) == 0;
    let permissionForCreatingForAll = (permission & 0x80000) == 0;
    let isBig = false;
    let allUsers = false;
    if (this.props.route && this.props.route.key == "all") {
      allUsers = true;
    }

    let numberOfColumns = dimensions.get("numberOfColumns");
    const showByColumns = globalSettings.get("showByColumns") == undefined ? true : globalSettings.get("showByColumns");

    if (showByColumns && numberOfColumns != 1) {
      isBig = true;
    }

    const { sceneDialogVisible, sceneIdentDialogVisible, comunicatDialogVisible, selectedGroup, sceneName, sceneIdent, scenes, editMode } =
      this.state;
    let additionalGroup = {
      label: t("scenes:SHOW_ONLY_IN_SCENES"),
      value: -1,
    };

    // let addition
    const lista =
      scenes &&
      scenes.map((scene) => {
        if (scene) {
          return permissionForEditScene ? (
            <ComponentContainerTouchable
              key={`${scene.get("sceneName")}${scene.get("id")}`}
              style={{
                flexDirection: isBig ? "column" : "row",
                width: "100%",
              }}
            >
              <TouchableOpacity
                style={{
                  paddingBottom: isBig ? 15 : 0,
                  alignSelf: "center",
                  flex: 1,
                  overflow: "hidden",
                }}
                onPress={() => {
                  this.props.navigation.navigate("SceneDetails", {
                    sceneName: scene.get("sceneName"),
                    scene: scene,
                    changeScene: this.changeScene,
                  });
                }}
              >
                <HeaderWithDotsScenes
                  sceneID={scene.get("id")}
                  sceneName={scene.get("sceneName")}
                  sceneLabel={
                    allUsers && scene.get("userName") ? scene.get("sceneName") + " (" + scene.get("userName") + ")" : scene.get("sceneName")
                  }
                  removeScene={this.removeScene}
                  editScene={this.editScene}
                  editSceneIdent={this.editSceneIdent}
                  copyScene={this.copyScene}
                  isBig={isBig}
                >
                  {scene.active && <MaterialCommunityIcons name={"check"} size={22} color={"rgb(50, 200, 80)"} style={{ paddingLeft: 5 }} />}
                  {!scene.get("Schedules").isEmpty() && <StyledIcon color="primary" size={20} name="clock" />}
                </HeaderWithDotsScenes>
              </TouchableOpacity>
              <View
                style={{
                  alignItems: "flex-end",
                  flexDirection: "row",
                  width: isBig ? "auto" : 100,
                  justifyContent: "space-evenly",
                }}
              >
                <TouchableOpacity style={{ paddingHorizontal: 6 }} onPress={() => this.controlScene(scene.get("id"), "run")}>
                  <FontAwesome name="play-circle" color={"#999"} size={isBig ? 32 : 24} />
                </TouchableOpacity>
                <TouchableOpacity style={{ paddingHorizontal: 6 }} onPress={() => this.controlScene(scene.get("id"), "undo")}>
                  <FontAwesome name="undo" color={"#999"} size={isBig ? 32 : 24} />
                </TouchableOpacity>
                <TouchableOpacity style={{ paddingHorizontal: 6 }} onPress={() => this.controlScene(scene.get("id"), "off")}>
                  <FontAwesome name="power-off" color={"#999"} size={isBig ? 32 : 24} />
                </TouchableOpacity>
              </View>
            </ComponentContainerTouchable>
          ) : (
            <SceneInGroup key={`${scene.get("sceneName")}${scene.get("id")}`} scene={scene} />
          );
        }
      });

    return (
      <View style={{ flex: 1 }}>
        {lista && lista.size ? <ScreenView>{lista}</ScreenView> : <NoDataScreen />}
        {sceneDialogVisible && (
          <ModalWithContent
            isVisible={sceneDialogVisible}
            title={editMode ? t("scenes:EDIT") : t("scenes:NEW_SCENE")}
            onClose={this.handleCancel}
            error={serverInfo && serverInfo.get("userId") == -1 ? t("NOT_AVAILABLE_IN_DEBUG_MODE") : null}
          >
            <View style={{ paddingBottom: 16 }}>
              <TextInputStyled placeholder={t("scenes:ENTER_NAME")} value={sceneName} onChangeText={this.onChangeSceneNameHandler} />
            </View>
            <View style={{ paddingBottom: 16 }}>
              <PrimaryColorText style={{ paddingBottom: 4 }}>{t("scenes:SELECT_LOCATION")}</PrimaryColorText>
              <PickerSelectObjects
                additionalGroup={additionalGroup}
                onValueChange={this.onGroupValueChange}
                value={selectedGroup}
                type={"groups"}
                overWriteLabel={t("scenes:SELECT_LOCATION")}
              />
            </View>
            {permissionForCreatingForAll && (
              <View style={{ paddingBottom: 16 }}>
                <ButtonsSelector
                  fontSize={14}
                  onChangeTab={(value) => {
                    this.setState({ selectedMode: value });
                  }}
                  active={this.state.selectedMode}
                  tabs={this.modes}
                />
              </View>
            )}
            <ButtonPrimary onPress={() => this.handleAdd(editMode ? "change" : "add")}>{editMode ? t("CHANGE") : t("ADD")}</ButtonPrimary>
          </ModalWithContent>
        )}

        {sceneIdentDialogVisible && (
          <ModalWithContent isVisible={sceneIdentDialogVisible} title={t("scenes:EDIT")} onClose={this.handleCancel}>
            <View style={{ paddingBottom: 16 }}>
              <TextInputStyled placeholder={t("scenes:ENTER_NAME_IDENT")} value={sceneIdent} onChangeText={this.onChangeSceneIdentHandler} />
            </View>
            <ButtonPrimary onPress={() => this.handleAdd("edit_ident")}>{"change"}</ButtonPrimary>
          </ModalWithContent>
        )}

        {comunicatDialogVisible && (
          <ModalSimple isVisible={comunicatDialogVisible} description={t("scenes:NAME_TAKEN")} onOK={this.onOKDuplicatedName} />
        )}
      </View>
    );
  }
}

// const possibleStatesToScenes = createSelector(
//   [
//     (state) => state.statesData.get("states"),
//     (state, devicesInScenes) => devicesInScenes,
//   ],
//   (smartHomeData, devicesInScenes) => {
//     const filteredItems = smartHomeData.map((device, index) => {
//       if (device && devicesInScenes.includes(index)) {
//         return device;
//       } else {
//         return null;
//       }
//     });
//     return filteredItems;
//   }
// );

const mapStateToProps = (state) => {
  const scenes = state.smartHomeData.get(state.profilesSettings.get("currentProfile")).get("scenes");
  const order = state.profilesSettings.get(state.profilesSettings.get("currentProfile")).get("order");
  let currentProfile = state.profilesSettings.get("currentProfile");
  return {
    dimensions: state.statesData.get("dimensions"),
    scenes: scenes,
    globalSettings: state.globalSettings,
    permission: state.statesData.get("server").get("serverInfo").get("perm"),
    serverInfo: state.statesData.get("server").get("serverInfo"),
    serverVersion: state.statesData.get("server").get("serverInfo").get("serverVersion"),
    userName: state.profilesSettings.get(currentProfile).get("loginData").get("login"),
    //devicesStates: possibleStates,
    order: order,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveScenesTemporary: (scenes) => dispatch(scenesReceived(scenes)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ScenesScreenChild);
