const scenesForScenes = (data, order, userName) => {
  let filteredArray = null;
  if (data) {
    filteredArray = data;

    let scenesGeneralOrder = order && order.get("scenes");
    if (scenesGeneralOrder) {
      filteredArray = filteredArray.map((scene) => {
        let inOrderListIndex = scenesGeneralOrder.findIndex((el) => {
          return el == scene.get("id");
        });
        if (inOrderListIndex == -1) {
          inOrderListIndex = undefined;
        }
        scene = scene.set("orderNumber", inOrderListIndex);
        return scene;
      });
      filteredArray = filteredArray.sort((x, y) => {
        return x.get("orderNumber") - y.get("orderNumber");
      });
    }
  }
  if (userName) {
    return filteredArray.filter((scene) => scene.get("userName") === userName);
  } else {
    return filteredArray;
  }
};

export default scenesForScenes;
