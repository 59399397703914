import React, { useEffect, useState } from "react";
import { View, Animated, StyleSheet, Text } from "react-native";
import { Ionicons } from "@expo/vector-icons";
import DefaultIcon from "../../../screens/Dashboard/weather/defaultIcon";
import { ComponentContainer, PrimaryColorText } from "../../UI/styledComponents";
import { useTheme } from "../../../../ThemeContext";
import { HighChartView } from "../../../utils";
import HighChartDonutView from "../../../utils/HighChartDonutView";
import TriangleComponent from "./TriangleComponent";
import HighChartBarPositiveAndNegative from "../../../utils/HighChartBarPositiveAndNegative";
import { connect } from "react-redux";
import moment from "moment";
import { store } from "../../../store/configureStore";
import { barChartDataReceived } from "../../../store/actions";
import mqttClient from "../../../services/mqtt";
import { SkottieLoader } from "../../animatedComponents/Loader";
import { t } from "../../../services/i18n";
import ButtonsSelector from "../../UI/ButtonsSelector";
import { set } from "immutable";

const EnergyComponent = (props) => {
  const { header, detailContent, dimensions, deviceFromCustom, device } = props;

  const [productionPower, setProductionPower] = useState(null);
  const [importPower, setImportPower] = useState(null);
  const [exportPower, setExportPower] = useState(null);

  const [importPowerStatus, setImportPowerStatus] = useState(0);
  const [exportPowerStatus, setExportPowerStatus] = useState(0);
  const [productionPowerStatus, setProductionPowerStatus] = useState(0);

  const [currentResolutionIndex, setCurrentResolutionIndex] = useState(1);

  const [currentTimeRangeIndex, setCurrentTimeRangeIndex] = useState(1);

  const timeRangeArray = [
    {
      label: t("account:TODAY"),
      value: 0,
    },
    {
      label: t("account:YESTERDAY"),
      value: 1,
    },
    {
      label: t("account:LAST_7_DAYS"),
      value: 2,
    },
    {
      label: t("account:LAST_MONTH"),
      value: 3,
    },
    {
      label: t("account:LAST_3_MONTHS"),
      value: 4,
    },
  ];

  const barChartResolution = [
    // w sekundach
    {
      label: t("15_MINUTES"),
      value: 900,
    },
    {
      label: t("HOUR"),
      value: 3600,
    },
    {
      label: t("DAY"),
      value: 86400,
    },
    {
      label: t("WEEK"),
      value: 86400 * 7,
    },
    {
      label: t("MONTH"),
      value: 86400 * 30,
    },
  ];

  const getToDate = (currentResolution) => {
    return Math.floor(moment().format("X") / currentResolution) * currentResolution + currentResolution;
  };

  const askForBarCharts = (deviceID) => {
    let from = moment().format("X");
    let to = moment().format("X");
    const currentTimeRange = timeRangeArray[currentTimeRangeIndex].value;
    const currentResolution = barChartResolution[currentResolutionIndex].value;
    if (currentTimeRange == 0) {
      from = from - (from % 86400);
    } else if (currentTimeRange == 1) {
      // YESTERDAY
      const yesterdayEpoch = from - (from % 86400);
      to = yesterdayEpoch;
      from = yesterdayEpoch - 86400;
      // YESTERDAY
    } else if (currentTimeRange == 2) {
      to = getToDate(currentResolution);
      from = to - 86400 * 7;
      // LAST WEEK
    } else if (currentTimeRange == 3) {
      to = getToDate(currentResolution);
      from = to - 86400 * 30;
      // LAST MONTH
    } else if (currentTimeRange == 4) {
      to = getToDate(currentResolution);
      from = to - 86400 * 120;
      // LAST 3 MONTHS
    } else if (currentTimeRange == 5) {
      to = getToDate(currentResolution);
      from = to - 86400 * 365;
      // LAST YEAR
    } else if (currentTimeRange == 8) {
      to = getToDate(currentResolution);
      from = to - 3600 * 24;
      // LAST 24
    }

    let numOfSteps = Math.ceil((to - from) / currentResolution);
    let message = `/api/json/device/${deviceID}/barCharts/${from}/${to}/${numOfSteps}`;
    mqttClient.askForBarCharts(message, deviceID);
  };

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    if (detailContent) {
      let params = {};
      try {
        params = JSON.parse(device.get("data"));
        if (params.totalExportedToGridId && params.totalImportedFromGridId && params.totalProductionId) {
          setExportPowerStatus(0);
          setImportPowerStatus(0);
          setProductionPowerStatus(0);
          store.dispatch(barChartDataReceived(params.totalExportedToGridId, null));
          store.dispatch(barChartDataReceived(params.totalProductionId, null));
          store.dispatch(barChartDataReceived(params.totalImportedFromGridId, null));
        }
      } catch (e) {
        console.log(e);
      }
    }
  };

  useEffect(() => {
    if (detailContent) {
      if (props.productionPower && props.productionPower.get("data")) {
        setProductionPower(props.productionPower);
        if (productionPowerStatus == 1) {
          setProductionPowerStatus(2);
        }
      } else if (params && params.totalProductionId) {
        setProductionPowerStatus(1);
        askForBarCharts(params.totalProductionId);
      }
    }
  }, [props.productionPower]);

  useEffect(() => {
    if (detailContent) {
      if (props.exportPower && props.exportPower.get("data")) {
        setExportPower(props.exportPower);
        if (exportPowerStatus == 1) {
          setExportPowerStatus(2);
        }
      } else if (params && params.totalExportedToGridId) {
        setExportPowerStatus(1);
        askForBarCharts(params.totalExportedToGridId);
      }
    }
  }, [props.exportPower]);

  useEffect(() => {
    if (detailContent) {
      if (props.importPower && props.importPower.get("data")) {
        setImportPower(props.importPower);
        if (importPowerStatus == 1) {
          setImportPowerStatus(2);
        }
      } else if (params && params.totalImportedFromGridId) {
        setImportPowerStatus(1);
        askForBarCharts(params.totalImportedFromGridId);
      }
    }
  }, [props.importPower]);

  let params = {};
  try {
    params = JSON.parse(device.get("data"));
  } catch (e) {
    console.log(e);
  }
  let columnWidth = dimensions.get("width");
  let webViewWidth = columnWidth - 20;
  let webViewHeight = webViewWidth;

  return detailContent ? (
    <View style={{ flexDirection: "column" }}>
      <ComponentContainer numOfColumns={1}>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            height: dimensions.get("smallTiles") ? 40 : undefined,
          }}
        >
          {!deviceFromCustom && header}
        </View>
      </ComponentContainer>

      <ComponentContainer numOfColumns={1}>
        <TriangleComponent {...props} />
      </ComponentContainer>

      {params && params.totalExportedToGridId && params.totalImportedFromGridId && params.totalProductionId && (
        <>
          <ComponentContainer numOfColumns={1}>
            <ButtonsSelector
              active={currentTimeRangeIndex}
              onChangeTab={(value) => {
                setCurrentTimeRangeIndex(value);
                getData();
              }}
              tabs={timeRangeArray}
              fontSize={12}
              style={{ marginBottom: 10 }}
            />
          </ComponentContainer>
          <ComponentContainer numOfColumns={1}>
            {exportPower && importPower && productionPower && importPowerStatus === 2 && exportPowerStatus === 2 && productionPowerStatus === 2 ? (
              <HighChartDonutView
                dimensions={{
                  width: webViewWidth,
                  height: webViewHeight,
                }}
                productionPower={productionPower}
                importPower={importPower}
                exportPower={exportPower}
              />
            ) : (
              <SkottieLoader style={{ width: 40, height: 40 }} />
            )}
          </ComponentContainer>
          <ComponentContainer numOfColumns={1}>
            <PrimaryColorText style={{ fontSize: 12, alignItems: "center", justifyContent: "center", flex: 1, textAlign: "center" }}>
              {t("BAR_TIME_WIDTH")}
            </PrimaryColorText>
            <ButtonsSelector
              active={currentResolutionIndex}
              onChangeTab={(value) => {
                setCurrentResolutionIndex(value);
                getData();
              }}
              tabs={barChartResolution}
              fontSize={12}
              style={{ marginBottom: 10 }}
            />
          </ComponentContainer>
          <ComponentContainer numOfColumns={1}>
            {exportPower && importPower && productionPower && importPowerStatus === 2 && exportPowerStatus === 2 && productionPowerStatus === 2 ? (
              <HighChartBarPositiveAndNegative
                dimensions={{
                  width: webViewWidth,
                  height: webViewHeight,
                }}
                productionPower={productionPower}
                importPower={importPower}
                exportPower={exportPower}
                dateFormat={currentResolutionIndex < 2 ? "%Y-%m-%d %H:%M" : "%Y-%m-%d"}
              />
            ) : (
              <SkottieLoader style={{ width: 40, height: 40 }} />
            )}
          </ComponentContainer>
        </>
      )}
    </View>
  ) : (
    <View>
      <View>{header}</View>
      <TriangleComponent {...props} />
    </View>
  );
};

const mapStateToProps = (state, props) => {
  let params = {};
  try {
    params = JSON.parse(props.device.get("data"));
  } catch (e) {
    console.log(e);
  }

  return {
    tzOffset: state.statesData.get("server").get("serverInfo").get("tzOffset"),
    dimensionsProps: state.statesData.get("dimensions"),
    exportPower:
      params && params.totalExportedToGridId && state.statesData.get("states").get(String(params.totalExportedToGridId))
        ? state.statesData.get("states").get(String(params.totalExportedToGridId)).get("barChartData")
        : null,
    importPower:
      params && params.totalImportedFromGridId && state.statesData.get("states").get(String(params.totalImportedFromGridId))
        ? state.statesData.get("states").get(String(params.totalImportedFromGridId)).get("barChartData")
        : null,
    productionPower:
      params && params.totalProductionId && state.statesData.get("states").get(String(params.totalProductionId))
        ? state.statesData.get("states").get(String(params.totalProductionId)).get("barChartData")
        : null,
  };
};

export default connect(mapStateToProps)(EnergyComponent);
