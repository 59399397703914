import React, { Component, useState } from "react";

import { View, Text, StyleSheet, TouchableOpacity, Animated, ActivityIndicator } from "react-native";
import { ScreenView } from "../../components/UI/screenViewX";
import { PrimaryColorText, ComponentContainer, StyledIcon, ButtonPrimary, SliderStyled } from "../../components/UI/styledComponents";
import DateTimePicker from "../../components/UI/dateTimePicker";
import moment from "moment";
import { useSelector } from "react-redux";
import DefaultIcon from "../Dashboard/weather/defaultIcon";
import { FontAwesome, MaterialCommunityIcons } from "@expo/vector-icons";

import { t } from "../../services/i18n";
import mqttClient from "../../services/mqtt";
import { SkottieLoader } from "../../components/animatedComponents/Loader";
import { ThemeContext, useTheme } from "../../../ThemeContext";
import PickerSelect from "../../components/UI/PickerSelect";

const delayTimes = [
  { label: "1min", value: 6000 },
  { label: "2min", value: 12000 },
  { label: "3min", value: 18000 },
  { label: "4min", value: 24000 },
  { label: "5min", value: 30000 },
  { label: "6min", value: 36000 },
  { label: "7min", value: 42000 },
  { label: "8min", value: 48000 },
  { label: "9min", value: 54000 },
  { label: "10min", value: 60000 },
  { label: "11min", value: 66000 },
  { label: "12min", value: 72000 },
  { label: "13min", value: 78000 },
  { label: "14min", value: 84000 },
  { label: "15min", value: 90000 },
  { label: "16min", value: 96000 },
  { label: "17min", value: 102000 },
  { label: "18min", value: 108000 },
  { label: "19min", value: 114000 },
  { label: "20min", value: 120000 },
  { label: "21min", value: 126000 },
  { label: "22min", value: 132000 },
  { label: "23min", value: 138000 },
  { label: "24min", value: 144000 },
  { label: "25min", value: 150000 },
  { label: "26min", value: 156000 },
  { label: "27min", value: 162000 },
  { label: "28min", value: 168000 },
  { label: "29min", value: 174000 },
  { label: "30min", value: 180000 },
];

const DeviceComponent = ({ action, index, modifyAction, actions }) => {
  let [time, setTime] = useState(action.time);
  const device = useSelector((state) => state.smartHomeData.get(state.profilesSettings.get("currentProfile")).get("devices").get(action.object_id));
  const deviceState = useSelector((state) => state.statesData.get("states").get(action.object_id));
  const onSlidingChangeHandler = (value) => {};
  const { theme } = useTheme();
  const onSlidingCompleteHandler = (value) => {
    setTime(value);
    modifyAction(value, "time", index);
  };

  const [delay, setDelay] = useState(
    index > 0 && index < actions.length ? actions[index].delay - (actions[index - 1].time + actions[index - 1].delay) : 0
  );

  const toggleValue = () => {
    // this.sendMessage(this.state.value);
    let message = deviceState.get("state") == "0" ? action.action : `/api/set/${action.object_id}/setValue/0`;
    mqttClient.stateChangeToSend(message, action.object_id);
  };

  const changeDiff = (value, index) => {
    modifyAction(value, "value2", index - 1);
  };

  return (
    <View>
      {index > 0 && index < actions.length && (
        <View style={{ flex: 1, justifyContent: "center", alignItems: "center", marginBottom: 5 }}>
          <PickerSelect
            placeholder={{
              label: t("DELAY"),
              value: null,
            }}
            items={delayTimes}
            onValueChange={(value) => setDelay(value)}
            onFinalChange={(value) => changeDiff(value, index)}
            containerStyle={{
              width: 120,
            }}
            inputStyle={{
              borderWidth: 0,
              backgroundColor: theme.COMPONENT_BACKGROUND_COLOR,
              color: theme.TEXT_COLOR_PRIMARY,
            }}
            value={delay}
          />
        </View>
      )}

      <ComponentContainer containerStyle={{ width: "100%" }}>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
            <MaterialCommunityIcons name="sprinkler-variant" size={30} color={theme.ICONS_COLOR_SECONDARY} />
            <PrimaryColorText style={{ textAlign: "center", paddingLeft: 10 }}>{device ? device.get("opis_menu") : null}</PrimaryColorText>
          </View>
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            {deviceState ? (
              <TouchableOpacity onPress={toggleValue} style={{ paddingHorizontal: 10 }}>
                {deviceState.get("state") == "0" ? (
                  <FontAwesome name="play-circle" color={"rgb(50, 200, 80)"} size={30} />
                ) : (
                  <View style={{ flexDirection: "row", alignItems: "center" }}>
                    <SkottieLoader style={{ width: 23, height: 23, margin: 3, paddingRight: 5 }} />

                    <FontAwesome name="stop-circle" color={"rgb(250, 85, 85)"} size={30} />
                  </View>
                )}
              </TouchableOpacity>
            ) : null}
            <PrimaryColorText>{time / 6000}min</PrimaryColorText>
          </View>
        </View>

        <View
          style={{
            width: "100%",

            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <TouchableOpacity
            style={styles.iconButton}
            onPress={() => {
              if (time - 6000 < 6000) return;
              setTime(time - 6000);
              modifyAction(time - 6000, "time", index);
            }}
          >
            <StyledIcon name={"minus-circle"} color={"primary"} size={24} />
          </TouchableOpacity>
          <SliderStyled
            value={time}
            step={6000}
            minimumValue={6000}
            //60mn * 600
            maximumValue={360000 * 6}
            onValueChange={onSlidingChangeHandler}
            onSlidingComplete={onSlidingCompleteHandler}
          />
          <TouchableOpacity
            style={styles.iconButton}
            onPress={() => {
              if (time + 6000 > 360000 * 6) return;
              setTime(time + 6000);
              modifyAction(time + 6000, "time", index);
            }}
          >
            <StyledIcon name={"plus-circle"} size={24} />
          </TouchableOpacity>
        </View>
      </ComponentContainer>
    </View>
  );
};
class Actions_IrrigationScreen extends Component {
  static contextType = ThemeContext;
  state = {
    editMode: false,
    animationOpacity: new Animated.Value(0),
    animationMargin: new Animated.Value(-42),
  };

  _moveRight = () => {
    Animated.timing(this.state.animationOpacity, {
      toValue: 1,
      duration: 500,
    }).start();

    Animated.timing(this.state.animationMargin, {
      toValue: 0,
      duration: 500,
    }).start();
  };

  _moveBack = () => {
    Animated.timing(this.state.animationOpacity, {
      toValue: 0,
      duration: 500,
    }).start();

    Animated.timing(this.state.animationMargin, {
      toValue: -42,
      duration: 500,
    }).start();
  };
  toggleOnPressMode = () => {
    let newEditMode = !this.state.editMode;
    this.setState({ editMode: newEditMode }, () => {
      if (this.state.editMode) {
        this._moveRight();
      } else {
        this._moveBack();
      }
    });
  };

  removeAction = (index) => {
    this.props.onRemoveAction(index);
  };

  render() {
    const { actions, modifyAction } = this.props;
    const { editMode } = this.state;
    const { theme } = this.context;

    return (
      <ScreenView>
        {actions &&
          actions.map((action, index) => {
            let ActionComponent;
            switch (action.type) {
              case 0:
                ActionComponent = DeviceComponent;
                break;

              default:
                break;
            }

            return (
              <View
                key={`${index}${action.type}${action.value}`}
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Animated.View style={[{ opacity: this.state.animationOpacity }]}>
                  <TouchableOpacity onPress={() => this.removeAction(index)}>
                    <View style={[styles.removeButton]}>
                      <Text
                        style={{
                          color: "white",
                          marginLeft: 1,
                          lineHeight: 13,
                          fontWeight: "bold",
                          fontSize: 10,
                        }}
                      >
                        —
                      </Text>
                    </View>
                  </TouchableOpacity>
                </Animated.View>
                <Animated.View
                  style={{
                    marginLeft: this.state.animationMargin,
                    width: "100%",
                  }}
                >
                  <ActionComponent action={action} actions={actions} index={index} modifyAction={modifyAction} />
                </Animated.View>
              </View>
            );
          })}
        <View
          style={{
            justifyContent: "center",
            width: "100%",
          }}
        >
          <ButtonPrimary
            style={{
              marginVertical: 10,
              paddingVertical: 5,
              // paddingHorizontal: 20,
              width: 100,
              backgroundColor: editMode ? "rgb(40,125,40)" : "rgb(200,00,0)",
            }}
            onPress={this.toggleOnPressMode}
          >
            {editMode ? t("DONE") : t("DELETING")}
          </ButtonPrimary>
        </View>
      </ScreenView>
    );
  }
}

const styles = StyleSheet.create({
  iconButton: {
    padding: 5,
  },
  removeButton: {
    width: 18,
    height: 18,
    backgroundColor: "rgb(200,00,0)",
    color: "white",
    borderRadius: 9,
    // display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginHorizontal: 12,
  },
  days: {
    flexDirection: "row",
    // alignItems: "center",
    justifyContent: "space-between",
    // flex: 1,
  },
});
export default Actions_IrrigationScreen;
