const groupListForPicker = (groups, order, addChildren = true) => {
  let groupsToSend = [];
  if (groups) {
    let groupsOrder = order && order.get("groups");
    if (groupsOrder) {
      groups = groups.map((group) => {
        if (group) {
          let inOrderListIndex = groupsOrder.findIndex((el) => {
            return el == group.get("id");
          });
          if (inOrderListIndex == -1) {
            inOrderListIndex = undefined;
          }
          group = group.set("orderNumber", inOrderListIndex);
          return group;
        }
      });
      groups = groups.sort((x, y) => {
        if (x && y) {
          return x.get("orderNumber") - y.get("orderNumber");
        }
      });
    }

    let addViews = (group, level) => {
      groupsToSend.push(groupElement(group, level));
      addChildren &&
        groups.map((child) => {
          if (child && child.get("id_rodzica") == group.get("id")) {
            addViews(child, level + 1);
            // groupsToSend.push(groupElement(child, level + 1));
          }
        });
    };

    let groupElement = (group, level) => {
      return {
        label: `  ` + "\u21b3 ".repeat(level) + group.get("opis_menu"),
        value: group.get("id"),
      };
    };

    groups &&
      groups.map((group) => {
        if (group && group.get("id_rodzica") == 0) {
          addViews(group, 0);
        }
      });
  }
  return groupsToSend;
};

export default groupListForPicker;
