import React, { Component } from "react";
import { View, Text, TouchableOpacity, Platform } from "react-native";
import { t } from "../../services/i18n";

import { ComponentContainer, PrimaryColorText, TextInputStyled } from "../../components/UI/styledComponents";
import PickerSelect from "../../components/UI/PickerSelect";

const AutomatizationTimeBar = (props) => {
  const { param3, turnOnTimeChange, type } = props;

  const pickerItems = [
    { label: type === "fade" ? t("conditions:INSTANT") : t("conditions:CONSTANT_TIME"), value: 0 },
    { label: type === "fade" ? t("conditions:DIMMING_TIME") : t("conditions:SPECIFIC_TIME"), value: 1 },
  ];

  const pickerTypeItems = [
    { label: "min", value: 0 },
    { label: "s", value: 1 },
  ];

  const [pickerValue, setPickerValue] = React.useState(param3 > 0 ? 1 : 0);
  const [pickerValueType, setPickerValueType] = React.useState(param3 / 60 > 0 && param3 % 60 == 0 ? 0 : 1);

  const [turnOnTime, setTurnOnTime] = React.useState(param3 > 0 ? (pickerValueType == 0 ? (param3 / 60).toString() : param3.toString()) : "");

  const pickerValueChange = (value) => {
    setPickerValue(value);

    if (value == 1) {
      if (turnOnTime <= 0) {
        setTurnOnTime("1");
        turnOnTimeChange(1);
      }
    } else if (value == 0) {
      setTurnOnTime("");
      turnOnTimeChange(-1);
    }
  };

  const onChangeTurnOnTime = (value) => {
    setTurnOnTime(value);
    if (pickerValueType == 0) {
      turnOnTimeChange(Number(value) * 60);
    } else if (pickerValueType == 1) {
      turnOnTimeChange(Number(value) * 1);
    }
  };

  const onChangeTurnOnTimeType = (value) => {
    setPickerValueType(value);
    if (turnOnTime > 0) {
      if (value == 0) {
        turnOnTimeChange(Number(turnOnTime) * 60);
      } else if (value == 1) {
        turnOnTimeChange(Number(turnOnTime) * 1);
      }
    }
  };

  return (
    <View
      style={{
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        marginTop: 16,
        paddingTop: 6,
        paddingHorizontal: 6,
        // borderTopColor: theme.TEXT_COLOR_SECONDARY,
        borderTopWidth: 0.4,
      }}
    >
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <PrimaryColorText
          style={
            {
              // fontSize: 12,
            }
          }
        >
          {t("conditions:TURN_ON")}
        </PrimaryColorText>

        <PickerSelect placeholder={{}} containerStyle={{ width: 140 }} items={pickerItems} onValueChange={pickerValueChange} value={pickerValue} />
      </View>
      {pickerValue !== 0 ? (
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <TextInputStyled
            placeholder={"0"}
            keyboardType={"numeric"}
            value={turnOnTime}
            onChangeText={onChangeTurnOnTime}
            style={{ width: 40, textAlign: "center", paddingRight: 5 }}
          />

          <PickerSelect
            placeholder={{}}
            containerStyle={{ width: 70, paddingHorizontal: 5 }}
            items={pickerTypeItems}
            onValueChange={onChangeTurnOnTimeType}
            value={pickerValueType}
          />
        </View>
      ) : null}
    </View>
  );
};

export default AutomatizationTimeBar;
