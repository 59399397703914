import React, { Component } from "react";
import { View } from "react-native";
import { connect } from "react-redux";
import { t } from "../../../../services/i18n";

import { saveFavourites } from "../../../../store/actions";
import DraggableFlatList from "react-native-draggable-flatlist";
import DraggableItem from "./DraggableItem";
import { ThemeContext } from "../../../../../ThemeContext";
import { NoDataScreen } from "../../../../components/UI/styledComponents";

class Favourites_Order_PersonalizationScreen extends Component {
	static contextType = ThemeContext;
	state = {
		favouritesList: [],
	};

	setData = () => {
		const { devices, favouritesDevices } = this.props;
		const favouritesList = [];

		favouritesDevices &&
			favouritesDevices.map((deviceID) => {
				if (devices.get(deviceID)) {
					let device = devices.get(deviceID);
					favouritesList.push({
						key: `${device.get("id")}`,
						label: `${device.get("opis_menu")}`,
					});
				}
			});

		this.setState({ favouritesList });
	};

	componentDidMount() {
		this.setData();
	}

	componentDidUpdate(prevProps) {
		if (prevProps.favouritesDevices != this.props.favouritesDevices) {
			this.setData();
		}
	}

	componentWillUnmount() {
		let newFavouritesArray = this.state.favouritesList.map((el) => el.key);

		this.props.onSaveFavourites(newFavouritesArray);
	}

	renderFavourites = ({ item, index, drag, isActive }) => {
		const { theme } = this.context;

		return (
			<DraggableItem
				theme={theme}
				item={item}
				index={index}
				drag={drag}
				isActive={isActive}
				type={"scene"}
			/>
		);
	};

	render() {
		const { favouritesList } = this.state;
		const { theme } = this.context;

		return (
			<View
				style={{
					flex: 1,
					backgroundColor: theme.APP_BACKGROUND_COLOR,
					padding: 5,
				}}
			>
				{favouritesList && favouritesList.length ? (
					<DraggableFlatList
						data={favouritesList}
						renderItem={this.renderFavourites}
						keyExtractor={(item, index) => `draggable-item-${item.key}`}
						onDragEnd={({ data }) => this.setState({ favouritesList: data })}
					/>
				) : (
					<NoDataScreen />
				)}
			</View>
		);
	}
}

const mapStateToProps = (state) => {
	let currentProfile = state.profilesSettings.get("currentProfile");

	return {
		devices: state.smartHomeData.get(currentProfile).get("devices"),
		favouritesDevices: state.profilesSettings
			.get(currentProfile)
			.get("favouritesDevices"),
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onSaveFavourites: (favourites) => dispatch(saveFavourites(favourites)),
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Favourites_Order_PersonalizationScreen);
