import React, { createContext, useContext } from "react";
import { useFonts } from "expo-font";
import {
  Montserrat_300Light,
  Montserrat_700Bold,
} from "@expo-google-fonts/montserrat";

const themesFile = {
  ampio: {
    TAB_BAR_TEXT_COLOR: "rgba(255, 255, 255, 0.5)",
    TAB_BAR_TEXT_COLOR_HIGHLITED: "#fff",
    TAB_BAR_BACKGROUND_COLOR: "#ED695C",

    APP_BACKGROUND_COLOR: "#f4f4f4",
    COMPONENT_BACKGROUND_COLOR: "#fff",
    COMPONENT_BACKGROUND_COLOR_SECONDARY: "#fff",
    PLACEHOLDER_BACKGROUND_COLOR: "#f4f4f4",

    BUTTON_BACKGROUND_COLOR_PRIMARY: "#ED695C",
    BUTTON_BACKGROUND_COLOR_PRIMARY_DISABLED: "rgba(237, 105, 92, 0.5)",
    BUTTON_BACKGROUND_COLOR_SECONDARY: "#FFF",

    TEXT_COLOR_PRIMARY: "#333333",
    TEXT_COLOR_SECONDARY: "#808080",
    AMPIO_COLOR: "#ED695C",

    SLIDER_MIN: "#ED695C",
    SLIDER_MAX: "#e4e4e4",
    SLICER_CIRCLE: "#FFFFFF",
    SLIDER_CIRCLE_BORDER: "#C1C1C1",

    SWITCH_OFF_BACKGROUND: "#DDD",

    ICONS_COLOR_PRIMARY: "#ED695C",
    ICONS_COLOR_SECONDARY: "#808080",

    CHART_VALUE_MIN: "rgba(250, 85, 85, 0.5)",
    CHART_VALUE_MAX: "rgba(50, 200, 80, 0.5)",
    FONT_BOLD: Montserrat_700Bold,
    FONT: Montserrat_300Light,
  },
  night: {
    TAB_BAR_TEXT_COLOR: "rgba(255, 255, 255, 0.5)",
    TAB_BAR_TEXT_COLOR_HIGHLITED: "#fff",
    TAB_BAR_BACKGROUND_COLOR: "#484848",

    APP_BACKGROUND_COLOR: "#333",
    COMPONENT_BACKGROUND_COLOR: "#808080",
    COMPONENT_BACKGROUND_COLOR_SECONDARY: "#747474",
    PLACEHOLDER_BACKGROUND_COLOR: "#606060",

    BUTTON_BACKGROUND_COLOR_PRIMARY: "#ED695C",
    BUTTON_BACKGROUND_COLOR_PRIMARY_DISABLED: "rgba(237, 105, 92, 0.5)",
    BUTTON_BACKGROUND_COLOR_SECONDARY: "#FFF",

    TEXT_COLOR_PRIMARY: "#FFF",
    TEXT_COLOR_SECONDARY: "#ccc",
    AMPIO_COLOR: "#484848",

    SLIDER_MIN: "#333333",
    SLIDER_MAX: "#606060",
    SLICER_CIRCLE: "#808080",
    SLIDER_CIRCLE_BORDER: "#E4E4E4",

    SWITCH_OFF_BACKGROUND: "#DDD",

    ICONS_COLOR_PRIMARY: "#FFF",
    ICONS_COLOR_SECONDARY: "#fff",

    CHART_VALUE_MIN: "#adadad",
    CHART_VALUE_MAX: "#151515",
    FONT_BOLD: Montserrat_700Bold,
    FONT: Montserrat_300Light,
  },
  oled: {
    TAB_BAR_TEXT_COLOR: "rgba(255, 255, 255, 0.5)",
    TAB_BAR_TEXT_COLOR_HIGHLITED: "#f1f1f1",
    TAB_BAR_BACKGROUND_COLOR: "#050505",

    APP_BACKGROUND_COLOR: "#050505",
    COMPONENT_BACKGROUND_COLOR: "#111",
    COMPONENT_BACKGROUND_COLOR_SECONDARY: "#333333",
    PLACEHOLDER_BACKGROUND_COLOR: "#606060",

    BUTTON_BACKGROUND_COLOR_PRIMARY: "#ED695C",
    BUTTON_BACKGROUND_COLOR_PRIMARY_DISABLED: "rgba(237, 105, 92, 0.5)",
    BUTTON_BACKGROUND_COLOR_SECONDARY: "#FFF",

    TEXT_COLOR_PRIMARY: "#F1f1f1",
    TEXT_COLOR_SECONDARY: "#ccc",
    AMPIO_COLOR: "#484848",

    SLIDER_MIN: "#333333",
    SLIDER_MAX: "#606060",
    SLICER_CIRCLE: "#808080",
    SLIDER_CIRCLE_BORDER: "#E4E4E4",

    SWITCH_OFF_BACKGROUND: "#777",

    ICONS_COLOR_PRIMARY: "#ED695C",
    ICONS_COLOR_SECONDARY: "#f1f1f1",

    CHART_VALUE_MIN: "#adadad",
    CHART_VALUE_MAX: "#f1f1f1",
    FONT_BOLD: Montserrat_700Bold,
    FONT: Montserrat_300Light,
  },
};

const initialState = {
  theme: themesFile.ampio,
  onThemeChange: () => {},
};

export const ThemeContext = createContext(initialState);

function ThemeProvider({ children, value }) {
  let [fonts] = useFonts({ Montserrat_300Light, Montserrat_700Bold });

  // const [dark, setDark] = React.useState(false); // Default theme is light

  // On mount, read the preferred theme from the persistence
  // React.useEffect(() => {
  // 	const isDark = localStorage.getItem("dark") === "true";
  // 	setDark(isDark);
  // }, [dark]);

  // // To toggle between dark and light modes
  // const toggle = () => {
  // 	const isDark = !dark;
  // 	localStorage.setItem("dark", JSON.stringify(isDark));
  // 	setDark(isDark);
  // };

  // const theme = dark ? themes.dark : themes.light;

  return (
    <ThemeContext.Provider fonts={fonts} value={value}>
      {children}
    </ThemeContext.Provider>
  );
}

export default ThemeProvider;
export const useTheme = () => useContext(ThemeContext);
