import * as React from "react";
import { StyleSheet } from "react-native";
import Animated, { useAnimatedStyle, useSharedValue } from "react-native-reanimated";
import { Gesture, GestureDetector } from "react-native-gesture-handler";

const THRESHOLD = 0.001;

import { canvas2Polar, polar2Canvas } from "./Coordinates";

export const clamp = (value: number, lowerBound: number, upperBound: number) => {
  "worklet";
  return Math.min(Math.max(lowerBound, value), upperBound);
};

interface CursorProps {
  r: number;
  strokeWidth: number;
  theta: Animated.SharedValue<number>;
  backgroundColor: Animated.SharedValue<string | number>;
}

export const Cursor = ({ r, strokeWidth, theta, backgroundColor }: CursorProps) => {
  const center = { x: r, y: r };

  const offsetX = useSharedValue<number>(0);
  const offsetY = useSharedValue<number>(0);

  // Reanimated v3: Use `Gesture` API for gesture handling
  const gesture = Gesture.Pan()
    .onBegin((event) => {
      const canvas = polar2Canvas(
        {
          theta: theta.value,
          radius: r,
        },
        center
      );

      offsetX.value = canvas.x;
      offsetY.value = canvas.y;
    })
    .onUpdate((event) => {
      const x = offsetX.value + event.translationX;
      const y1 = offsetY.value + event.translationY;
      let y: number;

      if (x < r) {
        y = y1;
      } else if (theta.value < Math.PI) {
        y = clamp(y1, 0, r - THRESHOLD);
      } else {
        y = clamp(y1, r, 2 * r);
      }

      const value = canvas2Polar({ x, y }, center).theta;

      theta.value = value > 0 ? value : 2 * Math.PI + value;
    });

  const style = useAnimatedStyle(() => {
    const translation = polar2Canvas(
      {
        theta: theta.value,
        radius: r,
      },
      center
    );
    return {
      backgroundColor: backgroundColor.value,
      transform: [{ translateX: translation.x }, { translateY: translation.y }],
    };
  });

  return (
    <GestureDetector gesture={gesture}>
      <Animated.View
        style={[
          {
            ...StyleSheet.absoluteFillObject,
            width: strokeWidth,
            height: strokeWidth,
            borderRadius: strokeWidth / 2,
            borderColor: "white",
            borderWidth: 3,
          },
          style,
        ]}
      />
    </GestureDetector>
  );
};
