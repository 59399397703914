import React, { Component, useContext, useState, useEffect, memo } from "react";

import { View, Text } from "react-native";
import moment from "moment";
import { t } from "../services/i18n";
import * as Localization from "expo-localization";
//ERROR IN WEB FROM BELOW
//WEB to zakomentuj
//import HighchartsReactNative from "@raw2dd4/highcharts-react-native";
//WEB to odkomentuj
import HighchartsReactNative from "../../assets/HighchartsReactNative";
import { connect } from "react-redux";
import { useTheme } from "../../ThemeContext";
import Long from "long";
import mqttClient from "../services/mqtt";
import { store } from "../store/configureStore";
import { barChartDataReceived } from "../store/actions";
import { SkottieLoader } from "../components/animatedComponents/Loader";
import { colorConsumed, colorExported, colorImported, colorProduced } from "../components/smartHomeComponents/energy/TriangleComponent";
import { dist } from "@shopify/react-native-skia";

const HighChartDonutView = memo(({ dimensionsProps, exportPower, importPower, productionPower, params, dimensions }) => {
  const { theme } = useTheme();

  const [chartOptionsState, setChartOptions] = useState(null);

  const chooseMomentLocale = (locale) => {
    // make the locale lower case
    // will fix crashes caused by "en-GB" (instead of "en-gb") not being found
    locale = locale.toLowerCase();
    if (moment.locales().includes(locale)) {
      // check if the locale is included in the array returned by `locales()` which (in this case) tells us which locales moment will support
      return locale;
    } else if (moment.locales().includes(locale.substring(0, 2))) {
      // check if the first two letters of the locale are included in the array returned by `locales()` which (in this case) tells us which locales moment will support
      // will fixes crashes caused by "en-US" not being found, as we'll tell moment to load "en" instead
      return locale.substring(0, 2);
    }
    // use "en-gb" (the default language and locale for my app) as a fallback if we can't find any other locale
    return "en-gb";
  };

  moment.locale(chooseMomentLocale(Localization.locale));

  useEffect(() => {
    let exported = undefined;
    let imported = undefined;
    let produced = undefined;
    if (exportPower && exportPower.get("data")) {
      //console.log("exportPower: ", exportPower.get("data").toJS());
      exported = exportPower.get("data").reduce((accumulator = 0, currentObject) => {
        if (currentObject) {
          return accumulator + currentObject.get("y");
        }
        return accumulator;
      }, 0);
    }
    if (importPower && importPower.get("data")) {
      //console.log("importPower: ", importPower.get("data").toJS());
      imported = importPower.get("data").reduce((accumulator = 0, currentObject) => {
        if (currentObject) {
          return accumulator + currentObject.get("y");
        }
        return accumulator;
      }, 0);
    }
    if (productionPower && productionPower.get("data")) {
      //console.log("productionPower: ", productionPower.get("data").toJS());
      produced = productionPower.get("data").reduce((accumulator = 0, currentObject) => {
        if (currentObject) {
          return accumulator + currentObject.get("y");
        }
        return accumulator;
      }, 0);
    }

    if (exported && imported && produced) {
      const selfConsumption = produced - exported;

      setChartOptions({
        chart: {
          type: "pie",
          backgroundColor: theme.COMPONENT_BACKGROUND_COLOR,
        },
        title: {
          text: null,
        },
        legend: {
          enabled: true,
        },
        tooltip: {
          valueDecimals: 1,
          format: '<b>{point.name}: </b> <span style="opacity: 0.9">{point.y:.1f} kWh</span>',
        },
        credits: {
          enabled: false,
        },

        plotOptions: {
          allowPointSelect: false,
          cursor: "pointer",
          pie: {
            borderColor: "transparent",
          },
        },

        series: [
          {
            name: t("PRODUCTION"),
            allowPointSelect: false,
            data: [
              { name: "PV", y: produced, color: colorProduced },
              { name: t("GRID"), y: imported, color: colorImported },
            ],
            size: "45%",
            showInLegend: true,
            dataLabels: {
              enabled: true,
              color: "#ffffff",
              distance: "-50%",
              format: '<span style="opacity: 0.9">{point.y:.1f} kWh</span>',
            },
          },
          {
            name: t("DISTRIBUTION"),
            allowPointSelect: false,
            data: [
              { name: t("EXPORTED"), y: exported, color: colorExported },
              // { name: t("SELF_CONSUMPTION"), y: selfConsumption, color: "#BCE29E" },
              { name: t("CONSUMED"), y: imported + produced - exported, color: colorConsumed },
            ],
            size: "80%",
            innerSize: "60%",
            showInLegend: true,
            dataLabels: {
              enabled: true,
              format: '<span style="opacity: 0.5">{point.y:.1f} kWh</span>',
              distance: 0,
              filter: {
                property: "y",
                operator: ">",
                value: 1,
              },
              style: {
                fontWeight: "normal",
              },
            },
          },
        ],
      });
    }
  }, [exportPower, importPower, productionPower]);

  return chartOptionsState && exportPower && importPower && productionPower ? (
    <HighchartsReactNative
      styles={{
        width: "100%",
        height: 300,
        opacity: 0.99,
      }}
      // THIS IS NEEDED FOR WEB
      viewStyle={{
        width: "100%",
        height: dimensions.height,
      }}
      webviewStyles={{
        backgroundColor: "transparent",
        opacity: 0.99,
      }}
      options={chartOptionsState}
      setOptions={{
        lang: {
          months: moment.months(),
          weekdays: moment.weekdays(),
          shortMonths: moment.monthsShort(),
        },
      }}
    />
  ) : (
    <SkottieLoader style={{ width: 40, height: 40 }} />
  );
}, areEqual);

function areEqual(prevProps, nextProps) {
  return true;
}

const mapStateToProps = (state, props) => {
  return {
    tzOffset: state.statesData.get("server").get("serverInfo").get("tzOffset"),
    dimensionsProps: state.statesData.get("dimensions"),
  };
};

export default connect(mapStateToProps)(HighChartDonutView);
