import React, { Component } from "react";
import { View, Text, ActivityIndicator } from "react-native";
import GoToModal from "../../../utils/GoToModal";
import { t } from "../../../services/i18n";

import { ComponentContainer, StyledIcon } from "../../UI/styledComponents";
import mqttClient from "../../../services/mqtt";
import PickerSelect from "../../UI/PickerSelect";
import { SkottieLoader } from "../../animatedComponents/Loader";

class IRComponent extends Component {
  state = {
    isLoading: true,
    irData: [],

    selectedIrInScene: -1,
  };

  changeIrDataForPicker = (irData) => {
    let irDataList = [];
    let element = {};

    irData.map((el) => {
      element = {
        label: `${el.name} - ${el.num}`,
        value: el.num,
      };
      irDataList.push(element);
    });

    return irDataList;
  };
  componentDidMount() {
    const { deviceInScene, deviceState, device } = this.props;
    if (deviceState) {
      if (!deviceState.get("irData")) {
        let message = `/api/json/device/${device.get("id")}/getIRs`;
        mqttClient.irQuiries(message);
      } else {
        if (deviceState.get("irData") && deviceState.get("irData").get("irs")) {
          let irData = this.changeIrDataForPicker(deviceState.get("irData").get("irs").toJS());
          this.setState({ irData, isLoading: false });
        }
      }
    }
    if (deviceInScene) {
      this.setState({
        selectedIrInScene: this.props.deviceInScene.get("param1"),
      });
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { deviceState } = nextProps;

    if (deviceState && deviceState.get("irData") && deviceState.get("irData").get("irs")) {
      let irData = this.changeIrDataForPicker(deviceState.get("irData").get("irs").toJS());
      this.setState({ irData, isLoading: false });
    }
  }

  onSelectedIrChange = (value) => {
    const { device, deviceInScene } = this.props;

    this.setState({ selectedIrInScene: value }, () => {
      let infoToUpdate = deviceInScene;
      infoToUpdate = infoToUpdate.set("param1", this.state.selectedIrInScene);
      this.props.changeScene(infoToUpdate, deviceInScene.get("temporary_id"));
    });
  };

  render() {
    const { selectedIrInScene, isLoading, irData } = this.state;
    const { deviceInScene, device, header, deviceFromSketch, dimensions } = this.props;

    return (
      <View style={{ height: dimensions.get("smallTiles") && !deviceFromSketch ? 40 : undefined, justifyContent: "center" }}>
        {!deviceInScene ? (
          <GoToModal
            params={{
              placeName: device.get("opis_menu"),
              device: device,
            }}
            screenName="IRScreen"
          >
            {!deviceFromSketch && header}
            <StyledIcon style={{ paddingRight: 5 }} name={"rss"} color={"primary"} size={20} />
          </GoToModal>
        ) : (
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {header}
            {isLoading ? (
              <SkottieLoader style={{ width: 40, height: 40, margin: 5 }} />
            ) : (
              <PickerSelect
                placeholder={{
                  label: t("ir:CHOOSE_IR_CODE"),
                  value: null,
                }}
                containerStyle={{ width: 150 }}
                items={irData}
                onValueChange={this.onSelectedIrChange}
                value={selectedIrInScene}
              />
            )}
          </View>
        )}
      </View>
    );
  }
}

export default IRComponent;
