import React, { Component } from "react";
import { Text, View, TouchableOpacity, StyleSheet, ActivityIndicator, Platform } from "react-native";
import { connect } from "react-redux";

import { t } from "../../services/i18n";

import { PrimaryColorText, ButtonPrimary } from "../../components/UI/styledComponents";
// import PickerSelectObjects from "../../components/UI/PickerSelectObjects";
// import MultiplePickerSelectObjects from "./MultiplePickerSelectObjects";
import ModalWithContent from "../../components/UI/ModalWithContent";
import PickerSelect from "../../components/UI/PickerSelect";
import PickerSelectObjects from "../../components/UI/PickerSelectObjects";
import { devicesForGroups } from "../../utils";
import { mainHost } from "../../../CloudConstants";

import { SkottieLoader } from "../../components/animatedComponents/Loader";
import DateTimePicker from "../../components/UI/dateTimePicker";

const conditionTypes = {
  DAYS: 1,
  TIME: 2,
  OBJECT: 0,
  DATE: 16,
  EVENT: 8,
  OBJECT_PARAM2: 15,
};
const locationRadius = {
  "200M": 200,
  "500M": 500,
  "1KM": 1000,
  "2KM": 2000,
  "5KM": 5000,
};

const styles = StyleSheet.create({
  map: {
    ...StyleSheet.absoluteFillObject,
  },
});

class AddConditionModal extends Component {
  types = Object.entries(conditionTypes).map(([key, value]) => {
    if (value !== conditionTypes.OBJECT_PARAM2) {
      let element = { label: t(`${t("conditions:" + key)}`), value: value };
      return element;
    }
    return null;
  });

  state = {
    selectedRegion: null,
    selectedType: conditionTypes.DAYS,
    selectedGroup: 0,
    selectedDevice: null,
    selectedEvent: null,
    selectedRadius: 200,
    buttonAddDisabled: false,
    permissionsToLocation: false,
    permisionChecked: false,
  };

  onAddHandler = () => {
    const { selectedType, selectedEvent, selectedDevice, selectedRegion, selectedRadius } = this.state;

    let object_id = null;

    if (conditionTypes.EVENT === selectedType) {
      object_id = selectedEvent;
    } else if (conditionTypes.OBJECT === selectedType || conditionTypes.OBJECT_PARAM2 === selectedType) {
      object_id = selectedDevice;
    } else if (selectedType == conditionTypes.LOCATION_LEAVE || selectedType == conditionTypes.LOCATION_ENTER) {
      object_id = { radius: selectedRadius, ...selectedRegion };
    }
    if ((selectedType == conditionTypes.LOCATION_LEAVE || selectedType == conditionTypes.LOCATION_ENTER) && !this.props.profile.get("cloudIp")) {
      window.app.showToast("Enable cloud access in security tab");
    } else {
      this.props.onAdd(this.state.selectedType, object_id);
    }
  };

  onSelectedTypeChange = (selectedType) => {
    let buttonDisabled = this.state.buttonAddDisabled;
    if (conditionTypes.LOCATION_ENTER == selectedType || conditionTypes.LOCATION_LEAVE == selectedType) {
      if (!this.state.selectedRegion) buttonDisabled = true;
      this.checkPermissions();
    }

    this.setState({
      selectedType,
      buttonAddDisabled: buttonDisabled,
    });
  };
  onGroupValueChange = (value) => {
    this.setState({
      selectedGroup: value,
      selectedDevice: null,

      buttonAddDisabled: true,
    });
  };

  onDeviceValueChange = (value) => {
    if (value) {
      this.setState({ selectedDevice: value, buttonAddDisabled: false });
    }
  };

  onEventValueChange = (value) => {
    if (value) {
      this.setState({ selectedEvent: value, buttonAddDisabled: false });
    }
  };

  onRadiusValueChange = (value) => {
    if (value) {
      this.setState({ selectedRadius: value, buttonAddDisabled: false });
    }
  };

  onRegionChangeComplete = (value) => {
    if (value) {
      this.setState({ selectedRegion: value, buttonAddDisabled: false });
    }
  };

  setDevices = () => {
    const { devices, group_devices, params_devices } = this.props;

    let devicesToSend = [];

    let listOfDevicesForGroup = devicesForGroups(group_devices, this.state.selectedGroup);

    listOfDevicesForGroup.map((deviceForGroup) => {
      let deviceID = deviceForGroup.get("id_obiektu");
      if (devices.get(deviceID)) {
        let device = devices.get(deviceID);
        let typKomponentu = device.get("typ_komponentu");

        if (typKomponentu == "custom") {
          let powiazane = params_devices.get(deviceID) ? params_devices.get(deviceID).get("powiazane") : "";
          let powiazaneArrayWithPossibleCustom = powiazane ? powiazane.split(",") : [];
          powiazaneArrayWithPossibleCustom.map((el, index) => {
            el = el.split(":").reverse();
            let deviceFromCustom = devices.get(el[0]);
            if (deviceFromCustom && deviceFromCustom.get("typ_komponentu") != "custom") {
              devicesToSend.push({
                label: deviceFromCustom.get("opis_menu"),
                value: deviceFromCustom.get("id"),
              });
            }
          });
        } else {
          devicesToSend.push({
            label: device.get("opis_menu"),
            value: device.get("id"),
          });
        }
      }
    });
    return devicesToSend;
  };

  setEvents = () => {
    const { events } = this.props;

    let eventsToSend = [];

    events &&
      events.map((event) => {
        if (event) {
          eventsToSend.push({
            label: event.get("opis"),
            value: event.get("wartoscN"),
          });
        }
      });

    return eventsToSend;
  };

  getCurrentPosition = async () => {
    let location = null;
    if (location) {
      let locationData = {
        latitude: location.coords.latitude,
        longitude: location.coords.longitude,
        latitudeDelta: 0.0922,
        longitudeDelta: 0.0421,
      };
      this.onRegionChangeComplete(locationData);
    }
  };

  checkPermissions = async () => {
    if (Platform.OS === "android") {
      const perm = await PermissionsAndroid.check(PermissionsAndroid.PERMISSIONS.ACCESS_BACKGROUND_LOCATION);
      const perm2 = await PermissionsAndroid.check(PermissionsAndroid.PERMISSIONS.ACCESS_FINE_LOCATION);

      if (!perm || !perm2) {
        this.setState({ permissionsToLocation: false, permisionChecked: true });
      } else {
        this.setState({ permissionsToLocation: true, permisionChecked: true });
        this.getCurrentPosition();
      }
    } else {
      this.setState({ permissionsToLocation: true, permisionChecked: true });
      this.getCurrentPosition();
    }
  };

  getPermissionsToLocation = async () => {
    this.checkPermissions();
  };

  render() {
    let events = this.setEvents();
    let devicesInGroup = this.setDevices();
    let radius = Object.entries(locationRadius).map(([key, value]) => {
      let element = { label: t("conditions:" + `${key}`), value: value };
      return element;
    });

    const { isVisible, onClose, eventAlreadyExist } = this.props;
    const { selectedType, selectedDevice, selectedGroup, selectedEvent, selectedRadius, buttonAddDisabled, selectedRegion } = this.state;

    if ((conditionTypes.LOCATION_ENTER == selectedType || conditionTypes.LOCATION_LEAVE == selectedType) && !this.state.permisionChecked) {
      this.checkPermissions();
    }

    const locationPermisionNeeded =
      this.state.permisionChecked &&
      !this.state.permissionsToLocation &&
      (conditionTypes.LOCATION_ENTER == selectedType || conditionTypes.LOCATION_LEAVE == selectedType);

    return (
      <ModalWithContent isVisible={isVisible} title={t("conditions:ADD_CONDITION")} onClose={onClose}>
        <View style={{ paddingBottom: 16 }}>
          <PrimaryColorText style={{ paddingBottom: 4 }}>{t("conditions:TYPE")}</PrimaryColorText>
          <PickerSelect
            placeholder={{
              label: t("conditions:CHOOSE_TYPE"),
              value: null,
            }}
            items={this.types}
            onValueChange={this.onSelectedTypeChange}
            value={selectedType}
          />
        </View>

        {conditionTypes.OBJECT == selectedType || conditionTypes.OBJECT_PARAM2 == selectedType ? (
          <View>
            <View style={{ paddingBottom: 16 }}>
              <PrimaryColorText style={{ paddingBottom: 4 }}>{t("OBJECT_PLACE")}</PrimaryColorText>
              <PickerSelectObjects onValueChange={this.onGroupValueChange} value={selectedGroup} type={"groups"} />
            </View>

            <View style={{ paddingBottom: 20 }}>
              <PrimaryColorText style={{ paddingBottom: 4 }}>{t("OBJECT_NAME")}</PrimaryColorText>
              <PickerSelectObjects type={"devices"} items={devicesInGroup} onValueChange={this.onDeviceValueChange} value={selectedDevice} />
            </View>
          </View>
        ) : null}
        {conditionTypes.EVENT == selectedType ? (
          eventAlreadyExist ? (
            <PrimaryColorText style={{ textAlign: "center", paddingBottom: 10 }}>{t("conditions:CAN_ADD_ONLY_1_EVENT")}</PrimaryColorText>
          ) : (
            <View style={{ paddingBottom: 16 }}>
              <PrimaryColorText style={{ paddingBottom: 4 }}>{t("EVENT_NAME")}</PrimaryColorText>

              <PickerSelect
                placeholder={{
                  label: t("SELECT_EVENT_NAME"),
                  value: null,
                }}
                items={events}
                onValueChange={this.onEventValueChange}
                value={selectedEvent}
              />
            </View>
          )
        ) : null}

        {locationPermisionNeeded ? (
          <View>
            <Text style={{ marginBottom: 10 }}>
              {t("THIS_APPLICATION_USES_LOCATION_AND_SENSOR_DATA_TO_PROVIDE_GEOFENCING_FUNCIONALITY_EVEN_WHEN_APP_IS_CLOSED_OR_UNUSED")}
            </Text>
            <ButtonPrimary onPress={this.getPermissionsToLocation}>{t("REQUEST_PERMISSIONS")}</ButtonPrimary>
          </View>
        ) : null}
        {!locationPermisionNeeded &&
        !selectedRegion &&
        (conditionTypes.LOCATION_ENTER == selectedType || conditionTypes.LOCATION_LEAVE == selectedType) ? (
          <View style={{ height: 50, marginBottom: 5, marginTop: 5 }}>
            <SkottieLoader style={{ width: 40, height: 40, margin: 5 }} />
          </View>
        ) : null}
        {!locationPermisionNeeded && (
          <ButtonPrimary disabled={buttonAddDisabled} onPress={this.onAddHandler}>
            {t("ADD")}
          </ButtonPrimary>
        )}
      </ModalWithContent>
    );
  }
}
const mapStateToProps = (state) => {
  let currentProfile = state.profilesSettings.get("currentProfile");
  return {
    group_devices: state.smartHomeData.get(currentProfile).get("group_devices"),
    devices: state.smartHomeData.get(currentProfile).get("devices"),
    params_devices: state.smartHomeData.get(currentProfile).get("params_devices"),
    events: state.smartHomeData.get(currentProfile).get("events"),
    profile: state.profilesSettings.get(currentProfile).get("loginData"),
  };
};

export default connect(mapStateToProps)(AddConditionModal);
