import React, { Component } from "react";
import { Text, View, TouchableOpacity, ScrollView } from "react-native";
import { connect } from "react-redux";
import { Feather } from "@expo/vector-icons";

import { t } from "../../services/i18n";

import { PrimaryColorText, ButtonPrimary } from "../../components/UI/styledComponents";
import { devicesForGroups } from "../../utils";
// import PickerSelectObjects from "../../components/UI/PickerSelectObjects";
import MultiplePickerSelectObjects from "../Scenes/MultiplePickerSelectObjects";
import ModalWithContent from "../../components/UI/ModalWithContent";
import PickerSelect from "../../components/UI/PickerSelect";
import PickerSelectObjects from "../../components/UI/PickerSelectObjects";

import { useTheme, ThemeContext } from "../../../ThemeContext";
import { actionTypes } from "./Actions_AutomatizationScreen";

const typeToShow = [
  "flaga",
  "flaga_l",
  "flaga_p",
  "led",
  "ledww",
  "flaga_liniowa",
  "flaga_liniowa16",
  "przekaznik",
  "reg",
  "radio",
  "ip_radio",
  "rgb",
  "rgbw",
  "roleta",
  "roleta_procenty",
  "roleta_lamelki",
  "event",
  "ir",
];
class AddActionModal extends Component {
  static contextType = ThemeContext;
  types = Object.entries(actionTypes).map(([key, value]) => {
    let element = { label: t(`${key}`), value: value };
    return element;
  });

  state = {
    selectedType: actionTypes.SCENE,
    selectedEvent: null,
    selectedGroup: 0,
    selectedDevices: null,
    selectedScene: null,
    buttonAddDisabled: true,
  };

  onAddHandler = () => {
    const { selectedType, selectedEvent, selectedDevices, selectedScene } = this.state;
    const { userName, devices } = this.props;
    let object_id = null;
    let additional_info = null;
    if (actionTypes.EVENT === selectedType) {
      object_id = selectedEvent;
    } else if (actionTypes.OBJECT === selectedType) {
      additional_info = selectedDevices;
    } else if (actionTypes.SCENE === selectedType) {
      object_id = selectedScene;
    } else if (actionTypes.PUSH === selectedType) {
      additional_info = userName;
    } else if (actionTypes.MESSAGE === selectedType) {
      additional_info = userName;
    }
    this.props.onAdd(this.state.selectedType, object_id, additional_info);
  };

  onSelectedTypeChange = (selectedType) => {
    this.setState({
      selectedType,
      buttonAddDisabled: actionTypes.PUSH == selectedType || actionTypes.MESSAGE == selectedType || actionTypes.AUDIO ? false : true,
    });
  };

  onGroupValueChange = (value) => {
    this.setState({
      selectedGroup: value,
      selectedDevices: null,
      buttonAddDisabled: true,
    });
  };

  onDeviceValueChange = (value) => {
    if (value != null) {
      this.setState({ selectedDevices: value, buttonAddDisabled: false });
    }
  };
  onSceneValueChange = (value) => {
    if (value != null) {
      this.setState({ selectedScene: value, buttonAddDisabled: false });
    }
  };

  onEventValueChange = (value) => {
    if (value != null) {
      this.setState({ selectedEvent: value, buttonAddDisabled: false });
    }
  };

  setDevices = () => {
    const { devices, group_devices, logging, params_devices } = this.props;

    let devicesToSend = [];
    let listOfDevicesForGroup = devicesForGroups(group_devices, this.state.selectedGroup);
    listOfDevicesForGroup.map((deviceForGroup) => {
      let deviceID = deviceForGroup.get("id_obiektu");
      if (devices.get(deviceID)) {
        let device = devices.get(deviceID);
        let typKomponentu = device.get("typ_komponentu");
        if (typKomponentu == "custom") {
          let powiazane = params_devices.get(deviceID) ? params_devices.get(deviceID).get("powiazane") : "";
          let powiazaneArrayWithPossibleCustom = powiazane ? powiazane.split(",") : [];

          powiazaneArrayWithPossibleCustom.map((el, index) => {
            el = el.split(":").reverse();
            let deviceFromCustom = devices.get(el[0]);
            if (
              deviceFromCustom &&
              deviceFromCustom.get("typ_komponentu") != "custom" &&
              typeToShow.includes(deviceFromCustom.get("typ_komponentu")) &&
              !deviceFromCustom.get("bezpieczne")
            ) {
              devicesToSend.push({
                label: deviceFromCustom.get("opis_menu"),
                value: deviceFromCustom.get("id"),
              });
            }
          });
        }

        if (typeToShow.includes(typKomponentu) && !device.get("bezpieczne")) {
          devicesToSend.push({
            label: device.get("opis_menu"),
            value: device.get("id"),
          });
        } else if ((typKomponentu === "satel_alarm" || typKomponentu === "satel_wyj" || typKomponentu === "alarm") && device.get("haslo")) {
          devicesToSend.push({
            label: device.get("opis_menu"),
            value: device.get("id"),
          });
        }
      }
    });
    return devicesToSend;
  };

  setScenes = () => {
    const { scenes } = this.props;

    let scenesToSend = [];

    scenes &&
      scenes.map((scene) => {
        if (scene) {
          scenesToSend.push({
            label: scene.get("sceneName"),
            value: scene.get("id"),
          });
        }
      });
    return scenesToSend;
  };

  setEvents = () => {
    const { events } = this.props;

    let eventsToSend = [];

    events &&
      events.map((event) => {
        if (event) {
          eventsToSend.push({
            label: event.get("opis"),
            value: event.get("wartoscN"),
          });
        }
      });

    return eventsToSend;
  };

  render() {
    const { isVisible, onClose } = this.props;
    const { buttonAddDisabled, selectedType, selectedScene, selectedEvent, selectedDevices, selectedGroup } = this.state;
    let scenes = this.setScenes();
    let events = this.setEvents();
    let devicesInGroup = this.setDevices();
    return (
      <ModalWithContent isVisible={isVisible} title={t("conditions:ADD_ACTION")} onClose={onClose}>
        <View style={{ paddingBottom: 16 }}>
          <PrimaryColorText style={{ paddingBottom: 4 }}>{t("conditions:TYPE")}</PrimaryColorText>
          <PickerSelect
            placeholder={{
              label: t("conditions:CHOOSE_TYPE"),
              value: null,
            }}
            items={this.types}
            onValueChange={this.onSelectedTypeChange}
            value={selectedType}
          />
        </View>
        {actionTypes.SCENE == selectedType ? (
          <View style={{ paddingBottom: 16 }}>
            <PrimaryColorText style={{ paddingBottom: 4 }}>{t("SCENE_NAME")}</PrimaryColorText>

            <PickerSelect
              // placeholder={{}}
              placeholder={{
                label: t("SELECT_SCENE"),
                value: null,
              }}
              items={scenes}
              onValueChange={this.onSceneValueChange}
              value={selectedScene}
            />
          </View>
        ) : null}
        {actionTypes.EVENT == selectedType ? (
          <View style={{ paddingBottom: 16 }}>
            <PrimaryColorText style={{ paddingBottom: 4 }}>{t("EVENT_NAME")}</PrimaryColorText>

            <PickerSelect
              placeholder={{
                label: t("SELECT_EVENT_NAME"),
                value: null,
              }}
              items={events}
              onValueChange={this.onEventValueChange}
              value={selectedEvent}
            />
          </View>
        ) : null}
        {actionTypes.OBJECT == selectedType ? (
          <View>
            <View style={{ paddingBottom: 16 }}>
              <PrimaryColorText style={{ paddingBottom: 4 }}>{t("OBJECT_PLACE")}</PrimaryColorText>
              <PickerSelectObjects onValueChange={this.onGroupValueChange} value={selectedGroup} type={"groups"} />
            </View>

            <View style={{ paddingBottom: 20 }}>
              <PrimaryColorText style={{ paddingBottom: 4 }}>{t("OBJECT_NAME")}</PrimaryColorText>
              <MultiplePickerSelectObjects
                devicesInGroup={devicesInGroup}
                onValueChange={this.onDeviceValueChange}
                selectedDevices={selectedDevices}
                selectedGroup={selectedGroup}
              />
              {/* <PickerSelectObjects
                type={"devices"}
                items={devicesInGroup}
                onValueChange={this.onDeviceValueChange}
                value={selectedDevice}
              /> */}
            </View>
          </View>
        ) : null}
        <ButtonPrimary disabled={buttonAddDisabled} onPress={this.onAddHandler}>
          {t("ADD")}
        </ButtonPrimary>
      </ModalWithContent>
    );
  }
}
const mapStateToProps = (state) => {
  let currentProfile = state.profilesSettings.get("currentProfile");
  return {
    userName: state.profilesSettings.get(currentProfile).get("loginData").get("login"),
    devices: state.smartHomeData.get(currentProfile).get("devices"),
    scenes: state.smartHomeData.get(state.profilesSettings.get("currentProfile")).get("scenes"),
    events: state.smartHomeData.get(currentProfile).get("events"),
    group_devices: state.smartHomeData.get(currentProfile).get("group_devices"),
    params_devices: state.smartHomeData.get(currentProfile).get("params_devices"),
    logging: state.smartHomeData.get(state.profilesSettings.get("currentProfile")).get("logging"),
  };
};

export default connect(mapStateToProps)(AddActionModal);
