import React, { Component } from "react";
import { View, TouchableOpacity, Text, StyleSheet, Platform } from "react-native";
import { connect } from "react-redux";
import { t } from "../../../services/i18n";
import mqttClient from "../../../services/mqtt";

import PickerSelect from "../../../components/UI/PickerSelect";
import { createSelector } from "reselect";
import AddDeviceModal from "./AddDeviceModal";
import { ListItemContainer } from "../styled";

import { TitleWithLines, SwitchStyled, ButtonPrimary, PrimaryColorText } from "../../../components/UI/styledComponents";
import { ScreenView } from "../../../components/UI/screenViewX";

class Detection_AccountScreen extends Component {
  types = [
    {
      label: t("detection:UNKNOWN_SENSOR_TYPE"),
      value: 0,
    },
    {
      label: t("detection:INSIDE_SENSOR"),
      value: 1,
    },
    {
      label: t("detection:ENTRANCE_SENSOR"),
      value: 2,
    },
  ];

  state = {
    simulationId: null,
    dialogVisible: false,
    devicesArray: [],
  };

  componentDidMount() {
    const { devices, params_devices, detectionDevice } = this.props;

    if (detectionDevice && detectionDevice.get("id") && params_devices.get(detectionDevice.get("id"))) {
      let detectionParams = params_devices.get(detectionDevice.get("id"));
      let simulationId = detectionDevice.get("id");
      let devicesArray = [];

      if (detectionParams.get("powiazane")) {
        let powiazaneArray = detectionParams.get("powiazane").split(",");
        powiazaneArray.map((el, index) => {
          el = el.split(":").reverse();
          let deviceFromCustom = devices.get(el[0]);

          if (deviceFromCustom && params_devices.get(deviceFromCustom.get("id"))) {
            let type = 0;
            if ((params_devices.get(deviceFromCustom.get("id")).get("params") & 0x800) > 0) {
              type = 2;
            } else if ((params_devices.get(deviceFromCustom.get("id")).get("params") & 0x1000) > 0) {
              type = 1;
            }
            let element = {
              id: deviceFromCustom.get("id"),
              type,
            };
            devicesArray.push(element);
          }
        });
      }
      this.setState({
        simulationId,
        devicesArray,
      });
    }
  }

  onCloseHandler = () => {
    this.setState({
      dialogVisible: false,
    });
  };

  onAddNewDevice = (selectedDeviceId) => {
    if (!this.state.devicesArray.some((device) => device.id == selectedDeviceId)) {
      let element = {
        id: selectedDeviceId,
        type: 1,
      };
      const devicesArray = this.state.devicesArray.concat(element);
      this.setState({ devicesArray, dialogVisible: false });
    } else {
      this.setState({ dialogVisible: false });
    }
  };

  onRemoveDevice = (selectedDeviceId) => {
    let devicesArray = this.state.devicesArray.filter((device) => device.id != selectedDeviceId);

    this.setState({ devicesArray });
  };

  onTypeChange = (type, selectedDeviceId) => {
    var devicesArray = [...this.state.devicesArray];
    let deviceIndex = this.state.devicesArray.findIndex((device) => device.id == selectedDeviceId);

    devicesArray[deviceIndex].type = type;
    this.setState({ devicesArray });
  };
  //ok
  componentWillUnmount() {
    if (this.state.simulationId) {
      let payload = {
        objects: this.state.devicesArray,
      };
      mqttClient.sendDetectionData(payload);
    }
  }
  //ok
  showAddModal = () => {
    this.setState({ dialogVisible: true });
  };

  descriptionForDevice = (value) => {
    let description = "";
    switch (value) {
      case "0":
        description = `${t("detection:UNDEFINED")}`;
        break;
      case "1":
        description = `${t("detection:NO_OBJECTS")}`;
        break;
      case "2":
        description = `${t("detection:SOMEONE_ENTERED")}`;
        break;
      case "3":
        description = `${t("detection:SOMEONE_LEAVE_OR_MORE_ENTERED")}`;
        break;
      case "4":
        description = `${t("detection:SOMEONE_HOME")}`;
        break;
      case "5":
        description = `${t("detection:HOME_EMPTY")}`;
        break;
      case "6":
        description = `${t("detection:SOMEONE_LEAVE")}`;
        break;
      default:
        description = `${t("detection:UNDEFINED")} `;
    }
    return description;
  };

  render() {
    const { devices, deviceState } = this.props;
    const { simulationId, dialogVisible, devicesArray } = this.state;
    let state = "0";
    if (deviceState) {
      state = deviceState.get("state");
    }
    let list = devicesArray.map((el) => {
      let device = devices.get(el.id);
      if (device) {
        return (
          <ListItemContainer key={devices.get(el.id)}>
            <PrimaryColorText numberOfLines={1}>{device.get("opis_menu")}</PrimaryColorText>
            <View style={{ flex: 1, marginHorizontal: 20 }}>
              <PickerSelect placeholder={{}} items={this.types} onValueChange={(value) => this.onTypeChange(value, el.id)} value={el.type} />
            </View>
            <TouchableOpacity onPress={() => this.onRemoveDevice(el.id)}>
              <Text
                style={{
                  color: "red",
                  fontStyle: "italic",
                }}
              >
                {t("REMOVE")}
              </Text>
            </TouchableOpacity>
          </ListItemContainer>
        );
      }
    });
    let content = simulationId ? (
      <ScreenView>
        {dialogVisible && <AddDeviceModal isVisible={dialogVisible} onClose={this.onCloseHandler} onAdd={this.onAddNewDevice} />}

        <ListItemContainer>
          <PrimaryColorText>{t("detection:STATE")}</PrimaryColorText>
          <PrimaryColorText>{this.descriptionForDevice(state)}</PrimaryColorText>
        </ListItemContainer>

        <TitleWithLines textInside={t("account:DEVICES")} />
        <View style={{ width: "100%", justifyContent: "center" }}>
          <ButtonPrimary style={{ paddingHorizontal: 15, margin: 10 }} onPress={this.showAddModal}>
            {t("detection:ADD_DEVICE_DETECTION")}
          </ButtonPrimary>
        </View>
        {list}
      </ScreenView>
    ) : (
      <View
        style={{
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          padding: 20,
        }}
      >
        <Text>{t("NO_RIGHTS_TO_USE_OBJECT")}</Text>
      </View>
    );
    return content;
  }
}

const selectDevices = (state) => state;

const devicesSelector = createSelector(selectDevices, (smartHomeData) => {
  let dev = null;
  smartHomeData.forEach((device) => {
    if (device && device.get("typ_komponentu") && device.get("typ_komponentu").includes("detekcja")) {
      dev = device;
    }
  });
  return dev;
});

const mapStateToProps = (state) => {
  let currentProfile = state.profilesSettings.get("currentProfile");
  const devices = state.smartHomeData.get(state.profilesSettings.get("currentProfile")).get("devices");

  let detectionDevice = devicesSelector(state.smartHomeData.get(currentProfile).get("devices"));

  return {
    devices: devices,
    params_devices: state.smartHomeData.get(state.profilesSettings.get("currentProfile")).get("params_devices"),
    globalSettings: state.globalSettings,
    deviceState: detectionDevice ? state.statesData.get("states").get(detectionDevice.get("id")) : null,
    detectionDevice: detectionDevice,
  };
};

export default connect(mapStateToProps)(Detection_AccountScreen);
