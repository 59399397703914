import React, { Component } from "react";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { useTheme } from "../../ThemeContext";

const EndSwitchIcon = ({ value }) => {
	const { theme } = useTheme();

	let valueToIcon = () => {
		let name = "";
		switch (`${value}`) {
			case "0":
				name = "garage";
				break;
			case "1":
				name = "garage-alert";
				break;
			case "2":
				name = "garage-open";
				break;
			case "3":
				name = "alert-circle-outline";
				break;
		}
		return name;
	};
	let iconName = valueToIcon();

	return (
		<MaterialCommunityIcons
			name={iconName}
			size={36}
			color={theme.ICONS_COLOR_PRIMARY}
			style={{ paddingHorizontal: 5 }}
		/>
	);
};

export default EndSwitchIcon;
