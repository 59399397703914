import React, { Component } from "react";
import { Text, View, TouchableOpacity } from "react-native";
import { t } from "../../../services/i18n";
import { connect } from "react-redux";

import { PrimaryColorText, ButtonPrimary } from "../../../components/UI/styledComponents";
import { devicesForGroups } from "../../../utils";
import PickerSelect from "../../../components/UI/PickerSelect";
import PickerSelectObjects from "../../../components/UI/PickerSelectObjects";
import ModalWithContent from "../../../components/UI/ModalWithContent";

class AddDeviceModal extends Component {
  state = {
    selectedGroup: 0,
    selectedDevice: null,
    selectedEvent: null,
    selectedOption: "allDevices",
    buttonAddDisabled: false,
  };

  onGroupValueChange = (value) => {
    this.setState({
      selectedGroup: value,
      selectedDevice: null,

      buttonAddDisabled: true,
    });
  };

  onDeviceValueChange = (value) => {
    if (value) {
      this.setState({ selectedDevice: value, buttonAddDisabled: false });
    }
  };

  onEventValueChange = (value) => {
    if (value) {
      this.setState({ selectedEvent: value, buttonAddDisabled: false });
    }
  };

  onOptionChange = (value) => {
    this.setState({
      selectedOption: value,
      buttonAddDisabled: value == "allDevices" || value == "allEvents" ? false : true,
    });
  };

  onAddHandler = () => {
    const { selectedOption } = this.state;
    let selectedValue = 0;
    if (selectedOption == "singleDevice") {
      selectedValue = this.props.devices.get(this.state.selectedDevice);
    } else if (selectedOption == "allEvents") {
      selectedValue = 0;
    } else if (selectedOption == "singleEvent") {
      selectedValue = this.props.events.get(this.state.selectedEvent);
    }
    this.props.onAdd(selectedValue, selectedOption);
  };

  setEvents = () => {
    const { events } = this.props;

    let eventsToSend = [];

    events &&
      events.map((event) => {
        if (event) {
          eventsToSend.push({
            label: event.get("opis"),
            value: event.get("wartoscN"),
          });
        }
      });

    return eventsToSend;
  };

  setDevices = () => {
    const { devices, group_devices, logging, params_devices } = this.props;

    let devicesToSend = [];

    let listOfDevicesForGroup = devicesForGroups(group_devices, this.state.selectedGroup);

    listOfDevicesForGroup.map((deviceForGroup) => {
      let deviceID = deviceForGroup.get("id_obiektu");
      if (devices.get(deviceID)) {
        let device = devices.get(deviceID);
        let typKomponentu = device.get("typ_komponentu");
        if (typKomponentu == "custom") {
          let powiazane = params_devices.get(deviceID) ? params_devices.get(deviceID).get("powiazane") : "";
          let powiazaneArrayWithPossibleCustom = powiazane ? powiazane.split(",") : [];

          powiazaneArrayWithPossibleCustom.map((el, index) => {
            el = el.split(":").reverse();
            let deviceFromCustom = devices.get(el[0]);
            if (
              deviceFromCustom &&
              deviceFromCustom.get("typ_komponentu") != "custom" &&
              (deviceFromCustom.get("czas_logowania") >= 0 || logging.find((el) => el.get("id_obiektu") == deviceFromCustom.get("id")))
            ) {
              devicesToSend.push({
                label: deviceFromCustom.get("opis_menu"),
                value: deviceFromCustom.get("id"),
              });
            }
          });
        } else {
          devicesToSend.push({
            label: device.get("opis_menu"),
            value: device.get("id"),
          });
        }
      }
    });
    return devicesToSend;
  };

  render() {
    const { isVisible, onClose, devices, group_devices, showEvents } = this.props;
    const { selectedDevice, selectedGroup, selectedEvent, buttonAddDisabled, selectedOption } = this.state;

    let options = [
      {
        label: t("statistics:ALL_DEVICES"),
        value: "allDevices",
      },

      {
        label: t("statistics:SINGLE_DEVICE"),
        value: "singleDevice",
      },
    ];

    if (showEvents == true) {
      options.push({
        label: t("statistics:ALL_EVENTS"),
        value: "allEvents",
      });
      options.push({
        label: t("statistics:SINGLE_EVENT"),
        value: "singleEvent",
      });
    }

    let events = this.setEvents();
    let devicesInGroup = this.setDevices();

    return (
      <ModalWithContent isVisible={isVisible} title={t("CHOOSE_OBJECT_TYPE")} onClose={onClose}>
        <View style={{ paddingBottom: 20 }}>
          <PrimaryColorText style={{ paddingBottom: 4 }}>{t("OBJECT_TYPE")}</PrimaryColorText>
          <PickerSelect
            placeholder={{
              label: t("OBJECT_TYPE"),
              value: null,
            }}
            items={options}
            onValueChange={this.onOptionChange}
            value={selectedOption}
          />
        </View>
        {selectedOption == "singleDevice" ? (
          <View>
            <View style={{ paddingBottom: 16 }}>
              <PrimaryColorText style={{ paddingBottom: 4 }}>{t("OBJECT_PLACE")}</PrimaryColorText>
              <PickerSelectObjects onValueChange={this.onGroupValueChange} value={selectedGroup} type={"groups"} />
            </View>

            <View style={{ paddingBottom: 20 }}>
              <PrimaryColorText style={{ paddingBottom: 4 }}>{t("OBJECT_NAME")}</PrimaryColorText>
              <PickerSelectObjects type={"devices"} items={devicesInGroup} onValueChange={this.onDeviceValueChange} value={selectedDevice} />
            </View>
          </View>
        ) : null}
        {selectedOption == "singleEvent" ? (
          <View style={{ paddingBottom: 16 }}>
            <PrimaryColorText style={{ paddingBottom: 4 }}>{t("OBJECT_PLACE")}</PrimaryColorText>

            <PickerSelect
              placeholder={{
                label: t("OBJECT_TYPE"),
                value: null,
              }}
              items={events}
              onValueChange={this.onEventValueChange}
              value={selectedEvent}
            />
          </View>
        ) : null}
        <ButtonPrimary disabled={buttonAddDisabled} onPress={this.onAddHandler}>
          {t("DONE")}
        </ButtonPrimary>
      </ModalWithContent>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    group_devices: state.smartHomeData.get(state.profilesSettings.get("currentProfile")).get("group_devices"),
    devices: state.smartHomeData.get(state.profilesSettings.get("currentProfile")).get("devices"),
    params_devices: state.smartHomeData.get(state.profilesSettings.get("currentProfile")).get("params_devices"),
    logging: state.smartHomeData.get(state.profilesSettings.get("currentProfile")).get("logging"),
    events: state.smartHomeData.get(state.profilesSettings.get("currentProfile")).get("events"),
  };
};

export default connect(mapStateToProps)(AddDeviceModal);
