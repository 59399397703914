import React, { Component } from "react";
import { View, TouchableOpacity, ActivityIndicator, Text, Platform } from "react-native";

import { connect } from "react-redux";
import { ThemeContext } from "../../../ThemeContext";
import { t } from "../../services/i18n";
import {
  StyledIcon,
  PrimaryColorText,
  ComponentContainerTouchable,
  ScreenViewWithoutScroll,
  NoDataScreen,
  SwitchStyled,
} from "../../components/UI/styledComponents";
import { ScreenView } from "../../components/UI/screenViewX";
import mqttClient from "../../services/mqtt";
import ActionSheet from "../../utils/ActionSheet";
import ModalSimple from "../../components/UI/ModalSimple";

import { FontAwesome, MaterialCommunityIcons } from "@expo/vector-icons";

import { irrigationsReceived } from "../../store/actions";
import { List, Map } from "immutable";
import { SkottieLoader } from "../../components/animatedComponents/Loader";
import { store } from "../../store/configureStore";

class SingleIrrigation extends Component {
  static contextType = ThemeContext;

  state = {
    active: this.props.irrigation.active,
    showWebActionSheet: false,
  };

  handleOnPressAction = (index, irrigationIndex) => {
    if (index == 0) {
      this.props.removeItem(irrigationIndex);
    } else if (index == 1) {
      this.props.renameItem(irrigationIndex);
    }

    if (Platform.OS == "web") {
      this.setState({ showWebActionSheet: false });
    }
  };
  showActionSheet = () => {
    if (Platform.OS != "web") {
      this.ActionSheet.show();
    } else {
      this.setState({ showWebActionSheet: true });
    }
  };

  toggleActiveValue = (value) => {
    let irrigation = this.props.irrigation.set("active", value ? 1 : 0);
    this.setState({ active: irrigation.active }, () => {
      mqttClient.sendCondition(irrigation, 1);
    });
  };

  stopIrrigation = (irrigation) => {
    if (irrigation.get("actions")) {
      irrigation.get("actions").map((el, index) => {
        return mqttClient.stateChangeToSend(`/api/set/${el.get("object_id")}/setValue/0`, el.get("object_id"));
      });
    }
    mqttClient.stopCondition(irrigation.get("id"));
  };

  render() {
    const { irrigation, irrigationIndex, navigation, wateringState } = this.props;
    const { theme } = this.context;
    return (
      <ComponentContainerTouchable
        containerStyle={{ width: "100%" }}
        style={{
          flexDirection: "column",
        }}
        onPress={() => {
          this.props.navigation.navigate("IrrigationDetails", {
            title: irrigation.get("name"),
            irrigation: irrigation,
          });
        }}
      >
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <View
            style={{
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            <TouchableOpacity onPress={this.showActionSheet} style={{ paddingHorizontal: 10, marginLeft: -5 }}>
              <StyledIcon name={"more-vertical"} size={18} color={"secondary"} />
            </TouchableOpacity>
            <MaterialCommunityIcons name="group" size={25} color={theme.ICONS_COLOR_SECONDARY} />
            <PrimaryColorText style={{ fontWeight: "bold", marginLeft: 5, maxWidth: "70%" }}>
              {irrigation.get("name") + wateringState}
            </PrimaryColorText>
          </View>
          <View
            style={{
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            <TouchableOpacity style={{ paddingHorizontal: 6 }} onPress={() => mqttClient.runCondition(irrigation.get("id"))}>
              <FontAwesome name="play-circle" color={"rgb(50, 200, 80)"} size={30} />
            </TouchableOpacity>
            <TouchableOpacity style={{ paddingHorizontal: 6 }} onPress={() => this.stopIrrigation(irrigation)}>
              <FontAwesome name="stop-circle" color={"rgb(250, 85, 85)"} size={30} />
            </TouchableOpacity>
          </View>
        </View>
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            paddingLeft: 30,
            marginTop: 10,
            // flex: 1,
          }}
        >
          <PrimaryColorText style={{ paddingRight: 10 }}>{t("ENABLED")}:</PrimaryColorText>
          <SwitchStyled value={irrigation.get("active") == 1 ? true : false} onValueChange={this.toggleActiveValue} />
        </View>
        <ActionSheet
          actionRef={(o) => (this.ActionSheet = o)}
          options={[t("conditions:REMOVE_SECTION"), t("CHANGE_NAME"), t("CLOSE")]}
          cancelButtonIndex={2}
          onPress={(index) => this.handleOnPressAction(index, irrigationIndex)}
          onWebActionSheetClose={() => this.setState({ showWebActionSheet: false })}
          showWebActionSheet={this.state.showWebActionSheet}
        />
      </ComponentContainerTouchable>
    );
  }
}
class IrrigationsScreen extends Component {
  static contextType = ThemeContext;

  state = {
    irrigations: null,
    loading: true,
    addDialogVisible: false,
    newName: "",
    showWebActionSheet: false,
    changeNameDialogVisible: false,
    nameToChange: "",
    clickedAutomatizationIndex: 0,
  };

  componentDidMount() {
    this.props.navigation.setOptions({
      headerRight: () => (
        <View
          style={{
            flexDirection: "row",
          }}
        >
          <TouchableOpacity style={{ paddingTop: 10 }} onPress={() => this.showDialog()}>
            <StyledIcon name={"plus"} color={"tabBar"} size={24} />
          </TouchableOpacity>

          <TouchableOpacity onPress={this.showActionSheetMenu} style={{ padding: 10 }}>
            <StyledIcon name={"more-vertical"} size={24} color={"tabBar"} />
          </TouchableOpacity>
        </View>
      ),
    });
    mqttClient.askForConditions(1);
  }

  handleOnPressActionMenu = (index) => {
    if (Platform.OS == "web") {
      this.setState({ showWebActionSheet: false });
    }
    if (index == 0) {
      mqttClient.changeStateOfConditions(0, 1);
    } else if (index == 1) {
      mqttClient.changeStateOfConditions(1, 1);
    }
  };

  showActionSheetMenu = () => {
    if (Platform.OS != "web") {
      this.ActionSheetMenu.show();
    }
  };

  showDialog = () => {
    this.setState({ addDialogVisible: true });
  };
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.irrigations) {
      let irrigations = nextProps.irrigations;
      if (irrigations) {
        this.setState({
          loading: false,
          irrigations: irrigations,
        });
      }
    }
  }

  removeItem = (irrigationIndex) => {
    let { irrigations } = this.state;

    let irrigationToRemove = irrigations.get(irrigationIndex);
    irrigationToRemove = irrigationToRemove.set("active", 2);
    mqttClient.sendCondition(irrigationToRemove, 1);
    irrigations = irrigations.delete(irrigationIndex);
    this.setState({ irrigations });
  };

  renameItem = (irrigationIndex) => {
    let { irrigations } = this.state;
    let irrigationToChange = irrigations.get(irrigationIndex);
    this.setState({ changeNameDialogVisible: true, nameToChange: irrigationToChange.get("name"), clickedAutomatizationIndex: irrigationIndex });
  };

  handleAddDialogClose = () => {
    this.setState({
      addDialogVisible: false,
      newName: "",
    });
  };
  handleAddDialogOK = () => {
    let newIrrigation = Map({
      id: -1,
      active: 1,
      state: 0,
      type: 1,
      lunched: 0,
      name: this.state.newName,
      sub_conditions: List([
        Map({
          active: 1,
          object_id: 0,
          type: 2,
          value: 900,
          operator: 1,
          state: 0,
          value2: 0,
          counter: 0,
          date_of_change: 0,
          description: "TIME",
        }),
        Map({
          active: 1,
          object_id: 0,
          type: 1,
          value: 127,
          operator: 11,
          state: 0,
          value2: 0,
          counter: 0,
          date_of_change: 0,
          description: "DAYS",
        }),
      ]),
      actions: List([]),
    });
    let irrigations = null;
    if (this.state.irrigations) {
      irrigations = this.state.irrigations.push(newIrrigation);
    }
    this.setState(
      {
        addDialogVisible: false,
        irrigations,
      },
      () => {
        this.props.navigation.navigate("IrrigationDetails", {
          title: this.state.newName,
          irrigation: newIrrigation,
        });
      }
    );
  };

  onChangeNameHandler = (value) => {
    this.setState({
      newName: value,
    });
  };

  onChangeNameHandlerConfirm = (value) => {
    let { irrigations } = this.state;
    if (this.state.clickedAutomatizationIndex >= 0) {
      this.setState({ changeNameDialogVisible: false });
      let automatizationToRename = irrigations.get(this.state.clickedAutomatizationIndex);

      automatizationToRename = automatizationToRename.set("name", this.state.newName);
      mqttClient.sendCondition(automatizationToRename, 1);
    }
  };

  componentWillUnmount() {
    this.props.irrigationsReceived(null);
  }
  render() {
    const { theme } = this.context;
    const { irrigations, loading, addDialogVisible, changeNameDialogVisible, nameToChange } = this.state;
    const { devices, navigation } = this.props;

    return (
      <View style={{ flex: 1 }}>
        {loading ? (
          <ScreenViewWithoutScroll
            style={{
              alignItems: "center",
            }}
          >
            <SkottieLoader style={{ width: 23, height: 23, margin: 3 }} />
          </ScreenViewWithoutScroll>
        ) : irrigations && irrigations.size ? (
          <ScreenView>
            {irrigations.map((irrigation, irrigationIndex) => {
              const actions = irrigation ? irrigation.get("actions") : null;
              let wateringState = "";
              if (actions) {
                const states = store.getState().statesData.get("states");
                if (states) {
                  for (let i = 0; i < actions.size; i++) {
                    const currentState = states.get(actions.get(i).get("object_id"));
                    if (currentState && currentState.get("state") && Number(currentState.get("state")) > 0) {
                      wateringState = " (" + t("WATTERING") + ")";
                      break;
                    }
                  }
                }
              }

              return (
                <SingleIrrigation
                  key={`${irrigation.get("name")}${irrigation.get("id")}`}
                  irrigation={irrigation}
                  irrigationIndex={irrigationIndex}
                  removeItem={this.removeItem}
                  renameItem={this.renameItem}
                  navigation={navigation}
                  wateringState={wateringState}
                />
              );
            })}
          </ScreenView>
        ) : (
          <NoDataScreen />
        )}
        {changeNameDialogVisible && (
          <ModalSimple
            isVisible={changeNameDialogVisible}
            onClose={() => {
              this.setState({ changeNameDialogVisible: false });
            }}
            onOK={this.onChangeNameHandlerConfirm}
            title={"conditions:SECTION_NAME"}
            placeholder={"conditions:ENTER_SECTION_NAME"}
            onInputChange={this.onChangeNameHandler}
          />
        )}

        <ModalSimple
          isVisible={this.state.addDialogVisible}
          title={"conditions:SECTION_NAME"}
          placeholder={"conditions:ENTER_SECTION_NAME"}
          onInputChange={this.onChangeNameHandler}
          onClose={this.handleAddDialogClose}
          onOK={this.handleAddDialogOK}
        />
        <ActionSheet
          actionRef={(o) => (this.ActionSheetMenu = o)}
          options={[t("conditions:TURN_OFF_ALL_SECTIONS"), t("conditions:TURN_ON_ALL_SECTIONS"), t("CLOSE")]}
          cancelButtonIndex={2}
          onPress={(index) => this.handleOnPressActionMenu(index)}
          onWebActionSheetClose={() => this.setState({ showWebActionSheet: false })}
          showWebActionSheet={this.state.showWebActionSheet}
        />
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  let currentProfile = state.profilesSettings.get("currentProfile");
  return {
    devices: state.smartHomeData.get(currentProfile).get("devices"),
    irrigations: state.statesData.get("irrigations"),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    irrigationsReceived: (irrigations) => dispatch(irrigationsReceived(irrigations)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(IrrigationsScreen);
