import React, { Component } from "react";
import { View, TouchableOpacity, Text, Platform } from "react-native";
import { connect } from "react-redux";
import QRCode from "react-native-qrcode-svg";
import { t } from "../../../services/i18n";
import { ScreenView } from "../../../components/UI/screenViewX";
import { PrimaryColorText, ButtonPrimary } from "../../../components/UI/styledComponents";

import { ThemeContext } from "../../../../ThemeContext";
import { TabView, TabBar } from "react-native-tab-view";
import { ListItemContainer } from "../styled";
import CloudIntegration from "../../Initial/CloudIntegration";
import { remoteSupport } from "../../Initial/CloudTools";
import mqttClient from "../../../services/mqtt";
import { TextInput } from "react-native-gesture-handler";

class Support_AccountScreen extends Component {
  static contextType = ThemeContext;

  state = {
    pin: "",
    index: 0,
    email: "",
    status: "",
    routes: [{ key: "code", title: "Code" }],
  };

  onIndexChange = (index) => {
    this.setState({ index });
  };

  makeid = (length) => {
    var result = "";
    var characters = "abcdefghjkmnpqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  sendMessage = (value, type) => {
    let pin = value == "1" ? this.makeid(6) : "";
    this.setState({ pin }, () => mqttClient.customerSupport(value, pin, type));
  };

  componentDidMount() {
    let serverInfo = this.props.server.get("serverInfo");
    if (serverInfo.get("device_id") && this.props.authData) {
      this.setState({
        routes: [
          { key: "code", title: "Code" },
          { key: "cloud", title: "Cloud" },
        ],
      });
    } else {
      this.setState({
        routes: [{ key: "code", title: "Code" }],
      });
    }

    //mqttClient.get_device_representation();
  }

  render() {
    let serverInfo = this.props.server.get("serverInfo");
    const { pin, routes, index, status, email } = this.state;
    const { theme } = this.context;
    let supportInProgress = parseInt(serverInfo.get("supportInfo"));
    const { authData, supportData } = this.props;

    let currentPin = supportInProgress > 0 ? pin : "";
    if (supportData && supportData.result && supportData.result.password) {
      currentPin += " (" + supportData.result.password + ")";
    }
    const renderSupport = ({ route }) => {
      switch (route.key) {
        case "code":
          return (
            <ScreenView>
              <View style={{ padding: 10 }}>
                <PrimaryColorText style={{ paddingBottom: 5 }}>{t("account:SUPPORT_EXPLANATION_1")}</PrimaryColorText>
                <PrimaryColorText>{t("account:SUPPORT_EXPLANATION_2")}</PrimaryColorText>
              </View>

              {supportInProgress > 0 && (
                <View style={{ width: "100%" }}>
                  <Text style={{ color: "green", padding: 10, textAlign: "center" }}>
                    {supportInProgress == 1 ? t("account:CONNECTION_IN_PROGRESS") : t("account:CONNECTION_IN_PROGRESS") + " - 6h"}
                  </Text>
                </View>
              )}
              <ListItemContainer>
                <PrimaryColorText>{t("account:SERIAL_NUMBER")}</PrimaryColorText>
                <PrimaryColorText>{serverInfo.get("mac")}</PrimaryColorText>
              </ListItemContainer>
              <ListItemContainer>
                <PrimaryColorText>{t("PASSWORD")}</PrimaryColorText>
                <PrimaryColorText>{currentPin}</PrimaryColorText>
              </ListItemContainer>
              <View
                style={{
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <ButtonPrimary
                  onPress={() => this.sendMessage(supportInProgress == 0 ? "1" : "0", 1)}
                  style={{ width: "50%", margin: 10 }}
                  // disabled={supportInProgress}
                >
                  {supportInProgress == 0 ? t("START") : t("STOP")}
                </ButtonPrimary>
              </View>
            </ScreenView>
          );
        case "cloud":
          return (
            <ScreenView>
              <View style={{ padding: 10 }}>
                <PrimaryColorText style={{ paddingBottom: 5 }}>{t("account:SUPPORT_EXPLANATION_3")}</PrimaryColorText>
              </View>

              <ListItemContainer>
                <PrimaryColorText>{t("account:INSTALLER_EMAIL")}</PrimaryColorText>
                <TextInput
                  onChangeText={(value) => this.setState({ email: value })}
                  inputMode="email"
                  style={{
                    width: "60%",
                    textAlign: "right",
                    color: theme.TEXT_COLOR_PRIMARY,
                  }}
                ></TextInput>
              </ListItemContainer>

              <View
                style={{
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                {authData ? (
                  <ButtonPrimary
                    onPress={async () => {
                      this.setState({ status: "" });
                      const result = await remoteSupport(authData, email, serverInfo.get("device_id"));
                      if (result.association) {
                        this.setState({ status: t("account:EMAIL_SENT") });
                      } else if (result.status) {
                        this.setState({
                          status: t("ERROR") + ": " + result.status + " " + result.detail,
                        });
                      }
                    }}
                    style={{ width: "50%", margin: 10 }}
                    // disabled={supportInProgress}
                  >
                    {t("SEND")}
                  </ButtonPrimary>
                ) : (
                  <CloudIntegration source="support"></CloudIntegration>
                )}
              </View>
              {status.length > 0 && (
                <View style={{ width: "100%" }}>
                  <Text style={{ color: "green", padding: 10, textAlign: "center" }}>{status}</Text>
                </View>
              )}
            </ScreenView>
          );

        default:
          return null;
      }
    };

    return (
      <TabView
        renderTabBar={(props) => (
          <TabBar
            {...props}
            indicatorStyle={{ backgroundColor: theme.TAB_BAR_TEXT_COLOR_HIGHLITED }}
            style={{ backgroundColor: theme.TAB_BAR_BACKGROUND_COLOR }}
            activeColor={theme.TAB_BAR_TEXT_COLOR_HIGHLITED}
            inactiveColor={theme.TAB_BAR_TEXT_COLOR}
          />
        )}
        navigationState={{ index, routes }}
        renderScene={renderSupport}
        onIndexChange={this.onIndexChange}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    server: state.statesData.get("server"),
    supportData: state.statesData.get("cloudSupportData"),
    authData: state.cloudData ? state.cloudData.get("authData") : null,
  };
};
export default connect(mapStateToProps)(Support_AccountScreen);
