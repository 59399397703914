import * as Actions from "../actionTypes";
import { List, Map, fromJS } from "immutable";

const initialState = Map({
	currentProfile: ""
});

const md5DataReducer = (state = initialState, action) => {
	let currentProfile = state.get("currentProfile");
	switch (action.type) {
		case Actions.CHANGE_CURRENT_PROFILE:
			return state.set("currentProfile", action.payload);
		case Actions.MD5_DATA_RECEIVED:
			return state.setIn([currentProfile], fromJS(action.payload));

		case Actions.DELETE_PROFILE:
			let newState = state.delete(action.payload);
			if (action.payload == state.get("currentProfile")) {
				return newState.set("currentProfile", "");
			}
			return newState;

		default:
			return state;
	}
};

export default md5DataReducer;
