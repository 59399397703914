import React, { useContext } from "react";
import { View } from "react-native";

import { FontAwesome, MaterialCommunityIcons, Feather, MaterialIcons } from "@expo/vector-icons";
import { useTheme } from "../../../../ThemeContext";

const DefaultIcon = (props) => {
  const { theme } = useTheme();

  let { library, color } = props;

  let content = null;
  switch (library) {
    case "MaterialCommunityIcons":
      return (content = (
        <MaterialCommunityIcons
          name={props.name}
          size={props.size || 22}
          color={color ? color : theme.ICONS_COLOR_PRIMARY}
          style={[{ padding: 5 }, props.style]}
        />
      ));
    case "MaterialIcons":
      return (content = (
        <MaterialIcons
          name={props.name}
          size={props.size || 22}
          color={color ? color : theme.ICONS_COLOR_PRIMARY}
          style={[{ padding: 5 }, props.style]}
        />
      ));
    case "FontAwesome":
      return (content = (
        <FontAwesome
          name={props.name}
          size={props.size || 22}
          color={color ? color : theme.ICONS_COLOR_PRIMARY}
          style={[{ padding: 5 }, props.style]}
        />
      ));

    case "Feather":
      return (content = (
        <Feather name={props.name} size={props.size || 22} color={color ? color : theme.ICONS_COLOR_PRIMARY} style={[{ padding: 5 }, props.style]} />
      ));

    default:
      break;
  }

  return <View>{content}</View>;
};

export default DefaultIcon;
