import React, { Component } from "react";
import { connect } from "react-redux";
import { View, Text, TouchableOpacity } from "react-native";
import { t } from "../../services/i18n";

import {
  AccessControlComponent,
  CameraComponent,
  InputComponent,
  LedComponent,
  LedWWComponent,
  RegulatorComponent,
  RelayComponent,
  SatelOutputComponent,
  EventComponent,
  RGBComponent,
  RollerComponent,
  SatelComponent,
  UnknownTypeComponent,
  LinearInputComponent,
  TextApiComponent,
  RadioComponent,
  IpRadioComponent,
  WebViewComponent,
  ChartComponent,
  ElsnerStationComponent,
  EnergyComponent,
  WaetherStationComponent,
  IRComponent,
  NoStateComponent,
  CameraUniversalComponent,
} from "../../components/smartHomeComponents/index";
import JanusSipComponent from "../../screens/AudioVideo/JanusSipComponent";
import AutomatizationTimeBar from "./AutomatizationTimeBar";
import PlaceGroupComponent from "../../screens/Places/PlaceGroupComponent";
import HeaderWithDotsDevices from "../HeaderWithDotsDevices";
import ModalSimple from "../../components/UI/ModalSimple";
import CustomComponent from "../../components/smartHomeComponents/custom/CustomComponent";
import CustomCarousel from "../../components/smartHomeComponents/custom/CustomCarousel";
import { ComponentContainer, PrimaryColorText } from "../../components/UI/styledComponents";
import { ThemeContext } from "../../../ThemeContext";
import JanusVideoRoomScreen from "../../screens/AudioVideo/JanusVideoRoomScreen";
import SeparatorComponent from "../../components/smartHomeComponents/separator/SeparatorComponent";
import GoToModal from "../GoToModal";
import { useRoute } from "@react-navigation/native";
import { SkottieLoader } from "../../components/animatedComponents/Loader";
import { WavyBars } from "../../components/smartHomeComponents/water/WavyBars";

export const devicesWithoutModal = ["grupa", "separator", "webview", "kamera_mjpeg", "kamera_rtsp", "sip", "wykres", "custom", "wekta"];

export const specialScreen = { kontrola_dostepu: "AccessControlScreen", ir: "IRScreen" };

class DeviceListHandlerX extends Component {
  static contextType = ThemeContext;

  state = {
    delayDialogVisible: false,
    sceneDelay: this.props.deviceInScene ? this.props.deviceInScene.get("delay") : null,
  };

  componentType = (device) => {
    if (!device) return UnknownTypeComponent;
    switch (device.get("typ_komponentu")) {
      case "kamera_mjpeg":
        return CameraComponent;
      case "kamera_rtsp":
        return CameraUniversalComponent;

      case "custom": {
        if (device && device.get("param1") === 2) {
          return CustomCarousel;
        }
        return CustomComponent;
      }
      case "flaga":
      case "flaga_l":
      case "flaga_p":
      case "przekaznik":
        return RelayComponent;

      case "satel_wyj":
        return SatelOutputComponent;

      case "event":
      case "ircode":
        return EventComponent;

      case "led":
      case "flaga_liniowa":
      case "flaga_liniowa16":
        return LedComponent;

      case "ledww":
        return LedWWComponent;

      case "reg":
      case "ac":
        return RegulatorComponent;

      case "radio":
        return RadioComponent;

      case "ip_radio":
        return IpRadioComponent;

      case "rgb":
      case "rgbw":
        return RGBComponent;

      case "roleta":
      case "roleta_procenty":
      case "roleta_lamelki":
        return RollerComponent;

      case "alarm":
      case "satel":
      case "satel_alarm":
        return SatelComponent;

      case "satel_wej":
      case "wej":
      case "wej_d":
      case "wej_g":
      case "motion":
      case "presence":
        return InputComponent;

      case "lin_wej":
      case "bit8":
      case "bit16":
      case "sbit16":
      case "bit32":
      case "moc_ciagla":
      case "moc_przyr":
      case "temp":
      case "tekst_can":
      case "sensor":
      case "poziom_wody":
        return LinearInputComponent;

      case "tekst_api":
        if (this.props.deviceState && this.props.deviceState.get("desc") && this.props.deviceState.get("desc").startsWith("<html>")) {
          return TextApiComponent;
        } else {
          return LinearInputComponent;
        }

      case "wekta":
        return JanusVideoRoomScreen;
      case "webview": {
        return WebViewComponent;
      }
      case "sip": {
        //return SkottieLoader;
        return JanusSipComponent;
      }

      case "wykres": {
        return ChartComponent;
      }
      case "ir": {
        return IRComponent;
      }
      case "grupa": {
        return null;
      }
      case "separator": {
        return SeparatorComponent;
      }
      case "symulacja": {
        return null;
      }
      case "kontrola_dostepu": {
        return AccessControlComponent;
      }
      case "stacja_elsner": {
        return ElsnerStationComponent;
      }
      case "energy": {
        return EnergyComponent;
      }
      case "stacja_pogodowa": {
        return WaetherStationComponent;
      }
      default:
        return UnknownTypeComponent;
    }
  };

  showDelayDialog = () => {
    this.setState({ delayDialogVisible: true });
  };

  handleDelayDialogClose = () => {
    this.setState({
      sceneDelay: this.props.deviceInScene.get("delay"),
    });
    this.setState({ delayDialogVisible: false });
  };

  handleDelayDialogOK = () => {
    let { device, changeSceneFromDevice, deviceInScene } = this.props;
    let infoToUpdate = deviceInScene.set("delay", this.state.sceneDelay.toString());
    changeSceneFromDevice(infoToUpdate, deviceInScene.get("temporary_id"));
    this.setState({ delayDialogVisible: false });
  };

  onSceneDelayChange = (value) => {
    let sceneDelayInMs = Number(value) * 1000;

    this.setState({
      sceneDelay: sceneDelayInMs,
    });
  };

  onTurnOnTimeChange = (turnOnTime) => {
    let { changeSceneFromDevice, deviceInScene } = this.props;
    let infoToUpdate = deviceInScene.set("param3", turnOnTime);
    changeSceneFromDevice(infoToUpdate, deviceInScene.get("temporary_id"));
  };

  getHeader = (deviceNameToShow, state, upTime) => {
    let { device, deviceInScene, removeDeviceFromScene, params_device, deviceFromCustom, deviceFromAutomatization, source } = this.props;
    return (
      <HeaderWithDotsDevices
        device={device}
        removeDeviceFromScene={removeDeviceFromScene}
        deviceInScene={deviceInScene}
        params_device={params_device}
        deviceFromCustom={deviceFromCustom}
        upTime={upTime}
        state={state}
        deviceNameToShow={deviceNameToShow}
        deviceFromAutomatization={deviceFromAutomatization}
        soruce={source}
      />
    );
  };

  getHeaderNoState = (deviceNameToShow) => {
    //let { device, deviceInScene, removeDeviceFromScene, deviceFromCustom, deviceFromAutomatization, source, params_device } = this.props;
    let { device, deviceInScene, removeDeviceFromScene, params_device, deviceFromCustom, deviceFromAutomatization, source } = this.props;
    return (
      <HeaderWithDotsDevices
        deviceNameToShow={deviceNameToShow}
        removeDeviceFromScene={removeDeviceFromScene}
        deviceInScene={deviceInScene}
        device={device}
        params_device={params_device}
        noStateDevice={true}
        deviceFromCustom={deviceFromCustom}
        deviceFromAutomatization={deviceFromAutomatization}
        soruce={source}
      />
    );
  };

  render() {
    let {
      device,
      deviceInScene,
      deviceState,
      changeSceneFromDevice,
      removeDeviceFromScene,
      params_device,
      deviceFromCustom,
      group_params,
      activeSceneControl,
      gretingViewHeight,
      customWidth,
      deviceFromAutomatization,
      source,
      currentConnection,
      customBg,
      popup,
      detailContent,
      realWidthColumn,
    } = this.props;
    const { theme } = this.context;
    let { delayDialogVisible } = this.state;

    let blockObject = false;
    if ((currentConnection == "local" || currentConnection == "local2") && params_device && (params_device.get("params") & 0x80) > 0) {
      blockObject = true;
    }
    if ((currentConnection == "cloud" || currentConnection == "new_cloud") && params_device && (params_device.get("params") & 0x100) > 0) {
      blockObject = true;
    }
    if (device && !blockObject) {
      let ComponentName = this.componentType(device);

      let readOnly = params_device && (params_device.get("params") & 0x40) > 0 ? true : false;
      //show long name if group param 02 > 0
      let deviceNameToShow = (group_params & 0x02) > 0 ? device.get("opis_rozwiniety") : device.get("opis_menu");

      let upTime = deviceState && deviceState.get("upTime") != null && deviceState.get("upTime") != undefined ? deviceState.get("upTime") : null;

      let state = deviceState ? deviceState : null;

      //show device only on sketch
      if ((group_params & 0x01) > 0) {
        return null;
      } else {
        if (device && device.get("typ_komponentu") == "grupa") {
          return <PlaceGroupComponent fromDevices={true} groupId={device.get("funkcja") + 1} overWriteName={device.get("opis_menu")} />;
        } else if (ComponentName && ComponentName.name == "UnknownTypeComponent") {
          return (
            <ComponentContainer containerStyle={{ width: "100%" }}>
              <UnknownTypeComponent device={device} header={this.getHeader(deviceNameToShow, state, upTime)} />
            </ComponentContainer>
          );
        } else if (
          (deviceState && deviceState.get("state")) ||
          device.get("typ_komponentu") == "event" ||
          device.get("typ_komponentu") == "ircode" ||
          device.get("typ_komponentu") == "roleta" ||
          device.get("typ_komponentu") == "kamera_mjpeg" ||
          device.get("typ_komponentu") == "kamera_rtsp" ||
          device.get("typ_komponentu") == "webview" ||
          device.get("typ_komponentu") == "wekta" ||
          device.get("typ_komponentu") == "wykres" ||
          device.get("typ_komponentu") == "custom" ||
          device.get("typ_komponentu") == "ir" ||
          device.get("typ_komponentu") == "sip" ||
          device.get("typ_komponentu") == "separator" ||
          device.get("typ_komponentu") == "kontrola_dostepu"
        ) {
          if (
            (device.get("typ_komponentu") == "reg" && deviceState.get("setTemperature") == undefined) ||
            (device.get("typ_komponentu") == "radio" && deviceState.get("freq") == undefined) ||
            (device.get("typ_komponentu") == "stacja_elsner" && deviceState.get("temp") == undefined) ||
            (device.get("typ_komponentu") == "stacja_pogodowa" && deviceState.get("temp") == undefined)
          ) {
            return (
              <NoStateComponent
                header={this.getHeaderNoState(deviceNameToShow)}
                deviceFromCustom={deviceFromCustom}
                dimensions={this.props.dimension}
              />
            );
          } else if (ComponentName) {
            const componentContent = deviceFromCustom ? (
              <View
                style={{
                  justifyContent: "center",
                  alignItems: deviceFromCustom && device.get("typ_komponentu") != "led" ? "center" : null,
                }}
              >
                {this.getHeader(deviceNameToShow, state, upTime)}

                <ComponentName
                  key={device.get("id")}
                  dimensions={this.props.dimension}
                  device={device}
                  detailContent={detailContent}
                  deviceState={deviceState}
                  params_device={params_device}
                  readOnly={readOnly}
                  deviceFromCustom={deviceFromCustom}
                  source={source}
                  currentConnection={currentConnection}
                  popup={popup}
                  realWidthColumn={realWidthColumn}
                />
              </View>
            ) : (
              <ComponentName
                customWidth={customWidth}
                key={device.get("id")}
                device={device}
                dimensions={this.props.dimension}
                detailContent={detailContent}
                deviceState={deviceState}
                deviceInScene={deviceInScene}
                deviceFromAutomatization={deviceFromAutomatization}
                activeSceneControl={activeSceneControl}
                changeScene={changeSceneFromDevice}
                params_device={params_device}
                group_params={group_params}
                header={!popup && this.getHeader(deviceNameToShow, state, upTime)}
                readOnly={readOnly}
                deviceFromCustom={deviceFromCustom}
                gretingViewHeight={gretingViewHeight}
                source={source}
                currentConnection={currentConnection}
                popup={popup}
                realWidthColumn={realWidthColumn}
              />
            );

            return detailContent || ComponentName.displayName === "CustomCarousel" ? (
              componentContent
            ) : (
              <ComponentContainer
                deviceFromCustom={deviceFromCustom}
                containerStyle={{ width: "100%" }}
                style={{ backgroundColor: device.get("typ_komponentu") == "separator" ? "transparent" : theme.COMPONENT_BACKGROUND_COLOR }}
                customBg={customBg}
              >
                {componentContent}
                {deviceInScene && !deviceFromAutomatization && (
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginTop: 16,
                      paddingTop: 6,
                      paddingHorizontal: 6,
                      borderTopColor: theme.TEXT_COLOR_SECONDARY,
                      borderTopWidth: 0.4,
                    }}
                  >
                    <PrimaryColorText
                      style={{
                        fontSize: 12,
                      }}
                    >
                      {t("DELAY")}
                    </PrimaryColorText>

                    <View style={{ flexDirection: "row" }}>
                      <TouchableOpacity disabled={readOnly} onPress={this.showDelayDialog}>
                        <Text
                          style={{
                            borderWidth: 1,
                            padding: 4,
                            minWidth: 50,
                            textAlign: "center",
                            borderColor: theme.PLACEHOLDER_BACKGROUND_COLOR,
                            color: theme.TEXT_COLOR_PRIMARY,
                            backgroundColor: theme.APP_BACKGROUND_COLOR,
                          }}
                        >
                          {this.state.sceneDelay / 1000}
                        </Text>
                      </TouchableOpacity>
                      {delayDialogVisible && (
                        <ModalSimple
                          isVisible={delayDialogVisible}
                          title={t("scenes:SET_DELAY")}
                          onInputChange={this.onSceneDelayChange}
                          onClose={this.handleDelayDialogClose}
                          onOK={this.handleDelayDialogOK}
                          keyboardType={"numeric"}
                        />
                      )}
                      <PrimaryColorText
                        style={{
                          fontSize: 12,
                          width: 10,
                          textAlign: "center",
                          padding: 4,
                        }}
                      >
                        {"s"}
                      </PrimaryColorText>
                    </View>
                  </View>
                )}
                {deviceFromAutomatization && ["led", "flaga", "flaga_l", "flaga_p", "przekaznik"].includes(device.get("typ_komponentu")) ? (
                  <AutomatizationTimeBar param3={deviceInScene.get("param3")} turnOnTimeChange={this.onTurnOnTimeChange} type={"on"} />
                ) : null}
                {deviceFromAutomatization && ["ledww"].includes(device.get("typ_komponentu")) ? (
                  <AutomatizationTimeBar param3={deviceInScene.get("param3")} turnOnTimeChange={this.onTurnOnTimeChange} type={"fade"} />
                ) : null}
              </ComponentContainer>
            );
          }
        } else {
          return (
            <NoStateComponent
              header={this.getHeaderNoState(deviceNameToShow)}
              deviceFromCustom={deviceFromCustom}
              dimensions={this.props.dimension}
            />
          );
        }
      }
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  let paramsDevice = null;
  let deviceState = null;
  let currentConnection = null;
  let serverInfo = null;
  try {
    paramsDevice = state.smartHomeData.get(state.profilesSettings.get("currentProfile")).get("params_devices").get(ownProps.device.get("id"));
    deviceState = state.statesData.get("states").get(ownProps.device.get("id"));
    serverInfo = state.statesData.get("server").get("serverInfo");
    if (state && state.statesData && state.statesData.get("currentConnection")) {
      currentConnection = `${state.statesData.get("currentConnection")}`;
    }
  } catch (e) {}

  return {
    deviceState: deviceState,
    currentConnection: currentConnection,
    serverInfo: serverInfo,
    params_device: paramsDevice,
    dimension: state.statesData.get("dimensions"),
  };
};

//device, header, readOnly, deviceInScene, deviceFromCustom, params_device, group_params, dimensions, deviceState

const DeviceListHandler = (props) => {
  const route = useRoute();

  if (props && props.device && props.dimension) {
    return devicesWithoutModal.includes(props.device.get("typ_komponentu")) ||
      props.deviceFromAutomatization ||
      props.detailContent ||
      !props.dimension.get("smallTiles") ? (
      <DeviceListHandlerX {...props} />
    ) : (
      <GoToModal
        params={{
          placeName: props.device.get("opis_menu"),
          device: props.device,
          props: { ...props },
          routeName: route.name,
        }}
        screenName={specialScreen[props.device.get("typ_komponentu")] ?? "DeviceDetails"}
      >
        <DeviceListHandlerX {...props} />
      </GoToModal>
    );
  } else {
    return null;
  }
};

export default connect(mapStateToProps, null)(DeviceListHandler);
