import * as Actions from "../actionTypes";
import { Map, fromJS, List } from "immutable";

const initialState = fromJS({
  authData: null,
  associationsData: null,
});
const cloudDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.AUTH_DATA_RECEIVED:
      if (action.payload === null) {
        state.set("associationsData", fromJS(null));
        state.set("metaData", fromJS(null));
      }
      return state.set("authData", fromJS(action.payload));
    case Actions.ASSOCIATIONS_DATA_RECEIVED:
      return state.set("associationsData", fromJS(action.payload));
    case Actions.META_DATA_RECEIVED:
      return state.set("metaData", fromJS(action.payload));
    case Actions.ICE_SERVER_RECEIVED:
      return state.set("iceServers", fromJS(action.payload));
    default:
      return state;
  }
};

export default cloudDataReducer;
