import React, { Component } from "react";
import { View } from "react-native";
import { connect } from "react-redux";

import DeviceListHandler from "../../../utils/smartHomeHandlers/DeviceListHandler";
import CustomCarousel from "./CustomCarousel";

class CustomComponent extends Component {
  render() {
    const { device, devices, header, params_device, source } = this.props;

    let powiazane = params_device ? params_device.get("powiazane") : "";
    let powiazaneArrayWithPossibleCustom = powiazane ? powiazane.split(",") : [];
    let powiazaneArray = [];
    powiazaneArrayWithPossibleCustom.forEach((el, index) => {
      el = el.split(":").reverse();
      if (devices.get(el[0])) {
        devices.get(el[0]).get("typ_komponentu") != "custom" &&
          powiazaneArray.push({
            index: el[1] ? el[1] : index,
            objectID: el[0],
          });
      }
    });

    powiazaneArray.sort((a, b) => parseFloat(a.index) - parseFloat(b.index));

    let rows = Array.from({ length: device.get("max") }, (v, indexRow) => {
      return (
        <View
          key={`row${indexRow}`}
          style={{
            display: "flex",
            flexDirection: "row",
            paddingTop: indexRow > 0 ? 10 : 0,
          }}
        >
          {Array.from({ length: device.get("min") }, (v, indexColumn) => {
            let arrayIndex = indexRow * device.get("min") + indexColumn;
            if (powiazaneArray[arrayIndex]) {
              let deviceIndex = Number(powiazaneArray[arrayIndex].objectID);

              return (
                devices.get(deviceIndex) && (
                  <View
                    key={deviceIndex}
                    style={{
                      flex: 1,
                    }}
                  >
                    <DeviceListHandler device={devices.get(deviceIndex)} deviceFromCustom={true} />
                  </View>
                )
              );
            }
          })}
        </View>
      );
    });
    let customTitleHidden = params_device && (params_device.get("params") & 0x4000) > 0;

    if (source == "fav") customTitleHidden = false;

    return (
      <View
        style={{
          marginVertical: customTitleHidden ? -5 : 0,
        }}
      >
        {!customTitleHidden ? header : null}
        {rows}
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    devices: state.smartHomeData.get(state.profilesSettings.get("currentProfile")).get("devices"),
  };
};

export default connect(mapStateToProps)(CustomComponent);
