import React, { Component } from "react";
import { Text, View, TouchableOpacity } from "react-native";
import { t } from "../../../services/i18n";
import { connect } from "react-redux";

import { PrimaryColorText, ButtonPrimary } from "../../../components/UI/styledComponents";
import { devicesForGroups } from "../../../utils";
import PickerSelect from "../../../components/UI/PickerSelect";
import PickerSelectObjects from "../../../components/UI/PickerSelectObjects";
import ModalWithContent from "../../../components/UI/ModalWithContent";

class GreetingViewModal extends Component {
  state = {
    selectedGroup: -1,
    selectedDevice: null,
    devicesInGroupIdArray: [],
    selectedOption: 0,
    buttonSaveDisabled: false,
  };

  onGroupValueChange = (value) => {
    this.setState({
      selectedGroup: value,
      selectedDevice: null,
      buttonSaveDisabled: true,
    });
  };

  onDeviceValueChange = (value) => {
    if (value) {
      this.setState({ selectedDevice: value, buttonSaveDisabled: false });
    }
  };

  onOptionChange = (value) => {
    this.setState({
      selectedOption: value,
      buttonSaveDisabled: value == 2 ? true : false,
    });
  };

  onSaveHandler = () => {
    const { devices } = this.props;

    let selectedView;
    let { selectedOption, selectedDevice } = this.state;
    if (selectedOption == 0) {
      selectedView = {
        name: t("WEATHER"),
        type: "weather",
        id: null,
      };
    } else if (selectedOption == 1) {
      selectedView = {
        name: t("CALENDAR"),
        type: "calendar",
        id: null,
      };
    } else {
      if (devices && selectedDevice && devices.get(selectedDevice)) {
        selectedView = {
          name: `${t("DEVICE")}: ${devices.get(selectedDevice).get("opis_menu")}`,
          type: "device",
          id: selectedDevice,
        };
      }
    }
    if (selectedView) {
      this.props.onSave(selectedView);
    }
  };

  setDevices = () => {
    const { devices, group_devices, logging, params_devices } = this.props;

    let devicesToSend = [];

    let listOfDevicesForGroup = devicesForGroups(group_devices, this.state.selectedGroup);

    listOfDevicesForGroup.map((deviceForGroup) => {
      let deviceID = deviceForGroup.get("id_obiektu");
      if (devices.get(deviceID)) {
        let device = devices.get(deviceID);
        let typKomponentu = device.get("typ_komponentu");
        if (typKomponentu == "custom") {
          let powiazane = params_devices.get(deviceID) ? params_devices.get(deviceID).get("powiazane") : "";
          let powiazaneArrayWithPossibleCustom = powiazane ? powiazane.split(",") : [];

          powiazaneArrayWithPossibleCustom.map((el, index) => {
            el = el.split(":").reverse();
            let deviceFromCustom = devices.get(el[0]);
            if (
              deviceFromCustom &&
              deviceFromCustom.get("typ_komponentu") != "custom" &&
              (deviceFromCustom.get("czas_logowania") >= 0 || logging.find((el) => el.get("id_obiektu") == deviceFromCustom.get("id")))
            ) {
              devicesToSend.push({
                label: deviceFromCustom.get("opis_menu"),
                value: deviceFromCustom.get("id"),
              });
            }
          });
        } else {
          devicesToSend.push({
            label: device.get("opis_menu"),
            value: device.get("id"),
          });
        }
      }
    });
    return devicesToSend;
  };

  render() {
    const { isVisible, onClose, devices, group_devices } = this.props;
    const { selectedDevice, selectedGroup, buttonSaveDisabled, selectedOption } = this.state;

    let options = [
      {
        label: t("WEATHER"),
        value: 0,
      },
      {
        label: t("CALENDAR"),
        value: 1,
      },
      {
        label: t("DEVICE"),
        value: 2,
      },
    ];

    let devicesInGroup = this.setDevices();

    return (
      <ModalWithContent isVisible={isVisible} title={t("CHOOSE_OBJECT_TYPE")} onClose={onClose}>
        <View style={{ paddingBottom: 20 }}>
          <PrimaryColorText style={{ paddingBottom: 4 }}>{t("OBJECT_TYPE")}</PrimaryColorText>
          <PickerSelect
            placeholder={{
              label: t("OBJECT_TYPE"),
              value: null,
            }}
            items={options}
            onValueChange={this.onOptionChange}
            value={selectedOption}
          />
        </View>
        {selectedOption == 2 && (
          <View>
            <View style={{ paddingBottom: 16 }}>
              <PrimaryColorText style={{ paddingBottom: 4 }}>{t("OBJECT_PLACE")}</PrimaryColorText>
              <PickerSelectObjects onValueChange={this.onGroupValueChange} value={selectedGroup} type={"groups"} />
            </View>

            <View style={{ paddingBottom: 20 }}>
              <PrimaryColorText style={{ paddingBottom: 4 }}>{t("OBJECT_NAME")}</PrimaryColorText>
              <PickerSelectObjects type={"devices"} items={devicesInGroup} onValueChange={this.onDeviceValueChange} value={selectedDevice} />
            </View>
          </View>
        )}
        <ButtonPrimary disabled={buttonSaveDisabled} onPress={this.onSaveHandler}>
          {t("DONE")}
        </ButtonPrimary>
      </ModalWithContent>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    group_devices: state.smartHomeData.get(state.profilesSettings.get("currentProfile")).get("group_devices"),
    devices: state.smartHomeData.get(state.profilesSettings.get("currentProfile")).get("devices"),
    params_devices: state.smartHomeData.get(state.profilesSettings.get("currentProfile")).get("params_devices"),
    logging: state.smartHomeData.get(state.profilesSettings.get("currentProfile")).get("logging"),
  };
};

export default connect(mapStateToProps)(GreetingViewModal);
