import React, { Component } from "react";
import { Text, View } from "react-native";
import { t } from "../../services/i18n";
import { Feather } from "@expo/vector-icons";

import {
	ComponentContainer,
	PrimaryColorText,
	StyledIcon,
} from "../../components/UI/styledComponents";
import PickerSelect from "../../components/UI/PickerSelect";

class TemperatureRow extends Component {
	render() {
		const { heatingSchedule, temp, onTempValueChange } = this.props;
		return (
			<ComponentContainer
				containerStyle={{ width: "100%" }}
				style={{ flexDirection: "row" }}
			>
				<View
					style={{
						display: "flex",
						width: "47%",
						marginRight: "6%",
					}}
				>
					<View
						style={{
							display: "flex",
							flexDirection: "row",
							alignItems: "center",
							marginVertical: 5,
						}}
					>
						<StyledIcon name={"sun"} size={20} style={{ marginRight: 5 }} />
						<PrimaryColorText>{t("heating:HIGH_TEMP")}</PrimaryColorText>
					</View>
					<PickerSelect
						placeholder={{}}
						items={temp}
						onValueChange={(e) => onTempValueChange(e, "sTemp")}
						value={Number(heatingSchedule.sTemp.toFixed(1))}
					/>
				</View>
				<View
					style={{
						display: "flex",
						width: "47%",
					}}
				>
					<View
						style={{
							display: "flex",
							flexDirection: "row",
							alignItems: "center",
							marginVertical: 5,
						}}
					>
						<StyledIcon name={"moon"} size={20} style={{ marginRight: 5 }} />
						<PrimaryColorText>{t("heating:LOW_TEMP")}</PrimaryColorText>
					</View>
					<View>
						<PickerSelect
							placeholder={{}}
							items={temp}
							onValueChange={(e) => onTempValueChange(e, "mTemp")}
							value={Number(heatingSchedule.mTemp.toFixed(1))}
						/>
					</View>
				</View>
			</ComponentContainer>
		);
	}
}

export default TemperatureRow;
