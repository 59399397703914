import React, { Component, useEffect, useState } from "react";

import { View, Text, StyleSheet, TouchableOpacity, Animated, Platform } from "react-native";
import {
  PrimaryColorText,
  ComponentContainer,
  StyledIcon,
  ButtonPrimary,
  TextInputStyled,
  SecondaryColorText,
  SwitchStyled,
  SliderStyled,
} from "../../components/UI/styledComponents";
import { ScreenView } from "../../components/UI/screenViewX";
import PickerSelect from "../../components/UI/PickerSelect";
import NfcManager, { NfcTech, NfcEvents } from "react-native-nfc-manager";

import DateTimePicker from "../../components/UI/dateTimePicker";
import moment from "moment";
import { useSelector } from "react-redux";

import { t } from "../../services/i18n";
const conditionTypes = {
  DAYS: 1,
  TIME: 2,
  OBJECT: 0,
  // AT_TIME: 2,
  // BEFORE_SUNRISE: 3,
  // AFTER_SUNRISE: 4,
  // BEFORE_SUNSET: 5,
  // AFTER_SUNSET: 6,
  EVENT: 8,
  LOCATION_ENTER: 12,
  LOCATION_LEAVE: 13,
  OBJECT_PARAM2: 15,
  DATE: 16,
};

let hrs = [];
for (let i = 0; i <= 24; i++) {
  let label = i % 2 == 0 ? i / 2 : (i + 1) / 2 - 1 + ",5";
  // if (label < 10) {
  // 	label = "0" + label;
  // }
  let value = i / 2;
  let element = {
    label: label.toString(),
    value: value * 60,
  };
  hrs.push(element);
}
let percent = [];

for (let index = 0; index <= 100; index = index + 5) {
  let element = {
    label: "" + index + " %",
    value: index,
  };

  percent.push(element);
}

const ObjectComponent = ({ condition, modifyCondition, index }) => {
  let changeHexToPercent = (hexValue) => {
    let percentValue = (hexValue * 100) / 255;
    if (percentValue < 1) {
      percentValue = Math.floor(percentValue * 10) / 10;
    } else {
      percentValue = Math.round(percentValue);
    }

    return percentValue;
  };

  let changePercentToHex = (percentValue) => {
    let hexValue = Math.round((percentValue * 255) / 100);
    return hexValue;
  };
  const operators = [
    { label: t("conditions:EQUAL_TO"), value: 1 },
    { label: t("conditions:UNEQUAL_TO"), value: 4 },
    { label: t("conditions:MORE_THAN"), value: 2 },
    { label: t("conditions:LESS_THAN"), value: 3 },
  ];

  const rollerTypes = [
    { label: t("ROLLERS") + " - ", value: 0 },
    { label: t("LAMMELS") + " - ", value: 15 },
  ];

  const device = useSelector((state) =>
    state.smartHomeData.get(state.profilesSettings.get("currentProfile")).get("devices").get(condition.object_id)
  );

  const deviceState = useSelector((state) => state.statesData.get("states").get(condition.object_id));

  const params_device = useSelector((state) =>
    state.smartHomeData.get(state.profilesSettings.get("currentProfile")).get("params_devices").get(condition.object_id)
  );
  let deviceType = device && device.get("typ_komponentu");
  let [devState, setDevState] = useState(deviceState);
  let [operator, setOperator] = useState(condition.operator);
  let [type, setType] = useState(condition.type);
  let [value, setValue] = useState(deviceType === "temp" ? condition.value / 10 : condition.value);
  let [initValue, setInitValue] = useState(condition.value);

  const evaluateState = (state) => {
    switch (state.toString()) {
      case "0":
        return "DISARMED"; //uzbroj
      case "1":
        return "ARMED"; //rozbroj
      case "2":
        //this.props.onShowAlertScreen(true);
        return "ALARM"; //rozbrij
      case "3":
        return "ALARM_DETECTION"; //dowole
      case "4":
        return "ARMING"; //dowolne
      default:
        return "UNKNOWN";
    }
  };

  const evaluateStateEnergy = (state) => {
    switch (state.toString()) {
      case "1":
        return "PRODUCING"; //uzbroj
      case "2":
        return "EXPORTING"; //rozbroj
      case "3":
        return "CONSUMING"; //rozbrij
      case "4":
        return "CHARGING"; //dowole
      case "5":
        return "DISCHARGING"; //dowolne
      default:
        return "UNKNOWN";
    }
  };

  const onValuePickerChange = (valueFromPicker, type = 0) => {
    if (type == 1) {
      if (valueFromPicker == 0) {
        setOperator(1);
      } else {
        setOperator(5); // operator bigger equal
      }
      setValue(valueFromPicker);
    } else if (type == 2) {
      if (valueFromPicker == 0) {
        setOperator(13); // operator and not
      } else {
        setOperator(11); // operator and
      }
      setType(15); // to check param2
      if (operator !== 13) {
        setValue(1 << Number(valueFromPicker > 0 ? valueFromPicker - 1 : 0));
      }
    } else if (type == 3) {
      setOperator(11); // operator and
      setValue(1 << Number(valueFromPicker > 0 ? valueFromPicker - 1 : 0));
    } else {
      setValue(valueFromPicker);
    }
  };

  useEffect(() => {
    if (deviceType === "temp") {
      modifyCondition(
        {
          ...condition,
          value: parseFloat(value.toString().replace(",", ".")) * 10,
          operator: operator,
          type: type,
        },
        index
      );
    } else {
      modifyCondition({ ...condition, value: value, operator: operator, type: type }, index);
    }
  }, [value, operator, type]);

  const onOperatorPickerChange = (value) => {
    setOperator(value);
  };

  const listenToNfcEventOnce = async () => {
    try {
      // register for the NFC tag with NDEF in it
      await NfcManager.requestTechnology(NfcTech.MifareIOS);
      const tag = await NfcManager.getTag();
      let cardNum = Number("0x" + tag.id.substring(0, 8));
      setDevState({ state: cardNum });
      setValue(cardNum.toString());
    } catch (ex) {
    } finally {
      NfcManager.cancelTechnologyRequest();
    }
  };

  const onRollerTypeChange = (value) => {
    setType(value);
  };

  const isPowerOfTwo = (n) => {
    return n > 0 && (n & (n - 1)) == 0 ? true : false;
  };

  const findPosition = (n) => {
    if (isPowerOfTwo(n) == false) return -1;
    var i = 1;
    var pos = 1;
    while ((i & n) == 0) {
      i = i << 1;
      pos += 1;
    }
    return pos;
  };

  const typeView = () => {
    if (
      [
        "flaga",
        "flaga_l",
        "flaga_p",
        "przekaznik",
        "satel_wej",
        "wej",
        "wej_d",
        "wej_g",
        "rgb",
        "rgbw",
        "rgbww",
        "ledww",
        "motion",
        "presence",
        "satel_wyj",
      ].includes(deviceType)
    ) {
      return (
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <PrimaryColorText>{t("conditions:RUN_IF")}</PrimaryColorText>
          <TouchableOpacity
            onPress={() => onValuePickerChange(value == 0 ? 1 : 0, 1)}
            style={[
              styles.switchButton,
              {
                backgroundColor: value == 0 ? "rgb(250, 85, 85)" : "rgb(50, 200, 80)",
              },
            ]}
          >
            <Text
              style={{
                color: "white",
                fontWeight: "600",
                textTransform: "uppercase",
              }}
            >
              {value == 0 ? t("conditions:UNACTIVE") : t("conditions:ACTIVE")}
            </Text>
          </TouchableOpacity>
        </View>
      );
    } else if (["satel_alarm", "alarm", "satel"].includes(deviceType)) {
      return (
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <PrimaryColorText>{t("conditions:RUN_IF")}</PrimaryColorText>
          <TouchableOpacity
            onPress={() => onValuePickerChange(findPosition(value) <= 3 ? findPosition(value) + 1 : 0, 2)}
            style={[
              styles.switchButton,
              {
                backgroundColor: value == 0 ? "rgb(250, 85, 85)" : "rgb(50, 200, 80)",
              },
            ]}
          >
            <Text
              style={{
                color: "white",
                fontWeight: "600",
                textTransform: "uppercase",
              }}
            >
              {operator === 13 ? t(`satel:${evaluateState(0)}`) : t(`satel:${evaluateState(findPosition(value))}`)}
            </Text>
          </TouchableOpacity>
        </View>
      );
    } else if (["energy"].includes(deviceType)) {
      return (
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <PrimaryColorText>{t("conditions:RUN_IF")}</PrimaryColorText>
          <TouchableOpacity
            onPress={() => onValuePickerChange(findPosition(value) <= 4 ? findPosition(value) + 1 : 0, 3)}
            style={[
              styles.switchButton,
              {
                backgroundColor: value == 0 ? "rgb(250, 85, 85)" : "rgb(50, 200, 80)",
              },
            ]}
          >
            <Text
              style={{
                color: "white",
                fontWeight: "600",
                textTransform: "uppercase",
              }}
            >
              {operator === 13 ? t(`energy:${evaluateStateEnergy(0)}`) : t(`energy:${evaluateStateEnergy(findPosition(value))}`)}
            </Text>
          </TouchableOpacity>
        </View>
      );
    } else if (["roleta", "roleta_procenty", "roleta_lamelki", "led"].includes(deviceType)) {
      return (
        <View>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-evenly",
              alignItems: "center",
              marginTop: 5,
            }}
          >
            <PrimaryColorText>{t("conditions:RUN_IF")}</PrimaryColorText>

            <PickerSelect
              placeholder={{}}
              items={operators}
              onValueChange={(value) => onOperatorPickerChange(value)}
              value={operator}
              containerStyle={{ flex: 1, marginRight: 10 }}
            />

            <PickerSelect
              placeholder={{}}
              items={percent}
              onValueChange={(value) => onValuePickerChange(["led"].includes(deviceType) ? changePercentToHex(value) : value)}
              value={["led"].includes(deviceType) ? changeHexToPercent(value) : value}
              containerStyle={{ flex: 1 }}
            />
          </View>
        </View>
      );
    } else if (
      [
        "lin_wej",
        "bit8",
        "bit16",
        "sbit16",
        "bit32",
        "sensor",
        "moc_ciagla",
        "moc_przyr",
        "temp",
        "tekst_can",
        "flaga_liniowa",
        "flaga_liniowa16",
        "kontrola_dostepu",
        "sensor",
      ].includes(deviceType)
    ) {
      let url = deviceType === "temp" ? "°C;" : params_device && params_device.get("url") ? params_device.get("url") : "";
      if ((deviceType == "flaga_liniowa" || deviceType == "flaga_liniowa16") && params_device && !params_device.get("url")) {
        url = "";
      }
      if (deviceType == "kontrola_dostepu" && deviceState && deviceState.get("state")) {
        url = "";
        if ((!devState || devState.state != deviceState.get("state")) && !initValue) {
          setDevState(deviceState.toJS());
          setValue(deviceState.get("state").toString());
        }
      }
      return (
        <View>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              paddingTop: 5,
            }}
          >
            {deviceType !== "kontrola_dostepu" && (
              <PickerSelect
                placeholder={{}}
                items={operators}
                onValueChange={(value) => onOperatorPickerChange(value)}
                value={operator}
                containerStyle={{ flex: 1, marginRight: 10 }}
              />
            )}
            <TextInputStyled
              placeholder={t("ENTER_VALUE")}
              value={value.toString()}
              onChangeText={(e) => {
                onValuePickerChange(e);
              }}
              style={{ flex: 1, marginRight: 10 }}
              keyboardType={"numeric"}
            />
            {deviceType !== "kontrola_dostepu" && <PrimaryColorText>{url}</PrimaryColorText>}
            {deviceType === "kontrola_dostepu" && <ButtonPrimary onPress={listenToNfcEventOnce}>SCAN</ButtonPrimary>}
          </View>
        </View>
      );
    }
  };
  return (
    <ComponentContainer containerStyle={{ width: "100%" }}>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <PrimaryColorText style={{ fontWeight: "bold" }}>{t("DEVICE")}: </PrimaryColorText>
        {device && device.get("typ_komponentu") === "roleta_lamelki" ? (
          <PickerSelect
            placeholder={{}}
            items={rollerTypes}
            onValueChange={(value) => onRollerTypeChange(value)}
            value={condition.type}
            containerStyle={{ flex: 0, marginRight: 10, width: 150 }}
          />
        ) : null}
        <PrimaryColorText>{device ? device.get("opis_menu") : null}</PrimaryColorText>
      </View>

      {typeView()}
    </ComponentContainer>
  );
};
const EventComponent = ({ condition }) => {
  const event = useSelector((state) => state.smartHomeData.get(state.profilesSettings.get("currentProfile")).get("events").get(condition.value));

  return (
    <ComponentContainer containerStyle={{ width: "100%" }}>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <PrimaryColorText style={{ fontWeight: "bold" }}>{t("statistics:EVENT")}: </PrimaryColorText>
        <PrimaryColorText>{event ? event.get("opis") : null}</PrimaryColorText>
      </View>
    </ComponentContainer>
  );
};

const LocationComponent = ({ condition }) => {
  return (
    <ComponentContainer containerStyle={{ width: "100%" }}>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <PrimaryColorText style={{ fontWeight: "bold" }}>
          {condition.type == conditionTypes.LOCATION_ENTER ? t("conditions:LOCATION_ENTER") : t("conditions:LOCATION_LEAVE")}
        </PrimaryColorText>
        <PrimaryColorText>{`${condition.operator}m - ${(condition.value / 1000000).toFixed(3)},${(condition.value2 / 1000000).toFixed(
          3
        )}`}</PrimaryColorText>
      </View>
    </ComponentContainer>
  );
};

const DateComponent = ({ condition, modifyCondition, index }) => {
  let [condValue, setCondValue] = useState(condition.value);

  const onTimeScopeChange = (value) => {
    const date = moment(value).utc();
    const dayStart = Math.floor(date.format("X") / 86400) * 86400;

    // Get the current hours and minutes
    const hours = date.hours();
    const minutes = date.minutes();

    // Convert hours and minutes to the required format (hours * 100 + minutes)
    const condValue = dayStart + hours * 100 + minutes;

    setCondValue(condValue);
  };

  useEffect(() => {
    modifyCondition({ ...condition, value: condValue }, index);
  }, [condValue]);

  const fromCustomFormatToDate = (customValue) => {
    const dayStart = Math.floor(customValue / 86400) * 86400;
    const hours = Math.floor((customValue % 86400) / 100);
    const minutes = customValue % 100;

    // Create a new moment object from the epochStart and set the time
    const resultDate = moment.unix(dayStart).utc().hours(hours).minutes(minutes);
    return resultDate.toDate();
  };

  const selectedDate = fromCustomFormatToDate(condValue);

  return (
    <ComponentContainer containerStyle={{ width: "100%" }} style={{ flexDirection: "row", alignItems: "center" }}>
      <PrimaryColorText style={{ paddingRight: 10, fontWeight: "bold" }}>{t("SPECIFIC_DATE")}:</PrimaryColorText>
      <DateTimePicker
        value={moment(selectedDate).format("DD-MM-YYYY, HH:mm")}
        date={selectedDate}
        onConfirm={(time) => onTimeScopeChange(time)}
        mode="datetime"
        minimumDate={new Date()}
      />
    </ComponentContainer>
  );
};

class DaysComponent extends Component {
  state = {
    daysArray: [
      {
        value: 1,
        label: t("weekdays:MON"),
        active: false,
      },
      {
        value: 2,
        label: t("weekdays:TUE"),
        active: false,
      },
      {
        value: 3,
        label: t("weekdays:WED"),
        active: false,
      },
      {
        value: 4,
        label: t("weekdays:THU"),
        active: false,
      },
      {
        value: 5,
        label: t("weekdays:FRI"),
        active: false,
      },
      {
        value: 6,
        label: t("weekdays:SAT"),
        active: false,
      },
      {
        value: 0,
        label: t("weekdays:SUN"),
        active: false,
      },
    ],
  };

  componentDidMount() {
    const { condition, modifyCondition, index } = this.props;
    let newDaysArray = this.state.daysArray.map((day, index) => {
      day.active = condition.value & (1 << day.value) ? true : false;
      return day;
    });

    this.setState({ daysArray: newDaysArray });
  }

  onScheduleChange = (sendValue) => {
    const { condition, modifyCondition, index } = this.props;

    const { daysArray } = this.state;
    let newDaysArray = [...daysArray];

    let indexToChange = daysArray.findIndex((el) => el.value == sendValue);

    newDaysArray[indexToChange].active = !newDaysArray[indexToChange].active;

    let dayMask = 0;

    newDaysArray.forEach((el) => {
      if (el.active) {
        dayMask |= 1 << el.value;
      }
    });

    this.setState({ daysArray: newDaysArray }, () => {
      let conditionToSave = { ...condition, value: dayMask };
      modifyCondition(conditionToSave, index);
    });
  };

  render() {
    let days = this.state.daysArray.map((day, index) => {
      return (
        <TouchableOpacity
          onPress={() => this.onScheduleChange(day.value, "day")}
          key={`${index}${day}`}
          style={{
            flex: 1,
            alignItems: "center",
          }}
        >
          <PrimaryColorText
            style={{
              paddingBottom: 2,
            }}
          >
            {day.label}
          </PrimaryColorText>
          {day.active ? <StyledIcon name={"check"} color={"primary"} size={20} /> : <StyledIcon name={"x"} color={"secondary"} size={20} />}
        </TouchableOpacity>
      );
    });
    return (
      <ComponentContainer containerStyle={{ width: "100%" }} style={{ flexDirection: "row", alignItems: "center" }}>
        <PrimaryColorText style={{ fontWeight: "bold" }}>{t("DAYS")}: </PrimaryColorText>
        <View style={styles.days}>{days}</View>
      </ComponentContainer>
    );
  }
}
class TimeComponent extends Component {
  constructor(props) {
    super(props);

    this.timeOptions = [
      {
        label: t("AT_TIME"),
        value: 2,
      },
      {
        label: t("BETWEEN"),
        value: 11,
      },
      {
        label: t("BEFORE_SUNRISE"),
        value: 3,
      },
      {
        label: t("AFTER_SUNRISE"),
        value: 4,
      },
      {
        label: t("BEFORE_SUNSET"),
        value: 5,
      },
      {
        label: t("AFTER_SUNSET"),
        value: 6,
      },
      {
        label: t("DAYTIME"),
        value: 9,
      },
      {
        label: t("NIGHTTIME"),
        value: 10,
      },
    ];

    this.state = {
      time: moment(`09:00`, "HH:mm").toDate(),
      time2: moment(`09:00`, "HH:mm").toDate(),
      timeOption: this.props.condition.type,
      minutesSun: this.props.condition.type == 2 ? "0" : this.props.condition.value.toString(),
    };
  }

  componentDidMount() {
    const { condition } = this.props;
    let initialHours = Math.floor(condition.value / 100).toString();
    if (initialHours.length <= 1) {
      initialHours = "0" + initialHours;
    }
    let initialMinutes = (condition.value % 100).toString();
    if (initialMinutes.length <= 1) {
      initialMinutes = "0" + initialMinutes;
    }

    let initialHours2 = Math.floor(condition.value2 / 100).toString();
    if (initialHours2.length <= 1) {
      initialHours2 = "0" + initialHours2;
    }
    let initialMinutes2 = (condition.value2 % 100).toString();
    if (initialMinutes2.length <= 1) {
      initialMinutes2 = "0" + initialMinutes2;
    }

    this.setState({
      time: moment(`${initialHours}:${initialMinutes}`, "HH:mm").toDate(),
      time2: moment(`${initialHours2}:${initialMinutes2}`, "HH:mm").toDate(),
    });
  }

  onTimeChange = (time, num) => {
    const { condition, modifyCondition, index } = this.props;
    let formatedTime = Number(moment(time).format("HHmm"));
    if (num === 1) {
      this.setState({ time }, () => {
        let conditionToSave = { ...condition, value: Number(formatedTime) };
        modifyCondition(conditionToSave, index);
      });
    } else if (num === 2) {
      this.setState({ time2: time }, () => {
        let conditionToSave = { ...condition, value2: Number(formatedTime) };
        modifyCondition(conditionToSave, index);
      });
    }
  };

  onMinutesChange = (value) => {
    const { condition, modifyCondition, index } = this.props;
    this.setState({ minutesSun: value });
    let conditionToSave = { ...condition, value: Number(value) };

    modifyCondition(conditionToSave, index);
  };

  onMinutesEnd = () => {};
  onTimeOptionChange = (timeOption) => {
    this.setState({ timeOption }, () => {
      if (Platform.OS === "android" || Platform.OS === "web") {
        this.onFinishPicker();
      }
    });
  };

  onTimeOptionClose = () => {
    this.onFinishPicker();
  };

  onFinishPicker = () => {
    const { condition, modifyCondition, index } = this.props;
    const { timeOption } = this.state;
    let conditionToSave = { ...condition, type: timeOption };

    if (timeOption != 2 && timeOption != 11) {
      conditionToSave.value = 0;
    } else {
      let formatedTime = Number(moment().format("HHmm"));
      conditionToSave.value = 900;
    }
    modifyCondition(conditionToSave, index);
  };

  render() {
    const { time, time2, timeOption, minutesSun } = this.state;

    return (
      <ComponentContainer
        containerStyle={{ width: "100%" }}
        style={{
          flexDirection: "row",
        }}
      >
        <PrimaryColorText style={{ paddingRight: 10, fontWeight: "bold" }}>{t("TIME")}:</PrimaryColorText>

        <View
          style={{
            flex: 1,
          }}
        >
          <PickerSelect
            placeholder={{
              label: t("CHOOSE_OPTION"),
              value: null,
            }}
            containerStyle={{ flex: 1, paddingBottom: 5 }}
            items={this.timeOptions}
            onValueChange={this.onTimeOptionChange}
            onClose={this.onTimeOptionClose}
            value={timeOption}
          />
          {timeOption == 2 && (
            <DateTimePicker value={moment(time).format("HH:mm")} onConfirm={(time) => this.onTimeChange(time, 1)} date={time} mode="time" />
          )}
          {timeOption == 11 && (
            <View
              style={{
                flexDirection: "row",
              }}
            >
              <DateTimePicker value={moment(time).format("HH:mm")} onConfirm={(time) => this.onTimeChange(time, 1)} date={time} mode="time" />
              <Text
                style={{
                  alignSelf: "center",
                }}
              >
                {"   "}-{"   "}
              </Text>
              <DateTimePicker value={moment(time2).format("HH:mm")} onConfirm={(time2) => this.onTimeChange(time2, 2)} date={time2} mode="time" />
            </View>
          )}
          {(timeOption == 3 || timeOption == 4 || timeOption == 5 || timeOption == 6) && (
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <PrimaryColorText
                style={{
                  paddingRight: 3,
                }}
              >
                {t("MINUTES")}:
              </PrimaryColorText>
              <TextInputStyled
                style={{
                  height: 26,
                  flex: 1,
                }}
                placeholder={t("MINUTES")}
                value={minutesSun}
                keyboardType={"numeric"}
                onChangeText={(value) => this.onMinutesChange(value)}
                onEndEditing={(value) => this.onMinutesEnd(value)}
              />
            </View>
          )}
        </View>
      </ComponentContainer>
    );
  }
}
class Conditions_AutomatizationScreen extends Component {
  state = {
    editMode: false,
    animationOpacity: new Animated.Value(0),
    animationMargin: new Animated.Value(-42),
  };

  _moveRight = () => {
    Animated.timing(this.state.animationOpacity, {
      toValue: 1,
      duration: 500,
      useNativeDriver: false,
    }).start();

    Animated.timing(this.state.animationMargin, {
      toValue: 0,
      duration: 500,
      useNativeDriver: false,
    }).start();
  };

  _moveBack = () => {
    Animated.timing(this.state.animationOpacity, {
      toValue: 0,
      duration: 500,
      useNativeDriver: false,
    }).start();

    Animated.timing(this.state.animationMargin, {
      toValue: -42,
      duration: 500,
      useNativeDriver: false,
    }).start();
  };
  toggleOnPressMode = () => {
    let newEditMode = !this.state.editMode;
    this.setState({ editMode: newEditMode }, () => {
      if (this.state.editMode) {
        this._moveRight();
      } else {
        this._moveBack();
      }
    });
  };

  removeCondition = (index) => {
    this.props.onRemoveCondition(index);
  };

  onOperatorChange = (oper) => {
    this.props.onOperatorChange(oper);
  };

  render() {
    const { conditions, modifyCondition, operator } = this.props;
    const { editMode } = this.state;

    return (
      <ScreenView>
        {conditions && conditions.length > 1 ? (
          <View
            style={{
              justifyContent: "center",
              width: "100%",
            }}
          >
            <TouchableOpacity
              onPress={() => this.onOperatorChange(operator == 0 ? 1 : 0)}
              style={[
                styles.switchButton,
                {
                  backgroundColor: operator == 0 ? "rgb(250, 85, 85)" : "rgb(50, 200, 80)",
                },
              ]}
            >
              <Text
                style={{
                  color: "white",
                  fontWeight: "600",
                  textTransform: "uppercase",
                }}
              >
                {operator == 0 ? t("conditions:AND") : null}
                {operator == 1 ? t("conditions:OR") : null}
              </Text>
            </TouchableOpacity>
          </View>
        ) : null}
        {conditions &&
          conditions.map((condition, index) => {
            let ConditionComponent;
            switch (condition.type) {
              case conditionTypes.DAYS:
                ConditionComponent = DaysComponent;
                break;

              case conditionTypes.DATE: {
                ConditionComponent = DateComponent;
                break;
              }
              case 2:
              case 3:
              case 4:
              case 5:
              case 9:
              case 10:
              case 11:
              case 6:
                ConditionComponent = TimeComponent;
                break;
              case conditionTypes.OBJECT_PARAM2:
              case conditionTypes.OBJECT:
                ConditionComponent = ObjectComponent;
                break;
              case conditionTypes.EVENT:
                ConditionComponent = EventComponent;
                break;
              case conditionTypes.LOCATION_ENTER:
                ConditionComponent = LocationComponent;
                break;
              case conditionTypes.LOCATION_LEAVE:
                ConditionComponent = LocationComponent;
                break;
              default:
                ConditionComponent = () => null;
                break;
            }
            return (
              <View key={index} style={{ width: "100%" }}>
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Animated.View style={[{ opacity: this.state.animationOpacity }]}>
                    <TouchableOpacity onPress={() => this.removeCondition(index)}>
                      <View style={[styles.removeButton]}>
                        <Text
                          style={{
                            color: "white",
                            marginLeft: 1,
                            lineHeight: 13,
                            fontWeight: "bold",
                            fontSize: 10,
                          }}
                        >
                          —
                        </Text>
                      </View>
                    </TouchableOpacity>
                  </Animated.View>
                  <Animated.View
                    style={{
                      marginLeft: this.state.animationMargin,
                      width: "100%",
                    }}
                  >
                    <ConditionComponent condition={condition} modifyCondition={modifyCondition} index={index} />
                  </Animated.View>
                </View>
                {conditions.length - 1 != index ? (
                  <PrimaryColorText style={{ textAlign: "center" }}>
                    {operator == 0 ? t("AND") : null}
                    {operator == 1 ? t("OR") : null}
                  </PrimaryColorText>
                ) : null}
              </View>
            );
          })}
        <View
          style={{
            justifyContent: "center",
            width: "100%",
          }}
        >
          <ButtonPrimary
            style={{
              marginVertical: 10,
              paddingVertical: 5,
              // paddingHorizontal: 20,
              width: 100,
              backgroundColor: editMode ? "rgb(40,125,40)" : "rgb(200,00,0)",
            }}
            onPress={this.toggleOnPressMode}
          >
            {editMode ? t("DONE") : t("DELETING")}
          </ButtonPrimary>
        </View>
      </ScreenView>
    );
  }
}

const styles = StyleSheet.create({
  switchButton: {
    margin: 5,
    paddingHorizontal: 10,
    // width: 80,
    height: 30,
    borderRadius: 8,
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
  },
  removeButton: {
    width: 18,
    height: 18,
    backgroundColor: "rgb(200,00,0)",
    color: "white",
    borderRadius: 9,
    // display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginHorizontal: 12,
  },
  days: {
    flexDirection: "row",
    // alignItems: "center",
    justifyContent: "space-between",

    flex: 1,
  },
});

export default Conditions_AutomatizationScreen;
