import * as Actions from "../actionTypes";
import * as Localization from "expo-localization";

const load = () => {
  return { type: Actions.FETCH_WEATHER_PENDING };
};

export const successInWeatherApi = (res) => {
  return { type: Actions.FETCH_WEATHER_SUCCESS, payload: res };
};
export const receivedWeatherAmpio = (res) => {
  return { type: Actions.WEATHER_AMPIO_RECEIVED, payload: res };
};

export const errorInWeather = (err) => {
  return { type: Actions.FETCH_WEATHER_FAILURE, payload: err };
};

export const fetchWeatherApi = (city) => {
  const APIkey = "207e88b79eceb23936d71c5257a3a69f";
  let lang = Localization.locale.split("-")[0];
  let url = `https://api.openweathermap.org/data/2.5/weather?APPID=${APIkey}&lang=${lang}&units=metric&q=${city}`;
  return (dispatch) => {
    dispatch(load());

    return fetch(url)
      .then((result) => {
        if (!result.ok) {
          throw result;
        }

        return result.json();
      })
      .then((dane) => {
        dispatch(successInWeatherApi(dane));
        //dispatch(errorInWeather("Error in weather"));
        //after success set the city for the rpofile
        dispatch({
          type: Actions.SET_WEATHER_CITY_FOR_SETTINGS,
          payload: city,
        });
      })
      .catch((err) => {
        dispatch(errorInWeather("Error in weather"));
      });
  };
};
