import React, { Component, useEffect, useState } from "react";
import { fromJS } from "immutable";

import { View, Text, StyleSheet, TouchableOpacity, Animated, ActivityIndicator, Platform } from "react-native";
import { ScreenView } from "../../components/UI/screenViewX";
import { PrimaryColorText, ComponentContainer, StyledIcon, ButtonPrimary, SliderStyled, TextInputStyled } from "../../components/UI/styledComponents";
import DateTimePicker from "../../components/UI/dateTimePicker";
import moment from "moment";
import DeviceListHandler from "../../utils/smartHomeHandlers/DeviceListHandler";

import { useSelector } from "react-redux";
import DefaultIcon from "../Dashboard/weather/defaultIcon";
import { FontAwesome } from "@expo/vector-icons";
import PickerSelect from "../../components/UI/PickerSelect";

import { t } from "../../services/i18n";
import mqttClient from "../../services/mqtt";
import MultiplePickerSelectObjects from "../Scenes/MultiplePickerSelectObjects";
import { store } from "../../store/configureStore";
export const actionTypes = { SCENE: 1, OBJECT: 2, PUSH: 3, EVENT: 4, MESSAGE: 5, AUDIO: 6 };

const ObjectComponent = ({ action, index, modifyAction }) => {
  const device = useSelector((state) => state.smartHomeData.get(state.profilesSettings.get("currentProfile")).get("devices").get(action.object_id));

  const changeSceneFromDevice = (receivedInfo) => {
    modifyAction(receivedInfo.toJS(), index);
  };
  return device ? (
    <DeviceListHandler
      device={device}
      activeSceneControl={false}
      changeSceneFromDevice={changeSceneFromDevice}
      deviceInScene={fromJS(action)}
      deviceFromAutomatization={true}
    />
  ) : null;
};

const EventComponent = ({ action, index, modifyAction }) => {
  const event = useSelector((state) => state.smartHomeData.get(state.profilesSettings.get("currentProfile")).get("events").get(action.object_id));

  return (
    <ComponentContainer containerStyle={{ width: "100%" }}>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <View
          style={{
            flexDirection: "row",

            alignItems: "center",
          }}
        >
          <PrimaryColorText style={{ fontWeight: "bold" }}>{t("statistics:EVENT")}: </PrimaryColorText>
          <PrimaryColorText>{event ? event.get("opis") : null}</PrimaryColorText>
        </View>
        <TouchableOpacity style={{ paddingHorizontal: 6 }} onPress={() => mqttClient.sendPush(`/api/setEvent/${event.get("id")}`)}>
          <FontAwesome name="play-circle" color={"#999"} size={24} />
        </TouchableOpacity>
      </View>
    </ComponentContainer>
  );
};

const SceneComponent = ({ action, index, modifyAction, removeAction }) => {
  const sceneOptions = [
    {
      label: t("scenes:PLAY"),
      value: 0,
    },
    {
      label: t("scenes:UNDO"),
      value: 1,
    },
    {
      label: t("scenes:TURN_OFF"),
      value: 2,
    },
  ];

  let [sceneOption, setSceneOption] = useState(action.param1);

  const scene =
    action.object_id >= 0
      ? useSelector((state) =>
          state.smartHomeData
            .get(state.profilesSettings.get("currentProfile"))
            .get("scenes")
            .find((val) => val.get("id") === action.object_id)
        )
      : undefined;

  useEffect(() => {
    if (scene === undefined) {
      removeAction(index);
    }
  }, []);

  let actionName = (value) => {
    let actionToDo = "";

    switch (value) {
      case 0:
        actionToDo = "run";
        break;
      case 1:
        actionToDo = "undo";
        break;
      case 2:
        actionToDo = "off";
        break;

      default:
        break;
    }
    return actionToDo;
  };
  const onOperatorPickerChange = (value) => {
    setSceneOption(value);
    let actionToSend = `/api/${actionName(value)}/scene/${action.object_id}`;
    modifyAction({ ...action, param1: value, action: actionToSend }, index);
  };

  return scene ? (
    <ComponentContainer containerStyle={{ width: "100%" }}>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <PrimaryColorText style={{ fontWeight: "bold" }}>{t("SCENE")}:</PrimaryColorText>
          <PrimaryColorText> {scene.get("sceneName")}</PrimaryColorText>
        </View>

        <PickerSelect
          placeholder={{}}
          items={sceneOptions}
          onValueChange={(value) => onOperatorPickerChange(value)}
          value={sceneOption}
          containerStyle={{ flex: 1, marginRight: 10 }}
        />
        <TouchableOpacity style={{ paddingHorizontal: 6 }} onPress={() => mqttClient.controlScene(action.object_id, actionName(sceneOption))}>
          <FontAwesome name="play-circle" color={"#999"} size={24} />
        </TouchableOpacity>
      </View>
    </ComponentContainer>
  ) : null;
};

const PushComponent = ({ action, index, modifyAction }) => {
  const userName = useSelector((state) => state.profilesSettings.get(state.profilesSettings.get("currentProfile")).get("loginData").get("login"));

  const serverInfo = useSelector((state) => state.statesData.get("server").get("serverInfo"));

  const pickerItems = [
    { label: `${t("conditions:USER")}: ${userName}`, value: 0 },
    { label: t("conditions:ALL"), value: 1 },
  ];

  let [pushText, setPushText] = useState(action.description);
  const [pickerValue, setPickerValue] = React.useState((action.action.match(/\//g) || []).length == 3 ? 1 : 0);

  let generateActionToSend = (pickerValue) => {
    return pickerValue === 0 ? `/api/pushNotification/${pushText}/${userName}` : `/api/pushNotification/${pushText}`;
  };

  const pickerValueChange = (pickerValue) => {
    setPickerValue(pickerValue);

    if (Platform.OS === "android") {
      modifyAction({ ...action, action: generateActionToSend(pickerValue) }, index);
    }
  };

  const pickerValueClose = () => {
    if (Platform.OS === "ios") {
      modifyAction({ ...action, action: generateActionToSend(pickerValue) }, index);
    }
  };
  let permissionForSendToAll = (serverInfo.get("perm") & 0x2000) == 0;

  const onPushTextChange = (value) => {
    setPushText(value);
    let actionToSend = `/api/pushNotification/${value}/${userName}`;
    modifyAction({ ...action, description: value, action: actionToSend }, index);
  };

  return (
    <ComponentContainer containerStyle={{ width: "100%" }}>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <PrimaryColorText style={{ fontWeight: "bold" }}>{t("PUSH")}: </PrimaryColorText>
        <TextInputStyled placeholder={t("ENTER_PUSH_NOTIFICATION_TEXT")} value={pushText} onChangeText={onPushTextChange} style={{ flex: 1 }} />
        <TouchableOpacity style={{ paddingHorizontal: 6 }} onPress={() => mqttClient.sendPush(generateActionToSend(pickerValue))}>
          <FontAwesome name="play-circle" color={"#999"} size={24} />
        </TouchableOpacity>
      </View>
      {permissionForSendToAll ? (
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            marginTop: 5,
          }}
        >
          <PrimaryColorText>{t("conditions:SEND_TO")}</PrimaryColorText>
          <PickerSelect
            placeholder={{}}
            containerStyle={{ width: 150 }}
            items={pickerItems}
            onValueChange={pickerValueChange}
            value={pickerValue}
            onClose={pickerValueClose}
          />
        </View>
      ) : null}
    </ComponentContainer>
  );
};

const AudioComponent = ({ action, index, modifyAction }) => {
  const [selectedDevices, setSelectedDevices] = useState([]);

  const currentProfile = store.getState().profilesSettings.get("currentProfile");
  const favouritesRadios = store.getState().profilesSettings.get(currentProfile).get("favouritesRadios")
    ? store.getState().profilesSettings.get(currentProfile).get("favouritesRadios").toJS()
    : [];

  const [selectedRadio, setSelectedRadio] = useState(undefined);
  const detectedOutputs = store.getState().statesData.get("detectedOutputs");
  const [pickerValue, setPickerValue] = React.useState(0);
  const [volume, setVolume] = React.useState(50);

  const pickerItems = [
    { label: t("SET_VOLUME"), value: 0 },
    { label: t("PLAY_FAV_RADIO"), value: 1 },
    { label: t("PAUSE"), value: 2 },
    { label: t("RESUME"), value: 3 },
  ];

  let generateActionToSend = (pickerValue, vol, rad, sel) => {
    if (pickerValue === 0) {
      return `/api/setOt/${sel.map((sel) => sel.value).join(",")}/setVolume/${vol}`;
    } else if (pickerValue === 1) {
      if (!rad) rad = favouritesRadios && favouritesRadios[0] ? favouritesRadios[0] : "";

      const fav = favouritesRadios.find((val) => val.id === rad);

      if (!fav) return "";
      return `/api/setOt/${sel.map((sel) => sel.value).join(",")}/playUri/${fav.radio.uri}`;
    } else if (pickerValue === 2) {
      return `/api/setOt/${sel.map((sel) => sel.value).join(",")}/pause`;
    } else if (pickerValue === 3) {
      return `/api/setOt/${sel.map((sel) => sel.value).join(",")}/resume`;
    }
    return "";
  };

  const onDeviceValueChange = (value) => {
    modifyAction({ ...action, action: generateActionToSend(pickerValue, volume, selectedRadio, value) }, index);
    setSelectedDevices(value);
  };
  const pickerValueChange = (pickerValue) => {
    setPickerValue(pickerValue);

    if (Platform.OS === "android") {
      modifyAction({ ...action, action: generateActionToSend(pickerValue, volume, selectedRadio, selectedDevices) }, index);
    }
  };

  useEffect(() => {
    // const parse url from action
    const actionUrl = action.action.split("/");

    if (actionUrl && actionUrl.length < 5) return;
    const radios = actionUrl[3].split(",");
    const selectedRadios = detectedOutputs
      .filter((val) => radios.includes(val.id))
      .map((val) => {
        return {
          value: val.id,
          label: val.name,
          chosen: true,
        };
      });

    setSelectedDevices(selectedRadios);
    if (actionUrl[4] === "setVolume") {
      setPickerValue(0);
      setVolume(parseInt(actionUrl[5]));
    } else if (actionUrl[4] === "pause") {
      setPickerValue(2);
    } else if (actionUrl[4] === "resume") {
      setPickerValue(2);
    } else if (actionUrl[4] === "playUri") {
      setPickerValue(1);
      let completeUrl = actionUrl[5];
      for (let i = 6; i < actionUrl.length; i++) {
        completeUrl += "/" + actionUrl[i];
      }
      if (action.action.endsWith("/")) {
        completeUrl += "/";
      }
      favouritesRadios.forEach((val) => {
        if (val.radio.uri === completeUrl) {
          setSelectedRadio(val.id);
        }
      });
      //setSelectedRadio(actionUrl[5]);
    }
  }, []);

  const pickerValueClose = () => {
    if (Platform.OS === "ios") {
      modifyAction({ ...action, action: generateActionToSend(pickerValue, volume, selectedRadio, selectedDevices) }, index);
    }
  };

  return (
    <ComponentContainer containerStyle={{ width: "100%" }}>
      <MultiplePickerSelectObjects
        devicesInGroup={
          detectedOutputs
            ? detectedOutputs
                .filter((val) => !val.requires_auth)
                .map((val) => {
                  return {
                    value: val.id,
                    label: val.name,
                  };
                })
            : []
        }
        onValueChange={onDeviceValueChange}
        selectedDevices={selectedDevices}
        selectedGroup={0}
      />
      {selectedDevices && selectedDevices.length > 0 && (
        <>
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              marginTop: 5,
            }}
          >
            <PrimaryColorText>{t("statistics:ACTION")}</PrimaryColorText>
            <PickerSelect
              placeholder={{}}
              containerStyle={{ width: 150 }}
              items={pickerItems}
              onValueChange={pickerValueChange}
              value={pickerValue}
              onClose={pickerValueClose}
            />
          </View>
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              marginTop: 5,
            }}
          >
            {pickerValue === 0 && (
              <SliderStyled
                value={volume}
                minimumValue={0}
                maximumValue={100}
                onSlidingComplete={(value) => {
                  setVolume(value);
                  modifyAction({ ...action, action: generateActionToSend(pickerValue, value, selectedRadio, selectedDevices) }, index);
                }}
              />
            )}

            {pickerValue === 1 && (
              <PickerSelect
                containerStyle={{ width: "100%" }}
                items={favouritesRadios.map((val) => {
                  return {
                    value: val.id,
                    label: val.radio.text,
                    uri: val.radio.uri,
                  };
                })}
                onValueChange={(value) => {
                  setSelectedRadio(value);
                  modifyAction({ ...action, action: generateActionToSend(pickerValue, volume, value, selectedDevices) }, index);
                  //modifyAction({ ...action, action: generateActionToSend(pickerValue) }, index);
                }}
                value={selectedRadio}
              />
            )}
          </View>
        </>
      )}
    </ComponentContainer>
  );
};

const MessageComponent = ({ action, index, modifyAction }) => {
  let [messageTitle, setMessageTitle] = useState(action.description.split("/")[0] || "");

  let [messageText, setMessageText] = useState(action.description.split("/")[1] || "");

  const onTextChange = (value, key) => {
    if (key === "title") {
      setMessageTitle(value.replace(/[\/]/, ""));
    } else if (key === "text") {
      setMessageText(value.replace(/[\/]/, ""));
    }

    let actionToSend = `/api/addMessage/${messageTitle}/${messageText}`;
    modifyAction(
      {
        ...action,
        description: `${messageTitle}/${messageText}`,
        action: actionToSend,
      },
      index
    );
  };

  return (
    <ComponentContainer containerStyle={{ width: "100%" }}>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <PrimaryColorText style={{ fontWeight: "bold" }}>{t("MESSAGE")}: </PrimaryColorText>
        <View style={{ flex: 1 }}>
          <TextInputStyled placeholder={t("ENTER_MESSAGE_TITLE")} value={messageTitle} onChangeText={(value) => onTextChange(value, "title")} />
          <TextInputStyled
            style={{ marginTop: 5 }}
            placeholder={t("ENTER_MESSAGE_TEXT")}
            value={messageText}
            onChangeText={(value) => onTextChange(value, "text")}
          />
        </View>
        <TouchableOpacity
          style={{ paddingHorizontal: 6 }}
          onPress={() =>
            // ampio/control/admin/add_message/{title}/{body}
            mqttClient.sendMessage(`/api/addMessage/${messageTitle}/${messageText}`)
          }
        >
          <FontAwesome name="play-circle" color={"#999"} size={24} />
        </TouchableOpacity>
      </View>
    </ComponentContainer>
  );
};
class Actions_AutomatizationScreen extends Component {
  state = {
    editMode: false,
    animationOpacity: new Animated.Value(0),
    animationMargin: new Animated.Value(-42),
  };

  _moveRight = () => {
    Animated.timing(this.state.animationOpacity, {
      toValue: 1,
      duration: 500,
      useNativeDriver: false,
    }).start();

    Animated.timing(this.state.animationMargin, {
      toValue: 0,
      duration: 500,
      useNativeDriver: false,
    }).start();
  };

  _moveBack = () => {
    Animated.timing(this.state.animationOpacity, {
      toValue: 0,
      duration: 500,
      useNativeDriver: false,
    }).start();

    Animated.timing(this.state.animationMargin, {
      toValue: -42,
      duration: 500,
      useNativeDriver: false,
    }).start();
  };
  toggleOnPressMode = () => {
    let newEditMode = !this.state.editMode;
    this.setState({ editMode: newEditMode }, () => {
      if (this.state.editMode) {
        this._moveRight();
      } else {
        this._moveBack();
      }
    });
  };

  removeAction = (index) => {
    this.props.onRemoveAction(index);
  };

  render() {
    const { actions, modifyAction } = this.props;
    const { editMode } = this.state;

    return (
      <ScreenView>
        {actions &&
          actions.map((action, index) => {
            let ActionComponent;
            switch (action.type) {
              case actionTypes.OBJECT:
                ActionComponent = ObjectComponent;
                break;
              case actionTypes.EVENT:
                ActionComponent = EventComponent;
                break;
              case actionTypes.SCENE:
                ActionComponent = SceneComponent;
                break;
              case actionTypes.PUSH:
                ActionComponent = PushComponent;
                break;
              case actionTypes.MESSAGE:
                ActionComponent = MessageComponent;
                break;
              case actionTypes.AUDIO:
                ActionComponent = AudioComponent;
                break;
              default:
                break;
            }
            return (
              <View
                key={`${index}${action.type}`}
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Animated.View style={[{ opacity: this.state.animationOpacity }]}>
                  <TouchableOpacity onPress={() => this.removeAction(index)}>
                    <View style={[styles.removeButton]}>
                      <Text
                        style={{
                          color: "white",
                          marginLeft: 1,
                          lineHeight: 13,
                          fontWeight: "bold",
                          fontSize: 10,
                        }}
                      >
                        —
                      </Text>
                    </View>
                  </TouchableOpacity>
                </Animated.View>
                <Animated.View
                  style={{
                    marginLeft: this.state.animationMargin,
                    width: "100%",
                  }}
                >
                  <ActionComponent action={action} index={index} modifyAction={modifyAction} removeAction={this.removeAction} />
                </Animated.View>
              </View>
            );
          })}
        <View
          style={{
            justifyContent: "center",
            width: "100%",
          }}
        >
          <ButtonPrimary
            style={{
              marginVertical: 10,
              paddingVertical: 5,
              // paddingHorizontal: 20,
              width: 100,
              backgroundColor: editMode ? "rgb(40,125,40)" : "rgb(200,00,0)",
            }}
            onPress={this.toggleOnPressMode}
          >
            {editMode ? t("DONE") : t("DELETING")}
          </ButtonPrimary>
        </View>
      </ScreenView>
    );
  }
}

const styles = StyleSheet.create({
  removeButton: {
    width: 18,
    height: 18,
    backgroundColor: "rgb(200,00,0)",
    color: "white",
    borderRadius: 9,
    // display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginHorizontal: 12,
  },
  days: {
    flexDirection: "row",
    // alignItems: "center",
    justifyContent: "space-between",
    // flex: 1,
  },
});
export default Actions_AutomatizationScreen;
