import React, { Component } from "react";
import { View, TouchableOpacity, Keyboard, ActivityIndicator, Vibration, Platform } from "react-native";
import { FontAwesome } from "@expo/vector-icons";
import mqttClient from "../../../services/mqtt";
import EndSwitchIcons from "../../../utils/EndSwitchIcons";

import { StyledIcon, SwitchStyled, ComponentHeaderText, ComponentContainer } from "../../UI/styledComponents";
import * as Haptics from "expo-haptics";
import ModalSimple from "../../UI/ModalSimple";
import ImageFromIkona0_0 from "../../../utils/ImageFromIkona0_0";
import ImageForLinearInput from "../../../utils/ImageForLinearInput";
import { Switch } from "react-native-gesture-handler";
import * as LocalAuthentication from "expo-local-authentication";
import { SkottieLoader } from "../../animatedComponents/Loader";
class RelayComponent extends Component {
  state = {
    value: false,
    blockProps: false,
    pinDialogVisible: false,
    wrongPinDialogVisible: false,
    overrideValue: null,
    pin: "",
    showSwitch: false,
  };

  componentDidMount() {
    const { device } = this.props;
    if (device.has("ikona_0")) {
      this.setState({
        showSwitch: device.get("ikona_0") == null || device.get("ikona_1") == null,
      });
    } else if (device.has("ikona1")) {
      this.setState({
        showSwitch: device.get("ikona0") == null || device.get("ikona1") == null,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.deviceInScene && prevProps.params_device && prevProps.params_device.get("czas")) {
      if (prevProps.deviceState.get("state") == 255 && this.props.deviceState.get("state") == 0) {
        this.setState({ value: false });
        return true;
      }
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!nextProps.deviceInScene && !prevState.blockProps) {
      return {
        value: nextProps.deviceState.get("state") == "0" ? false : true,
      };
    } else if (nextProps.deviceInScene && !prevState.deviceFromScene) {
      return {
        deviceFromScene: true,
        value: nextProps.deviceInScene.get("value") == "0" ? false : true,
      };
    }
    return null;
  }

  onPinInputHandler = (value) => {
    this.setState({
      pin: value,
    });
  };

  handleClose = () => {
    Keyboard.dismiss();
    this.setState({ pinDialogVisible: false });
  };

  handleOK = () => {
    const { device } = this.props;
    Keyboard.dismiss();
    this.setState({ pinDialogVisible: false, pin: "" });
    if (this.state.pin == device.get("haslo")) {
      if (this.state.overrideValue === null) {
        this.handleSend();
      } else {
        this.sendMessage(this.state.overrideValue);
      }
    } else {
      setTimeout(() => {
        this.setState({
          wrongPinDialogVisible: true,
        });
      }, 500);
    }
  };

  handleBiometrics = async () => {
    Keyboard.dismiss();
    this.setState({ pinDialogVisible: false });
    const result = await LocalAuthentication.authenticateAsync({ requireConfirmation: false });
    if (result && result.success) {
      if (this.state.overrideValue === null) {
        this.handleSend();
      } else {
        this.sendMessage(this.state.overrideValue);
      }
    }
  };

  handleOKwrongPin = () => {
    this.setState({ wrongPinDialogVisible: false });
  };

  toggleValue = (value) => {
    const { device } = this.props;

    if (device.get("bezpieczne")) {
      this.setState({ pinDialogVisible: true, overrideValue: null });
    } else {
      this.handleSend();
    }
  };

  componentWillUnmount() {
    clearTimeout(this.timeOut);
  }

  runTimeout = () => {
    let updateState = () => {
      const { deviceState } = this.props;
      this.setState({
        blockProps: false,
        value: Number(deviceState.get("state")),
      });
    };
    this.timeOut = setTimeout(() => {
      this.timeOut = null;
      updateState();
    }, 5000);
  };

  clearTimeoutMine = () => {
    clearTimeout(this.timeOut);
    this.runTimeout();
  };

  sendMessage = (value) => {
    const { device, deviceInScene, changeScene, params_device, activeSceneControl } = this.props;

    let message = "";
    if (params_device && params_device.get("czas")) {
      if (device.get("interpretacja") != device.get("funkcja")) {
        message = `/api/set/${device.get("id")}/setValue/255/${params_device.get("czas")}`;
      } else {
        message = value ? `/api/set/${device.get("id")}/setValue/255/${params_device.get("czas")}` : `/api/set/${device.get("id")}/setValue/0`;
      }
    } else {
      message = value ? `/api/set/${device.get("id")}/setValue/255` : `/api/set/${device.get("id")}/setValue/0`;
    }

    activeSceneControl != false && mqttClient.stateChangeToSend(message, device.get("id"));
  };

  handleSend = () => {
    const { device, deviceInScene, changeScene, params_device } = this.props;
    Platform.OS !== "web" && Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Light);
    this.setState(
      (prevState) => ({
        blockProps: true,
        value: device.get("interpretacja") !== device.get("funkcja") ? this.state.value : !prevState.value,
      }),
      () => {
        !deviceInScene && this.clearTimeoutMine();
        if (device.get("interpretacja") !== device.get("funkcja")) {
          let value = this.state.value ? 0 : 255;
          this.sendMessage(value);
        } else {
          this.sendMessage(this.state.value);
        }

        if (deviceInScene) {
          let value = this.state.value ? 255 : 0;
          let infoToUpdate = deviceInScene.set("value", value);

          changeScene(infoToUpdate, deviceInScene.get("temporary_id"));
        }
      }
    );
  };

  bellOnPreess = (value) => {
    const { device } = this.props;
    if (device.get("bezpieczne")) {
      this.setState({ pinDialogVisible: true, overrideValue: value });
    } else {
      this.sendMessage(value);
    }
  };

  gateContent = () => {
    const { deviceState, deviceFromCustom } = this.props;
    if (deviceState.get("res")) {
      return <ImageForLinearInput id={deviceState.get("res")} deviceFromCustom={deviceFromCustom} />;
    } else if (deviceState.get("desc")) {
      return <ComponentHeaderText style={{ paddingHorizontal: 10 }}>{deviceState.get("desc")}</ComponentHeaderText>;
    } else if (deviceState.get("conn")) {
      return <EndSwitchIcons value={deviceState.get("conn")} />;
    } else {
      return null;
    }
  };

  getBellComponent = () => {
    const { device, readOnly, deviceFromCustom, deviceFromSketch, stretchSketch, group_params } = this.props;
    const { value } = this.state;
    return (
      <TouchableOpacity
        onPressIn={() => this.bellOnPreess(255)}
        onPressOut={() => this.bellOnPreess(0)}
        disabled={readOnly}
        hitSlop={{ top: 10, bottom: 10, left: 10, right: 10 }} // Increase touch area by 10 pixels on each side
        style={{
          width: deviceFromSketch ? "100%" : null,
          height: deviceFromSketch ? "100%" : null,
          // paddingHorizontal: 5,
        }}
      >
        {((device.has("ikona0") && device.get("ikona0")) || (device.has("ikona_0") && device.get("ikona_0"))) &&
        (deviceFromSketch || (!deviceFromSketch && (group_params & 0x04) == 0)) ? (
          <ImageFromIkona0_0
            deviceFromSketch={deviceFromSketch}
            deviceFromCustom={deviceFromCustom}
            device={device}
            value={value}
            resizeMode={stretchSketch ? "stretch" : "contain"}
            style={{
              width: deviceFromSketch ? "100%" : 36,
              height: deviceFromSketch ? "100%" : 36,
            }}
          />
        ) : (
          <FontAwesome name="play-circle" color={"rgb(50, 200, 80)"} size={30} />
        )}
      </TouchableOpacity>
    );
  };

  getTimeComponent = () => {
    const { device, readOnly, deviceFromCustom, deviceFromSketch, stretchSketch, group_params, params_device } = this.props;
    const { value } = this.state;
    const showIndicator = params_device && (params_device.get("params") & 0x800000) === 0;

    return (
      <View style={{ flexDirection: "row" }}>
        {value && device.get("interpretacja") == device.get("funkcja") && showIndicator ? (
          <SkottieLoader style={{ width: 23, height: 23, marginRight: 3 }} />
        ) : null}

        {value == "0" ? (
          <TouchableOpacity
            onPress={this.toggleValue}
            disabled={readOnly}
            hitSlop={{ top: 10, bottom: 10, left: 10, right: 10 }} // Increase touch area by 10 pixels on each side
            style={{
              width: deviceFromSketch ? "100%" : null,
              height: deviceFromSketch ? "100%" : null,
              // paddingHorizontal: 5,
            }}
          >
            {((device.has("ikona0") && device.get("ikona0")) || (device.has("ikona_0") && device.get("ikona_0"))) &&
            (deviceFromSketch || (!deviceFromSketch && (group_params & 0x04) == 0)) ? (
              <ImageFromIkona0_0
                deviceFromSketch={deviceFromSketch}
                deviceFromCustom={deviceFromCustom}
                device={device}
                value={value}
                resizeMode={stretchSketch ? "stretch" : "contain"}
                style={{
                  width: deviceFromSketch ? "100%" : 36,
                  height: deviceFromSketch ? "100%" : 36,
                }}
              />
            ) : (
              <FontAwesome name="play-circle" color={"rgb(50, 200, 80)"} size={30} />
            )}
          </TouchableOpacity>
        ) : (
          <TouchableOpacity
            onPress={this.toggleValue}
            disabled={readOnly}
            hitSlop={{ top: 10, bottom: 10, left: 10, right: 10 }} // Increase touch area by 10 pixels on each side
            style={{
              width: deviceFromSketch ? "100%" : null,
              height: deviceFromSketch ? "100%" : null,
            }}
          >
            {((device.has("ikona1") && device.get("ikona1")) || (device.has("ikona_1") && device.get("ikona_1"))) &&
            (deviceFromSketch || (!deviceFromSketch && (group_params & 0x04) == 0)) ? (
              <ImageFromIkona0_0
                deviceFromSketch={deviceFromSketch}
                deviceFromCustom={deviceFromCustom}
                device={device}
                value={value}
                resizeMode={stretchSketch ? "stretch" : "contain"}
                style={{
                  width: deviceFromSketch ? "100%" : 36,
                  height: deviceFromSketch ? "100%" : 36,
                }}
              />
            ) : (
              <FontAwesome name="stop-circle" color={"rgb(250, 85, 85)"} size={30} />
            )}
          </TouchableOpacity>
        )}
      </View>
    );
  };

  getWithoutTimeComponent = () => {
    const { device, readOnly, deviceFromCustom, deviceFromSketch, stretchSketch, group_params, params_device } = this.props;
    const { value } = this.state;

    let contentWithoutTime =
      this.state.showSwitch && deviceFromSketch ? (
        <Switch value={this.state.value} onValueChange={this.toggleValue} disabled={readOnly} />
      ) : this.state.showSwitch || (!deviceFromSketch && (group_params & 0x04) > 0) ? (
        <SwitchStyled value={this.state.value} onValueChange={this.toggleValue} disabled={readOnly} />
      ) : (
        <TouchableOpacity
          onPress={this.toggleValue}
          disabled={readOnly}
          hitSlop={{ top: 10, bottom: 10, left: 10, right: 10 }} // Increase touch area by 10 pixels on each side
          style={{
            width: deviceFromSketch ? "100%" : null,
            height: deviceFromSketch ? "100%" : null,
          }}
        >
          <ImageFromIkona0_0
            deviceFromSketch={deviceFromSketch}
            deviceFromCustom={deviceFromCustom}
            device={device}
            value={value}
            resizeMode={stretchSketch ? "stretch" : "contain"}
            style={{
              width: deviceFromSketch ? "100%" : 36,
              height: deviceFromSketch ? "100%" : 36,
            }}
          />
        </TouchableOpacity>
      );
    return contentWithoutTime;
  };

  render() {
    const {
      device,
      header,
      readOnly,
      deviceFromCustom,
      deviceFromSketch,
      stretchSketch,
      group_params,
      deviceState,
      params_device,
      dimensions,
      styleToPass,
    } = this.props;

    let playTime = params_device && params_device.get("czas");
    // let loaderHidden =params_device && (params_device.get("params") & 0x800000) > 0;

    const showAsDoorBell = params_device && (params_device.get("params") & 0x8000) > 0;
    const { value, pinDialogVisible, wrongPinDialogVisible } = this.state;

    const view = (
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          width: deviceFromSketch ? "100%" : null,
          height: deviceFromSketch ? "100%" : null,
        }}
      >
        {!deviceFromCustom && !deviceFromSketch && !this.props.detailContent && header}
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            width: deviceFromSketch ? "100%" : null,
            paddingLeft: 5,
            height: dimensions.get("smallTiles") && !deviceFromSketch ? 40 : deviceFromSketch ? "100%" : null,
          }}
        >
          {this.gateContent()}
          {device.get("bezpieczne") ? <StyledIcon style={{ marginRight: 4 }} name={"lock"} size={20} /> : null}
          {showAsDoorBell ? this.getBellComponent() : playTime ? this.getTimeComponent() : this.getWithoutTimeComponent()}
        </View>
        {pinDialogVisible && (
          <ModalSimple
            isVisible={pinDialogVisible}
            title={"AUTH"}
            description={"PASS_PIN"}
            placeholder={"PASS_PIN"}
            secureTextEntry={true}
            onInputChange={this.onPinInputHandler}
            keyboardType={"numeric"}
            onClose={this.handleClose}
            onOK={this.handleOK}
            showBiometrics={device.get("bezpieczne") == 2}
            onThirdOption={this.handleBiometrics}
          />
        )}
        {wrongPinDialogVisible && (
          <ModalSimple isVisible={wrongPinDialogVisible} title={"AUTH"} description={"PIN_NOT"} onOK={this.handleOKwrongPin} />
        )}
      </View>
    );

    return this.props.detailContent ? (
      <View>
        <ComponentContainer numOfColumns={1}>
          <View
            flexDirection="row"
            justifyContent={"space-between"}
            style={{ marginHorizontal: 10, flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}
            alignItems="center"
          >
            <ComponentHeaderText style={{ fontSize: 15 }}>{device.get("opis_menu")}</ComponentHeaderText>
            {view}
          </View>
        </ComponentContainer>
      </View>
    ) : (
      view
    );
  }
}

export default RelayComponent;
