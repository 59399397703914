import * as Actions from "../actionTypes";

const arrayFilledWithNulls = (data) => {
  if (data.length) {
    const lengthOfNewArray = data[data.length - 1].id;
    let newArray = new Array(lengthOfNewArray).fill(null);
    for (let i = 0; i < data.length; i++) {
      newArray[data[i].id] = data[i];
    }
    return newArray;
  }

  return data;
};

let eventsFilledWithNulls = (data) => {
  if (data.length) {
    const max = data.reduce((prev, current) => {
      let object = prev.id > current.id ? prev : current;
      return object.wartoscN;
    });
    const lengthOfNewArray = max + 1;
    let newArray = new Array(lengthOfNewArray).fill(null);
    for (let i = 0; i < data.length; i++) {
      newArray[data[i].wartoscN] = data[i];
    }
    return newArray;
  }

  return data;
};

const resourcesSort = (data) => {
  let sortedResources = {
    type4: [],
    type2: [],
    type0: [],
    rest: [],
  };

  for (let i = 0; i < data.length; i++) {
    if (data[i].type == 4) sortedResources.type4.push(data[i]);
    else if (data[i].type == 2) sortedResources.type2.push(data[i]);
    else if (data[i].type == 0) sortedResources.type0.push(data[i]);
    else sortedResources.rest.push(data[i]);
  }
  sortedResources.type4 = eventsFilledWithNulls(sortedResources.type4);
  sortedResources.type2 = eventsFilledWithNulls(sortedResources.type2);

  return sortedResources;
};

export const devicesReceived = (devices) => {
  return {
    type: Actions.DEVICES_RECEIVED,
    payload: arrayFilledWithNulls(devices),
  };
};

export const group_devicesReceived = (group_devices) => {
  return {
    type: Actions.GROUP_DEVICES_RECEIVED,
    payload: arrayFilledWithNulls(group_devices),
  };
};

export const params_devicesReceived = (params_devices) => {
  const withNulls = arrayFilledWithNulls(params_devices);
  return {
    type: Actions.PARAMS_DEVICES_RECEIVED,
    payload: withNulls,
  };
};

export const loggingReceived = (logging) => {
  return {
    type: Actions.LOGGING_RECEIVED,
    payload: logging,
  };
};

export const groupsReceived = (groups) => {
  return {
    type: Actions.GROUPS_RECEIVED,
    payload: arrayFilledWithNulls(groups),
  };
};

export const scenesReceived = (scenes) => {
  return {
    type: Actions.SCENES_RECEIVED,
    payload: scenes,
  };
};

export const resourcesReceived = (resources) => {
  return {
    type: Actions.RESOURCES_RECEIVED,
    payload: resourcesSort(resources),
  };
};

export const iconsReceived = (icons) => {
  return {
    type: Actions.ICONS_RECEIVED,
    payload: arrayFilledWithNulls(icons),
  };
};

export const changeParamsOfDevice = (deviceID, params) => {
  return {
    type: Actions.CHANGE_PARAM_OF_DEVICE,
    deviceID: deviceID,
    payload: params,
  };
};
export const changeName = (objectID, newName, objectType) => {
  return {
    type: Actions.CHANGE_NAME,
    payload: { objectID, newName, objectType },
  };
};
