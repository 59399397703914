export const WEATHER_AMPIO_RECEIVED = "WEATHER_AMPIO_RECEIVED";
export const FETCH_WEATHER_SUCCESS = "FETCH_WEATHER_SUCCESS";
export const FETCH_WEATHER_FAILURE = "FETCH_WEATHER_FAILURE";
export const FETCH_WEATHER_PENDING = "FETCH_WEATHER_PENDING";

//authData
export const AUTH_DATA_RECEIVED = "AUTH_DATA_RECEIVED";
export const ASSOCIATIONS_DATA_RECEIVED = "ASSOCIATIONS_DATA_RECEIVED";
export const META_DATA_RECEIVED = "META_DATA_RECEIVED";
export const ICE_SERVER_RECEIVED = "ICE_SERVER_RECEIVED";
//md5 data
export const MD5_DATA_RECEIVED = "MD5_DATA_RECEIVED";
//login data
export const CHANGE_CURRENT_PROFILE = "CHANGE_CURRENT_PROFILE";
export const SAVE_PROFILE = "SAVE_PROFILE";
export const DELETE_PROFILE = "DELETE_PROFILE";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";

//smart home data
export const DEVICES_RECEIVED = "DEVICES_RECEIVED";
export const GROUP_DEVICES_RECEIVED = "GROUP_DEVICES_RECEIVED";
export const PARAMS_DEVICES_RECEIVED = "PARAMS_DEVICES_RECEIVED";
export const LOGGING_RECEIVED = "LOGGING_RECEIVED";
export const GROUPS_RECEIVED = "GROUPS_RECEIVED";
export const SCENES_RECEIVED = "SCENES_RECEIVED";
export const RESOURCES_RECEIVED = "RESOURCES_RECEIVED";
export const ICONS_RECEIVED = "ICONS_RECEIVED";
export const CHANGE_PARAM_OF_DEVICE = "CHANGE_PARAM_OF_DEVICE";
export const CHANGE_NAME = "CHANGE_NAME";
export const ADD_SCENE = "ADD_SCENE";
export const REMOVE_SCENE = "REMOVE_SCENE";
export const CHANGE_CALL_ID = "CHANGE_CALL_ID";
export const CHANGE_CURRENT_ROUTE = "CHANGE_CURRENT_ROUTE";
export const CLEAR_STATES_DATA = "CLEAR_STATES_DATA";
export const CHANGE_DIMMENSIONS = "CHANGE_DIMMENSIONS";
export const CHANGE_BLOCK_DIMENSIONS = "CHANGE_BLOCK_DIMENSIONS";
export const CHANGE_APP_LOCATION = "CHANGE_APP_LOCATION";
export const CHANGE_CLIENT_ID = "CHANGE_CLIENT_ID";
export const CHANGE_JANUS_ID = "CHANGE_JANUS_ID";
export const CHANGE_JANUS = "CHANGE_JANUS";
export const CHANGE_CLIENT_DATA = "CHANGE_CLIENT_DATA";
export const CHANGE_CLOUD_SUPPORT_DATA = "CHANGE_CLOUD_SUPPORT_DATA";
export const CHANGE_EXPO_SOUND = "CHANGE_EXPO_SOUND";
export const CHANGE_ANWERED_CALL = "CHANGE_ANWERED_CALL";
export const CHANGE_INCOMING_CALL_PC = "CHANGE_INCOMING_CALL_PC";
export const CHANGE_SOUND_STATUS = "CHANGE_SOUND_STATUS";
export const CHANGE_SELECTED_SOURCES = "CHANGE_SELECTED_SOURCES";
export const STATES_RECEIVED = "STATES_RECEIVED";
export const STATES_ACTIVE_RECEIVED = "STATES_ACTIVE_RECEIVED";
export const SINGLE_STATE_RECEIVED = "SINGLE_STATE_RECEIVED";
export const SINGLE_ACTIVE_STATE_RECEIVED = "SINGLE_ACTIVE_STATE_RECEIVED";
export const CHANGE_CONNECTION_STATUS = "CHANGE_CONNECTION_STATUS";
export const CHANGE_TIME_MISSMATCH = "CHANGE_TIME_MISSMATCH";
export const CHANGE_INFO_BAR_VISIBILTY = "CHANGE_INFO_BAR_VISIBILTY";
export const DURING_TALK = "DURING_TALK";
export const CHANGE_NO_CONNECTION_CRITICAL = "CHANGE_NO_CONNECTION_CRITICAL";
export const CHANGE_ALERT_SCREEN_VISIBILTY = "CHANGE_ALERT_SCREEN_VISIBILTY";
export const CHANGE_APP_STATE = "CHANGE_APP_STATE";
export const CHANGE_LAST_TIMER = "CHANGE_LAST_TIMER";
export const CHANGE_BLOCK_LOGIN_AUTOMATICALLY = "CHANGE_BLOCK_LOGIN_AUTOMATICALLY";
export const CHANGE_FROM_SWITCH_PROFILE = "CHANGE_FROM_SWITCH_PROFILE";
export const CHANGE_CURRENT_CONNECTION_PLACE = "CHANGE_CURRENT_CONNECTION_PLACE";
export const CHANGE_POPUP_OBJECT_ID = "CHANGE_POPUP_OBJECT_ID";
export const HEATING_SCHEDULE_RECEIVED = "HEATING_SCHEDULE_RECEIVED";
export const IR_DATA_RECEIVED = "IR_DATA_RECEIVED";
export const IR_STATUS_RECEIVED = "IR_STATUS_RECEIVED";
export const ACCESS_CONTROL_RECEIVED = "ACCESS_CONTROL_RECEIVED";
export const CHART_DATA_RECEIVED = "CHART_DATA_RECEIVED";
export const LOGBOOK_DATA_RECEIVED = "LOGBOOK_DATA_RECEIVED";
export const BAR_CHART_DATA_RECEIVED = "BAR_CHART_DATA_RECEIVED";
export const REMOVE_OUTDATED_HEATING_SCHEDULE = "REMOVE_OUTDATED_HEATING_SCHEDULE";
export const REMOVE_OUTDATED_ACCESS_CONTROL = "REMOVE_OUTDATED_ACCESS_CONTROL";
export const RADIO_FAVOURITES_RECEIVED = "RADIO_FAVOURITES_RECEIVED";
export const CAMERA_STATE_RECEIVED = "CAMERA_STATE_RECEIVED";

//user settings
export const CHANGE_AUTO_LOGIN = "CHANGE_AUTO_LOGIN";
export const CHANGE_INIT_CALLS = "CHANGE_INIT_CALLS";
export const CHANGE_LANGUAGE = "CHANGE_LANGUAGE";
export const CHANGE_CALLER = "CHANGE_CALLER";
export const CHANGE_AUTO_LOGIN_TYPE = "CHANGE_AUTO_LOGIN_TYPE";
export const CHANGE_APP_LOGOUT = "CHANGE_APP_LOGOUT";
export const CHANGE_USER_THEME = "CHANGE_USER_THEME";
export const CHANGE_THEME_BY_SCHEDULE = "CHANGE_THEME_BY_SCHEDULE";
export const CHANGE_NIGHT_THEME_TURN_BY_SCHEDULE = "CHANGE_NIGHT_THEME_TURN_BY_SCHEDULE";
export const CHANGE_GROUP_ICONS_BG_VISIBILITY = "CHANGE_GROUP_ICONS_BG_VISIBILITY";
export const CHANGE_DEVICE_ICONS_BG_VISIBILITY = "CHANGE_DEVICE_ICONS_BG_VISIBILITY";
export const SET_NIGHT_THEME_SCHEDULE = "SET_NIGHT_THEME_SCHEDULE";
export const SAVE_ICON_BG_COLORS = "SAVE_ICON_BG_COLORS";
export const SAVE_SIDE_MENU_COLOR = "SAVE_SIDE_MENU_COLOR";
export const SET_WEATHER_CITY_FOR_SETTINGS = "SET_WEATHER_CITY_FOR_SETTINGS";
export const SET_DONT_ASK = "SET_DONT_ASK";
export const SET_QUESTIONNAIRE_RESPONSE = "SET_QUESTIONNAIRE_RESPONSE";
export const SET_CHANGED_TO_LIGHT = "SET_CHANGED_TO_LIGHT";
export const CHANGE_PROFILE_AUTOMATIC_LOGIN = "CHANGE_PROFILE_AUTOMATIC_LOGIN";
export const SHOW_BY_COLUMNS = "SHOW_BY_COLUMNS";
export const CHANGE_WEATHER_VISIBILITY = "CHANGE_WEATHER_VISIBILITY";
export const CHANGE_CALENDAR_VISIBILITY = "CHANGE_CALENDAR_VISIBILITY";
export const CHANGE_GREETINIG_VIEW_VISIBILITY = "CHANGE_GREETINIG_VIEW_VISIBILITY";
export const CHANGE_SIDE_MENU_VISIBILITY = "CHANGE_SIDE_MENU_VISIBILITY";
export const CHANGE_ONLY_MAIN_GROUPS_VISIBILITY = "CHANGE_ONLY_MAIN_GROUPS_VISIBILITY";
export const PLACES_BUTTON_WITH_SIDE_MENU_VISIBILITY = "PLACES_BUTTON_WITH_SIDE_MENU_VISIBILITY";
export const CHANGE_SIDE_MENU_SIZE = "CHANGE_SIDE_MENU_SIZE";
export const CHANGE_STARTING_PLACE = "CHANGE_STARTING_PLACE";
export const CHANGE_BACK_TO_START_TIMEOUT = "CHANGE_BACK_TO_START_TIMEOUT";
export const SIDE_MENU_SET_BY_USER = "SIDE_MENU_SET_BY_USER";
export const SHOW_AS_LIST = "SHOW_AS_LIST";
export const HIDE_LAST_CHANGE_TIME_ON_LIST = "HIDE_LAST_CHANGE_TIME_ON_LIST";
export const CHANGE_KEEP_AWAKE_SETTINGS = "CHANGE_KEEP_AWAKE_SETTINGS";
export const CHANGE_STRETCH_SKETCH = "CHANGE_STRETCH_SKETCH";
export const CHANGE_SHOW_SKETCH_FIRST = "CHANGE_SHOW_SKETCH_FIRST";
export const CHANGE_DEVICE_UUID = "CHANGE_DEVICE_UUID";
export const CHANGE_CONSENTS_TIME = "CHANGE_CONSENTS_TIME";
export const CHANGE_CONSENTS_STATUS = "CHANGE_CONSENTS_STATUS";
export const CHANGE_DEVICE_ID_VISIBILITY = "CHANGE_DEVICE_ID_VISIBILITY";
export const CHANGE_FORCE_LANDSCAPE_ORIENTATION = "CHANGE_FORCE_LANDSCAPE_ORIENTATION";
export const CHANGE_INVERTED_FORCE_LANDSCAPE_ORIENTATION = "CHANGE_INVERTED_FORCE_LANDSCAPE_ORIENTATION";
export const CHANGE_DISABLE_CLICK_VIBRATIONS = "CHANGE_DISABLE_CLICK_VIBRATIONS";
export const CHANGE_DASHBOARD_TOPBAR_VISIBILITY = "CHANGE_DASHBOARD_TOPBAR_VISIBILITY";
export const CHANGE_MESSAGES_INDICATOR_VISIBILITY = "CHANGE_MESSAGES_INDICATOR_VISIBILITY";
export const CHANGE_SIMULATION_DASHBOARD_VISIBILITY = "CHANGE_SIMULATION_DASHBOARD_VISIBILITY";
export const ADD_FAVOURITE_COLOR_RGB_MODAL = "ADD_FAVOURITE_COLOR_RGB_MODAL";
export const CHANGE_OBJECT_SIZE = "CHANGE_OBJECT_SIZE";
export const CHANGE_CAMERA_CONNECTION_TYPE = "CHANGE_CAMERA_CONNECTION_TYPE";
export const REMOVE_FAVOURITE_COLOR_RGB_MODAL = "REMOVE_FAVOURITE_COLOR_RGB_MODAL";
export const ADD_DEVICE_TO_FAVOURITES = "ADD_DEVICE_TO_FAVOURITES";
export const ADD_RADIO_TO_FAVOURITES = "ADD_RADIO_TO_FAVOURITES";
export const REMOVE_RADIO_FROM_FAVOURITES = "REMOVE_RADIO_FROM_FAVOURITES";
export const CHANGE_OBJECT_FULL_WIDTH = "CHANGE_OBJECT_FULL_WIDTH";
export const SAVE_FAVOURITES = "SAVE_FAVOURITES";
export const REMOVE_DEVICE_FROM_FAVOURITES = "REMOVE_DEVICE_FROM_FAVOURITES";
export const CHANGE_CLOUD_CONNECTION_ALLOWANCE = "CHANGE_CLOUD_CONNECTION_ALLOWANCE";
export const SAVE_ORDER = "SAVE_ORDER";
export const SAVE_GEOFENCES = "SAVE_GEOFENCES";
export const RESET_ORDER = "RESET_ORDER";
export const SAVE_GREETING_VIEW_OBJECTS = "SAVE_GREETING_VIEW_OBJECTS";
export const SERVER_INFO_RECEIVED = "SERVER_INFO_RECEIVED";
export const SERVER_OWNER_RECEIVED = "SERVER_OWNER_RECEIVED";
export const MQTT_VERSION_RECEIVED = "MQTT_VERSION_RECEIVED";
export const DIAGNOSTIC_STATS_RECEIVED = "DIAGNOSTIC_STATS_RECEIVED";
export const RECEIVED_DETECTED_OUTPUTS = "RECEIVED_DETECTED_OUTPUTS";
export const RECEIVED_QUEUE_INFO = "RECEIVED_QUEUE_INFO";
export const RECEIVED_PLAYER_INFO = "RECEIVED_PLAYER_INFO";
export const INITIAL_LOADING_STATUS = "INITIAL_LOADING_STATUS";
export const SERVER_UPDATE_INFO_RECEIVED = "SERVER_UPDATE_INFO_RECEIVED";
export const MQTT_UPDATE_INFO_RECEIVED = "MQTT_UPDATE_INFO_RECEIVED";
export const LOGBOOK_RECEIVED = "LOGBOOK_RECEIVED";
export const ACTIONS_LOG_RECEIVED = "ACTIONS_LOG_RECEIVED";
export const MESSAGES_RECEIVED = "MESSAGES_RECEIVED";
export const URGENT_MESSAGES_RECEIVED = "URGENT_MESSAGES_RECEIVED";
export const MESSAGES_COUNT_RECEIVED = "MESSAGES_COUNT_RECEIVED";
export const IRRIGATIONS_RECEIVED = "IRRIGATIONS_RECEIVED";
export const AUTOMATIZATIONS_RECEIVED = "AUTOMATIZATIONS_RECEIVED";
export const PASSWORD_CHANGED_INFO_RECEIVED = "PASSWORD_CHANGED_INFO_RECEIVED";
