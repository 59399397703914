import * as Localization from "expo-localization";

// creating a language detection plugin using expo
// http://i18next.com/docs/ownplugin/#languagedetector
const languageDetector = {
	type: "languageDetector",
	async: true, // flags below detection to be async
	detect: (callback) => {
		return /*'en'; */ callback(Localization.locale.split("-")[0]);
	},
	init: () => {},
	cacheUserLanguage: () => {},
};

export default languageDetector;
