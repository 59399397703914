const scenesForGroups = (data, groupId, order) => {
	let filteredArray = [];
	if (data && data.size) {
		filteredArray = data.filter((scene) => {
			if (scene) {
				return scene.get("parentId") == groupId;
			}
		});

		let scenesOrder =
			order && order.get(`${groupId}`) && order.get(`${groupId}`).get("scenes");

		if (scenesOrder) {
			filteredArray = filteredArray.map((scene) => {
				let inOrderListIndex = scenesOrder.findIndex((el) => {
					return el == scene.get("sceneName");
				});
				scene = scene.set("orderNumber", inOrderListIndex);
				return scene;
			});
			filteredArray = filteredArray.sort((x, y) => {
				return x.get("orderNumber") - y.get("orderNumber");
			});
		}
	}

	return filteredArray;
};

export default scenesForGroups;
