import { mediaDevices, MediaStream, RTCIceCandidate, RTCPeerConnection, RTCSessionDescription, RTCView } from "react-native-webrtc-web-shim";
import React from "react";
import { Audio } from "expo-av";
import { AppState, Dimensions, FlatList, StatusBar, ImageBackground, View, ActivityIndicator, Platform } from "react-native";
import Janus from "../../services/janus/Janus";
import JanusVideoRoomPlugin from "../../services/janus/plugins/videoroom/JanusVideoRoomPlugin";
import InCallManager from "react-native-incall-manager";
import { ButtonPrimary, PrimaryColorText } from "../../components/UI/styledComponents";
import { t } from "../../services/i18n";
import { ThemeContext } from "../../../ThemeContext";
import mqttClient from "../../services/mqtt";

Janus.setDependencies({
  RTCPeerConnection,
  RTCSessionDescription,
  RTCIceCandidate,
  MediaStream,
});

class JanusVideoRoomScreen extends React.Component {
  static contextType = ThemeContext;
  constructor(props) {
    super(props);
    this.state = {
      stream: null,
      noPermissions: undefined,
      publishers: [],
      appState: AppState.currentState,
      janusConnected: false,
    };
    this.duringInit = false;
  }

  _handleAppStateChange = async (nextAppState) => {
    if (this.state.appState === "background" && nextAppState === "active") {
      //await this.getMediaStream();
      this.setState({ appState: nextAppState });
    } else if (this.state.appState === "active" && nextAppState == "background") {
      await this.deinitJanus(nextAppState);
    }
  };

  deinitJanus = async (nextAppState, janus) => {
    if (this.state.publishers) {
      for (let i = 0; i < this.state.publishers.length; i++) {
        if (this.state.publishers[i].stream) {
          this.state.publishers[i].stream.getTracks().forEach((track) => track.stop());
        }
        if (this.state.publishers[i].videoRoom && this.state.publishers[i].videoRoom.pc) {
          await this.state.publishers[i].videoRoom.pc.close();
        }
      }
    }

    if (this.state.stream) {
      this.state.stream.getTracks().forEach((track) => track.stop());
    }
    if (this.videoRoom && this.videoRoom.pc) {
      this.videoRoom.pc.close();
    }
    this.setState({
      appState: nextAppState,
      stream: null,
      isCalling: false,
    });
    if (janus) {
      await janus.destroy();
    }
    if (this.janus) {
      await this.janus.destroy();
    }
  };
  async receivePublisher(publisher) {
    try {
      let videoRoom = new JanusVideoRoomPlugin(this.janus);
      videoRoom.setRoomID(1234);
      videoRoom.setOnStreamListener((stream) => {
        this.setState((state) => ({
          publishers: [
            ...state.publishers,
            {
              publisher: publisher,
              stream: stream,
              videoRoom: videoRoom,
            },
          ],
        }));
      });

      await videoRoom.createPeer();
      await videoRoom.connect();
      await videoRoom.receive(this.videoRoom.getUserPrivateID(), publisher);
    } catch (e) {
      console.error(e);
    }
  }

  async removePublisher(publisherID) {
    try {
      this.setState((state) => ({
        publishers: state.publishers.filter((pub) => pub.publisher == null || pub.publisher.id !== publisherID),
      }));
    } catch (e) {
      console.error(e);
    }
  }

  async initJanus(stream) {
    const { device, currentConnection, params_device } = this.props;

    let condition = currentConnection && (currentConnection == "cloud" || currentConnection == "new_cloud");

    let url = params_device ? params_device.get("url") : "";
    let uri = condition ? device.get("uwagi") : url;
    if (!uri.startsWith("ws://") && !condition) {
      uri = "ws://" + uri;
    }

    try {
      this.setState((state) => ({
        publishers: [
          {
            publisher: null,
            stream: stream,
          },
        ],
      }));
      this.janus = new Janus(uri);
      this.janus.setIceServers([{ urls: ["stun:77.55.214.60:3478"] }, { urls: ["turn:77.55.214.60:3478"], username: "none", credential: "none" }]);

      await this.janus.init();

      this.videoRoom = new JanusVideoRoomPlugin(this.janus);

      this.videoRoom.setRoomID(1234);
      this.videoRoom.setDisplayName("Ampio");
      //this.videoRoom.setApiSecret("V|/ekt@2021");
      console.log("Janus init", uri);
      this.videoRoom.setOnPublishersListener((publishers) => {
        for (let i = 0; i < publishers.length; i++) {
          this.receivePublisher(publishers[i]);
        }
      });
      this.videoRoom.setOnPublisherJoinedListener((publisher) => {
        console.log("Publisher joined");
        this.receivePublisher(publisher);
      });
      this.videoRoom.setOnPublisherLeftListener((publisherID) => {
        this.removePublisher(publisherID);
      });
      this.videoRoom.setOnWebRTCUpListener(async () => {});

      this.videoRoom.setOnReceiveAudioListener(async () => {
        if (Platform.OS !== "web") {
          try {
            InCallManager.setForceSpeakerphoneOn(true);
            setTimeout(() => {
              try {
                InCallManager.setSpeakerphoneOn(true);
              } catch {}
            }, 100);
          } catch {}
        }
      });
      await this.videoRoom.createPeer();
      await this.videoRoom.connect();
      await this.videoRoom.join();
      this.setState({ janusConnected: true });
      //await this.videoRoom.publish(stream);
    } catch (e) {
      console.error("Janus video room init", JSON.stringify(e));
    }
    this.duringInit = false;
  }

  getMediaStream = async () => {
    const AudioPerm = await Audio.requestPermissionsAsync();
    if (AudioPerm && AudioPerm.status === "granted") {
      let stream = await mediaDevices.getUserMedia({
        audio: true,
        video: false,
      });
      await this.initJanus(stream);
      this.setState({
        noPermissions: false,
      });
    } else {
      this.setState({
        noPermissions: true,
      });
    }
  };

  async componentDidMount() {
    this.appStateSubscription = AppState.addEventListener("change", this._handleAppStateChange);
    this.getMediaStream();
  }

  componentWillUnmount = async () => {
    //AppState.removeEventListener("change", this._handleAppStateChange);
    this.appStateSubscription.remove();
    if (this.janus) {
      await this.janus.destroy();
    }
  };

  hangUpAndOpen = async () => {
    const { params_device, device } = this.props;
    let message = null;
    if (params_device && params_device.get("czas")) {
      message = `/api/set/${device.get("id")}/setValue/0/${params_device.get("czas")}`;
    } else {
      message = `/api/set/${device.get("id")}/setValue/0`;
    }
    mqttClient.stateChangeToSend(message, device.get("id"));
    if (this.videoRoom) {
      await this.videoRoom.unpublish();
    }
  };

  hangUp = async () => {
    if (this.videoRoom) {
      await this.videoRoom.unpublish();
    }
  };

  pickUp = async () => {
    await this.videoRoom.publish(this.state.publishers[0].stream);
    if (Platform.OS !== "web") {
      setTimeout(() => {
        try {
          InCallManager.setSpeakerphoneOn(true);
        } catch {}
      }, 100);
    }
    const { params_device, device } = this.props;
    let message = null;
    if (params_device && params_device.get("czas")) {
      message = `/api/set/${device.get("id")}/setValue/255/${params_device.get("czas")}`;
    } else {
      message = `/api/set/${device.get("id")}/setValue/255`;
    }
    mqttClient.stateChangeToSend(message, device.get("id"));
  };

  render() {
    const { header } = this.props;
    const { theme } = this.context;
    const { noPermissions } = this.state;
    let possibleTalk = false;
    let duringTalk = false;

    if (this.props.deviceState && (this.props.deviceState.get("state") & 0x01) > 0) possibleTalk = true;

    if (this.props.deviceState && (this.props.deviceState.get("state") & 0x02) > 0) duringTalk = true;

    if (InCallManager && Platform.OS !== "web") {
      try {
        if (possibleTalk === true && duringTalk === false) {
          InCallManager.startRingtone("_DEFAULT_");
        } else {
          InCallManager.stopRingtone();
        }
      } catch {}
    }

    if (this.state.publishers) {
      //console.log("TOO", this.state.publishers.length, this.state.publishers);
    }

    return (
      <View>
        {header}
        <View
          style={{
            justifyContent: "center",
            alignItems: "center",
            paddingTop: 10,
          }}
        >
          <View
            style={[
              {
                backgroundColor: theme.COMPONENT_BACKGROUND_COLOR,
                flexDirection: "column",
                justifyContent: "center",
                alignContent: "center",
                width: "100%",
                flex: 1,
              },
              this.props.style,
            ]}
          >
            {this.janus && this.state.publishers && this.state.publishers[1] ? (
              <ImageBackground
                style={{
                  backgroundColor: "transparent",
                  flex: 1,
                }}
                imageStyle={{
                  resizeMode: "contain",
                }}
              >
                <View
                  style={[
                    {
                      backgroundColor: "transparent",
                      flexDirection: "row",
                      height: 200,
                    },
                    this.props.style,
                  ]}
                >
                  <RTCView
                    style={{
                      height: 200,
                      flex: 1,
                    }}
                    objectFit={"contain"}
                    streamURL={this.state.publishers[1].stream.toURL()}
                  />
                </View>
              </ImageBackground>
            ) : null}

            {this.janus ? (
              <View
                style={[
                  {
                    flexDirection: "row",
                    justifyContent: "center",
                    marginTop: 10,
                    width: "100%",
                  },
                ]}
              >
                {possibleTalk && !duringTalk ? (
                  <ButtonPrimary style={{ marginBottom: 10 }} onPress={this.pickUp}>
                    {t("PICKUP")}
                  </ButtonPrimary>
                ) : null}

                {possibleTalk || duringTalk ? (
                  <ButtonPrimary style={{ marginBottom: 10 }} onPress={this.hangUpAndOpen}>
                    {duringTalk ? t("HANGUP_OPEN") : t("OPEN")}
                  </ButtonPrimary>
                ) : null}

                {this.state.stream && duringTalk ? (
                  <ButtonPrimary style={{ marginBottom: 10 }} onPress={this.hangUp}>
                    {t("HANGUP")}
                  </ButtonPrimary>
                ) : null}
              </View>
            ) : (
              <View>
                {noPermissions === false ? (
                  <PrimaryColorText style={{ paddingBottom: 4 }}>{t("NO_PERMISSIONS_TO_MICROPHONE")}</PrimaryColorText>
                ) : null}
              </View>
            )}
          </View>
        </View>
      </View>
    );

    return (
      <View
        style={[
          {
            backgroundColor: "transparent",
            flexDirection: "row",
          },
          this.props.style,
        ]}
      >
        {possibleTalk ? (
          <View
            style={[
              {
                flexDirection: "column",
                justifyContent: "center",
                flex: 1,
                width: "100%",
              },
            ]}
          >
            {(this.props.deviceState && this.props.deviceState.get("state") & 0x01) > 0 ? (
              <ButtonPrimary style={{ marginBottom: 10 }} onPress={this.pickUp}>
                {t("PICKUP")}
              </ButtonPrimary>
            ) : null}
            <ButtonPrimary onPress={this.hangUp}>{t("HANGUP")}</ButtonPrimary>
          </View>
        ) : null}

        {(possibleTalk || duringTalk) && this.state.publishers && this.state.publishers[1] ? (
          <RTCView
            style={{
              flex: 1,
              height: 200,
            }}
            objectFit={"cover"}
            streamURL={this.state.publishers[1].stream.toURL()}
          />
        ) : null}
      </View>
    );
  }
}

export default JanusVideoRoomScreen;
