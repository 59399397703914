import { View, Switch, TouchableOpacity, Text } from "react-native";
import React, { useContext } from "react";

import { useTheme } from "../../../ThemeContext";

export const ListItemContainer = (props) => {
  const { theme } = useTheme();

  return (
    <View style={{ width: "100%" }}>
      <View
        style={[
          {
            marginBottom: 5,
            padding: 8,
            backgroundColor: theme.COMPONENT_BACKGROUND_COLOR,
            alignItems: "center",
            justifyContent: "space-between",
            flexDirection: "row",
          },
          props.style,
        ]}
        onLayout={props.onLayout}
      >
        {props.children}
      </View>
    </View>
  );
};
