import React, { Component, useEffect } from "react";
import { TouchableOpacity, View, Text } from "react-native";
import { FontAwesome } from "@expo/vector-icons";
import mqttClient from "../services/mqtt";
import { useSelector } from "react-redux";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { connect } from "react-redux";
import { ComponentContainer, ComponentHeaderText } from "../components/UI/styledComponents";
import { useTheme } from "../../ThemeContext";

const SceneInGroup = ({ scene, dimensions, devicesStates }) => {
  const controlScene = (sceneID, option) => {
    mqttClient.controlScene(sceneID, option);
  };
  const { theme } = useTheme();
  const numberOfColumns = dimensions.get("numberOfColumns");

  const isSceneActive = () => {
    return scene.get("Infos").every((sceneDevice) => {
      let deviceState = devicesStates.get(sceneDevice.get("id"));
      if (deviceState && deviceState.get("state") == sceneDevice.get("value")) {
        return true;
      }
      return false;
    });
  };

  const [sceneActive, setSceneActive] = React.useState(isSceneActive());

  let isBig = numberOfColumns == 1 ? false : true;

  useEffect(() => {
    setSceneActive(isSceneActive());
  }, [devicesStates]);

  return (
    <ComponentContainer
      key={`${scene.get("sceneName")}${scene.get("id")}`}
      containerStyle={{ width: `${100 / numberOfColumns}%` }}
      style={{
        flexDirection: isBig ? "column" : "row",
        justifyContent: isBig ? "center" : null,
        height: dimensions.get("smallTiles") ? 100 : undefined,
      }}
    >
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          flex: 1,
          marginLeft: 15,
          marginRight: 10,
          paddingBottom: isBig ? 10 : 0,
        }}
      >
        <View flexDirection="row" justifyContent={"space-between"} alignItems="center">
          <ComponentHeaderText style={{ fontSize: 15 }}>{scene.get("sceneName")}</ComponentHeaderText>
        </View>
        {sceneActive && <MaterialCommunityIcons name={"check"} size={22} color={"rgb(50, 200, 80)"} style={{ paddingLeft: 5, marginRight: 5 }} />}
      </View>

      <View
        style={{
          alignItems: "flex-end",
          flexDirection: "row",
          width: isBig ? "auto" : 100,
          justifyContent: "space-evenly",
        }}
      >
        <TouchableOpacity
          style={{
            paddingHorizontal: 6,
          }}
          onPress={() => controlScene(scene.get("id"), "run")}
        >
          <FontAwesome name="play-circle" color={"#999"} size={isBig ? 32 : 24} />
        </TouchableOpacity>
        <TouchableOpacity style={{ paddingHorizontal: 6 }} onPress={() => controlScene(scene.get("id"), "undo")}>
          <FontAwesome name="undo" color={"#aaa"} color={"#999"} size={isBig ? 32 : 24} />
        </TouchableOpacity>
        <TouchableOpacity style={{ paddingHorizontal: 6 }} onPress={() => controlScene(scene.get("id"), "off")}>
          <FontAwesome name="power-off" color={"#aaa"} color={"#999"} size={isBig ? 32 : 24} />
        </TouchableOpacity>
      </View>
    </ComponentContainer>
  );
};

const mapStateToProps = (state) => {
  return {
    dimensions: state.statesData.get("dimensions"),
    devicesStates: state.statesData.get("statesActive"),
  };
};

function compareScenes(prev, next) {
  const prevActive = prev.scene.get("Infos").every((sceneDevice) => {
    let deviceState = prev.devicesStates.get(sceneDevice.get("id"));
    if (deviceState && deviceState.get("state") == sceneDevice.get("value")) {
      return true;
    }
    return false;
  });

  const nextActive = next.scene.get("Infos").every((sceneDevice) => {
    let deviceState = next.devicesStates.get(sceneDevice.get("id"));
    if (deviceState && deviceState.get("state") == sceneDevice.get("value")) {
      return true;
    }
    return false;
  });

  return nextActive === prevActive;
}

export default connect(mapStateToProps)(React.memo(SceneInGroup, compareScenes));
