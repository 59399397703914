import React, { Component } from "react";
import { View, TouchableOpacity, Text, StyleSheet, Platform } from "react-native";
import { connect } from "react-redux";
import { t } from "../../../services/i18n";
import mqttClient from "../../../services/mqtt";

import AddDeviceModal from "./AddDeviceModal";
import { ListItemContainer } from "../styled";
import { changeSimulationVisibility } from "../../../store/actions";
import { ScreenView } from "../../../components/UI/screenViewX";
import { TitleWithLines, SwitchStyled, ButtonPrimary, PrimaryColorText } from "../../../components/UI/styledComponents";

class Simulation_AccountScreen extends Component {
  state = {
    dialogVisible: false,
    devicesArray: [],
    simulationActive: false,
  };

  componentDidMount() {
    const { devices, params_devices } = this.props;

    let simulationDevice = devices.find((el) => {
      return el && el.get("typ_komponentu") == "symulacja";
    });

    if (simulationDevice && simulationDevice.get("id") && params_devices.get(simulationDevice.get("id"))) {
      let simulationParams = params_devices.get(simulationDevice.get("id"));
      let simulationActive = Number(simulationParams.get("czas")) > 0 ? true : false;

      let devicesArray = [];

      if (simulationParams.get("powiazane")) {
        let powiazaneArray = simulationParams.get("powiazane").split(",");
        powiazaneArray.map((el, index) => {
          el = el.split(":").reverse();
          let deviceFromCustom = devices.get(el[0]);
          if (deviceFromCustom) {
            devicesArray.push(deviceFromCustom.get("id"));
          }
        });
      }

      this.setState({
        showSimulation: true,
        simulationActive,
        devicesArray,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { devices, params_devices } = this.props;

    let simulationDevice = devices.find((el) => {
      return el && el.get("typ_komponentu") == "symulacja";
    });
    if (simulationDevice && simulationDevice.get("id") && params_devices.get(simulationDevice.get("id"))) {
      let simulationParams = params_devices.get(simulationDevice.get("id"));
      let simulationActive = Number(simulationParams.get("czas")) > 0 ? true : false;
      if (prevState.simulationActive != simulationActive) {
        this.setState({ simulationActive });
      }
    }
  }
  changeSimulationVisibilityHandler = (value) => {
    this.props.onChangeSimulationVisibility(value);
  };

  changeSimulationActivity = (value) => {
    this.setState({
      simulationActive: value,
    });
    let message = `/api/json/simulation/${value ? "active" : "deactive"}`;
    mqttClient.changeSimulationActivity(message);
  };

  onCloseHandler = () => {
    this.setState({
      dialogVisible: false,
    });
  };

  onAddNewDevice = (selectedDevice) => {
    if (!this.state.devicesArray.some((deviceId) => deviceId == selectedDevice)) {
      const devicesArray = this.state.devicesArray.concat(selectedDevice);
      this.setState({ devicesArray, dialogVisible: false });
    } else {
      this.setState({ dialogVisible: false });
    }
  };

  onRemoveDevice = (selectedDevice) => {
    const devicesArray = this.state.devicesArray.filter((id) => id != selectedDevice);
    this.setState({ devicesArray });
  };

  componentWillUnmount() {
    if (this.state.showSimulation) {
      let payload = {
        objects: this.state.devicesArray,
      };
      mqttClient.sendSimulationData(payload);
    }
  }

  showAddModal = () => {
    this.setState({ dialogVisible: true });
  };
  render() {
    const { devices, simulationDashboardShown } = this.props;
    const { showSimulation, dialogVisible, devicesArray, simulationActive } = this.state;
    let list = devicesArray.map((id) => {
      let device = devices.get(id);
      if (device) {
        return (
          <ListItemContainer key={devices.get(id)}>
            <PrimaryColorText>{device.get("opis_menu")}</PrimaryColorText>
            <TouchableOpacity onPress={() => this.onRemoveDevice(id)}>
              <Text
                style={{
                  color: "red",
                  fontStyle: "italic",
                }}
              >
                {t("REMOVE")}
              </Text>
            </TouchableOpacity>
          </ListItemContainer>
        );
      }
    });
    let content = showSimulation ? (
      <ScreenView>
        {dialogVisible && <AddDeviceModal isVisible={dialogVisible} onClose={this.onCloseHandler} onAdd={this.onAddNewDevice} />}
        <ListItemContainer>
          <PrimaryColorText>{t("account:TURN_ON_SIMULATION")}</PrimaryColorText>
          <SwitchStyled value={simulationActive} onValueChange={this.changeSimulationActivity} />
        </ListItemContainer>
        <ListItemContainer>
          <PrimaryColorText>{t("account:SHOW_SIMULATION_DASHBOARD")}</PrimaryColorText>

          <SwitchStyled value={simulationDashboardShown} onValueChange={this.changeSimulationVisibilityHandler} />
        </ListItemContainer>

        <TitleWithLines textInside={t("account:DEVICES")} />
        <View style={{ width: "100%", justifyContent: "center" }}>
          <ButtonPrimary style={{ paddingHorizontal: 15, margin: 10 }} onPress={this.showAddModal}>
            {t("account:ADD_DEVICE_SIMULATION")}
          </ButtonPrimary>
        </View>
        {list}
      </ScreenView>
    ) : (
      <View
        style={{
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          padding: 20,
        }}
      >
        <Text>{t("NO_RIGHTS_TO_USE_OBJECT")}</Text>
      </View>
    );
    return content;
  }
}

const mapStateToProps = (state) => {
  return {
    devices: state.smartHomeData.get(state.profilesSettings.get("currentProfile")).get("devices"),
    params_devices: state.smartHomeData.get(state.profilesSettings.get("currentProfile")).get("params_devices"),
    globalSettings: state.globalSettings,
    simulationDashboardShown: state.globalSettings.get("simulationDashboardShown"),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onChangeSimulationVisibility: (simulationDashboardVisible) => dispatch(changeSimulationVisibility(simulationDashboardVisible)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Simulation_AccountScreen);
