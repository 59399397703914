import React, { Component } from "react";
import { View, Text, Platform } from "react-native";
import { connect } from "react-redux";
import { t } from "../../../services/i18n";

import {
  changeStretchSketch,
  changeShowSketchFirst,
} from "../../../store/actions";
import { ScreenView } from "../../../components/UI/screenViewX";
import {
  PrimaryColorText,
  TextInputStyled,
  SwitchStyled,
} from "../../../components/UI/styledComponents";

import { ListItemContainer } from "../styled";

const Sketch_PersonalizationScreen = (props) => {
  const changeStretchSketchHandler = (value) => {
    props.changeStretchSketch(value);
  };
  const changeShowSketchFirstHandler = (value) => {
    props.changeShowSketchFirst(value);
  };

  const { globalSettings } = props;

  const stretchSketch = globalSettings.get("stretchSketch");
  let showSketchFirst = globalSettings.get("showSketchFirst");
  if (showSketchFirst == undefined) {
    showSketchFirst = true;
  }

  return (
    <ScreenView>
      <ListItemContainer>
        <PrimaryColorText>{t("account:STRETCH_SKETCH")}</PrimaryColorText>
        <SwitchStyled
          value={stretchSketch}
          onValueChange={changeStretchSketchHandler}
        />
      </ListItemContainer>
      <ListItemContainer>
        <PrimaryColorText>{t("account:SHOW_SKETCH_FIRST")}</PrimaryColorText>
        <SwitchStyled
          value={showSketchFirst}
          onValueChange={changeShowSketchFirstHandler}
        />
      </ListItemContainer>
    </ScreenView>
  );
};

const mapStateToProps = (state) => {
  return {
    globalSettings: state.globalSettings,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeStretchSketch: (stretchSketch) =>
      dispatch(changeStretchSketch(stretchSketch)),
    changeShowSketchFirst: (showSketchFirst) =>
      dispatch(changeShowSketchFirst(showSketchFirst)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Sketch_PersonalizationScreen);
