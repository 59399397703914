import React, { Component } from "react";
import {
  View,
  TouchableOpacity,
  Text,
  ScrollView,
  Platform,
  FlatList,
} from "react-native";
import { t } from "../../../services/i18n";
import { PrimaryColorText } from "../../../components/UI/styledComponents";
import { ScreenView } from "../../../components/UI/screenViewX";
import * as Localization from "expo-localization";

import libraries from "./libreries";
import versions from "./versions";

const About_AccountScreen = () => {
  let lang = Localization.locale.split("-")[0];

  return (
    <ScreenView style={{ padding: 5 }}>
      <View>
        <PrimaryColorText
          style={{ fontWeight: "bold", fontSize: 18, paddingVertical: 10 }}
        >
          CHANGELOG:
        </PrimaryColorText>

        <FlatList
          data={versions}
          nestedScrollEnabled={true}
          scrollEnabled={false}
          renderItem={({ item, index, separators }) => (
            <View key={item.name} style={{ width: "100%", marginBottom: 8 }}>
              <View style={{ flexDirection: "row" }}>
                <PrimaryColorText
                  style={{ fontWeight: "bold", paddingRight: 5 }}
                >
                  {t("VERSION")} {item.name},
                </PrimaryColorText>
                <PrimaryColorText style={{ fontStyle: "italic" }}>
                  {t("DATE")}: {item.date}
                </PrimaryColorText>
              </View>
              <PrimaryColorText>
                {lang == "pl" ? item.desc_pl : item.desc_en}
              </PrimaryColorText>
            </View>
          )}
          keyExtractor={(item, index) => index}
        />
      </View>
      <View>
        <PrimaryColorText
          style={{
            fontWeight: "bold",
            fontSize: 18,
            marginTop: 15,
            marginBottom: 10,
          }}
        >
          THIRD PARTY LIBRERIES:
        </PrimaryColorText>

        <FlatList
          data={libraries}
          nestedScrollEnabled={true}
          scrollEnabled={false}
          renderItem={({ item, index, separators }) => (
            <View key={item.name} style={{ width: "100%", marginBottom: 8 }}>
              <PrimaryColorText style={{ fontWeight: "bold", paddingTop: 5 }}>
                {item.name}
              </PrimaryColorText>
              <PrimaryColorText>{item.description}</PrimaryColorText>
            </View>
          )}
          keyExtractor={(item, index) => index}
        />
      </View>
    </ScreenView>
  );
};

export default About_AccountScreen;
