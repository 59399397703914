import React, { Component } from "react";
import { Text, View, TouchableOpacity, StyleSheet, TextInput, Platform } from "react-native";
import { t } from "../../../services/i18n";

import DateTimePicker from "../../../components/UI/dateTimePicker";

import PickerSelect from "../../../components/UI/PickerSelect";
import { ThemeContext } from "../../../../ThemeContext";

import { TextInputStyled, StyledIcon, PrimaryColorText } from "../../../components/UI/styledComponents";
import moment from "moment";

class SceneScheduleSingle extends Component {
  static contextType = ThemeContext;

  constructor(props) {
    super(props);
    this.timeOptions = [
      {
        label: t("AT_TIME"),
        value: 0,
      },
      {
        label: t("BEFORE_SUNRISE"),
        value: 25,
      },
      {
        label: t("AFTER_SUNRISE"),
        value: 26,
      },
      {
        label: t("BEFORE_SUNSET"),
        value: 27,
      },
      {
        label: t("AFTER_SUNSET"),
        value: 28,
      },
    ];

    this.actionOptions = [
      {
        label: t("scenes:PLAY"),
        value: 0,
      },
      {
        label: t("scenes:UNDO"),
        value: 1,
      },
      {
        label: t("scenes:TURN_OFF"),
        value: 2,
      },
    ];

    this.state = {
      daysArray: [
        {
          value: 1,
          label: t("weekdays:MON"),
          active: false,
        },
        {
          value: 2,
          label: t("weekdays:TUE"),
          active: false,
        },
        {
          value: 3,
          label: t("weekdays:WED"),
          active: false,
        },
        {
          value: 4,
          label: t("weekdays:THU"),
          active: false,
        },
        {
          value: 5,
          label: t("weekdays:FRI"),
          active: false,
        },
        {
          value: 6,
          label: t("weekdays:SAT"),
          active: false,
        },
        {
          value: 0,
          label: t("weekdays:SUN"),
          active: false,
        },
      ],
      schedule: null,
      selectedTimeOption: 0,
      selectedActionOption: 0,
      minutesSun: "0",
    };
  }

  componentDidMount() {
    const { schedule } = this.props;
    let selectedTimeOption = 0;
    let selectedActionOption = schedule.get("action") ? schedule.get("action") : 0;
    let minutesSun = "0";
    let days = this.state.daysArray.map((day, index) => {
      day.active = this.props.schedule.get("day") & (1 << day.value) ? true : false;
      return day;
    });
    if (Number(schedule.get("hour")) > 24) {
      selectedTimeOption = schedule.get("hour");
      minutesSun = schedule.get("minute").toString();
    }

    this.setState({
      daysArray: days,
      schedule,
      selectedTimeOption,
      selectedActionOption,
      minutesSun,
    });
  }

  onRemoveSchedule = () => {
    this.props.onRemoveScheduleHandler(this.props.index);
  };

  onTimeOptionChange = (selectedTimeOption) => {
    this.setState({ selectedTimeOption }, () => {
      if (Platform.OS === "android" || Platform.OS === "web") {
        if (this.state.selectedTimeOption != 0) {
          this.onScheduleChange(null, "notTimePicker");
        } else {
          this.onScheduleChange("10:00", "timePicker");
        }
      }
    });
  };

  onTimeOptionClose = () => {
    if (this.state.selectedTimeOption != 0) {
      this.onScheduleChange(null, "notTimePicker");
    } else {
      this.onScheduleChange("10:00", "timePicker");
    }
  };

  onMinutesChange = (value) => {
    this.setState({ minutesSun: value });
  };

  onMinutesClose = () => {
    this.onScheduleChange(null, "notTimePicker");
  };

  onActionOptionChange = (value) => {
    // this.setState({ minutesSun: value });
    this.setState({ selectedActionOption: value }, () => {
      if (Platform.OS === "android" || Platform.OS === "web") {
        this.onScheduleChange(null, "action");
      }
    });
  };

  onActionOptionClose = () => {
    this.onScheduleChange(null, "action");
  };

  onScheduleChange = (sendValue, key) => {
    let newSchedule = null;
    if (key == "day") {
      let daysArray = [...this.state.daysArray];
      let indexToChange = this.state.daysArray.findIndex((el) => el.value == sendValue);

      daysArray[indexToChange].active = !daysArray[indexToChange].active;

      let newDayMask = 0;

      daysArray.forEach((el) => {
        if (el.active) {
          newDayMask |= 1 << el.value;
        }
      });
      newSchedule = this.state.schedule.set("day", newDayMask);
    } else if (key == "action") {
      let action = Number(this.state.selectedActionOption);

      newSchedule = this.state.schedule.set("action", action);
    } else if (key == "timePicker") {
      sendValue = moment(sendValue).format("HH:mm");

      let hours = Number(sendValue.split(":")[0]);
      let minutes = Number(sendValue.split(":")[1]);
      newSchedule = this.state.schedule.set("hour", hours).set("minute", minutes);
    } else if (key == "notTimePicker") {
      let hours = Number(this.state.selectedTimeOption);
      let minutes = Number(this.state.minutesSun);
      newSchedule = this.state.schedule.set("hour", hours).set("minute", minutes);
    }

    this.setState({ schedule: newSchedule }, () => {
      this.props.onScheduleChange(this.state.schedule, this.props.index);
    });
  };

  render() {
    let { schedule, daysArray, selectedTimeOption, minutesSun, selectedActionOption } = this.state;

    let timeValue = "10:00";
    if (schedule && schedule.get("hour") <= 24) {
      let hour = `${schedule.get("hour")}`.length == 1 ? `0${schedule.get("hour")}` : `${schedule.get("hour")}`;
      let minute = `${schedule.get("minute")}`.length == 1 ? `0${schedule.get("minute")}` : `${schedule.get("minute")}`;

      timeValue = `${hour}:${minute}`;
    }

    let days = daysArray.map((day, index) => {
      return (
        <TouchableOpacity
          onPress={() => this.onScheduleChange(day.value, "day")}
          key={`${index}${day}`}
          style={{
            flex: 1,
            alignItems: "center",
          }}
        >
          <PrimaryColorText
            style={{
              paddingBottom: 2,
            }}
          >
            {day.label}
          </PrimaryColorText>
          {day.active ? <StyledIcon name={"check"} color={"primary"} size={20} /> : <StyledIcon name={"x"} color={"secondary"} size={20} />}
        </TouchableOpacity>
      );
    });
    const { theme } = this.context;

    return (
      <View
        style={{
          backgroundColor: theme.COMPONENT_BACKGROUND_COLOR,
          paddingTop: 10,
          paddingHorizontal: 10,
          marginHorizontal: 3,
          marginTop: 3,

          borderRadius: 10,
        }}
      >
        <View style={styles.row}>
          <PrimaryColorText
            style={{
              fontWeight: "bold",
              marginRight: 10,
              width: 60,
            }}
          >
            {t("OPTION")}&nbsp;:
          </PrimaryColorText>

          <View
            style={{
              flex: 1,
            }}
          >
            <PickerSelect
              placeholder={{
                label: t("CHOOSE_OPTION"),

                value: null,
              }}
              containerStyle={{ width: "100%" }}
              items={this.actionOptions}
              onValueChange={this.onActionOptionChange}
              onClose={this.onActionOptionClose}
              value={selectedActionOption}
            />
          </View>
        </View>

        <View style={styles.row}>
          <PrimaryColorText
            style={{
              fontWeight: "bold",
              marginRight: 10,
              width: 60,
            }}
          >
            {t("TIME")}:
          </PrimaryColorText>
          <View style={{ flex: 1, flexDirection: "row" }}>
            <View
              style={{
                width: "47%",
                marginRight: "6%",
              }}
            >
              <PickerSelect
                placeholder={{
                  label: t("CHOOSE_OPTION"),
                  value: null,
                }}
                // containerStyle={{ width: "100%" }}
                items={this.timeOptions}
                onValueChange={this.onTimeOptionChange}
                onClose={this.onTimeOptionClose}
                value={selectedTimeOption}
              />
            </View>
            <View style={{ paddingLeft: 5, width: "47%" }}>
              {selectedTimeOption == 0 ? (
                <DateTimePicker
                  value={timeValue}
                  onConfirm={(time) => this.onScheduleChange(time, "timePicker")}
                  date={moment(timeValue, "HH:mm").toDate()}
                  mode="time"
                />
              ) : (
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <PrimaryColorText
                    style={{
                      paddingRight: 3,
                    }}
                  >
                    {t("MINUTES")}:
                  </PrimaryColorText>

                  <TextInputStyled
                    style={{
                      height: 26,
                    }}
                    placeholderTextColor={theme.TAB_BAR_TEXT_COLOR}
                    placeholder={t("MINUTES")}
                    value={minutesSun}
                    keyboardType={"numeric"}
                    onChangeText={this.onMinutesChange}
                    onEndEditing={this.onMinutesClose}
                  />
                </View>
              )}
            </View>
          </View>
        </View>

        <View style={styles.row}>
          <PrimaryColorText
            style={{
              fontWeight: "bold",
              marginRight: 10,
              width: 60,
            }}
          >
            {t("DAY")}:
          </PrimaryColorText>
          <View style={styles.days}>{days}</View>
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  days: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    flex: 1,
  },
  row: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 10,
  },

  dayCircle: {
    width: 30,
    height: 30,
    borderRadius: 15,
    margin: 2,
    // borderWidth: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
});
export default SceneScheduleSingle;
