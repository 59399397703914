import React, { useState, useEffect } from "react";
import { View, TouchableOpacity, ActivityIndicator, Text } from "react-native";
import mqttClient from "../../../services/mqtt";
import { connect } from "react-redux";
import { t } from "../../../services/i18n";

import HighChartView from "../../../utils/HighChartView";
import { barChartDataReceived, chartDataReceived } from "../../../store/actions";

import { SecondaryColorText, PrimaryColorText, StyledIcon } from "../../UI/styledComponents";
import { SkottieLoader } from "../../animatedComponents/Loader";
import HighChartDonutView from "../../../utils/HighChartDonutView";
import { useFocusEffect } from "@react-navigation/native";
import HighChartColumnView from "../../../utils/HighChartColumnView";
import Long from "long";
import moment from "moment";

const ChartComponent = (props) => {
  const {
    device,
    header,
    deviceFromCustom,
    chartDevice,
    chartDeviceState,
    deviceID,
    connectionStatus,
    gretingViewHeight,
    customWidth,
    deviceFromSketch,
    onSketchWidth,
    onSketchHeight,
    params_device,
    chartDeviceParam,
    dimensions,
    fromDetails,
    currentTimeRange,
  } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [showText, setShowText] = useState(false);

  const params =
    chartDeviceParam && chartDeviceParam.get("params") ? Long.fromString("0x" + chartDeviceParam.get("params").toString(16), 16) : new Long(0);

  useFocusEffect(() => {
    if (params.and(Long.fromString("0x100000000", 16)).isZero()) {
      if (chartDeviceState && !chartDeviceState.get("chartData")) {
        askForCharts();
      }
    } else {
      if (chartDeviceState && !chartDeviceState.get("barChartData")) {
        askForBarCharts();
      }
    }
  });

  useEffect(() => {
    if (params.and(Long.fromString("0x100000000", 16)).isZero()) {
      if (chartDeviceState && chartDeviceState.get("chartData") && chartDeviceState.get("chartData").get("List")) {
        setIsLoading(false);
        setShowText(chartDeviceState.get("chartData").get("List").size ? false : true);
      } else {
        setIsLoading(true);
      }
    } else {
      if (chartDeviceState && chartDeviceState.get("barChartData") && chartDeviceState.get("barChartData").get("data")) {
        setIsLoading(false);
        setShowText(chartDeviceState.get("barChartData").get("data").size ? false : true);
      } else {
        setIsLoading(true);
      }
    }
  }, [chartDeviceState]);

  const askForCharts = () => {
    if (params.and(Long.fromString("0x100000000", 16)).isZero()) {
      let message =
        currentTimeRange !== undefined
          ? `/api/json/device/${device.get("id")}/charts/${currentTimeRange}/0/0/0`
          : `/api/json/device/${device.get("funkcja")}/charts/${device.get("interpretacja")}`;

      setIsLoading(true);
      props.chartDataReceived(device.get("id"), null);
      mqttClient.askForCharts(message, device.get("id"));
      console.log(message);
    } else {
      askForBarCharts();
    }
  };

  const getToDate = (type) => {
    if (type == 0) {
      // HOUR
      return Math.floor(moment().format("X") / 3600) * 3600 + 3600;
    } else {
      return Math.floor(moment().format("X") / 86400) * 86400 + 86400;
    }
  };

  const askForBarCharts = () => {
    let from, to;
    let numOfSteps = 12;
    if (currentTimeRange == 2) {
      to = getToDate();
      from = to - 86400 * 7;
      numOfSteps = 7;
      // LAST WEEK
    } else if (currentTimeRange == 3) {
      to = getToDate();
      from = to - 86400 * 30;
      numOfSteps = 30;
      // LAST MONTH
    } else if (currentTimeRange == 1) {
      to = getToDate();
      to = to % 86400 == 0 ? to - 86400 : to - (to % 86400);
      from = to - 86400;
      numOfSteps = (to - from) / 3600;
    } else {
      to = getToDate();
      // from is begining of the day
      from = to % 86400 == 0 ? to - 86400 : to - (to % 86400);
      numOfSteps = (to - from) / 3600;
    }

    let message = `/api/json/device/${device.get("id")}/barCharts/${from}/${to}/${numOfSteps}`;
    console.log(message);
    setIsLoading(true);
    props.barChartDataReceived(device.get("id"), null);
    mqttClient.askForBarCharts(message, device.get("id"));
  };

  const refreshCharts = () => {
    setIsLoading(true);
    askForCharts();
  };

  useEffect(() => {
    if (chartDevice && connectionStatus == 1) {
      askForCharts();
    }
  }, [connectionStatus]);

  useEffect(() => {
    if (currentTimeRange !== undefined) {
      askForCharts();
    }
  }, [currentTimeRange]);

  let columnWidth = dimensions.get("columnWidth");
  let width = dimensions.get("width");

  if (customWidth || dimensions.get("smallTiles")) {
    columnWidth = width;
  }

  let webViewWidth = gretingViewHeight ? width / 2 - 20 : columnWidth - 20;

  let webViewHeight = gretingViewHeight ? gretingViewHeight / 2 - 20 : webViewWidth * 1.15;
  if (deviceFromSketch) {
    webViewWidth = onSketchWidth;
    webViewHeight = onSketchHeight;
  }

  return !chartDevice ? (
    <View>
      {!deviceFromCustom && !deviceFromSketch && !fromDetails && header}
      <SecondaryColorText
        style={{
          paddingLeft: deviceFromCustom ? 0 : 10,
          textAlign: deviceFromCustom ? "center" : null,
        }}
      >
        {deviceFromCustom ? "?" : t("NO_DEVICE_OF_GIVEN_ID")}
      </SecondaryColorText>
    </View>
  ) : (
    <View
      style={{
        width: "100%",
        height: gretingViewHeight ? "100%" : fromDetails ? webViewHeight - 40 : webViewHeight,
      }}
    >
      {!fromDetails && (
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            paddingBottom: 5,
            zIndex: 500,
          }}
        >
          {!deviceFromCustom && !deviceFromSketch && header}
          {!deviceFromSketch ? (
            <TouchableOpacity onPress={refreshCharts}>
              <SecondaryColorText style={{ fontStyle: "italic" }}>{t("REFRESH")}</SecondaryColorText>
            </TouchableOpacity>
          ) : (
            <TouchableOpacity onPress={refreshCharts} style={{ paddingTop: -100, position: "relative" }}>
              <StyledIcon name={"refresh-ccw"} color={"primary"} size={22} style={{ position: "absolute", top: 15, left: 10 }} />
            </TouchableOpacity>
          )}
        </View>
      )}

      {isLoading ? (
        <View
          style={{
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <SkottieLoader style={{ width: 40, height: 40, margin: 5 }} />
        </View>
      ) : showText ? (
        <View
          style={{
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <PrimaryColorText>{t("NO_DATA")}</PrimaryColorText>
        </View>
      ) : params.and(Long.fromString("0x100000000", 16)).isZero() ? (
        <HighChartView
          dimensions={{
            width: webViewWidth,
            height: webViewHeight,
          }}
          params_device={chartDeviceParam}
          timeRange={fromDetails ? currentTimeRange : device.get("interpretacja")}
          chartData={chartDeviceState ? chartDeviceState.get("chartData") : null}
          deviceType={chartDevice.get("typ_komponentu")}
          deviceId={chartDevice.get("id")}
        />
      ) : (
        <HighChartColumnView
          dimensions={{
            width: webViewWidth,
            height: webViewHeight,
          }}
          params_device={chartDeviceParam}
          timeRange={fromDetails ? currentTimeRange : device.get("interpretacja")}
          chartData={chartDeviceState ? chartDeviceState.get("barChartData") : null}
          deviceType={chartDevice.get("typ_komponentu")}
        />
      )}
    </View>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    chartDeviceParam: ownProps.fromDetails
      ? state.smartHomeData.get(state.profilesSettings.get("currentProfile")).get("params_devices").get(ownProps.device.get("id"))
      : state.smartHomeData.get(state.profilesSettings.get("currentProfile")).get("params_devices").get(ownProps.device.get("funkcja")),

    chartDeviceState: state.statesData.get("states").get(ownProps.device.get("id")),
    chartDevice: ownProps.fromDetails
      ? state.smartHomeData.get(state.profilesSettings.get("currentProfile")).get("devices").get(ownProps.device.get("id"))
      : state.smartHomeData.get(state.profilesSettings.get("currentProfile")).get("devices").get(ownProps.device.get("funkcja")),
    connectionStatus: state.statesData.get("connectionStatus"),
    noConnectionBarVisible: state.statesData.get("noConnectionBarVisible"),
    dimensions: state.statesData.get("dimensions"),
    noConnectionCriticalError: state.statesData.get("noConnectionCriticalError"),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    chartDataReceived: (deviceID, reset) => dispatch(chartDataReceived(deviceID, reset)),
    barChartDataReceived: (deviceID, reset) => dispatch(barChartDataReceived(deviceID, reset)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChartComponent);
