import React, { Component } from "react";
import {
  Text,
  View,
  Animated,
  StyleSheet,
  TouchableOpacity,
} from "react-native";
import { ScreenView } from "../../../components/UI/screenViewX";
import {
  NoDataScreen,
  ButtonPrimary,
} from "../../../components/UI/styledComponents";
import SceneScheduleSingle from "./SceneScheduleSingle";
import { t } from "../../../services/i18n";

class Schedule_SceneDetailsScreen extends Component {
  state = {
    hours: 0,
    minutes: 0,
    editMode: false,
    animationOpacity: new Animated.Value(0),
    animationMargin: new Animated.Value(-42),
  };

  toggleOnPressMode = () => {
    let _moveRight = () => {
      Animated.timing(this.state.animationOpacity, {
        toValue: 1,
        duration: 500,
      }).start();

      Animated.timing(this.state.animationMargin, {
        toValue: 0,
        duration: 500,
      }).start();
    };

    let _moveBack = () => {
      Animated.timing(this.state.animationOpacity, {
        toValue: 0,
        duration: 500,
      }).start();

      Animated.timing(this.state.animationMargin, {
        toValue: -42,
        duration: 500,
      }).start();
    };
    let newEditMode = !this.state.editMode;
    this.setState({ editMode: newEditMode }, () => {
      if (this.state.editMode) {
        _moveRight();
      } else {
        _moveBack();
      }
    });
  };

  onRemoveSchedule = (scheduleIndex) => {
    let schedules = this.props.schedules;

    schedules = schedules.delete(scheduleIndex);
    this.props.scheduleChange(schedules);
  };

  onScheduleChange = (schedule, index) => {
    let schedules = this.props.schedules.set(index, schedule);
    this.props.scheduleChange(schedules);
  };

  render() {
    const { editMode } = this.state;
    const { schedules } = this.props;

    const harmonograms =
      schedules &&
      schedules.map((schedule, index) => {
        let dayMask = schedule.get("days");
        let hour = schedule.get("hour");
        let minute = schedule.get("minute");

        return (
          <View
            key={`${index}${dayMask}${hour}${minute}`}
            style={{
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Animated.View style={[{ opacity: this.state.animationOpacity }]}>
              <TouchableOpacity onPress={() => this.onRemoveSchedule(index)}>
                <View style={[styles.removeButton]}>
                  <Text
                    style={{
                      color: "white",
                      marginLeft: 1,
                      lineHeight: 16,
                      fontWeight: "bold",
                      fontSize: 10,
                    }}
                  >
                    —
                  </Text>
                </View>
              </TouchableOpacity>
            </Animated.View>

            <Animated.View
              style={{
                marginLeft: this.state.animationMargin,
                width: "100%",
              }}
            >
              <SceneScheduleSingle
                index={index}
                schedule={schedule}
                onScheduleChange={this.onScheduleChange}
              />
            </Animated.View>
          </View>
        );
      });
    return schedules ? (
      <ScreenView>
        <View style={{ width: "100%" }}>
          {harmonograms}
          {schedules && schedules.size > 0 ? (
            <ButtonPrimary
              style={{
                marginVertical: 10,
                backgroundColor: editMode ? "rgb(40,125,40)" : "rgb(200,00,0)",
                alignSelf: "center",
              }}
              onPress={this.toggleOnPressMode}
            >
              {editMode ? t("DONE") : t("DELETING")}
            </ButtonPrimary>
          ) : null}
        </View>
      </ScreenView>
    ) : (
      <NoDataScreen />
    );
  }
}

const styles = StyleSheet.create({
  removeButton: {
    width: 18,
    height: 18,
    backgroundColor: "rgb(200,00,0)",
    borderRadius: 9,
    // display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginHorizontal: 12,
  },
});

export default Schedule_SceneDetailsScreen;
