import * as config from "./config/i18n";

const translationLoader = {
	type: "backend",
	init: () => {},
	read: function (language, namespace, callback) {
		let resource,
			error = null;

		try {
			let supportedLocale = config.supportedLocales[language]
				? config.supportedLocales[language]
				: config.supportedLocales[config.fallback];
			resource = supportedLocale.translationFileLoader()[namespace];
		} catch (_error) {
			console.log(_error, "_error");
			// error = _error;
		}

		callback(null, resource);
		//jak było z wysyłaniem errora to mi przychodziła na sentry
		// callback(error, resource);
	},
};

export default translationLoader;
