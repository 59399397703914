import React, { useContext } from "react";
import { Text, View, TouchableOpacity, Pressable, Keyboard, Platform } from "react-native";
import Modal from "react-native-modal";
import { Feather } from "@expo/vector-icons";
import { useTheme } from "../../../ThemeContext";
import WebModal from "modal-enhanced-react-native-web";
import { ButtonPrimary } from "./styledComponents";
import { t } from "../../services/i18n";

const ModalWithContent = (props) => {
  const { theme } = useTheme();

  const { isVisible, title, onClose, overwriteTheme, modalHeight, error } = props;
  let backgroundColor = overwriteTheme ? "white" : theme.COMPONENT_BACKGROUND_COLOR;
  let colorText = overwriteTheme ? "black" : theme.TEXT_COLOR_PRIMARY;

  const content = error ? (
    <View
      style={{
        backgroundColor: theme.COMPONENT_BACKGROUND_COLOR,
        justifyContent: "space-between",
        borderRadius: 10,
        width: "90%",
        maxWidth: 600,
      }}
    >
      <Text
        style={{
          fontSize: 18,
          fontWeight: "bold",
          paddingTop: 15,
          color: theme.TEXT_COLOR_PRIMARY,
          textAlign: "center",
        }}
      >
        {error}
      </Text>
      <ButtonPrimary
        style={{
          margin: 15,
        }}
        onPress={onClose}
      >
        {t("CLOSE")}
      </ButtonPrimary>
    </View>
  ) : (
    <View
      style={{
        backgroundColor: backgroundColor,
        borderRadius: 10,
        width: "100%",
        height: modalHeight,
        maxHeight: "80%",
        paddingVertical: 8,
        paddingHorizontal: 14,
        maxWidth: 600,
      }}
    >
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          paddingBottom: 6,
        }}
      >
        <Text
          style={{
            fontSize: 18,
            fontWeight: "bold",
            paddingVertical: 5,
            color: colorText,
          }}
        >
          {title}
        </Text>

        <TouchableOpacity style={{ padding: 5 }} onPress={onClose}>
          <Feather name={"x"} size={18} color={colorText} />
        </TouchableOpacity>
      </View>

      {modalHeight ? (
        <View
          style={{
            justifyContent: "center",
            height: "90%",
          }}
        >
          {props.children}
        </View>
      ) : (
        props.children
      )}
    </View>
  );

  return isVisible ? (
    Platform.OS == "web" ? (
      <WebModal animationType="slide" transparent={true} visible={isVisible} style={{ margin: 0 }}>
        <View
          style={{
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {content}
        </View>
      </WebModal>
    ) : (
      <Pressable onPress={Keyboard.dismiss} accessible={false}>
        <Modal isVisible={isVisible} style={{ alignItems: "center" }}>
          {content}
        </Modal>
      </Pressable>
    )
  ) : null;
};

export default ModalWithContent;
