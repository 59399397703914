import React, { Component } from "react";
import { Text, View, TouchableOpacity, ScrollView } from "react-native";
import { connect } from "react-redux";
import { Feather } from "@expo/vector-icons";

import { t } from "../../services/i18n";

import {
	PrimaryColorText,
	ButtonPrimary,
} from "../../components/UI/styledComponents";
import { devicesForGroups } from "../../utils";
// import PickerSelectObjects from "../../components/UI/PickerSelectObjects";
// import MultiplePickerSelectObjects from "./MultiplePickerSelectObjects";
import ModalWithContent from "../../components/UI/ModalWithContent";
import PickerSelect from "../../components/UI/PickerSelect";
import { useTheme, ThemeContext } from "../../../ThemeContext";

class AddActionModal extends Component {
	static contextType = ThemeContext;

	state = {
		devicesArray: [],
		buttonAddDisabled: true,
	};

	componentDidMount() {
		let devicesArray = this.setDevices();
		this.setState({ devicesArray });
	}
	onAddHandler = () => {
		let devicesArray = this.state.devicesArray
			.filter((el) => el.chosen == true)
			.map((el) => el.value);

		this.props.onAdd(devicesArray);
	};

	setDevices = () => {
		const { devices } = this.props;
		let devicesToSend = [];

		devices.map((device) => {
			if (
				device &&
				["flaga", "flaga_l", "flaga_p", "przekaznik"].includes(
					device.get("typ_komponentu")
				)
			) {
				devicesToSend.push({
					label: device.get("opis_menu"),
					value: device.get("id"),
					chosen: false,
				});
			}
		});

		if (devicesToSend.length > 1) {
			devicesToSend.push({
				label: t("scenes:ALL"),
				value: "ALL",
				chosen: false,
			});
		}

		//dodatkowa grupa

		return devicesToSend;
	};

	itemSelected = (el) => {
		let newArray = this.state.devicesArray;

		if (el == "ALL") {
			let item = newArray[newArray.length - 1];
			item.chosen = !item.chosen;

			newArray.map((el) => {
				el.chosen = item.chosen;
				return el;
			});
		} else {
			let indexToChange = newArray.findIndex((item) => {
				return item.value == el;
			});
			newArray[indexToChange].chosen = !newArray[indexToChange].chosen;
		}
		this.setState({
			devicesArray: newArray,
			buttonAddDisabled: newArray.some((el) => el.chosen == true)
				? false
				: true,
		});
	};

	render() {
		const { isVisible, onClose, groups, devices, group_devices } = this.props;
		const { buttonAddDisabled, devicesArray } = this.state;
		const { theme } = this.context;
		// let [deviceArray, setDeviceArray] = useState(items);
		const textColor = theme.TEXT_COLOR_PRIMARY;
		const textColorSecondary = theme.TEXT_COLOR_SECONDARY;
		const inputBackgroundColor = theme.APP_BACKGROUND_COLOR;
		const borderColor = theme.PLACEHOLDER_BACKGROUND_COLOR;
		let content = (
			<ScrollView style={{ width: "100%" }}>
				{devicesArray.map((item, index) => {
					return (
						<TouchableOpacity
							style={{
								padding: 10,
								flexDirection: "row",
								flex: 1,
								alignItems: "center",
								// justifyContent: "space-between",
							}}
							key={`${item.value}${index}`}
							onPress={() => this.itemSelected(item.value)}
						>
							<Feather
								name={item.chosen ? "check-circle" : "circle"}
								color={item.chosen ? "rgb(50, 200, 80)" : "#ccc"}
								size={22}
							/>
							<Text style={{ color: textColor, marginLeft: 10 }}>
								{item.label}
							</Text>
						</TouchableOpacity>
					);
				})}
			</ScrollView>
		);
		return (
			<ModalWithContent
				isVisible={isVisible}
				title={t("ADD_OBJECT")}
				onClose={onClose}
			>
				{content}

				<ButtonPrimary disabled={buttonAddDisabled} onPress={this.onAddHandler}>
					{t("ADD")}
				</ButtonPrimary>
			</ModalWithContent>
		);
	}
}
const mapStateToProps = (state) => {
	let currentProfile = state.profilesSettings.get("currentProfile");
	return {
		devices: state.smartHomeData.get(currentProfile).get("devices"),
	};
};

export default connect(mapStateToProps)(AddActionModal);
