import { mediaDevices, MediaStream, RTCIceCandidate, RTCPeerConnection, RTCSessionDescription, RTCView } from "react-native-webrtc-web-shim";
import React from "react";
import { connect } from "react-redux";
import { Audio } from "expo-av";
import { ActivityIndicator, AppState, View, ImageBackground, Platform, PermissionsAndroid } from "react-native";
import { store } from "../../store/configureStore";
import Janus from "../../services/janus/Janus";
import JanusPlugin from "../../services/janus/utils/JanusPlugin";
import mqttClient from "../../services/mqtt";
import { CameraUniversalComponent } from "../../components/smartHomeComponents";
import { t } from "../../services/i18n";
import { ButtonPrimary, PrimaryColorText } from "../../components/UI/styledComponents";
import { ThemeContext } from "../../../ThemeContext";
import { min } from "moment";
import { setDuringTalk, changeInitCalls, changeAnsweredCall, changePopupObjectId } from "../../store/actions";
import { SkottieLoader } from "../../components/animatedComponents/Loader";
Janus.setDependencies({
  RTCPeerConnection,
  RTCSessionDescription,
  RTCIceCandidate,
  MediaStream,
});
import { useIsFocused } from "@react-navigation/native";

class JanusSipComponentX extends React.Component {
  static contextType = ThemeContext;
  constructor(props) {
    super(props);
    this.janus = null;
    this.sipPlugin = null;
    this.mediaStream = null;
    this.dtmfSender = null;

    try {
      if (this.props.params_device.get("url")) {
        this.sipData = JSON.parse(this.props.params_device.get("url").trim());
        if (this.sipData) {
          if (!this.sipData.username.startsWith("sip:")) this.sipData.username = "sip:" + this.sipData.username;

          if (!this.sipData.callee.startsWith("sip:")) this.sipData.callee = "sip:" + this.sipData.callee;
        }
      }
    } catch (e) {
      console.log("Constructor error", e);
    }
    this.state = {
      stream: null,
      videoStream: null,
      isCalling: false,
      appState: AppState.currentState,
      sipConnected: false,
      noPermissions: null,
      audioPerm: null,
      callPerm: null,
      callMade: false,
    };
  }

  getMediaStream = async (AudioPerm, janus) => {
    if (!janus || AppState.currentState !== "active") return;

    if (AudioPerm && AudioPerm.status === "granted") {
      let stream = await mediaDevices.getUserMedia({
        audio: { echoCancellation: true },
        video: false,
      });
      this.mediaStream = stream;
      await this.initJanus(stream, janus);
    } else {
      this.setState({
        noPermissions: true,
      });
    }
  };

  open = async () => {
    let message = `/api/set/${this.sipData.obj}/setValue/255/${this.sipData.objTime}`;
    mqttClient.stateChangeToSend(message, this.sipData.obj);
  };

  hangUp = async () => {
    this.props.changePopupObjectId(null);
    this.props.changeAnsweredCall(null);
    this.props.setDuringTalk(null);

    if (this.sipPlugin) {
      try {
        const startResponse = await this.sipPlugin.sendAsync({
          request: "hangup",
        });
      } catch (e) {
        console.log("Error on hangup", e);
        this.setState({
          stream: null,
          sipConnected: false,
          isCalling: false,
          isBusy: false,
        });
        this.getMediaStream(this.state.audioPerm, this.props.janus);
      }
    } else {
      this.setState({
        stream: null,
        sipConnected: false,
        isCalling: false,
        isBusy: false,
      });
    }
  };

  makeCall = async () => {
    this.props.setDuringTalk(this.props.device.get("id"));

    let offer = await this.sipPlugin.pc.createOffer({
      media: {
        audioSend: true,
        audioRecv: true,
        videoSend: true,
        videoRecv: true,
      },
    });
    if (offer && offer.sdp && offer.type) {
      await this.sipPlugin.pc.setLocalDescription(offer);

      const startResponse = await this.sipPlugin.sendAsyncWithJsep(
        {
          request: "call",
          uri: this.sipData.callee,
        },
        { sdp: offer.sdp, type: offer.type }
      );
    }
  };

  initJanus = async (stream, janus) => {
    this.setState((state) => ({
      sipConnected: false,
    }));
    let foundPluginOnList = null;

    if (janus && janus.socket && janus.socket.plugins) {
      const plugins = Object.values(janus.socket.plugins);
      foundPluginOnList = plugins.find((plugin) => plugin && plugin.objectId === this.props.device.get("id"));
      if (foundPluginOnList) {
        if (foundPluginOnList) {
          if (foundPluginOnList && foundPluginOnList.pc) {
            foundPluginOnList.pc.close();
          }
          try {
            foundPluginOnList.detach();
          } catch (e) {
            console.log("Error on detach", e);
            throw e;
          }
        }
      }
    }

    try {
      this.janus = janus ? janus : this.props.janus;

      if (this.janus && this.sipData && this.sipData.username && this.sipData.password) {
        if (this.sipPlugin) {
          if (this.sipPlugin.pc) {
            this.sipPlugin.pc.close();
          }
          try {
            this.sipPlugin.detach();
          } catch (e) {
            console.log("Error on detach SIP", e);
            throw e;
            //
          }
        }
        this.sipPlugin = new JanusPlugin("janus.plugin.sip", this.janus, this.props.device.get("id"));

        this.sipPlugin.onMessage = async (message) => {
          try {
            if (
              message &&
              message.plugindata &&
              message.plugindata.data &&
              message.plugindata.data.result &&
              message.plugindata.data.result.event == "hangup"
            ) {
              if (message.plugindata.data.result.code == 503 || message.plugindata.data.result.code == 486) {
                this.setState({ isBusy: true });
                setTimeout(() => {
                  this.setState({ isBusy: false });
                }, 5000);
              } else if (this.state.isCalling) {
                this.deinitJanus(this.state.appState);
                let stream = await mediaDevices.getUserMedia({
                  audio: { echoCancellation: true },
                  video: false,
                });
                this.mediaStream = stream;
                await this.initJanus(stream, this.props.janus);
              }
            } else if (
              message &&
              message.plugindata &&
              message.plugindata.data &&
              message.plugindata.data.result &&
              message.plugindata.data.result.event == "ringing"
            ) {
              this.setState((state) => ({
                isCalling: true,
              }));
            } else if (
              message &&
              message.plugindata &&
              message.plugindata.data &&
              message.plugindata.data.result &&
              message.plugindata.data.result.event == "registered"
            ) {
              this.setState((state) => ({
                sipConnected: true,
              }));

              stream.getTracks().forEach((track) => {
                this.sipPlugin.pc.addTrack(track, stream);
              });

              // if (this.props.answeredCall && this.props.popup && !this.state.callMade) {
              //   RNCallKeep.endCall(this.props.answeredCall.uuid);
              //   InCallManager.stopRingtone();
              //   if (Number(this.props.answeredCall.objectId) === this.props.device.get("id")) {
              //     this.props.setDuringTalk(this.props.answeredCall.objectId);
              //     this.makeCall();

              //     this.setState((state) => ({
              //       callMade: true,
              //     }));
              //   }
              // } else {

              // }

              if (!this.props.answeredCall) {
                this.props.setDuringTalk(null);
              }
            } else if (
              message &&
              message.plugindata &&
              message.plugindata.data &&
              message.plugindata.data.result &&
              message.plugindata.data.result.event == "incomingcall"
            ) {
              setTimeout(async () => {
                try {
                  const startResponse = await this.sipPlugin.sendAsync({
                    request: "decline",
                    code: 480,
                  });
                } catch (e) {
                  console.log("Error on decline", e);
                }
              }, 1);
            } else if (
              message &&
              message.jsep &&
              message.plugindata &&
              message.plugindata.data &&
              message.plugindata.data.result &&
              (message.plugindata.data.result.event == "progress" || message.plugindata.data.result.event == "accepted")
            ) {
              this.setState((state) => ({
                isCalling: true,
              }));
              await this.sipPlugin.pc.setRemoteDescription(
                new Janus.RTCSessionDescription({
                  sdp: message.jsep.sdp,
                  type: message.jsep.type,
                })
              );
            }
          } catch (e) {
            console.log("onMessage janus sip error in call", e);
          }
        };
        this.sipPlugin.setOnStreamListener((stream, reconnect) => {
          this.setState((state) => ({
            stream: stream,
          }));
          if (reconnect) {
            this.getMediaStream(this.state.audioPerm, this.props.janus);
          }
        });

        this.sipPlugin.setOnWebRTCUpListener(async () => {
          console.log("RTCUp Listener");
        });
        await this.sipPlugin.createPeer();
        await this.sipPlugin.connect();

        const createStreamingResponse = await this.sipPlugin.sendAsync({
          request: "register",
          username: this.sipData.username,
          secret: this.sipData.password,
        });
      }
    } catch (e) {
      let err = this.janus ? this.janus.connectionID : "no janus";
      console.log("SIP Component janus init ", err, JSON.stringify(e));
    }
  };

  requestPermissions = async () => {
    try {
      const granted = await PermissionsAndroid.request(PermissionsAndroid.PERMISSIONS.RECORD_AUDIO, {
        title: t("AMPIO_NEEDS_ACCESS_TO_YOUR_MICROPHONE"),
        message: t("AMPIO_NEEDS_ACCESS_TO_YOUR_MICROPHONE_TO_MAKE_CALLS"),
        buttonNegative: "Cancel",
        buttonPositive: "OK",
      });
      if (granted === PermissionsAndroid.RESULTS.GRANTED) {
        let AudioPerm = { status: "granted" };
        this.proccessIfGranted(AudioPerm);
      } else {
        console.log("Camera permission denied");
      }
    } catch (err) {
      console.warn(err);
    }
  };

  proccessIfGranted = async (AudioPerm, skipCallPermissions) => {
    if (!skipCallPermissions) {
      await this.getMediaStream(AudioPerm, this.props.janus);
    }

    if (AudioPerm.status === "granted") {
      if (Platform.OS === "android") {
        const perm = await PermissionsAndroid.check(PermissionsAndroid.PERMISSIONS.CALL_PHONE);
        if (!perm) {
          const granted = await PermissionsAndroid.request(PermissionsAndroid.PERMISSIONS.CALL_PHONE, {
            title: t("AMPIO_NEEDS_ACCESS_TO_YOUR_MICROPHONE"),
            message: t("AMPIO_NEEDS_ACCESS_TO_YOUR_MICROPHONE_TO_MAKE_CALLS"),
            buttonNegative: "Cancel",
            buttonPositive: "OK",
          });
          if (granted === PermissionsAndroid.RESULTS.GRANTED) {
            if (!this.props.initCalls) {
              this.props.changeInitCalls(true);
            }
          } else {
            const permission = await PermissionsAndroid.check(PermissionsAndroid.PERMISSIONS.RECORD_AUDIO);
            if (permission) {
              this.setState({ audioPerm: { status: "granted" }, noPermissions: false });
            } else {
              this.setState({ audioPerm: { status: "ask" } });
            }
          }
        } else {
          const permission = await PermissionsAndroid.check(PermissionsAndroid.PERMISSIONS.RECORD_AUDIO);
          if (permission) {
            this.setState({ audioPerm: { status: "granted" }, noPermissions: false });
          } else {
            this.setState({ audioPerm: { status: "ask" } });
          }
        }
      } else if (Platform.OS === "ios" && !this.props.initCalls) {
        this.props.changeInitCalls(true);
        setupCalls();
      }
    }
  };

  componentDidUpdate(prevProps, prevState) {}

  async componentDidMount() {
    this.appStateSubscription = AppState.addEventListener("change", this._handleAppStateChange);
    let AudioPerm = null;
    if (Platform.OS === "android") {
      const permission = await PermissionsAndroid.check(PermissionsAndroid.PERMISSIONS.RECORD_AUDIO);

      if (permission) {
        AudioPerm = { status: "granted" };
      } else {
        AudioPerm = { status: "ask" };
      }
    } else if (Platform.OS === "web") {
      const mediaDevices = navigator.mediaDevices;
      if (mediaDevices) {
        try {
          const stream = await mediaDevices.getUserMedia({ audio: true });
          console.log("Microphone access granted!", stream);
          AudioPerm = { status: "granted" };
        } catch (err) {
          console.error("Microphone access denied:", err);
          AudioPerm = { status: "ask" };
        }
      } else {
        AudioPerm = { status: "notInPreview" };
        console.error("Media devices not supported in this browser", navigator);
      }
    } else {
      AudioPerm = await Audio.requestPermissionsAsync();
    }

    console.log("AudioPerm", AudioPerm);

    this.setState({ audioPerm: AudioPerm });

    if (AudioPerm.status === "granted") {
      this.proccessIfGranted(AudioPerm);
    }

    //displayIncomingCall("Ampio", "5124279000");
  }

  componentWillUnmount = async () => {
    this.appStateSubscription.remove();
    this.deinitJanus("background");
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.janus == null && nextProps.janus) {
      this.getMediaStream(this.state.audioPerm, nextProps.janus);
    } else if (this.props.janus && nextProps.janus && this.props.janus.connectionID !== nextProps.janus.connectionID) {
      this.getMediaStream(this.state.audioPerm, nextProps.janus);
    }
  }

  deinitJanus = async (nextAppState, onlySip) => {
    if (this.janus && this.janus.connectionID === store.getState().statesData.get("janusId")) {
      if (this.mediaStream) {
        this.mediaStream.getTracks().forEach((track) => track.stop());
      }
      if (this.state.stream) {
        this.state.stream.getTracks().forEach((track) => track.stop());
      }

      if (this.sipPlugin && this.sipPlugin.pc) {
        this.sipPlugin.pc.close();
      }
      if (this.sipPlugin && !onlySip) {
        this.sipPlugin.detach();
        this.sipPlugin = null;
      }

      this.setState({
        appState: nextAppState,
        stream: null,
        sipConnected: false,
        isCalling: false,
        isBusy: false,
      });
    }
  };

  _handleAppStateChange = async (nextAppState) => {
    if (this.state.appState === "background" && nextAppState === "active") {
      this.setState({ appState: nextAppState });
      await this.getMediaStream(this.state.audioPerm, this.props.janus);
    } else if (this.state.appState === "active" && nextAppState == "background") {
      await this.deinitJanus(nextAppState);
    }
  };

  render() {
    const { device, header, deviceState, currentConnection, currentTheme, popup, duringTalk, popupObjectId, answeredCall } = this.props;
    const { theme } = this.context;
    const { noPermissions, audioPerm, callPerm } = this.state;
    const onlyAudio = this.sipData && (this.sipData.onlyAudio || this.sipData.rtsp) ? true : false;

    const popupVisibleWithId = popupObjectId ? Number(popupObjectId.get("id")) : -1;
    const sameObjectInPopup = !popup && popupVisibleWithId === Number(device.get("id"));
    return AppState.currentState === "active" && this.props.isFocused && !sameObjectInPopup && this.props.janus ? (
      <View>
        {this.sipData && this.sipData.rtsp ? <CameraUniversalComponent {...this.props} fromSip /> : header}
        <View
          style={{
            justifyContent: "center",
            alignItems: "center",
            paddingTop: 10,
            height: popup ? 50 : undefined,
          }}
        >
          <View
            style={[
              {
                backgroundColor: theme.COMPONENT_BACKGROUND_COLOR,
                flexDirection: "column",
                justifyContent: "center",
                alignContent: "center",
                width: "100%",
                flex: 1,
              },
              this.props.style,
            ]}
          >
            {this.state.stream ? (
              <RTCView
                style={{
                  marginTop: onlyAudio ? 0 : 10,
                  height: 1,
                }}
                stream={Platform.OS === "web" ? this.state.stream : null}
              />
            ) : null}

            {callPerm && callPerm.status !== "ask" && (
              <View
                style={[
                  {
                    flexDirection: "row",
                    alignItems: "space-between",
                    justifyContent: "center",
                    marginTop: 10,
                    width: "100%",
                  },
                ]}
              >
                <ButtonPrimary onPress={this.requestPermissions}>{t("REQUEST_CALL_PERMISSIONS")}</ButtonPrimary>
              </View>
            )}

            {this.state.sipConnected ? (
              <View
                style={[
                  {
                    flexDirection: "row",
                    alignItems: "space-between",
                    justifyContent: "center",
                    marginTop: 10,
                    width: "100%",
                  },
                ]}
              >
                <View>
                  {!this.state.stream ? (
                    this.state.isCalling ? (
                      <ButtonPrimary onPress={this.hangUp}>{t("CALLING")}</ButtonPrimary>
                    ) : (
                      <ButtonPrimary onPress={this.makeCall}>{this.state.isBusy ? t("BUSY") : t("CALL")}</ButtonPrimary>
                    )
                  ) : null}
                  {this.state.stream ? (
                    <View>
                      <ButtonPrimary onPress={this.hangUp}>{t("HANGUP")}</ButtonPrimary>
                    </View>
                  ) : null}
                </View>

                {this.sipData.objTime && this.sipData.obj ? (
                  <View
                    style={[
                      {
                        marginLeft: 10,
                      },
                    ]}
                  >
                    <ButtonPrimary onPress={this.open}>{t("OPEN")}</ButtonPrimary>
                  </View>
                ) : null}
              </View>
            ) : (
              <View
                style={[
                  {
                    flexDirection: "row",
                    alignItems: "space-between",
                    justifyContent: "center",
                    marginTop: 10,
                    width: "100%",
                  },
                ]}
              >
                {noPermissions || currentConnection === "cloud" || (audioPerm && audioPerm.status !== "granted") ? (
                  <PrimaryColorText style={{ paddingBottom: 4 }}>
                    {audioPerm && audioPerm.status === "ask" ? (
                      <ButtonPrimary onPress={this.requestPermissions}>{t("REQUEST_MICROPHONE_PERMISSIONS")}</ButtonPrimary>
                    ) : audioPerm && audioPerm.status === "notInPreview" ? (
                      t("NOT_AVAILABLE_IN_PREVIEW")
                    ) : noPermissions ? (
                      t("NO_PERMISSIONS_TO_MICROPHONE")
                    ) : (
                      t("ONLY_WORKS_USING_CLOUD_AMPIO_COM")
                    )}
                  </PrimaryColorText>
                ) : (
                  <SkottieLoader style={{ width: 40, height: 40, margin: 5, marginTop: 10 }} />
                )}
              </View>
            )}
          </View>
        </View>
      </View>
    ) : currentConnection === "cloud" ? (
      <PrimaryColorText style={{ paddingBottom: 4 }}>
        {audioPerm && audioPerm.status === "ask" ? (
          <ButtonPrimary onPress={this.requestPermissions}>{t("REQUEST_MICROPHONE_PERMISSIONS")}</ButtonPrimary>
        ) : noPermissions ? (
          t("NO_PERMISSIONS_TO_MICROPHONE")
        ) : (
          t("ONLY_WORKS_USING_CLOUD_AMPIO_COM")
        )}
      </PrimaryColorText>
    ) : (
      <SkottieLoader style={{ width: 40, height: 40, margin: 5, marginTop: 10 }} />
    );
  }
}

const mapStateToProps = (state) => {
  let currentProfile = state.profilesSettings.get("currentProfile");

  return {
    currentConnection: state.statesData.get("currentConnection"),
    answeredCall: state.statesData.get("answeredCall"),
    device_id: state.profilesSettings.get(currentProfile).get("loginData").get("device_id"),
    janus: state.statesData.get("janus"),
    dimensions: state.statesData.get("dimensions"),
    authData: state.cloudData ? state.cloudData.get("authData") : null,
    server: state.statesData.get("server"),
    popupObjectId: state.statesData ? state.statesData.get("popupObjectId") : null,
    currentTheme: state.globalSettings.get("primaryTheme"),
    initCalls: state.globalSettings.get("initCalls"),
    duringTalk: state.statesData.get("duringTalk"),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setDuringTalk: (value) => dispatch(setDuringTalk(value)),
    changeInitCalls: (value) => dispatch(changeInitCalls(value)),
    changeAnsweredCall: (value) => dispatch(changeAnsweredCall(value)),
    changePopupObjectId: (value) => dispatch(changePopupObjectId(value)),
  };
};

const JanusSipComponent = (props) => {
  const isFocused = useIsFocused();

  return <JanusSipComponentX {...props} isFocused={isFocused} />;
};

export default connect(mapStateToProps, mapDispatchToProps)(JanusSipComponent);
