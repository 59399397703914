import React, { Component, useEffect, useState } from "react";

import { View, Text, StyleSheet, TouchableOpacity, Animated, Platform } from "react-native";
import { ScreenView } from "../../components/UI/screenViewX";
import {
  PrimaryColorText,
  ComponentContainer,
  StyledIcon,
  ButtonPrimary,
  TextInputStyled,
  SecondaryColorText,
  SwitchStyled,
  SliderStyled,
} from "../../components/UI/styledComponents";
import { connect } from "react-redux";

import PickerSelect from "../../components/UI/PickerSelect";

import DateTimePicker from "../../components/UI/dateTimePicker";
import moment from "moment";
import { useSelector } from "react-redux";

import { t } from "../../services/i18n";

import RainTimeComponent from "./RainTimeComponent";

let hrs = [];
for (let i = 0; i <= 24; i++) {
  let label = i % 2 == 0 ? i / 2 : (i + 1) / 2 - 1 + ",5";
  // if (label < 10) {
  // 	label = "0" + label;
  // }
  let value = i / 2;
  let element = {
    label: label.toString(),
    value: value * 60,
  };
  hrs.push(element);
}

const RainComponent = ({ condition }) => {
  const device = useSelector((state) =>
    state.smartHomeData.get(state.profilesSettings.get("currentProfile")).get("devices").get(condition.object_id)
  );

  const deviceState = useSelector((state) => state.statesData.get("states").get(condition.object_id));
  return (
    <ComponentContainer
      containerStyle={{ width: "100%" }}
      style={{
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <PrimaryColorText>{t("conditions:RAIN_SENSOR")}: </PrimaryColorText>
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <PrimaryColorText>{device ? device.get("opis_menu") : null}</PrimaryColorText>
        {deviceState ? <SecondaryColorText> ({deviceState.get("state") == 0 ? "OFF" : "ON"})</SecondaryColorText> : null}
      </View>
    </ComponentContainer>
  );
};

const HumidityComponent = ({ condition, modifyCondition, index }) => {
  let [humidity, setHumidity] = useState(condition.value);

  const device = useSelector((state) =>
    state.smartHomeData.get(state.profilesSettings.get("currentProfile")).get("devices").get(condition.object_id)
  );

  const deviceState = useSelector((state) => state.statesData.get("states").get(condition.object_id));

  const onSlidingChangeHandler = (value) => {};

  const onSlidingCompleteHandler = (value) => {
    setHumidity(value);
    let conditionToSave = { ...condition, value };
    modifyCondition(conditionToSave, index);
  };

  return (
    <ComponentContainer containerStyle={{ width: "100%" }}>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <PrimaryColorText>{device ? device.get("opis_menu") : null}</PrimaryColorText>

        <PrimaryColorText>{humidity} %</PrimaryColorText>
      </View>

      <View
        style={{
          width: "100%",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <SliderStyled
          value={humidity}
          step={1}
          minimumValue={0}
          maximumValue={100}
          onValueChange={onSlidingChangeHandler}
          onSlidingComplete={onSlidingCompleteHandler}
        />
      </View>
    </ComponentContainer>
  );
};
const TempComponent = ({ condition, modifyCondition, index }) => {
  const device = useSelector((state) =>
    state.smartHomeData.get(state.profilesSettings.get("currentProfile")).get("devices").get(condition.object_id)
  );

  const deviceState = useSelector((state) => state.statesData.get("states").get(condition.object_id));
  let [temp, setTemp] = useState((condition.value / 10).toString());

  const onEndEditing = () => {
    let conditionToSave = { ...condition, value: temp * 10 };
    modifyCondition(conditionToSave, index);
  };
  return (
    <ComponentContainer containerStyle={{ width: "100%" }}>
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          paddingBottom: 10,
          justifyContent: "space-between",
        }}
      >
        <PrimaryColorText>{t("conditions:TEMP_SENSOR")}: </PrimaryColorText>
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <PrimaryColorText>{device ? device.get("opis_menu") : null}</PrimaryColorText>
          {deviceState ? <SecondaryColorText> ({deviceState.get("state")}&deg;C)</SecondaryColorText> : null}
        </View>
      </View>
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <PrimaryColorText>{t("conditions:TEMP_MIN")}: </PrimaryColorText>
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <TextInputStyled
            style={{ width: 70, borderRadius: 3 }}
            value={temp}
            onChangeText={(value) => setTemp(value)}
            keyboardType={"numeric"}
            onEndEditing={onEndEditing}
          />
          <PrimaryColorText> &deg;C</PrimaryColorText>
        </View>
      </View>
    </ComponentContainer>
  );
};
class DaysComponent extends Component {
  state = {
    daysArray: [
      {
        value: 1,
        label: t("weekdays:MON"),
        active: false,
      },
      {
        value: 2,
        label: t("weekdays:TUE"),
        active: false,
      },
      {
        value: 3,
        label: t("weekdays:WED"),
        active: false,
      },
      {
        value: 4,
        label: t("weekdays:THU"),
        active: false,
      },
      {
        value: 5,
        label: t("weekdays:FRI"),
        active: false,
      },
      {
        value: 6,
        label: t("weekdays:SAT"),
        active: false,
      },
      {
        value: 0,
        label: t("weekdays:SUN"),
        active: false,
      },
    ],
  };

  componentDidMount() {
    const { condition, modifyCondition, index } = this.props;

    let newDaysArray = this.state.daysArray.map((day, index) => {
      day.active = condition.value & (1 << day.value) ? true : false;
      return day;
    });

    this.setState({ daysArray: newDaysArray });
  }

  onScheduleChange = (sendValue) => {
    const { condition, modifyCondition, index } = this.props;

    const { daysArray } = this.state;
    let newDaysArray = [...daysArray];

    let indexToChange = daysArray.findIndex((el) => el.value == sendValue);

    newDaysArray[indexToChange].active = !newDaysArray[indexToChange].active;

    let dayMask = 0;

    newDaysArray.forEach((el) => {
      if (el.active) {
        dayMask |= 1 << el.value;
      }
    });

    this.setState({ daysArray: newDaysArray }, () => {
      let conditionToSave = { ...condition, value: dayMask };
      modifyCondition(conditionToSave, index);
    });
  };

  render() {
    let days = this.state.daysArray.map((day, index) => {
      return (
        <TouchableOpacity
          onPress={() => this.onScheduleChange(day.value, "day")}
          key={`${index}${day}`}
          style={{
            flex: 1,
            alignItems: "center",
          }}
        >
          <PrimaryColorText
            style={{
              paddingBottom: 2,
            }}
          >
            {day.label}
          </PrimaryColorText>
          {day.active ? <StyledIcon name={"check"} color={"primary"} size={20} /> : <StyledIcon name={"x"} color={"secondary"} size={20} />}
        </TouchableOpacity>
      );
    });
    return (
      <ComponentContainer containerStyle={{ width: "100%" }} style={{ flexDirection: "row", alignItems: "center" }}>
        <PrimaryColorText>{t("DAYS")}: </PrimaryColorText>
        <View style={styles.days}>{days}</View>
      </ComponentContainer>
    );
  }
}
class TimeComponent extends Component {
  constructor(props) {
    super(props);

    this.timeOptions = [
      {
        label: t("AT_TIME"),
        value: 2,
      },
      {
        label: t("BEFORE_SUNRISE"),
        value: 3,
      },
      {
        label: t("AFTER_SUNRISE"),
        value: 4,
      },
      {
        label: t("BEFORE_SUNSET"),
        value: 5,
      },
      {
        label: t("AFTER_SUNSET"),
        value: 6,
      },
    ];

    this.state = {
      time: moment(`09:00`, "HH:mm").toDate(),
      timeOption: this.props.condition.type,
      minutesSun: this.props.condition.type == 2 ? "0" : this.props.condition.value.toString(),
    };
  }

  componentDidMount() {
    const { condition } = this.props;
    let initialHours = Math.floor(condition.value / 100).toString();
    if (initialHours.length <= 1) {
      initialHours = "0" + initialHours;
    }
    let initialMinutes = (condition.value % 100).toString();
    if (initialMinutes.length <= 1) {
      initialMinutes = "0" + initialMinutes;
    }

    this.setState({
      time: moment(`${initialHours}:${initialMinutes}`, "HH:mm").toDate(),
    });
  }

  onTimeChange = (time) => {
    const { condition, modifyCondition, index } = this.props;
    this.setState({ time }, () => {
      let formatedTime = Number(moment(time).format("HHmm"));
      let conditionToSave = { ...condition, value: Number(formatedTime) };
      modifyCondition(conditionToSave, index);
    });
  };

  onMinutesChange = (value) => {
    this.setState({ minutesSun: value });
  };

  onMinutesEnd = () => {
    const { condition, modifyCondition, index } = this.props;
    const { minutesSun } = this.state;
    let conditionToSave = { ...condition, value: Number(minutesSun) };

    modifyCondition(conditionToSave, index);
  };
  onTimeOptionChange = (timeOption) => {
    this.setState({ timeOption }, () => {
      if (Platform.OS === "android" || Platform.OS === "web") {
        this.onFinishPicker();
      }
    });
  };

  onTimeOptionClose = () => {
    this.onFinishPicker();
  };

  onFinishPicker = () => {
    const { condition, modifyCondition, index } = this.props;
    const { timeOption, time } = this.state;
    let conditionToSave = { ...condition, type: timeOption };

    if (timeOption != 2) {
      conditionToSave.value = 0;
      modifyCondition(conditionToSave, index);
    } else {
      let formatedTime = Number(moment().format("HHmm"));
      conditionToSave.value = 900;
      modifyCondition(conditionToSave, index);
    }
  };
  render() {
    const { time, timeOption, minutesSun } = this.state;

    return (
      <ComponentContainer
        containerStyle={{ width: "100%" }}
        style={{
          flexDirection: "row",
        }}
      >
        <PrimaryColorText style={{ paddingRight: 10 }}>{t("TIME")}:</PrimaryColorText>

        <View
          style={{
            flex: 1,
          }}
        >
          <PickerSelect
            placeholder={{
              label: t("CHOOSE_OPTION"),
              value: null,
            }}
            containerStyle={{ flex: 1, paddingBottom: 5 }}
            items={this.timeOptions}
            onValueChange={this.onTimeOptionChange}
            onClose={this.onTimeOptionClose}
            value={timeOption}
          />
          {timeOption == 2 ? (
            <DateTimePicker value={moment(time).format("HH:mm")} onConfirm={(time) => this.onTimeChange(time)} date={time} mode="time" />
          ) : (
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <PrimaryColorText
                style={{
                  paddingRight: 3,
                }}
              >
                {t("MINUTES")}:
              </PrimaryColorText>
              <TextInputStyled
                style={{
                  height: 26,
                  flex: 1,
                }}
                placeholder={t("MINUTES")}
                value={minutesSun}
                keyboardType={"numeric"}
                onChangeText={(value) => this.onMinutesChange(value)}
                onEndEditing={(value) => this.onMinutesEnd(value)}
              />
            </View>
          )}
        </View>
      </ComponentContainer>
    );
  }
}
class Conditions_IrrigationScreen extends Component {
  state = {
    editMode: false,
    animationOpacity: new Animated.Value(0),
    animationMargin: new Animated.Value(-42),
  };

  _moveRight = () => {
    Animated.timing(this.state.animationOpacity, {
      toValue: 1,
      duration: 500,
    }).start();

    Animated.timing(this.state.animationMargin, {
      toValue: 0,
      duration: 500,
    }).start();
  };

  _moveBack = () => {
    Animated.timing(this.state.animationOpacity, {
      toValue: 0,
      duration: 500,
    }).start();

    Animated.timing(this.state.animationMargin, {
      toValue: -42,
      duration: 500,
    }).start();
  };
  toggleOnPressMode = () => {
    let newEditMode = !this.state.editMode;
    this.setState({ editMode: newEditMode }, () => {
      if (this.state.editMode) {
        this._moveRight();
      } else {
        this._moveBack();
      }
    });
  };

  removeCondition = (index) => {
    if (index != 0) {
      this.props.onRemoveCondition(index);
    }
  };

  toggleActiveValue = () => {
    let newValue = this.props.active == 1 ? 0 : 1;
    this.props.changeActive(newValue);
  };

  render() {
    const { conditions, modifyCondition, active } = this.props;
    const { editMode } = this.state;
    return (
      <ScreenView>
        {conditions &&
          conditions.map((condition, index) => {
            let ConditionComponent;
            switch (condition.description) {
              case "DAYS":
                ConditionComponent = DaysComponent;
                break;
              case "TIME":
                ConditionComponent = TimeComponent;
                break;
              case "RAIN":
                ConditionComponent = RainComponent;
                break;
              case "RAIN_TIME":
                ConditionComponent = RainTimeComponent;
                break;
              case "HUM":
                ConditionComponent = HumidityComponent;
                break;

              case "TEMP":
                ConditionComponent = TempComponent;
                break;
              default:
                break;
            }
            return (
              <View
                key={`${index}${condition.type}${condition.value}`}
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Animated.View style={[{ opacity: this.state.animationOpacity }]}>
                  <TouchableOpacity onPress={() => this.removeCondition(index)}>
                    <View style={[styles.removeButton]}>
                      <Text
                        style={{
                          color: "white",
                          marginLeft: 1,
                          lineHeight: 13,
                          fontWeight: "bold",
                          fontSize: 10,
                        }}
                      >
                        —
                      </Text>
                    </View>
                  </TouchableOpacity>
                </Animated.View>
                <Animated.View
                  style={{
                    marginLeft: this.state.animationMargin,
                    width: "100%",
                  }}
                >
                  <ConditionComponent condition={condition} modifyCondition={modifyCondition} index={index} />
                </Animated.View>
              </View>
            );
          })}
        <View
          style={{
            justifyContent: "center",
            width: "100%",
          }}
        >
          <ButtonPrimary
            style={{
              marginVertical: 10,
              paddingVertical: 5,
              // paddingHorizontal: 20,
              width: 100,
              backgroundColor: editMode ? "rgb(40,125,40)" : "rgb(200,00,0)",
            }}
            onPress={this.toggleOnPressMode}
          >
            {editMode ? t("DONE") : t("DELETING")}
          </ButtonPrimary>
        </View>
      </ScreenView>
    );
  }
}

const styles = StyleSheet.create({
  removeButton: {
    width: 18,
    height: 18,
    backgroundColor: "rgb(200,00,0)",
    color: "white",
    borderRadius: 9,
    // display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginHorizontal: 12,
  },
  days: {
    flexDirection: "row",
    // alignItems: "center",
    justifyContent: "space-between",

    flex: 1,
  },
});

export default Conditions_IrrigationScreen;
