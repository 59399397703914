import React, { Component } from "react";
import { View, TouchableOpacity } from "react-native";
import { TabView, TabBar } from "react-native-tab-view";
import { fromJS } from "immutable";
import { devicesForGroups } from "../../utils";

import { connect } from "react-redux";
import { ThemeContext } from "../../../ThemeContext";
import Devices_SceneDetailsScreen from "./Devices/Devices_SceneDetailsScreen";
import Schedule_SceneDetailsScreen from "./Schedule/Schedule_SceneDetailsScreen";
import { t } from "../../services/i18n";
import { StyledIcon } from "../../components/UI/styledComponents";
import AddDeviceModal from "./AddDeviceModal";

let typeToShow = [
  "flaga",
  "flaga_l",
  "flaga_p",
  "led",
  "ledww",
  "flaga_liniowa",
  "przekaznik",
  "reg",
  "radio",
  "ip_radio",
  "rgb",
  "rgbw",
  "roleta",
  "roleta_procenty",
  "roleta_lamelki",
  "event",
  "ir",
];

class SceneDetailsScreen extends Component {
  static contextType = ThemeContext;

  state = {
    index: 0,
    lastTemporaryIdNumber: 0,
    routes: [
      { key: "devices", title: t("DEVICES") },
      { key: "schedules", title: t("SCHEDULE") },
    ],
    sceneSchedules: null,
    sceneInfos: null,
    sceneActions: null,
    dialogVisible: false,
  };

  componentDidMount() {
    const { scene } = this.props.route.params;
    const sceneSchedules = scene.get("Schedules");
    const { devices } = this.props;
    let sceneInfos = scene.get("Infos");
    let lastTemporaryIdNumber = 0;
    const sceneActions = scene.get("Actions");
    sceneInfos = sceneInfos.map((el, index) => {
      //kompatyblilnosc wstecz gdy dla rolet bylo mozliwe dac -1 dla parametrow
      if (devices.get(el.get("id")) && ["roleta", "roleta_procenty", "roleta_lamelki"].includes(devices.get(el.get("id")).get("typ_komponentu"))) {
        if (el.get("param1") == -1 || el.get("param1") == -1) {
          el = el.set("param1", el.get("param1") == -1 ? 0 : el.get("param1")).set("param2", el.get("param2") == -1 ? 0 : el.get("param2"));
        }
      }
      lastTemporaryIdNumber = index;
      el = el.set("temporary_id", index);
      return el;
    });

    this.setState({
      sceneSchedules,
      sceneInfos,
      sceneActions,
      lastTemporaryIdNumber,
    });

    this.props.navigation.setOptions({
      headerRight: () => (
        <TouchableOpacity
          style={{ padding: 10 }}
          onPress={() => {
            this.onPressHeaderButton();
          }}
        >
          <StyledIcon name={"plus"} color={"tabBar"} size={24} />
        </TouchableOpacity>
      ),
      headerLeft: () => (
        <TouchableOpacity
          style={{ padding: 10, flexDirection: "row" }}
          onPress={() => {
            this.onPressBackButton();
          }}
        >
          <StyledIcon name={"arrow-left"} color={"tabBar"} size={24} style={{ paddingRight: 10 }} />
          <StyledIcon name={"save"} color={"tabBar"} size={24} />
        </TouchableOpacity>
      ),
    });
  }

  generateActions = (infos) => {
    const { devices, params_devices } = this.props;
    let methodsArray = [];
    if (infos) {
      infos.map((el) => {
        if (devices.get(el.get("id"))) {
          let device = devices.get(el.get("id"));
          let params_device = params_devices.get(el.get("id"));
          let typKomponentu = device.get("typ_komponentu");
          let deviceID = el.get("id");
          let delay = Number(el.get("delay"));
          let action = "";
          if (typKomponentu == "rgbw" || typKomponentu == "rgb") {
            action = `set/${deviceID}/setColors/${el.get("value")}`;
            methodsArray.push({ action, delay });
          } else if (typKomponentu == "event" || typKomponentu == "ircode") {
            action = `set/${deviceID}/setValue/255`;
            methodsArray.push({ action, delay });
          } else if (typKomponentu == "ledww") {
            action = `set/${deviceID}/setWW/${el.get("value")}`;
            methodsArray.push({ action, delay });
          } else if (typKomponentu == "reg") {
            action = `set/${deviceID}/setTemperature/${el.get("value")}`;
            methodsArray.push({ action, delay });
          } else if (typKomponentu == "radio" || typKomponentu == "ip_radio") {
            action = `set/${deviceID}/setVolume/${el.get("value")}`;
            methodsArray.push({ action, delay });
            if (el.get("param1") != -1 && el.get("param1") != null) {
              action = `set/${deviceID}/setInput/${el.get("param1")}`;
              methodsArray.push({ action, delay });
            }
            if (el.get("param2") != -1 && el.get("param2") != null) {
              action = `set/${deviceID}/setStation/${el.get("param2")}`;
              methodsArray.push({ action, delay });
            }
          } else if (typKomponentu == "roleta") {
            action = `set/${deviceID}/${el.get("param1") ? "open" : "close"}`;
            methodsArray.push({ action, delay });
          } else if (typKomponentu == "roleta_lamelki" || typKomponentu == "roleta_procenty") {
            action = `set/${deviceID}/setRollerPos/${el.get("param1")}/${el.get("param2")}`;
            methodsArray.push({ action, delay });
          } else if (
            (typKomponentu == "flaga" || typKomponentu == "flaga_l" || typKomponentu == "flaga_p" || typKomponentu == "przekaznik") &&
            params_device &&
            params_device.get("czas") > 0
          ) {
            action = `set/${deviceID}/setValue/255/${params_device.get("czas")}`;
            methodsArray.push({ action, delay });
          } else if (typKomponentu == "ir") {
            if (el.get("param1") != -1 && el.get("param1") != null) {
              action = `set/${deviceID}/irRun/${el.get("param1")}`;
              methodsArray.push({ action, delay });
            }
          } else {
            action = `set/${deviceID}/setValue/${el.get("value")}`;
            methodsArray.push({ action, delay });
          }
        }
      });
    }
    return methodsArray;
  };

  onAddNewDevice = (selectedDevices, selectedGroup) => {
    let newDevice = fromJS();
    let { sceneInfos } = this.state;
    const { devices, group_devices, params_devices } = this.props;

    let lastTemporaryIdNumber = this.state.lastTemporaryIdNumber;

    selectedDevices.map((item) => {
      let deviceID = item.value;

      if (
        devices.get(deviceID) &&
        deviceID != "ALL"
        // !sceneInfos.some(
        // 	(deviceInfo) =>
        // 		deviceInfo.get("id") == devices.get(deviceID).get("id")
        // )
      ) {
        let device = devices.get(deviceID);

        let typKomponentu = device.get("typ_komponentu");

        if (typKomponentu == "custom") {
          let powiazane = params_devices.get(deviceID) ? params_devices.get(deviceID).get("powiazane") : "";
          let powiazaneArrayWithPossibleCustom = powiazane ? powiazane.split(",") : [];

          powiazaneArrayWithPossibleCustom.map((el) => {
            el = el.split(":").reverse();
            if (el && el.length > 0) {
              let deviceFromCustom = devices.get(el[0]);
              if (
                deviceFromCustom &&
                deviceFromCustom.get("typ_komponentu") != "custom" &&
                typeToShow.includes(deviceFromCustom.get("typ_komponentu")) &&
                !deviceFromCustom.get("bezpieczne") &&
                !sceneInfos.some((deviceInfo) => deviceInfo.get("id") == deviceFromCustom.get("id"))
              ) {
                lastTemporaryIdNumber += 1;
                if (["roleta", "roleta_procenty", "roleta_lamelki"].includes(typKomponentu)) {
                  newDevice = fromJS({
                    id: deviceFromCustom.get("id"),
                    value: 0,
                    param1: 0,
                    param2: 0,
                    param3: -1,
                    delay: "0",
                    temporary_id: lastTemporaryIdNumber,
                  });
                } else {
                  newDevice = fromJS({
                    id: deviceFromCustom.get("id"),
                    value: 0,
                    param1: -1,
                    param2: -1,
                    param3: -1,
                    delay: "0",
                    temporary_id: lastTemporaryIdNumber,
                  });
                }

                sceneInfos = sceneInfos.push(newDevice);
              }
            }
          });
        }

        if (typeToShow.includes(typKomponentu)) {
          lastTemporaryIdNumber += 1;

          if (["roleta", "roleta_procenty", "roleta_lamelki"].includes(typKomponentu)) {
            newDevice = fromJS({
              id: device.get("id"),
              value: 0,
              param1: 0,
              param2: 0,
              param3: -1,
              delay: "0",
              temporary_id: lastTemporaryIdNumber,
            });
          } else {
            newDevice = fromJS({
              id: device.get("id"),
              value: 0,
              param1: -1,
              param2: -1,
              param3: -1,
              delay: "0",
              temporary_id: lastTemporaryIdNumber,
            });
          }

          sceneInfos = sceneInfos.push(newDevice);
        }
      }
    });
    // } else {
    // 	lastTemporaryIdNumber += 1;
    // 	if (
    // 		["roleta", "roleta_procenty", "roleta_lamelki"].includes(
    // 			devices.get(selectedDevice).get("typ_komponentu")
    // 		)
    // 	) {
    // 		newDevice = fromJS({
    // 			id: selectedDevice,
    // 			value: 0,
    // 			param1: 0,
    // 			param2: 0,
    // 			param3: -1,
    // 			delay: "0",
    // 			temporary_id: lastTemporaryIdNumber,
    // 		});
    // 	} else {
    // 		newDevice = fromJS({
    // 			id: selectedDevice,
    // 			value: 0,
    // 			param1: -1,
    // 			param2: -1,
    // 			param3: -1,
    // 			delay: "0",
    // 			temporary_id: lastTemporaryIdNumber,
    // 		});
    // 	}

    // 	sceneInfos = sceneInfos.push(newDevice);
    // }

    this.setState({ sceneInfos, lastTemporaryIdNumber, dialogVisible: false });
  };

  removeDeviceFromScene = (temporary_id) => {
    let { sceneInfos } = this.state;
    let indexToremove = sceneInfos.findIndex((obj) => obj.get("temporary_id") == temporary_id);
    sceneInfos = sceneInfos.delete(indexToremove);
    this.setState({ sceneInfos });
  };

  onPressBackButton = () => {
    const { sceneName, scene } = this.props.route.params;

    let actionsArray = fromJS(this.generateActions(this.state.sceneInfos));

    let newScene = scene.set("Infos", this.state.sceneInfos).set("Actions", actionsArray).set("Schedules", this.state.sceneSchedules);
    this.props.route.params.changeScene(sceneName, newScene);

    this.props.navigation.goBack();
  };

  onPressHeaderButton = () => {
    if (this.state.index == 0) {
      this.setState({ dialogVisible: true });
    } else if (this.state.index == 1) {
      this.onAddNewSchedule();
    }
  };

  onAddNewSchedule = () => {
    let newSchedule = fromJS({ day: 62, hour: 12, minute: 30, action: 0 });
    let schedules = this.state.sceneSchedules;
    schedules = schedules.push(newSchedule);
    this.setState({ sceneSchedules: schedules });
  };

  scheduleChange = (sceneSchedules) => {
    this.setState({ sceneSchedules });
  };

  onIndexChange = (index) => {
    this.setState({ index });
  };

  onOKHanlder = () => {
    this.setState({
      dialogVisible: false,
    });
  };

  changeSceneFromDevice = (receivedInfo, temporaryId) => {
    let indexToUpdate = this.state.sceneInfos.findIndex((x) => x.get("temporary_id") == temporaryId);

    let newInfos = this.state.sceneInfos.set(indexToUpdate, receivedInfo);

    this.setState({ sceneInfos: newInfos });
  };

  render() {
    const { group_devices, groups, devices, params_devices } = this.props;
    const { theme } = this.context;
    const { routes, index, sceneSchedules, sceneInfos, dialogVisible } = this.state;

    const renderScene = ({ route }) => {
      switch (route.key) {
        case "devices":
          return (
            <Devices_SceneDetailsScreen
              sceneInfos={sceneInfos}
              changeSceneFromDevice={this.changeSceneFromDevice}
              removeDeviceFromScene={this.removeDeviceFromScene}
            />
          );
        case "schedules":
          return <Schedule_SceneDetailsScreen schedules={sceneSchedules} scheduleChange={this.scheduleChange} />;
        default:
          return null;
      }
    };
    return (
      <View style={{ flex: 1 }}>
        <TabView
          renderTabBar={(props) => (
            <TabBar
              {...props}
              indicatorStyle={{ backgroundColor: theme.TAB_BAR_TEXT_COLOR_HIGHLITED }}
              style={{ backgroundColor: theme.TAB_BAR_BACKGROUND_COLOR }}
              activeColor={theme.TAB_BAR_TEXT_COLOR_HIGHLITED}
              inactiveColor={theme.TAB_BAR_TEXT_COLOR}
            />
          )}
          navigationState={{ index, routes }}
          renderScene={renderScene}
          onIndexChange={this.onIndexChange}
        />
        {dialogVisible && (
          <AddDeviceModal
            groups={groups}
            group_devices={group_devices}
            devices={devices}
            params_devices={params_devices}
            isVisible={dialogVisible}
            onClose={this.onOKHanlder}
            onAdd={this.onAddNewDevice}
            typeToShow={typeToShow}
          />
        )}
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  let currentProfile = state.profilesSettings.get("currentProfile");
  return {
    scenes: state.smartHomeData.get(currentProfile).get("scenes"),
    group_devices: state.smartHomeData.get(currentProfile).get("group_devices"),
    devices: state.smartHomeData.get(currentProfile).get("devices"),
    groups: state.smartHomeData.get(currentProfile).get("groups"),
    params_devices: state.smartHomeData.get(currentProfile).get("params_devices"),
    permission: state.statesData.get("server").get("serverInfo").get("perm"),
  };
};

export default connect(mapStateToProps, null)(SceneDetailsScreen);
