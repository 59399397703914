import React, { Component } from "react";
import { Text, Animated, Image, View, TouchableOpacity, ActivityIndicator, StyleSheet, Platform } from "react-native";
import { HighChartView } from "../../utils";
import Tooltip from "react-native-walkthrough-tooltip";
import HighChartColumnView from "../../utils/HighChartColumnView";

import { chartDataReceived, barChartDataReceived } from "../../store/actions";
import moment from "moment";

import AddDeviceModal from "./AddDeviceModal";
import TabSelectorAnimation from "../../components/UI/TabSelectorAnimation";

import { StyledIcon, PrimaryColorText, NoDataScreen, ButtonPrimary, ButtonSecondary } from "../../components/UI/styledComponents";
import DateTimePicker from "../../components/UI/dateTimePicker";

import { t } from "../../services/i18n";
import { Feather } from "@expo/vector-icons";
import { connect } from "react-redux";

import PickerSelect from "../../components/UI/PickerSelect";
import mqttClient from "../../services/mqtt";

import { ThemeContext } from "../../../ThemeContext";
import { SkottieLoader } from "../../components/animatedComponents/Loader";

class ChartScreen extends Component {
  static contextType = ThemeContext;
  constructor(props) {
    super(props);
    let timeRange = 0;

    if (props.route.params.placeName === "BAR_CHART") {
      timeRange = 2;
      this.timeRangeArray = [
        {
          label: t("account:LAST24HOURS"),
          value: 8,
        },
        {
          label: t("account:LAST_7_DAYS"),
          value: 2,
        },
        {
          label: t("account:LAST_MONTH"),
          value: 3,
        },
        {
          label: t("account:LAST_3_MONTHS"),
          value: 4,
        },
        {
          label: t("account:LAST_YEAR"),
          value: 5,
        },
        {
          label: t("account:CUSTOM"),
          value: 6,
        },
      ];
    } else {
      this.timeRangeArray = [
        {
          label: t("account:TODAY"),
          value: 0,
        },
        {
          label: t("account:LASTHOUR"),
          value: 9,
        },
        {
          label: t("account:LAST24HOURS"),
          value: 8,
        },
        {
          label: t("account:YESTERDAY"),
          value: 1,
        },
        {
          label: t("account:LAST_7_DAYS"),
          value: 2,
        },
        {
          label: t("account:LAST_MONTH"),
          value: 3,
        },
        {
          label: t("account:LAST_3_MONTHS"),
          value: 4,
        },
        {
          label: t("account:LAST_YEAR"),
          value: 5,
        },
        {
          label: t("account:CUSTOM"),
          value: 6,
        },
      ];
    }

    this.state = {
      isLoading: true,
      showText: false,
      logbook: null,
      addDeviceModalVisible: false,
      additionalID: 0,
      additionalDevice: null,
      detailCharts: false,
      toolTipVisible: false,
      indexTab: 1,
      options: {
        timeRange: timeRange,
        objectType: 0,
        from: moment().subtract(6, "hours").format("X"),
        to: moment().format("X"),
      },
      timeToCustom: {
        start: moment().subtract(1, "hours").toDate(),
        end: moment().toDate(),
      },
    };
  }

  componentDidMount() {
    this.askForCharts();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { deviceState } = nextProps;
    if (deviceState && deviceState.get("chartData") && deviceState.get("chartData").get("List")) {
      let chartData = deviceState.get("chartData");
      this.setState({
        isLoading: false,
        showText: chartData.get("List").size ? false : true,
      });
    }
    if (deviceState && deviceState.get("barChartData")) {
      let chartData = deviceState.get("barChartData");
      this.setState({
        isLoading: false,
        showText: chartData ? false : true,
      });
    }
  }

  setIndexTab = (index) => {
    this.setState({ indexTab: index, isLoading: true }, () => {
      this.askForCharts();
    });

    //this.askForCharts();
  };

  showAddDeviceModalModal = () => {
    this.setState({
      addDeviceModalVisible: true,
    });
  };

  getToDate = (type) => {
    if (type == 0) {
      // HOUR
      return Math.floor(moment().format("X") / 3600) * 3600 + 3600;
    } else {
      return Math.floor(moment().format("X") / 86400) * 86400 + 86400;
    }
  };

  askForCharts = () => {
    const { deviceID, placeName } = this.props.route.params;
    if (placeName === "CHART") {
      let { additionalID } = this.state;
      let { timeRange, from, to } = this.state.options;
      let message = "";

      if (this.state.detailCharts) {
        timeRange = timeRange + 0x100;
      }

      if (timeRange != 6) {
        message = `/api/json/device/${deviceID}/charts/${timeRange}/0/0/${additionalID}`;
      } else {
        message = `/api/json/device/${deviceID}/charts/${timeRange}/${from}/${to}/${additionalID}`;
      }

      this.setState({ isLoading: true }, () => {
        this.props.chartDataReceived(deviceID, null);
        mqttClient.askForCharts(message, deviceID);
      });
    } else {
      this.askForBarCharts();
    }
  };

  askForBarCharts = () => {
    const { deviceID } = this.props.route.params;

    let { from, to } = this.state.options;
    if (this.state.options.timeRange == 2) {
      to = this.getToDate(this.state.indexTab);
      from = to - 86400 * 7;
      // LAST WEEK
    } else if (this.state.options.timeRange == 3) {
      to = this.getToDate(this.state.indexTab);
      from = to - 86400 * 30;
      // LAST MONTH
    } else if (this.state.options.timeRange == 4) {
      to = this.getToDate(this.state.indexTab);
      from = to - 86400 * 120;
      // LAST 3 MONTHS
    } else if (this.state.options.timeRange == 5) {
      to = this.getToDate(this.state.indexTab);
      from = to - 86400 * 365;
      // LAST YEAR
    } else if (this.state.options.timeRange == 8) {
      to = this.getToDate(this.state.indexTab);
      from = to - 3600 * 24;
      // LAST 24
    }

    let numOfSteps = 12;
    if (this.state.indexTab == 0) {
      numOfSteps = (to - from) / 3600;
    } else if (this.state.indexTab == 1) {
      numOfSteps = (to - from) / (3600 * 24);
    } else if (this.state.indexTab == 2) {
      numOfSteps = (to - from) / (3600 * 24 * 7);
    } else if (this.state.indexTab == 3) {
      numOfSteps = (to - from) / (3600 * 24 * 30);
    }

    let message = `/api/json/device/${deviceID}/barCharts/${from}/${to}/${numOfSteps}`;
    this.setState({ isLoading: true }, () => {
      this.props.barChartDataReceived(deviceID, null);
      mqttClient.askForBarCharts(message, deviceID);
    });
  };

  onCloseModalHanlder = () => {
    this.setState({
      addDeviceModalVisible: false,
    });
  };

  onAddDeviceHandler = (device) => {
    this.setState(
      {
        addDeviceModalVisible: false,
        additionalDevice: device,
        additionalID: device.get("id"),
      },
      () => {
        this.askForCharts();
      }
    );
  };

  onOptionValueChange = (value, key) => {
    let options = Object.assign({}, this.state.options); //creating copy of object in state

    options[key] = value;
    this.setState({ options }, () => {
      if (Platform.OS === "android") {
        if (this.state.options.timeRange == 6) {
          this.setState({ isLoading: true });
          this.askForCharts();
        } else {
          this.askForCharts();
        }
      }
    });
  };

  onClosePickerHandler = () => {
    if (Platform.OS === "ios") {
      if (this.state.options.timeRange == 6) {
        this.setState({ isLoading: true });
        this.askForCharts();
      } else {
        this.askForCharts();
      }
    }
  };

  removeAdditonalDevice = () => {
    this.setState(
      {
        addDeviceModalVisible: false,
        additionalID: 0,
        additionalDevice: null,
      },
      () => {
        this.askForCharts();
      }
    );
  };

  componentWillUnmount() {
    const { navigation, route } = this.props;
    const { deviceID, deviceType } = route.params;
    this.props.chartDataReceived(deviceID, null);
  }

  onTimeScopeChange = (value, key) => {
    let timeToCustom = Object.assign({}, this.state.timeToCustom); //creating copy of object in state

    timeToCustom[key] = value;

    let options = Object.assign({}, this.state.options); //creating copy of object in state
    if (key == "start") {
      options.from = moment(value).format("X");
    } else if (key == "end") {
      options.to = moment(value).format("X");
    }

    this.setState({ timeToCustom, options, isLoading: true });
  };

  refreshCharts = () => {
    this.setState(
      {
        isLoading: true,
      },
      () => {
        this.askForCharts();
      }
    );
  };

  render() {
    const { navigation, route, deviceState, params_device } = this.props;
    const { deviceID, deviceType, placeName } = route.params;

    const { theme } = this.context;
    const DATA = [{ title: "HOUR" }, { title: "DAY" }, { title: "WEEK" }, { title: "MONTH" }];

    const { isLoading, options, showText, addDeviceModalVisible, timeToCustom, indexTab } = this.state;

    let dataPickerRow = (
      <View style={{ width: "100%" }}>
        <View
          style={{
            flexDirection: "row",
            marginHorizontal: 10,
            marginTop: 5,
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <View
            style={{
              flex: 3,
              marginHorizontal: 10,
            }}
          >
            <DateTimePicker
              value={moment(timeToCustom.start).format("DD-MM-YY, HH:mm")}
              date={timeToCustom.start}
              onConfirm={(time) => this.onTimeScopeChange(time, "start")}
              mode="datetime"
              maximumDate={new Date()}
            />
          </View>

          <View style={{ flex: 3, marginHorizontal: 10 }}>
            <DateTimePicker
              value={moment(timeToCustom.end).format("DD-MM-YY, HH:mm")}
              date={timeToCustom.end}
              onConfirm={(time) => this.onTimeScopeChange(time, "end")}
              mode="datetime"
              maximumDate={new Date()}
            />
          </View>
          <TouchableOpacity style={{ padding: 5 }} onPress={this.refreshCharts}>
            <Text style={{ color: theme.TAB_BAR_TEXT_COLOR_HIGHLITED }}>OK</Text>
          </TouchableOpacity>
        </View>
        <View>
          <Text
            style={{
              fontSize: 12,
              marginTop: 3,
              textAlign: "center",
              color: theme.TAB_BAR_TEXT_COLOR_HIGHLITED,
            }}
          >
            {t("TO_UPDATE_CHART")}
          </Text>
        </View>
      </View>
    );

    let chartView = showText ? (
      <NoDataScreen />
    ) : (
      <View
        style={{
          backgroundColor: theme.COMPONENT_BACKGROUND_COLOR,
          flex: 1,
          width: "100%",
          height: "100%",
        }}
      >
        {deviceType != "reg" && deviceState && deviceState.get("chartData") ? (
          <View
            style={{
              // borderWidth: 1,
              // borderColor: "red",
              flexDirection: "row",
              justifyContent: "space-around",
              margin: 10,
            }}
          >
            <ButtonPrimary onPress={this.showAddDeviceModalModal} style={{ width: "40%", maxWidth: 150 }}>
              {t("ADD_OBJECT")}
            </ButtonPrimary>
            <ButtonSecondary style={{ width: "40%", maxWidth: 150 }} onPress={this.removeAdditonalDevice}>
              {t("CLEAR")}
            </ButtonSecondary>
          </View>
        ) : null}
        {placeName === "BAR_CHART" ? (
          <View style={styles.wrapperAll}>
            <TabSelectorAnimation active={indexTab} onChangeTab={this.setIndexTab} style={styles.tabSelector} tabs={DATA} />
          </View>
        ) : null}
        {placeName === "CHART" ? (
          <HighChartView
            options={this.state.options}
            timeRange={this.state.options.timeRange}
            additionalDevice={this.state.additionalDevice}
            deviceName={this.props.route.params.deviceName}
            chartData={deviceState ? deviceState.get("chartData") : null}
            theme={theme}
            deviceType={deviceType}
            params_device={params_device}
            deviceId={deviceID}
          />
        ) : null}
        {placeName === "BAR_CHART" ? (
          <HighChartColumnView
            options={this.state.options}
            timeRange={this.state.options.timeRange}
            additionalDevice={this.state.additionalDevice}
            deviceName={this.props.route.params.deviceName}
            chartData={deviceState ? deviceState.get("barChartData") : null}
            theme={theme}
            deviceType={deviceType}
            params_device={params_device}
          />
        ) : null}
      </View>
    );

    return (
      <View
        style={{
          flex: 1,
          backgroundColor: theme.APP_BACKGROUND_COLOR,
        }}
      >
        <View style={[styles.stickyTopBar, { backgroundColor: theme.TAB_BAR_BACKGROUND_COLOR }]}>
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              // width: "90%",
            }}
          >
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                paddingRight: 10,
              }}
            >
              <Text
                style={{
                  color: theme.TAB_BAR_TEXT_COLOR_HIGHLITED,
                  paddingRight: 10,
                }}
              >
                {t("CHOOSE_TIME")}
              </Text>

              <PickerSelect
                placeholder={{
                  label: t("CHOOSE_TIME"),
                  value: null,
                }}
                containerStyle={{ width: 100 }}
                items={this.timeRangeArray}
                onValueChange={(e) => this.onOptionValueChange(e, "timeRange")}
                value={options.timeRange}
                onClose={(e) => this.onClosePickerHandler(e, "timeRange")}
              />
            </View>
            <TouchableOpacity onPress={this.refreshCharts}>
              <Text style={{ color: theme.TAB_BAR_TEXT_COLOR_HIGHLITED }}>{t("REFRESH")}</Text>
            </TouchableOpacity>
            {this.props.serverVersion >= 863 && (
              <TouchableOpacity
                onPress={() => {
                  window.app.showToast(!this.state.detailCharts ? t("DETAILED_CHARTS") : t("NORMAL_CHARTS"));
                  this.setState(
                    {
                      detailCharts: !this.state.detailCharts,
                    },
                    () => this.refreshCharts()
                  );
                }}
                style={{ marginLeft: 8 }}
              >
                <StyledIcon
                  name={this.state.detailCharts ? "bar-chart-2" : "bar-chart"}
                  size={24}
                  style={{ color: theme.TAB_BAR_TEXT_COLOR_HIGHLITED }}
                />
              </TouchableOpacity>
            )}
          </View>
          {options.timeRange == 6 && dataPickerRow}
        </View>

        {isLoading ? (
          <View
            style={{
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: theme.COMPONENT_BACKGROUND_COLOR,
            }}
          >
            <SkottieLoader style={{ width: 40, height: 40, margin: 5 }} />
          </View>
        ) : (
          chartView
        )}

        {addDeviceModalVisible && (
          <AddDeviceModal
            isVisible={this.state.addDeviceModalVisible}
            onClose={this.onCloseModalHanlder}
            onAdd={this.onAddDeviceHandler}
            deviceID={deviceID}
          />
        )}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  singleLine: {
    paddingVertical: 5,
    paddingHorizontal: 10,
    width: "100%",
  },
  stickyTopBar: {
    justifyContent: "center",
    alignItems: "center",
    padding: 10,
  },
  wrapperAll: {
    alignItems: "center",
    justifyContent: "center",
  },
  tabSelector: {
    marginHorizontal: 20,
    marginTop: 10,
    marginBottom: 10,
  },
});
const mapStateToProps = (state, ownProps) => {
  return {
    deviceState: state.statesData.get("states").get(ownProps.route.params.deviceID),
    params_device: state.smartHomeData.get(state.profilesSettings.get("currentProfile")).get("params_devices").get(ownProps.route.params.deviceID),
    serverVersion: state.statesData.get("server").get("serverInfo").get("serverVersion"),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    chartDataReceived: (deviceID, reset) => dispatch(chartDataReceived(deviceID, reset)),
    barChartDataReceived: (deviceID, reset) => dispatch(barChartDataReceived(deviceID, reset)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChartScreen);
