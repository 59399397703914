import React, { Component } from "react";
import { StyleSheet, View, TouchableOpacity, ScrollView, Text } from "react-native";
import { is } from "immutable";
import { t } from "../../services/i18n";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import { ThemeContext } from "../../../ThemeContext";
import { ScreenView } from "../../components/UI/screenViewX";
import { useIsFocused } from "@react-navigation/native";
import shallowequal from "shallowequal";
import { StyledIcon, NoDataScreen, TitleWithLines } from "../../components/UI/styledComponents";
import { devicesForGroups, groupListForPicker, DevicesToShowByColumns } from "../../utils";
import PickerSelectObjects from "../../components/UI/PickerSelectObjects";
import ModalSimple from "../../components/UI/ModalSimple";
import mqttClient from "../../services/mqtt";

let typeToShow = [
  "flaga",
  "flaga_l",
  "flaga_p",
  "led",
  "ledww",
  "flaga_liniowa",
  "flaga_liniowa16",
  "przekaznik",
  "radio",
  "ip_radio",
  "rgb",
  "rgbw",
  "satel_wyj",
];

class ActiveScreenX extends Component {
  static contextType = ThemeContext;

  state = {
    turnOffDialogVisible: false,
    selectedGroup: -1,
    arrayOfIdsOfActiveDevices: [],
  };

  componentDidMount() {
    const { navigation } = this.props;
    this.view = null;
    navigation.setOptions({
      headerRight: () => (
        <TouchableOpacity style={{ padding: 10 }} onPress={() => this.showDialog()}>
          <StyledIcon name={"power"} color={"tabBar"} size={20} />
        </TouchableOpacity>
      ),
    });
    let listForActiveScreen = this.activeDevices();

    this.setState({ arrayOfIdsOfActiveDevices: listForActiveScreen });

    this.focusListener = navigation.addListener("focus", () => {
      let listForActiveScreen = this.activeDevices();

      this.setState({ arrayOfIdsOfActiveDevices: listForActiveScreen });
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    //console.log(this.props.currentRoute, nextProps.currentRoute, nextProps.activeDevicesArray);
    if (
      !is(nextProps.activeDevicesArray, this.props.activeDevicesArray) ||
      JSON.stringify(this.state.arrayOfIdsOfActiveDevices) !== JSON.stringify(nextState.arrayOfIdsOfActiveDevices)
    ) {
      return true;
    }
    if (this.state.turnOffDialogVisible !== nextState.currentRoute) {
      return true;
    }
    if (this.props.currentRoute !== nextProps.currentRoute) {
      return true;
    }
    if (this.state.selectedGroup !== nextState.selectedGroup) {
      return true;
    }
    return false;
  }

  showDialog = () => {
    this.setState({ turnOffDialogVisible: true });
  };

  onGroupValueChange = (value) => {
    this.setState({ selectedGroup: value }, () => {
      let listForActiveScreen = this.activeDevices();

      this.setState({ arrayOfIdsOfActiveDevices: listForActiveScreen });
    });
  };

  handleDialogOK = () => {
    this.setState({ turnOffDialogVisible: false }, () => {
      this.sendMessage();
    });
  };

  sendMessage = () => {
    let message = "";
    const { devices } = this.props;
    let listForActiveScreen = this.activeDevices();
    listForActiveScreen.forEach((el) => {
      if (devices && devices.get(el)) {
        let typKomponentu = devices.get(el).get("typ_komponentu");
        if (typKomponentu == "radio") {
          message = `/api/set/${el}/setVolume/0`;
        } else if (typKomponentu == "rgb" || typKomponentu == "rgbw") {
          message = `/api/set/${el}/setColors/0`;
        } else if (typKomponentu == "ledww") {
          message = `/api/set/${el}/setWW/0`;
        } else {
          message = `/api/set/${el}/setValue/0`;
        }
        mqttClient.stateChangeToSend(message, el);
      }
    });
  };

  handleDialogClose = () => {
    this.setState({ turnOffDialogVisible: false });
  };

  activeDevices = (groupId) => {
    const { devices, groups, group_devices, params_devices, activeDevicesArray } = this.props;

    let selectedGroup = groupId != undefined ? groupId : this.state.selectedGroup;

    let arrayOfIds = [];

    let list = [selectedGroup];
    /*
    groups &&
      groups.map((el) => {
        if (selectedGroup != 0 && el && el.get("id_rodzica") == selectedGroup) {
          list.push(el.get("id"));
        }
      });
      */
    let listOfIdsInGroup = [];

    if (selectedGroup == -1 && activeDevicesArray) {
      activeDevicesArray.map((activeDeviceId) => {
        if (!arrayOfIds.includes(activeDeviceId.get("id"))) {
          arrayOfIds.push(activeDeviceId.get("id"));
        }
      });
    } else {
      list.forEach((el) => {
        let devicesInGroup = devicesForGroups(group_devices, el);

        devicesInGroup &&
          devicesInGroup.map((el) => {
            listOfIdsInGroup.push(el.get("id_obiektu"));
            if (devices.get(el.get("id_obiektu")) && devices.get(el.get("id_obiektu")).get("typ_komponentu") == "custom") {
              let device = devices.get(el.get("id_obiektu"));
              let powiazane = params_devices.get(el.get("id_obiektu")) ? params_devices.get(el.get("id_obiektu")).get("powiazane") : "";

              let powiazaneArrayWithPossibleCustom = powiazane ? powiazane.split(",") : [];
              const params = params_devices.get(el.get("id_obiektu")).get("params");
              let option1 = (params & 0x8000) > 0;

              powiazaneArrayWithPossibleCustom &&
                powiazaneArrayWithPossibleCustom.map((el, index) => {
                  el = el.split(":").reverse();
                  let deviceFromCustom = devices.get(el[0]);
                  if (deviceFromCustom) {
                    let deviceFromCustomId = deviceFromCustom.get("id");
                    activeDevicesArray &&
                      activeDevicesArray.map((activeDeviceId) => {
                        if (!option1) {
                          if (
                            activeDeviceId.get("id") == deviceFromCustomId &&
                            !arrayOfIds.includes(device.get("id"))
                          ) {
                            arrayOfIds.push(device.get("id"));
                            return true; // Exits the loop early
                          }
                        } else {
                          if (
                            activeDeviceId.get("id") == deviceFromCustomId &&
                            !arrayOfIds.includes(device.get("id"))
                          ) {
                            arrayOfIds.push(deviceFromCustom.get("id"));
                          }
                        }
                      });
                  }
                });
            }
          });
      });

      activeDevicesArray &&
        activeDevicesArray.map((activeDeviceId) => {
          listOfIdsInGroup.map((idInGroup) => {
            if (activeDeviceId.get("id") == idInGroup && !arrayOfIds.includes(activeDeviceId.get("id"))) {
              arrayOfIds.push(activeDeviceId.get("id"));
            }
          });
        });
    }
    return arrayOfIds;
  };

  render() {
    const { devices, groups, order, isFocused } = this.props;
    const { theme } = this.context;
    const { navigation } = this.props;
    const { selectedGroup, turnOffDialogVisible, arrayOfIdsOfActiveDevices } = this.state;

    if (!isFocused) {
      return this.view ? (
        this.view
      ) : (
        <View
          style={{
            flex: 1,
            backgroundColor: theme.APP_BACKGROUND_COLOR,
          }}
        />
      );
    } else {
      let additionalGroup = {
        label: t("active:ALL_ITEMS"),
        value: -1,
      };

      let dataToShow_Group = <DevicesToShowByColumns arrayOfIds={arrayOfIdsOfActiveDevices} devices={devices} />;

      let desc = "";
      let objectsGroups = [
        {
          label: t("DASHBOARD"),
          value: 0,
        },
      ];
      objectsGroups.push(...groupListForPicker(groups, order));
      let dashboardDevices;
      if (selectedGroup == -1) {
        dashboardDevices = this.activeDevices(0);
      }
      let shownActiveDevices = dashboardDevices;
      if (arrayOfIdsOfActiveDevices.length > 0) {
        if (selectedGroup == -1 || !objectsGroups.find((obj) => obj.value == selectedGroup)) {
          desc = `${t("active:TURN_OFF_ALL")}`;
        } else {
          desc = `${t("active:TURN_OFF")}${objectsGroups.find((obj) => obj.value == selectedGroup).label}?`;
        }
      } else {
        desc = `${t("active:NO_ACTIVE_DEVICES_TO_TURN")}`;
      }

      this.view = (
        <View
          style={{
            flex: 1,
            backgroundColor: theme.APP_BACKGROUND_COLOR,
          }}
        >
          <View style={[styles.stickyTopBar, { backgroundColor: theme.TAB_BAR_BACKGROUND_COLOR }]}>
            <PickerSelectObjects
              containerStyle={{ width: "90%" }}
              additionalGroup={additionalGroup}
              onValueChange={this.onGroupValueChange}
              value={selectedGroup}
              type={"groups"}
            />
          </View>
          {arrayOfIdsOfActiveDevices && arrayOfIdsOfActiveDevices.length ? (
            <ScreenView>
              {selectedGroup !== -1 ? (
                dataToShow_Group
              ) : (
                <>
                  {dashboardDevices && dashboardDevices.length ? (
                    <View style={{ width: "100%" }}>
                      <TitleWithLines textInside={t("DASHBOARD")} />
                      <DevicesToShowByColumns arrayOfIds={dashboardDevices} devices={devices} />
                    </View>
                  ) : null}
                  {groups &&
                    groups.map((group) => {
                      if (group) {
                        let listForActiveScreen = this.activeDevices(group.get("id"));

                        for (var i = 0; i < listForActiveScreen.length; i++) {
                          if (shownActiveDevices.indexOf(listForActiveScreen[i]) > -1) {
                            listForActiveScreen = listForActiveScreen.filter(function (obj) {
                              return obj !== listForActiveScreen[i];
                            });
                          }
                        }

                        if (listForActiveScreen && listForActiveScreen.length) {
                          shownActiveDevices = shownActiveDevices.concat(listForActiveScreen);
                          return (
                            <View key={group.get("id")} style={{ width: "100%" }}>
                              <TitleWithLines textInside={group.get("opis_menu")} />
                              <DevicesToShowByColumns arrayOfIds={listForActiveScreen} devices={devices} />
                            </View>
                          );
                        }
                      }
                    })}
                </>
              )}
            </ScreenView>
          ) : (
            <NoDataScreen />
          )}
          {turnOffDialogVisible && (
            <ModalSimple
              noTranslate={true}
              isVisible={turnOffDialogVisible}
              description={desc}
              onOK={this.handleDialogOK}
              onClose={this.handleDialogClose}
            />
          )}
        </View>
      );
      return this.view;
    }
  }
}

const styles = StyleSheet.create({
  stickyTopBar: {
    justifyContent: "center",
    alignItems: "center",
    padding: 10,
  },
});

const selectDevices = (state) => state;

const possibleDevicesToActive = createSelector(selectDevices, (smartHomeData) => {
  const filteredItems = smartHomeData.filter((device) => {
    if (device) {
      return typeToShow.includes(device.get("typ_komponentu")) && !device.get("bezpieczne");
    } else {
      return false;
    }
  });
  return filteredItems;
});

const mapStateToProps = (state, ownProps) => {
  let currentProfile = state.profilesSettings.get("currentProfile");
  if (state.statesData.get("currentRoute") === "Active") {
    let possibleDevices = possibleDevicesToActive(state.smartHomeData.get(currentProfile).get("devices"));
    let devicesStates = state.statesData.get("statesActive");
    let params_devices = state.smartHomeData.get(currentProfile).get("params_devices");

    return {
      groups: state.smartHomeData.get(currentProfile).get("groups"),
      group_devices: state.smartHomeData.get(currentProfile).get("group_devices"),
      devices: state.smartHomeData.get(currentProfile).get("devices"),
      activeDevicesArray: possibleDevices.filter((device, index) => {
        if (device && devicesStates && devicesStates.get(device.get("id"))) {
          let typKomponentu = device.get("typ_komponentu");
          let stan = devicesStates.get(device.get("id")) ? devicesStates.get(device.get("id")).get("state") : 0;
          if (typKomponentu === "ip_radio" && devicesStates.get(device.get("id")).get("status")) {
            if (devicesStates.get(device.get("id")).get("status").includes("play")) {
              stan = 1;
            } else {
              stan = 0;
            }
          } else if (typKomponentu === "ledww") {
            stan = stan & 0xff;
          }

          if (
            stan != null &&
            stan != 0 &&
            typeToShow.includes(typKomponentu) &&
            !device.get("bezpieczne") &&
            params_devices &&
            params_devices.get(device.get("id")) &&
            (params_devices.get(device.get("id")).get("params") & 0b1) > 0
          ) {
            return true;
          }
          return false;
        }
        return false;
      }),
      params_devices: state.smartHomeData.get(currentProfile).get("params_devices"),
      order: state.profilesSettings.get(currentProfile).get("order"),
      currentRoute: state.statesData.get("currentRoute"),
    };
  } else {
    return {
      groups: state.smartHomeData.get(currentProfile).get("groups"),
      group_devices: state.smartHomeData.get(currentProfile).get("group_devices"),
      devices: state.smartHomeData.get(currentProfile).get("devices"),
      order: state.profilesSettings.get(currentProfile).get("order"),
      params_devices: state.smartHomeData.get(currentProfile).get("params_devices"),
      currentRoute: state.statesData.get("currentRoute"),
    };
  }
};

const ActiveScreen = (props) => {
  const isFocused = useIsFocused();
  return <ActiveScreenX {...props} isFocused={isFocused} />;
};

export default connect(mapStateToProps)(ActiveScreen);
