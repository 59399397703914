import React, { PureComponent } from "react";
import { View, Animated, TouchableOpacity, Dimensions, Text, StyleSheet } from "react-native";

import { ThemeContext } from "../../../ThemeContext";

const COLORS = {
  backgroundColor: "#D2D2D2",
  shadowColor: "#E8E8E8",
  backgroundColorItem: "#fff",
  colorText: "#000",
};

const { width } = Dimensions.get("window");
class TabSelectorAnimation extends PureComponent {
  static contextType = ThemeContext;

  state = {
    active: 0,
    xTabOne: 0,
    xTabTwo: 0,
    translateX: new Animated.Value(0),
    translateXTabOne: new Animated.Value(0),
    translateXTabTwo: new Animated.Value(width),
    translateY: -1000,
  };

  handleSlide = (type, index, skip) => {
    const { onChangeTab } = this.props;
    const { translateX, translateXTabOne, translateXTabTwo, active } = this.state;
    if (onChangeTab && !skip) {
      onChangeTab(index);
    }
    Animated.timing(translateX, {
      toValue: this.state[type] || 0,
      delay: 0,
      duration: 200,
      useNativeDriver: false,
    }).start();
    if (active === 0) {
      Animated.parallel([
        Animated.spring(translateXTabOne, {
          toValue: 0,
          duration: 100,
          useNativeDriver: false,
        }).start(),
        Animated.spring(translateXTabTwo, {
          toValue: width,
          duration: 100,
          useNativeDriver: false,
        }).start(),
      ]);
    } else {
      Animated.parallel([
        Animated.spring(translateXTabOne, {
          toValue: -width,
          useNativeDriver: false,
          duration: 100,
        }).start(),
        Animated.spring(translateXTabTwo, {
          toValue: 0,
          duration: 100,
          useNativeDriver: false,
        }).start(),
      ]);
    }
  };

  render() {
    const { tabs = [], style, active, styleTab } = this.props;
    const { theme } = this.context;
    let { translateX } = this.state;
    translateX = active * ((width - 10) / 4);
    return (
      <View
        style={[
          styles.container,
          style,
          {
            backgroundColor: theme.APP_BACKGROUND_COLOR,
          },
        ]}
      >
        <Animated.View
          style={[
            styles.animatedView,
            {
              width: `${97 / tabs.length}%`,
              shadowColor: theme.COMPONENT_BACKGROUND_COLOR_SECONDARY,
              backgroundColor: theme.COMPONENT_BACKGROUND_COLOR,
              transform: [
                {
                  translateX,
                },
              ],
            },
          ]}
        />
        {tabs.map((item, index) => (
          <TouchableOpacity
            key={item.title ?? item.label}
            style={[styles.tab, styleTab]}
            onLayout={(event) => {
              this.setState({
                [`xTab${index}`]: event.nativeEvent.layout.x,
              });
              if (index === tabs.length - 1) {
                //this.handleSlide(`xTab${active}`, active, true);
              }
            }}
            onPress={() => this.setState({ active: index }, () => this.handleSlide(`xTab${index}`, index, false))}
          >
            <Text style={[styles.textTitle, { color: theme.TEXT_COLOR_PRIMARY, fontFamily: theme.FONT_BOLD }]}>{item.title ?? item.label}</Text>
          </TouchableOpacity>
        ))}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    borderRadius: 20,
    height: 30,
  },
  tab: {
    flex: 1,
    height: 30,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 20,
  },
  textTitle: {
    fontSize: 11,
  },
  animatedView: {
    position: "absolute",
    height: 26,
    top: 2,
    left: 0,
    marginHorizontal: 2,
    shadowOffset: { width: 0, height: 0 },
    shadowColor: COLORS.shadowColor,
    shadowRadius: 3,
    shadowOpacity: 1,
    backgroundColor: COLORS.backgroundColorItem,
    borderRadius: 20,
  },
});
export default TabSelectorAnimation;
