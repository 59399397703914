import React, { Component } from "react";
import { Text, View, Dimensions } from "react-native";

import { ComponentHeaderText, PrimaryColorText } from "../../UI/styledComponents";
import ImageForLinearInput from "../../../utils/ImageForLinearInput";
import { WebView as RNWebView } from "react-native-webview";
import { WebView } from "WebView";
const TextApiComponent = ({ deviceState, params_device, device, header, deviceFromCustom, deviceFromSketch }) => {
  // var html =
  // 	'<html><div style="background-color: red"><p style="font-size:70;"><strong>WebView Content</strong></p></div></html>';
  // var html3 = "<h1>Hello world</h1>";
  // var html2 =
  // 	'<div style="background-color: red"><p style="color:blue; font-size:60; font-weight:bold; font-style:italic;">Tu jakiś tekst</p></div>';

  const textToDispalay = () => {
    if (deviceState.get("desc") && deviceState.get("desc").startsWith("<html>")) {
      return (
        <View
          style={{
            flex: 1,
            width: deviceFromSketch ? "100%" : null,
          }}
        >
          <WebView
            source={{ html: deviceState.get("desc") }}
            style={{
              width: "100%",
              opacity: 0.99,
            }}
          />
        </View>
      );
    } else {
      return <PrimaryColorText>{deviceState.get("desc")}</PrimaryColorText>;
    }
  };

  return !deviceFromSketch ? (
    <View
      style={{
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <View>{!deviceFromCustom && header}</View>
      {textToDispalay()}
    </View>
  ) : (
    textToDispalay()
  );
};

export default TextApiComponent;
