import React, { Component } from "react";
import { Text, View, FlatList, Button, TouchableOpacity, ActivityIndicator, StyleSheet, Animated, Keyboard, Platform } from "react-native";
import moment from "moment";
import * as DocumentPicker from "expo-document-picker";
import * as FileSystem from "expo-file-system";
import * as Sharing from "expo-sharing";
import { t } from "../../services/i18n";
import { Feather } from "@expo/vector-icons";
import { connect } from "react-redux";
import { StyledIcon, ScreenViewWithoutScroll, ButtonPrimary } from "../../components/UI/styledComponents";
import { ScreenView } from "../../components/UI/screenViewX";

import mqttClient from "../../services/mqtt";

import { removeOutdatedAccessControl, singleStateReceived } from "../../store/actions";

import AccessControlSingle from "./AccessControlSingle";
import { SkottieLoader } from "../../components/animatedComponents/Loader";

class AccessControlScreen extends Component {
  state = {
    isLoading: true,
    editMode: false,
    animationOpacity: new Animated.Value(0),
    animationMargin: new Animated.Value(-42),
    accessObjects: [],
  };

  _closeScreen = (save) => {
    Keyboard.dismiss();
    if (save) {
      let pinTooShort = this.state.accessObjects.some((el) => {
        return el && el.security == 1 && el.pin.toString().length < 4;
      });
      if (pinTooShort) {
        alert(t("MIN_LENGHT_3"));
      } else {
        const { device } = this.props.route.params;
        if (!this.state.isLoading) {
          mqttClient.sendNewAccessControl(this.state.accessObjects, device.get("id"));
        }
        this.props.navigation.goBack(null);
      }
    } else {
      this.props.navigation.goBack(null);
    }
  };
  componentDidMount() {
    const { navigation, route } = this.props;
    navigation.setOptions({
      headerRight: () => (
        <View
          style={{
            flexDirection: "row",
          }}
        >
          <TouchableOpacity
            style={{ paddingTop: 10, paddingRight: 10 }}
            onPress={async () => {
              try {
                let result;
                if (Platform.OS === "ios") {
                  result = await DocumentPicker.getDocumentAsync();
                } else {
                  result = await DocumentPicker.getDocumentAsync({
                    copyToCacheDirectory: false,
                  });
                }

                if (result && result.assets && result.assets.length > 0 && result.assets[0].uri) {
                  let profilesData;
                  if (Platform.OS === "web") {
                    let blob = await urlToBlob(result.assets[0].uri);
                    profilesData = await readAsTextAsync(blob);
                  } else if (Platform.OS === "ios") {
                    profilesData = await FileSystem.readAsStringAsync(result.assets[0].uri);
                  } else {
                    let result2 = await FileSystem.copyAsync({
                      from: result.assets[0].uri,
                      to: FileSystem.documentDirectory + result.assets[0].name,
                    });
                    profilesData = await FileSystem.readAsStringAsync(FileSystem.documentDirectory + result.assets[0].name);
                  }
                  let profilesJson = JSON.parse(profilesData);
                  this.setState({ accessObjects: profilesJson });
                }
              } catch (ex) {
                console.log(ex);
                alert(ex.message);
              }
            }}
          >
            <StyledIcon name={"download"} color={"tabBar"} size={24} />
          </TouchableOpacity>
          <TouchableOpacity
            style={{ paddingTop: 10 }}
            onPress={async () => {
              const { device } = this.props.route.params;
              try {
                if (Platform.OS === "web") {
                  const bytes = new TextEncoder().encode(JSON.stringify(this.state.accessObjects));
                  const blob = new Blob([bytes], {
                    type: "application/json;charset=utf-8",
                  });
                  let link = document.createElement("a");
                  link.href = URL.createObjectURL(blob);
                  link.download = "profiles.json";
                  document.body.appendChild(link);
                  link.dispatchEvent(
                    new MouseEvent("click", {
                      bubbles: true,
                      cancelable: true,
                      view: window,
                    })
                  );
                  link.remove();
                  URL.revokeObjectURL(link.href);
                } else {
                  await FileSystem.writeAsStringAsync(
                    FileSystem.cacheDirectory + device.get("id") + "_pins.json",
                    JSON.stringify(this.state.accessObjects)
                  );
                  Sharing.shareAsync(FileSystem.cacheDirectory + device.get("id") + "_pins.json");
                }
              } catch (ex) {
                console.log(ex);
                alert(ex.message);
              }
            }}
          >
            <StyledIcon name={"upload"} color={"tabBar"} size={24} />
          </TouchableOpacity>
          <TouchableOpacity style={{ padding: 10 }} onPress={() => this._closeScreen(false)}>
            <StyledIcon name={"x"} color={"tabBar"} size={24} />
          </TouchableOpacity>
        </View>
      ),
      headerLeft: () => (
        <TouchableOpacity style={{ padding: 10, flexDirection: "row" }} onPress={() => this._closeScreen(true)}>
          <StyledIcon name={"arrow-left"} color={"tabBar"} size={24} style={{ paddingRight: 10 }} />
          <StyledIcon name={"save"} color={"tabBar"} size={24} />
        </TouchableOpacity>
      ),
    });
    const { device } = route.params;
    mqttClient.askForAccessControl(device.get("id"));
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state.accessObjects.length === 0 && nextProps.deviceState && nextProps.deviceState.get("accessControl")) {
      let accessObjects = nextProps.deviceState.get("accessControl");
      this.setState({
        isLoading: false,
        accessObjects: accessObjects.toJS(),
      });
    }
  }

  _moveRight = () => {
    Animated.timing(this.state.animationOpacity, {
      toValue: 1,
      duration: 500,
      useNativeDriver: false,
    }).start();

    Animated.timing(this.state.animationMargin, {
      toValue: 0,
      duration: 500,
      useNativeDriver: false,
    }).start();
  };

  _moveBack = () => {
    Animated.timing(this.state.animationOpacity, {
      toValue: 0,
      duration: 500,
      useNativeDriver: false,
    }).start();

    Animated.timing(this.state.animationMargin, {
      toValue: -42,
      duration: 500,
      useNativeDriver: false,
    }).start();
  };
  toggleOnPressMode = () => {
    let newEditMode = !this.state.editMode;
    this.setState({ editMode: newEditMode }, () => {
      if (this.state.editMode) {
        this._moveRight();
      } else {
        this._moveBack();
      }
    });
  };

  removeAccessObject = (index) => {
    let accessObjects = this.state.accessObjects;
    accessObjects.splice(index, 1);
    this.setState({ accessObjects });
  };
  addNewAccessObject = () => {
    let pin = (Math.floor(Math.random() * 10000) + 10000).toString().substring(1);
    let pinExists = false;
    do {
      this.state.accessObjects.forEach((el) => {
        if (el.pin === pin) {
          pinExists = true;
          pin = (Math.floor(Math.random() * 10000) + 10000).toString().substring(1);
        }
      });
    } while (pinExists);

    let newAccessObject = {
      type: 1,
      security: 1,
      eventNum: 14,
      pin: pin,
      card: 0,
    };
    let accessObjects = this.state.accessObjects.concat(newAccessObject);
    this.setState({ accessObjects });
  };

  accessObjectChange = (newAccessObject, index) => {
    let accessObjects = this.state.accessObjects;

    if (!newAccessObject.type) {
      newAccessObject.type = 1;
    }
    if (!newAccessObject.security) {
      newAccessObject.security = 1;
    }
    accessObjects[index] = newAccessObject;
    this.setState({ accessObjects });
  };

  render() {
    const { editMode, accessObjects } = this.state;
    const { device } = this.props.route.params;

    const harmonograms = (
      <FlatList
        data={accessObjects}
        nestedScrollEnabled={true}
        scrollEnabled={false}
        renderItem={({ item, index, separators }) => (
          <View
            key={`${index}${item.type}`}
            style={{
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Animated.View style={[{ opacity: this.state.animationOpacity }]}>
              <TouchableOpacity onPress={() => this.removeAccessObject(index)}>
                <View style={[styles.removeButton]}>
                  <Text
                    style={{
                      color: "white",
                      marginLeft: 1,
                      lineHeight: 16,
                      fontWeight: "bold",
                      fontSize: 10,
                    }}
                  >
                    —
                  </Text>
                </View>
              </TouchableOpacity>
            </Animated.View>

            <Animated.View
              style={{
                marginLeft: this.state.animationMargin,
                width: "100%",
              }}
            >
              <AccessControlSingle
                accessObject={item}
                index={index}
                onAccessObjectChange={this.accessObjectChange}
                events={this.props.events}
                deviceState={this.props.deviceState}
                singleStateReceived={this.props.singleStateReceived}
                device={device}
              />
            </Animated.View>
          </View>
        )}
        keyExtractor={(item, index) => index}
      />
    );

    return this.state.isLoading ? (
      <ScreenView>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <SkottieLoader style={{ width: 40, height: 40, margin: 5 }} />
        </View>
      </ScreenView>
    ) : (
      <ScreenView>
        <View>
          {harmonograms}

          <View
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <ButtonPrimary
              style={{
                marginVertical: 10,
                paddingVertical: 5,
                marginRight: 5,
                width: 100,
              }}
              onPress={this.addNewAccessObject}
            >
              {t("ADD_NEW")}
            </ButtonPrimary>
            <ButtonPrimary
              style={{
                marginVertical: 10,
                paddingVertical: 5,
                marginLeft: 5,
                width: 100,
                backgroundColor: editMode ? "rgb(40,125,40)" : "rgb(200,00,0)",
              }}
              onPress={this.toggleOnPressMode}
            >
              {editMode ? t("DONE") : t("DELETING")}
            </ButtonPrimary>
          </View>
        </View>
      </ScreenView>
    );
  }
}

const styles = StyleSheet.create({
  removeButton: {
    width: 18,
    height: 18,
    backgroundColor: "rgb(200,00,0)",
    color: "white",
    borderRadius: 9,
    // display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginHorizontal: 12,
  },
});

const mapStateToProps = (state, ownProps) => {
  return {
    deviceState: state.statesData.get("states").get(ownProps.route.params.device.get("id")),
    events: state.smartHomeData.get(state.profilesSettings.get("currentProfile")).get("events"),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onRemoveOutdatedAccessControl: (deviceID) => dispatch(removeOutdatedAccessControl(deviceID)),
    singleStateReceived: (deviceID, messageToDispatch) => dispatch(singleStateReceived(deviceID, messageToDispatch)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AccessControlScreen);
