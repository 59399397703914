import React, { Component } from "react";
import { View, TouchableOpacity, Text, Platform } from "react-native";
import { connect } from "react-redux";
import { t } from "../../../services/i18n";

import {
  saveSideMenuColor,
  changeSideMenuSize,
  changeSideMenuVisibility,
  changeOnlyMainGroupsVisibility,
  changePlacesButtonVisibility,
  sideMenuSetByUser,
} from "../../../store/actions";
import RGBPicker from "./RGBPicker";
import PickerSelect from "../../../components/UI/PickerSelect";
import { ScreenView } from "../../../components/UI/screenViewX";
import {
  SwitchStyled,
  SecondaryColorText,
  PrimaryColorText,
} from "../../../components/UI/styledComponents";
import { ListItemContainer } from "../styled";

class SideMenu_PersonalizationScreen extends Component {
  constructor(props) {
    super(props);
    this.sideMenuOptions = [{ label: t("SHORT"), value: "short" }];

    this.state = {
      modalVisible: false,
      colorForPicker: "",
      sideMenuColor: "rgb(	51, 51, 51)",
      sideMenuSize: "short",
    };
  }

  componentDidMount() {
    const { globalSettings, dimensions } = this.props;
    const sideMenuSize = globalSettings.get("sideMenuSize");

    const width = dimensions.get("width");
    if (width >= 800) {
      this.sideMenuOptions.push({ label: t("LONG"), value: "long" });
    }
    this.setState({
      sideMenuColor: globalSettings.get("sideMenuColor")
        ? globalSettings.get("sideMenuColor")
        : "rgb(	51, 51, 51)",

      sideMenuSize: sideMenuSize ? sideMenuSize : "short",
    });
  }

  changeSideMenuVisibilityHandler = (value) => {
    const { globalSettings } = this.props;

    // const sideMenuSize = globalSettings.get("sideMenuSize");
    // if (sideMenuSize && sideMenuSize != "short" && width < 800) {
    // 	this.props.changeSideMenuSize("short");
    // }

    if (!globalSettings.get("sideMenuSetByUser")) {
      this.props.sideMenuSetByUser(true);
    }

    this.props.changeSideMenuVisibility(value);
  };

  changeOnlyMainGroupsVisibilityHandler = (value) => {
    this.props.changeOnlyMainGroupsVisibility(value);
  };

  sideMenuSizeChange = (value) => {
    this.setState({ sideMenuSize: value }, () => {
      if (Platform.OS === "android") {
        this.props.changeSideMenuSize(value);
      }
    });
  };

  sideMenuSizeClose = () => {
    if (Platform.OS === "ios") {
      this.props.changeSideMenuSize(this.state.sideMenuSize);
    }
  };

  changePlacesButtonVisibilityHandler = (value) => {
    this.props.changePlacesButtonVisibility(value);
  };

  setModalVisible = (color) => {
    this.setState(
      {
        colorForPicker: color,
      },
      () => {
        this.setState({ modalVisible: true });
      }
    );
  };

  onCloseModal = (mode, color) => {
    if (mode == "save") {
      // let sideMenuColor = this.state.sideMenuColor;

      this.props.onSaveSideMenuColor(color);

      this.setState({
        colorForPicker: "",
        modalVisible: false,
        sideMenuColor: color,
      });
    } else if (mode == "close") {
      this.setState({
        colorForPicker: "",
        modalVisible: false,
      });
    }
  };

  render() {
    const { modalVisible, colorForPicker, sideMenuColor, sideMenuSize } =
      this.state;

    const { globalSettings, dimensions } = this.props;
    const width = dimensions.get("width");
    const landscapeOrientation = dimensions.get("landscapeOrientation");
    const sideMenuShown = globalSettings.get("sideMenuShown");
    const placesButtonWithSideMenuShown = globalSettings.get(
      "placesButtonWithSideMenuShown"
    );
    const onlyMainGroupsShown = globalSettings.get("onlyMainGroupsShown");

    return (
      <ScreenView>
        {modalVisible && (
          <RGBPicker
            title={t("account:SIDE_MENU_COLOR")}
            modalVisible={modalVisible}
            onCloseModal={this.onCloseModal}
            colorRGB={colorForPicker}
            landscapeOrientation={landscapeOrientation}
          />
        )}
        <ListItemContainer>
          <PrimaryColorText style={{ marginRight: 15, flex: 1 }}>
            {t("account:SHOW_SIDE_MENU")}
          </PrimaryColorText>
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            {width >= 800 || globalSettings.get("sideMenuSetByUser") ? (
              <PickerSelect
                placeholder={{
                  label: t("SELECT_MODE"),
                  value: null,
                }}
                containerStyle={{ marginRight: 20, width: 100 }}
                items={this.sideMenuOptions}
                onValueChange={this.sideMenuSizeChange}
                onClose={this.sideMenuSizeClose}
                value={sideMenuSize}
              />
            ) : null}
            <SwitchStyled
              value={sideMenuShown}
              onValueChange={this.changeSideMenuVisibilityHandler}
            />
          </View>
        </ListItemContainer>
        <ListItemContainer>
          <PrimaryColorText>{t("account:SIDE_MENU_COLOR")}</PrimaryColorText>

          <TouchableOpacity
            onPress={() => this.setModalVisible(sideMenuColor)}
            style={{
              backgroundColor: sideMenuColor,
              height: 24,
              width: 24,
              borderRadius: 24,
              borderWidth: 1,
              borderColor: "#ED695C",
              paddingRight: 5,
            }}
          />
        </ListItemContainer>
        <ListItemContainer>
          <PrimaryColorText style={{ marginRight: 15, flex: 1 }}>
            {t("account:SHOW_ONLY_MAIN_GROUPS")}
          </PrimaryColorText>
          <SwitchStyled
            value={onlyMainGroupsShown}
            onValueChange={this.changeOnlyMainGroupsVisibilityHandler}
          />
        </ListItemContainer>
        <ListItemContainer>
          <PrimaryColorText style={{ marginRight: 15, flex: 1 }}>
            {t("account:SHOW_PLACES_BUTTON_WITH_SIDE_MENU")}
          </PrimaryColorText>
          <SwitchStyled
            value={placesButtonWithSideMenuShown}
            onValueChange={this.changePlacesButtonVisibilityHandler}
          />
        </ListItemContainer>
      </ScreenView>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    globalSettings: state.globalSettings,
    dimensions: state.statesData.get("dimensions"),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSaveSideMenuColor: (color) => dispatch(saveSideMenuColor(color)),
    changeSideMenuVisibility: (sideMenuVisible) =>
      dispatch(changeSideMenuVisibility(sideMenuVisible)),
    changePlacesButtonVisibility: (placesButtonWithSideMenuShown) =>
      dispatch(changePlacesButtonVisibility(placesButtonWithSideMenuShown)),
    changeOnlyMainGroupsVisibility: (onlyMainGroupsShown) =>
      dispatch(changeOnlyMainGroupsVisibility(onlyMainGroupsShown)),
    sideMenuSetByUser: (value) => dispatch(sideMenuSetByUser(value)),
    changeSideMenuSize: (sideMenuSize) =>
      dispatch(changeSideMenuSize(sideMenuSize)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SideMenu_PersonalizationScreen);
