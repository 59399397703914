import React, { Component } from "react";
import { Text, View, TouchableOpacity, StyleSheet } from "react-native";
import { t } from "../../services/i18n";

import PickerSelect from "../../components/UI/PickerSelect";
import {
  StyledIcon,
  PrimaryColorText,
  ComponentContainer,
  SliderStyled,
} from "../../components/UI/styledComponents";
import DateTimePicker from "../../components/UI/dateTimePicker";
import moment from "moment";
import { ThemeContext } from "../../../ThemeContext";

class HeatingHarmonogramFixed extends Component {
  static contextType = ThemeContext;

  state = {
    daysArray: [
      {
        value: 1,
        label: t("weekdays:MON"),
        active: false,
      },
      {
        value: 2,
        label: t("weekdays:TUE"),
        active: false,
      },
      {
        value: 3,
        label: t("weekdays:WED"),
        active: false,
      },
      {
        value: 4,
        label: t("weekdays:THU"),
        active: false,
      },
      {
        value: 5,
        label: t("weekdays:FRI"),
        active: false,
      },
      {
        value: 6,
        label: t("weekdays:SAT"),
        active: false,
      },
      {
        value: 0,
        label: t("weekdays:SUN"),
        active: false,
      },
    ],
    schedule: this.props.schedule,
  };

  componentDidMount() {
    let days = this.state.daysArray.map((day, index) => {
      day.active =
        this.props.schedule.dayMask & (1 << day.value) ? true : false;
      return day;
    });

    this.setState({
      daysArray: days,
    });
  }

  onScheduleChange = (sendValue, key) => {
    let schedule = Object.assign({}, this.state.schedule); //creating copy of object in state

    if (key == "s" || key == "e") {
      let formatedTime = Number(moment(sendValue).format("HHmm"));
      schedule[key] = formatedTime;
    } else if (key == "temp") {
      schedule[key] = sendValue;
    } else if (key == "dayMask") {
      let daysArray = [...this.state.daysArray];
      let indexToChange = this.state.daysArray.findIndex(
        (el) => el.value == sendValue
      );

      daysArray[indexToChange].active = !daysArray[indexToChange].active;

      let newDayMask = 0;

      daysArray.forEach((el) => {
        if (el.active) {
          newDayMask |= 1 << el.value;
        }
      });

      schedule.dayMask = newDayMask;
    }

    this.setState({ schedule }, () => {
      this.props.onScheduleChange(this.state.schedule, this.props.index);
    });
  };

  increaseOne = () => {
    const { device } = this.props;
    if (this.state.schedule.temp < device.get("max"))
      this.onScheduleChange(this.state.schedule.temp + 0.1, "temp");
  };

  decreaseOne = () => {
    const { device } = this.props;
    if (this.state.schedule.temp > device.get("min"))
      this.onScheduleChange(this.state.schedule.temp - 0.1, "temp");
  };

  onSlidingChangeHandler = (value) => {
    //this.onScheduleChange(value, "temp");
  };

  onSlidingCompleteHandler = (value) => {
    this.onScheduleChange(value, "temp");
  };

  onTempValueChange = (value) => {
    this.onScheduleChange(value, "temp");
  };

  render() {
    let { schedule, daysArray } = this.state;

    const { device } = this.props;
    let step = device.get("param1") ? Number(device.get("param1")) : 1;

    let days = daysArray.map((day, index) => {
      return (
        <TouchableOpacity
          onPress={() => this.onScheduleChange(day.value, "dayMask")}
          key={index}
          style={{
            flex: 1,
            alignItems: "center",
          }}
        >
          <PrimaryColorText
            style={{
              paddingBottom: 2,
            }}
          >
            {day.label}
          </PrimaryColorText>

          {day.active ? (
            <StyledIcon name={"check"} color={"primary"} size={20} />
          ) : (
            <StyledIcon name={"x"} color={"secondary"} size={20} />
          )}
        </TouchableOpacity>
      );
    });
    const { theme } = this.context;

    let initialHours = Math.floor(schedule.s / 100).toString();
    if (initialHours.length <= 1) {
      initialHours = "0" + initialHours;
    }
    let initialMinutes = (schedule.s % 100).toString();
    if (initialMinutes.length <= 1) {
      initialMinutes = "0" + initialMinutes;
    }

    let time = moment(`${initialHours}:${initialMinutes}`, "HH:mm").toDate();

    const temp = [];

    temp.push({
      label: t("heating:TEMP_ECO"),
      value: -100.1,
    });
    temp.push({
      label: t("heating:TEMP_CONF"),
      value: -100.0,
    });
    temp.push({
      label: schedule.temp > -100 ? schedule.temp.toFixed(1) + "°C" : "20.0°C",
      value: schedule.temp > -100 ? schedule.temp : 20.0,
    });

    return (
      <View
        style={{
          backgroundColor: theme.COMPONENT_BACKGROUND_COLOR,
          paddingTop: 10,
          paddingHorizontal: 10,
          marginHorizontal: 3,
          marginTop: 3,
          borderWidth: 1,
          borderColor: "gray",
        }}
      >
        <View style={styles.row}>
          <PrimaryColorText
            style={{
              fontWeight: "bold",
              marginRight: 10,
              width: 50,
            }}
          >
            {t("TIME")}:
          </PrimaryColorText>

          <DateTimePicker
            value={moment(time).format("HH:mm")}
            onConfirm={(time) => this.onScheduleChange(time, "s")}
            date={time}
            mode="time"
          />
        </View>
        <View style={styles.row}>
          <PrimaryColorText
            style={{
              fontWeight: "bold",
              marginRight: 10,
              width: 50,
            }}
          >
            {t("TEMP")}:
          </PrimaryColorText>
          {schedule.temp > -100 ? (
            <View
              style={{ flexDirection: "row", flex: 1, alignItems: "center" }}
            >
              <TouchableOpacity
                style={styles.iconButton}
                onPress={this.decreaseOne}
              >
                <StyledIcon name={"minus-circle"} size={18} />
              </TouchableOpacity>
              <SliderStyled
                value={schedule.temp}
                step={step}
                minimumValue={device.get("min")}
                maximumValue={device.get("max")}
                onValueChange={this.onSlidingChangeHandler}
                onSlidingComplete={this.onSlidingCompleteHandler}
              />
              <TouchableOpacity
                style={styles.iconButton}
                onPress={this.increaseOne}
              >
                <StyledIcon name={"plus-circle"} size={18} />
              </TouchableOpacity>
            </View>
          ) : null}
          <PickerSelect
            placeholder={{}}
            containerStyle={{ flex: 1 }}
            items={temp}
            onValueChange={(e) => this.onTempValueChange(e)}
            value={schedule.temp ? schedule.temp : null}
          />
        </View>
        <View style={styles.row}>
          <PrimaryColorText
            style={{
              fontWeight: "bold",
              marginRight: 10,
              width: 50,
            }}
          >
            {t("DAY")}:
          </PrimaryColorText>
          <View style={styles.days}>{days}</View>
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  // row: {
  // 	flexDirection: "row",
  // 	alignItems: "center",
  // 	justifyContent: "space-between",
  // 	margin: 5,
  // 	borderWidth: 0.3,
  // 	borderColor: "gray",
  // 	padding: 5
  // },
  days: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    flex: 1,
  },
  row: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 10,
  },
  row2: {
    // flex: 1,
    flexDirection: "row",
    alignItems: "center",
    // justifyContent: "center"
  },
  hourText: {
    fontWeight: "bold",
    fontSize: 16,
    margin: 6,
  },
  dayText: {
    fontSize: 12,
    color: "white",
  },
  dayCircle: {
    width: 26,
    height: 26,
    borderRadius: 13,
    margin: 2,
    // borderWidth: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  iconButton: {
    padding: 5,
  },
});
export default HeatingHarmonogramFixed;
