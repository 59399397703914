import * as Actions from "../actionTypes";

export const changeAutoLogin = (autoLog) => {
  return { type: Actions.CHANGE_AUTO_LOGIN, payload: autoLog };
};

export const changeInitCalls = (initCalls) => {
  return { type: Actions.CHANGE_INIT_CALLS, payload: initCalls };
};
export const changeLanguage = (language) => {
  return { type: Actions.CHANGE_LANGUAGE, payload: language };
};

export const changeCaller = (caller) => {
  return { type: Actions.CHANGE_CALLER, payload: caller };
};

export const changeCallId = (id) => {
  return { type: Actions.CHANGE_CALL_ID, payload: id };
};

export const changeChangedToLight = (changedToLight) => {
  return {
    type: Actions.SET_CHANGED_TO_LIGHT,
    payload: changedToLight,
  };
};

export const changeQuestionnaire = (questionnaireResponse) => {
  return {
    type: Actions.SET_QUESTIONNAIRE_RESPONSE,
    payload: questionnaireResponse,
  };
};

export const changeAutoLoginType = (autoLogType) => {
  return { type: Actions.CHANGE_AUTO_LOGIN_TYPE, payload: autoLogType };
};

export const changeAppLogout = (appLogout) => {
  return { type: Actions.CHANGE_APP_LOGOUT, payload: appLogout };
};

export const changeUserTheme = (theme) => {
  return { type: Actions.CHANGE_USER_THEME, payload: theme };
};
export const changeThemeBySchedule = (theme) => {
  return { type: Actions.CHANGE_THEME_BY_SCHEDULE, payload: theme };
};
export const changeThemeTurnByScheduleSwitch = (bool) => {
  return { type: Actions.CHANGE_NIGHT_THEME_TURN_BY_SCHEDULE, payload: bool };
};

export const changeGroupIconsBGVisibility = (bool) => {
  return { type: Actions.CHANGE_GROUP_ICONS_BG_VISIBILITY, payload: bool };
};

export const changeDeviceIconsBGVisibility = (bool) => {
  return { type: Actions.CHANGE_DEVICE_ICONS_BG_VISIBILITY, payload: bool };
};

export const setNightThemeSchedule = (schedule) => {
  return { type: Actions.SET_NIGHT_THEME_SCHEDULE, payload: schedule };
};

export const saveIconBGColors = (colorsObject) => {
  return { type: Actions.SAVE_ICON_BG_COLORS, payload: colorsObject };
};

export const saveSideMenuColor = (color) => {
  return { type: Actions.SAVE_SIDE_MENU_COLOR, payload: color };
};

export const changeShowByColumns = (showByColumns) => {
  return { type: Actions.SHOW_BY_COLUMNS, payload: showByColumns };
};
export const changeWeatherVisibility = (weatherVisible) => {
  return { type: Actions.CHANGE_WEATHER_VISIBILITY, payload: weatherVisible };
};

export const changeCalendarVisibility = (calendarVisible) => {
  return { type: Actions.CHANGE_CALENDAR_VISIBILITY, payload: calendarVisible };
};
export const changeGreetingViewVisibility = (greetingViewVisible) => {
  return {
    type: Actions.CHANGE_GREETINIG_VIEW_VISIBILITY,
    payload: greetingViewVisible,
  };
};

export const changeSideMenuVisibility = (sideMenuVisible) => {
  return {
    type: Actions.CHANGE_SIDE_MENU_VISIBILITY,
    payload: sideMenuVisible,
  };
};
export const changeOnlyMainGroupsVisibility = (onlyMainGroupsVisible) => {
  return {
    type: Actions.CHANGE_ONLY_MAIN_GROUPS_VISIBILITY,
    payload: onlyMainGroupsVisible,
  };
};
export const changePlacesButtonVisibility = (placesButtonWithSideMenuShown) => {
  return {
    type: Actions.PLACES_BUTTON_WITH_SIDE_MENU_VISIBILITY,
    payload: placesButtonWithSideMenuShown,
  };
};
export const sideMenuSetByUser = (sideMenuSetByUser) => {
  return {
    type: Actions.SIDE_MENU_SET_BY_USER,
    payload: sideMenuSetByUser,
  };
};
export const changeSideMenuSize = (sideMenuSize) => {
  return {
    type: Actions.CHANGE_SIDE_MENU_SIZE,
    payload: sideMenuSize,
  };
};

export const changeDeviceIdVisibility = (deviceIdVisible) => {
  return {
    type: Actions.CHANGE_DEVICE_ID_VISIBILITY,
    payload: deviceIdVisible,
  };
};

export const changeForceLandscapeOrientation = (forceLandscapeOrientation) => {
  return {
    type: Actions.CHANGE_FORCE_LANDSCAPE_ORIENTATION,
    payload: forceLandscapeOrientation,
  };
};

export const changeInvertedForceLandscapeOrientation = (forceLandscapeOrientation) => {
  return {
    type: Actions.CHANGE_INVERTED_FORCE_LANDSCAPE_ORIENTATION,
    payload: forceLandscapeOrientation,
  };
};

export const changeDisableClickVibrations = (disableClickVibrations) => {
  return {
    type: Actions.CHANGE_DISABLE_CLICK_VIBRATIONS,
    payload: disableClickVibrations,
  };
};

export const changeDashboardTopBarVisibility = (dashboardTopBarVisible) => {
  return {
    type: Actions.CHANGE_DASHBOARD_TOPBAR_VISIBILITY,
    payload: dashboardTopBarVisible,
  };
};
export const changeMessagesIndicatorVisibility = (messagesIndicatorVisible) => {
  return {
    type: Actions.CHANGE_MESSAGES_INDICATOR_VISIBILITY,
    payload: messagesIndicatorVisible,
  };
};

export const changeSimulationVisibility = (simulationDashboardVisible) => {
  return {
    type: Actions.CHANGE_SIMULATION_DASHBOARD_VISIBILITY,
    payload: simulationDashboardVisible,
  };
};

export const changeKeepAwakeSettings = (keepAwakeOn) => {
  return {
    type: Actions.CHANGE_KEEP_AWAKE_SETTINGS,
    payload: keepAwakeOn,
  };
};

export const changeShowAsList = (showAsList) => {
  return {
    type: Actions.SHOW_AS_LIST,
    payload: showAsList,
  };
};

export const changeHideLastChangeTimeOnList = (hide) => {
  return {
    type: Actions.HIDE_LAST_CHANGE_TIME_ON_LIST,
    payload: hide,
  };
};

export const changeStretchSketch = (stretchSketch) => {
  return {
    type: Actions.CHANGE_STRETCH_SKETCH,
    payload: stretchSketch,
  };
};
export const changeShowSketchFirst = (showSketchFirst) => {
  return {
    type: Actions.CHANGE_SHOW_SKETCH_FIRST,
    payload: showSketchFirst,
  };
};
export const changeDeviceUUID = (deviceUUID) => {
  return {
    type: Actions.CHANGE_DEVICE_UUID,
    payload: deviceUUID,
  };
};
export const changeConsentsTime = (consentsTime) => {
  return {
    type: Actions.CHANGE_CONSENTS_TIME,
    payload: consentsTime,
  };
};

export const changeConsentsStatus = (consentsStatus) => {
  return {
    type: Actions.CHANGE_CONSENTS_STATUS,
    payload: consentsStatus,
  };
};
