import React, { Component } from "react";
import { View, TouchableOpacity, Text, Platform } from "react-native";
import { connect } from "react-redux";
import { t } from "../../../../services/i18n";
import { Feather } from "@expo/vector-icons";
import mqttClient from "../../../../services/mqtt";

import {
  ComponentImage,
  ComponentHeaderText,
  ComponentContainerTouchable,
  PrimaryColorText,
  ScreenView,
  StyledIcon,
} from "../../../../components/UI/styledComponents";
import { changeName } from "../../../../store/actions";
import ActionSheet from "../../../../utils/ActionSheet";
import ModalSimple from "../../../../components/UI/ModalSimple";

class DraggableItem extends Component {
  state = {
    name: this.props.item.label,
    changeNameDialogVisible: false,
    showWebActionSheet: false,
  };

  showActionSheet = () => {
    if (Platform.OS != "web") {
      this.ActionSheet.show();
    } else {
      this.setState({ showWebActionSheet: true });
    }
  };

  onNameChangeHandler = (name) => {
    this.setState({ name });
  };

  handleOnPressAction = (index) => {
    let { customWidth, key } = this.props.item;
    if (Platform.OS == "web") {
      this.setState({ showWebActionSheet: false });
    }
    //change name
    if (index == 0) {
      this.setState({ changeNameDialogVisible: true });
    }

    //change width
    if (this.props.type == "device" && index == 1) {
      let newWidth = customWidth == 1 ? 0 : 1;
      this.props.onChangeWidth(newWidth, key);
    }
  };

  handleChangeNameOK = (key) => {
    this.setState({ changeNameDialogVisible: false });

    this.props.onChangeName(this.props.item.key, this.state.name, `${this.props.type}s`);

    mqttClient.changeName(this.props.item.key, this.state.name, `${this.props.type}`);
  };

  render() {
    const { item, index, drag, isActive, theme, navigateToGroup, type, serverInfo, dimensions } = this.props;
    const { changeNameDialogVisible, name } = this.state;
    const width = dimensions.get("width");
    let permissionForEditName = (serverInfo.get("perm") & 0x40) == 0;
    let { customWidth } = this.props.item;
    let changeWidthText = customWidth ? t("account:SHOW_COLUMN_WIDTH") : t("account:SHOW_FULL_WIDTH");

    return (
      <ComponentContainerTouchable
        containerStyle={{ width: "100%" }}
        // delayLongPress={2000}
        onLongPress={drag}
        style={{
          backgroundColor: isActive ? "#ccc" : theme.COMPONENT_BACKGROUND_COLOR,
          alignItems: "center",
          width: "90%",
          alignSelf: "center",
        }}
      >
        {changeNameDialogVisible && (
          <View style={{ flex: 1 }}>
            <ModalSimple
              isVisible={changeNameDialogVisible}
              title={"CHANGE_NAME"}
              onInputChange={this.onNameChangeHandler}
              onClose={() => {
                this.setState({ changeNameDialogVisible: false });
              }}
              onOK={this.handleChangeNameOK}
              value={name}
            />
          </View>
        )}

        {permissionForEditName && type != "scene" ? (
          <TouchableOpacity onPress={this.showActionSheet} style={{ paddingHorizontal: 10, marginLeft: -5 }}>
            <StyledIcon name={"more-vertical"} size={18} color={"secondary"} />
          </TouchableOpacity>
        ) : null}
        {type == "group" ? (
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              flex: 1,
              alignItems: "center",
            }}
          >
            <PrimaryColorText style={{ fontWeight: "bold" }}>{item.label}</PrimaryColorText>
            <TouchableOpacity onPress={navigateToGroup} style={{ paddingHorizontal: 10 }}>
              <StyledIcon name={"chevron-right"} size={24} color={"secondary"} />
            </TouchableOpacity>
          </View>
        ) : (
          <PrimaryColorText style={{ fontWeight: "bold", flex: 1, width: "100%" }}>
            {name} {item.deviceIdShown ? `(${item.key})` : ""}
          </PrimaryColorText>
        )}

        {this.props.type == "device" || width >= 600 ? (
          <ActionSheet
            actionRef={(o) => (this.ActionSheet = o)}
            options={[t("CHANGE_NAME"), changeWidthText, t("CLOSE")]}
            cancelButtonIndex={2}
            onPress={(index) => this.handleOnPressAction(index)}
            onWebActionSheetClose={() => this.setState({ showWebActionSheet: false })}
            showWebActionSheet={this.state.showWebActionSheet}
          />
        ) : (
          <ActionSheet
            actionRef={(o) => (this.ActionSheet = o)}
            options={[t("CHANGE_NAME"), t("CLOSE")]}
            cancelButtonIndex={1}
            onPress={(index) => this.handleOnPressAction(index)}
            onWebActionSheetClose={() => this.setState({ showWebActionSheet: false })}
            showWebActionSheet={this.state.showWebActionSheet}
          />
        )}
      </ComponentContainerTouchable>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    serverInfo: state.statesData.get("server").get("serverInfo"),
    dimensions: state.statesData.get("dimensions"),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onChangeName: (objectID, newName, objectType) => dispatch(changeName(objectID, newName, objectType)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(DraggableItem);
