import React, { useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { FlatList, View } from "react-native";
import { ScreenView } from "../../components/UI/screenViewX";
import { NoDataScreen } from "../../components/UI/styledComponents";
import PlaceGroupComponent from "./PlaceGroupComponent";
import { groupsForGroups } from "../../utils";

const PlacesScreen = (props) => {
  const { groups, order, dimensions } = props;
  const listOfGroups = useMemo(() => groupsForGroups(groups, 0, order), [groups, order]);
  return listOfGroups && dimensions ? (
    <ScreenView>
      {listOfGroups.map((group) => (
        <PlaceGroupComponent groupId={group.get("id")} key={group.get("id")} />
      ))}

      {/* {dimensions.get("numberOfColumns") === 1 && (
        <FlatList
          key={1}
          data={listOfGroups.toJS()}
          nestedScrollEnabled={true}
          numColumns={1}
          scrollEnabled={false}
          renderItem={({ item, index, separators }) => {
            return <PlaceGroupComponent groupId={item.id} key={item.id} />;
          }}
          keyExtractor={(item, index) => "1" + item.id}
        />
      )}
      {dimensions.get("numberOfColumns") === 2 && (
        <FlatList
          key={2}
          data={listOfGroups.toJS()}
          nestedScrollEnabled={true}
          numColumns={2}
          scrollEnabled={false}
          renderItem={({ item, index, separators }) => {
            return <PlaceGroupComponent groupId={item.id} key={item.id} />;
          }}
          keyExtractor={(item, index) => "2" + item.id}
        />
      )}
      {dimensions.get("numberOfColumns") === 3 && (
        <FlatList
          key={3}
          data={listOfGroups.toJS()}
          nestedScrollEnabled={true}
          numColumns={3}
          scrollEnabled={false}
          renderItem={({ item, index, separators }) => {
            return <PlaceGroupComponent groupId={item.id} key={item.id} />;
          }}
          keyExtractor={(item, index) => "3" + item.id}
        />
      )} */}
    </ScreenView>
  ) : (
    <NoDataScreen />
  );
};

const mapStateToProps = (state) => {
  return {
    dimensions: state.statesData.get("dimensions"),
    groups: state.smartHomeData.get(state.profilesSettings.get("currentProfile")).get("groups"),

    order: state.profilesSettings.get(state.profilesSettings.get("currentProfile")).get("order"),
  };
};

export default connect(mapStateToProps)(PlacesScreen);
