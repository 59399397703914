import i18next from "i18next";

//
import { I18nManager } from "react-native";

import * as config from "./config/i18n";

import languageDetector from "./language-dector";
import translationLoader from "./translation-loader";
import { changeLanguage } from "../../store/actions";
import { store } from "../../store/configureStore";

const i18n = {
  init: () => {
    return new Promise((resolve, reject) => {
      const lang = store.getState().globalSettings.get("language");

      i18next
        //taking localization from expo module
        .use(translationLoader)
        .use(languageDetector)

        //w docsach jest jeszcze
        //.use(reactI18nextModule) jakoimport { reactI18nextModule } from 'react-i18next' ale jeszcze nie wiem dlaczego ja tego nie uzwyam

        .init(
          {
            //falback
            fallbackLng: config.fallback,
            //namespace + df namespace

            //tu w doccach jescze mają: resources: ale u mnie jakoś to załatwia translation loader
            ns: config.namespaces,
            defaultNS: config.defaultNamespace,
            debug: false,
            lng: lang,
            // interpolation not needed for react as it does escape per default to prevent xss!
            interpolation: {
              escapeValue: false,
            },
          },
          (error) => {
            if (error) {
              return reject(error);
            }
          }
        );
    });
  },
  changeLanguage: (language) => {
    return new Promise((resolve, reject) => {
      i18next.changeLanguage(language, (error) => {
        if (error) {
          return reject(error);
        }

        //dispatching language change
        changeLanguage(language);
        return resolve(language);
      });
    });
  },
  t: (key, option) => i18next.t(key, option),
  get locale() {
    return i18next.language;
  },

  get dir() {
    //to sprawdza direction received from i18n by languge used

    return i18next.dir().toUpperCase();
  },

  // to w ogole jest nie uzywane wiec komentuje
  get isRTL() {
    //to sprawdza direction receive from react native

    return I18nManager.isRTL;
  },

  select(map) {
    const key = this.isRTL ? "rtl" : "ltr";
    return map[key];
  },
};

export const t = i18n.t;

export default i18n;
