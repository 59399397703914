import React, { Component } from "react";
import { View, TouchableOpacity, Text, TextInput, Platform, ActivityIndicator } from "react-native";
import { connect } from "react-redux";
import { t } from "../../../services/i18n";
import mqttClient from "../../../services/mqtt";

import ModalSimple from "../../../components/UI/ModalSimple";
import { ScreenView } from "../../../components/UI/screenViewX";
import { TitleWithLines, PrimaryColorText, TextInputStyled } from "../../../components/UI/styledComponents";

import { ListItemContainer } from "../styled";
import { SkottieLoader } from "../../../components/animatedComponents/Loader";

class Parameters_AccountScreen extends Component {
  state = {
    price: this.props.userVariables.get(2).get("wartoscS").toString(),
    currency: this.props.userVariables.get(1).get("wartoscS"),
    city: this.props.serverInfo.get("city"),
    cityEditable: "",
    coord: {
      lon: this.props.serverInfo.get("lon"),
      lat: this.props.serverInfo.get("lat"),
    },

    cityDialogVisible: false,
    error: "",
    loading: false,
  };

  onInputChange = (value, key) => {
    this.setState({ [key]: value });
  };

  showCityDialog = () => {
    this.setState({ cityDialogVisible: true });
  };
  handleCityDialogClose = () => {
    this.setState({ cityDialogVisible: false });
  };

  fetchWeatherApi = async () => {
    let cityEditable = this.state.cityEditable;
    if (cityEditable.endsWith(" ")) {
      cityEditable = cityEditable.slice(0, -1);
    }
    const APIkey = "11f7f8c147bae185162e1a152301e698";
    let url = `http://api.openweathermap.org/data/2.5/weather?APPID=${APIkey}&units=metric&q=${cityEditable}`;

    return fetch(url)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw this.setState({
            error: t("account:CANNOT_FETCH_LOCATION"),
            loading: false,
          });
        }
      })
      .then((dane) => {
        this.setState({ coord: dane.coord, loading: false, city: dane.name });

        let payload = {
          lat: dane.coord.lat,
          lon: dane.coord.lon,
          city: dane.name,
        };
        mqttClient.editserverLocation(payload);
      })
      .catch((err) => {
        this.setState({
          error: t("account:CANNOT_FETCH_LOCATION"),
          loading: false,
        });
      });
  };

  handleCityDialogOK = () => {
    this.fetchWeatherApi();
    this.setState({ error: "", cityDialogVisible: false, loading: true });
  };

  handleErrorDialogOK = () => {
    this.setState({ errorDialogVisible: false, loading: false });
  };

  onInputEndEditing = (key) => {
    const { userVariables } = this.props;
    const { currency, price, city } = this.state;
    let id;
    let value;
    if (key == "price") {
      value = price;
      id = userVariables.get(2).get("id");
      mqttClient.editUserVariables(value, id);
    } else if (key == "currency") {
      value = currency;
      id = userVariables.get(1).get("id");
      mqttClient.editUserVariables(value, id);
    } else if (key == "cityEditable") {
      this.fetchWeatherApi();
      this.setState({ cityDialogVisible: false, loading: true });
    }
  };

  render() {
    const { serverInfo } = this.props;
    const { price, currency, city, coord, error } = this.state;
    let permissionForEditPower = (serverInfo.get("perm") & 0x20) == 0;

    return (
      <ScreenView>
        <TitleWithLines textInside={t("ENERGY")} />
        {permissionForEditPower ? (
          <ListItemContainer style={{ flexDirection: "row" }}>
            <View style={{ flex: 1 }}>
              <PrimaryColorText>{t("PRICE")}:</PrimaryColorText>
              <TextInputStyled
                keyboardType={"numeric"}
                value={price}
                // style={{ flex: 1 }}
                onEndEditing={() => this.onInputEndEditing("price")}
                placeholder={t("PRICE_PER_1KWH")}
                onChangeText={(value) => this.onInputChange(value, "price")}
              />
            </View>
            <View style={{ flex: 1, marginLeft: 10 }}>
              <PrimaryColorText>{t("CURRENCY")}:</PrimaryColorText>
              <TextInputStyled
                value={currency}
                // style={{ marginLeft: 5, flex: 1 }}
                placeholder={t("CURRENCY")}
                onEndEditing={() => this.onInputEndEditing("currency")}
                onChangeText={(value) => this.onInputChange(value, "currency")}
              />
            </View>
            <View
              style={{
                paddingLeft: 5,
                width: 50,
                alignSelf: "flex-end",
                paddingBottom: 5,
              }}
            >
              <PrimaryColorText>/ 1kWh</PrimaryColorText>
            </View>
          </ListItemContainer>
        ) : (
          <ListItemContainer>
            <PrimaryColorText style={{ flex: 1 }}>
              Price: {price} {currency} / 1kWh
            </PrimaryColorText>
          </ListItemContainer>
        )}
        <TitleWithLines textInside={t("account:SERVER_LOCATION")} />
        <ListItemContainer>
          {city == undefined ? (
            <PrimaryColorText style={{ flex: 1 }}>{t("PLEASE_UPDATE_SERVER")}</PrimaryColorText>
          ) : this.state.loading ? (
            <SkottieLoader style={{ width: 23, height: 23, margin: 3 }} />
          ) : (
            <TouchableOpacity onPress={this.showCityDialog} style={{ justifyContent: "center", width: "100%" }}>
              {!city ? (
                <PrimaryColorText>{t("weather:ENTER_CITY_NAME")}</PrimaryColorText>
              ) : error ? (
                <PrimaryColorText>{error}</PrimaryColorText>
              ) : (
                <View>
                  <PrimaryColorText style={{ paddingBottom: 10 }}>
                    {t("weather:CITY_NAME")}:&nbsp;{city}
                  </PrimaryColorText>
                  <View style={{ flexDirection: "row" }}>
                    <View style={{ width: "50%" }}>
                      <PrimaryColorText>
                        {t("account:COORD_LON")}:&nbsp;
                        {Math.round(coord.lon * 100) / 100}&deg;
                      </PrimaryColorText>
                    </View>
                    <View style={{ width: "50%" }}>
                      <PrimaryColorText>
                        {t("account:COORD_LAT")}:&nbsp;
                        {Math.round(coord.lat * 100) / 100}&deg;
                      </PrimaryColorText>
                    </View>
                  </View>
                </View>
              )}
            </TouchableOpacity>
          )}
        </ListItemContainer>

        <ModalSimple
          isVisible={this.state.cityDialogVisible}
          title={"weather:ENTER_CITY_NAME"}
          placeholder={"weather:CITY_NAME"}
          onInputChange={(value) => this.onInputChange(value, "cityEditable")}
          onClose={this.handleCityDialogClose}
          onOK={this.handleCityDialogOK}
        />
      </ScreenView>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userVariables: state.smartHomeData.get(state.profilesSettings.get("currentProfile")).get("userVariables"),
    serverInfo: state.statesData.get("server").get("serverInfo"),
  };
};

export default connect(mapStateToProps)(Parameters_AccountScreen);
