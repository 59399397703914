import React, { Component } from "react";
import {
  View,
  TouchableOpacity,
  Text,
  Platform,
  ScrollView,
} from "react-native";
import { connect } from "react-redux";
import { t } from "../../../../services/i18n";
import { Feather } from "@expo/vector-icons";
import DraggableItem from "./DraggableItem";
import { KeyboardAvoidingView } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import DraggableFlatList from "react-native-draggable-flatlist";
import {
  ComponentImage,
  ComponentHeaderText,
  ComponentContainerTouchable,
  PrimaryColorText,
  ScreenView,
  StyledIcon,
  TitleWithLines,
} from "../../../../components/UI/styledComponents";
import { groupsForGroups } from "../../../../utils";

import { saveOrder } from "../../../../store/actions";

import { ThemeContext } from "../../../../../ThemeContext";
class Groups_Order_PersonalizationScreen extends Component {
  state = {
    groupsData: null,
  };

  static contextType = ThemeContext;

  componentDidMount() {
    this.setData();
  }
  setData = () => {
    const { groups, order } = this.props;
    const groupsData = [];
    let filteredGroups = groupsForGroups(groups, 0, order);

    filteredGroups
      ? filteredGroups.forEach((group, index) => {
          if (group && group.get("id_rodzica") == 0) {
            groupsData.push({
              key: `${group.get("id")}`,
              label: `${group.get("opis_menu")}`,
            });
          }
        })
      : null;
    this.setState({ groupsData });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.groups != this.props.groups) {
      this.setData();
    }
  }

  renderGroupItem = ({ item, index, drag, isActive }) => {
    const { theme } = this.context;
    return (
      <DraggableItem
        navigateToGroup={() => {
          this.props.navigation.navigate("Devices_Order_Personalization", {
            itemId: item.key,
            placeName: item.label,
          });
        }}
        theme={theme}
        item={item}
        index={index}
        drag={drag}
        isActive={isActive}
        type={"group"}
      />
    );
  };

  componentWillUnmount() {
    let groupOrderArray = this.state.groupsData.map((el) => el.key);
    this.props.onSaveOrder(groupOrderArray, "groups");
  }

  render() {
    const { groupsData } = this.state;
    const { theme } = this.context;
    return (
      <View
        style={{
          flex: 1,
          backgroundColor: theme.APP_BACKGROUND_COLOR,
          padding: 5,
        }}
      >
        <ComponentContainerTouchable
          containerStyle={{ width: "100%" }}
          style={{
            width: "90%",
            alignSelf: "center",
            alignItems: "center",
            marginBottom: 20,
          }}
          onPress={() => {
            this.props.navigation.navigate("Devices_Order_Personalization", {
              itemId: 0,
              placeName: t("DASHBOARD"),
            });
          }}
        >
          <PrimaryColorText style={{ fontWeight: "bold" }}>
            {t("DASHBOARD")}
          </PrimaryColorText>

          <StyledIcon name={"chevron-right"} size={24} color={"secondary"} />
        </ComponentContainerTouchable>

        {groupsData ? (
          <DraggableFlatList
            data={groupsData}
            renderItem={this.renderGroupItem}
            keyExtractor={(item, index) => {
              if (item) {
                return `draggable-item-${item.key}`;
              } else {
                return `draggable-item-${index}`;
              }
            }}
            onDragEnd={({ data }) => this.setState({ groupsData: data })}
          />
        ) : null}
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  let currentProfile = state.profilesSettings.get("currentProfile");

  return {
    groups: state.smartHomeData.get(currentProfile).get("groups"),
    order: state.profilesSettings.get(currentProfile).get("order"),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSaveOrder: (order, groupId) => dispatch(saveOrder(order, groupId)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Groups_Order_PersonalizationScreen);
