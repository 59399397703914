import React, { useState, useContext, useEffect, useMemo } from "react";
import { connect } from "react-redux";
import Sketch_PlacesScreen from "./Sketch_PlacesScreen";
import { ScreenView } from "../../components/UI/screenViewX";
import { NoDataScreen, TitleWithLines } from "../../components/UI/styledComponents";

import { scenesForGroups } from "../../utils";

import { useTheme } from "../../../ThemeContext";
import { devicesForGroups, SceneInGroup, DevicesToShowByColumns, groupsForGroups } from "../../utils";
import PlaceGroupComponent from "./PlaceGroupComponent";
import { MaterialCommunityIcons } from "@expo/vector-icons";

import { TouchableOpacity, FlatList } from "react-native";

import { t } from "../../services/i18n";

const Devices_PlacesScreen = ({ route, navigation, showSketchFirstProp, devices, group_devices, groups, scenes, order }) => {
  const { theme } = useTheme();
  const { group } = route.params;
  const { auto } = route.params;
  const itemId = group ? group.get("id") : -1;

  const listOfGroups = useMemo(() => groupsForGroups(groups, itemId, order), [groups, itemId, order]);

  const listOfDevices = useMemo(() => devicesForGroups(group_devices, itemId, order), [group_devices, itemId, order]);

  const listOfScenes = useMemo(() => scenesForGroups(scenes, itemId, order), [scenes, itemId, order]);

  useEffect(() => {}, []);

  const [showSketchFirst, setShowSketchFirst] = useState(false);
  let showCornerIcon = group?.get("zdjecie") && !(group.get("params") & 0x02) > 0 ? true : false;
  useEffect(() => {
    let myShowSketchFirst = showSketchFirstProp;
    if (myShowSketchFirst == undefined) {
      myShowSketchFirst = true;
    }

    navigation.setOptions({
      headerRight: () =>
        showCornerIcon ? (
          <TouchableOpacity style={{ padding: 10 }} onPress={() => changeMode()}>
            <MaterialCommunityIcons
              color={theme.TAB_BAR_TEXT_COLOR_HIGHLITED}
              size={20}
              name={myShowSketchFirst ? "format-list-bulleted" : "floor-plan"}
            />
          </TouchableOpacity>
        ) : null,
    });
    setShowSketchFirst(myShowSketchFirst);
    if (auto) {
      window.app.setGoToGroup(false);
    }
  }, [, route.params.group]);

  useEffect(() => {
    navigation.setOptions({
      headerRight: () =>
        showCornerIcon ? (
          <TouchableOpacity style={{ padding: 10 }} onPress={() => changeMode()}>
            <MaterialCommunityIcons
              color={theme.TAB_BAR_TEXT_COLOR_HIGHLITED}
              size={20}
              name={showSketchFirst ? "format-list-bulleted" : "floor-plan"}
            />
          </TouchableOpacity>
        ) : null,
    });
  }, [showSketchFirst]);

  const changeMode = () => {
    setShowSketchFirst((prevState) => !prevState);
  };

  let lista;
  let scenyDoWyswietlenie;
  let daneDoWyswitelenie;
  let currentGroup;

  if (itemId >= 0) {
    lista =
      listOfGroups && listOfGroups.size > 0
        ? listOfGroups.map((group) => {
            return <PlaceGroupComponent key={"groups"} groupId={group.get("id")} key={group.get("id")} />;
          })
        : null;

    scenyDoWyswietlenie =
      listOfScenes && listOfScenes.size > 0
        ? listOfScenes.map((scene) => {
            return <SceneInGroup key={scene.get("sceneName")} scene={scene} />;
          })
        : null;

    daneDoWyswitelenie = (
      <DevicesToShowByColumns listOfDevices={listOfDevices} devices={devices} customBg={group.get("bg") ? true : false} key={"devices"} />
    );
    currentGroup = groups.get(itemId);
  }

  let renderComponentsInOrder = () => {
    let generalOrder = order && order.get("general") ? order.get("general") : ["scenes", "groups", "devices"];

    const showBars = scenyDoWyswietlenie || lista;
    //const showBars = false;

    const componentsInOrder = generalOrder.map((c) => {
      switch (c) {
        case "scenes":
          return (
            <React.Fragment key={"scenes"}>
              {showBars && scenyDoWyswietlenie ? <TitleWithLines textInside={t("SCENES")} /> : null}
              {scenyDoWyswietlenie}
            </React.Fragment>
          );

        case "devices":
          return (
            <React.Fragment key={"devices"}>
              {showBars && listOfDevices && listOfDevices.size > 0 ? <TitleWithLines textInside={t("account:DEVICES")} /> : null}
              {daneDoWyswitelenie}
            </React.Fragment>
          );

        case "groups":
          return (
            <React.Fragment key={"groups"}>
              {showBars && lista ? <TitleWithLines textInside={t("GROUPS")} /> : null}
              {lista}
            </React.Fragment>
          );
      }
    });

    {
      /* <React.Fragment key={Component.name}>
            {Component.component && Component.component.length !== 0 ? <TitleWithLines textInside={t(Component.name)} /> : null}
            {Component.component}
          </React.Fragment> */
    }

    return componentsInOrder.map((Component) => {
      if (Component) {
        return Component;
      }
    });
  };

  return showSketchFirst && group?.get("zdjecie") && currentGroup ? (
    <Sketch_PlacesScreen
      key={currentGroup.get("id")}
      currentGroup={currentGroup}
      listOfDevices={listOfDevices}
      devices={devices}
      navigation={navigation}
    />
  ) : (lista && lista.size) || (scenyDoWyswietlenie && scenyDoWyswietlenie.size) || (listOfDevices && listOfDevices.size) ? (
    <ScreenView bg={group?.get("bg")}>{renderComponentsInOrder()}</ScreenView>
  ) : (
    <NoDataScreen />
  );
};

const mapStateToProps = (state) => {
  let currentProfile = state.profilesSettings.get("currentProfile");
  return {
    group_devices: state.smartHomeData.get(currentProfile).get("group_devices"),
    devices: state.smartHomeData.get(currentProfile).get("devices"),
    groups: state.smartHomeData.get(currentProfile).get("groups"),
    scenes: state.smartHomeData.get(currentProfile).get("scenes"),
    order: state.profilesSettings.get(currentProfile).get("order"),
    showSketchFirstProp: state.globalSettings.get("showSketchFirst"),
  };
};

export default connect(mapStateToProps)(Devices_PlacesScreen);
