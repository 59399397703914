import React, { PureComponent } from "react";
import { View } from "react-native";
import * as Localization from "expo-localization";
import "moment/min/locales";
import { ComponentContainer, SecondaryColorText, PrimaryColorText } from "../../../components/UI/styledComponents";
import nameDays from "./nameDays";
import moment from "moment";

class CalendarComponent extends PureComponent {
  state = {
    time: new Date(),
    fontSize: 50,
  };

  componentDidMount() {
    this.intervalID = setInterval(() => this.tick(), 1000 * 60);
  }
  componentWillUnmount() {
    clearInterval(this.intervalID);
  }

  tick() {
    this.setState({
      time: new Date(),
    });
  }

  chooseMomentLocale = (locale) => {
    // make the locale lower case
    // will fix crashes caused by "en-GB" (instead of "en-gb") not being found
    locale = locale.toLowerCase();
    if (moment.locales().includes(locale)) {
      // check if the locale is included in the array returned by `locales()` which (in this case) tells us which locales moment will support
      return locale;
    } else if (moment.locales().includes(locale.substring(0, 2))) {
      // check if the first two letters of the locale are included in the array returned by `locales()` which (in this case) tells us which locales moment will support
      // will fixes crashes caused by "en-US" not being found, as we'll tell moment to load "en" instead
      return locale.substring(0, 2);
    }
    // use "en-gb" (the default language and locale for my app) as a fallback if we can't find any other locale
    return "en-gb";
  };

  onLayout = ({ nativeEvent }) => {
    let { width, height } = nativeEvent.layout;
    let fontSize = width / 6 < 80 ? width / 6 : 80;
    this.setState({ fontSize });
  };

  render() {
    const { time, fontSize } = this.state;

    var date = "";
    try {
      date = moment(time).locale(this.chooseMomentLocale(Localization.locale)).format("D MMMM YYYY, dddd");
    } catch (ex) {
      date = moment(time).format("D MMMM YYYY, dddd");
    }

    let clockTime = moment(time).format("HH : mm");
    let dateForNameDay = moment(time).format("MM-DD");
    let namesElement = nameDays.find((el) => {
      return el.includes(dateForNameDay);
    });

    let names = namesElement ? namesElement.slice(namesElement.indexOf("|") + 2) : "";

    return (
      <ComponentContainer
        containerStyle={[{ width: this.props.middleWidth ? "50%" : "100%" }, this.props.containerStyle]}
        style={[
          {
            alignItems: "center",
            flex: 1,
            justifyContent: "center",
          },
        ]}
        onLayout={this.onLayout}
      >
        <SecondaryColorText style={{ fontSize: fontSize / 3 }}>{date}</SecondaryColorText>
        <PrimaryColorText style={{ fontSize: fontSize, fontWeight: "bold" }}>{clockTime}</PrimaryColorText>
        {Localization.locale.startsWith("pl") ? <SecondaryColorText>{names}</SecondaryColorText> : null}
      </ComponentContainer>
    );
  }
}

export default CalendarComponent;
