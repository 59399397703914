import React, { useEffect, useState } from "react";
import { Dimensions, PixelRatio, StyleSheet, View, Text } from "react-native";
import Animated, { useSharedValue, useDerivedValue, interpolateColor, useAnimatedReaction, runOnJS } from "react-native-reanimated";

import { Cursor } from "./Cursor";
import { CircularProgress } from "./CircularProgress";
import { useTheme } from "../../../../../ThemeContext";
import LinearGradient from "react-native-linear-gradient";
import { canvas2Polar, polar2Canvas } from "./Coordinates";
import { PrimaryColorText } from "../../../UI/styledComponents";
import { Ionicons } from "@expo/vector-icons";
import { Svg, Defs, RadialGradient, Stop, Circle } from "react-native-svg";

const { width } = Dimensions.get("window");
const size = width - 80;
const STROKE_WIDTH = 30;
const r = PixelRatio.roundToNearestPixel(size / 2);

const defaultTheta = canvas2Polar({ x: 0, y: 0 }, { x: r, y: r }).theta;
const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 20,
  },
  content: {
    width: r * 2,
    height: r * 2,
  },

  textContainer: {
    position: "absolute",
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  textStyle: {
    fontSize: 24, // Example font size
    fontWeight: "bold", // Example font weight

    // Add more styling as needed
  },
  textStyleMeasure: {
    fontSize: 20, // Example font size
  },
});

export const CircularSlider = ({ min, max, value, onChange, measureTemp, modeIcon, modeIconColor, cooling, step }) => {
  const theta = useSharedValue((2 * Math.PI * (max - value)) / (max - min));
  const { theme } = useTheme();

  const [regValueState, setRegValueState] = useState(value);

  const backgroundColor = useDerivedValue(() => {
    return interpolateColor(theta.value, [0, Math.PI, Math.PI * 2], ["red", "orange", "blue"]);
  });

  useAnimatedReaction(
    () => theta.value,
    (current, previous) => {
      if (current !== previous && previous !== null) {
        const regValue = Math.round((((Math.PI * 2 - current) / (Math.PI * 2)) * (max - min) + min) / step) * step;
        //console.log(step, regValue);

        runOnJS(onChange)(Number(regValue));
        runOnJS(setRegValueState)(Number(regValue));
      }
    },
    [theta] // dependencies array, re-run the reaction if any of these shared values change
  );

  const regValue = Math.round((((Math.PI * 2 - theta.value) / (Math.PI * 2)) * (max - min) + min) / step) * step;

  const radius = 100;

  return (
    <View style={styles.container}>
      <View style={styles.content}>
        <Animated.View style={StyleSheet.absoluteFill}>
          <CircularProgress backgroundColor={backgroundColor} strokeWidth={STROKE_WIDTH} {...{ r }} {...{ theta }} />
          <View style={styles.textContainer}>
            <Svg height="100%" width="100%" style={StyleSheet.absoluteFill}>
              <Defs>
                <RadialGradient id="grad" cx="50%" cy="50%" r={radius} gradientUnits="userSpaceOnUse">
                  <Stop offset="0%" stopColor={cooling ? "blue" : "red"} stopOpacity="0.15" />
                  <Stop offset="80%" stopColor="#000000" stopOpacity="0.0" />
                  <Stop offset="100%" stopColor="#000000" stopOpacity="0" />
                </RadialGradient>
              </Defs>
              <Circle cx="50%" cy="50%" r={radius} fill="url(#grad)" />
            </Svg>
          </View>
        </Animated.View>
        <View style={styles.textContainer}>
          <View style={{ flexDirection: "column", alignItems: "center" }}>
            <Ionicons name={modeIcon} size={24} color={modeIconColor} />
            <PrimaryColorText style={styles.textStyle} bold>
              {regValue.toFixed(1)} &deg;C
            </PrimaryColorText>
            <PrimaryColorText style={styles.textStyleMeasure}>{measureTemp} &deg;C</PrimaryColorText>
          </View>
        </View>

        <Cursor strokeWidth={STROKE_WIDTH} r={r - STROKE_WIDTH / 2} backgroundColor={backgroundColor} {...{ theta }} />
      </View>
    </View>
  );
};
