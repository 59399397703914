import { store } from "../store/configureStore";
import React, { Dimensions } from "react-native";

export const calculateDimmenions = (width, height) => {
  let showByColumns = store.getState().globalSettings.get("showByColumns") == undefined ? true : store.getState().globalSettings.get("showByColumns");
  let numberOfColumns = 1;
  let calculatedNumberOfColumns = 1;
  let smallTiles = false;

  //console.log("Calculate dimensions: ", width, height);

  if (showByColumns) {
    if (width > 900) {
      numberOfColumns = 3;
      calculatedNumberOfColumns = 3;
    } else if (width > 600) {
      numberOfColumns = 2;
      calculatedNumberOfColumns = 2;
    } else {
      calculatedNumberOfColumns = 2;
      if (store.getState().globalSettings.get("showList")) {
        smallTiles = false;
        numberOfColumns = 1;
      } else {
        smallTiles = true;
        numberOfColumns = 2;
      }
    }
  }

  let newDimensions = {
    width: width,
    height: height,
    landscapeOrientation: width > height,
    columnWidth: width / numberOfColumns,
    numberOfColumns,
    calculatedNumberOfColumns,
    smallTiles,
  };

  return newDimensions;
};
