import React, { Component, useState } from "react";
import { View, TouchableOpacity, Text } from "react-native";
import { connect } from "react-redux";
import { fromJS } from "immutable";
import { FontAwesome } from "@expo/vector-icons";
import { t } from "../../services/i18n";
import { scenesReceived } from "../../store/actions";

import { TabView, SceneMap, TabBar } from "react-native-tab-view";
import { useTheme } from "../../../ThemeContext";
import SceneScreenChild from "./SceneScreenChild";
import { scenesForScenes } from "../../utils";

const ScenesScreen = (props) => {
  let listOfScenes = scenesForScenes(props.scenes, props.order);

  const foundMyScenes = listOfScenes.filter((scene) => scene && scene.get("userName") === props.userName);
  let permissionToAllScenes = props && (props.permission & 0x40000) == 0;
  const { theme } = useTheme();
  const [index, setIndex] = useState(foundMyScenes.size > 0 ? 0 : 1);
  const [routes] = useState([
    { key: "mine", title: t("MINE") },
    { key: "all", title: t("account:ALL") },
  ]);

  const renderScene = ({ route }) => {
    switch (route.key) {
      case "mine":
        return <SceneScreenChild {...props} route={route} listOfScenes={foundMyScenes} index={index} />;
      case "all":
        return <SceneScreenChild {...props} route={route} listOfScenes={listOfScenes} index={index} />;
      default:
        return null;
    }
  };

  return !permissionToAllScenes ? (
    <SceneScreenChild {...props} route={{ key: "mine" }} listOfScenes={foundMyScenes} index={0} />
  ) : (
    <TabView
      renderTabBar={(props) => (
        <TabBar
          {...props}
          indicatorStyle={{ backgroundColor: theme.TAB_BAR_TEXT_COLOR_HIGHLITED }}
          style={{ backgroundColor: theme.TAB_BAR_BACKGROUND_COLOR }}
          activeColor={theme.TAB_BAR_TEXT_COLOR_HIGHLITED}
          inactiveColor={theme.TAB_BAR_TEXT_COLOR}
        />
      )}
      navigationState={{ index, routes }}
      renderScene={renderScene}
      onIndexChange={setIndex}
    />
  );
};

const mapStateToProps = (state) => {
  const scenes = state.smartHomeData.get(state.profilesSettings.get("currentProfile")).get("scenes");
  const order = state.profilesSettings.get(state.profilesSettings.get("currentProfile")).get("order");
  let currentProfile = state.profilesSettings.get("currentProfile");

  return {
    dimensions: state.statesData.get("dimensions"),
    userName: state.profilesSettings.get(currentProfile).get("loginData").get("login"),
    scenes: scenes,
    globalSettings: state.globalSettings,
    permission: state.statesData.get("server").get("serverInfo").get("perm"),
    serverInfo: state.statesData.get("server").get("serverInfo"),
    serverVersion: state.statesData.get("server").get("serverInfo").get("serverVersion"),
    //devicesStates: possibleStates,
    order: order,
  };
};

export default connect(mapStateToProps)(ScenesScreen);
