import React, { Component } from "react";
import { View } from "react-native";
import humanizeDuration from "humanize-duration";
import { connect } from "react-redux";

import * as Localization from "expo-localization";

import { changeParamsOfDevice } from "../store/actions";
import mqttClient from "../services/mqtt";
import { t } from "../services/i18n";

import {
  PrimaryColorText,
  TextInputStyled,
} from "../components/UI/styledComponents";
import ModalWithContent from "../components/UI/ModalWithContent";

let typesToShowPower = [
  "led",
  "flaga_liniowa",
  "flaga_liniowa16",
  "przekaznik",
  "rgb",
  "rgbw",
];

let lang = Localization.locale.split("-")[0];
class DeviceInfoDialog extends Component {
  state = {
    devicePower: "",
  };

  onInputChange = (value) => {
    this.setState({ devicePower: value });
  };

  onInputEndEditing = () => {
    const { params_device, deviceID } = this.props;
    let param1 = "0";
    if (this.state.devicePower) {
      param1 = parseFloat(this.state.devicePower.replace(",", ".")).toString();
    }

    this.setState({ devicePower: param1 });
    mqttClient.updateParam(deviceID, param1);

    let newParams = params_device.set("param1", param1);
    this.props.onChangeParamsOfDevice(deviceID, newParams);
  };

  componentDidMount() {
    const { params_device, deviceID } = this.props;

    if (params_device && params_device.get("param1")) {
      this.setState({ devicePower: params_device.get("param1").toString() });
    }
    let message = `/api/json/device/${deviceID}/getUpTime/0`;
    mqttClient.askForUptime(message, deviceID);
  }

  handleClose = () => {
    this.props.handleCloseDialog();
  };

  render() {
    const {
      dialogVisible,
      userVariables,
      deviceType,
      permission,
      deviceState,
    } = this.props;
    let permissionForEditPower = (permission & 0x20) == 0;
    const upTime = deviceState.get("upTime");
    const { devicePower } = this.state;
    let formattedUpTime = humanizeDuration(upTime * 100, { language: lang });
    let todayCost;
    if (
      userVariables &&
      //type 2, wartoscN 1: currency
      userVariables.get(1) &&
      //type 2, wartoscN 2: price
      userVariables.get(2)
    ) {
      todayCost =
        Math.round(
          ((((Number(devicePower) *
            Number(userVariables.get(2).get("wartoscS").replace(",", "."))) /
            1000) *
            upTime) /
            (10 * 60 * 60)) *
            100
        ) / 100;
    }

    let content = (
      <View style={{ paddingBottom: 10, alignItems: "center" }}>
        <PrimaryColorText>{t("TODAY_TURN_TIME")}</PrimaryColorText>
        <PrimaryColorText style={{ marginBottom: 10, fontWeight: "bold" }}>
          {formattedUpTime}
        </PrimaryColorText>
        {typesToShowPower.includes(deviceType) && (
          <View>
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                marginBottom: 10,
              }}
            >
              <PrimaryColorText>{t("DEVICE_POWER")}: </PrimaryColorText>
              {permissionForEditPower ? (
                <TextInputStyled
                  style={{ marginLeft: 5, flex: 1 }}
                  value={devicePower}
                  placeholder={t("DEVICE_POWER")}
                  onEndEditing={this.onInputEndEditing}
                  onChangeText={this.onInputChange}
                  keyboardType={"numeric"}
                />
              ) : (
                <PrimaryColorText>{devicePower}</PrimaryColorText>
              )}

              <PrimaryColorText style={{ paddingLeft: 5 }}>W</PrimaryColorText>
            </View>

            {userVariables && userVariables.get(2).get("wartoscS") ? (
              <PrimaryColorText>
                {t("TODAY_PRICE")}: {todayCost}
                {userVariables.get(1).get("wartoscS") &&
                  userVariables.get(1).get("wartoscS")}
              </PrimaryColorText>
            ) : (
              <PrimaryColorText>{t("INSERT_THE_PRICE")}</PrimaryColorText>
            )}
          </View>
        )}
      </View>
    );

    return (
      <ModalWithContent
        isVisible={dialogVisible}
        title={t("INFO")}
        onClose={this.handleClose}
      >
        {content}
      </ModalWithContent>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    params_device: state.smartHomeData
      .get(state.profilesSettings.get("currentProfile"))
      .get("params_devices")
      .get(ownProps.deviceID),
    userVariables: state.smartHomeData
      .get(state.profilesSettings.get("currentProfile"))
      .get("userVariables"),
    deviceState: state.statesData.get("states").get(ownProps.deviceID),
    permission: state.statesData.get("server").get("serverInfo").get("perm"),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onChangeParamsOfDevice: (deviceID, params) =>
      dispatch(changeParamsOfDevice(deviceID, params)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(DeviceInfoDialog);
