import React, { Component } from "react";
import { View, TouchableOpacity, Text, Platform, StyleSheet } from "react-native";
import { connect } from "react-redux";
import moment from "moment";

import { t } from "../../../services/i18n";
import AddDeviceModal from "./AddDeviceModal";

import { ThemeContext } from "../../../../ThemeContext";

import { actions_logReceived } from "../../../store/actions";

import PickerSelect from "../../../components/UI/PickerSelect";
import DateTimePicker from "../../../components/UI/dateTimePicker";

import { StyledIcon, PrimaryColorText, NoDataScreen, ButtonPrimary } from "../../../components/UI/styledComponents";
import ModalWithContent from "../../../components/UI/ModalWithContent";

class TopBarOptions extends Component {
  static contextType = ThemeContext;

  state = {
    date: new Date(),
    addDeviceModalVisible: false,
    timeModalVisible: false,
    inputText: t("statistics:ALL_DEVICES"),
    selectedValue: 0,
    timeOption: "last",
  };

  onAddDeviceHandler = (selectedValue, selectedOption) => {
    let inputText;
    let value;
    if (selectedOption == "allDevices") {
      inputText = t("statistics:ALL_DEVICES");
      value = -1;
    } else if (selectedOption == "singleDevice") {
      inputText = selectedValue.get("opis_menu");
      value = selectedValue.get("id");
    } else if (selectedOption == "allEvents") {
      inputText = t("statistics:ALL_EVENTS");
      value = -1;
    } else if (selectedOption == "singleEvent") {
      inputText = selectedValue.get("opis");
      value = selectedValue.get("wartoscN");
    }

    this.setState(
      {
        addDeviceModalVisible: false,
        isLoading: true,
        inputText,
        selectedValue,
      },
      () => this.props.optionChanged(value, selectedOption)
    );
  };

  showTimeModal = () => {
    this.setState({
      timeModalVisible: true,
    });
  };

  hideTimeModal = () => {
    this.setState({
      timeModalVisible: false,
    });
  };

  //zostaje
  showAddDeviceModal = () => {
    this.setState({
      addDeviceModalVisible: true,
    });
  };

  hideAddDeviceModal = () => {
    this.setState({
      addDeviceModalVisible: false,
    });
  };

  //to jest potwierdzenie daty
  handleDateChange = (date) => {
    this.setState({ date });
  };

  onDateConfirm = () => {
    let { timeOption, date } = this.state;
    this.setState(
      {
        timeModalVisible: false,
      },
      () => {
        this.props.optionChanged(timeOption == "last" ? "last" : date, "date");
      }
    );
  };

  onTimeOptionChange = (timeOption) => {
    this.setState({ timeOption });
  };

  render() {
    const { addDeviceModalVisible, inputText, date, deviceID, timeModalVisible, timeOption, selectedValue } = this.state;
    const { theme } = this.context;

    let timeOptions = [
      {
        label: t("LAST"),
        value: "last",
      },
      {
        label: t("SPECIFIC_DATE"),
        value: "specificDate",
      },
    ];
    return (
      <View>
        <View
          style={[
            styles.stickyTopBar,
            {
              backgroundColor: theme.TAB_BAR_BACKGROUND_COLOR,
              flexDirection: "row",
            },
          ]}
        >
          <View
            style={{
              width: "47%",
              marginRight: "6%",
            }}
          >
            <Text
              style={{
                color: theme.TAB_BAR_TEXT_COLOR_HIGHLITED,
                marginVertical: 5,
              }}
            >
              {t("CHOOSE_OBJECT_TYPE")}
            </Text>

            <TouchableOpacity
              style={{
                // fontSize: 12,
                paddingLeft: 8,
                height: 26,
                borderWidth: 1,
                borderColor: theme.PLACEHOLDER_BACKGROUND_COLOR,
                borderRadius: 4,
                width: "100%",
                backgroundColor: theme.PLACEHOLDER_BACKGROUND_COLOR,
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
              onPress={this.showAddDeviceModal}
            >
              <PrimaryColorText
                numberOfLines={1}
                style={{
                  fontSize: 12,
                }}
              >
                {inputText}
              </PrimaryColorText>
              <StyledIcon style={{ right: 8 }} name="chevron-down" size={18} color={"secondary"} />
            </TouchableOpacity>
          </View>
          <View
            style={{
              width: "47%",
            }}
          >
            <Text
              style={{
                color: theme.TAB_BAR_TEXT_COLOR_HIGHLITED,
                marginVertical: 5,
              }}
            >
              {t("CHOOSE_TIME")}
            </Text>

            <TouchableOpacity
              style={{
                // fontSize: 12,
                paddingLeft: 8,
                height: 26,
                borderWidth: 1,
                borderColor: theme.PLACEHOLDER_BACKGROUND_COLOR,
                borderRadius: 4,
                width: "100%",
                backgroundColor: theme.PLACEHOLDER_BACKGROUND_COLOR,
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
              onPress={this.showTimeModal}
            >
              <PrimaryColorText
                numberOfLines={1}
                style={{
                  fontSize: 12,
                }}
              >
                {timeOption == "last" ? t("LAST") : moment(date).format("DD-MM-YYYY")}
              </PrimaryColorText>
              <StyledIcon style={{ right: 8 }} name="chevron-down" size={18} color={"secondary"} />
            </TouchableOpacity>
          </View>
        </View>
        {timeModalVisible && (
          <ModalWithContent isVisible={timeModalVisible} title={t("CHOOSE_TIME")} onClose={this.hideTimeModal}>
            <View style={{ paddingBottom: 20 }}>
              <PrimaryColorText style={{ paddingBottom: 4 }}>{t("TIME")}</PrimaryColorText>
              <PickerSelect
                placeholder={{
                  label: t("CHOOSE_TIME"),
                  value: null,
                }}
                items={timeOptions}
                onValueChange={this.onTimeOptionChange}
                value={timeOption}
              />
            </View>

            {timeOption != "last" && (
              <View style={{ height: 40 }}>
                <DateTimePicker
                  value={moment(date).format("DD-MM-YYYY")}
                  date={date}
                  maximumDate={new Date()}
                  mode="date"
                  onConfirm={this.handleDateChange}
                />
              </View>
            )}
            <ButtonPrimary onPress={this.onDateConfirm}>{t("DONE")}</ButtonPrimary>
          </ModalWithContent>
        )}
        {addDeviceModalVisible && (
          <AddDeviceModal
            showEvents={this.props.showEvents}
            isVisible={addDeviceModalVisible}
            onClose={this.hideAddDeviceModal}
            onAdd={this.onAddDeviceHandler}
            deviceID={deviceID}
            selectedOption={selectedValue}
          />
        )}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  singleLine: {
    paddingVertical: 5,
    paddingHorizontal: 10,
    width: "100%",
  },
  stickyTopBar: {
    justifyContent: "center",
    alignItems: "center",
    padding: 10,
  },
});

const mapStateToProps = (state) => {
  return {
    actions_log: state.statesData.get("actions_log"),
    devices: state.smartHomeData.get(state.profilesSettings.get("currentProfile")).get("devices"),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions_logReceived: (reset) => dispatch(actions_logReceived(reset)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(TopBarOptions);
