import thunkMiddleware from "redux-thunk";
import ExpoFileSystemStorage from "redux-persist-expo-filesystem";
import { applyMiddleware, combineReducers, createStore, compose } from "redux";
import { createLogger } from "redux-logger";
import { composeWithDevTools } from "redux-devtools-extension";
import { getStoredState, persistStore, persistReducer } from "redux-persist";
//to wybija od redux-persis 6.0 - zmnajdź nowe
//THIS SHOUDL BE USED IN WEB
import storage from "redux-persist/lib/storage"; //default to localStorage for web
import createIdbStorage from "@piotr-cz/redux-persist-idb-storage";
import reduxStorage from "redux-persist-indexeddb-storage";
import { Platform } from "react-native";
import immutableTransform from "redux-persist-transform-immutable";
//import { reduxStorage } from "./reduxStorage";
import { Map } from "immutable";

import localforage from "localforage";

import {
  smartHomeDataReducer,
  statesDataReducer,
  weatherDataReducer,
  profilesSettingsReducer,
  globalSettingsReducer,
  md5DataReducer,
  cloudDataReducer,
} from "./reducers";

const rootReducer = combineReducers({
  weatherData: weatherDataReducer,
  smartHomeData: smartHomeDataReducer,
  statesData: statesDataReducer,
  profilesSettings: profilesSettingsReducer,
  globalSettings: globalSettingsReducer,
  md5HashData: md5DataReducer,
  cloudData: cloudDataReducer,
});

const isStateEmpty = (state) => {
  let counter = 0;
  if (state && state.profilesSettings) {
    state.profilesSettings.forEach((v, k) => {
      if (k && !k.includes("currentProfile") && k != "demo") {
        counter++;
      }
    });
  }
  return counter === 0;
};

const migrate = async (state) => {
  // Migrate from async storage to fs https://github.com/robwalkerco/redux-persist-filesystem-storage#migration-from-previous-storage
  __DEV__ && console.log("Attempting migration");
  if (isStateEmpty(state)) {
    // if state from fs storage is empty try to read state from previous storage
    __DEV__ && console.log("MMKV state empty");
    try {
      const asyncState = await getStoredState(oldConfig);
      if (!isStateEmpty(asyncState)) {
        asyncState.smartHomeData = Map({
          currentProfile: "",
        });
        __DEV__ && console.log("Async state not empty. Attempting migration.");
        return asyncState;
      }
    } catch (getStateError) {
      __DEV__ && console.warn("getStoredState error", getStateError);
    }
  }
  __DEV__ && console.log("FS state not empty");
  return state;
};

const oldConfig = {
  transforms: [immutableTransform()],
  key: "root",
  //THIS SHOUDL BE USED IN WEB
  storage: storage,
  //storage: ExpoFileSystemStorage,
  timeout: null,
  //blaclist is what is deleted every time from store
  blacklist: [
    "weatherData",
    "statesData",
    "authData",
    //this is putdated due to big changes in reducer
    "md5Data",
  ],
  migrate,
};

const persistConfig = {
  transforms: [immutableTransform()],
  key: "root",
  //THIS SHOUDL BE USED IN WEB
  //storage: Platform.OS == "web" ? storage : ExpoFileSystemStorage,
  storage: storage,
  timeout: null,
  //blaclist is what is deleted every time from store
  blacklist: [
    "weatherData",
    "statesData",
    "authData",
    "smartHomeData",
    // "smartHomeData",
    //this is putdated due to big changes in reducer
    "md5Data",
  ],
  migrate,
};

// //do podziału:
// const fileSysytemPersistConfig = {
// 	key: "fileSystem",
// 	storage: ExpoFileSystemStorage,
// 	timeout: null,
// 	blacklist: [
// 		//this should be deleted every time
// 		"weatherData",
// 		"statesData",
// 		//this is putdated due to big changes in reducer
// 		"md5Data"
// 	]
// };
// const asyncStoreagePersistConfig = {
// 	key: "root",
// 	storage: Platform.OS == "web" ? storage : ExpoFileSystemStorage,
// 	timeout: null,
// 	blacklist: [
// 		//this should be deleted every time
// 		"weatherData",
// 		"statesData",
// 		//this is putdated due to big changes in reducer
// 		"md5Data"
// 	]
// };

const middleWare = [thunkMiddleware];

// if (__DEV__) {
// 	middleWare.push(createLogger());
// }

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(persistedReducer, undefined, composeWithDevTools(applyMiddleware(...middleWare)));

export const persistor = persistStore(store, { timeout: 1000 }, (err) => {
  // console.log(
  // 	"beore persistor",
  // 	ExpoFileSystemStorage.getItem("profilesSettings")
  // );
  if (err) {
    console.log("err in persist", err);
  }
});

// persistor.purge();
