import React, { useEffect, Component } from "react";

import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import BackgroundTimer from "react-native-background-timer";
import registerForPushNotificationsAsync from "../../services/pushes/registerForPushNotificationsAsync";
import { createStackNavigator, TransitionPresets } from "@react-navigation/stack";
import { useNavigation, getFocusedRouteNameFromRoute } from "@react-navigation/native";

import { useTheme } from "../../../ThemeContext";
import { t } from "../../services/i18n";
import {
  showAlertScreen,
  changeAppState,
  changeConsentsStatus,
  changeRoute,
  changeDimensions,
  changeLastTimer,
  changePopupObjectId,
  setDuringTalk,
  changeAnsweredCall,
} from "../../store/actions";
import mqttClient from "../../services/mqtt";
import { connect, useSelector } from "react-redux";
import { calculateSizeMenu, DeviceListHandler } from "../../utils";
import { useSafeArea } from "react-native-safe-area-context";
import { activateKeepAwakeAsync, deactivateKeepAwake } from "expo-keep-awake";
import * as ScreenOrientation from "expo-screen-orientation";
import BottomTabBar from "./BottomTabBar";
import { store } from "../../store/configureStore";
import UserInactivity from "react-native-user-inactivity";
import moment from "moment";
import { checkTokenNeedRefresh, checkLocalTokenNeedRefresh, fetchMetaData } from "../../screens/Initial/CloudTools";

import { AppState, View, TouchableOpacity, StatusBar, Dimensions, Platform, Text, Button } from "react-native";
import ModalSimple from "../../components/UI/ModalSimple";
import { ButtonPrimary, StyledIcon } from "../../components/UI/styledComponents";
import Janus from "../../services/janus/Janus";
import SideMenu from "../SideMenu";
import { Audio } from "expo-av";

import {
  ActiveScreen,
  DashboardScreen,
  FavouritesScreen,
  PlacesScreen,
  Devices_PlacesScreen,
  ScenesScreen,
  SceneDetailsScreen,
  IrrigationDetailsScreen,
  Irrigation_AccountScreen,
  AutomatizationDetailsScreen,
  Automatization_AccountScreen,
  AccountScreen,
  Parameters_AccountScreen,
  Personalization_AccountScreen,
  Dashboard_PersonalizationScreen,
  SideMenu_PersonalizationScreen,
  General_Order_PersonalizationScreen,
  Groups_Order_PersonalizationScreen,
  Devices_Order_PersonalizationScreen,
  Favourites_Order_PersonalizationScreen,
  Scenes_Order_PersonalizationScreen,
  Colors_PersonalizationScreen,
  Random_PersonalizationScreen,
  Sketch_PersonalizationScreen,
  Security_AccountScreen,
  Password_SecurityScreen,
  Versions_AccountScreen,
  Simulation_AccountScreen,
  Detection_AccountScreen,
  Support_AccountScreen,
  Statistics_AccountScreen,
  Message_Center_AccountScreen,
  Message_DetailsScreen,
  About_AccountScreen,
  Diagnostic_AccountScreen,
  HeatingScreen,
  ChartScreen,
  AccessControlScreen,
  SourceChooseScreen,
  IRScreen,
  AudioLibrary_AccountScreen,
  CurrentMusicScreen,
  DeviceDetailsScreen,
} from "../../screens";
import { Feather } from "@expo/vector-icons";
import { calculateDimmenions } from "../../utils/screenTools";
import ModalWithContent from "../../components/UI/ModalWithContent";

const forModalPresentationIOS = (props) => {
  const config = TransitionPresets.ModalPresentationIOS.cardStyleInterpolator(props);
  config.cardStyle.borderBottomLeftRadius = undefined;
  config.cardStyle.borderBottomRightRadius = undefined;
  return config;
};

const fixTabNestedStackCardStyleInterpolator = Platform.select({
  ios: forModalPresentationIOS,
  default: TransitionPresets.BottomSheetAndroid.cardStyleInterpolator,
});

const DashboardStack = createStackNavigator();
const DashboardStackScreenX = ({ globalSettings }) => {
  let sideMenuShown = globalSettings.get("sideMenuShown");

  const { theme } = useTheme();
  const insets = useSafeArea();
  let sideMenuShownProps = globalSettings.get("dashboardTopBarShown");

  let dashboardTopBarShown = sideMenuShownProps == undefined ? true : sideMenuShownProps;

  return (
    <DashboardStack.Navigator
      screenOptions={{
        ...TransitionPresets.SlideFromRightIOS,

        headerMode: "float",
        headerTitleAlign: "center",
        headerTintColor: theme.TAB_BAR_TEXT_COLOR_HIGHLITED,
        headerStatusBarHeight: sideMenuShown ? 6 : insets.top,
        // headerStatusBarHeight: 20,
        headerBackTitle: "",
        headerBackTitleStyle: {
          fontFamily: theme.FONT,
        },
        // headerTransparent: true,
        // headerBlurEffect: "dark",

        headerTitleStyle: {
          fontFamily: theme.FONT_BOLD,
          maxWidth: Dimensions.get("window").width - 120,
        },
        headerBackTitle: "",

        headerStyle: {
          backgroundColor: theme.TAB_BAR_BACKGROUND_COLOR,
          shadowColor: "transparent",
        },
      }}
    >
      <DashboardStack.Screen
        name="DashboardScren"
        component={DashboardScreen}
        options={{
          title: t("DASHBOARD").toUpperCase(),
          headerShown: dashboardTopBarShown,
        }}
      />
      <DashboardStack.Screen name="Versions_Account" component={Versions_AccountScreen} options={{ title: t("account:VERSIONS").toUpperCase() }} />
      <DashboardStack.Screen
        name="Message_Center_Account"
        component={Message_Center_AccountScreen}
        options={{ title: t("account:MESSAGE_CENTER").toUpperCase() }}
      />
      <DashboardStack.Screen
        name="Message_Details"
        component={Message_DetailsScreen}
        options={{ title: t("account:MESSAGE_CENTER").toUpperCase() }}
      />
      <DashboardStack.Screen
        name="Devices_Places"
        component={Devices_PlacesScreen}
        options={({ route }) => ({
          title: route.params.group ? route.params.group.get("opis_menu") : t("DEVICES").toUpperCase(),
        })}
      />
    </DashboardStack.Navigator>
  );
};

const mapStateToPropsDash = (state) => {
  return {
    globalSettings: state.globalSettings,
  };
};

let DashboardStackScreen = connect(mapStateToPropsDash)(DashboardStackScreenX);

const PlacesStack = createStackNavigator();
const PlacesStackScreen = ({ navigation }) => {
  const globalSettings = useSelector((state) => state.globalSettings);
  let sideMenuShown = globalSettings.get("sideMenuShown");
  const { theme } = useTheme();
  const insets = useSafeArea();

  return (
    <PlacesStack.Navigator
      screenOptions={{
        ...TransitionPresets.SlideFromRightIOS,
        headerTitleAlign: "center",
        headerMode: "float",

        headerTruncatedBackTitle: "",
        gesturesEnabled: false,
        headerTintColor: theme.TAB_BAR_TEXT_COLOR_HIGHLITED,
        headerStatusBarHeight: sideMenuShown ? 6 : insets.top,
        headerTitleStyle: {
          fontFamily: theme.FONT_BOLD,
          maxWidth: Dimensions.get("window").width - 120,
        },
        headerBackTitle: "",
        headerBackTitleStyle: {
          fontFamily: theme.FONT,
        },
        headerStyle: {
          backgroundColor: theme.TAB_BAR_BACKGROUND_COLOR,
          shadowColor: "transparent",
        },
      }}
    >
      <PlacesStack.Screen name="PlacesScreen" component={PlacesScreen} options={{ title: t("PLACES").toUpperCase() }} />
      <PlacesStack.Screen
        name="Devices_Places"
        component={Devices_PlacesScreen}
        options={({ route }) => ({
          // headerLeft: null,
          title: route.params.group ? route.params.group.get("opis_menu") : t("DEVICES").toUpperCase(),
        })}
      />
    </PlacesStack.Navigator>
  );
};

const FavouritesStack = createStackNavigator();
const FavouritesStackScreen = () => {
  const globalSettings = useSelector((state) => state.globalSettings);
  let sideMenuShown = globalSettings.get("sideMenuShown");
  const { theme } = useTheme();
  const insets = useSafeArea();
  return (
    <FavouritesStack.Navigator
      screenOptions={{
        ...TransitionPresets.SlideFromRightIOS,
        headerTitleAlign: "center",
        headerMode: "float",
        headerTintColor: theme.TAB_BAR_TEXT_COLOR_HIGHLITED,
        headerStatusBarHeight: sideMenuShown ? 6 : insets.top,
        headerTitleStyle: {
          fontFamily: theme.FONT_BOLD,
          maxWidth: Dimensions.get("window").width - 120,
        },
        headerBackTitle: "",
        headerBackTitleStyle: {
          fontFamily: theme.FONT,
        },
        headerStyle: {
          backgroundColor: theme.TAB_BAR_BACKGROUND_COLOR,
          shadowColor: "transparent",
        },
      }}
    >
      <FavouritesStack.Screen name="FavouritesScreen" component={FavouritesScreen} options={{ title: t("FAVOURITES").toUpperCase() }} />
    </FavouritesStack.Navigator>
  );
};

const ScenesStack = createStackNavigator();
const ScenesStackScreen = () => {
  const globalSettings = useSelector((state) => state.globalSettings);
  let sideMenuShown = globalSettings.get("sideMenuShown");
  const { theme } = useTheme();
  const insets = useSafeArea();
  return (
    <ScenesStack.Navigator
      screenOptions={{
        ...TransitionPresets.SlideFromRightIOS,
        headerTitleAlign: "center",
        headerMode: "float",
        headerTintColor: theme.TAB_BAR_TEXT_COLOR_HIGHLITED,
        headerStatusBarHeight: sideMenuShown ? 6 : insets.top,
        headerTitleStyle: {
          fontFamily: theme.FONT_BOLD,
          maxWidth: Dimensions.get("window").width - 120,
        },
        headerBackTitle: "",
        headerBackTitleStyle: {
          fontFamily: theme.FONT,
        },
        headerStyle: {
          backgroundColor: theme.TAB_BAR_BACKGROUND_COLOR,
          shadowColor: "transparent",
        },
      }}
    >
      <ScenesStack.Screen name="Scenes" component={ScenesScreen} options={{ title: t("SCENES").toUpperCase() }} />
      <ScenesStack.Screen
        name="SceneDetails"
        component={SceneDetailsScreen}
        options={({ route }) => ({
          title: route.params.sceneName ? route.params.sceneName : t("SCENES").toUpperCase(),
        })}
      />
    </ScenesStack.Navigator>
  );
};

const ActiveStack = createStackNavigator();
const ActiveStackScreen = () => {
  const globalSettings = useSelector((state) => state.globalSettings);
  let sideMenuShown = globalSettings.get("sideMenuShown");

  const { theme } = useTheme();
  const insets = useSafeArea();
  return (
    <ActiveStack.Navigator
      screenOptions={{
        ...TransitionPresets.SlideFromRightIOS,
        headerTitleAlign: "center",
        headerMode: "float",
        headerTintColor: theme.TAB_BAR_TEXT_COLOR_HIGHLITED,
        headerStatusBarHeight: sideMenuShown ? 6 : insets.top,
        headerTitleStyle: {
          fontFamily: theme.FONT_BOLD,
          maxWidth: Dimensions.get("window").width - 120,
        },
        headerBackTitle: "",
        headerBackTitleStyle: {
          fontFamily: theme.FONT,
        },
        headerStyle: {
          backgroundColor: theme.TAB_BAR_BACKGROUND_COLOR,
          shadowColor: "transparent",
        },
      }}
    >
      <ActiveStack.Screen name="ActiveScreen" component={ActiveScreen} options={{ title: t("ACTIVE").toUpperCase() }} />
    </ActiveStack.Navigator>
  );
};

const AccountStack = createStackNavigator();
const AccountStackScreen = () => {
  const globalSettings = useSelector((state) => state.globalSettings);
  let sideMenuShown = globalSettings.get("sideMenuShown");

  const { theme } = useTheme();
  const insets = useSafeArea();

  return (
    <AccountStack.Navigator
      screenOptions={({ route }) => ({
        ...TransitionPresets.SlideFromRightIOS,
        headerMode: "float",
        title: route.params?.title ? `${route.params.title}` : "AMPIO",
        headerTitleAlign: "center",
        headerTruncatedBackTitle: "",
        headerTintColor: theme.TAB_BAR_TEXT_COLOR_HIGHLITED,
        headerStatusBarHeight: sideMenuShown ? 6 : insets.top,
        headerTitleStyle: {
          fontFamily: theme.FONT_BOLD,
          maxWidth: Dimensions.get("window").width - 120,
        },
        headerBackTitle: "",
        headerBackTitleStyle: {
          fontFamily: theme.FONT,
        },
        headerStyle: {
          backgroundColor: theme.TAB_BAR_BACKGROUND_COLOR,
          shadowColor: "transparent",
        },
      })}
      // initialRouteName="Account"
    >
      <AccountStack.Screen name="AccountScreen" component={AccountScreen} />

      <AccountStack.Screen name="Scenes_Account" component={ScenesScreen} />
      <AccountStack.Screen
        name="SceneDetails"
        component={SceneDetailsScreen}
        options={({ route }) => ({
          title: route.params.sceneName ? route.params.sceneName : t("SCENES").toUpperCase(),
        })}
      />
      <AccountStack.Screen name="Security_Account" component={Security_AccountScreen} />
      <AccountStack.Screen name="Password_Security" component={Password_SecurityScreen} />
      <AccountStack.Screen name="Versions_Account" component={Versions_AccountScreen} />
      <AccountStack.Screen name="Simulation_Account" component={Simulation_AccountScreen} />
      <AccountStack.Screen name="Detection_Account" component={Detection_AccountScreen} />
      <AccountStack.Screen name="Support_Account" component={Support_AccountScreen} />

      <AccountStack.Screen name="Statistics_Account" component={Statistics_AccountScreen} />
      <AccountStack.Screen name="About_Account" component={About_AccountScreen} />
      <AccountStack.Screen name="Diagnostic_Account" component={Diagnostic_AccountScreen} />
      <AccountStack.Screen name="Personalization_Account" component={Personalization_AccountScreen} />
      <AccountStack.Screen name="Dashboard_Personalization" component={Dashboard_PersonalizationScreen} />
      <AccountStack.Screen name="SideMenu_Personalization" component={SideMenu_PersonalizationScreen} />
      <AccountStack.Screen name="General_Order_Personalization" component={General_Order_PersonalizationScreen} />
      <AccountStack.Screen name="Groups_Order_Personalization" component={Groups_Order_PersonalizationScreen} />
      <AccountStack.Screen
        name="Devices_Order_Personalization"
        component={Devices_Order_PersonalizationScreen}
        options={({ route }) => ({
          title: route.params?.placeName ? `${route.params.placeName}` : "",
          headerBackTitle: "",
        })}
      />
      <AccountStack.Screen
        name="Favourites_Order_Personalization"
        component={Favourites_Order_PersonalizationScreen}
        options={{
          headerBackTitle: "",
        }}
      />
      <AccountStack.Screen
        name="Scenes_Order_Personalization"
        component={Scenes_Order_PersonalizationScreen}
        options={{
          headerBackTitle: "",
        }}
      />
      <AccountStack.Screen name="Colors_Personalization" component={Colors_PersonalizationScreen} />
      <AccountStack.Screen name="Sketch_Personalization" component={Sketch_PersonalizationScreen} />
      <AccountStack.Screen name="Random_Personalization" component={Random_PersonalizationScreen} />
      <AccountStack.Screen name="Parameters_Account" component={Parameters_AccountScreen} />
      <AccountStack.Screen
        name="AudioLibrary_Account"
        component={AudioLibrary_AccountScreen}
        options={({ route }) => ({
          title: t("AUDIOLIBRARY_ACCOUNT"),
          headerBackTitle: "",
        })}
      />
      <AccountStack.Screen name="Message_Center_Account" component={Message_Center_AccountScreen} />
      <AccountStack.Screen name="Message_Details" component={Message_DetailsScreen} />
      <AccountStack.Screen name="Irrigation_Account" component={Irrigation_AccountScreen} />
      <AccountStack.Screen name="IrrigationDetails" component={IrrigationDetailsScreen} />
      <AccountStack.Screen name="Automatization_Account" component={Automatization_AccountScreen} />
      <AccountStack.Screen name="AutomatizationDetails" component={AutomatizationDetailsScreen} />
    </AccountStack.Navigator>
  );
};

const getInitialRoute = () => {
  const profileSettings = store.getState().profilesSettings.get(store.getState().profilesSettings.get("currentProfile"));
  const startingPlace = profileSettings.get("startingPlace") ? profileSettings.get("startingPlace") : -4;
  let timeout = profileSettings.get("backToStartTimeout") ? profileSettings.get("backToStartTimeout") : 0;
  let initalRoute = "Dashboard";
  if (startingPlace >= 0) {
    initalRoute = "Places";
  } else if (startingPlace == -1) {
    initalRoute = "Active";
  } else if (startingPlace == -2) {
    initalRoute = "Scenes";
  } else if (startingPlace == -3) {
    initalRoute = "Favourites";
  } else if (startingPlace == -4) {
    initalRoute = "Dashboard";
  }
  if (!store.getState().statesData.get("currentRoute")) {
    store.dispatch(changeRoute(initalRoute));
  }

  return { name: initalRoute, id: startingPlace, timeout };
};

const Tab = createBottomTabNavigator();
const TabStack = () => {
  const { onThemeChange, theme } = useTheme();
  const globalSettings = store.getState().globalSettings;
  const statesData = store.getState().statesData;

  const currentConnectionType = statesData.get("currentConnection");

  let initalRoute = getInitialRoute().name;

  const checkGeolocations = async () => {
    if (Platform.OS !== "web") {
      window.app.getAndRegisterGeofences();
    }
  };

  const oneMinuteInterval = async () => {
    setTimeout(async () => {
      const cloudData = store.getState().cloudData;
      const authData = cloudData ? cloudData.get("authData") : null;

      try {
        if (store.getState().statesData.get("lastTimer")) {
          if (currentConnectionType !== "loader") {
            if (currentConnectionType === "new_cloud" && checkTokenNeedRefresh(authData)) {
              mqttClient.appGoesToBackground("reconnectWithNewToken");
            } else if (
              currentConnectionType === "new_cloud" &&
              statesData.get("clientData") &&
              statesData.get("clientData").get("local_token") &&
              statesData.get("clientData").get("local_tokenExp") &&
              checkLocalTokenNeedRefresh(statesData.get("clientData").get("local_tokenExp"))
            ) {
              mqttClient.appGoesToBackground("reconnectWithNewToken");
            }
          }
          await checkConsents();
        }
        store.dispatch(changeLastTimer(new Date().getTime()));
      } catch (ex) {
        console.log(ex);
      }
    }, 5000);
  };

  const checkConsents = async () => {
    const cloudData = store.getState().cloudData;
    const authData = cloudData ? cloudData.get("authData") : null;
    if (currentConnectionType === "new_cloud" && authData) {
      let consentsTime = globalSettings.get("consentsTime");
      if (!consentsTime || moment().unix() - consentsTime > 24 * 60 * 60) {
        let metaData = await fetchMetaData(authData.toJS());
        if (metaData && metaData.user && metaData.user.requiredConsentsGranted === false) {
          store.dispatch(changeConsentsStatus(1));
        }
      }
    }
  };

  const handleAppStateChange = async (nextAppState) => {
    const appState = store.getState().statesData.get("appState");
    async function lockLandscapeOrientation(left) {
      try {
        await ScreenOrientation.getOrientationAsync().then((orientationId) => {
          let orientation = ScreenOrientation.Orientation[orientationId];

          ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock[orientation]);
        });
      } catch (error) {
        console.log(error, "error in 	ScreenOrientation.OrientationLock");
      }
    }
    async function unlockLandscapeOrientation() {
      try {
        await ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock["DEFAULT"]);
      } catch (error) {
        console.log(error, "error in 	ScreenOrientation.OrientationLock");
      }
    }

    if (appState === "background" && nextAppState === "active") {
      if (globalSettings.get("keepAwakeOn")) {
        try {
          await activateKeepAwakeAsync();
        } catch (ex) {}
      }

      mqttClient.appComesToForeground();

      let nightModeTurnBySchedule = globalSettings.get("nightModeTurnBySchedule");

      !globalSettings.get("forceLandscapeOrientation") &&
        !globalSettings.get("forceInvertedLandscapeOrientation") &&
        Platform.OS !== "web" &&
        lockLandscapeOrientation();
      if (nightModeTurnBySchedule == true) {
        let theme = "";
        let schedule = globalSettings.get("schedule");
        let startSchedule = schedule.get("start");
        let endSchedule = schedule.get("end");

        var currentTime = moment().format("HH:mm");
        if (startSchedule < endSchedule) {
          if (currentTime > startSchedule && currentTime < endSchedule) {
            theme = "night";
          } else {
            theme = "ampio";
          }
        } else {
          if (currentTime > endSchedule && currentTime < startSchedule) {
            theme = "ampio";
          } else {
            theme = "night";
          }
        }
        onThemeChange(theme);
      }

      store.dispatch(changeAppState(nextAppState));
    } else if (appState === "active" && nextAppState == "background") {
      try {
        if (globalSettings.get("keepAwakeOn")) {
          deactivateKeepAwake();
        }
      } catch {}
      if (Platform.OS !== "web") {
        BackgroundTimer.runBackgroundTimer(() => {
          if (nextAppState == AppState.currentState) {
            !globalSettings.get("forceLandscapeOrientation") &&
              !globalSettings.get("forceInvertedLandscapeOrientation") &&
              Platform.OS !== "web" &&
              unlockLandscapeOrientation();
            mqttClient.appGoesToBackground();
            store.dispatch(changeLastTimer(null));
            store.dispatch(changeAppState(nextAppState));
          }

          setTimeout(() => BackgroundTimer.stopBackgroundTimer(), 0);
        }, 5000);
      }
    }
  };

  useEffect(() => {
    store.dispatch(changeAppState(AppState.currentState));

    const subscription = AppState.addEventListener("change", handleAppStateChange);

    if (Platform.OS !== "web") {
      registerForPushNotificationsAsync();
    }
    let interval = null;
    if (currentConnectionType === "new_cloud") {
      interval = setInterval(() => oneMinuteInterval(false), 1000 * 60);
    }

    checkGeolocations();

    return () => {
      if (interval) {
        clearInterval(interval);
      }
      subscription.remove();
      checkGeolocations();
    };
  }, []);

  return (
    <View
      style={{ flex: 1 }}
      onLayout={({ nativeEvent }) => {
        let width = nativeEvent.layout.width;
        let height = nativeEvent.layout.height;

        if (!store.getState().statesData.get("blockDimensionsChange")) {
          if (width && height) {
            store.dispatch(changeDimensions(calculateDimmenions(width, height)));
          }
        }
      }}
    >
      <Tab.Navigator
        initialRouteName={initalRoute}
        screenOptions={{ headerShown: false }}
        tabBar={(props) => <BottomTabBar {...props} />}
        backBehavior="initialRoute"
      >
        <Tab.Screen name="Account" component={AccountStackScreen} />
        <Tab.Screen name="Dashboard" component={DashboardStackScreen} />
        <Tab.Screen name="Places" component={PlacesStackScreen} />
        <Tab.Screen name="Favourites" component={FavouritesStackScreen} />
        <Tab.Screen name="Active" component={ActiveStackScreen} />
      </Tab.Navigator>
    </View>
  );
};

const InnerAppStack = createStackNavigator();
const InnerAppStackContainer = React.memo(() => {
  const globalSettings = useSelector((state) => state.globalSettings);
  const states = useSelector((state) => state.statesData);

  const width = states && states.get("dimensions") ? states.get("dimensions").get("width") : null;

  const titleWidth = width ? width - 120 : Dimensions.get("window").width - 120;

  let sideMenuShown = globalSettings.get("sideMenuShown");

  const answeredCall = useSelector((state) => state.statesData.get("answeredCall"));
  const popupObjectId = useSelector((state) => state.statesData.get("popupObjectId"));
  const duringTalk = useSelector((state) => state.statesData.get("duringTalk"));

  const { theme } = useTheme();

  const nav = useNavigation();
  const initialRoute = getInitialRoute();

  return (
    <UserInactivity
      timeForInactivity={initialRoute.timeout * 1000}
      onAction={(isActive) => {
        if (!isActive && initialRoute.timeout > 0) {
          window.app.setGoToGroup(true);
          nav.reset({
            index: 0,
            routes: [
              {
                name: initialRoute.name,
              },
            ],
          });
        }
      }}
    >
      <InnerAppStack.Navigator
        screenOptions={{
          headerTintColor: theme.TAB_BAR_TEXT_COLOR_HIGHLITED,
          presentation: "modal",
          headerTitleAlign: "center",
          gestureEnabled: false,
          headerStatusBarHeight: sideMenuShown ? 6 : undefined,
          headerTitleStyle: {
            fontFamily: theme.FONT_BOLD,
            maxWidth: titleWidth,
          },
          headerBackTitleStyle: {
            fontFamily: theme.FONT,
          },
          headerBackTitle: "",
          headerStyle: {
            backgroundColor: theme.TAB_BAR_BACKGROUND_COLOR,
            shadowColor: "transparent",
          },
        }}
      >
        <InnerAppStack.Screen
          name="Tabs"
          component={TabStack}
          options={{ headerShown: false, cardStyleInterpolator: fixTabNestedStackCardStyleInterpolator }}
        />
        <InnerAppStack.Screen
          name="HeatingScreen"
          component={HeatingScreen}
          options={({ navigation, route }) => ({
            title: route.params.device ? route.params.device.get("opis_menu") : t("HEATING").toUpperCase(),
            headerLeft: null,
            cardStyleInterpolator: fixTabNestedStackCardStyleInterpolator,
            headerRight: () => {
              return (
                <TouchableOpacity
                  style={{
                    padding: 10,
                  }}
                  hitSlop={{ top: 10, bottom: 10, left: 10, right: 10 }}
                  onPress={() => navigation.goBack(null)}
                >
                  <StyledIcon name={"x"} color={"tabBar"} size={24} />
                </TouchableOpacity>
              );
            },
          })}
        />
        <InnerAppStack.Screen
          name="ChartScreen"
          component={ChartScreen}
          options={({ navigation, route }) => ({
            title: route.params.deviceName ? route.params.deviceName : t("CHART").toUpperCase(),
            cardStyleInterpolator: fixTabNestedStackCardStyleInterpolator,
            headerLeft: null,
            headerRight: () => {
              return (
                <TouchableOpacity
                  style={{
                    padding: 10,
                  }}
                  hitSlop={{ top: 10, bottom: 10, left: 10, right: 10 }}
                  onPress={() => navigation.goBack(null)}
                >
                  <StyledIcon name={"x"} color={"tabBar"} size={24} />
                </TouchableOpacity>
              );
            },
          })}
        />
        <InnerAppStack.Screen
          name="AccessControlScreen"
          component={AccessControlScreen}
          options={({ route }) => ({
            headerLeft: null,
            title: route.params.placeName ? route.params.placeName : "",
            cardStyleInterpolator: fixTabNestedStackCardStyleInterpolator,
          })}
        />

        <InnerAppStack.Screen
          name="CurrentMusicScreen"
          component={CurrentMusicScreen}
          options={({ route }) => ({
            headerLeft: null,
            title: t("CHOOSE_MUSIC"),
            cardStyleInterpolator: fixTabNestedStackCardStyleInterpolator,
          })}
        />
        <InnerAppStack.Screen
          name="IRScreen"
          component={IRScreen}
          options={({ navigation, route }) => ({
            headerLeft: null,
            title: route.params.placeName ? route.params.placeName : "",
            cardStyleInterpolator: fixTabNestedStackCardStyleInterpolator,
            headerRight: () => (
              <TouchableOpacity
                style={{
                  padding: 10,
                }}
                hitSlop={{ top: 10, bottom: 10, left: 10, right: 10 }}
                onPress={() => navigation.goBack(null)}
              >
                <StyledIcon name={"x"} color={"tabBar"} size={24} />
              </TouchableOpacity>
            ),
          })}
        />
        <InnerAppStack.Screen
          name="DeviceDetails"
          component={DeviceDetailsScreen}
          options={({ navigation, route }) => ({
            headerLeft: null,
            cardStyleInterpolator: fixTabNestedStackCardStyleInterpolator,
            title: route.params.placeName ? route.params.placeName : "",
            headerRight: () => (
              <TouchableOpacity
                style={{
                  padding: 10,
                }}
                hitSlop={{ top: 10, bottom: 10, left: 10, right: 10 }}
                onPress={() => navigation.goBack(null)}
              >
                <StyledIcon name={"x"} color={"tabBar"} size={24} />
              </TouchableOpacity>
            ),
          })}
        />
      </InnerAppStack.Navigator>
    </UserInactivity>
  );
});

const InsetWrapper = (props) => {
  const insets = useSafeArea();
  const { theme } = useTheme();
  let backgroundColor = props.currentTheme === "ampio_light" ? theme.TAB_BAR_BACKGROUND_COLOR : "black";
  if (!props.sideMenuShown && !props.landscapeOrientation && props.landscapeOrientation != null) {
    backgroundColor = theme.TAB_BAR_BACKGROUND_COLOR;
  }

  return (
    <View
      style={{
        flex: 1,
        flexDirection: "row",
        paddingBottom: insets.bottom,
        paddingTop: props.sideMenuShown ? insets.top : 0,
        paddingLeft: insets.left,
        paddingRight: insets.right,

        backgroundColor,
      }}
    >
      {props.currentTheme == "night" || props.currentTheme == "oled" ? (
        <StatusBar barStyle="light-content" backgroundColor={theme.APP_BACKGROUND_COLOR} />
      ) : (
        <StatusBar barStyle="dark-content" backgroundColor={theme.APP_BACKGROUND_COLOR} />
      )}
      {props.children}
    </View>
  );
};

let { width, height } = Dimensions.get("window");

class AppStackScreen extends Component {
  state = {
    blinkingOpacity: 0.9,
    showByColumns: this.props.globalSettings.get("showByColumns") == undefined ? true : this.props.globalSettings.get("showByColumns"),
    sideMenuShown: this.props.globalSettings.get("sideMenuShown"),
    sideMenuSize: width < 800 ? "short" : this.props.globalSettings.get("sideMenuSize"),
    sound: null,
    hideUrgent: 0,
    dimensions: {
      width: width,
      height: height,
      // landscapeOrientation: width > height,
      landscapeOrientation: null,
      // columnWidth: width / 1,
      // numberOfColumns: 1,
      columnWidth:
        (Dimensions.get("window").width -
          (this.props.globalSettings.get("sideMenuShown") ? calculateSizeMenu(this.props.globalSettings.get("sideMenuSize")) : 0)) /
        (Dimensions.get("window").width -
          (this.props.globalSettings.get("sideMenuShown") ? calculateSizeMenu(this.props.globalSettings.get("sideMenuSize")) : 0) >
        900
          ? 3
          : Dimensions.get("window").width -
              (this.props.globalSettings.get("sideMenuShown") ? calculateSizeMenu(this.props.globalSettings.get("sideMenuSize")) : 0) >
            600
          ? 2
          : 1),
      numberOfColumns:
        Dimensions.get("window").width -
          (this.props.globalSettings.get("sideMenuShown") ? calculateSizeMenu(this.props.globalSettings.get("sideMenuSize")) : 0) >
        900
          ? 3
          : Dimensions.get("window").width -
              (this.props.globalSettings.get("sideMenuShown") ? calculateSizeMenu(this.props.globalSettings.get("sideMenuSize")) : 0) >
            600
          ? 2
          : 1,
    },
  };

  componentDidMount() {
    const { profileSettings } = this.props;
    const startingPlace = profileSettings.get("startingPlace") ? profileSettings.get("startingPlace") : -4;

    let initalRoute = "Dashboard";
    if (startingPlace >= 0) {
      initalRoute = "Places";
    } else if (startingPlace == -1) {
      initalRoute = "Active";
    } else if (startingPlace == -2) {
      initalRoute = "Scenes";
    } else if (startingPlace == -3) {
      initalRoute = "Favourites";
    } else if (startingPlace == -4) {
      initalRoute = "Dashboard";
    }

    store.dispatch(changeRoute(initalRoute));
    //this.loadAlarmSounds();
    this.calculateParams();
    if (this.props.alertScreenVisible) {
      this.blinkingInterval = setInterval(() => {
        this.setState((prevState) => ({
          blinkingOpacity: prevState.blinkingOpacity == 0.9 ? 0.7 : 0.9,
        }));
      }, 500);
    }
  }

  loadAlarmSounds = async (play) => {
    const { sound } = await Audio.Sound.createAsync(require("./../../../assets/alarm.mp3"));
    await sound.setIsLoopingAsync(true);
    this.sound = sound;
    if (play) {
      this.sound.playAsync();
    }
  };

  calculateParams = () => {
    let { dimensions, showByColumns, sideMenuShown, sideMenuSize } = this.state;

    let { width, height } = dimensions;

    if (width && height) {
      let newDimensions = calculateDimmenions(width, height);

      if (!this.props.blockDimensionsChange) {
        this.setState({ dimensions: newDimensions });
        this.props.changeDimensions(newDimensions);
      }
    }
  };

  onCloseAlarmScreen = () => {
    this.props.onShowAlertScreen(false);
  };
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.alertScreenVisible != nextProps.alertScreenVisible && nextProps.alertScreenVisible) {
      this.blinkingInterval = setInterval(() => {
        this.setState((prevState) => ({
          blinkingOpacity: prevState.blinkingOpacity == 0.9 ? 0.7 : 0.9,
        }));
      }, 500);
    }
    if (!this.sound && nextProps.alertScreenVisible) {
      this.loadAlarmSounds(true);
    } else if (this.sound) {
      if (nextProps.alertScreenVisible) {
        this.sound.playAsync();
      } else {
        this.sound.stopAsync();
      }
    }

    if (this.props.appState === "background" && nextProps.appState === "active") {
      this.setState({ hideUrgent: 0 });
    }
    if (
      nextProps.urgentMessages &&
      nextProps.urgentMessages != null &&
      nextProps.urgentMessages.size >= 0 &&
      this.state.hideUrgent > nextProps.urgentMessages.size
    ) {
      this.setState({ hideUrgent: nextProps.urgentMessages.size });
    }
    let goToCalculate =
      this.props.globalSettings.get("showByColumns") != nextProps.globalSettings.get("showByColumns") ||
      this.props.globalSettings.get("showList") != nextProps.globalSettings.get("showList");

    if (this.props.globalSettings != nextProps.globalSettings) {
      let globalSettings = nextProps.globalSettings;
      this.setState(
        {
          showByColumns: globalSettings.get("showByColumns") == undefined ? true : globalSettings.get("showByColumns"),
          sideMenuShown: globalSettings.get("sideMenuShown"),
          sideMenuSize: globalSettings.get("sideMenuSize"),
        },
        () => {
          if (goToCalculate) {
            this.calculateParams();
          }
        }
      );
    }
  }

  componentWillUnmount() {
    clearInterval(this.blinkingInterval);
    this.blinkingInterval = 0;
  }

  render() {
    const {
      navigation,
      globalSettings,
      alertScreenVisible,
      currentTheme,
      devices,
      popupObjectId,
      changePopupObjectId,
      setDuringTalk,
      changeAnsweredCall,
      urgentMessages,
    } = this.props;
    const { dimensions, blinkingOpacity, sideMenuShown, sideMenuSize, hideUrgent } = this.state;
    let sideMenuWidth = sideMenuShown ? calculateSizeMenu(sideMenuSize) : 0;

    return (
      <View style={{ flex: 1 }}>
        {popupObjectId && popupObjectId != null && devices ? (
          <ModalWithContent
            isVisible={true}
            title={popupObjectId.get("title") ? t(popupObjectId.get("title")) : t("POPUP")}
            onClose={() => {
              changePopupObjectId(null);
              setDuringTalk(null);
              changeAnsweredCall(null);
            }}
          >
            <DeviceListHandler device={devices.get(`${popupObjectId.get("id")}`)} popup={true}></DeviceListHandler>
          </ModalWithContent>
        ) : null}
        {urgentMessages && urgentMessages != null && urgentMessages.size > 0 && hideUrgent < urgentMessages.size ? (
          <ModalWithContent
            isVisible={true}
            title={t("URGENT_MESSAGES_TO_READ")}
            onClose={() => {
              this.setState({ hideUrgent: urgentMessages.size });
            }}
          >
            <ButtonPrimary
              onPress={() => {
                this.setState({ hideUrgent: urgentMessages.size });
                navigation.navigate("Message_Center_Account");
              }}
            >
              {t("MESSAGES")}
            </ButtonPrimary>
          </ModalWithContent>
        ) : null}
        {alertScreenVisible && (
          <View
            style={{
              backgroundColor: "red",
              position: "absolute",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              zIndex: 100,
              flex: 1,
              justifyContent: "space-evenly",
              alignItems: "center",
              opacity: blinkingOpacity,
            }}
          >
            <Text
              style={{
                fontSize: 40,
                fontWeight: "bold",
                color: "white",
                textShadowColor: "#333",
                textShadowOffset: { width: 3, height: 3 },
                textShadowRadius: 3,
              }}
            >
              ALARM!
            </Text>
            <Feather
              name="alert-octagon"
              size={100}
              color="white"
              style={{
                textShadowColor: "#333",
                textShadowOffset: { width: 3, height: 3 },
                textShadowRadius: 3,
              }}
            />
            <TouchableOpacity
              onPress={this.onCloseAlarmScreen}
              style={{
                borderRadius: 15,
                paddingVertical: 5,
                backgroundColor: "white",
                width: 200,
                alignItems: "center",
                borderWidth: 0.4,
              }}
            >
              <Text style={{ fontSize: 20, fontWeight: "bold" }}>{t("CLOSE")}</Text>
            </TouchableOpacity>
          </View>
        )}
        <InsetWrapper sideMenuShown={sideMenuShown} landscapeOrientation={dimensions.landscapeOrientation} currentTheme={currentTheme}>
          {sideMenuShown ? (
            <View
              style={{
                width: sideMenuWidth,
                height: dimensions.height,
              }}
            >
              <SideMenu navigation={navigation} sideMenuWidth={sideMenuWidth} />
            </View>
          ) : null}

          <View style={{ flex: 1 }}>
            <InnerAppStackContainer />
          </View>
        </InsetWrapper>
      </View>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onShowAlertScreen: (value) => dispatch(showAlertScreen(value)),
    changeDimensions: (value) => dispatch(changeDimensions(value)),
    changePopupObjectId: (objectId) => dispatch(changePopupObjectId(objectId)),
    setDuringTalk: (duringTalk) => dispatch(setDuringTalk(duringTalk)),
    changeAnsweredCall: (answeredCall) => dispatch(changeAnsweredCall(answeredCall)),
  };
};

const mapStateToProps = (state) => {
  let currentProfile = state.profilesSettings.get("currentProfile");

  return {
    appState: state.statesData.get("appState"),
    globalSettings: state.globalSettings,
    alertScreenVisible: state.statesData.get("alertScreenVisible"),
    blockDimensionsChange: state.statesData.get("blockDimensionsChange"),
    profileSettings: state.profilesSettings.get(currentProfile),
    currentTheme: state.globalSettings.get("primaryTheme"),
    answeredCall: state.statesData.get("answeredCall"),
    popupObjectId: state.statesData ? state.statesData.get("popupObjectId") : null,
    urgentMessages: state.statesData ? state.statesData.get("urgentMessages") : null,
    duringTalk: state.statesData ? state.statesData.get("duringTalk") : false,
    devices: state.smartHomeData && state.smartHomeData.get(currentProfile) ? state.smartHomeData.get(currentProfile).get("devices") : null,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AppStackScreen);
