import * as Actions from "../actionTypes";
import { List, Map, fromJS } from "immutable";

const initialState = Map({
  currentProfile: "",
});

const smartHomeDataReducer = (state = initialState, action) => {
  let currentProfile = state.get("currentProfile");
  switch (action.type) {
    case Actions.CHANGE_CURRENT_PROFILE:
      return state.set("currentProfile", action.payload);
    case Actions.DELETE_PROFILE:
      let newState = state.delete(action.payload);
      if (action.payload == state.get("currentProfile")) {
        return newState.set("currentProfile", "");
      }
      return newState;

    case Actions.DEVICES_RECEIVED:
      return state.setIn([currentProfile, "devices"], fromJS(action.payload));

    case Actions.GROUP_DEVICES_RECEIVED:
      return state.setIn([currentProfile, "group_devices"], fromJS(action.payload));

    case Actions.GROUPS_RECEIVED:
      return state.setIn([currentProfile, "groups"], fromJS(action.payload));

    case Actions.PARAMS_DEVICES_RECEIVED:
      return state.setIn([currentProfile, "params_devices"], fromJS(action.payload));

    case Actions.LOGGING_RECEIVED:
      return state.setIn([currentProfile, "logging"], fromJS(action.payload));

    case Actions.SCENES_RECEIVED:
      return state.setIn([currentProfile, "scenes"], fromJS(action.payload));

    case Actions.RESOURCES_RECEIVED:
      return state
        .setIn([currentProfile, "events"], fromJS(action.payload.type4))
        .setIn([currentProfile, "userVariables"], fromJS(action.payload.type2))
        .setIn([currentProfile, "systemVariables"], fromJS(action.payload.type0));

    case Actions.ICONS_RECEIVED:
      return state.setIn([currentProfile, "icons"], fromJS(action.payload));

    //zrob!!!!
    case Actions.CHANGE_PARAM_OF_DEVICE:
      let newParams = fromJS(action.payload);
      let newset = state.setIn([currentProfile, "params_devices", action.deviceID], newParams);
      return newset;
    case Actions.CHANGE_NAME:
      return state.setIn([currentProfile, `${action.payload.objectType}`, action.payload.objectID, "opis_menu"], action.payload.newName);

    default:
      return state;
  }
};

export default smartHomeDataReducer;
