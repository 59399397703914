import React, { Component } from "react";
import { Text, View } from "react-native";
import { t } from "../../services/i18n";
import { Feather } from "@expo/vector-icons";

import {
  ComponentContainer,
  PrimaryColorText,
  StyledIcon,
  TextInputStyled,
} from "../../components/UI/styledComponents";
import PickerSelect from "../../components/UI/PickerSelect";

class TemperatureRowFixed extends Component {
  render() {
    const { heatingSchedule, temp, onTempValueChange } = this.props;
    return (
      <View>
        {heatingSchedule.active == 0 ? (
          <PrimaryColorText
            style={{
              fontStyle: "italic",
              fontSize: 18,
              textAlign: "center",
              color: "red",
              padding: 10,
            }}
          >
            {t("heating:UNACTIVE_ZONE")}
          </PrimaryColorText>
        ) : null}
        <ComponentContainer
          containerStyle={{ width: "100%" }}
          style={{ flexDirection: "row" }}
        >
          <View
            style={{
              flexDirection: "row",
              width: "47%",
              marginRight: "6%",
            }}
          >
            <PrimaryColorText>{t("heating:TEMP_MIN")}: </PrimaryColorText>
            <PrimaryColorText>
              {heatingSchedule.tempMin} &deg;C
            </PrimaryColorText>
          </View>
          <View
            style={{
              flexDirection: "row",
              width: "47%",
            }}
          >
            <PrimaryColorText>{t("heating:TEMP_MAX")}: </PrimaryColorText>
            <PrimaryColorText>
              {heatingSchedule.tempMax} &deg;C
            </PrimaryColorText>
          </View>
        </ComponentContainer>
        <ComponentContainer
          containerStyle={{ width: "100%" }}
          style={{ flexDirection: "row" }}
        >
          <View
            style={{
              display: "flex",
              width: "47%",
              marginRight: "6%",
            }}
          >
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginVertical: 5,
              }}
            >
              <PrimaryColorText>
                {t("heating:TEMP_ECO") + " / " + t("HOLIDAYS")}:{" "}
              </PrimaryColorText>
            </View>
            <PickerSelect
              placeholder={{}}
              items={temp}
              onValueChange={(e) => onTempValueChange(e, "tempEco")}
              value={Number(heatingSchedule.tempEco)}
            />
          </View>
          <View
            style={{
              display: "flex",
              width: "47%",
            }}
          >
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginVertical: 5,
              }}
            >
              <PrimaryColorText>{t("heating:TEMP_CONF")}: </PrimaryColorText>
            </View>
            <View>
              <PickerSelect
                placeholder={{}}
                items={temp}
                onValueChange={(e) => onTempValueChange(e, "tempComf")}
                value={Number(heatingSchedule.tempComf)}
              />
            </View>
          </View>
        </ComponentContainer>
      </View>
    );
  }
}

export default TemperatureRowFixed;
