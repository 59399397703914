import React from "react";
//import { Skottie } from "react-native-skottie";
// DotLottie files are supported as well!
import { View } from "react-native";
import LottieView from "lottie-react-native";

export const SkottieLoader = ({ style }) => {
  // add align self to style

  return (
    <View style={[{ alignSelf: "center" }, style]}>
      <LottieView source={require("./../../../assets/loader_animation.json")} style={[{ alignSelf: "center" }, style]} autoPlay loop />
    </View>
  );
};

export const NoDataAnimation = ({ style }) => {
  return (
    <View style={[{ alignSelf: "center" }, style]}>
      <LottieView source={require("./../../../assets/nodata_animation.json")} style={[{ alignSelf: "center" }, style]} autoPlay loop />
    </View>
  );
};

export const MusicPlayingAnimation = ({ style }) => {
  return (
    <View style={[{ alignSelf: "center" }, style]}>
      <LottieView
        source={require("./../../../assets/music_animation.json")}
        style={[{ alignSelf: "center" }, style]}
        autoPlay
        loop
        speed={2}
      ></LottieView>{" "}
    </View>
  );
};
