//COMMENT BELOW FOR WEB!!

import * as Sentry from "sentry-expo";

Sentry.init({
  dsn: "https://4889d5b0af3145e5b0f716cdeb51f603@sentry.io/1528782",
  enableInExpoDevelopment: false,
  debug: false,
});

// Sentry.setRelease(Constants.manifest.revisionId);

// blocked until the bug with the white screen is resolved, when going directly to nested stack and coming Back

// enableScreens();
import React, { Component } from "react";
import { Provider } from "react-redux";
import { ActionSheetProvider } from "@expo/react-native-action-sheet";
import ThemeProvider from "./ThemeContext";
import * as Updates from "expo-updates";
import { changeDeviceUUID } from "./src/store/actions";
import * as Location from "expo-location";
import * as SplashScreen from "expo-splash-screen";
import base64 from "base-64";
import * as Device from "expo-device";

import { Montserrat_300Light, Montserrat_700Bold } from "@expo-google-fonts/montserrat";

import { ActivityIndicator, LogBox, View, I18nManager, Platform, ImageBackground, Vibration, Alert } from "react-native";

import i18next from "i18next";
import * as Notifications from "expo-notifications";

import { PersistGate } from "redux-persist/integration/react";

import { Asset } from "expo-asset";
import * as Icon from "@expo/vector-icons";
import * as Font from "expo-font";

import AppContainer from "./src/navigation/AppContainer";
import { store, persistor } from "./src/store/configureStore";

import i18n from "./src/services/i18n";

require("./assets/themes.js");
import themesFile from "./assets/themes";

//ERROR IN WEB FROM BELOW
import Toast from "react-native-root-toast";
import * as ScreenOrientation from "expo-screen-orientation";
I18nManager.allowRTL(true);

import * as Application from "expo-application";
import { LocationGeofencingEventType } from "expo-location";
import * as TaskManager from "expo-task-manager";
import { v5 as uuidv5 } from "uuid";
import * as Linking from "expo-linking";
// used on web. you may need to use a polyfill for
// browsers that do not implement `crypto` interface (for example, IE < 11)
// or, you can use uuid@3.4.0 , which falls back to Math.random()
import { v4 as uuidv4 } from "uuid";
import { changePopupObjectId } from "./src/store/actions/statesData";
LogBox.ignoreLogs(["Setting a timer"]);
SplashScreen.preventAutoHideAsync();
const debug = require("debug");

const UUID_NAMESPACE = "c61aa376-957a-4f9a-afd1-928171d169cc";
let appStarts = 0;
let gotoGroup = true;
let orientation = null;
let type = "MOBILE";

async function getInstallationIdManually() {
  let installationId;

  if (["android", "ios"].includes(Platform.OS)) {
    let identifierForVendor;

    if (Platform.OS === "android") {
      identifierForVendor = Application.androidId;
    } else {
      // ios
      identifierForVendor = await Application.getIosIdForVendorAsync();
    }

    const bundleIdentifier = Application.applicationId;

    if (identifierForVendor) {
      installationId = uuidv5(`${bundleIdentifier}-${identifierForVendor}`, UUID_NAMESPACE);
    } else {
      const installationTime = await Application.getInstallationTimeAsync();
      installationId = uuidv5(`${bundleIdentifier}-${installationTime.getTime()}`, UUID_NAMESPACE);
    }
  } else {
    // WEB. random (uuid v4)
    installationId = uuidv4();
  }

  return installationId;
}

function timeout(ms) {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      reject(new Error("Request timed out"));
    }, ms);
  });
}

function fetchWithTimeout(url, options, timeoutDuration = 5000) {
  return Promise.race([fetch(url, options), timeout(timeoutDuration)]);
}

TaskManager.defineTask("GEOFENCE_TASK", async ({ data: { eventType, region }, error }) => {
  let profile = null;
  let regionIdent = region ? region.identifier : "";
  try {
    if (region.identifier) {
      let splited = region.identifier.split(";");
      if (splited.length > 1) {
        regionIdent = splited[0];
        if (
          store.getState().profilesSettings &&
          store.getState().profilesSettings.get(splited[1]) &&
          store.getState().profilesSettings.get(splited[1]).get("loginData")
        ) {
          profile = store.getState().profilesSettings.get(splited[1]).get("loginData").toJS();
        }
      }
    }
  } catch (ex) {}

  try {
    if (profile && profile.cloudIp && regionIdent) {
      let url = null;
      if (eventType === LocationGeofencingEventType.Enter) {
        url = `https://api-${profile.cloudIp}:443/api/location/inside/${regionIdent}`;
      } else if (eventType === LocationGeofencingEventType.Exit) {
        url = `https://api-${profile.cloudIp}:443/api/location/outside/${regionIdent}`;
      }

      if (url) {
        const headers = {
          Authorization: "Basic " + base64.encode(`${profile.login}:${profile.password}`),
        };

        let response = await fetchWithTimeout(url, { headers }, 5000);

        if (response && response.status !== 200) {
          Notifications.scheduleNotificationAsync({
            content: {
              title: "GEOFENCING",
              body: "Error in geofence query " + response.status,
            },
            trigger: null,
          });
        }
      }
    }
  } catch (ex) {}
  return;
});

Notifications.setNotificationHandler({
  handleNotification: async () => ({
    shouldShowAlert: true,
    shouldPlaySound: true,
    shouldSetBadge: false,
  }),
});

Notifications.addNotificationReceivedListener((notification) => {
  Vibration.vibrate();
  let title = notification ? notification.request.content.title : null;
  let message = notification ? notification.request.content.body : null;
  let customData = notification.request?.content?.data?.customData;
  let blockAlert = false;
  if (customData) {
    let customObj = JSON.parse(customData);
    if (customObj.action === "SHOW_OBJECT") {
      let objToShow = { id: customObj.objectId, title: customObj.title };
      if (objToShow) {
        store.dispatch(changePopupObjectId(objToShow));
        blockAlert = true;
      }
    }
  }
  if (blockAlert === false && window && window.app) {
    if (title && message) {
      Toast.show(title + " " + message, {
        duration: Toast.durations.LONG,
        position: Toast.positions.BOTTOM,
        shadow: true,
        animation: true,
        hideOnPress: true,
        delay: 0,
      });
    } else if (title) {
      Toast.show(title, {
        duration: Toast.durations.LONG,
        position: Toast.positions.BOTTOM,
        shadow: true,
        animation: true,
        hideOnPress: true,
        delay: 0,
      });
    } else if (message) {
      Toast.show(message, {
        duration: Toast.durations.LONG,
        position: Toast.positions.BOTTOM,
        shadow: true,
        animation: true,
        hideOnPress: true,
        delay: 0,
      });
    }
  }
});

// import ThemeContext from "./ThemeContext";
class App extends React.Component {
  constructor(props) {
    console.log("App starting...");
    super(props);
    debug.disable();
    //    this.onThemeChange("ampio");
    gotoGroup = true;
    type = "MOBILE";
    window.app = this;
    this.state = {
      isSplashReady: false,
      isAppReady: false,
      theme: themesFile["ampio"],
      mqttData: {},
      isI18nInitialized: false,
    };
  }

  async componentDidMount() {
    // Expo’s Localization.isRTL can get it wrong sometimes

    try {
      if (!Font.isLoaded({ Montserrat_300Light, Montserrat_700Bold })) {
        await Font.loadAsync({ Montserrat_300Light, Montserrat_700Bold });
      }
    } catch (ex) {
      console.log("Failed to load montserrat", ex);
    }
    const typeNum = await Device.getDeviceTypeAsync();

    if (typeNum === 2) {
      type = "TABLET";
    } else if (typeNum === 3) {
      type = "BROWSER";
    } else {
      type = "MOBILE";
    }
    i18n.init();

    if (!i18next.isInitialized) {
      // wait for initalization
      i18next.on("initialized", () => {
        this.rtlFunc();
      });
    } else {
      this.rtlFunc();
    }

    await this._cacheResourcesAsync();
  }

  rtlFunc = () => {
    const RNDir = I18nManager.isRTL ? "RTL" : "LTR";

    // RN doesn't always correctly identify native
    // locale direction, but i18next package yes, so if they are not the same
    // so we force it to rerneder here.
    if (i18n.dir !== RNDir && Platform.OS !== "web") {
      //ERROR IN WEB - direction is rtl for ltr orientations
      const isLocaleRTL = i18n.dir === "RTL";
      I18nManager.forceRTL(isLocaleRTL);

      // RN won't set the layout direction if we
      // don't restart the app's JavaScript.
      //Updates.reloadAsync();
    }

    this.setState({ isI18nInitialized: true });
  };
  renderLoading = () => (
    <View>
      <ActivityIndicator size="large" />
    </View>
  );

  onBeforeLiftHandler = () => {
    async function orientation() {
      let forceLandscapeOrientation = store.getState().globalSettings.get("forceLandscapeOrientation");

      let forceInvertedLandscapeOrientation = store.getState().globalSettings.get("forceInvertedLandscapeOrientation");

      if ((forceLandscapeOrientation || forceInvertedLandscapeOrientation) && Platform.OS !== "web") {
        try {
          if (forceInvertedLandscapeOrientation) {
            await ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock.LANDSCAPE_LEFT);
          } else {
            await ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock.LANDSCAPE_RIGHT);
          }
        } catch (error) {
          console.error(error, "error in 	ScreenOrientation.OrientationLock");
        }
      }
    }
    orientation();

    let chosenTheme = store.getState().globalSettings.get("primaryTheme");

    this.setState({ theme: themesFile[chosenTheme] });
  };

  onThemeChange = (newTheme) => {
    this.setState({ theme: themesFile[newTheme] });
  };

  getGoToGroup = () => {
    return gotoGroup;
  };
  getType = () => {
    return type;
  };

  setGoToGroup = (value) => {
    gotoGroup = value;
  };

  showToast = (messageToDispaly) => {
    Toast.show(messageToDispaly, {
      duration: Toast.durations.LONG,
      position: Toast.positions.BOTTOM,
      shadow: true,
      animation: true,
      hideOnPress: true,
      delay: 0,
    });
  };

  getAndRegisterGeofences = async () => {
    const profiles = store.getState().profilesSettings;
    let geofencesArray = [];

    profiles.map((el, key) => {
      if (el && typeof el === "object" && el.get("geofences")) {
        let geofences = el.get("geofences").toJS();
        for (const fence of geofences) {
          let splited = fence.identifier.split(";");
          if (splited.length > 1 && profiles.get(splited[1])) {
            geofencesArray.push(fence);
          } else if (splited.length == 1) {
            geofencesArray.push(fence);
          }
        }
      }
    });
    try {
      if (geofencesArray && geofencesArray.length > 0) {
        await Location.startGeofencingAsync("GEOFENCE_TASK", geofencesArray);
      } else {
        await Location.stopGeofencingAsync("GEOFENCE_TASK");
      }
    } catch (ex) {
      //console.error(ex, "error in getAndRegisterGeofences");
    }
  };

  componentWillUnmount() {
    // Notifications.removeNotificationSubscription();
  }

  render() {
    if (this.state.isAppReady && this.state.isI18nInitialized) {
      return (
        <Provider store={store}>
          <PersistGate persistor={persistor} loading={this.renderLoading()} onBeforeLift={this.onBeforeLiftHandler}>
            <ActionSheetProvider>
              <ThemeProvider
                value={{
                  theme: this.state.theme,
                  onThemeChange: this.onThemeChange,
                }}
              >
                <AppContainer />
              </ThemeProvider>
            </ActionSheetProvider>
          </PersistGate>
        </Provider>
      );
    }

    return (
      <View style={{ flex: 1, backgroundColor: "#050505" }}>
        <ImageBackground
          source={require("./assets/splash.png")}
          resizeMode="cover"
          style={{
            flex: 1,
            justifyContent: "center",
          }}
        ></ImageBackground>
      </View>
    );
  }

  _cacheResourcesAsync = async () => {
    let cacheImages = null;
    try {
      const images = [
        require("./assets/iphone_login_background.jpg"),
        require("./assets/iphone_login_logo.png"),
        require("./assets/iphone_login_logo2.png"),
        require("./assets/background_app.png"),
        require("./assets/background_loading.jpeg"),
        require("./assets/rectangle.png"),
        require("./assets/logo_ciemnetlo.png"),
        require("./assets/logo_ciemnetlo2.png"),
        require("./assets/splash.png"),
        require("./assets/splash_dark.png"),
        require("./assets/ampio1024.png"),
        require("./assets/ampio1024_transparent.png"),
        require("./assets/ampio96_transparent.png"),
        require("./assets/color-wheel.png"),
        require("./assets/rectangle.png"),
        require("./assets/rectangle_full.png"),
        require("./assets/ampio-hat.svg"),
      ];

      cacheImages = images.map((image) => {
        return Asset.fromModule(image).downloadAsync();
      });
    } catch (ex) {
      console.log("Failed to load assets");
    }

    try {
      if (cacheImages) {
        await Promise.all([
          cacheImages,
          Font.loadAsync({
            ...Icon.FontAwesome.font,
            ...Icon.Feather.font,
            ...Icon.MaterialCommunityIcons.font,
          }),
        ]);
      }
    } catch (ex) {
      console.log("Failed to load assets");
    }
    let storedIdOfDevice = store.getState().globalSettings.get("deviceUUID");
    if (!storedIdOfDevice) {
      let idOfDevice = await getInstallationIdManually();
      store.dispatch(changeDeviceUUID(idOfDevice));
    }
    try {
      this.orientation = await ScreenOrientation.getOrientationAsync();
    } catch (error) {
      console.error(error, "error in 	ScreenOrientation.OrientationLock");
    }
    this.setState({ isAppReady: true });
  };
}

export default App;
