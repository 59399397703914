import * as React from "react";
import Svg, { Defs, LinearGradient, Stop, Path } from "react-native-svg";

const AppleMusicIcon = (props) => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={36} height={36} {...props}>
    <Defs>
      <LinearGradient
        id="a"
        gradientUnits="userSpaceOnUse"
        x1={36}
        y1={2.286}
        x2={36}
        y2={72.455}
        gradientTransform="matrix(.49315 0 0 -.49315 0 36.497)"
      >
        <Stop
          offset={0}
          style={{
            stopColor: "#fa233b",
            stopOpacity: 1,
          }}
        />
        <Stop
          offset={1}
          style={{
            stopColor: "#fb5c74",
            stopOpacity: 1,
          }}
        />
      </LinearGradient>
    </Defs>
    <Path
      style={{
        stroke: "none",
        fillRule: "evenodd",
        fill: "url(#a)",
      }}
      d="M35.508 11.105V9.832c0-.355-.008-.715-.016-1.07-.02-.778-.07-1.563-.207-2.332a7.875 7.875 0 0 0-.73-2.22 7.413 7.413 0 0 0-1.371-1.886 7.413 7.413 0 0 0-1.887-1.37 7.728 7.728 0 0 0-2.215-.731c-.77-.141-1.555-.184-2.332-.207-.363-.004-.723-.012-1.078-.016H9.832c-.355 0-.715.004-1.07.016-.778.02-1.563.066-2.332.207a7.697 7.697 0 0 0-2.215.734c-.7.356-1.336.816-1.887 1.371A7.36 7.36 0 0 0 .953 4.215a7.698 7.698 0 0 0-.73 2.223C.082 7.202.039 7.987.016 8.77.012 9.125.004 9.477 0 9.832V25.668c0 .355.004.715.016 1.07.02.782.066 1.563.207 2.332.136.782.367 1.512.73 2.22a7.502 7.502 0 0 0 1.371 1.89 7.508 7.508 0 0 0 1.887 1.37c.71.36 1.437.59 2.215.731.77.137 1.554.18 2.332.207.355.008.715.012 1.07.012.426.008.848 0 1.274 0h14.562c.356 0 .715-.004 1.07-.012.778-.02 1.563-.07 2.332-.207a7.934 7.934 0 0 0 2.215-.73 7.508 7.508 0 0 0 1.887-1.371 7.365 7.365 0 0 0 1.371-1.89c.36-.708.594-1.435.73-2.22.141-.77.184-1.55.208-2.332.011-.355.015-.715.015-1.07.004-.422 0-.848 0-1.273Zm0 0"
    />
    <Path
      style={{
        stroke: "none",
        fillRule: "evenodd",
        fill: "#fff",
        fillOpacity: 1,
      }}
      d="M25.102 5.426c-.086.008-.848.14-.942.16L13.605 7.719h-.003c-.278.058-.493.156-.657.297-.203.164-.312.406-.355.683a2.515 2.515 0 0 0-.024.356v13.207c0 .308-.027.605-.234.863-.207.254-.469.328-.77.395-.23.043-.457.093-.687.136-.875.176-1.441.297-1.953.492-.492.196-.86.434-1.156.743-.582.605-.817 1.433-.735 2.207.07.66.367 1.293.88 1.757.343.313.773.555 1.28.657.528.101 1.086.066 1.907-.102.433-.086.84-.226 1.23-.453.387-.227.715-.527.973-.898.258-.368.422-.778.515-1.215.094-.45.121-.86.121-1.309v-11.45c0-.612.172-.776.668-.898l9.184-1.851c.574-.106.84.055.84.652v7.82c0 .313-.008.622-.215.88-.207.257-.465.332-.766.394-.234.043-.46.094-.691.137-.875.18-1.441.297-1.953.496-.492.191-.86.433-1.156.738a2.787 2.787 0 0 0-.754 2.211c.07.66.386 1.29.898 1.754.344.316.774.547 1.281.652.528.102 1.086.067 1.907-.101a3.82 3.82 0 0 0 1.23-.446c.387-.23.715-.53.973-.898.258-.371.422-.781.515-1.219.094-.449.102-.86.102-1.308V6.355c-.008-.605-.328-.98-.898-.93Zm0 0"
    />
  </Svg>
);

export default AppleMusicIcon;
