import React, { Component } from "react";

import { View, Platform } from "react-native";
import {
	PrimaryColorText,
	ComponentContainer,
	SecondaryColorText,
} from "../../components/UI/styledComponents";
import { connect } from "react-redux";

import PickerSelect from "../../components/UI/PickerSelect";

import { t } from "../../services/i18n";

let hrs = [];
for (let i = 0; i <= 24; i++) {
	let label = i % 2 == 0 ? i / 2 : (i + 1) / 2 - 1 + ",5";
	// if (label < 10) {
	// 	label = "0" + label;
	// }
	let value = i / 2;
	let element = {
		label: label.toString(),
		value: value * 60,
	};
	hrs.push(element);
}

class RainTimeComponent extends Component {
	state = {
		timeDuration: this.props.condition.value,
		rainDuration: this.props.condition.value2,
	};

	onScheduleChange = (e, key) => {
		this.setState(
			{
				[key]: e,
			},
			() => {
				if (Platform.OS === "android") {
					const { modifyCondition, condition, index } = this.props;

					let sub_condition = {
						...condition,
						value: this.state.timeDuration,
						value2: this.state.rainDuration,
					};

					modifyCondition(sub_condition, index);
				}
			}
		);
	};
	onScheduleClose = () => {
		const { modifyCondition, condition, index } = this.props;
		const { timeDuration, rainDuration } = this.state;

		let sub_condition = {
			...condition,
			value: timeDuration,
			value2: rainDuration,
		};
		modifyCondition(sub_condition, index);
	};

	render() {
		const { timeDuration, rainDuration } = this.state;
		const { device, deviceState } = this.props;
		return (
			<ComponentContainer containerStyle={{ width: "100%" }}>
				<View
					style={{
						flexDirection: "row",
						alignItems: "center",
						justifyContent: "space-between",
					}}
				>
					<PrimaryColorText>
						{t("conditions:RAIN_SENSOR_TIME")}:{" "}
					</PrimaryColorText>
					<View
						style={{
							flexDirection: "row",
							alignItems: "center",
						}}
					>
						<PrimaryColorText>
							{device ? device.get("opis_menu") : null}
						</PrimaryColorText>
						{deviceState ? (
							<SecondaryColorText>
								{" "}
								({deviceState.get("state") == 0 ? "OFF" : "ON"})
							</SecondaryColorText>
						) : null}
					</View>
				</View>
				<View
					style={{
						flexDirection: "row",
						alignItems: "center",
						paddingTop: 10,
						flexWrap: "wrap",
					}}
				>
					<PrimaryColorText>{t("conditions:WATER_IF")}</PrimaryColorText>
					<PickerSelect
						placeholder={{}}
						additionalTextStyle={{ fontSize: 12 }}
						containerStyle={{ width: 80, marginHorizontal: 6 }}
						items={hrs}
						onValueChange={(e) => this.onScheduleChange(e, "timeDuration")}
						onClose={this.onScheduleClose}
						value={timeDuration}
					/>
					<PrimaryColorText>{t("conditions:H")}, </PrimaryColorText>

					<PrimaryColorText>{t("conditions:RAIN_MORE")}</PrimaryColorText>
					<PickerSelect
						placeholder={{}}
						additionalTextStyle={{ fontSize: 12 }}
						containerStyle={{ width: 80, marginHorizontal: 6 }}
						items={hrs}
						onValueChange={(e) => this.onScheduleChange(e, "rainDuration")}
						onClose={this.onScheduleClose}
						value={rainDuration}
					/>
					<PrimaryColorText>{t("conditions:H")}.</PrimaryColorText>
				</View>
			</ComponentContainer>
		);
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		device: state.smartHomeData
			.get(state.profilesSettings.get("currentProfile"))
			.get("devices")
			.get(ownProps.condition.object_id),
		deviceState: state.statesData
			.get("states")
			.get(ownProps.condition.object_id),
	};
};
export default connect(mapStateToProps, null)(RainTimeComponent);
