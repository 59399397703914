import React, { Component } from "react";
import { Text, View } from "react-native";

import { t } from "../../services/i18n";
import moment from "moment";

import DateTimePicker from "../../components/UI/dateTimePicker";
import { PrimaryColorText } from "../../components/UI/styledComponents";
class DatePickerRow extends Component {
  state = {
    schedule: this.props.schedule,
  };
  onTimeChange = (value, key) => {
    let schedule = Object.assign({}, this.state.schedule);

    let time;
    if (this.props.mode == "datetime") {
      time = Number(moment(value).format("X"));
      const offset = new Date().getTimezoneOffset();
      time = time - offset * 60;
    } else {
      time = moment(value).local().format("HH:mm");
    }
    schedule[key] = time;

    this.setState({ schedule }, () => {
      this.props.onScheduleChange(time, key);
    });
  };
  render() {
    const { mode } = this.props;

    let { s, e } = this.state.schedule;

    let start, end;
    if (mode == "datetime") {
      const offset = new Date().getTimezoneOffset();
      start = moment(s + offset * 60, "X").toDate();
      end = moment(e + offset * 60, "X").toDate();
    } else {
      start = moment(s, "HH:mm").toDate();
      end = moment(e, "HH:mm").toDate();
    }
    let dataPickerRow = (
      <View
        style={{
          flexDirection: "row",
          marginTop: 10,
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <PrimaryColorText
          style={{
            fontWeight: "bold",
            marginRight: 10,
          }}
        >
          {t("TIME")}:
        </PrimaryColorText>
        <View
          style={{
            flex: 3,
            marginHorizontal: 10,
          }}
        >
          <DateTimePicker
            value={moment(start).format(mode == "datetime" ? "DD-MM-YY, HH:mm" : "HH:mm")}
            date={start}
            onConfirm={(time) => this.onTimeChange(time, "s")}
            mode={mode}
          />
        </View>

        <View style={{ flex: 3 }}>
          <DateTimePicker
            value={moment(end).format(mode == "datetime" ? "DD-MM-YY, HH:mm" : "HH:mm")}
            date={end}
            onConfirm={(time) => this.onTimeChange(time, "e")}
            mode={mode}
          />
        </View>
      </View>
    );

    return dataPickerRow;
  }
}

export default DatePickerRow;
