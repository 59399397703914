import * as React from "react";
import { Text, View, TouchableOpacity, Platform } from "react-native";

import { t } from "../services/i18n";

import { ComponentHeaderText, StyledIcon } from "../components/UI/styledComponents";
import ActionSheet from "./ActionSheet";
import { MaterialCommunityIcons } from "@expo/vector-icons";

import { compose } from "redux";
import { connect } from "react-redux";

class HeaderWithDotsScenes extends React.Component {
  state = { showWebActionSheet: false };
  showActionSheet = () => {
    if (Platform.OS != "web") {
      this.ActionSheet.show();
    } else {
      this.setState({ showWebActionSheet: true });
    }
  };

  doAction = (index) => {
    const { sceneID, sceneName, removeScene, copyScene, editScene, editSceneIdent } = this.props;
    if (index == 0) {
      removeScene(sceneID);
    } else if (index == 1) {
      editScene(sceneName);
    } else if (index == 2) {
      editSceneIdent(sceneName);
    } else if (index == 3) {
      copyScene(sceneName);
    }
  };

  handleOnPressAction = (index) => {
    if (Platform.OS == "web") {
      this.setState({ showWebActionSheet: false }, () => {
        this.doAction(index);
      });
    } else {
      this.doAction(index);
    }
  };

  render() {
    let { sceneID, sceneName, sceneLabel, isBig } = this.props;
    return (
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          flex: 1,
          width: "100%",
          // alignContent: "center",
          // justifyContent: "center",
          // alignItems: "center",
          // alignSelf: "center",
        }}
      >
        <TouchableOpacity
          onPress={this.showActionSheet}
          // style={{ paddingHorizontal: 10 }}
        >
          <StyledIcon color="secondary" size={20} name="more-vertical" />
        </TouchableOpacity>
        <ActionSheet
          actionRef={(o) => (this.ActionSheet = o)}
          options={[t("scenes:REMOVE"), t("scenes:EDIT_ACTION"), t("scenes:EDIT_IDENT"), t("scenes:COPY"), t("CLOSE")]}
          cancelButtonIndex={4}
          onPress={(index) => this.handleOnPressAction(index)}
          onWebActionSheetClose={() => this.setState({ showWebActionSheet: false })}
          showWebActionSheet={this.state.showWebActionSheet}
        />
        <ComponentHeaderText
          numberOfLines={1}
          style={{
            textAlign: isBig ? "center" : null,
            paddingRight: 10,
            flex: 1,
            alignContent: "center",
            justifyContent: "center",
            alignItems: "flex-start",
            alignSelf: "center",
          }}
        >
          {sceneLabel}
        </ComponentHeaderText>

        {this.props.children}
      </View>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onRemoveScene: (sceneID) => dispatch(removeScene(sceneID)),
  };
};

export default connect(null, mapDispatchToProps)(HeaderWithDotsScenes);
