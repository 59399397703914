import React, { Component } from "react";
import { View } from "react-native";
import * as Localization from "expo-localization";
import PickerSelect from "../components/UI/PickerSelect";
import { t } from "../services/i18n";
import mqttClient from "../services/mqtt";
import {
  ButtonPrimary,
  TextInputStyled,
} from "../components/UI/styledComponents";
import ModalWithContent from "../components/UI/ModalWithContent";

let lang = Localization.locale.split("-")[0];
class DeviceTimeControlDialog extends Component {
  componentDidMount() {}

  handleClose = () => {
    this.props.handleCloseDialog();
  };

  handleStart = () => {
    const { device } = this.props;
    let timeToSet = Number(this.state.time);
    if (timeToSet > 0) {
      if (this.state.pickerTypeItemsValue == 0) timeToSet *= 60;
      let message = `/api/set/${device.get("id")}/setValue/255/${
        timeToSet * 100
      }`;
      mqttClient.stateChangeToSend(message, device.get("id"));
      this.props.handleCloseDialog();
    }
  };

  state = {
    pickerTypeItemsValue: 1,
    time: null,
  };

  render() {
    const { dialogVisible } = this.props;
    const { time } = this.state;
    const pickerTypeItems = [
      { label: "min", value: 0 },
      { label: "s", value: 1 },
    ];

    let content = (
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <TextInputStyled
          placeholder={"0"}
          keyboardType={"numeric"}
          value={time}
          onChangeText={(value) => {
            this.setState({ time: value });
          }}
          style={{ width: 40, textAlign: "center", paddingRight: 5 }}
        />

        <PickerSelect
          placeholder={{}}
          containerStyle={{ width: 70, paddingHorizontal: 5 }}
          items={pickerTypeItems}
          onValueChange={(value) => {
            this.setState({ pickerTypeItemsValue: value });
          }}
          value={this.state.pickerTypeItemsValue}
        />
        <ButtonPrimary style={{ marginTop: 10 }} onPress={this.handleStart}>
          {t("START")}
        </ButtonPrimary>
      </View>
    );

    return (
      <ModalWithContent
        isVisible={dialogVisible}
        title={t("INFO")}
        onClose={this.handleClose}
      >
        {content}
      </ModalWithContent>
    );
  }
}

export default DeviceTimeControlDialog;
