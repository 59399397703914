import React from "react";

import { View, Text } from "react-native";

import WeatherComponent from "./weather/Weather";
import CalendarComponent from "./calendar/Calendar";
import { connect } from "react-redux";
import { DeviceListHandler } from "../../utils";
import {
  PrimaryColorText,
  ComponentContainer,
} from "../../components/UI/styledComponents";
import { fromJS } from "immutable";
import { t } from "../../services/i18n";
import { useSelector } from "react-redux";

const EmptyView = () => {
  return (
    <View
      style={{
        width: "100%",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <PrimaryColorText>{t("GREETING_VIEW_NOTHING_CHOSEN")}</PrimaryColorText>
    </View>
  );
};
const GreetingView = ({ devices, greetingViewObjects, dimensions }) => {
  const width = dimensions.get("width");
  const height = dimensions.get("height");
  let dashboardTopBarShownProp = useSelector((state) =>
    state.globalSettings.get("dashboardTopBarShown")
  );
  const dashboardTopBarShown =
    dashboardTopBarShownProp == undefined ? true : dashboardTopBarShownProp;

  let headerBarHeight = dashboardTopBarShown ? 64 : 0;
  let bottomBarHeight = 50;

  let gretingViewHeight = height - headerBarHeight - bottomBarHeight;

  const componentType = (el) => {
    if (el.get("type") == "device" && devices.get(`${el.get("id")}`)) {
      return (
        <DeviceListHandler
          device={devices.get(`${el.get("id")}`)}
          gretingViewHeight={gretingViewHeight}
        />
      );
    } else if (el.get("type") == "weather") {
      return <WeatherComponent style={{ width: "100%", height: "100%" }} />;
    } else if (el.get("type") == "calendar") {
      return (
        <CalendarComponent containerStyle={{ width: "100%", height: "100%" }} />
      );
    } else {
      return <EmptyView />;
    }
  };
  if (!greetingViewObjects || !greetingViewObjects.size) {
    greetingViewObjects = fromJS([
      {
        type: "",
        id: null,
      },
      {
        type: "",
        id: null,
      },
      {
        type: "",
        id: null,
      },
      {
        type: "",
        id: null,
      },
    ]);
  }

  return (
    <View
      style={{
        width: width,
        height: gretingViewHeight,
        flexWrap: "wrap",
        flexDirection: "row",
      }}
    >
      {greetingViewObjects.map((el, index) => {
        return (
          <ComponentContainer
            key={index}
            containerStyle={{
              width: width / 2 - 2.5,
              height: gretingViewHeight / 2 - 2.5,
            }}
            style={{
              paddingVertical: 0,
              justifyContent: "center",
              flex: 1,
            }}
          >
            {componentType(el)}
          </ComponentContainer>
        );
      })}
    </View>
  );
};

const mapStateToProps = (state) => {
  return {
    dimensions: state.statesData.get("dimensions"),
    devices: state.smartHomeData
      .get(state.profilesSettings.get("currentProfile"))
      .get("devices"),
    greetingViewObjects: state.profilesSettings
      .get(state.profilesSettings.get("currentProfile"))
      .get("greetingViewObjects"),
  };
};
export default connect(mapStateToProps)(GreetingView);
