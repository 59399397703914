import React, { Component, useEffect, useState, useRef } from "react";
import { Dimensions, StyleSheet, View, I18nManager, Image, TouchableOpacity, Text, FlatList, Keyboard, PermissionsAndroid } from "react-native";
import { Audio, InterruptionModeAndroid, InterruptionModeIOS } from "expo-av";
import { connect } from "react-redux";
import { Platform } from "react-native";
import { t } from "../../../services/i18n";
import { NoDataScreen, TextInputStyled } from "../../../components/UI/styledComponents";
import MultiplePickerSelectObjects from "../../Scenes/MultiplePickerSelectObjects";
import mqttClient from "../../../services/mqtt";
import AppleMusicIcon from "../../../utils/svg/AppleMusicIcon";
import Animated from "react-native-reanimated";
import { useIsFocused } from "@react-navigation/native";
import { GoToModal } from "../../../utils";
import { ComponentContainerTouchable, StyledIcon, ComponentHeaderText, SecondaryColorText } from "../../../components/UI/styledComponents";
import { ScreenView } from "../../../components/UI/screenViewX";
import SearchIcon from "../../../utils/svg/SearchIcon";
import { store } from "../../../store/configureStore";
import ActionSheet from "../../../utils/ActionSheet";

import { addRadioToFavourites, removeRadioFromFavourites, changeExpoSound, changeSoundStatus, changeSelectedSoruces } from "../../../store/actions";
import { MusicPlayingAnimation } from "../../../components/animatedComponents/Loader";

const events = [Event.PlaybackState, Event.PlaybackError];

const AudioLibrary_AccountScreen = ({
  changeExpoSound,
  changeSoundStatus,
  navigation,
  route,
  devices,
  selectedSoruces,
  detectedOutputs,
  dimensions,
  favouritesRadios,
  addRadioToFavourites,
  removeRadioFromFavourites,
  changeSelectedSoruces,
}) => {
  const [state, setState] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showWebActionSheet, setWebActionSheetVisible] = useState(false);
  const [preset, setPreset] = useState(null);
  const [actionSheet, setActionSheet] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedRadio, setSelectedRadio] = useState(null);
  const selectedRadioRef = useRef(selectedRadio);
  const isAirplayConnected = Platform.OS === "ios" ? useAirplayConnectivity() : null;
  const isFocused = useIsFocused();
  const [playerState, setPlayerState] = useState(null);

  const setSearchQuery = async (value, type) => {
    if (type === "APPLE_MUSIC") {
      getAppleMusicData(value);
    } else if (type === "SPOTIFY") {
      getSpotifyMusicData(value);
    }
  };

  async function playSound(radio, device) {
    setPreset(radio);
    const selected = [];
    let playLocal = false;
    Object.keys(selectedSoruces).forEach((key) => {
      if (selectedSoruces[key]) {
        if (key === "local") {
          playLocal = true;
        } else {
          selected.push(key);
        }
      }
    });
    if (radio.key && radio.key.startsWith("APPLEID")) {
      let type = "LOCAL_APPLE";
      if (!device && selected.length === 0) {
        AppleMusic.startSong(radio.preset_id);
      } else {
        let message = `/api/set/${device.value}/setIpRadioSharelink/${radio.URL}`;
        mqttClient.stateChangeToSend(message, device.value);
        type = "IP_APPLE";
      }
      const radioInfo = {
        title: radio.text,
        subtitle: radio.subtext,
        image: radio.image,
      };
      changeSoundStatus({
        ...radioInfo,
        ...{ isPlaying: true, type },
        ...{ radio },
      });
    } else if (radio.key && radio.key.startsWith("SPOTIFYID")) {
      if (selected && selected.length > 0) {
        selected.forEach((device) => {
          if (device !== "local") {
            let message = `/api/set/${device}/setIpRadioSharelink/${radio.URL}`;
            mqttClient.stateChangeToSend(message, device);
          }
        });
      } else {
        Keyboard.dismiss();
        window.app.showToast("Select SONOS device to play music");
      }
    } else {
      if (!isLoading) {
        setIsLoading(true);
        setPreset(radio);
        fetch(radio.URL)
          .then((response) => response.text())
          .then(async (textResponse) => {
            const source = {
              uri: textResponse.split("\n")[0].replace(/(\r\n|\n|\r)/gm, ""),
            };

            const radioInfo = {
              title: radio.text,
              subtitle: radio.subtext,
              image: radio.image,
              uri: source.uri,
            };

            if (detectedOutputs && detectedOutputs.find((el) => el.selected) && !playLocal) {
              mqttClient.requestOt("playUri", "POST", "/api/queue/items/add?playback=start&shuffle=false&uris=" + source.uri);
              changeSoundStatus({
                ...radioInfo,
                isPlaying: true,
                ...{ radio },
              });
            } else {
              if (device) {
                let message = `/api/set/${device.value}/setIpRadioUri/${source.uri}`;
                mqttClient.stateChangeToSend(message, device.value);
              }
              selected.map((obj) => {
                let message = `/api/set/${obj}/setIpRadioUri/${source.uri}`;
                mqttClient.stateChangeToSend(message, obj);
              });
              changeSoundStatus({
                ...radioInfo,
                isPlaying: true,
                ...{ radio },
              });

              //console.log(message, mqttClient);
            }
            setIsLoading(false);
          })
          .catch((error) => {
            console.log("AudioLibrary", error);
            setIsLoading(false);
          });
      }
    }
  }

  const handleOnPressAction = (index) => {
    if (Platform.OS == "web") {
      this.setState({ showWebActionSheet: false });
    }

    if (index == 0) {
      setModalVisible(true);
    } else if (index == 1 && route.params.url === "FAVORITES" && selectedRadioRef.current) {
      removeRadioFromFavourites(selectedRadioRef.current.storeId);
    } else if (index == 1 && selectedRadioRef.current) {
      fetch(selectedRadioRef.current.URL)
        .then((response) => response.text())
        .then(async (textResponse) => {
          selectedRadioRef.current.uri = textResponse.split("\n")[0].replace(/(\r\n|\n|\r)/gm, "");
          addRadioToFavourites(selectedRadioRef.current.preset_id, selectedRadioRef.current);
        });
    }
  };

  const getRadios = () => {
    let devicesArray = [];
    return devicesArray;
  };

  const showActionSheet = (item) => {
    setSelectedRadio(item);
    selectedRadioRef.current = item;
    if (Platform.OS != "web") {
      actionSheet.show();
    } else {
      setWebActionSheetVisible(true);
    }
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  const onDeviceStreamValueChange = (device) => {
    setModalVisible(false);
    device.map((el, key) => {
      if (el && typeof el === "object") {
        //console.log("Playing", selectedRadio, el);
        playSound(selectedRadio, el);
      }
    });

    setSelectedRadio(null);
  };

  const getItemView = (item) => {
    let secondLine = item.subtext;
    if (secondLine && item.subtype) {
      secondLine = item.subtype + " - " + item.subtext;
    }
    return (
      <ComponentContainerTouchable
        key={item.URL ? item.URL : item.text}
        numberOfColumns={dimensions.get("smallTiles") ? 1 : undefined}
        onLongPress={() => {}}
        onPress={() => {
          if (item.type === "station" || item.type === "audio" || item.type === "topic") {
            let playMedia = false;
            if (preset && item.preset_id == preset.preset_id) {
              setPreset(null);
            } else {
              playMedia = true;
            }

            try {
              if (Platform.OS === "ios") {
                AppleMusic.pause();
              }
              const sound = store.getState().statesData.get("expoSound");
              if (sound) {
                sound.unloadAsync();
              }
              if (client) {
                client.pause();
              }
              if (detectedOutputs && detectedOutputs.find((el) => el.selected)) {
                mqttClient.requestOt("pause", "PUT", "/api/player/pause");
              }
            } catch (ex) {
              console.log(ex);
              setIsLoading(false);
            }

            if (playMedia) {
              playSound(item);
            }
          } else if (item.URL || item.children) {
            if (item.URL === "LOCAL_STORAGE") {
              //pickMediaAsync();
            } else {
              navigation.push("AudioLibrary_Account", {
                url: item.URL,
                children: item.children,
              });
            }
          }
        }}
        style={{ alignItems: "center" }}
      >
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          {item.type === "station" || item.type === "audio" || item.type === "topic" ? (
            <TouchableOpacity
              onPress={() => {
                if (item) {
                  showActionSheet(item);
                }
              }}
              style={{ width: 20, height: 30, marginTop: 5 }}
              hitSlop={{ top: 10, bottom: 10, left: 10, right: 10 }}
            >
              <StyledIcon color="secondary" size={20} name="more-vertical" />
            </TouchableOpacity>
          ) : null}
          <View>
            {(item.type === "station" || item.type === "audio" || item.type === "topic") && item.image ? (
              <View>
                <Image source={{ uri: item.image }} resizeMode="stretch" style={{ width: 36, height: 36 }} width={36} height={36} />
              </View>
            ) : item.key === "LOCAL_STORAGE" ? (
              <StyledIcon name="music" size={36} color={"secondary"} />
            ) : item.key === "FAVORITES" ? (
              <StyledIcon name="star-outline" size={36} color={"secondary"} library="MaterialCommunityIcons" />
            ) : item.key === "APPLE_MUSIC" ? (
              <AppleMusicIcon></AppleMusicIcon>
            ) : (
              <StyledIcon name="refresh-ccw" size={36} color={"secondary"} />
            )}
          </View>
          {preset && item.preset_id === preset.preset_id ? (
            <MusicPlayingAnimation style={{ width: 40, height: 40, paddingLeft: 5 }} />
          ) : // <Image
          //   source={require("../../../../assets/playing.gif")}
          //   resizeMode="stretch"
          //   style={{ width: 30, height: 30, paddingLeft: 5 }}
          //   width={30}
          //   height={30}
          // />
          null}
          <View style={{ flexDirection: "column" }}>
            <ComponentHeaderText style={{ paddingLeft: 5 }}>{item ? item.text : "N/A"}</ComponentHeaderText>
            {secondLine && <SecondaryColorText style={{ paddingLeft: 5 }}>{secondLine}</SecondaryColorText>}
          </View>
        </View>
        {item.type === "station" || item.type === "audio" || item.type === "topic" || item.type === "text" ? null : (
          <StyledIcon name={I18nManager.isRTL ? "chevron-left" : "chevron-right"} size={36} color={"secondary"} />
        )}
      </ComponentContainerTouchable>
    );
  };

  const getSpotifyMusicData = async (value) => {
    if (value && value.length > 2) {
      var params = {
        method: "POST",
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          method: "GET",
          query: "/v1/search?query=" + value + "&type=album,track,playlist",
          body: "",
        }),
      };

      const response = await fetch("https://hook.eu1.make.com/datazx1uoy6rvpsm7dwftnrokmc1xiog", params);
      const spotifyData = await response.json();
      const data = [];

      const parseSpotifyData = (el) => {
        data.push({
          element: "outline",
          type: "audio",
          subtype: el.type,
          text: el.name,
          URL: el.uri,
          subtext: "",
          image: el.images && el.images.length > 0 ? el.images.at(-1).url : undefined,
          key: "SPOTIFYID" + el.id,
          preset_id: el.id,
        });
      };

      if (spotifyData && spotifyData.albums) {
        spotifyData &&
          spotifyData.albums &&
          spotifyData.albums.items &&
          spotifyData.albums.items.forEach((el) => {
            parseSpotifyData(el);
          });

        spotifyData.tracks &&
          spotifyData.tracks.items &&
          spotifyData.tracks.items.forEach((el) => {
            parseSpotifyData(el);
          });

        spotifyData.playlists &&
          spotifyData.playlists.items &&
          spotifyData.playlists.items.forEach((el) => {
            parseSpotifyData(el);
          });

        setState(data);
      }
    }
  };
  const getAppleMusicData = async (value) => {};

  useEffect(() => {
    if (route.params.url === "FAVORITES") {
      if (favouritesRadios) {
        setState(
          favouritesRadios
            .toJS()
            .filter((el) => el.radio)
            .map((el) => ({
              ...el.radio, // Spread the 'radio' object properties
              storeId: el.id, // Add the 'storeId' field
            }))
        );
      }
    }
  }, [favouritesRadios]);

  useEffect(() => {
    if (route && route.params && route.params.children) {
      setState(route.params.children);
    } else {
      let fetchUrl = route.params.url ? route.params.url : "https://opml.radiotime.com/Browse.ashx?render=json";
      if (fetchUrl === "FAVORITES") {
        if (favouritesRadios) {
          setState(
            favouritesRadios
              .toJS()
              .filter((el) => el.radio)
              .map((el) => ({
                ...el.radio, // Spread the 'radio' object properties
                storeId: el.id, // Add the 'storeId' field
              }))
          );
        }
      } else if (fetchUrl === "APPLE_MUSIC") {
        getAppleMusicData(null);
      } else if (fetchUrl === "SPOTIFY") {
        getSpotifyMusicData(null);
      } else {
        fetch(fetchUrl + "&render=json")
          .then((response) => response.json())
          .then((jsonResponse) => {
            let fav = {
              element: "outline",
              type: "link",
              text: t("FAVOURITES"),
              URL: "FAVORITES",
              key: "FAVORITES",
            };
            let appleMusic = {
              element: "outline",
              type: "link",
              text: "Apple Music",
              URL: "APPLE_MUSIC",
              key: "APPLE_MUSIC",
            };
            let spotify = {
              element: "outline",
              type: "link",
              text: "Spotify",
              URL: "SPOTIFY",
              key: "SPOTIFY",
            };
            if (!route.params.url) {
              jsonResponse.body.unshift(fav);
              if (Platform.OS === "ios") jsonResponse.body.unshift(appleMusic);
              //jsonResponse.body.unshift(spotify);
            }
            setState(jsonResponse.body);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  }, []);

  return (
    <ScreenView>
      <ActionSheet
        actionRef={(o) => {
          setActionSheet(o);
        }}
        options={
          route.params.url === "FAVORITES"
            ? [t("STREAM_TO_OBJECT"), t("REMOVE_FROM_FAVOURITES"), t("CLOSE")]
            : [t("STREAM_TO_OBJECT"), t("ADD_TO_FAVOURITES"), t("CLOSE")]
        }
        cancelButtonIndex={2}
        onPress={(index) => handleOnPressAction(index)}
        onWebActionSheetClose={() => setWebActionSheetVisible(false)}
        showWebActionSheet={showWebActionSheet}
      />
      <MultiplePickerSelectObjects
        devicesInGroup={getRadios()}
        onValueChange={onDeviceStreamValueChange}
        modalVisible={modalVisible}
        disabled={false}
        hideText={true}
        closeModal={closeModal}
        placeholder={t("CHOOSE_RECEIVERS")}
        addText={t("STREAM")}
      />
      {route.params.url === "APPLE_MUSIC" || route.params.url === "SPOTIFY" ? (
        <Animated.View
          style={{
            width: "100%",
            paddingBottom: 5,
            paddingRight: 5,
            paddingLeft: 5,
          }}
        >
          <TouchableOpacity activeOpacity={1} onPress={() => null} style={styles.searchPlaceholder}>
            <View style={{ marginRight: 4 }}>
              <SearchIcon />
            </View>
            <TextInputStyled
              style={styles.searchPlaceholderText}
              placeholder={"Artists, songs or podcasts"}
              secureTextEntry={false}
              onChangeText={(value) => setSearchQuery(value, route.params.url)}
              autoCapitalize={"none"}
            />
          </TouchableOpacity>
        </Animated.View>
      ) : null}

      <FlatList
        data={state && state.length == 1 && !state[0].type && state[0].children ? state[0].children : state}
        style={{ flex: 0 }}
        nestedScrollEnabled={true}
        scrollEnabled={false}
        viewabilityConfig={{
          waitForInteraction: true,
          viewAreaCoveragePercentThreshold: 95,
        }}
        ListEmptyComponent={route.params.url === "FAVORITES" ? <NoDataScreen></NoDataScreen> : undefined}
        initialNumToRender={state && state.length == 1 && !state[0].type && state[0].children ? state[0].children.length : state ? state.length : 0}
        renderItem={({ item, index, separators }) => getItemView(item)}
        keyExtractor={(item, index) => index}
      />
    </ScreenView>
  );
};

const styles = StyleSheet.create({
  containerSearchBar: {
    paddingHorizontal: 12,
    backgroundColor: "#000000",
    paddingBottom: 16,
    paddingTop: 24,
  },
  searchPlaceholder: {
    alignItems: "center",
    backgroundColor: "#FFFFFF",
    borderRadius: 6,
    flexDirection: "row",
    paddingLeft: 16,
    paddingVertical: 16,
  },
  searchPlaceholderText: {
    fontSize: 16,
    color: "#000000",
    width: "80%",
  },
  sectionHeading: {
    fontSize: 18,
    color: "#FFFFFF",
    marginBottom: 24,
    marginLeft: 24,
    marginTop: 16,
  },
  containerRow: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginLeft: 24,
  },
  containerColumn: {
    width: "50%",
  },
  iconRight: {
    alignItems: "center",
    height: 28,
    justifyContent: "center",
    position: "absolute",
    right: 24,
    top: 40,
    width: 28,
  },
  containerHeader: {
    position: "absolute",
    top: 0,
    width: "100%",
    zIndex: 10,
  },
});

const mapStateToProps = (state) => {
  let currentProfile = state.profilesSettings.get("currentProfile");

  return {
    selectedSoruces: state.statesData.get("selectedSoruces"),
    detectedOutputs: state.statesData.get("detectedOutputs"),
    dimensions: state.statesData.get("dimensions"),
    favouritesRadios: state.profilesSettings.get(currentProfile).get("favouritesRadios"),
    devices: state.smartHomeData.get(state.profilesSettings.get("currentProfile")).get("devices"),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeExpoSound: (audio) => dispatch(changeExpoSound(audio)),
    changeSoundStatus: (status) => dispatch(changeSoundStatus(status)),
    addRadioToFavourites: (id, radio) => dispatch(addRadioToFavourites(id, radio)),
    removeRadioFromFavourites: (id) => dispatch(removeRadioFromFavourites(id)),
    changeSelectedSoruces: (status) => dispatch(changeSelectedSoruces(status)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AudioLibrary_AccountScreen);
