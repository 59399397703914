module.exports = function () {
	return `
<!DOCTYPE html>
<html>
  <head>
    <meta name="viewport" content="width=device-width, initial-scale=1, user-scalable=0" />
    <link rel="stylesheet" type="text/css" href="css/styles.css">
  </head>
  <body>
    <div id="container"></div>
    <script type="text/javascript" src="js/message.js"></script>
  </body>
</html>`;
};
