import React from "react";
import { View } from "react-native";

import WeatherComponent from "./weather/Weather";
import CalendarComponent from "./calendar/Calendar";

const CalendarWeatherRow = ({ calendarShown, weatherShown, dimensions }) => {
  let width = dimensions.get("width");
  let landscapeOrientation = dimensions.get("landscapeOrientation");

  if (width > 600) {
    landscapeOrientation = true;
  }
  return (
    <View
      style={{
        flexDirection:
          landscapeOrientation && weatherShown && calendarShown
            ? "row"
            : "column",
        width: "100%",
      }}
    >
      {calendarShown ? (
        <CalendarComponent middleWidth={landscapeOrientation && weatherShown} />
      ) : null}

      {weatherShown ? (
        <WeatherComponent middleWidth={landscapeOrientation && calendarShown} />
      ) : null}
    </View>
  );
};

export default CalendarWeatherRow;
