import React, { Component } from "react";
import { View, TouchableOpacity, ActivityIndicator, Text, Button } from "react-native";
import { connect } from "react-redux";
import { ThemeContext } from "../../../ThemeContext";
import { t } from "../../services/i18n";
import { ScreenView } from "../../components/UI/screenViewX";
import * as Haptics from "expo-haptics";
import {
  StyledIcon,
  PrimaryColorText,
  ComponentContainerTouchable,
  ScreenViewWithoutScroll,
  NoDataScreen,
  SwitchStyled,
  TextInputStyled,
  ButtonPrimary,
  TitleWithLines,
  ButtonSecondary2,
} from "../../components/UI/styledComponents";
import mqttClient from "../../services/mqtt";
import ActionSheet from "../../utils/ActionSheet";
import ModalSimple from "../../components/UI/ModalSimple";
import { SafeAreaView } from "react-native-safe-area-context";
import { Platform } from "react-native";
import { FontAwesome, MaterialCommunityIcons } from "@expo/vector-icons";

import { automatizationsReceived } from "../../store/actions";
import { List, Map } from "immutable";
import ModalWithContent from "../../components/UI/ModalWithContent";
import ButtonsSelector from "../../components/UI/ButtonsSelector";
import { SkottieLoader } from "../../components/animatedComponents/Loader";

class SingleAutomatization extends Component {
  static contextType = ThemeContext;
  state = {
    automatization: this.props.automatization,
    name: "",
    nameToChange: "",
    showWebActionSheet: false,
    restToChange: 0,
  };

  handleOnPressAction = (index, automatizationIndex) => {
    if (index == 0) {
      this.props.removeItem(automatizationIndex);
    } else if (index == 1) {
      this.props.renameItem(automatizationIndex);
    } else if (index == 2) {
      this.props.changeRestTime(automatizationIndex);
    }
    if (Platform.OS == "web") {
      this.setState({ showWebActionSheet: false });
    }
  };
  showActionSheet = () => {
    if (Platform.OS != "web") {
      this.ActionSheet.show();
    } else {
      this.setState({ showWebActionSheet: true });
    }
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.automatization) {
      this.setState({ automatization: nextProps.automatization });
    }
  }

  toggleActiveValue = (value) => {
    if (this.state.automatization) {
      let automatization = this.state.automatization.set("active", value ? 1 : 0);
      this.setState({ automatization }, () => {
        mqttClient.sendCondition(automatization, 2);
      });
    }
  };

  render() {
    const { automatizationIndex, navigation, showUser } = this.props;
    const { automatization } = this.state;
    const { theme } = this.context;
    return (
      <ComponentContainerTouchable
        containerStyle={{ width: "100%" }}
        style={{
          flexDirection: "column",
        }}
        onPress={() => {
          navigation.navigate("AutomatizationDetails", {
            title: automatization.get("name"),
            automatization: automatization,
            automatizations: this.props.automatizations,
          });
        }}
      >
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <View
            style={{
              alignItems: "center",
              flexDirection: "row",
              width: "70%",
            }}
          >
            <TouchableOpacity onPress={this.showActionSheet} style={{ paddingHorizontal: 10, marginLeft: -5 }}>
              <StyledIcon name={"more-vertical"} size={18} color={"secondary"} />
            </TouchableOpacity>
            <PrimaryColorText style={{ fontWeight: "bold" }}>
              {showUser && automatization.get("userName")
                ? automatization.get("name") + " (" + automatization.get("userName") + ")"
                : automatization.get("name")}
            </PrimaryColorText>
            {automatization.get("lunched") ? <MaterialCommunityIcons name={"check"} size={22} color={"rgb(50, 200, 80)"} /> : null}
          </View>
          <View
            style={{
              alignItems: "center",
              flexDirection: "row",
              width: 80,
            }}
          >
            {/* <TouchableOpacity style={{ paddingHorizontal: 6 }} onPress={() => mqttClient.runCondition(automatization.get("id"))}>
              <FontAwesome name="play-circle" color={"rgb(50, 200, 80)"} size={30} />
            </TouchableOpacity> */}
            <ButtonSecondary2
              style={{ minWidth: 80 }}
              onPress={() => {
                Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Light);
                mqttClient.runCondition(automatization.get("id"));
              }}
            >
              {t("TEST")}
            </ButtonSecondary2>

            {/* <TouchableOpacity
              style={{ paddingHorizontal: 6 }}
              onPress={() => mqttClient.stopCondition(automatization.get("id"))}
            >
              <FontAwesome
                name="stop-circle"
                color={"rgb(250, 85, 85)"}
                size={30}
              />
            </TouchableOpacity> */}
          </View>
        </View>
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            paddingLeft: 30,
            marginTop: 10,
            // flex: 1,
          }}
        >
          <PrimaryColorText style={{ paddingRight: 10 }}>{t("ENABLED")}:</PrimaryColorText>
          <SwitchStyled
            // style={{ marginHorizontal: 20 }}
            value={automatization.get("active") == 1 ? true : false}
            onValueChange={this.toggleActiveValue}
          />
        </View>
        <ActionSheet
          actionRef={(o) => (this.ActionSheet = o)}
          options={[t("conditions:REMOVE_AUTOMATIZATION"), t("conditions:RENAME_AUTOMATIZATION"), t("conditions:SET_REST_TIME"), t("CLOSE")]}
          cancelButtonIndex={3}
          onPress={(index) => this.handleOnPressAction(index, automatizationIndex)}
          onWebActionSheetClose={() => this.setState({ showWebActionSheet: false })}
          showWebActionSheet={this.state.showWebActionSheet}
        />
      </ComponentContainerTouchable>
    );
  }
}
class AutomatizationsScreenChild extends Component {
  static contextType = ThemeContext;

  constructor(props) {
    super(props);
    this.modes = [
      {
        label: t("FOR_ME"),
        value: 0,
      },
      {
        label: t("FOR_ALL"),
        value: 1,
      },
    ];
  }

  state = {
    automatizations: null,
    loading: true,
    addDialogVisible: false,
    newName: "",
    changeNameDialogVisible: false,
    changeRestTimeDialogVisible: false,
    clickedAutomatizationIndex: -1,
    restToChange: 0,
    selectedMode: this.props.route && this.props.route.key == "mine" ? 0 : 1,
  };

  setNavigationOptions = () => {
    const { scenes, navigation, permission, order, listOfScenes } = this.props;

    if (this.props.index === 0 && this.props.route.key === "mine") {
      navigation.setOptions({
        headerRight: () => (
          <TouchableOpacity style={{ padding: 10 }} onPress={() => this.showDialog()}>
            <StyledIcon name={"plus"} color={"tabBar"} size={24} />
          </TouchableOpacity>
        ),
      });
    } else if (this.props.index === 1 && this.props.route.key === "all") {
      navigation.setOptions({
        headerRight: () => (
          <TouchableOpacity style={{ padding: 10 }} onPress={() => this.showDialog()}>
            <StyledIcon name={"plus"} color={"tabBar"} size={24} />
          </TouchableOpacity>
        ),
      });
    }
  };

  componentDidMount() {
    this.setNavigationOptions();
  }

  componentDidUpdate(prevProps, prevState) {
    this.setNavigationOptions();
  }

  showDialog = () => {
    this.setState({ addDialogVisible: true });
  };
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.automatizations) {
      let automatizations = nextProps.automatizations;
      if (automatizations) {
        this.setState({
          loading: false,
          automatizations: automatizations,
        });
      }
    }
  }

  removeItem = (automatizationIndex) => {
    let { automatizations } = this.state;

    let automatizationToRemove = automatizations.get(automatizationIndex);
    if (automatizationToRemove) {
      automatizationToRemove = automatizationToRemove.set("active", 2);
      mqttClient.sendCondition(automatizationToRemove, 2);
      automatizations = automatizations.delete(automatizationIndex);
      this.setState({ automatizations });
    }
  };

  changeRestTime = (automatizationIndex) => {
    let { automatizations } = this.state;
    this.setState({
      changeRestTimeDialogVisible: true,
      clickedAutomatizationIndex: automatizationIndex,
      restToChange: automatizations.get(automatizationIndex).get("rest"),
    });
  };

  renameItem = (automatizationIndex) => {
    let { automatizations } = this.state;
    this.setState({
      changeNameDialogVisible: true,
      clickedAutomatizationIndex: automatizationIndex,
      nameToChange: automatizations.get(automatizationIndex).name,
    });
  };

  onRestChangeHanler = (value) => {
    this.setState({
      restToChange: value ? Number(value) : null,
    });
  };

  handleChangeRestOk = (key) => {
    let { automatizations } = this.state;
    if (this.state.clickedAutomatizationIndex >= 0) {
      this.setState({ changeRestTimeDialogVisible: false });
      let automatizationToChange = automatizations.get(this.state.clickedAutomatizationIndex);

      automatizationToChange = automatizationToChange.set("rest", this.state.restToChange);
      mqttClient.sendCondition(automatizationToChange, 2);
    }
  };

  handleChangeNameOK = (key) => {
    let { automatizations } = this.state;
    if (this.state.clickedAutomatizationIndex >= 0) {
      this.setState({ changeNameDialogVisible: false });
      let automatizationToRename = automatizations.get(this.state.clickedAutomatizationIndex);

      automatizationToRename = automatizationToRename.set("name", this.state.name);
      mqttClient.sendCondition(automatizationToRename, 2);
    }
  };

  handleAddDialogClose = () => {
    this.setState({
      addDialogVisible: false,
      newName: "",
    });
  };
  handleAddDialogOK = (forAll) => {
    let newAutomatization = Map({
      id: -1,
      active: 1,
      state: 0,
      type: 2,
      lunched: 0,
      name: this.state.newName,
      sub_conditions: List([]),
      actions: List([]),
      forAll: forAll,
    });
    let automatizations = null;
    if (this.state.automatizations) {
      automatizations = this.state.automatizations.push(newAutomatization);
    }

    this.setState(
      {
        addDialogVisible: false,
        automatizations,
      },
      () => {
        this.props.navigation.navigate("AutomatizationDetails", {
          title: this.state.newName,
          automatization: newAutomatization,
          automatizations: automatizations,
        });
      }
    );
  };

  onChangeNameHandler = (value) => {
    this.setState({
      newName: value,
    });
  };

  onNameChangeHandler = (name) => {
    this.setState({ name });
  };

  componentWillUnmount() {
    this.props.automatizationsReceived(null);
  }
  render() {
    const { theme } = this.context;
    const { automatizations, loading, addDialogVisible, changeNameDialogVisible, name, nameToChange, changeRestTimeDialogVisible, restToChange } =
      this.state;
    const { devices, navigation, serverInfo, permission } = this.props;
    let permissionForCreatingForAll = (permission & 0x40000) == 0;

    const activeAutomations = [];
    const inactiveAutomations = [];
    if (automatizations) {
      automatizations.forEach((automatization) => {
        if (automatization.get("active") == 1) {
          activeAutomations.push(automatization);
        } else {
          inactiveAutomations.push(automatization);
        }
      });
    }

    return (
      <View style={{ flex: 1 }}>
        {loading ? (
          <ScreenViewWithoutScroll
            style={{
              alignItems: "center",
            }}
          >
            <SkottieLoader style={{ width: 23, height: 23, margin: 3 }} />
          </ScreenViewWithoutScroll>
        ) : automatizations && automatizations.size ? (
          <ScreenView>
            {automatizations.map((automatization, automatizationIndex) => {
              return automatization.get("active") === 1 ? (
                <SingleAutomatization
                  key={`${automatization.get("name")}${automatization.get("id")}`}
                  automatization={automatization}
                  automatizationIndex={automatizationIndex}
                  removeItem={this.removeItem}
                  renameItem={this.renameItem}
                  changeRestTime={this.changeRestTime}
                  automatizations={automatizations}
                  navigation={navigation}
                  showUser={this.props.route && this.props.route.key == "all"}
                />
              ) : null;
            })}

            {automatizations.map((automatization, automatizationIndex) => {
              return automatization.get("active") === 0 ? (
                <SingleAutomatization
                  key={`${automatization.get("name")}${automatization.get("id")}`}
                  automatization={automatization}
                  automatizationIndex={automatizationIndex}
                  removeItem={this.removeItem}
                  renameItem={this.renameItem}
                  changeRestTime={this.changeRestTime}
                  automatizations={automatizations}
                  navigation={navigation}
                  showUser={this.props.route && this.props.route.key == "all"}
                />
              ) : null;
            })}
          </ScreenView>
        ) : (
          <NoDataScreen />
        )}
        {/* <ModalSimple
          isVisible={this.state.addDialogVisible}
          title={"conditions:AUTOMATIZATION_NAME"}
          placeholder={"conditions:ENTER_AUTOMATIZATION_NAME"}
          onInputChange={this.onChangeNameHandler}
          onClose={this.handleAddDialogClose}
          onOK={() => this.handleAddDialogOK(false)}
          thirdOptionText={t("conditions:ADD_FOR_ALL")}
          onThirdOption={() => this.handleAddDialogOK(true)}
          error={serverInfo && serverInfo.get("userId") == -1 ? t("NOT_AVAILABLE_IN_DEBUG_MODE") : null}
        /> */}

        <ModalWithContent
          isVisible={this.state.addDialogVisible}
          title={t("conditions:AUTOMATIZATION_NAME")}
          onClose={this.handleAddDialogClose}
          error={serverInfo && serverInfo.get("userId") == -1 ? t("NOT_AVAILABLE_IN_DEBUG_MODE") : null}
        >
          <View style={{ paddingBottom: 16 }}>
            <TextInputStyled
              placeholder={t("conditions:ENTER_AUTOMATIZATION_NAME")}
              value={this.state.newName}
              onChangeText={this.onChangeNameHandler}
            />
          </View>

          {permissionForCreatingForAll && (
            <View style={{ paddingBottom: 16 }}>
              <ButtonsSelector
                fontSize={14}
                onChangeTab={(value) => {
                  this.setState({ selectedMode: value });
                }}
                active={this.state.selectedMode}
                tabs={this.modes}
              />
            </View>
          )}
          <ButtonPrimary onPress={() => this.handleAddDialogOK(this.state.selectedMode == 1)}>{t("ADD")}</ButtonPrimary>
        </ModalWithContent>

        {changeNameDialogVisible && (
          <ModalSimple
            isVisible={changeNameDialogVisible}
            onClose={() => {
              this.setState({ changeNameDialogVisible: false });
            }}
            onOK={this.handleChangeNameOK}
            title={"conditions:AUTOMATIZATION_NAME"}
            placeholder={"conditions:ENTER_AUTOMATIZATION_NAME"}
            onInputChange={this.onNameChangeHandler}
            value={nameToChange}
          />
        )}

        {changeRestTimeDialogVisible && (
          <ModalSimple
            isVisible={changeRestTimeDialogVisible}
            onClose={() => {
              this.setState({ changeRestTimeDialogVisible: false });
            }}
            onOK={this.handleChangeRestOk}
            title={"conditions:AUTOMATIZATION_REST_TIME_IN_S"}
            placeholder={"conditions:ENTER_REST_TIME"}
            onInputChange={this.onRestChangeHanler}
            value={this.state.restToChange ? this.state.restToChange.toString() : undefined}
          />
        )}
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  let currentProfile = state.profilesSettings.get("currentProfile");
  return {
    devices: state.smartHomeData.get(currentProfile).get("devices"),

    serverInfo: state.statesData.get("server").get("serverInfo"),
    permission: state.statesData.get("server").get("serverInfo").get("perm"),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    automatizationsReceived: (automatizations) => dispatch(automatizationsReceived(automatizations)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AutomatizationsScreenChild);
