import React, { useState, useEffect } from "react";
import { Text, TouchableOpacity, Platform, View, StyleSheet } from "react-native";
import { connect } from "react-redux";

import { DevicesToShowByColumns } from "../../utils";
import { ScreenView } from "../../components/UI/screenViewX";
import { StyledIcon, NoDataScreen, TextInputStyled } from "../../components/UI/styledComponents";
import AddFavouritesModal from "./AddFavouritesModal";
import { addDeviceToFavourites, removeDeviceFromFavourites } from "../../store/actions";
import SearchIcon from "../../utils/svg/SearchIcon";
import { useTheme } from "../../../ThemeContext";
import { t } from "../../services/i18n";
const FavouritesScreen = ({ favouritesDevices, navigation, onAddDeviceToFavourites, devices }) => {
  const [dialogVisible, setDialogVisible] = useState(false);

  const [searchQuery, setSearchQuery] = useState("");

  const [filteredDevices, setFilteredDevices] = useState([]);

  const { theme } = useTheme();

  const textColor = theme.TEXT_COLOR_PRIMARY;
  const textColorSecondary = theme.TEXT_COLOR_SECONDARY;
  const inputBackgroundColor = theme.PLACEHOLDER_BACKGROUND_COLOR;
  const borderColor = theme.PLACEHOLDER_BACKGROUND_COLOR;
  const backgroundColor = theme.APP_BACKGROUND_COLOR;

  useEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <TouchableOpacity style={{ padding: 10 }} onPress={() => showDialog()}>
          <StyledIcon name={"plus"} color={"tabBar"} size={24} />
        </TouchableOpacity>
      ),
    });
  }, []);

  useEffect(() => {
    if (searchQuery.length > 0) {
      let filteredDevices = devices
        .filter((device) => device && device.get("opis_menu") && device.get("opis_menu").toLowerCase().includes(searchQuery.toLowerCase()))
        .map((device) => device.get("id"));
      setFilteredDevices(filteredDevices);
    } else {
      setFilteredDevices([]);
    }
  }, [searchQuery]);

  const showDialog = () => {
    setDialogVisible(true);
  };

  const onAdd = (selectedDevice) => {
    onAddDeviceToFavourites(selectedDevice);
    setDialogVisible(false);
  };

  if (searchQuery.length > 0) {
    favouritesDevices = filteredDevices;
  }

  let daneDoWyswitelenie = favouritesDevices ? <DevicesToShowByColumns arrayOfIds={favouritesDevices} devices={devices} source="fav" /> : null;

  return (
    <View style={{ flex: 1 }}>
      <View onPress={() => null} style={[styles.searchPlaceholder, { backgroundColor: backgroundColor }]}>
        <View style={{ marginRight: 4 }}>
          <SearchIcon fill={textColor} />
        </View>

        <TextInputStyled
          style={{
            fontSize: 16,
            width: "80%",
            backgroundColor: inputBackgroundColor,
            borderRadius: 5,
            height: 30,
          }}
          placeholder={t("NAME_OF_OBJECT")}
          secureTextEntry={false}
          value={searchQuery}
          onChangeText={(value) => setSearchQuery(value)}
          autoCapitalize={"none"}
        />
      </View>

      {favouritesDevices && favouritesDevices.size ? <ScreenView>{daneDoWyswitelenie}</ScreenView> : <NoDataScreen />}

      {dialogVisible && (
        <AddFavouritesModal
          isVisible={dialogVisible}
          onClose={() => {
            setDialogVisible(false);
          }}
          onAdd={onAdd}
        />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  containerSearchBar: {
    paddingHorizontal: 12,
    paddingBottom: 16,
    paddingTop: 16,
  },
  searchPlaceholder: {
    alignItems: "center",
    flexDirection: "row",
    paddingLeft: 16,
    paddingVertical: 16,
  },
  searchPlaceholderText: {
    fontSize: 16,
    width: "80%",
  },
  sectionHeading: {
    fontSize: 18,
    color: "#FFFFFF",
    marginBottom: 24,
    marginLeft: 24,
    marginTop: 16,
  },
  containerRow: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginLeft: 24,
  },
  containerColumn: {
    width: "50%",
  },
  iconRight: {
    alignItems: "center",
    height: 28,
    justifyContent: "center",
    position: "absolute",
    right: 24,
    top: 40,
    width: 28,
  },
  containerHeader: {
    position: "absolute",
    top: 0,
    width: "100%",
    zIndex: 10,
  },
});

const mapStateToProps = (state) => {
  let currentProfile = state.profilesSettings.get("currentProfile");
  return {
    devices: state.smartHomeData.get(currentProfile).get("devices"),
    favouritesDevices: state.profilesSettings.get(currentProfile).get("favouritesDevices"),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAddDeviceToFavourites: (deviceID) => dispatch(addDeviceToFavourites(deviceID)),
    onRemoveDeviceFromFavourites: (deviceID) => dispatch(removeDeviceFromFavourites(deviceID)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FavouritesScreen);
