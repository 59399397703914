import React, { Component } from "react";
import { Text, Image, View, TouchableOpacity, ActivityIndicator, Platform } from "react-native";
import ActionSheet from "../../utils/ActionSheet";

import { irDataReceived } from "../../store/actions";
import { FontAwesome } from "@expo/vector-icons";

import * as DocumentPicker from "expo-document-picker";
import * as FileSystem from "expo-file-system";
import * as Sharing from "expo-sharing";
import ModalWithContent from "../../components/UI/ModalWithContent";
import ModalSimple from "../../components/UI/ModalSimple";
import { ScreenView } from "../../components/UI/screenViewX";
import { StyledIcon, PrimaryColorText, ScreenViewWithoutScroll, ComponentContainer, ButtonPrimary } from "../../components/UI/styledComponents";

import { t } from "../../services/i18n";
import { connect } from "react-redux";

import mqttClient from "../../services/mqtt";
import { SkottieLoader } from "../../components/animatedComponents/Loader";
import { store } from "../../store/configureStore";

class IRScreen extends Component {
  state = {
    isLoading: true,
    isVisible: false,
    nameDialogVisible: false,
    ir_status: null,
    name: "",
    indexOfOpenActionSheet: -1,
    irData: {},
    showWebActionSheet: false,
  };

  componentDidMount() {
    const { deviceState, navigation, device } = this.props;
    const { irData, isVisible, ir_status, name, nameDialogVisible } = this.state;
    this.onSendMqttMessage("getIRs");
    if (deviceState && deviceState.get("irData")) {
      let irData = deviceState.get("irData").toJS();

      this.setState({
        isLoading: false,
        irData,
      });
    }

    navigation.setOptions({
      headerLeft: () => (
        <TouchableOpacity style={{ padding: 10 }} onPress={() => navigation.goBack(null)}>
          <StyledIcon name={"x"} color={"tabBar"} size={24} />
        </TouchableOpacity>
      ),
      headerRight: () => (
        <View
          style={{
            flexDirection: "row",
          }}
        >
          <TouchableOpacity
            style={{ paddingTop: 10, paddingRight: 10 }}
            onPress={async () => {
              const { device } = this.props.route.params;
              try {
                let result;
                if (Platform.OS === "ios") {
                  result = await DocumentPicker.getDocumentAsync();
                } else {
                  result = await DocumentPicker.getDocumentAsync({
                    copyToCacheDirectory: false,
                  });
                }

                if (result && result.assets && result.assets.length > 0 && result.assets[0].uri) {
                  let profilesData;
                  if (Platform.OS === "web") {
                    let blob = await urlToBlob(result.assets[0].uri);
                    profilesData = await readAsTextAsync(blob);
                  } else if (Platform.OS === "ios") {
                    profilesData = await FileSystem.readAsStringAsync(result.assets[0].uri);
                  } else {
                    let result2 = await FileSystem.copyAsync({
                      from: result.assets[0].uri,
                      to: FileSystem.documentDirectory + result.assets[0].name,
                    });
                    profilesData = await FileSystem.readAsStringAsync(FileSystem.documentDirectory + result.assets[0].name);
                  }
                  let profilesJson = JSON.parse(profilesData);

                  this.setState({ irData: profilesJson });
                  mqttClient.saveIR(profilesJson, device.get("id"));
                }
              } catch (ex) {
                console.log(ex);
                alert(ex.message);
              }
            }}
          >
            <StyledIcon name={"download"} color={"tabBar"} size={24} />
          </TouchableOpacity>
          <TouchableOpacity
            style={{ padding: 10 }}
            onPress={async () => {
              const { device } = this.props.route.params;
              const devState = store.getState().statesData.get("states").get(device.get("id"));
              const data = devState ? devState.get("irData").toJS() : null;
              try {
                if (Platform.OS === "web") {
                  const bytes = new TextEncoder().encode(JSON.stringify(this.state.accessObjects));
                  const blob = new Blob([bytes], {
                    type: "application/json;charset=utf-8",
                  });
                  let link = document.createElement("a");
                  link.href = URL.createObjectURL(blob);
                  link.download = "profiles.json";
                  document.body.appendChild(link);
                  link.dispatchEvent(
                    new MouseEvent("click", {
                      bubbles: true,
                      cancelable: true,
                      view: window,
                    })
                  );
                  link.remove();
                  URL.revokeObjectURL(link.href);
                } else {
                  await FileSystem.writeAsStringAsync(FileSystem.cacheDirectory + device.get("id") + "_irs.json", JSON.stringify(data));
                  Sharing.shareAsync(FileSystem.cacheDirectory + device.get("id") + "_irs.json");
                }
              } catch (ex) {
                console.log(ex);
                alert(ex.message);
              }
            }}
          >
            <StyledIcon name={"upload"} color={"tabBar"} size={24} />
          </TouchableOpacity>
        </View>
      ),
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.deviceState) {
      if (nextProps.deviceState.get("irData")) {
        let irData = nextProps.deviceState.get("irData");
        this.setState({
          isLoading: false,
          irData: irData.toJS(),
        });
      }
      if (nextProps.deviceState.get("ir_status")) {
        this.setState({
          ir_status: nextProps.deviceState.get("ir_status").toJS(),
        });
      }
    }
  }

  onSendMqttMessage = (command) => {
    const { device } = this.props.route.params;

    let message = `/api/json/device/${device.get("id")}/${command}`;

    mqttClient.irQuiries(message);
  };

  serachIR = () => {
    this.setState({ ir_status: null }, () => {
      this.onSendMqttMessage("irLearn");
    });
  };

  onShowModal = () => {
    this.setState({ isVisible: true }, () => {
      this.onSendMqttMessage("irLearn");
    });
  };

  onCloseModal = () => {
    this.setState({ isVisible: false, ir_status: null });
  };

  onNameChangeHandler = (name) => {
    this.setState({ name });
  };

  handleNameDialogOK = () => {
    const { device } = this.props.route.params;

    let irData = Object.assign({}, this.state.irData);

    irData.irs[this.state.indexOfOpenActionSheet].name = this.state.name;

    this.setState({ nameDialogVisible: false, irData }, () => {
      this.props.irDataReceived(device.get("id"), this.state.irData);

      mqttClient.saveIR(irData, device.get("id"));
    });
  };

  handleNameDialogClose = () => {
    this.setState({ nameDialogVisible: false });
  };

  onSave = () => {
    let irData = Object.assign({}, this.state.irData);
    let num = 0;
    if (irData.irs.length > 0) {
      num = irData.irs[irData.irs.length - 1].num + 1;
    }
    irData.irs.push({ name: t("ir:NO_NAME"), num, data: "" });

    this.setState({ irData, isVisible: false, ir_status: null }, () => {
      this.onSendMqttMessage(`irSave`);
    });
  };

  showActionSheet = (index) => {
    this.setState(
      {
        indexOfOpenActionSheet: index,
        name: this.state.irData.irs[index].name,
      },
      () => {
        if (Platform.OS != "web") {
          this.ActionSheet.show();
        } else {
          this.setState({ showWebActionSheet: true });
        }
      }
    );
  };

  doAction = (index, num) => {
    if (index == 0) {
      this.setState({ nameDialogVisible: true });
    } else if (index == 1) {
      const { device } = this.props.route.params;

      let irData = Object.assign({}, this.state.irData);

      irData.irs.splice(this.state.indexOfOpenActionSheet, 1);

      this.setState({ irData }, () => {
        this.props.irDataReceived(device.get("id"), irData);
        mqttClient.saveIR(irData, device.get("id"));
      });
    }
  };

  handleOnPressAction = (index, num) => {
    if (Platform.OS == "web") {
      this.setState({ showWebActionSheet: false }, () => {
        this.doAction(index, num);
      });
    } else {
      this.doAction(index, num);
    }
  };

  render() {
    const { route, params_device } = this.props;
    let readOnly = params_device && (params_device.get("params") & 0x40) > 0 ? true : false;

    const { irData, isVisible, ir_status, name, nameDialogVisible } = this.state;
    let list;
    if (irData && irData.irs) {
      list = irData.irs.map((el, key) => {
        return (
          <ComponentContainer
            key={el.num}
            containerStyle={{ width: "100%" }}
            style={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              {!readOnly && (
                <TouchableOpacity onPress={() => this.showActionSheet(key)}>
                  <StyledIcon color="secondary" size={20} name="more-vertical" />
                </TouchableOpacity>
              )}

              <PrimaryColorText>
                {el.name} - {el.num}
              </PrimaryColorText>
            </View>

            <TouchableOpacity onPress={() => this.onSendMqttMessage(`irRun/${el.num}`)}>
              <FontAwesome name="play-circle" color={"rgb(50, 200, 80)"} size={30} />
            </TouchableOpacity>
          </ComponentContainer>
        );
      });
    }

    let modalContent = (
      <View style={{ paddingVertical: 10 }}>
        <SkottieLoader style={{ width: 40, height: 40, margin: 5 }} />

        <PrimaryColorText style={{ fontStyle: "italic", paddingTop: 10 }}>{t("ir:NEW_INSTRUCTIONS")}</PrimaryColorText>
        <PrimaryColorText style={{ paddingTop: 10 }}>
          {t("ir:FRAME_LENGTH")}: {ir_status ? ir_status.size : "..."}
        </PrimaryColorText>
      </View>
    );
    if (ir_status != null) {
      if (ir_status.status == 0) {
        //finished search
        if (ir_status.size != 0) {
          //found
          modalContent = (
            <View>
              <PrimaryColorText>{t("ir:FOUND")}</PrimaryColorText>
              <PrimaryColorText>
                {t("ir:FRAME_LENGTH")}: {ir_status.size}
              </PrimaryColorText>
              <View>
                <ButtonPrimary onPress={this.serachIR} style={{ padding: 10, margin: 10 }}>
                  {t("ir:SEARCH_AGAIN")}
                </ButtonPrimary>
                <ButtonPrimary onPress={() => this.onSendMqttMessage(`irTest`)} style={{ padding: 10, margin: 10 }}>
                  {t("ir:TEST")}
                </ButtonPrimary>

                <ButtonPrimary onPress={this.onSave} style={{ padding: 10, margin: 10 }}>
                  {t("ir:SAVE")}
                </ButtonPrimary>
              </View>
            </View>
          );
        } else {
          modalContent = (
            <View>
              <PrimaryColorText>
                {t("ir:FRAME_LENGTH")} {ir_status.size}
              </PrimaryColorText>
              <PrimaryColorText style={{ paddingVertical: 10, fontWeight: "bold" }}>{t("ir:NOT_FOUND")}</PrimaryColorText>
              <View>
                <ButtonPrimary onPress={this.serachIR} style={{ padding: 10, margin: 10 }}>
                  {t("ir:SEARCH_AGAIN")}
                </ButtonPrimary>
              </View>
            </View>
          );
        }
      }
    }

    return this.state.isLoading ? (
      <ScreenViewWithoutScroll
        style={{
          alignItems: "center",
        }}
      >
        <SkottieLoader style={{ width: 40, height: 40, margin: 5 }} />
      </ScreenViewWithoutScroll>
    ) : (
      <ScreenView>
        {!readOnly && (
          <View style={{ width: "100%" }}>
            <ButtonPrimary onPress={this.onShowModal} style={{ padding: 10, margin: 10 }}>
              {t("ir:ADD_NEW")}
            </ButtonPrimary>
          </View>
        )}
        {list}

        <ModalWithContent isVisible={isVisible} title={t("ir:ADD_NEW")} onClose={this.onCloseModal}>
          {modalContent}
        </ModalWithContent>
        <ModalSimple
          isVisible={nameDialogVisible}
          title={"ir:ENTER_CODE_NAME"}
          placeholder={"ir:CODE_NAME"}
          onInputChange={this.onNameChangeHandler}
          value={name}
          onClose={this.handleNameDialogClose}
          onOK={this.handleNameDialogOK}
        />
        <ActionSheet
          actionRef={(o) => (this.ActionSheet = o)}
          options={[t("ir:EDIT_NAME"), t("ir:REMOVE"), t("CLOSE")]}
          cancelButtonIndex={2}
          onPress={(index) => {
            this.handleOnPressAction(index);
          }}
          onWebActionSheetClose={() => this.setState({ showWebActionSheet: false })}
          showWebActionSheet={this.state.showWebActionSheet}
        />
      </ScreenView>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    deviceState: state.statesData.get("states").get(ownProps.route.params.device.get("id")),
    params_device: state.smartHomeData
      .get(state.profilesSettings.get("currentProfile"))
      .get("params_devices")
      .get(ownProps.route.params.device.get("id")),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    irDataReceived: (deviceID, irData) => dispatch(irDataReceived(deviceID, irData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(IRScreen);
