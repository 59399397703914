import { Rect, RoundedRect } from "@shopify/react-native-skia";
import { CANVAS_HEIGHT, CANVAS_MIDDLE } from "../utils/constants";
import { useTheme } from "../../../../../ThemeContext";

export const Outline = () => {
  const { theme } = useTheme();

  return (
    <>
      <RoundedRect x={CANVAS_MIDDLE - 115} y={10} width={8} height={CANVAS_HEIGHT} r={10} color={theme.APP_BACKGROUND_COLOR} />
      <RoundedRect x={CANVAS_MIDDLE + 105} y={10} width={8} height={CANVAS_HEIGHT} r={10} color={theme.APP_BACKGROUND_COLOR} />
      <Rect x={85} y={CANVAS_HEIGHT - 8} width={225} height={8} color={theme.APP_BACKGROUND_COLOR} />
    </>
  );
};
