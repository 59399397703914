import React, { Component, useContext, useState, useEffect, memo } from "react";

import { View, Text } from "react-native";
import moment from "moment";
import { t } from "../services/i18n";
import * as Localization from "expo-localization";
//ERROR IN WEB FROM BELOW
//WEB to zakomentuj
//import HighchartsReactNative from "@raw2dd4/highcharts-react-native";
//WEB to odkomentuj
import HighchartsReactNative from "../../assets/HighchartsReactNative";
import { connect } from "react-redux";
import { useTheme } from "../../ThemeContext";
import Long from "long";
import mqttClient from "../services/mqtt";
import { store } from "../store/configureStore";
import { barChartDataReceived } from "../store/actions";
import { SkottieLoader } from "../components/animatedComponents/Loader";
import { colorConsumed, colorExported, colorImported, colorProduced } from "../components/smartHomeComponents/energy/TriangleComponent";

const HighChartBarPositiveAndNegative = memo(({ dimensionsProps, exportPower, importPower, productionPower, params, dateFormat, dimensions }) => {
  const { theme } = useTheme();

  const [chartOptionsState, setChartOptions] = useState({
    chart: {
      type: "column",
      backgroundColor: theme.COMPONENT_BACKGROUND_COLOR,
    },
    title: {
      text: null,
    },

    yAxis: {
      title: {
        text: "kWh",
        align: "high",
        rotation: 0,
        offset: 10,
        y: -15,
      },
    },
    plotOptions: {
      column: {
        stacking: "normal",
        borderColor: "transparent",
      },
    },
    tooltip: {
      pointFormat: "<b>{point.y}kWh</b>",
      valueDecimals: 1,
      xDateFormat: "%Y-%m-%d",
    },
    xAxis: {
      type: "datetime",
    },
    credits: {
      enabled: false,
    },

    series: [],
  });

  const chooseMomentLocale = (locale) => {
    // make the locale lower case
    // will fix crashes caused by "en-GB" (instead of "en-gb") not being found
    locale = locale.toLowerCase();
    if (moment.locales().includes(locale)) {
      // check if the locale is included in the array returned by `locales()` which (in this case) tells us which locales moment will support
      return locale;
    } else if (moment.locales().includes(locale.substring(0, 2))) {
      // check if the first two letters of the locale are included in the array returned by `locales()` which (in this case) tells us which locales moment will support
      // will fixes crashes caused by "en-US" not being found, as we'll tell moment to load "en" instead
      return locale.substring(0, 2);
    }
    // use "en-gb" (the default language and locale for my app) as a fallback if we can't find any other locale
    return "en-gb";
  };

  moment.locale(chooseMomentLocale(Localization.locale));

  useEffect(() => {
    if (exportPower && exportPower.get("data") && importPower && importPower.get("data") && productionPower && productionPower.get("data")) {
      let chartOptions = Object.assign({}, chartOptionsState); //creating copy of object in state
      chartOptions.series = [];
      chartOptions.tooltip.xDateFormat = dateFormat;
      let serie = { name: t("IMPORTED"), data: [], color: colorImported };
      let serie4 = { name: t("CONSUMED"), data: [], color: colorConsumed };

      importPower.get("data").map((el, index) => {
        serie.data.push({
          x: el.get("x") * 1000,
          y: el.get("y"),
        });

        let consumption = el.get("y") * -1;

        if (productionPower && productionPower.get("data").get(index) && productionPower.get("data").get(index).get("y")) {
          consumption -= productionPower.get("data").get(index).get("y");
        }

        if (exportPower && exportPower.get("data").get(index) && exportPower.get("data").get(index).get("y")) {
          consumption += exportPower.get("data").get(index).get("y");
        }

        serie4.data.push({
          x: el.get("x") * 1000,
          y: consumption,
        });
      });

      let serie2 = { name: t("EXPORTED"), data: [], color: colorExported };

      exportPower.get("data").map((el) => {
        serie2.data.push({
          x: el.get("x") * 1000,
          y: el.get("y") * -1,
        });
      });

      let serie3 = { name: t("PRODUCED"), data: [], color: colorProduced };

      productionPower.get("data").map((el) => {
        serie3.data.push({
          x: el.get("x") * 1000,
          y: el.get("y"),
        });
      });
      chartOptions.series.push(serie);
      chartOptions.series.push(serie2);
      chartOptions.series.push(serie3);
      chartOptions.series.push(serie4);
      setChartOptions(chartOptions);
    }
  }, [exportPower, importPower, productionPower]);

  return exportPower && importPower && productionPower ? (
    <HighchartsReactNative
      styles={{
        width: "100%",
        height: 300,
        opacity: 0.99,
      }}
      // THIS IS NEEDED FOR WEB
      viewStyle={{
        width: "100%",
        height: dimensions.height,
      }}
      webviewStyles={{
        backgroundColor: "transparent",
        opacity: 0.99,
      }}
      options={chartOptionsState}
      setOptions={{
        lang: {
          months: moment.months(),
          weekdays: moment.weekdays(),
          shortMonths: moment.monthsShort(),
        },
      }}
    />
  ) : (
    <SkottieLoader style={{ width: 40, height: 40 }} />
  );
}, areEqual);

function areEqual(prevProps, nextProps) {
  return true;
}

const mapStateToProps = (state, props) => {
  return {
    tzOffset: state.statesData.get("server").get("serverInfo").get("tzOffset"),
    dimensionsProps: state.statesData.get("dimensions"),
  };
};

export default connect(mapStateToProps)(HighChartBarPositiveAndNegative);
