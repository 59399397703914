import "expo-dev-client";

import { registerRootComponent } from "expo";

import App from "./App";
import { LoadSkiaWeb } from "@shopify/react-native-skia/lib/module/web";
// registerRootComponent calls AppRegistry.registerComponent('main', () => App);
// It also ensures that whether you load the app in Expo Go or in a native build,
// the environment is set up appropriately

registerRootComponent(App);
