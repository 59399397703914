import React, { Component } from "react";
import { View, TouchableOpacity, Text, Platform, ActivityIndicator } from "react-native";
import { connect } from "react-redux";
import { t } from "../../../services/i18n";

import { PrimaryColorText, ScreenViewWithoutScroll, NoDataScreen, SecondaryColorText } from "../../../components/UI/styledComponents";
import { ScreenView } from "../../../components/UI/screenViewX";

import mqttClient from "../../../services/mqtt";

class Diagnostic_AccountScreen extends Component {
  state = {
    isLoading: true,
    mqttUpdateStatus: `${t("UPDATE_AVAILABLE")}. ${t("account:CLICK_TO_START")}`,
  };

  handleUpdateInfo = (mqttUpdateInfo) => {
    let message = "";
    switch (mqttUpdateInfo) {
      case "START":
        message = t("account:START");
        break;
      case "UPDATE_OK":
        message = t("account:UPDATE_OK");
        break;
      case "ERROR_RENAME":
        message = t("account:ERROR_RENAME");
        break;
      case "INVALID_MD5":
        message = t("account:INVALID_MD5");
        break;
      case "NO_UPDATE":
        message = t("account:NO_UPDATE");
        break;
      default:
        message = `${mqttUpdateInfo}%`;
    }
    this.setState({
      mqttUpdateStatus: message,
    });
  };

  componentDidMount() {
    mqttClient.getDiagnostic();
  }

  handleReboot = () => {
    mqttClient.askForReboot();
  };

  handleMQTTUpdate = () => {
    mqttClient.askForServerUpdate("mqtt");
    this.setState({ mqttUpdateStatus: t("account:WAITING") });
  };

  handleMQTTUpdateTest = () => {
    mqttClient.askForServerUpdate("mqtt_test");
    this.setState({ mqttUpdateStatus: t("account:WAITING") });
  };

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    if (nextProps.server.get("mqttUpdateInfo") && nextProps.server.get("mqttUpdateInfo") != this.state.mqttUpdateStatus) {
      this.handleUpdateInfo(nextProps.server.get("mqttUpdateInfo"));
    }

    if (nextProps.diagnosticStats) {
      this.setState({ isLoading: false });
    }
  };

  render() {
    const { diagnosticStats, server } = this.props;
    const { mqttUpdateStatus, isLoading } = this.state;

    let mqttUpdateAvailable = false;

    const serverInfo = server.get("serverInfo");
    const permissions = serverInfo.get("perm");
    const mqttVersion = serverInfo.get("mqttVersion");

    if (serverInfo.get("update") && (serverInfo.get("update") & 0x02) > 0) {
      mqttUpdateAvailable = true;
    }
    return (
      <ScreenView>
        {diagnosticStats && <PrimaryColorText style={{ width: "100%" }}>{`${JSON.stringify(diagnosticStats, undefined, 2)}`}</PrimaryColorText>}

        {
          <TouchableOpacity onLongPress={this.handleMQTTUpdateTest} style={{ width: "100%" }}>
            <View style={{ paddingTop: 20 }}>
              <PrimaryColorText>{"MQTT: " + mqttVersion}</PrimaryColorText>
            </View>
          </TouchableOpacity>
        }
        <TouchableOpacity onPress={this.handleMQTTUpdate} style={{ width: "100%" }}>
          <PrimaryColorText
            style={{
              fontStyle: "italic",
            }}
          >
            {mqttUpdateAvailable ? mqttUpdateStatus : t("account:EVERYTHING_UP_TO_DATE")}
          </PrimaryColorText>
        </TouchableOpacity>

        <TouchableOpacity onLongPress={this.handleReboot} style={{ width: "100%" }}>
          <PrimaryColorText
            style={{
              fontStyle: "italic",
            }}
          >
            {"Permissions: " + permissions}
          </PrimaryColorText>
        </TouchableOpacity>
      </ScreenView>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    server: state.statesData.get("server"),
    diagnosticStats: state.statesData.get("diagnosticStats"),
  };
};
export default connect(mapStateToProps, null)(Diagnostic_AccountScreen);
