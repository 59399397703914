import React, { Component, useContext, useState, useEffect, useRef } from "react";

import { View, Text } from "react-native";
import moment from "moment";
import { t } from "../services/i18n";
import * as Localization from "expo-localization";

//ERROR IN WEB FROM BELOW
//WEB to zakomentuj
//import HighchartsReactNative from "@raw2dd4/highcharts-react-native";
//WEB to odkomentuj
import HighchartsReactNative from "../../assets/HighchartsReactNative";
import { connect } from "react-redux";
import { useTheme } from "../../ThemeContext";
import Long from "long";

let stepObjects = [
  "flaga",
  "flaga_l",
  "flaga_p",
  "przekaznik",
  "satel_wyj",
  "alarm",
  "satel",
  "satel_alarm",
  "satel_wej",
  "wej",
  "wej_d",
  "wej_g",
  "motion",
  "presence",
  "roleta",
  "roleta_procenty",
  "roleta_lamelki",
  "reg",
  "flaga_liniowa",
  "flaga_liniowa16",
];

const HighChartView = ({
  deviceName,
  params_device,
  chartData,
  options,
  deviceType,
  additionalDevice,
  timeRange,
  tzOffset,
  dimensions,
  dimensionsProps,
  deviceState,
}) => {
  const { theme } = useTheme();
  const landscapeOrientation = dimensionsProps.get("landscapeOrientation");
  const width = dimensionsProps.get("width");
  const height = dimensionsProps.get("height");

  const [chartOptionsState, setChartOptions] = useState({
    chart: {
      zoomType: stepObjects.includes(deviceType) ? "x" : "xy",
      backgroundColor: theme.COMPONENT_BACKGROUND_COLOR,
      height: dimensions ? dimensions.height - 35 : landscapeOrientation ? height - 200 : width,
    },
    title: {
      text: deviceName ? `${deviceName}` : null,
      style: {
        color: theme.TEXT_COLOR_PRIMARY,
      },
    },
    legend: {
      itemStyle: { fontSize: 12 },
      itemDistance: 10,
    },
    credits: {
      enabled: false,
    },
    tooltip: {
      valueDecimals: 2,
    },
    xAxis: {
      type: "datetime",
      lineColor: theme.TEXT_COLOR_PRIMARY,
      labels: {
        style: {
          color: theme.TEXT_COLOR_PRIMARY,
        },
      },
    },
    accessibility: {
      enabled: false,
    },
    yAxis: [
      {
        title: {
          text: null,
        },
        lineColor: theme.TEXT_COLOR_PRIMARY,
        lineWidth: 1,
        gridLineColor: theme.SLIDER_CIRCLE_BORDER,
        gridLineWidth: 0.5,
        gridLineDashStyle: "Dash",
        labels: {
          style: {
            color: theme.TEXT_COLOR_PRIMARY,
          },
        },
      },
      {
        title: {
          text: null,
        },
        lineColor: theme.TEXT_COLOR_PRIMARY,
        lineWidth: 1,
        gridLineColor: theme.SLIDER_CIRCLE_BORDER,
        gridLineWidth: 0.5,
        gridLineDashStyle: "Dash",
        opposite: true,
        labels: {
          style: {
            color: theme.TEXT_COLOR_PRIMARY,
          },
        },
      },
    ],
    series: [],
  });

  const MIN_UPDATE_INTERVAL = 60 * 1000; // 1 minute in milliseconds
  const lastUpdateTimeRef = useRef(0); // Track the last update time

  useEffect(() => {
    if (deviceState && deviceState.get("on") && chartOptionsState.live && chartOptionsState.series && chartOptionsState.series[0]) {
      const lastTime = chartOptionsState.series[0].data[chartOptionsState.series[0].data.length - 1][0] / 1000;
      const lastValue = chartOptionsState.series[0].data[chartOptionsState.series[0].data.length - 1][1] / 1000;

      let offsetSec = tzOffset ? Number(tzOffset) : moment().utcOffset();
      offsetSec = offsetSec * 60 * 1000;
      const currentDatTime = Number(new Date().getTime()) + offsetSec;

      const now = new Date().getTime();

      // Check if one minute has passed since the last update
      if (now - lastUpdateTimeRef.current >= MIN_UPDATE_INTERVAL) {
        if (lastTime !== currentDatTime && lastValue !== Number(deviceState.get("state"))) {
          chartOptionsState.series[0].data.push([currentDatTime, Number(deviceState.get("state"))]);
          setChartOptions({ ...chartOptionsState });

          // Update the last update time
          lastUpdateTimeRef.current = now;
        }
      }
    }
  }, [deviceState]);

  const chooseMomentLocale = (locale) => {
    // make the locale lower case
    // will fix crashes caused by "en-GB" (instead of "en-gb") not being found
    locale = locale.toLowerCase();
    if (moment.locales().includes(locale)) {
      // check if the locale is included in the array returned by `locales()` which (in this case) tells us which locales moment will support
      return locale;
    } else if (moment.locales().includes(locale.substring(0, 2))) {
      // check if the first two letters of the locale are included in the array returned by `locales()` which (in this case) tells us which locales moment will support
      // will fixes crashes caused by "en-US" not being found, as we'll tell moment to load "en" instead
      return locale.substring(0, 2);
    }
    // use "en-gb" (the default language and locale for my app) as a fallback if we can't find any other locale
    return "en-gb";
  };

  const getBorderValues = (offsetSec) => {
    let currentTime = Number(moment().format("X"));

    let currentMidnight = Math.floor(currentTime / 24 / 60 / 60) * 24 * 60 * 60;
    currentTime += offsetSec;

    let x1 = currentMidnight;
    let x2 = currentTime;
    switch (timeRange) {
      case 0:
        break;
      case 1:
        x1 = currentMidnight - 24 * 60 * 60;
        x2 = currentMidnight;
        break;
      case 2:
        x1 = currentMidnight - 7 * 24 * 60 * 60;
        break;
      case 3:
        x1 = currentMidnight - 30 * 24 * 60 * 60;
        break;
      case 4:
        x1 = currentMidnight - 90 * 24 * 60 * 60;
        break;
      case 5:
        x1 = currentMidnight - 365 * 24 * 60 * 60;
        break;
      case 6:
        x1 = Number(options.from) + offsetSec;
        x2 = Number(options.to) + offsetSec;
        break;
      case 8:
        x1 = currentTime - 24 * 60 * 60;
        break;
      case 9:
        x1 = currentTime - 1 * 60 * 60;
        break;
      default:
        break;
    }
    return [x1, x2, x2 === currentTime];
  };
  useEffect(() => {
    //normalnie dane mają List1 i potem dodatkowe urządzenie ma List2
    // w reg jest List1 i List2 jako dodatkowe temp zadana i mierzona
    if (chartData && chartData.get("List") && chartData.get("List").size) {
      let mainList = chartData.get("List");
      let mainList2 = chartData.get("List2");
      let newData = [];
      let newDataMin = [];
      let newDataMax = [];
      let newDataAdditional = [];
      let forceStep = params_device ? (params_device.get("params") & 0x20000000) > 0 : false;
      // additional
      let offsetSec = tzOffset ? Number(tzOffset) : moment().utcOffset();
      offsetSec = offsetSec * 60;

      const [x1, x2, live] = getBorderValues(offsetSec);

      const params =
        params_device && params_device.get("params") ? Long.fromString("0x" + params_device.get("params").toString(16), 16) : new Long(0);

      let lastValue = null;
      mainList.map((el, index) => {
        let myY = Number(el.get("y"));
        let myX = Number(el.get("x")) * 1000;
        for (let i = index - 1; i >= 0; i--) {
          if (mainList.get(i) && mainList.get(i).get("x") === el.get("x")) {
            myX++;
          } else {
            break;
          }
        }
        if (el.get("x") == 0) {
          myX = x1 * 1000;
        }

        if (!params.and(Long.fromString("0x100000000", 16)).isZero()) {
          // spradzić czy to most przyrosotowa
          if (lastValue) {
            const diff = myY - lastValue;
            newData.push([myX, diff]);
          }
          lastValue = myY;
        } else {
          newData.push([myX, myY]);
          if (el.get("y_min") && params.and(Long.fromString("0x100000000", 16)).isZero()) {
            // sprawdzić czy nie pokazywać min max
            let y_min = Number(el.get("y_min"));
            let y_max = Number(el.get("y_max"));

            if (el.get("x") == 0) {
              y_min = Number(el.get("y"));
              y_max = Number(el.get("y"));
            }
            newDataMin.push([myX, y_min]);
            newDataMax.push([myX, y_max]);
          }
        }
      });
      // //zobacz czy to musi być dla stepowych czy dla kazdych
      if (stepObjects.includes(deviceType)) {
        let yLast = Number(mainList.last().get("y"));
        newData.push([x2 * 1000, yLast]);
      }
      //additiona device
      if (mainList2 && mainList2.size != 0) {
        // if (mainList2.first().get("x") == 0) {
        // 	mainList2 = mainList2.delete("0");
        // }
        mainList2.map((el, index) => {
          let myX = Number(el.get("x")) * 1000;
          for (let i = index - 1; i >= 0; i--) {
            if (mainList2.get(i) && mainList2.get(i).get("x") === el.get("x")) {
              myX++;
            } else {
              break;
            }
          }

          if (el.get("x") == 0) {
            myX = x1 * 1000;
          }
          newDataAdditional.push([myX, Number(el.get("y"))]);
        });

        newDataAdditional.push([x2 * 1000, Number(mainList2.last().get("y"))]);
        // }
      }
      let chartOptions = Object.assign({}, chartOptionsState); //creating copy of object in state
      chartOptions.series = [];
      chartOptions.live = live;
      let url = "";

      if (deviceType === "temp" || deviceType === "reg") {
        url = "[°C]";
      } else if (["lin_wej", "bit8", "bit16", "sbit16", "bit32", "moc_ciagla", "moc_przyr", "tekst_can", "sensor"].includes(deviceType)) {
        url = params_device && params_device.get("url") ? `[${params_device.get("url")}]` : "[%]";
      }

      let data;
      chartOptions.series.push(
        (data = {
          name: deviceType == "reg" ? "Temp. zadana [°C]" : `${t("VALUE")}${url}`,
          data: newData,
          color: theme.ICONS_COLOR_PRIMARY,
          yAxis: 0,
          type: stepObjects.includes(deviceType) || forceStep || deviceType == "led" ? "line" : "spline",
          step: stepObjects.includes(deviceType) || forceStep || deviceType == "led" ? "left" : undefined,
          showInLegend: dimensionsProps.get("smallTiles") ? false : true,
        })
      );

      if (newDataMax.length > 0) {
        chartOptions.series.push(
          (data = {
            name: `${t("VALUE_MIN")} ${url}`,
            data: newDataMin,
            color: theme.CHART_VALUE_MIN,
            lineWidth: 1,
            yAxis: 0,
            type: stepObjects.includes(deviceType) || forceStep || deviceType == "led" ? "line" : "spline",
            step: stepObjects.includes(deviceType) || forceStep || deviceType == "led" ? "left" : undefined,
            showInLegend: dimensionsProps.get("smallTiles") ? false : true,
            marker: {
              symbol: "diamond",
            },
          })
        );

        chartOptions.series.push(
          (data = {
            name: `${t("VALUE_MAX")} ${url}`,
            label: {
              enabled: false,
            },
            data: newDataMax,
            yAxis: 0,
            color: theme.CHART_VALUE_MAX,
            lineWidth: 1,
            step: stepObjects.includes(deviceType) || forceStep || deviceType == "led" ? "left" : undefined,
            type: stepObjects.includes(deviceType) || forceStep || deviceType == "led" ? "line" : "spline",
            showInLegend: dimensionsProps.get("smallTiles") ? false : true,
            marker: {
              symbol: "diamond",
            },
          })
        );
      }

      if (deviceType == "reg") {
        chartOptions.series.push(
          (data = {
            name: "Temp. mierzona [°C]",
            data: newDataAdditional,
            // color:  props.theme.CHART_VALUE_MIN,
            color: "blue",
            lineWidth: 1,
            type: "spline",
            step: undefined,
            showInLegend: dimensionsProps.get("smallTiles") ? false : true,
            marker: {
              symbol: "diamond",
            },
          })
        );
      } else if (additionalDevice) {
        chartOptions.series.push(
          (data = {
            name: additionalDevice.get("opis_menu"),
            data: newDataAdditional,
            // color:  props.theme.CHART_VALUE_MIN,
            color: "blue",
            lineWidth: 1,
            yAxis: 1,
            showInLegend: dimensionsProps.get("smallTiles") ? false : true,
            type:
              stepObjects.includes(additionalDevice.get("typ_komponentu")) || forceStep || additionalDevice.get("typ_komponentu") == "led"
                ? "line"
                : "spline",
            step:
              stepObjects.includes(additionalDevice.get("typ_komponentu")) || forceStep || additionalDevice.get("typ_komponentu") == "led"
                ? "left"
                : undefined,
            marker: {
              symbol: "diamond",
            },
          })
        );
      }
      setChartOptions(chartOptions);
    }
  }, [chartData]);

  moment.locale(chooseMomentLocale(Localization.locale));

  return (
    <HighchartsReactNative
      styles={{
        width: "100%",
        height: "100%",
        opacity: 0.99,
      }}
      // THIS IS NEEDED FOR WEB
      viewStyle={{
        width: "100%",
        height: "100%",
      }}
      webviewStyles={{
        backgroundColor: "transparent",
        opacity: 0.99,
      }}
      options={chartOptionsState}
      setOptions={{
        lang: {
          months: moment.months(),
          weekdays: moment.weekdays(),
          shortMonths: moment.monthsShort(),
        },
      }}
    />
  );
};

const mapStateToProps = (state, props) => {
  return {
    tzOffset: state.statesData.get("server").get("serverInfo").get("tzOffset"),
    dimensionsProps: state.statesData.get("dimensions"),
    deviceState: state.statesData.get("states").get(props.deviceId),
  };
};

export default connect(mapStateToProps)(HighChartView);
