const groupsForGroups = (data, itemId, order) => {
  let filteredArray = null;
  if (data && itemId >= 0) {
    filteredArray = data.filter((group) => {
      if (group) {
        return (
          group.get("id_rodzica") == itemId && (group.get("params") & 0x04) == 0
        );
      }
    });
    let groupsOrder =
      order && order.get(`${itemId}`) && order.get(`${itemId}`).get("groups");

    if (itemId == 0 && !groupsOrder) {
      groupsOrder = order && order.get("groups");
    }

    if (groupsOrder) {
      filteredArray = filteredArray.map((group) => {
        let inOrderListIndex = groupsOrder.findIndex((el) => {
          return el == group.get("id");
        });
        if (inOrderListIndex == -1) {
          inOrderListIndex = undefined;
        }
        group = group.set("orderNumber", inOrderListIndex);
        return group;
      });
      filteredArray = filteredArray.sort((x, y) => {
        return x.get("orderNumber") - y.get("orderNumber");
      });
    } else {
      filteredArray = filteredArray.sort((x, y) => {
        return x.get("lp") - y.get("lp");
      });
    }
  }

  return filteredArray;
};

export default groupsForGroups;
