import React, { useState, useEffect, Component } from "react";
import { View, Text, Platform } from "react-native";
import { connect } from "react-redux";
import { t } from "../../../services/i18n";
import GreetingViewModal from "./GreetingViewModal";

import {
  changeWeatherVisibility,
  changeCalendarVisibility,
  changeGreetingViewVisibility,
  changeDashboardTopBarVisibility,
  saveGreetingViewObjects,
  changeMessagesIndicatorVisibility,
} from "../../../store/actions";
import { ScreenView } from "../../../components/UI/screenViewX";
import {
  PrimaryColorText,
  TextInputStyled,
  SwitchStyled,
  ComponentHeaderText,
  ComponentContainerTouchable,
  TitleWithLines,
} from "../../../components/UI/styledComponents";

import { ListItemContainer } from "../styled";

class Dashboard_PersonalizationScreen extends Component {
  state = {
    modalVisible: false,
    activeIndex: 0,
    greetingViewObjects: [
      {
        type: "",
        id: null,
      },
      {
        type: "",
        id: null,
      },
      {
        type: "",
        id: null,
      },
      {
        type: "",
        id: null,
      },
    ],
  };
  componentDidMount() {
    const { greetingViewObjects } = this.props;

    if (greetingViewObjects && greetingViewObjects.size) {
      this.setState({
        greetingViewObjects: greetingViewObjects.toJS(),
      });
    }
  }

  openModal = (index) => {
    this.setState({
      activeIndex: index,
      modalVisible: true,
    });
  };

  closeModal = () => {
    this.setState({
      modalVisible: false,
    });
  };

  changeWeatherVisibilityHandler = (value) => {
    this.props.changeWeatherVisibility(value);
  };

  changeDashboardTopBarVisibilityHandler = (value) => {
    this.props.changeDashboardTopBarVisibility(value);
  };

  changeMessagesIndicatorVisibilityHandler = (value) => {
    this.props.changeMessagesIndicatorVisibility(value);
  };

  changeCalendarVisibilityHandler = (value) => {
    this.props.changeCalendarVisibility(value);
  };
  changeGreetingViewVisibilityHandler = (value) => {
    this.props.changeGreetingViewVisibility(value);
  };

  onSave = (selectedView) => {
    let { greetingViewObjects } = this.state;
    greetingViewObjects[this.state.activeIndex] = selectedView;
    this.setState(
      {
        greetingViewObjects,
        modalVisible: false,
      },
      () => {
        this.props.saveGreetingViewObjects(greetingViewObjects);
      }
    );
  };

  render() {
    const { globalSettings, dimensions } = this.props;
    const width = dimensions.get("width");
    const height = dimensions.get("height");

    const { modalVisible, greetingViewObjects } = this.state;
    const weatherShown = globalSettings.get("weatherShown");
    const calendarShown = globalSettings.get("calendarShown");
    const greetingViewShown = globalSettings.get("greetingViewShown");

    const dashboardTopBarShown =
      globalSettings.get("dashboardTopBarShown") === undefined
        ? true
        : globalSettings.get("dashboardTopBarShown");
    const messagesIndicatorShown =
      globalSettings.get("messagesIndicatorShown") === undefined
        ? true
        : globalSettings.get("messagesIndicatorShown");

    return (
      <ScreenView>
        <ListItemContainer>
          <PrimaryColorText>{t("account:SHOW_WEATHER")}</PrimaryColorText>
          <SwitchStyled
            value={weatherShown}
            onValueChange={this.changeWeatherVisibilityHandler}
          />
        </ListItemContainer>
        <ListItemContainer>
          <PrimaryColorText>{t("account:SHOW_CALENDAR")}</PrimaryColorText>
          <SwitchStyled
            value={calendarShown}
            onValueChange={this.changeCalendarVisibilityHandler}
          />
        </ListItemContainer>

        <ListItemContainer>
          <PrimaryColorText>
            {t("account:SHOW_TOPBAR_DASHBOARD")}
          </PrimaryColorText>
          <SwitchStyled
            value={dashboardTopBarShown}
            onValueChange={this.changeDashboardTopBarVisibilityHandler}
          />
        </ListItemContainer>
        <ListItemContainer>
          <PrimaryColorText>
            {t("account:SHOW_MESSAGES_INDICATOR")}
          </PrimaryColorText>
          <SwitchStyled
            value={messagesIndicatorShown}
            onValueChange={this.changeMessagesIndicatorVisibilityHandler}
          />
        </ListItemContainer>
        {width >= 700 && height >= 600 && (
          <ListItemContainer>
            <PrimaryColorText>
              {t("account:SHOW_GREETING_VIEW")}
            </PrimaryColorText>
            <SwitchStyled
              value={greetingViewShown}
              onValueChange={this.changeGreetingViewVisibilityHandler}
            />
          </ListItemContainer>
        )}
        {modalVisible && (
          <GreetingViewModal
            isVisible={modalVisible}
            onClose={this.closeModal}
            onSave={this.onSave}
          />
        )}
        {greetingViewShown && (
          <View>
            <TitleWithLines textInside={t("account:GREETING_VIEW")} />

            <View
              style={{
                width: "100%",
                flexWrap: "wrap",
                flexDirection: "row",
                marginTop: 5,
              }}
            >
              {greetingViewObjects.map((el, index) => {
                return (
                  <ComponentContainerTouchable
                    containerStyle={{ width: "50%", height: 50 }}
                    key={index}
                    style={{
                      height: "100%",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: 5,
                      borderWidth: 1,
                      borderColor: "#999",
                    }}
                    onPress={() => this.openModal(index)}
                  >
                    <PrimaryColorText style={{ textAlign: "center" }}>
                      {el.type ? el.name : t("CHOOSE_OBJECT_TYPE")}
                    </PrimaryColorText>
                  </ComponentContainerTouchable>
                );
              })}
            </View>
          </View>
        )}
      </ScreenView>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    globalSettings: state.globalSettings,
    dimensions: state.statesData.get("dimensions"),
    greetingViewObjects: state.profilesSettings
      .get(state.profilesSettings.get("currentProfile"))
      .get("greetingViewObjects"),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeWeatherVisibility: (weatherVisible) =>
      dispatch(changeWeatherVisibility(weatherVisible)),
    changeCalendarVisibility: (weatherVisible) =>
      dispatch(changeCalendarVisibility(weatherVisible)),
    changeGreetingViewVisibility: (weatherVisible) =>
      dispatch(changeGreetingViewVisibility(weatherVisible)),
    changeDashboardTopBarVisibility: (dashboardTopBarVisible) =>
      dispatch(changeDashboardTopBarVisibility(dashboardTopBarVisible)),
    changeMessagesIndicatorVisibility: (messagesIndicatorVisible) =>
      dispatch(changeMessagesIndicatorVisibility(messagesIndicatorVisible)),
    saveGreetingViewObjects: (greetingViewObjects) =>
      dispatch(saveGreetingViewObjects(greetingViewObjects)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Dashboard_PersonalizationScreen);
