import React, { useEffect, useRef } from "react";
import { View, Animated, StyleSheet, Text } from "react-native";
import { Ionicons } from "@expo/vector-icons";
import { Entypo } from "@expo/vector-icons";
import { MaterialIcons } from "@expo/vector-icons";
import DefaultIcon from "../../../screens/Dashboard/weather/defaultIcon";
import { ComponentContainer, PrimaryColorText } from "../../UI/styledComponents";
import { useTheme } from "../../../../ThemeContext";
import { HighChartView } from "../../../utils";
import HighChartDonutView from "../../../utils/HighChartDonutView";
import AmpioIcon from "../../../utils/svg/AmpioIcon";
import { de } from "date-fns/locale";
import { UtilityPole } from "lucide-react-native";

export const colorExported = "#7BAFD4";
export const colorImported = "#FFD580";
export const colorProduced = "#BCE29E";
export const colorConsumed = "#EBA982";
export const colorCharging = "#76C7C0";
export const colorDischarging = "#F4A6A6";

const TriangleComponent = ({ deviceState, header, detailContent, dimensions, deviceFromCustom, device, params_device }) => {
  const { theme } = useTheme();
  const animatedArrowPositionProduction = useRef(new Animated.Value(0)).current;
  const animatedArrowPositionConsumption = useRef(new Animated.Value(1)).current;
  const arrowAnimationProduction = useRef(
    Animated.loop(
      Animated.sequence([
        Animated.timing(animatedArrowPositionProduction, {
          toValue: 1,
          duration: 2500,
          useNativeDriver: true,
        }),
        Animated.timing(animatedArrowPositionProduction, {
          toValue: 0,
          duration: 0,
          useNativeDriver: true,
        }),
      ])
    )
  ).current;

  const arrowAnimationConsumption = useRef(
    Animated.loop(
      Animated.sequence([
        Animated.timing(animatedArrowPositionConsumption, {
          toValue: 0,
          duration: 0,
          useNativeDriver: true,
        }),
        Animated.timing(animatedArrowPositionConsumption, {
          toValue: 1,
          duration: 3200,
          useNativeDriver: true,
        }),
      ])
    )
  ).current;

  useEffect(() => {
    arrowAnimationProduction.start();
    arrowAnimationConsumption.start();
    return () => {
      arrowAnimationProduction.stop();
      arrowAnimationConsumption.stop();
    }; // Clean up by stopping the animation
  }, []);

  let params = {};
  try {
    params = JSON.parse(device.get("data"));
  } catch (e) {
    console.log(e);
  }
  let columnWidth = dimensions.get("columnWidth");
  let webViewWidth = columnWidth - 20;

  let webViewHeight = webViewWidth * 1.15;

  const importEnergy = deviceState && deviceState.get("pg") > 50 ? deviceState.get("pg") : 0;
  const exportEnergy = deviceState && deviceState.get("tg") > 50 ? deviceState.get("tg") : 0;
  const production = deviceState && deviceState.get("pv") > 50 ? deviceState.get("pv") : 0;
  const bateryCharging = deviceState && deviceState.get("bat") < 0 ? deviceState.get("bat") * -1 : 0;
  const batteryDischaring = deviceState && deviceState.get("bat") > 0 ? deviceState.get("bat") : 0;
  let batteryConnected = params_device && (params_device.get("param1") === 3 || params_device.get("param1") === 4);

  //console.log(deviceState);
  const triangleContainer2 = (
    <View style={styles.container}>
      <View style={styles.triangleContainer}>
        <View style={[styles.triangleRow, { marginBottom: 50 }]}>
          <View style={[styles.iconContainer, { marginLeft: 0, opacity: production ? 1 : 0.3 }]}>
            <>
              <PrimaryColorText style={styles.valueText}>{(production / 1000).toFixed(1) + " kW"}</PrimaryColorText>
              <DefaultIcon name="solar-power" library="MaterialIcons" size={40} color={colorProduced} />
            </>
          </View>
          <View style={[styles.iconContainer, { marginHorizontal: 50, opacity: batteryDischaring ? 1 : 0.3 }]}>
            {batteryConnected ? (
              <>
                <PrimaryColorText style={styles.valueText}>{(batteryDischaring / 1000).toFixed(1) + " kW"}</PrimaryColorText>
                <DefaultIcon name="battery-arrow-down-outline" library="MaterialCommunityIcons" size={40} color={colorDischarging} />
              </>
            ) : null}
          </View>
          <View style={[styles.iconContainer, { marginRight: 0, opacity: importEnergy ? 1 : 0.3 }]}>
            <>
              <PrimaryColorText style={styles.valueText}>{(importEnergy / 1000).toFixed(1) + " kW"}</PrimaryColorText>
              <UtilityPole size={40} color={colorImported} style={{ margin: 5 }} />
            </>
          </View>
        </View>
        <View style={styles.triangleRow}>
          <View style={[styles.iconContainer]}>
            {/* <AmpioIcon /> */}
            <DefaultIcon name="home" library="FontAwesome" size={40} color={colorConsumed} />
            <PrimaryColorText style={styles.valueTextAmpio}>{(deviceState.get("ht") / 1000).toFixed(1) + " kW"}</PrimaryColorText>
          </View>
        </View>
        <View style={styles.triangleRow}>
          <View
            style={[
              styles.iconContainer,
              {
                marginRight: batteryConnected ? 75 : 0,
                marginTop: batteryConnected ? 0 : 50,
                marginHorizontal: batteryConnected ? 10 : 0,
                opacity: exportEnergy ? 1 : 0.3,
              },
            ]}
          >
            <UtilityPole size={40} color={colorExported} style={{ margin: 5 }} />
            <PrimaryColorText style={styles.valueText}>{(exportEnergy / 1000).toFixed(1) + " kW"}</PrimaryColorText>
          </View>
          {batteryConnected ? (
            <View style={[styles.iconContainer, { marginLeft: 75, opacity: bateryCharging ? 1 : 0.3 }]}>
              <DefaultIcon
                name={bateryCharging ? "battery-arrow-up-outline" : "battery-high"}
                library="MaterialCommunityIcons"
                size={40}
                color={colorCharging}
              />
              <PrimaryColorText style={styles.valueText}>{(bateryCharging / 1000).toFixed(1) + " kW"}</PrimaryColorText>
            </View>
          ) : null}
        </View>
      </View>
      {importEnergy ? (
        <View style={styles.mirroredLineContainerTopRight}>
          <View style={[styles.line, { backgroundColor: theme.ICONS_COLOR_PRIMARY }]} />
          <View style={styles.arrowContainer}>
            <Animated.View
              style={[
                styles.arrow,
                {
                  transform: [
                    {
                      translateX: animatedArrowPositionProduction.interpolate({
                        inputRange: [0, 1],
                        outputRange: [-10, -100],
                      }),
                    },
                  ],
                },
              ]}
            >
              {importEnergy > 0 && <Entypo name="dot-single" size={24} color={theme.ICONS_COLOR_PRIMARY} />}
            </Animated.View>
          </View>
        </View>
      ) : null}
      {production ? (
        <View style={styles.mirroredLineContainerTopLeft}>
          <View style={[styles.line, { backgroundColor: theme.ICONS_COLOR_PRIMARY }]} />
          <View style={styles.arrowContainer}>
            <Animated.View
              style={[
                styles.arrow,
                {
                  transform: [
                    {
                      translateX: animatedArrowPositionProduction.interpolate({
                        inputRange: [0, 1],
                        outputRange: [-10, -100],
                      }),
                    },
                  ],
                },
              ]}
            >
              {production && <Entypo name="dot-single" size={24} color={theme.ICONS_COLOR_PRIMARY} />}
            </Animated.View>
          </View>
        </View>
      ) : null}
      {bateryCharging && batteryConnected ? (
        <View style={styles.mirroredLineContainerBottomRight}>
          <View style={[styles.line, { backgroundColor: theme.ICONS_COLOR_PRIMARY }]} />
          <View style={styles.arrowContainer}>
            <Animated.View
              style={[
                styles.arrow,
                {
                  transform: [
                    {
                      translateX: animatedArrowPositionConsumption.interpolate({
                        inputRange: [0, 1],
                        outputRange: [-10, -100],
                      }),
                    },
                  ],
                },
              ]}
            >
              <Entypo name="dot-single" size={24} color={theme.ICONS_COLOR_PRIMARY} />
            </Animated.View>
          </View>
        </View>
      ) : null}

      {batteryDischaring && batteryConnected ? (
        <View style={styles.mirroredLineContainerTopMid}>
          <View style={[styles.line, { backgroundColor: theme.ICONS_COLOR_PRIMARY }]} />
          <View style={styles.arrowContainer}>
            <Animated.View
              style={[
                styles.arrow,
                {
                  transform: [
                    {
                      translateX: animatedArrowPositionProduction.interpolate({
                        inputRange: [0, 1],
                        outputRange: [-10, -60],
                      }),
                    },
                  ],
                },
              ]}
            >
              <Entypo name="dot-single" size={24} color={theme.ICONS_COLOR_PRIMARY} />
            </Animated.View>
          </View>
        </View>
      ) : null}
      {exportEnergy ? (
        <View
          style={
            batteryConnected
              ? styles.mirroredLineContainerBottomLeft
              : [
                  {
                    transform: [{ rotate: "-90deg" }],
                    position: "absolute",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    bottom: 130,
                    width: 70,
                    height: 20,
                  },
                ]
          }
        >
          <View style={[styles.line, { backgroundColor: theme.ICONS_COLOR_PRIMARY }]} />
          <View style={styles.arrowContainer}>
            <Animated.View
              style={[
                styles.arrow,
                {
                  transform: [
                    {
                      translateX: animatedArrowPositionConsumption.interpolate({
                        inputRange: [0, 1],
                        outputRange: batteryConnected ? [-10, -100] : [-10, -60],
                      }),
                    },
                  ],
                },
              ]}
            >
              {exportEnergy && <Entypo name="dot-single" size={24} color={theme.ICONS_COLOR_PRIMARY} />}
            </Animated.View>
          </View>
        </View>
      ) : null}
    </View>
  );

  return triangleContainer2;
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  triangleContainer: {
    alignItems: "center",
    marginBottom: 20,
  },
  triangleRow: {
    flexDirection: "row",
  },
  iconContainer: {
    alignItems: "center",
    marginHorizontal: 10,
  },
  valueText: {
    marginTop: 10,
    fontSize: 18,
    fontWeight: "bold",
  },
  valueTextAmpio: {
    marginTop: 2,
    fontSize: 20,
    fontWeight: "bold",
  },
  lineContainerLeft: {
    position: "absolute",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    top: 50,
    left: 50,
    width: 130,
    height: 120,
    transform: [{ rotate: "125deg" }],
  },
  lineContainerRight: {
    position: "absolute",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    top: 50,
    left: 180,
    width: 130,
    height: 100,
    transform: [{ rotate: "55deg" }],
  },

  mirroredLineContainerTopMid: {
    position: "absolute",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    top: 80,
    width: 70,
    height: 30,
    transform: [{ rotate: "-90deg" }], // Mirrored rotation, opposite angle
  },

  mirroredLineContainerTopLeft: {
    position: "absolute",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    top: 90,
    left: 60, // Mirrored position from the original Right container
    width: 110,
    height: 30,
    transform: [{ rotate: "-135deg" }], // Mirrored rotation, opposite angle
  },
  mirroredLineContainerTopRight: {
    position: "absolute",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    top: 90,
    right: 60, // Mirrored position from the original Left container
    width: 110,
    height: 30,
    transform: [{ rotate: "-45deg" }], // Mirrored rotation, opposite angle
  },
  mirroredLineContainerBottomLeft: {
    position: "absolute",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    bottom: 100,
    left: 80, // Mirrored position from the original Right container
    width: 110,
    height: 30,
    transform: [{ rotate: "-45deg" }], // Mirrored rotation, opposite angle
  },
  mirroredLineContainerBottomRight: {
    position: "absolute",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    bottom: 100,
    right: 80, // Mirrored position from the original Left container
    width: 110,
    height: 30,
    transform: [{ rotate: "-135deg" }], // Mirrored rotation, opposite angle
  },
  lineContainerBottom: {
    position: "absolute",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    top: 50,
    left: 125,
    width: 130,
    height: 230,
  },
  line: {
    flex: 1,
    height: 1,
  },
  arrowContainer: {
    position: "relative",
    width: 24,
    height: 24,
  },
  arrow: {
    position: "absolute",
  },
});

export default TriangleComponent;
