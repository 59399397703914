import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { View, Text, Pressable, Platform } from "react-native";
import { CommonActions, TabActions } from "@react-navigation/native";
import { useTheme } from "../../../ThemeContext";
import { t } from "../../services/i18n";
import { Connect } from "react-redux";
import BarMusicPlayer from "../../screens/Account/Audio/BarMusicPlayer";
import AmpioIcon from "../../utils/svg/AmpioIcon";
import { AccountScreen } from "../../screens";
import { connect } from "react-redux";
import * as Haptics from "expo-haptics";
import * as NavigationBar from "expo-navigation-bar";
import { changeRoute } from "../../store/actions";
import { TouchableOpacity } from "react-native-web";
const BottomTabBar = ({ state, descriptors, navigation, changeRoute }) => {
  const globalSettings = useSelector((state) => state.globalSettings);
  let sideMenuShown = globalSettings.get("sideMenuShown");
  let placesButtonWithSideMenuShown = globalSettings.get("placesButtonWithSideMenuShown");
  let lastUsed = "Dashboard";
  const disableClickVibrations = globalSettings.get("disableClickVibrations");

  const { theme } = useTheme();

  useEffect(() => {
    if (Platform.OS === "android") {
      NavigationBar.setBackgroundColorAsync(theme.TAB_BAR_BACKGROUND_COLOR);
    }
  }, [theme]);

  const iconFunc = (focused, route) => {
    let iconName;
    if (!disableClickVibrations) {
      Platform.OS !== "web" && Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Light);
    }
    switch (route.name) {
      case "Dashboard":
        iconName = focused ? "home" : "home-outline";
        break;
      case "Places":
        iconName = "floor-plan";
        break;
      case "Favourites":
        iconName = focused ? "star" : "star-outline";
        break;
      case "Scenes":
        iconName = focused ? "eye" : "eye-outline";
        break;
      case "Active":
        iconName = focused ? "lightbulb-on" : "lightbulb-on-outline";
        break;
      case "Account":
        iconName = "account-outline";
        break;
      default:
        break;
    }

    return <MaterialCommunityIcons name={iconName} size={22} color={focused ? theme.TAB_BAR_TEXT_COLOR_HIGHLITED : theme.TAB_BAR_TEXT_COLOR} />;
  };

  const getTouchable = (label, icon, isFocused, route) => {
    const onPress = () => {
      const event = navigation.emit({
        type: "tabPress",
        target: route.key,
      });
      //gdy na dashboardzie grupa i w nią weszliśmy, po przełączeniu tabów zresetuj
      if (state && state.index === 0 && state.routes && state.routes[0] && state.routes[0].state && state.routes[0].state.index > 0) {
        if (navigation.canGoBack()) {
          navigation.popToTop();
        }
      }

      if (!isFocused && !event.defaultPrevented) {
        lastUsed = route.name;
        navigation.navigate(route.name);
        changeRoute(route.name);
      }
    };

    return (
      <Pressable onPress={onPress} key={label}>
        <View
          style={{
            justifyContent: "center",
            alignItems: "center",
            flex: 1,
          }}
        >
          {icon}

          <Text
            style={{
              color: isFocused ? theme.TAB_BAR_TEXT_COLOR_HIGHLITED : theme.TAB_BAR_TEXT_COLOR,
              fontSize: 8,
              fontFamily: theme.FONT_BOLD,
            }}
          >
            {label.toUpperCase()}
          </Text>
        </View>
      </Pressable>
    );
  };

  let optionPlacesMenuShown = sideMenuShown && !placesButtonWithSideMenuShown;

  if (state.index === 0) {
    return (
      <React.Fragment>
        <BarMusicPlayer></BarMusicPlayer>
        <View
          style={{
            backgroundColor: theme.TAB_BAR_BACKGROUND_COLOR,
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: 50,
          }}
        >
          <TouchableOpacity
            onPress={() => {
              if (navigation.canGoBack()) {
                navigation.popToTop();
              }
              if (lastUsed) {
                navigation.navigate(lastUsed, {
                  withAnimation: true,
                });
              } else {
                if (navigation.canGoBack()) {
                  navigation.goBack();
                }
              }
            }}
          >
            <AmpioIcon />
          </TouchableOpacity>
        </View>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <BarMusicPlayer></BarMusicPlayer>
        <View
          style={{
            flexDirection: "row",
            backgroundColor: theme.TAB_BAR_BACKGROUND_COLOR,
          }}
        >
          <View
            style={{
              flexDirection: "row",
              flex: optionPlacesMenuShown ? 2 : 3,
              backgroundColor: theme.TAB_BAR_BACKGROUND_COLOR,
              justifyContent: "space-evenly",
              height: 50,
            }}
          >
            {state.routes.map((route, index) => {
              if (index > 0 && index < state.routes.length / 2) {
                const label = t(route.name.toUpperCase());
                const isFocused = state.index === index;
                let icon = iconFunc(isFocused, route);
                let showPlacesTab = route.name == "Places" && optionPlacesMenuShown;
                return showPlacesTab ? null : getTouchable(label, icon, isFocused, route);
              }
            })}
          </View>
          <View
            style={{
              backgroundColor: theme.TAB_BAR_BACKGROUND_COLOR,
              alignItems: "center",
              justifyContent: "center",
              width: 50,
              height: 50,
              marginLeft: 10,
              marginRight: 10,
            }}
          >
            <TouchableOpacity
              onPress={() => {
                if (!disableClickVibrations) {
                  Platform.OS !== "web" && Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Light);
                }
                navigation.navigate("Account");
              }}
            >
              <AmpioIcon />
            </TouchableOpacity>
          </View>
          <View
            style={{
              flexDirection: "row",
              flex: 3,
              backgroundColor: theme.TAB_BAR_BACKGROUND_COLOR,
              justifyContent: "space-evenly",
              height: 50,
            }}
          >
            {state.routes.map((route, index) => {
              if (index >= state.routes.length / 2) {
                const label = t(route.name.toUpperCase());
                const isFocused = state.index === index;
                let icon = iconFunc(isFocused, route);
                let showPlacesTab = route.name == "Places" && optionPlacesMenuShown;
                return showPlacesTab ? null : getTouchable(label, icon, isFocused, route);
              }
            })}
          </View>
        </View>
      </React.Fragment>
    );
  }
};

const propsAreEqual = (prevProps, nextProps) => {
  if (prevProps.state && nextProps.state) {
    return prevProps.state.index === nextProps.state.index;
  }
  return false;
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeRoute: (route) => dispatch(changeRoute(route)),
  };
};
export default connect(null, mapDispatchToProps)(React.memo(BottomTabBar, propsAreEqual));
