import React, { PureComponent } from "react";
import { View, Animated, TouchableOpacity, Text, StyleSheet, Image } from "react-native";

import { ThemeContext } from "../../../ThemeContext";
import { StyledIcon } from "./styledComponents";

class ButtonsSelector extends PureComponent {
  static contextType = ThemeContext;

  handleSlide = (type, index, skip) => {
    const { onChangeTab } = this.props;
    if (onChangeTab && !skip) {
      onChangeTab(index);
    }
  };

  render() {
    const { tabs = [], style, active, styleTab, fontSize, iconLibrary, iconSize, checkByValue } = this.props;
    const { theme } = this.context;

    return (
      <View
        style={[
          styles.container,
          style,
          {
            backgroundColor: "transparent",
            flexWrap: "wrap",
            justifyContent: "center",
          },
        ]}
      >
        {tabs.map((item, index) => (
          <TouchableOpacity
            key={index}
            style={[
              styles.button,

              {
                backgroundColor:
                  checkByValue && active === item.value
                    ? theme.SLIDER_MAX
                    : !checkByValue && index === active
                    ? theme.SLIDER_MAX
                    : theme.APP_BACKGROUND_COLOR,
                fontFamily: theme.FONT_BOLD,
                flexDirection: "row",
              },
            ]}
            onPress={() => {
              if (item.action) {
                item.action();
              } else {
                if (checkByValue) {
                  this.setState({ active: item.value }, () => this.handleSlide(`xTab${item.value}`, item.value, false));
                } else {
                  this.setState({ active: index }, () => this.handleSlide(`xTab${index}`, index, false));
                }
              }
            }}
          >
            {item.iconName && <StyledIcon size={iconSize} name={item.iconName} style={{ paddingHorizontal: 2 }} library={iconLibrary} />}
            {item.iconBase && (
              <Image
                source={{ uri: "data:image/png;base64," + item.iconBase }}
                resizeMode={"contain"}
                style={[{ marginHorizontal: 3, width: iconSize, height: iconSize }]}
              />
            )}
            <Text style={{ color: theme.TEXT_COLOR_PRIMARY, fontSize: fontSize ?? 10, fontFamily: theme.FONT }}>{item.title ?? item.label}</Text>
          </TouchableOpacity>
        ))}
      </View>
    );
  }
}
const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start", // Changed to 'flex-start' to align buttons to start
  },
  button: {
    alignItems: "center",
    justifyContent: "center",
    paddingVertical: 8, // Adjust vertical padding as needed
    paddingHorizontal: 8, // Adjust horizontal padding as needed
    borderRadius: 20, // This will give you rounded corners
    marginHorizontal: 5, // This adds spacing between the buttons
    marginTop: 5,
    minWidth: 50,
    // If you want to have a uniform size for all buttons, you can set a width here.
  },
});
export default ButtonsSelector;
