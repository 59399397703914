import React, { useContext } from "react";
import { View, TouchableOpacity, StyleSheet } from "react-native";
import { Feather } from "@expo/vector-icons";
import { StyledIcon } from "../../UI/styledComponents";
import { useTheme } from "../../../../ThemeContext";

const RollerButtonsBar = (props) => {
  const { theme } = useTheme();

  const onAction = (action) => {
    props.onActionFromButtons(action);
  };

  let smallTiles = props.smallTiles;

  if (props.customWidth) {
    smallTiles = false;
  }

  return (
    <View
      style={{
        alignItems: "center",
      }}
    >
      <View
        style={{
          width: "100%",
          borderRadius: 14,
          height: 34,
          borderColor: theme.PLACEHOLDER_BACKGROUND_COLOR,
          backgroundColor: theme.COMPONENT_BACKGROUND_COLOR_SECONDARY,
          borderWidth: 1,
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          paddingLeft: smallTiles ? 0 : 10,
          paddingRight: smallTiles ? 0 : 10,
        }}
        W
      >
        {(!smallTiles || !props.withLammels) && (
          <TouchableOpacity style={styles.touchableContainer} onPress={props.invertPercent ? () => onAction("open") : () => onAction("close")}>
            <StyledIcon
              name={"skip-forward"}
              size={22}
              style={!props.rollerMarquize ? { transform: [{ rotate: "90deg" }] } : { transform: [{ rotate: "180deg" }] }}
              color={"secondary"}
            />
          </TouchableOpacity>
        )}
        {(!smallTiles || props.withLammels) && (
          <TouchableOpacity
            style={styles.touchableContainer}
            onPressIn={props.invertPercent ? () => onAction("open") : () => onAction("close")}
            onPressOut={() => onAction("stop")}
          >
            <StyledIcon
              name={"play"}
              size={22}
              style={!props.rollerMarquize ? { transform: [{ rotate: "90deg" }] } : { transform: [{ rotate: "180deg" }] }}
              color={"secondary"}
            />
          </TouchableOpacity>
        )}
        {!smallTiles && (
          <TouchableOpacity style={styles.touchableContainer} onPress={() => onAction("stop")}>
            <StyledIcon name={"pause"} size={22} color={"secondary"} />
          </TouchableOpacity>
        )}
        {(!smallTiles || props.withLammels) && (
          <TouchableOpacity
            style={styles.touchableContainer}
            onPressIn={props.invertPercent ? () => onAction("close") : () => onAction("open")}
            onPressOut={() => onAction("stop")}
          >
            <StyledIcon
              name={"play"}
              size={22}
              style={!props.rollerMarquize ? { transform: [{ rotate: "-90deg" }] } : { transform: [{ rotate: "0deg" }] }}
              color={"secondary"}
            />
          </TouchableOpacity>
        )}
        {(!smallTiles || !props.withLammels) && (
          <TouchableOpacity style={styles.touchableContainer} onPress={props.invertPercent ? () => onAction("close") : () => onAction("open")}>
            <StyledIcon
              name={"skip-forward"}
              size={22}
              style={!props.rollerMarquize ? { transform: [{ rotate: "-90deg" }] } : { transform: [{ rotate: "0deg" }] }}
              color={"secondary"}
            />
          </TouchableOpacity>
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  touchableContainer: {
    padding: 5,
    // borderWidth: 1
  },
});
export default RollerButtonsBar;
