import React, { Component, useEffect, useState } from "react";
import { View, Platform, Text } from "react-native";
import { t } from "../../../services/i18n";
import mqttClient from "../../../services/mqtt";

import { ComponentContainer, ComponentHeaderText, PrimaryColorText } from "../../UI/styledComponents";

import ImageForLinearInput from "../../../utils/ImageForLinearInput";
import RollerButtonsBar from "./RollerButtonsBar";
import RollerAnimationView from "./RollerAnimationView";
import PickerSelect from "../../UI/PickerSelect";
import EndSwitchIcons from "../../../utils/EndSwitchIcons";
import { Slider as SliderM } from "@miblanchard/react-native-slider";
import * as Haptics from "expo-haptics";
import CircularSliderSvg from "./CircuralSliderSvg";

import { StyleSheet, Dimensions } from "react-native";
import Animated, { useSharedValue, useAnimatedStyle, withSpring, withTiming } from "react-native-reanimated";
import { useTheme } from "../../../../ThemeContext";

const LAMEL_HEIGHT = 20; // Height of each lamel
//const height = 400;

const Window = () => {
  const { theme } = useTheme();

  return (
    <View style={[styles.frame, { backgroundColor: theme.APP_BACKGROUND_COLOR }]}>
      <View style={styles.window}></View>
    </View>
  );
};

const RollerBlinds = ({ deviceState, rollerMarquize, invertPercent, height }) => {
  if (!deviceState) {
    return null;
  }

  const NUM_LAMELS = Math.ceil((height - 40) / LAMEL_HEIGHT); // Number of lamels

  //const [percentage, setPercentage] = useState(deviceState.get("lammel") / 100);
  const animatedValues = new Array(NUM_LAMELS).fill(0).map(() => useSharedValue(0));

  const rollerPosition = deviceState.get("state") ? deviceState.get("state") : 0;

  let transformNumOfDeg = invertPercent ? 180 : 0;
  if (rollerMarquize) {
    transformNumOfDeg = invertPercent ? 270 : 90;
  }

  useEffect(() => {
    const lammelPosition = deviceState.get("lammel") ? deviceState.get("lammel") : 0;
    let toRotate = (lammelPosition / 100) * 90 - 20;
    if (toRotate < 0) {
      toRotate = 0;
    }

    animatedValues.forEach((value) => {
      value.value = withSpring(toRotate); // Rotate up to 90 degrees
    });
  }, [deviceState]);

  return (
    <View style={[styles.container, { height: height, transform: rollerMarquize ? [{ rotate: `${transformNumOfDeg}deg` }] : undefined }]}>
      <Window />
      <View style={[styles.lamelWrapper]}>
        {animatedValues.map((value, index) =>
          ((100 - rollerPosition) / 100) * NUM_LAMELS > index ? (
            <Lamel key={index} index={index} animatedValue={value} rollerPosition={rollerPosition} />
          ) : (
            <Lamel key={index} index={index} animatedValue={value} rollerPosition={rollerPosition} />
          )
        )}
      </View>
    </View>
  );
};

const Lamel = ({ index, animatedValue, rollerPosition }) => {
  const animatedStyle = useAnimatedStyle(() => {
    return {
      transform: [{ rotateX: `${animatedValue.value}deg` }],
      top: index,
    };
  });
  const percentClose = (100 - rollerPosition) / 100;

  const positionStyle = useAnimatedStyle(() => {
    return {
      top: withTiming(index * LAMEL_HEIGHT * percentClose, { duration: 800 }),
    };
  });

  return (
    <Animated.View style={[styles.lamelContainer, positionStyle]}>
      <Animated.View style={[styles.lamel, animatedStyle]} />
    </Animated.View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "transparent",
    marginTop: 10,
    justifyContent: "center",
    alignItems: "center",
  },
  lamelWrapper: {
    width: "80%", // Match the frame's width
    position: "absolute",
    top: 15, // Adjust for the frame's padding
    bottom: 20, // Adjust for the frame's padding
  },
  frame: {
    width: "80%", // Slightly larger than window to show frame
    height: "100%", // Slightly larger than window to show frame
    backgroundColor: "#fff", // Frame color
    position: "relative",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 10, // Rounded corners
    padding: 20,
    shadowColor: "#000", // Shadow color
    shadowOffset: { width: 0, height: 4 }, // Shadow offset
    shadowOpacity: 0.3, // Shadow opacity
    shadowRadius: 5, // Shadow radius
    elevation: 5, // For Android shadow
  },
  window: {
    width: "100%",
    height: "100%",
    rounded: 10,
    backgroundColor: "#add8e6", // Light blue background color for the window
    position: "absolute",
  },
  lamelContainer: {
    position: "absolute",
    width: "100%",
    height: LAMEL_HEIGHT,
  },
  lamel: {
    position: "absolute",
    width: "100%",
    height: LAMEL_HEIGHT,

    borderBottomWidth: LAMEL_HEIGHT,
    borderBottomColor: "#808080", // Light gray color
    borderLeftWidth: 5, // Adjust for trapezoidal effect
    borderLeftColor: "transparent",
    borderRightWidth: 5, // Adjust for trapezoidal effect
    borderRightColor: "transparent",
  },
  text: {
    marginVertical: 20,
    fontSize: 18,
    color: "#fff",
  },
});
class RollerComponent extends Component {
  state = {
    block: 0,
    blindsLevel: 0,
    lammelsLevel: 0,
    blindsLevelToSend: 0,
    lammelsLevelToSend: 0,
    blindsVertical: 50,
    inChange: false,
    percent: [],
    percentLammels: [],
    startAngle: 0,
    angleLength: 0,
  };

  round5 = (value) => {
    if (value > 0 && value <= 100) {
      let number = Number(value);
      return Math.round(number / 5) * 5;
    } else {
      return value;
    }
  };

  componentDidMount() {
    const { deviceState, device, deviceInScene, params_device, deviceFromAutomatization, dimensions } = this.props;
    const percent = [];
    const percentLammels = [];
    let lammelsStep = 5;

    if ((deviceFromAutomatization || deviceInScene) && device.get("typ_komponentu") == "roleta_lamelki") {
      percent.push({
        label: t("NO_ACTION"),
        value: 101,
      });

      percentLammels.push({
        label: t("NO_ACTION"),
        value: 101,
      });
    }

    if (params_device && (params_device.get("params") & 0x8000) > 0) {
      lammelsStep = 1;
    }

    for (let index = 0; index <= 100; index = index + 5) {
      let element = {
        label: "" + index + " %",
        value: index,
      };
      if (index == 0) {
        if (params_device && (params_device.get("params") & 0x2000000) > 0) {
          // MARKIZA
          if (params_device && (params_device.get("params") & 0x200000) > 0) {
            element.label = "" + index + " %   " + t("RIGHT");
          } else {
            element.label = "" + index + " %   " + t("LEFT");
          }
        } else {
          // NORMALNE
          if (params_device && (params_device.get("params") & 0x200000) > 0) {
            element.label = "" + index + " %   " + t("UP");
          } else {
            element.label = "" + index + " %   " + t("DOWN");
          }
        }
      } else if (index == 100) {
        if (params_device && (params_device.get("params") & 0x2000000) > 0) {
          // MARKIZA
          if (params_device && (params_device.get("params") & 0x200000) > 0) {
            element.label = "" + index + " %   " + t("LEFT");
          } else {
            element.label = "" + index + " %   " + t("RIGHT");
          }
        } else {
          // NORMALNE
          if (params_device && (params_device.get("params") & 0x200000) > 0) {
            element.label = "" + index + " %   " + t("DOWN");
          } else {
            element.label = "" + index + " %   " + t("UP");
          }
        }
      }
      percent.push(element);
    }

    for (let index = 0; index <= 100; index = index + lammelsStep) {
      let lammelsElement = {
        label: "" + index + " %",
        value: index,
      };

      percentLammels.push(lammelsElement);
    }

    if (!deviceInScene && !deviceFromAutomatization) {
      if (device.get("typ_komponentu") != "roleta") {
        this.setState({
          blindsLevel: this.round5(deviceState.get("state")),
          block: deviceState.get("block") ? Number(deviceState.get("block")) : 0,
        });
        if (device.get("typ_komponentu") == "roleta_lamelki") {
          this.setState({
            lammelsLevel:
              params_device && (params_device.get("params") & 0x8000) > 0
                ? Number(deviceState.get("lammel"))
                : this.round5(deviceState.get("lammel")),
          });
        }
      }
    } else {
      this.setState({
        blindsLevel: deviceInScene.get("param1"),
        lammelsLevel: deviceInScene.get("param2"),
      });
    }

    this.setState({ percent, percentLammels });
  }
  onPickerOpen = () => {
    this.setState({ inChange: true });
  };

  onPickerValueChange = (value, key) => {
    if (key == "blinds") {
      this.setState(
        {
          blindsLevelToSend: value,
          lammelsLevelToSend: this.state.lammelsLevel,
        },
        () => {
          if (Platform.OS === "android") {
            this.pickerCallback();
          }
        }
      );
    } else if (key == "lammels") {
      this.setState(
        {
          lammelsLevelToSend: value,
          blindsLevelToSend: this.state.blindsLevel,
        },
        () => {
          if (Platform.OS === "android") {
            this.pickerCallback();
          }
        }
      );
    }
  };

  onPickerClose = () => {
    if (Platform.OS === "ios") {
      this.setState({ inChange: false });

      this.pickerCallback();
    }
  };

  pickerCallback = (blinds, lammels) => {
    const { device, deviceInScene, params_device } = this.props;

    let { blindsLevelToSend, lammelsLevelToSend } = this.state;

    if (blinds) {
      blindsLevelToSend = blinds[0];
    }
    if (lammels !== undefined) {
      lammelsLevelToSend = lammels;
    }

    let deviceID = device.get("id");
    let message =
      device.get("typ_komponentu") == "roleta_lamelki"
        ? `/api/set/${deviceID}/setRollerPos/${blindsLevelToSend}/${lammelsLevelToSend}`
        : `/api/set/${deviceID}/setRollerPos/${blindsLevelToSend}/-1`;
    if (deviceInScene && device.get("typ_komponentu") == "roleta") {
      message = `/api/set/${deviceID}/${blindsLevelToSend ? "open" : "close"}`;
    }

    this.sendMessageHandler(message);

    if (deviceInScene) {
      this.setState({
        blindsLevel: this.round5(blindsLevelToSend),
        lammelsLevel: params_device && (params_device.get("params") & 0x8000) > 0 ? Number(lammelsLevelToSend) : this.round5(lammelsLevelToSend),
      });
      let infoToUpdate = deviceInScene;
      infoToUpdate = infoToUpdate.set("param1", blindsLevelToSend);
      infoToUpdate = infoToUpdate.set("param2", lammelsLevelToSend);
      this.props.changeScene(infoToUpdate, deviceInScene.get("temporary_id"));
    }
  };

  onActionFromButtons = (action) => {
    const { device } = this.props;
    let message = "";
    switch (action) {
      case "close":
        message = `/api/set/${device.get("id")}/close`;
        break;
      case "open":
        message = `/api/set/${device.get("id")}/open`;
        break;
      case "stop":
        message = `/api/set/${device.get("id")}/stop`;
        break;
      default:
        break;
    }

    this.sendMessageHandler(message);
    Platform.OS !== "web" && Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Light);
  };

  sendMessageHandler = (message) => {
    const { device, activeSceneControl } = this.props;
    activeSceneControl != false && mqttClient.stateChangeToSend(message, device.get("id"));
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { deviceInScene, deviceState, device, params_device } = this.props;
    if (!deviceInScene && device.get("typ_komponentu") != "roleta") {
      let lammelsLevelReceived =
        device.get("typ_komponentu") == "roleta_lamelki"
          ? params_device && (params_device.get("params") & 0x8000) > 0
            ? Number(nextProps.deviceState.get("lammel"))
            : this.round5(nextProps.deviceState.get("lammel"))
          : 0;
      this.setState({
        blindsLevel: this.round5(nextProps.deviceState.get("state")),
        lammelsLevel: lammelsLevelReceived,
        block: nextProps.deviceState.get("block") ? Number(nextProps.deviceState.get("block")) : 0,
      });
    }
  }

  evaluateStateSmall = () => {
    const { deviceState } = this.props;

    const block = deviceState && deviceState.get("block") ? Number(deviceState.get("block")) : 0;
    const percent = deviceState ? deviceState.get("state") : -1;

    if (block > 0) {
      switch (block) {
        case 1:
          return t("ROLLER_CLOSE_LOCK");
        case 2:
          return t("ROLLER_OPEN_LOCK");
        case 3:
          return t("ROLLER_LOCK");
        default:
          return t("UNKNOWN");
      }
    } else {
      if (percent === -1) {
        return "";
      }
      if (percent == 0) {
        return t("statistics:CLOSE");
      } else if (percent == 100) {
        return t("statistics:OPEN");
      } else {
        if (percent) {
          return t("statistics:OPEN") + " " + percent + "%";
        } else {
          return t("statistics:OPEN");
        }
      }
    }
  };

  evaluateState = () => {
    let result = this.evaluateStateSmall();
    result = result ? result[0].toUpperCase() + result.slice(1) : "";
    return result;
  };

  render() {
    const {
      device,
      deviceInScene,
      deviceFromAutomatization,
      header,
      readOnly,
      deviceState,
      deviceFromCustom,
      params_device,
      dimensions,
      customWidth,
    } = this.props;
    const { percent, percentLammels, lammelsLevel, lammelsLevelToSend, block } = this.state;

    let rollerMarquize = false;
    let invertPercent = false;
    if (params_device && (params_device.get("params") & 0x2000000) > 0) {
      rollerMarquize = true;
    }
    if (params_device && (params_device.get("params") & 0x200000) > 0) {
      invertPercent = true;
    }

    const evaluated = this.evaluateState();

    const rollerView =
      deviceState && deviceState.get("res") ? (
        <ImageForLinearInput id={deviceState.get("res")} />
      ) : (
        <RollerAnimationView
          blindsLevel={this.state.blindsLevel}
          rollerMarquize={rollerMarquize}
          invertPercent={invertPercent}
          width={this.props.detailContent ? 102 : undefined}
          height={this.props.detailContent ? 102 : undefined}
        />
      );

    return this.props.detailContent ? (
      <View>
        <ComponentContainer numOfColumns={1}>
          <View style={{ justifyContent: "center", alignItems: "center", flexDirection: "row", flex: 1 }}>
            <View style={{ flexDirection: "row" }}>
              <ComponentHeaderText
                style={{
                  fontWeight: "bold",
                  textAlign: "center",
                  justifyContent: "center",
                  marginHorizontal: 10,
                }}
              >
                {t("ROLLER")}
              </ComponentHeaderText>
              <PickerSelect
                placeholder={{
                  label: t("SELECT_VALUE"),
                  value: null,
                }}
                items={percent}
                containerStyle={{ width: 80 }}
                onValueChange={(value) => this.onPickerValueChange(value, "blinds")}
                onOpen={this.onPickerOpen}
                onClose={this.onPickerClose}
                disabled={readOnly}
                value={this.state.inChange ? this.state.blindsLevelToSend : this.state.blindsLevel}
              />
            </View>
            {device.get("typ_komponentu") == "roleta_lamelki" ? (
              <View style={{ flexDirection: "row", marginHorizontal: 10 }}>
                <ComponentHeaderText
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    justifyContent: "center",
                  }}
                >
                  {t("LAMMEL")}
                </ComponentHeaderText>
                <PickerSelect
                  // containerWidthAndroid={80}
                  disabled={readOnly}
                  placeholder={{
                    label: t("SELECT_VALUE"),
                    value: null,
                  }}
                  items={percentLammels}
                  onOpen={this.onPickerOpen}
                  onValueChange={(value) => this.onPickerValueChange(value, "lammels")}
                  containerStyle={{ width: 80 }}
                  onClose={this.onPickerClose}
                  value={this.state.inChange ? this.state.lammelsLevelToSend : this.state.lammelsLevel}
                />
              </View>
            ) : null}
          </View>
          <View flexDirection="row" alignItems="center" alignContent="center" justifyContent="center" position="relative">
            <View style={{ height: rollerMarquize ? Number(dimensions.get("width")) * 0.9 : 400 }}>
              <RollerBlinds
                deviceState={deviceState}
                rollerMarquize={rollerMarquize}
                invertPercent={invertPercent}
                height={rollerMarquize ? Number(dimensions.get("width")) * 0.9 : 400}
              />
            </View>
            {!rollerMarquize && deviceState && deviceState.get("state") && (
              <View
                style={{
                  width: 360,
                  position: "absolute",
                  right: -170,
                  top: 360 / 2,
                }}
              >
                <SliderM
                  animateTransitions
                  minimumValue={0}
                  maximumValue={100}
                  value={this.state.blindsVertical}
                  onSlidingComplete={(value) => {
                    this.setState({ blindsVertical: value[0] });
                    this.pickerCallback(value);
                  }}
                  vertical={true}
                  step={1}
                />
              </View>
            )}
          </View>
        </ComponentContainer>

        <ComponentContainer numOfColumns={1}>
          <RollerButtonsBar
            onActionFromButtons={this.onActionFromButtons}
            rollerMarquize={rollerMarquize}
            invertPercent={invertPercent}
            customWidth={customWidth}
          />
        </ComponentContainer>
      </View>
    ) : dimensions && dimensions.get("smallTiles") && !deviceFromAutomatization && !deviceInScene && !deviceFromCustom ? (
      <View>
        <View
          style={{
            flexDirection: "row",
            paddingBottom: 0,
            alignItems: "center",
            alignContent: "center",
            height: 40,
          }}
        >
          <View
            style={{
              flexDirection: "column",
              flex: 1,
            }}
          >
            {!deviceFromCustom ? header : null}
            {evaluated && (
              <PrimaryColorText style={{ paddingLeft: 8, fontSize: 12 }} numberOfLines={1}>
                {evaluated}
              </PrimaryColorText>
            )}
          </View>
          {!deviceInScene && !readOnly && block !== 3 && (
            <View style={{ width: customWidth ? "70%" : 70 }}>
              <RollerButtonsBar
                onActionFromButtons={this.onActionFromButtons}
                withLammels={device.get("typ_komponentu") == "roleta_lamelki"}
                rollerMarquize={rollerMarquize}
                invertPercent={invertPercent}
                smallTiles={this.props.dimensions.get("smallTiles")}
                customWidth={customWidth}
              />
            </View>
          )}
        </View>
      </View>
    ) : (
      <View>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            paddingBottom: deviceFromAutomatization || deviceInScene ? 0 : 10,
            alignItems: "center",
            alignContent: "center",
            height: 40,
          }}
        >
          {!deviceFromCustom ? <View style={{ flex: 1 }}>{header}</View> : null}
          {device.get("typ_komponentu") == "roleta" && deviceState && deviceState.get("conn") && <EndSwitchIcons value={deviceState.get("conn")} />}
          {device.get("typ_komponentu") != "roleta" ? (
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-around",
                flex: deviceFromCustom ? 1 : undefined,
              }}
            >
              {((dimensions && !dimensions.get("smallTiles")) || deviceFromAutomatization || deviceInScene) && (
                <PickerSelect
                  placeholder={{}}
                  items={percent}
                  // containerWidthAndroid={80}
                  onValueChange={(value) => this.onPickerValueChange(value, "blinds")}
                  onOpen={this.onPickerOpen}
                  value={this.state.inChange ? this.state.blindsLevelToSend : this.state.blindsLevel}
                  containerStyle={{ width: 120 }}
                  onClose={this.onPickerClose}
                  disabled={readOnly}
                />
              )}
              {!deviceInScene && rollerView}
            </View>
          ) : null}
          {(deviceInScene || deviceFromAutomatization) && device.get("typ_komponentu") == "roleta" && (
            <PickerSelect
              // containerWidthAndroid={80}
              placeholder={{}}
              items={[
                {
                  label: t("DOWN"),
                  value: 0,
                },
                {
                  label: t("UP"),
                  value: 100,
                },
              ]}
              onOpen={this.onPickerOpen}
              onValueChange={(value) => this.onPickerValueChange(value, "blinds")}
              value={this.state.inChange ? this.state.blindsLevelToSend : this.state.blindsLevel}
              containerStyle={{ width: 80 }}
              onClose={this.onPickerClose}
            />
          )}
        </View>
        {!deviceInScene && !readOnly && block !== 3 && (
          <RollerButtonsBar
            onActionFromButtons={this.onActionFromButtons}
            rollerMarquize={rollerMarquize}
            invertPercent={invertPercent}
            dimensions={this.props.dimensions}
            customWidth={customWidth}
          />
        )}
        {block === 1 && (
          <PrimaryColorText
            style={{
              fontWeight: "bold",
              textAlign: "center",
              color: "red",
              paddingTop: 5,
            }}
          >
            {t("ROLLER_CLOSE_LOCK")}
          </PrimaryColorText>
        )}
        {block === 2 && (
          <PrimaryColorText
            style={{
              fontWeight: "bold",
              textAlign: "center",
              color: "red",
              paddingTop: 5,
            }}
          >
            {t("ROLLER_OPEN_LOCK")}
          </PrimaryColorText>
        )}
        {block === 3 && (
          <PrimaryColorText
            style={{
              fontWeight: "bold",
              textAlign: "center",
              color: "red",
            }}
          >
            {t("ROLLER_LOCK")}
          </PrimaryColorText>
        )}
        {device.get("typ_komponentu") == "roleta_lamelki" ? (
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              marginTop: 10,
              marginLeft: deviceFromCustom ? 15 : undefined,
              width: deviceFromCustom ? "70%" : undefined,
            }}
          >
            <ComponentHeaderText style={{ marginLeft: 6, marginTop: 5 }}>{t("LAMMELS")}</ComponentHeaderText>

            <PickerSelect
              // containerWidthAndroid={80}
              disabled={readOnly}
              placeholder={{
                label: t("SELECT_VALUE"),
                value: null,
              }}
              items={percentLammels}
              onOpen={this.onPickerOpen}
              onValueChange={(value) => this.onPickerValueChange(value, "lammels")}
              value={this.state.inChange ? this.state.lammelsLevelToSend : this.state.lammelsLevel}
              containerStyle={{ width: 80 }}
              onClose={this.onPickerClose}
            />
          </View>
        ) : null}
      </View>
    );
  }
}

export default RollerComponent;
