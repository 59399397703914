import React, { Component, useEffect, useState } from "react";
import { Text, View, TouchableOpacity, StyleSheet, ScrollView } from "react-native";
import { connect } from "react-redux";
import { Feather } from "@expo/vector-icons";
import { useRoute, getFocusedRouteNameFromRoute } from "@react-navigation/native";
import IconBadge from "../utils/IconBadge";
import { groupsForGroups } from "../utils";

import { PrimaryColorText, ComponentImage } from "../components/UI/styledComponents";

const styles = StyleSheet.create({
  tab: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    height: 70,
    width: "100%",
    margin: 5,
    backgroundColor: "transparent",
    overflow: "hidden",
    paddingHorizontal: 20,
  },
  tabContainer: {
    paddingVertical: 20,
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  },
});

const SideMenu = ({ groups, globalSettings, sideMenuWidth, navigation, order }) => {
  let level = 36;
  let list = [];

  let [activeId, setActiveId] = useState(null);
  const sideMenuColor = globalSettings.get("sideMenuColor") ? globalSettings.get("sideMenuColor") : "rgb(51,51,51)";

  const sideMenuSize = globalSettings.get("sideMenuSize") ? globalSettings.get("sideMenuSize") : "short";

  const groupView = (group, level) => {
    let ifShortMenu = sideMenuSize == "short" ? true : false;
    // navigation
    var base64Icon = `data:image/png;base64,${group.get("ikona_menu")}`;

    return (
      <TouchableOpacity
        onPress={() => {
          navigation.navigate("Places", {
            initial: false,
            screen: "Devices_Places",
            params: { group },
          });
        }}
        key={group.get("id")}
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: ifShortMenu ? "center" : "flex-start",
          height: 50,
          paddingLeft: ifShortMenu ? 0 : 10,
          backgroundColor: group.get("id") == activeId ? "rgba(0,0,0,0.2)" : null,
        }}
      >
        {level != 36 && (
          <Feather style={{ paddingHorizontal: !ifShortMenu ? (1 / level) * 100 : 0 }} name={"arrow-right"} size={level / 3} color={"#ccc"} />
        )}
        <IconBadge group={group}>
          {base64Icon && (
            <ComponentImage
              source={{ uri: base64Icon }}
              style={{
                width: level,
                height: level,
              }}
            />
          )}
        </IconBadge>
        {!ifShortMenu ? (
          <Text
            style={{
              color: "white",
              paddingLeft: 10,
              fontWeight: "bold",
            }}
          >
            {group.get("opis_menu")}
          </Text>
        ) : null}
      </TouchableOpacity>
    );
  };

  const addViews = (group, level) => {
    const onlyMainGroupsShown = globalSettings.get("onlyMainGroupsShown");

    list.push(groupView(group, level));
    !onlyMainGroupsShown &&
      groupsForGroups(groups, group.get("id"), order).map((child) => {
        if (child) addViews(child, level - 6);
      });
  };

  groupsForGroups(groups, 0, order).forEach((group) => {
    if (group) addViews(group, 36);
  });

  return (
    <View
      style={[
        styles.tabContainer,
        {
          flex: 1,
          width: sideMenuWidth,
          backgroundColor: sideMenuColor,
        },
      ]}
    >
      <ScrollView
        contentContainerStyle={{
          width: sideMenuWidth,
        }}
      >
        {list.map((el) => {
          return el;
        })}
      </ScrollView>
    </View>
  );
};

const mapStateToProps = (state) => {
  return {
    groups: state.smartHomeData.get(state.profilesSettings.get("currentProfile")).get("groups"),
    globalSettings: state.globalSettings,
    order: state.profilesSettings.get(state.profilesSettings.get("currentProfile")).get("order"),
  };
};

export default connect(mapStateToProps)(SideMenu);
