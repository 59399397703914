import React, { Component } from "react";

import {
  authDataReceived,
  associationsDataReceived,
  fetchWeatherApi,
  changeCurrentProfile,
  changeCurrentConnectionPlace,
  changeSideMenuVisibility,
  serverUpdateInfoReceived,
  changeBlockLoginAutomatically,
  changeAppLocation,
  changeFromSwitchProfile,
  clearStatesData,
  changeDimensions,
} from "../../store/actions";
import * as Progress from "react-native-progress";
import { changePopupObjectId } from "../../store/actions/statesData";
import SpotlightSearch from "react-native-spotlight-search";
import { connect } from "react-redux";
import * as SplashScreen from "expo-splash-screen";
import { ThemeContext } from "../../../ThemeContext";
import { fetchAccessToken, fetchLocalToken } from "./CloudTools";
import CloudIntegration from "./CloudIntegration";
import * as ScreenOrientation from "expo-screen-orientation";
import * as Localization from "expo-localization";
import { calculateSizeMenu } from "../../utils";
import CLOUD_CONSTANTS, { mainHost, updateHost } from "../../../CloudConstants";
import moment from "moment";
import base64 from "base-64";
import * as Linking from "expo-linking";
import md5 from "blueimp-md5";
import * as Notifications from "expo-notifications";
import * as LocalAuthentication from "expo-local-authentication";
import "moment/locale/pl";
import "moment/locale/en-gb";
import { View, ImageBackground, StyleSheet, Image, ActivityIndicator, TouchableOpacity, Pressable, Text, Dimensions, Platform } from "react-native";
import { t } from "../../services/i18n";

import mqttClient from "../../services/mqtt";
import ModalSimple from "../../components/UI/ModalSimple";
import backgroundImage from "../../../assets/background_app.png";
import backgroundImageLoading from "../../../assets/background_loading.jpeg";
import logoImage from "../../../assets/iphone_login_logo2.png";
import logoImageBlack from "../../../assets/logo_ciemnetlo2.png";
import rectangle from "../../../assets/rectangle.png";

import { ButtonPrimary, ButtonSecondary, PrimaryColorText, SecondaryColorText } from "../../components/UI/styledComponents";
import { FontAwesome, Feather, MaterialCommunityIcons } from "@expo/vector-icons";
import { SkottieLoader } from "../../components/animatedComponents/Loader";
import { calculateDimmenions } from "../../utils/screenTools";

class InitialScreen extends Component {
  static contextType = ThemeContext;

  constructor(props) {
    super(props);
    window.authScreen = this;

    this.state = {
      showIndicator: false,
      welcomeScreen: false,
      triedConnectFromNotifiaction: false,
      offlineAlertVisible: false,
      requireUpdateVisible: false,
      requireOwnerAssociate: false,
      connectDisabled: true,
      cloudDisable: true,
      updateProgress: "",
      updateStatus: "",
      onOKdisabled: true,
      offlineReason: "CHECK_CONNECTION",
      loginData: {
        name: "",
        login: "",
        password: "",
        ip: "",
        ip2: "",
        cloudIp: "",
        device_id: "",
        cloudType: "",
      },
    };
  }

  setSideMenuForTheFirstTimeUsers = () => {
    if (Platform.OS != "web") {
      const pixelSize = Math.sqrt(Math.pow(Dimensions.get("screen").width, 2) + Math.pow(Dimensions.get("screen").height, 2)) / 160;

      ScreenOrientation.getOrientationAsync().then((orientationId) => {
        let landscapeOr = ["LANDSCAPE", "LANDSCAPE_LEFT", "LANDSCAPE_RIGHT"];
        if (pixelSize > 5.5 && landscapeOr.includes(ScreenOrientation.Orientation[orientationId])) {
          this.props.changeSideMenuVisibility(true);
        }
      });
    } else {
      if (Dimensions.get("window").width > 600 && Dimensions.get("window").width > Dimensions.get("window").height) {
        this.props.changeSideMenuVisibility(true);
      }
    }
  };

  checkCloudData = async (authData) => {
    this.goToProfiles();
  };

  checkTypeOfNavigation = async (profileFound) => {
    const { currentProfile, globalSettings, profilesSettings, blockLoginAutomatically, authData } = this.props;
    let autoLog = globalSettings.get("autoLogin");
    if (autoLog == undefined) {
      autoLog = true;
    }
    if (globalSettings.get("appLogout") === true) {
      autoLog = false;
      profileFound = false;
    }

    if (!blockLoginAutomatically && (autoLog || profileFound)) {
      this.setState({ welcomeScreen: true }, () => {
        this.props.onChangeFromSwitchProfile(true);
        if (this.onConnect) {
          if (profileFound && currentProfile) {
            window.app.showToast(t("DIRECT_CONNECT_TO") + ": " + currentProfile);
          }
          this.onConnect("automaticLogin");
        }
      });
    } else {
      this.checkCloudData(authData);
    }
  };

  componentWillUnmount() {
    this._unsubscribe();
  }

  async componentDidMount() {
    this._unsubscribe = this.props.navigation.addListener("focus", () => {
      if (this.props.route && this.props.route.params) {
        this.setState({
          offlineAlertVisible: false,
          requireOwnerAssociate: false,
          requireUpdateVisible: false,
        });
        this.onConnect(this.props.route.params.source, this.props.route.params.loginDataForNewCloud, this.props.route.params.connectLoginData);
      }
    });

    this.props.onUpdateInfoReceived("");
    let profileFound = false;
    if (this.props.fromSwitchProfile) {
      this.setState({ showIndicator: true, welcomeScreen: true });
    } else {
      const { currentProfile, profilesSettings, globalSettings } = this.props;

      let profile = currentProfile;
      try {
        if (Platform.OS !== "web") {
          const lastNotification = await Notifications.getLastNotificationResponseAsync();

          let deviceId = null;
          if (
            lastNotification &&
            lastNotification.notification &&
            lastNotification.notification.request &&
            lastNotification.notification.request.content &&
            !this.state.triedConnectFromNotifiaction
          ) {
            let data = lastNotification.notification.request.content.data;
            if (!data && lastNotification.notification.request.content.dataString) {
              data = JSON.parse(lastNotification.notification.request.content.dataString);
            }
            if (data) {
              deviceId = data.deviceId;
            }
          }
          console.log("AMPIO LAST NOTIFICATION", lastNotification, deviceId);
          if (Platform.OS === "ios") {
            let item = await SpotlightSearch.getInitialSearchItem();
            if (item && profilesSettings.get(item)) {
              profile = item;
              profileFound = true;
            }
          }
          if (deviceId) {
            profilesSettings.forEach((v, k) => {
              if (
                v &&
                k &&
                k !== "undefined" &&
                k != "demo" &&
                k != "currentProfile" &&
                v.get("loginData") &&
                v.get("loginData").get("device_id") === deviceId
              ) {
                profile = k;
                profileFound = true;
              }
            });
          }
          if (profileFound) {
            let customData = lastNotification?.notification?.request?.content?.data?.customData;
            if (customData) {
              let customObj = JSON.parse(customData);
              if (customObj.action === "SHOW_OBJECT") {
                let objToShow = {
                  id: customObj.objectId,
                  title: customObj.title,
                };
                if (objToShow) {
                  this.props.changePopupObjectId(objToShow);
                }
              }
            }
          }
        }
      } catch (e) {
        console.error("Erorr fetching last notification", e);
      }
      if (!profileFound) {
        if (globalSettings.get("autoLogin") === true) {
          if (globalSettings.get("autoLoginType")) {
            profile = globalSettings.get("autoLoginType");
          }
          this.props.onChangeCurrentProfile(profile);
        }
      } else {
        this.props.onChangeCurrentProfile(profile);
      }
      !globalSettings.get("sideMenuSetByUser") && this.setSideMenuForTheFirstTimeUsers();

      if (Platform.OS === "web") {
        try {
          const url = await Linking.getInitialURL();
          const obj = Linking.parse(url);

          if (obj.queryParams && obj.queryParams.code) {
            fetchAccessToken(obj.queryParams.code);

            window.history.replaceState(null, "", "");
            this.props.onChangeFromSwitchProfile(true);
            this.checkTypeOfNavigation();
            return;
          } else {
            const profile = obj.queryParams;
            if (profile) {
              this.setState({
                showIndicator: true,
                welcomeScreen: true,
                triedConnectFromNotifiaction: true,
              });
              if (profile.ip && (profile.password || profile.local_token)) {
                if (profile.password) {
                  profile.password = base64.decode(profile.password);
                }
                this.props.onChangeFromSwitchProfile(true);
                this.onConnect("local", null, profile);
                return;
              }
            }
          }
        } catch (e) {
          console.log("Error in Linking", e);
        }
      }

      if (profile && profile != "demo" && profile != "cloud new" && profilesSettings.get(`${profile}`)) {
        let loginDataProps = profilesSettings.get(`${profile}`).get("loginData");
        let loginData = Object.assign({}, this.state.loginData);

        loginData.name = loginDataProps.get("name");
        loginData.login = loginDataProps.get("login");
        loginData.password = loginDataProps.get("password");
        loginData.ip = loginDataProps.get("ip");
        loginData.ip2 = loginDataProps.get("ip2");
        loginData.cloudIp = loginDataProps.get("cloudIp");
        loginData.device_id = loginDataProps.get("device_id");
        loginData.cloudType = loginDataProps.get("cloudType");

        this.setState(
          {
            loginData,
            connectDisabled: false,
            cloudDisable: loginData.cloudIp ? false : true,
            triedConnectFromNotifiaction: true,
          },
          () => {
            this.checkTypeOfNavigation(profileFound);
          }
        );
      } else if (profile && profile != "demo" && profile != "cloud new") {
        this.goToProfiles();
      } else {
        this.setState({
          showIndicator: false,
          welcomeScreen: true,
          triedConnectFromNotifiaction: true,
        });
      }
    }
  }

  //albo z buttona albo automatycznie
  onConnect = async (source, loginDataForNewCloud, connectLoginData) => {
    const { globalSettings, profilesSettings, authData, currentProfile } = this.props;

    const result =
      profilesSettings &&
      profilesSettings.get(`${currentProfile}`) &&
      profilesSettings.get(`${currentProfile}`).get("loginData") &&
      profilesSettings.get(`${currentProfile}`).get("loginData").get("biometics")
        ? await LocalAuthentication.authenticateAsync({ requireConfirmation: false })
        : { success: true };

    if (result && result.success) {
      let loginData = this.state.loginData;
      if (connectLoginData) {
        loginData.name = connectLoginData.name;
        loginData.login = connectLoginData.login;
        loginData.password = connectLoginData.password;
        loginData.ip = connectLoginData.ip;
        loginData.ip2 = connectLoginData.ip2;
        loginData.cloudIp = connectLoginData.cloudIp;
        loginData.device_id = connectLoginData.device_id;
        loginData.cloudType = connectLoginData.cloudType;
        loginData.local_token = connectLoginData.local_token;
        loginData.loginAsAdmin = connectLoginData.loginAsAdmin;
      }
      let connectionType = "local";
      if (source == "demo") {
        this.props.onChangeCurrentProfile("demo");
        this.setState({ showIndicator: true });
        loginData = {
          name: "demo",
          login: "demo",
          password: "demo",
          ip: "192.168.1.36",
          ip2: "demo.ampio.pl",
          cloudIp: "",
          device_id: "",
          cloudType: "",
          //przy WEB zrob cloud
          // cloudIp: "70eefbb7bc6d87078713ff5e9e3e9b07.ampio.pl",
        };
        let lang = Localization.locale.split("-")[0];
        if (lang !== "pl") {
          loginData.login = "demo_eng";
        }
      } else if (source == "supportConnection") {
        this.props.onChangeCurrentProfile("demo");
        this.setState({ showIndicator: true });

        loginData = {
          name: "demo",
          login: connectLoginData.login,
          password: connectLoginData.device_id + connectLoginData.password,
          ip2: "wss://" + md5(connectLoginData.device_id + connectLoginData.password) + ".ampio.pl:443",
          loginAsAdmin: true,
        };
      } else if (source == "cloud") {
        connectionType = "cloud";
      } else if (source === "new_cloud") {
        // CLOUD_CODE
        connectionType = "new_cloud";
      }

      if (!loginData.name) {
        loginData.name = currentProfile;
      }
      this.props.onChangeBlockLoginAutomatically(true);
      !globalSettings.get("forceLandscapeOrientation") &&
        !globalSettings.get("forceInvertedLandscapeOrientation") &&
        Platform.OS !== "web" &&
        this.lockOrientation();
      mqttClient.mqttInit(loginData, connectionType);
    } else {
      this.checkCloudData(authData);
    }
  };

  lockOrientation = async () => {
    let orientation;
    try {
      await ScreenOrientation.getOrientationAsync().then((orientationId) => {
        orientation = ScreenOrientation.Orientation[orientationId];

        ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock[orientation]);
      });
    } catch (error) {
      console.log(error, "error in 	ScreenOrientation.OrientationLock");
    }
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.currentProfile &&
      nextProps.currentProfile != "demo" &&
      nextProps.currentProfile != "new_cloud" &&
      nextProps.profilesSettings.get(`${nextProps.currentProfile}`)
    ) {
      let loginDataProps = nextProps.profilesSettings.get(`${nextProps.currentProfile}`).get("loginData");
      let loginData = Object.assign({}, this.state.loginData);

      loginData.name = loginDataProps.get("name");
      loginData.login = loginDataProps.get("login");
      loginData.password = loginDataProps.get("password");
      loginData.ip = loginDataProps.get("ip");
      loginData.ip2 = loginDataProps.get("ip2");
      loginData.cloudIp = loginDataProps.get("cloudIp");
      loginData.device_id = loginDataProps.get("device_id");
      loginData.cloudType = loginDataProps.get("cloudType");

      this.setState({
        loginData,
        connectDisabled: false,
        cloudDisable: loginData.cloudIp ? false : true,
      });
    } else {
      let loginData = {
        name: "",
        login: "",
        password: "",
        ip: "",
        ip2: "",
        cloudIp: "",
        cloudType: "",
        device_id: "",
      };
      this.setState({ loginData, connectDisabled: true });
    }

    if (nextProps.server.get("serverUpdateInfo") && nextProps.server.get("serverUpdateInfo") != this.state.updateStatus) {
      this.handleUpdateInfo(nextProps.server.get("serverUpdateInfo"));
    }
  }

  fetchWeatherApi = () => {
    let profile = this.props.profilesSettings.get(`${this.props.currentProfile}`);
    let city = null;
    if (profile) {
      city = profile.get("cityForWeather");
    }
    let fetchWeatherApiIfExist = () => {
      if (city) {
        this.props.onfetchWeatherApi(city);
      } else {
        return null;
      }
    };
    fetchWeatherApiIfExist();
  };

  setProperTheme = () => {
    let { globalSettings } = this.props;

    let themeByScheduleEnabled = globalSettings.get("themeByScheduleEnabled");
    let currentTheme = globalSettings.get("primaryTheme") ? globalSettings.get("primaryTheme") : "ampio";
    let themeBySchedule = globalSettings.get("themeBySchedule") ? globalSettings.get("themeBySchedule") : "night";

    let theme = "";
    if (themeByScheduleEnabled == true) {
      let schedule = globalSettings.get("schedule");
      let startSchedule = moment(schedule.get("start")).format("HH:mm");
      let endSchedule = moment(schedule.get("end")).format("HH:mm");

      var currentTime = moment().format("HH:mm");
      if (startSchedule < endSchedule) {
        if (currentTime > startSchedule && currentTime < endSchedule) {
          theme = themeBySchedule;
        } else {
          theme = currentTheme;
        }
      } else {
        if (currentTime > endSchedule && currentTime < startSchedule) {
          theme = currentTheme;
        } else {
          theme = themeBySchedule;
        }
      }
    } else if (themeByScheduleEnabled == false) {
      theme = globalSettings.get("primaryTheme");
    }

    this.context.onThemeChange(theme);
  };

  navigateToApp = async (connectionType) => {
    this.props.onChangeCurrentConnectionPlace(connectionType);

    this.setProperTheme();
    // let weatherShown = this.props.globalSettings.get("weatherShown");
    // if (weatherShown) {
    this.fetchWeatherApi();

    // }
    this.props.onChangeFromSwitchProfile(false);
    this.props.onChangeAppLocation("app");
  };

  requireUpdate = () => {
    this.setState({ requireUpdateVisible: true });
  };
  requireAssociate = () => {
    this.setState({ requireOwnerAssociate: true });
  };
  showNoConnectionModal = async (message) => {
    try {
      await SplashScreen.hideAsync();
    } catch (error) {}

    this.props.onChangeFromSwitchProfile(false);
    this.setState({
      showIndicator: true,
      offlineAlertVisible: true,
      offlineReason: message,
    });
  };

  handleOKoffline = () => {
    this.setState({ offlineAlertVisible: false }, () => {
      this.checkTypeOfNavigation();
    });
  };

  handleOKAssociate = () => {
    this.setState({ requireOwnerAssociate: false });
  };

  handleCloseAssociate = () => {
    this.setState({ requireOwnerAssociate: false });
  };
  handleCloseUpdateRequire = () => {
    this.setState({ requireUpdateVisible: false, showIndicator: false }, () => {
      mqttClient.handleLogOut();
    });
  };
  handleOKUpdateRequire = () => {
    this.setState({ requireUpdateVisible: false });

    this.props.onUpdateInfoReceived("");
    mqttClient.askForServerUpdate("query");
  };

  handleUpdateInfo = (serverUpdateInfo) => {
    let message = "";
    switch (serverUpdateInfo) {
      case "START":
        message = t("account:START_OF_UPDATE");
        break;
      case "UPDATE_OK":
        message = t("account:UPDATE_OK");
        break;
      case "ERROR_RENAME":
        message = t("account:ERROR_RENAME");
        break;
      case "INVALID_MD5":
        message = t("account:INVALID_MD5");
        break;
      case "NO_UPDATE":
        message = t("account:NO_UPDATE");
        break;
      default:
        message = `${serverUpdateInfo}%`;
    }
    this.setState({
      updateStatus: message,
    });
  };

  goToProfiles = () => {
    window.app.onConnect = this.onConnect;
    this.props.navigation.navigate("ProfilesList");
    this.setState({ showIndicator: true, welcomeScreen: true });
  };

  changeNewCloudHost = () => {
    if (mainHost == "cloud.ampio.com") {
      updateHost("staging.ampio.pl");
    } else {
      updateHost("cloud.ampio.com");
    }
    window.app.showToast("New cloud host: " + mainHost);
    this.render();
  };

  render() {
    let { initialLoadingStatus, route, dimensions } = this.props;
    let width = dimensions.get("width");
    let height = dimensions.get("height");

    const temp = width < height ? width : height;
    const smallDimension = temp < 320 ? temp : 320;
    const proportions = smallDimension / 320;

    let { theme } = this.context;
    let { showIndicator, loginData, offlineAlertVisible, requireUpdateVisible, requireOwnerAssociate, updateStatus, offlineReason, welcomeScreen } =
      this.state;

    let allowLocal = true;
    let allowCloud = true;

    if (Platform.OS === "web") {
      if (window.location.protocol == "http:") allowCloud = false;
      if (window.location.protocol == "https:") allowLocal = false;
    }

    if (this.props.fromSwitchProfile) {
      showIndicator = true;
    }

    const percentage = initialLoadingStatus && initialLoadingStatus.get("percentage") >= 0 ? Number(initialLoadingStatus.get("percentage")) : 0;

    let progressBarContent = initialLoadingStatus ? (
      <View style={styles.progressContainer}>
        <Text style={{ textAlign: "center", paddingBottom: 10, color: "white" }}>{initialLoadingStatus.get("status")}</Text>
        <Progress.Bar progress={percentage / 100} width={null} color={theme.AMPIO_COLOR} unfilledColor={theme.SWITCH_OFF_BACKGROUND} />
      </View>
    ) : null;
    return (
      <ImageBackground
        source={welcomeScreen ? (showIndicator ? backgroundImageLoading : backgroundImage) : null}
        style={styles.backgroundImage}
        onLayout={({ nativeEvent }) => {
          let width = nativeEvent.layout.width;
          let height = nativeEvent.layout.height;
          if (width !== 0 && height !== 0) {
            let newDimensions = calculateDimmenions(width, height);

            this.props.changeDimensions(newDimensions);
          }
        }}
      >
        <View
          style={
            welcomeScreen
              ? [
                  styles.logoContainer,
                  {
                    backgroundColor: showIndicator ? null : "#fff",
                    marginTop: showIndicator ? 100 : null,
                  },
                ]
              : styles.logoContainerSolo
          }
        >
          <Image
            source={welcomeScreen ? (showIndicator ? logoImageBlack : logoImage) : logoImageBlack}
            style={{
              width: 300,
              resizeMode: "contain",
              height: 100,
              marginTop: 50,
            }}
          />
        </View>

        {showIndicator ? (
          <View style={[styles.containerProgress, { alignItems: "center" }]}>
            {progressBarContent}
            <SkottieLoader style={{ width: 40, height: 40, margin: 5 }} />
            {/* <ActivityIndicator size="large" color="#fff" animating={true} style={{ paddingVertical: 5, color: "#fff" }} /> */}
            <Pressable>
              <ButtonPrimary
                onPress={async () => {
                  setTimeout(async () => {
                    mqttClient.handleLogOut();
                    await this.props.clearStatesData();
                    this.goToProfiles();
                  }, 1);
                }}
                onLongPress={() => {
                  mqttClient.die();
                }}
              >
                {t("CANCEL")}
              </ButtonPrimary>
            </Pressable>
            <Text style={{ paddingVertical: 10, color: "#fff" }}>{updateStatus}</Text>
          </View>
        ) : null}
        {welcomeScreen && !showIndicator ? (
          <View style={styles.container}>
            <View style={[styles.welcomeContainer, { width: smallDimension, height: smallDimension }]}>
              <Image
                source={rectangle}
                resizeMode={"contain"}
                style={{
                  width: smallDimension * 0.9,
                  marginLeft: 20,
                }}
              />
              <View
                style={{
                  position: "absolute",
                }}
              >
                <Text
                  style={{
                    color: "#fff",
                    fontWeight: "bold",
                    fontSize: 24,
                    marginVertical: 10,
                    marginRight: smallDimension * 0.25,
                    width: smallDimension * 0.8,
                  }}
                >
                  {t("WELCOME_IN_APP")}
                </Text>
                <Text
                  style={{
                    color: "#fff",
                    maxWidth: 320,
                  }}
                >
                  {t("WELCOME_CONTROL")}
                </Text>
              </View>

              <TouchableOpacity
                style={{
                  flexDirection: "row",
                  position: "absolute",
                  paddingTop: 187 * proportions + 10,
                  left: 20,
                }}
                onPress={() => {
                  if (this.onConnect) {
                    this.onConnect("demo");
                  }
                }}
              >
                <Text style={{ fontWeight: "bold", color: "#fff", marginRight: 10 }}>{t("WELCOME_SHOW_DEMO")}</Text>
                <FontAwesome color={theme.AMPIO_COLOR} size={20} name={"angle-double-right"} />
              </TouchableOpacity>
            </View>

            <View style={styles.buttonsContainer}>
              <SecondaryColorText style={{ fontSize: 18, textAlign: "center", color: "#fff" }}>{t("WELCOME_SCREEN_INFO")}</SecondaryColorText>
              {/* CLOUD_CODE */}
              {loginData && allowCloud ? <CloudIntegration onConnect={this.onConnect} goToProfiles={this.goToProfiles} source="init" /> : null}
              {allowLocal && (
                <ButtonSecondary
                  style={[styles.buttonStyles, { backgroundColor: "#fff" }]}
                  textStyle={{ color: "#808080" }}
                  onPress={this.goToProfiles}
                >
                  {t("CONNECT_WITHOUT_CLOUD")}
                </ButtonSecondary>
              )}
            </View>
            <View style={styles.bottomContainer}>
              <Text style={{ color: "#888" }} onPress={this.changeNewCloudHost}>
                Ver. 2.0.5
              </Text>
            </View>
          </View>
        ) : null}
        {offlineAlertVisible && (
          <ModalSimple isVisible={offlineAlertVisible} title={"CANNOT_CONNECT_TO_SERVER"} description={offlineReason} onOK={this.handleOKoffline} />
        )}
        {requireUpdateVisible && (
          <ModalSimple
            isVisible={requireUpdateVisible}
            title={"OUTDATE_SERVER"}
            description={"OUTDATE_SERVER_DESC"}
            onOK={this.handleOKUpdateRequire}
            onClose={this.handleCloseUpdateRequire}
          />
        )}
        {requireOwnerAssociate && (
          <ModalSimple
            isVisible={requireOwnerAssociate}
            title="NEED TO ASSOCIATE"
            description="DO YOU WANT?"
            onOK={this.handleOKAssociate}
            onClose={this.handleCloseAssociate}
          />
        )}
      </ImageBackground>
    );
  }
}

const styles = StyleSheet.create({
  backgroundImage: {
    width: "100%",
    height: "100%",
    // justifyContent: "center",
    alignItems: "center",

    // flex: 1
    // flex: 1
  },
  logoContainer: {
    height: 200,
    // flex: 1,
    //marginTop: 100,
    justifyContent: "center",
    alignItems: "center",
    paddingVertical: 50,
    width: "100%",
  },
  logoContainerSolo: {
    height: "100%",
    backgroundColor: "#000",
    // flex: 1,
    justifyContent: "center",
    alignItems: "center",
    paddingVertical: 50,
    width: "100%",
  },
  container: {
    width: "90%",
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
    // paddingVertical: 100
    // marginBottom: 100,
  },
  containerProgress: {
    width: "90%",
    marginTop: 200,
    justifyContent: "center",
    alignItems: "center",
  },
  welcomeContainer: {
    flex: 1,
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    marginHorizontal: 20,
  },
  buttonsContainer: {
    alignItems: "center",
    justifyContent: "center",
    height: "50%",
    flex: 1,
    width: "100%",
    marginTop: 60,
  },
  mainButtons: {
    // height: '50%',
    flex: 1,
    width: "80%",
    alignItems: "center",
  },
  textConnect: {
    color: "#fff",
    fontSize: 16,
    // textAlign: "center"
  },
  textProfile: {
    fontSize: 16,
  },
  buttonStyles: {
    width: 250,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 20,
    height: 40,
    margin: 10,
  },

  bottomContainer: {
    bottom: 10,
    width: "100%",
  },
  progressContainer: {
    width: "90%",
  },
  progressBar: {
    // margin: 10,
    borderColor: "#ccc",
    borderWidth: 1,
    backgroundColor: "#fff",
    height: 10,
    width: "100%",
    borderRadius: 10,
  },
});

const mapStateToProps = (state) => {
  return {
    authData: state.cloudData ? state.cloudData.get("authData") : null,
    currentProfile: state.profilesSettings.get("currentProfile"),
    profilesSettings: state.profilesSettings,
    globalSettings: state.globalSettings,
    server: state.statesData.get("server"),
    dimensions: state.statesData.get("dimensions"),
    blockLoginAutomatically: state.statesData.get("blockLoginAutomatically"),
    fromSwitchProfile: state.statesData.get("fromSwitchProfile"),
    initialLoadingStatus: state.statesData.get("initialLoadingStatus"),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    authDataReceived: (authData) => dispatch(authDataReceived(authData)),
    clearStatesData: () => dispatch(clearStatesData()),
    changePopupObjectId: (objectId) => dispatch(changePopupObjectId(objectId)),
    associationsDataReceived: (associationsData) => dispatch(associationsDataReceived(associationsData)),
    onfetchWeatherApi: (city) => dispatch(fetchWeatherApi(city)),
    onChangeAppLocation: (appLocation) => dispatch(changeAppLocation(appLocation)),
    onChangeCurrentConnectionPlace: (value) => dispatch(changeCurrentConnectionPlace(value)),
    onChangeCurrentProfile: (profileName) => dispatch(changeCurrentProfile(profileName)),
    changeSideMenuVisibility: (sideMenuVisible) => dispatch(changeSideMenuVisibility(sideMenuVisible)),
    onUpdateInfoReceived: (serverUpdateInfo) => dispatch(serverUpdateInfoReceived(serverUpdateInfo)),
    onChangeBlockLoginAutomatically: (block) => dispatch(changeBlockLoginAutomatically(block)),
    onChangeFromSwitchProfile: (value) => dispatch(changeFromSwitchProfile(value)),
    changeDimensions: (value) => dispatch(changeDimensions(value)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InitialScreen);
