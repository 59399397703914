import React, { Component } from "react";
import {
  View,
  TouchableOpacity,
  Text,
  Platform,
  ScrollView,
} from "react-native";
import { connect } from "react-redux";
import { t } from "../../../../services/i18n";
import { Feather } from "@expo/vector-icons";
import { KeyboardAvoidingView } from "react-native";
import { saveOrder } from "../../../../store/actions";
import DraggableFlatList from "react-native-draggable-flatlist";
import DraggableItem from "./DraggableItem";
import { ThemeContext } from "../../../../../ThemeContext";

import {
  ComponentImage,
  ComponentHeaderText,
  ComponentContainer,
  PrimaryColorText,
  ScreenView,
  TitleWithLines,
} from "../../../../components/UI/styledComponents";

import {
  devicesForGroups,
  scenesForGroups,
  groupsForGroups,
} from "../../../../utils";

class Devices_Order_PersonalizationScreen extends Component {
  static contextType = ThemeContext;
  state = {
    devicesList: [],
    groupsList: [],
    scenesList: [],
  };

  setData = () => {
    const {
      devices,
      group_devices,
      groups,
      scenes,
      order,
      globalSettings,
      params_devices,
    } = this.props;
    const { params } = this.props.route;
    const groupId = params.itemId;
    const deviceIdShown = globalSettings.get("deviceIdShown");

    const devicesList = [];
    const groupsList = [];
    const scenesList = [];
    let listOfDevices = devicesForGroups(group_devices, groupId, order);
    listOfDevices.map((group_device) => {
      if (devices.get(group_device.get("id_obiektu"))) {
        let device = devices.get(group_device.get("id_obiektu"));

        let powiazane = params_devices.get(group_device.get("id_obiektu"))
          ? params_devices.get(group_device.get("id_obiektu")).get("powiazane")
          : "";
        let powiazaneArrayWithPossibleCustom = powiazane
          ? powiazane.split(",")
          : [];

        powiazaneArrayWithPossibleCustom.map((el, index) => {
          el = el.split(":").reverse();
          let deviceFromCustom = devices.get(el[0]);
          if (
            deviceFromCustom &&
            deviceFromCustom.get("typ_komponentu") != "custom" &&
            !deviceFromCustom.get("bezpieczne")
          ) {
            devicesList.push({
              key: `${deviceFromCustom.get("id")}`, // For example only -- don't use index as your key!
              label: `${deviceFromCustom.get("opis_menu")}`,
              customWidth: group_device.get("customWidth"),
              deviceIdShown: deviceIdShown,
            });
          }
        });

        devicesList.push({
          key: `${device.get("id")}`, // For example only -- don't use index as your key!
          label: `${device.get("opis_menu")}`,
          customWidth: group_device.get("customWidth"),
          deviceIdShown: deviceIdShown,
        });
      }
    });

    let filteredGroups = groupsForGroups(groups, groupId, order);
    filteredGroups
      ? filteredGroups.forEach((group, index) => {
          groupsList.push({
            key: `${group.get("id")}`, // For example only -- don't use index as your key!
            label: `${group.get("opis_menu")}`,
          });
        })
      : null;

    //scenes
    let filteredScenes = scenesForGroups(scenes, groupId, order);
    filteredScenes
      ? filteredScenes.forEach((scene, index) => {
          scenesList.push({
            key: `${scene.get("sceneName")}`, // For example only -- don't use index as your key!
            label: `${scene.get("sceneName")}`,
          });
        })
      : null;

    this.setState({ devicesList, groupsList, scenesList });
  };
  componentDidMount() {
    this.setData();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.groups != this.props.groups ||
      prevProps.groups != this.props.groups ||
      prevProps.scenes != this.props.scenes
    ) {
      this.setData();
    }
  }

  componentWillUnmount() {
    const { params } = this.props.route;
    const groupId = params.itemId;
    const { devicesList, groupsList, scenesList } = this.state;

    let deviceOrderArray = devicesList.map((el) => ({
      id: el.key,
      customWidth: el.customWidth,
    }));
    let groupOrderArray = groupsList.map((el) => el.key);
    let sceneOrderArray = scenesList.map((el) => el.key);
    let order = {
      groups: groupOrderArray,
      devices: deviceOrderArray,
      scenes: sceneOrderArray,
    };
    this.props.onSaveOrder(order, groupId.toString());
  }

  renderGroup = ({ item, index, drag, isActive }) => {
    const { theme } = this.context;

    return (
      <DraggableItem
        navigateToGroup={() => {
          this.props.navigation.push("Devices_Order_Personalization", {
            itemId: item.key,
            placeName: item.label,
          });
        }}
        theme={theme}
        item={item}
        index={index}
        drag={drag}
        isActive={isActive}
        type={"group"}
      />
    );
  };

  onChangeWidth = (newWidth, key) => {
    let devicesList = [...this.state.devicesList];

    let elIndex = devicesList.findIndex((x) => x.key == key);
    devicesList[elIndex].customWidth = newWidth;
    this.setState({ devicesList });
  };

  renderDevice = ({ item, index, drag, isActive }) => {
    const { theme } = this.context;
    return (
      <DraggableItem
        onChangeWidth={this.onChangeWidth}
        theme={theme}
        item={item}
        index={index}
        drag={drag}
        isActive={isActive}
        type={"device"}
      />
    );
  };
  renderScene = ({ item, index, drag, isActive }) => {
    const { theme } = this.context;

    return (
      <DraggableItem
        theme={theme}
        item={item}
        index={index}
        drag={drag}
        isActive={isActive}
        type={"scene"}
      />
    );
  };

  render() {
    const { route, globalSettings } = this.props;
    const { params } = route;
    const itemId = params.itemId;

    const { devicesList, groupsList, scenesList } = this.state;
    const { theme } = this.context;
    return (
      <View
        style={{
          flex: 1,
          backgroundColor: theme.APP_BACKGROUND_COLOR,
          padding: 5,
        }}
      >
        {devicesList && devicesList.length ? (
          <View style={{ flex: 1, marginBottom: 30 }}>
            <TitleWithLines textInside={t("DEVICES")} />
            <DraggableFlatList
              data={devicesList}
              renderItem={this.renderDevice}
              keyExtractor={(item, index) => `draggable-item-${item.key}`}
              onDragEnd={({ data }) => this.setState({ devicesList: data })}
            />
          </View>
        ) : null}

        {groupsList && groupsList.length ? (
          <View style={{ flex: 1 }}>
            <TitleWithLines textInside={t("GROUPS")} />
            <DraggableFlatList
              data={groupsList}
              renderItem={this.renderGroup}
              keyExtractor={(item, index) => `draggable-item-${item.key}`}
              onDragEnd={({ data }) => this.setState({ groupsList: data })}
            />
          </View>
        ) : null}
        {scenesList && scenesList.length ? (
          <View style={{ flex: 1 }}>
            <TitleWithLines textInside={t("SCENES")} />
            <DraggableFlatList
              data={scenesList}
              renderItem={this.renderScene}
              keyExtractor={(item, index) => `draggable-item-${item.key}`}
              onDragEnd={({ data }) => this.setState({ scenesList: data })}
            />
          </View>
        ) : null}
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  let currentProfile = state.profilesSettings.get("currentProfile");

  return {
    group_devices: state.smartHomeData.get(currentProfile).get("group_devices"),
    params_devices: state.smartHomeData
      .get(currentProfile)
      .get("params_devices"),
    globalSettings: state.globalSettings,
    devices: state.smartHomeData.get(currentProfile).get("devices"),
    groups: state.smartHomeData.get(currentProfile).get("groups"),
    scenes: state.smartHomeData.get(currentProfile).get("scenes"),
    order: state.profilesSettings.get(currentProfile).get("order"),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSaveOrder: (order, groupId) => dispatch(saveOrder(order, groupId)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Devices_Order_PersonalizationScreen);
