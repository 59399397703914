import React, { useRef } from "react";
import PropTypes from "prop-types";
import { AppState, Image, StyleSheet, Text, TouchableOpacity, View } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { FontAwesome } from "@expo/vector-icons";
import { useTheme } from "../../../../ThemeContext";
import { t } from "../../../services/i18n";
import { connect } from "react-redux";
import AppleMusic from "@raw2dd4/react-native-apple-music";
import { changeSoundStatus } from "../../../store/actions";
import mqttClient from "../../../services/mqtt";

import { TextMarquee } from "../../../components/UI/TextMarqiuee";

const BarMusicPlayer = ({
  song,
  soundStatus,
  changeSoundStatus,
  selectedSoruces,
  selectedSorucesState,
  playerInfo,
  queueInfo,
  type,
  detectedOutputs,
}) => {
  const navigation = useNavigation();

  if (!selectedSoruces) selectedSoruces = selectedSorucesState;

  const { theme } = useTheme();
  const [last, setLast] = React.useState(null);
  const [pause, setPause] = React.useState(false);
  const [track, setTrack] = React.useState({});
  const otIsPlaying = playerInfo && (playerInfo.state === "play" || playerInfo.state === "pause" || playerInfo.state === "stop") && detectedOutputs;

  const otIsPlayingAndSelected = otIsPlaying && detectedOutputs.find((output) => output.selected);

  const current = playerInfo && queueInfo && queueInfo.items ? queueInfo.items.find((item) => item.id === playerInfo.item_id) : null;

  const playingLast = last && playerInfo && last.id === playerInfo.item_id;
  const playingFirst = queueInfo && queueInfo.items && queueInfo.items.length > 0 && playerInfo && queueInfo.items[0].id === playerInfo.item_id;

  React.useEffect(() => {
    let interval = null;
    if (pause) {
      interval = setInterval(() => {
        if (pause) {
          changeSoundStatus(null);
        }
      }, 5000);
    } else {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [pause]);

  React.useEffect(() => {
    if (soundStatus) {
      if (soundStatus.get("isPlaying")) {
        setPause(false);
      } else {
        setPause(true);
      }
    }
  }, [soundStatus]);

  React.useEffect(() => {
    if (playerInfo && queueInfo) {
      const nowPlaying = queueInfo && queueInfo.items ? queueInfo.items.find((item) => item.id === playerInfo.item_id) : null;

      if (nowPlaying && playerInfo.item_id !== nowPlaying.id) {
        mqttClient.requestOt("otQueue", "GET", `/api/queue`);
      }
    }
    if (queueInfo && queueInfo.items && queueInfo.count) {
      const last = queueInfo.items.find((item) => item.position === queueInfo.count - 1);
      setLast(last);
    }
  }, [playerInfo, queueInfo]);

  const handlePreviousTrack = async () => {
    if (playerInfo && current && queueInfo) {
      const previous = queueInfo.items
        .filter((item) => item.position < current.position) // Filter items that are before the current position
        .sort((a, b) => b.position - a.position) // Sort them in descending order
        .find(() => true); // Get the first item in the sorted array
      console.log("PLaing prev", previous);
      mqttClient.requestOt("pause", "PUT", "/api/player/play?item_id=" + previous.id);
    } else if (playerInfo) {
      mqttClient.requestOt("pause", "PUT", "/api/player/previous");
    }
  };

  const handleNextTrack = async () => {
    if (playerInfo && current && queueInfo) {
      const next = queueInfo.items
        .filter((item) => item.position > current.position) // Filter items that are after the current position
        .sort((a, b) => a.position - b.position) // Sort them in ascending order
        .find(() => true); // Get the first item in the sorted array

      mqttClient.requestOt("pause", "PUT", "/api/player/play?item_id=" + next.id);
    } else if (playerInfo) {
      mqttClient.requestOt("pause", "PUT", "/api/player/next");
    }
  };

  React.useEffect(() => {
    if (soundStatus && queueInfo && queueInfo.items && soundStatus.get("image") && soundStatus.get("uri")) {
      let current = queueInfo.items.find((item) => !item.artwork_url && item.uri === soundStatus.get("uri"));

      if (current && !current.artwork_url) {
        mqttClient.requestOt(
          "playUri",
          "PUT",
          "/api/queue/items/" +
            current.id +
            "?artist=" +
            encodeURIComponent(soundStatus.get("title")) +
            "&title=" +
            encodeURIComponent(soundStatus.get("subtitle")) +
            "&artwork_url=" +
            encodeURIComponent(soundStatus.get("image"))
        );
      }
    }
    if (queueInfo && queueInfo.items && track && track.artwork && track.url) {
      let current = queueInfo.items.find((item) => !item.artwork_url && item.uri === track.url);

      if (current && !current.artwork_url) {
        mqttClient.requestOt(
          "playUri",
          "PUT",
          "/api/queue/items/" +
            current.id +
            "?artist=" +
            encodeURIComponent(track.artist) +
            "&title=" +
            encodeURIComponent(track.title) +
            "&artwork_url=" +
            encodeURIComponent(track.artwork)
        );
      }
    }
  }, [soundStatus, queueInfo]);

  const _onClick = async (isPlaying) => {
    let controlledDevice = false;
    if (selectedSoruces && Object.keys(selectedSoruces).length > 0) {
      Object.keys(selectedSoruces).map((key) => {
        if (key !== "local") {
          if (selectedSoruces[key]) {
            let message = `/api/set/${key}/setIpRadioButton/pause`;
            mqttClient.stateChangeToSend(message, key);
            controlledDevice = true;
          }
        }
      });
      if (controlledDevice) {
        setPause(true);
      }
    }

    if (soundStatus && soundStatus.get("type") === "LOCAL_APPLE") {
      if (soundStatus.get("isPlaying")) {
        AppleMusic.pause();
        setPause(true);
      } else {
        AppleMusic.play();
      }
    }

    if (playerInfo && playerInfo.state === "play") {
      mqttClient.requestOt("pause", "PUT", "/api/player/pause");
    } else if (playerInfo) {
      mqttClient.requestOt("play", "PUT", "/api/player/play");
    }
  };

  let artWork = null;
  let title = null;
  let artist = null;

  if (selectedSoruces && selectedSoruces["local"]) {
    if (track) {
      artWork = track.artwork;
      title = track.title;
      artist = track.artist;
    }
  } else if (current) {
    artWork = current.artwork_url;
    title = current.title;
    artist = current.artist;
  }

  if (soundStatus && !title && !artist) {
    artWork = soundStatus.get("image");
    title = soundStatus.get("title");
    artist = soundStatus.get("subtitle");
  }
  if (current && !title && !artist) {
    title = current.title;
    artist = current.artist;
    artWork = current.artwork_url;
  }

  if (type === "current") {
    return soundStatus || otIsPlaying ? (
      <View style={{ alignItems: "center", justifyContent: "center", width: "100%", padding: 20 }}>
        {artWork ? <Image source={{ uri: artWork }} style={{ width: 200, height: 200, marginBottom: 10 }} resizeMode="contain" /> : null}

        <TextMarquee duration={10000} loop bounce repeatSpacer={50} marqueeDelay={500}>
          <Text style={{ fontSize: 24, fontWeight: "bold", color: theme.TAB_BAR_TEXT_COLOR_HIGHLITED }}>{title}</Text>
        </TextMarquee>

        <TextMarquee duration={10000} loop bounce repeatSpacer={50} marqueeDelay={500}>
          <Text style={{ fontSize: 20, color: theme.TAB_BAR_TEXT_COLOR }}>{artist}</Text>
        </TextMarquee>

        {/* <Slider
          style={{ width: "100%", marginVertical: 20 }}
          minimumValue={0}
          minimumTrackTintColor="#1f6ee8"
          maximumTrackTintColor="#c7c7c7"
          onSlidingComplete={handleSliderChange}
        /> */}

        <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%", paddingTop: 10 }}>
          <TouchableOpacity onPress={handlePreviousTrack} disabled={playingFirst} style={{ opacity: playingFirst ? 0.2 : 1 }}>
            <FontAwesome name="backward" size={28} color={theme.TAB_BAR_TEXT_COLOR} />
          </TouchableOpacity>

          {otIsPlaying ? (
            <TouchableOpacity
              onPress={() => {
                _onClick(playerInfo.state === "play");
              }}
            >
              <FontAwesome color={theme.TAB_BAR_TEXT_COLOR} name={playerInfo.state === "play" ? "pause-circle" : "play-circle"} size={35} />
            </TouchableOpacity>
          ) : null}
          <TouchableOpacity onPress={handleNextTrack} disabled={playingLast} style={{ opacity: playingLast ? 0.2 : 1 }}>
            <FontAwesome name="forward" size={28} color={theme.TAB_BAR_TEXT_COLOR} />
          </TouchableOpacity>
        </View>
      </View>
    ) : null;
  } else {
    return soundStatus || otIsPlayingAndSelected ? (
      <TouchableOpacity
        activeOpacity={1}
        onPress={() => {
          navigation.navigate(`CurrentMusicScreen`);
        }}
        style={[
          styles.container,
          {
            backgroundColor: theme.TAB_BAR_BACKGROUND_COLOR,
            borderBottomColor: theme.TAB_BAR_BACKGROUND_COLOR,
          },
        ]}
      >
        {artWork ? (
          <Image source={{ uri: artWork }} resizeMode="stretch" style={{ width: 30, height: 30, marginLeft: 5 }} width={30} height={30} />
        ) : null}
        <View style={{ flex: 1 }}>
          <View style={[styles.containerSong, {}]}>
            <TextMarquee duration={10000} loop bounce repeatSpacer={50} marqueeDelay={500}>
              <Text style={[styles.artist, { color: theme.TAB_BAR_TEXT_COLOR_HIGHLITED }]}>{title}</Text>
            </TextMarquee>
          </View>
          <View
            style={[
              {
                alignItems: "center",
                flexDirection: "row",
                justifyContent: "center",
                marginTop: 4,
              },
            ]}
          >
            <TextMarquee duration={10000} loop bounce repeatSpacer={50} marqueeDelay={500}>
              <Text style={[styles.device, { color: theme.TAB_BAR_TEXT_COLOR }]}>{artist}</Text>
            </TextMarquee>
          </View>
        </View>

        {soundStatus ? (
          <TouchableOpacity
            hitSlop={{ bottom: 10, left: 10, right: 10, top: 10 }}
            onPress={() => {
              _onClick(!pause);
            }}
            style={[styles.containerIcon]}
          >
            <FontAwesome color={theme.TAB_BAR_TEXT_COLOR} name={!pause ? "pause-circle" : "play-circle"} size={28} />
          </TouchableOpacity>
        ) : otIsPlaying ? (
          <TouchableOpacity
            hitSlop={{ bottom: 10, left: 10, right: 10, top: 10 }}
            onPress={() => {
              _onClick(playerInfo.state === "play");
            }}
            style={[styles.containerIcon]}
          >
            <FontAwesome color={theme.TAB_BAR_TEXT_COLOR} name={playerInfo.state === "play" ? "pause-circle" : "play-circle"} size={28} />
          </TouchableOpacity>
        ) : null}
      </TouchableOpacity>
    ) : null;
  }
};

const styles = StyleSheet.create({
  container: {
    alignSelf: "center",
    borderBottomWidth: StyleSheet.hairlineWidth,
    flexDirection: "row",
    justifyContent: "space-between",
    paddingVertical: 8,
    width: "100%",
  },
  containerIcon: {
    alignItems: "center",
    justifyContent: "center",
    width: 50,
  },
  containerSong: {
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "center",
    overflow: "hidden",
  },
  title: {
    fontSize: 12,
  },
  artist: {
    fontSize: 12,
  },
  device: {
    fontSize: 10,
    marginLeft: 4,
    textTransform: "uppercase",
  },
});
const mapStateToProps = (state) => {
  return {
    soundStatus: state.statesData.get("soundStatus"),
    playerInfo: state.statesData.get("playerInfo"),
    queueInfo: state.statesData.get("queueInfo"),
    detectedOutputs: state.statesData.get("detectedOutputs"),
    selectedSorucesState: state.statesData.get("selectedSoruces"),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeSoundStatus: (status) => dispatch(changeSoundStatus(status)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BarMusicPlayer);
