import React, { Component } from "react";
import { Text, View, TouchableOpacity, StyleSheet, ActivityIndicator, Platform } from "react-native";

import { fromJS } from "immutable";
import { t } from "../../../services/i18n";
import mqttClient from "../../../services/mqtt";
import { connect } from "react-redux";

import { ComponentHeaderText, StyledIcon, SliderStyled, PrimaryColorText, ComponentContainer } from "../../UI/styledComponents";
import FavouriteStationsModal from "./FavouriteStationsModal";
import AddFavouriteStationModal from "./AddFavouriteStationModal";
import RadioButtonsBar from "./RadioButtonsBar";
import PickerSelect from "../../UI/PickerSelect";
import ModalWithContent from "../../UI/ModalWithContent";
import * as Haptics from "expo-haptics";
import { addFavouriteColorToRGBModal, removeFavouriteColorFromRGBModal } from "../../../store/actions";
import { Slider } from "@miblanchard/react-native-slider";
import { kelvinToRgb } from "../led/LedWWComponent";
import ButtonsSelector from "../../UI/ButtonsSelector";
import { SkottieLoader } from "../../animatedComponents/Loader";

const modes = [
  {
    label: "Input 1",
    value: 0,
  },
  {
    label: "Input 2",
    value: 1,
  },
  {
    label: "Radio FM",
    value: 2,
  },
];

const placeholder = {
  label: "Select input...",
  value: null,
};

let changeVolumeToPercent = (volumeValue) => {
  let percentValue = Math.round((volumeValue * 100) / 56);
  return `${percentValue} %`;
};

class RadioComponent extends Component {
  constructor(props) {
    super(props);
    this.timeOut = null;
    this.state = {
      favouritesListDialogVisible: false,
      addFavouriteStationDialogVisible: false,
      blockProps: false,
      isLoading: true,
      favouriteStations: null,
      volume: 0,
      bass: 0,
      mid: 0,
      treble: 0,
      mode: -1,
      selectedStationInScene: -1,
      settingsDialogVisible: false,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!nextProps.deviceInScene && !prevState.blockProps) {
      return {
        volume: Number(nextProps.deviceState.get("state")),
        bass: Number(nextProps.deviceState.get("bass")),
        mid: Number(nextProps.deviceState.get("mid")),
        treble: Number(nextProps.deviceState.get("treble")),
        mode: Number(nextProps.deviceState.get("mode")),
      };
    } else if (nextProps.deviceInScene && !prevState.deviceFromScene) {
      return {
        deviceFromScene: true,
        volume: Number(nextProps.deviceInScene.get("value")),
        mode: Number(nextProps.deviceInScene.get("param1")),
        selectedStationInScene: Number(nextProps.deviceInScene.get("param2")),
      };
    }

    return null;
  }

  componentDidMount() {
    const { deviceState } = this.props;

    if (deviceState.get("favouriteStations")) {
      this.setState({
        favouriteStations: deviceState.get("favouriteStations"),
        isLoading: false,
      });
    } else {
      mqttClient.askForRadioFavorite(this.props.device.get("id"));
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.noConnectionBarVisible == true && this.props.noConnectionBarVisible == false) {
      mqttClient.askForRadioFavorite(this.props.device.get("id"));
    }
    const { deviceState, noConnectionBarVisible, noConnectionCriticalError } = this.props;
    if (deviceState.get("favouriteStations") != prevProps.deviceState.get("favouriteStations")) {
      this.setState({
        favouriteStations: deviceState.get("favouriteStations"),
        isLoading: false,
      });
    }
    //this is because new states are overriding fav radios
  }

  componentWillUnmount() {
    clearTimeout(this.timeOut);

    this.setState({
      favouritesListDialogVisible: false,
      addFavouriteStationDialogVisible: false,
    });
  }

  showFavouritesListDialog = () => {
    this.setState({ favouritesListDialogVisible: true });
  };

  showAddFavouriteStationDialog = () => {
    this.setState({ addFavouriteStationDialogVisible: true });
  };

  onAddFavouriteStation = (name, num) => {
    const { device, deviceState } = this.props;

    let object = { name: name, freq: deviceState.get("freq"), num: num };
    let favouriteStations = this.state.favouriteStations;

    if (favouriteStations) {
      if (favouriteStations.some((el) => el.get("num") == num)) {
        let index = favouriteStations.findIndex((x) => x.get("num") == num);
        favouriteStations = favouriteStations.set(index, fromJS(object));
      } else {
        favouriteStations = favouriteStations.push(fromJS(object));
      }

      mqttClient.addFavouriteStationRadio(object, device.get("id"));

      this.setState({
        addFavouriteStationDialogVisible: false,
        favouriteStations,
      });
    }
  };

  increaseOne = () => {
    if (this.state.volume < 55) {
      this.setState(
        (prevState) => ({
          volume: prevState.volume + 1,
          blockProps: true,
        }),
        () => this.sendMessage(this.state.volume, "volume")
      );
    }
  };

  decreaseOne = () => {
    if (this.state.volume > 0) {
      this.setState(
        (prevState) => ({
          volume: prevState.volume - 1,
          blockProps: true,
        }),
        () => this.sendMessage(this.state.volume, "volume")
      );
    }
  };

  onSlidingStartHandler = () => {
    this.setState({ blockProps: true });
  };

  onSlidingChangeHandler = (volume) => {
    if (!this.state.blockProps) {
      this.setState({ blockProps: true });
    }
    this.sendMessage(volume, "volume", true);
  };

  onSlidingCompleteHandler = (volume) => {
    this.setState({ volume: Number(volume) }, () => this.sendMessage(volume, "volume"));
  };

  onModeChange = (mode) => {
    this.setState({ mode, blockProps: true }, () => {
      if (Platform.OS === "android" || Platform.OS === "web") {
        this.sendMessage(this.state.mode, "mode");
      }
    });
  };

  onModeClose = () => {
    if (Platform.OS === "ios") {
      this.sendMessage(this.state.mode, "mode");
    }
  };

  runTimeout = () => {
    let updateState = () => {
      const { deviceState } = this.props;
      this.setState({
        blockProps: false,
        volume: Number(deviceState.get("state")),
        mode: Number(deviceState.get("mode")),
      });
    };
    this.timeOut = setTimeout(() => {
      this.timeOut = 0;
      updateState();
    }, 5000);
  };

  clearTimeoutMine = () => {
    if (this.timeOut) {
      clearTimeout(this.timeOut);
      this.timeOut = 0;
    }
    this.runTimeout();
  };

  sendMessage = (value, type, fromSliderMoving) => {
    const { device, deviceInScene, activeSceneControl } = this.props;

    !deviceInScene && !fromSliderMoving && this.clearTimeoutMine();

    let message = "";
    if (type == "volume") {
      message = `/api/set/${device.get("id")}/setVolume/${value}`;
    } else if (type == "mode") {
      message = `/api/set/${device.get("id")}/setInput/${value}`;
    } else if (type == "seek") {
      message = `/api/set/${device.get("id")}/setSeek/${value}`;
    } else if (type == "playFav") {
      message = `/api/set/${device.get("id")}/setStation/${value}`;
    } else if (type == "removeFav") {
      message = `/api/set/${device.get("id")}/removeStation/${value}`;
    } else if (type == "bass") {
      message = `/api/set/${device.get("id")}/setBass/${value}`;
    } else if (type == "mid") {
      message = `/api/set/${device.get("id")}/setMid/${value}`;
    } else if (type == "treble") {
      message = `/api/set/${device.get("id")}/setTreble/${value}`;
    }
    if (type != "setFavInScenes") {
      activeSceneControl != false && mqttClient.stateChangeToSend(message, device.get("id"));
    }
    if (deviceInScene) {
      let infoToUpdate = deviceInScene;
      infoToUpdate = infoToUpdate.set("value", this.state.volume);
      infoToUpdate = infoToUpdate.set("param1", this.state.mode);
      infoToUpdate = infoToUpdate.set("param2", this.state.selectedStationInScene);

      this.props.changeScene(infoToUpdate, deviceInScene.get("temporary_id"));
    }
  };

  onActionFromButtons = (action) => {
    if (action == "showFavouritesListDialog") {
      this.showFavouritesListDialog();
    } else if (action == "showAddFavouriteStationDialog") {
      this.showAddFavouriteStationDialog();
    } else {
      this.sendMessage(action, "seek");
    }
  };

  onActionFromStationList = (num, action) => {
    this.sendMessage(num, action);
    if (action == "playFav") {
      this.setState({ favouritesListDialogVisible: false });
    } else if (action == "removeFav") {
      let favouriteStations = this.state.favouriteStations;
      let index = favouriteStations.findIndex((x) => x.get("num") == num);
      favouriteStations = favouriteStations.delete(index);
      this.setState({ favouriteStations });
    }
  };

  onStationForSceneChange = (value) => {
    this.setState({ selectedStationInScene: value }, () => {
      this.sendMessage(this.state.selectedStationInScene, "setFavInScenes");
    });
  };

  openRadioSettings = () => {
    this.setState({ settingsDialogVisible: true });
  };
  closeRadioSettings = () => {
    this.setState({ settingsDialogVisible: false });
  };

  toggleVolume = () => {
    const { volume } = this.state;

    let value = volume == 0 ? 28 : 0;
    Platform.OS !== "web" && Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Light);

    const { favouritesColors } = this.props;
    if (value == 0) {
      const { device } = this.props;
      const deviceID = device.get("id");
      this.props.onAddFavouriteColorToRGBModal(deviceID, "lastColor", this.state.volume);
    } else {
      let colorOn = favouritesColors ? favouritesColors.get("lastColor") : undefined;

      if (colorOn && colorOn.get("color") !== undefined && colorOn.get("color") > 0) {
        value = colorOn.get("color");
      }
    }
    this.sendMessage(value, "volume");
  };

  onSettingsChangeHandler = (value, el) => {
    if (!this.state.blockProps) {
      this.setState({ blockProps: true });
    }

    this.sendMessage(value, el, true);

    // if (el == "bass") {
    // 	this.sendMessage(value, "bass", true);
    // } else if (el == "mid") {
    // 	this.sendMessage(value, "mid", true);
    // } else if (el == "treble") {
    // 	this.sendMessage(value, "treble", true);
    // }
  };

  onSettingsCompleteHandler = (value, el) => {
    if (el == "bass") {
      this.setState({ bass: value }, () => this.sendMessage(value, "bass"));
    } else if (el == "mid") {
      this.setState({ mid: value }, () => this.sendMessage(value, "mid"));
    } else if (el == "treble") {
      this.setState({ treble: value }, () => this.sendMessage(value, "treble"));
    }
  };

  render() {
    const { device, deviceInScene, header, readOnly, deviceState, dimensions, detailContent, customWidth } = this.props;
    const {
      favouriteStations,
      selectedStationInScene,
      isLoading,
      favouritesListDialogVisible,
      addFavouriteStationDialogVisible,
      mode,
      settingsDialogVisible,
      volume,
      bass,
      mid,
      treble,
    } = this.state;

    let favouriteStationsList = [];
    if (favouriteStations) {
      let element = {};
      for (let i = 0; i < 16; i++) {
        favouriteStations.some((el) => {
          return el.get("num") == i;
        })
          ? (element = {
              label: `${i + 1}. ` + favouriteStations.find((x) => x.get("num") == i).get("name"),
              value: i,
            })
          : (element = { label: `${i + 1}. Empty`, value: i });

        favouriteStationsList.push(element);
      }
    }

    let settingsArray = [
      { label: "treble", value: treble },
      { label: "mid", value: mid },
      { label: "bass", value: bass },
    ];

    const activeMode = modes.findIndex((el) => el.value === mode);
    const evaluated =
      activeMode && modes[activeMode] ? modes[activeMode].label + " - " + changeVolumeToPercent(volume) : changeVolumeToPercent(volume);

    const modals = (
      <>
        <FavouriteStationsModal
          isVisible={favouritesListDialogVisible}
          favouriteStations={favouriteStations}
          isLoading={isLoading}
          onActionFromStationList={this.onActionFromStationList}
          onClose={() => {
            this.setState({ favouritesListDialogVisible: false });
          }}
        />
        <AddFavouriteStationModal
          isVisible={addFavouriteStationDialogVisible}
          favouriteStationsList={favouriteStationsList}
          isLoading={isLoading}
          onClose={() => {
            this.setState({ addFavouriteStationDialogVisible: false });
          }}
          onAdd={this.onAddFavouriteStation}
        />
        {settingsDialogVisible ? (
          <ModalWithContent isVisible={settingsDialogVisible} title={t("RADIO_SETTINGS")} onClose={this.closeRadioSettings} modalHeight={250}>
            {settingsArray.map((el) => {
              return (
                <View key={el.label}>
                  <View
                    style={{
                      // width: "100%",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <PrimaryColorText
                      style={{
                        textTransform: "capitalize",
                        fontWeight: "bold",
                      }}
                    >
                      {el.label}
                    </PrimaryColorText>
                    <PrimaryColorText style={{ textTransform: "capitalize" }}>{el.value - 100}</PrimaryColorText>
                  </View>
                  <View
                    style={{
                      // width: "100%",
                      flexDirection: "row",
                      paddingBottom: 10,
                    }}
                  >
                    <SliderStyled
                      disabled={readOnly}
                      value={el.value}
                      minimumValue={93}
                      maximumValue={107}
                      step={1}
                      // onSlidingStart={this.onSlidingStartHandler}
                      onValueChange={(value) => this.onSettingsChangeHandler(value, el.label)}
                      onSlidingComplete={(value) => this.onSettingsCompleteHandler(value, el.label)}
                    />
                  </View>
                </View>
              );
            })}
          </ModalWithContent>
        ) : null}
      </>
    );

    return detailContent ? (
      <View flexDirection="column">
        {modals}
        <ComponentContainer numOfColumns={1}>
          <View style={readOnly ? { opacity: 0.3, width: "100%", paddingLeft: 10 } : { opacity: 1, width: "100%", paddingLeft: 10 }}>
            <View
              flexDirection="row"
              justifyContent={"space-between"}
              style={{ flexDirection: "row", justifyContent: "space-between", alignItems: "center", marginBottom: 10 }}
              alignItems="center"
            >
              <ComponentHeaderText>{t("POWER")}</ComponentHeaderText>
              <TouchableOpacity disabled={readOnly} onPress={() => this.toggleVolume()}>
                {volume === 0 ? <StyledIcon name={"volume-2"} size={30} /> : <StyledIcon name={"volume-x"} size={30} />}
              </TouchableOpacity>
            </View>
          </View>
        </ComponentContainer>
        <ComponentContainer numOfColumns={1}>
          <View style={readOnly ? { opacity: 0.3, width: "100%", paddingLeft: 10 } : { opacity: 1, width: "100%", paddingLeft: 10 }}>
            <View flexDirection="row" justifyContent={"space-between"} tyle={{ marginBottom: 10 }} alignItems="center">
              <ComponentHeaderText>{t("MIXER")}</ComponentHeaderText>
              <TouchableOpacity style={{ flexDirection: "row", alignItems: "center" }} onPress={this.openRadioSettings}>
                <StyledIcon style={{ paddingRight: 5 }} name={"sliders"} color={"primary"} size={30} />
              </TouchableOpacity>
            </View>
          </View>
        </ComponentContainer>
        <ComponentContainer numOfColumns={1}>
          <View style={readOnly ? { opacity: 0.3, width: "100%", paddingLeft: 10 } : { opacity: 1, width: "100%", paddingLeft: 10 }}>
            <View flexDirection="row" justifyContent={"space-between"} tyle={{ marginBottom: 10 }} alignItems="center">
              <ComponentHeaderText>{t("INPUT")}</ComponentHeaderText>
              <ButtonsSelector
                active={activeMode}
                onChangeTab={(value) => {
                  this.sendMessage(modes[value].value, "mode");
                }}
                tabs={modes}
                fontSize={12}
              />
            </View>
          </View>
        </ComponentContainer>
        <ComponentContainer numOfColumns={1}>
          <View style={readOnly ? { opacity: 0.3, width: "100%", paddingLeft: 10 } : { opacity: 1, width: "100%", paddingLeft: 10 }}>
            <View flexDirection="row" justifyContent="space-between" style={{ marginBottom: 10 }}>
              <ComponentHeaderText>{t("LOUDNESS")}</ComponentHeaderText>
              <ComponentHeaderText>{changeVolumeToPercent(volume)}</ComponentHeaderText>
            </View>
            <Slider
              animateTransitions
              value={volume}
              minimumValue={0}
              maximumValue={56}
              onValueChange={(value) => this.onSlidingChangeHandler(value[0])}
              onSlidingComplete={(value) => this.onSlidingCompleteHandler(value[0])}
              thumbStyle={{
                backgroundColor: kelvinToRgb(2700),
                borderRadius: 20,
                height: 40,
                width: 40,
                borderWidth: 2,
                borderColor: "white",
              }}
              minimumTrackStyle={{
                borderRadius: 15,
                height: 25,
                backgroundColor: kelvinToRgb(2700),
              }}
              maximumTrackStyle={{
                borderRadius: 15,
                height: 25,
              }}
            />
          </View>
        </ComponentContainer>
        {mode === 2 && (
          <ComponentContainer numOfColumns={1}>
            {!deviceInScene ? (
              mode == 2 ? (
                <RadioButtonsBar
                  onActionFromButtons={this.onActionFromButtons}
                  rds={deviceState.get("rds")}
                  freq={deviceState.get("freq")}
                  readOnly={readOnly}
                />
              ) : null
            ) : mode == 2 ? (
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <PrimaryColorText style={{ paddingRight: 5 }}>{t("FAVOURITE_STATIONS")}</PrimaryColorText>
                {isLoading ? (
                  <SkottieLoader style={{ width: 23, height: 23 }} />
                ) : (
                  <PickerSelect
                    placeholder={{
                      label: "Select favourite station",
                      value: null,
                    }}
                    items={favouriteStationsList}
                    onValueChange={this.onStationForSceneChange}
                    value={selectedStationInScene}
                  />
                )}
              </View>
            ) : null}
          </ComponentContainer>
        )}
      </View>
    ) : dimensions.get("smallTiles") && !customWidth ? (
      <View style={{ width: "100%" }}>
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            height: 40,
            marginBottom: readOnly || dimensions.get("smallTiles") ? 0 : 10,
          }}
        >
          <View
            style={{
              flexDirection: "column",
              flex: 1,
            }}
          >
            {header}
            {evaluated && (
              <PrimaryColorText style={{ paddingLeft: 8, fontSize: 12 }} numberOfLines={1}>
                {evaluated}
              </PrimaryColorText>
            )}
          </View>
          <View
            style={{
              flexDirection: "row",
            }}
          >
            <TouchableOpacity disabled={readOnly} onPress={() => this.toggleVolume()} hitSlop={{ top: 10, bottom: 10, left: 10, right: 10 }}>
              {volume === 0 ? (
                <StyledIcon name={"volume-2"} color={"secondary"} size={22} />
              ) : (
                <StyledIcon name={"volume-x"} color={"secondary"} size={22} />
              )}
            </TouchableOpacity>
          </View>
        </View>
      </View>
    ) : (
      <View>
        {modals}
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {deviceInScene ? (
            <View style={{ flex: 1 }}>{header}</View>
          ) : (
            <TouchableOpacity style={{ flex: 1, flexDirection: "row", alignItems: "center" }} onPress={this.openRadioSettings}>
              {header}

              <StyledIcon style={{ paddingRight: 5 }} name={"settings"} color={"primary"} size={20} />
            </TouchableOpacity>
          )}
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <ComponentHeaderText style={{ marginRight: 10 }}>{changeVolumeToPercent(volume)}</ComponentHeaderText>

            <PickerSelect
              placeholder={placeholder}
              items={modes}
              disabled={readOnly}
              containerStyle={{ width: 90 }}
              onValueChange={this.onModeChange}
              onClose={this.onModeClose}
              value={mode}
            />
          </View>
        </View>

        <View>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: 10,
              marginBottom: 10,
            }}
          >
            <TouchableOpacity style={styles.iconButton} onPress={this.decreaseOne}>
              <StyledIcon name={"volume-1"} color={"primary"} size={24} />
            </TouchableOpacity>

            <SliderStyled
              disabled={readOnly}
              value={volume}
              maximumValue={56}
              // onSlidingStart={this.onSlidingStartHandler}
              onValueChange={this.onSlidingChangeHandler}
              onSlidingComplete={this.onSlidingCompleteHandler}
            />
            <TouchableOpacity disabled={readOnly} style={styles.iconButton} onPress={this.increaseOne}>
              <StyledIcon name={"volume-2"} color={"primary"} size={24} />
            </TouchableOpacity>
          </View>
        </View>
        {!deviceInScene ? (
          mode == 2 ? (
            <RadioButtonsBar
              onActionFromButtons={this.onActionFromButtons}
              rds={deviceState.get("rds")}
              freq={deviceState.get("freq")}
              readOnly={readOnly}
            />
          ) : null
        ) : mode == 2 ? (
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <PrimaryColorText style={{ paddingRight: 5 }}>{t("FAVOURITE_STATIONS")}</PrimaryColorText>
            {isLoading ? (
              <SkottieLoader style={{ width: 23, height: 23 }} />
            ) : (
              <PickerSelect
                placeholder={{
                  label: "Select favourite station",
                  value: null,
                }}
                items={favouriteStationsList}
                onValueChange={this.onStationForSceneChange}
                value={selectedStationInScene}
              />
            )}
          </View>
        ) : null}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  iconButton: {
    padding: 5,
  },
});

const mapStateToProps = (state, ownProps) => {
  let favourites = state.profilesSettings.get(state.profilesSettings.get("currentProfile")).get("favouritesColors");
  return {
    favouritesColors: favourites ? favourites.get(ownProps.device.get("id").toString()) : null,
    noConnectionBarVisible: state.statesData.get("noConnectionBarVisible"),
    noConnectionCriticalError: state.statesData.get("noConnectionCriticalError"),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAddFavouriteColorToRGBModal: (deviceID, colorPosition, color) => dispatch(addFavouriteColorToRGBModal(deviceID, colorPosition, color)),
    onRemoveFavouriteColorFromRGBModal: (deviceID, colorPosition) => dispatch(removeFavouriteColorFromRGBModal(deviceID, colorPosition)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RadioComponent);
