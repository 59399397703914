import React, { Component } from "react";
import { View, Text } from "react-native";

import { ThemeContext } from "../../../../ThemeContext";
import { TitleWithLines } from "../../UI/styledComponents";

class SeparatorComponent extends Component {
  static contextType = ThemeContext;
  state = {
    value: false,
    blockProps: false,
    pinDialogVisible: false,
    wrongPinDialogVisible: false,
    overrideValue: null,
    pin: "",
    showSwitch: false,
  };

  render() {
    let { device } = this.props;
    const { theme } = this.context;

    return <TitleWithLines textInside={device.get("opis_menu")} />;
  }
}

export default SeparatorComponent;
