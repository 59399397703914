import * as Actions from "../actionTypes";
import { List, Map, fromJS, merge } from "immutable";
import { t } from "../../services/i18n";
import { Dimensions } from "react-native";

let numberOfColumns = 1;
let width = Dimensions.get("window").width;
if (width > 900) {
  numberOfColumns = 3;
} else if (width > 600) {
  numberOfColumns = 2;
}

const initialState = Map({
  appLocation: "auth",
  clientId: null,
  clientData: null,
  states: List(),
  statesActive: List(),
  connectionStatus: 0,
  noConnectionBarVisible: false,
  noConnectionCriticalError: null,
  alertScreenVisible: false,
  blockLoginAutomatically: false,
  fromSwitchProfile: false,
  currentConnection: "local",
  detectedOutputs: null,
  playerInfo: null,
  duringTalk: false,
  server: fromJS({
    serverInfo: Map(),
    serverUpdateInfo: "",
    mqttUpdateInfo: "",
    mqttVersion: null,
  }),
  diagnosticStats: null,
  logbook: List(),
  actions_log: List(),
  messages: null,
  urgentMessages: null,
  irrigations: null,
  automatizations: null,
  initialLoadingStatus: Map({
    status: "",
    percentage: "0%",
  }),
  selectedSoruces: { local: true },
  passwordChangedInfo: "",
  currentRoute: "",
  dimensions: fromJS({
    width,
    height: Dimensions.get("window").height,
    columnWidth: width,
    landscapeOrientation: Dimensions.get("window").width > Dimensions.get("window").height,
    numberOfColumns,
  }),
  blockDimensionsChange: false,
  popupObjectId: null,
  lastTimer: null,
  callId: null,
  answeredCall: null,
});

const statesDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.CLEAR_STATES_DATA:
      return (state = initialState);

    case Actions.CHANGE_BLOCK_DIMENSIONS:
      return state.set("blockDimensionsChange", action.payload);

    case Actions.CHANGE_DIMMENSIONS:
      return state.set("dimensions", fromJS(action.payload));

    case Actions.CHANGE_APP_LOCATION:
      return state.set("appLocation", fromJS(action.payload));

    case Actions.CHANGE_CURRENT_ROUTE:
      return state.set("currentRoute", fromJS(action.payload));

    case Actions.CHANGE_JANUS:
      return state.set("janus", fromJS(action.payload));

    case Actions.CHANGE_JANUS_ID:
      return state.set("janusId", fromJS(action.payload));
    case Actions.CHANGE_CLIENT_ID:
      return state.set("clientId", fromJS(action.payload));

    case Actions.CHANGE_ANWERED_CALL: {
      if (action.payload && action.payload.callData && action.payload.callData.callUUID) {
        const uuidData = action.payload.callData.callUUID.split("-");
        const deviceId = uuidData[0] + uuidData[4].substring(4);
        const objectId = parseInt(uuidData[4].substring(0, 4), 16);
        let objToShow = { id: objectId, title: "CALL" };
        return state
          .set("answeredCall", { deviceId, objectId, uuid: action.payload.callData.callUUID })
          .set("popupObjectId", fromJS(objToShow))
          .set("duringTalk", objectId);
      } else {
        return state.set("answeredCall", action.payload);
      }
    }

    case Actions.CHANGE_EXPO_SOUND:
      return state.set("expoSound", fromJS(action.payload));

    case Actions.CHANGE_SOUND_STATUS:
      return state.set("soundStatus", fromJS(action.payload));

    case Actions.CHANGE_SELECTED_SOURCES:
      return state.set("selectedSoruces", action.payload);

    case Actions.CHANGE_CLOUD_SUPPORT_DATA:
      return state.set("cloudSupportData", action.payload);
    case Actions.CHANGE_CLIENT_DATA:
      return state.set("clientData", fromJS(action.payload));

    case Actions.STATES_ACTIVE_RECEIVED:
      return state.set("statesActive", fromJS(action.payload.array));

    case Actions.STATES_RECEIVED:
      if (action.payload.alarming && action.payload.calling) {
        return state
          .set("popupObjectId", fromJS(action.payload.calling))
          .set("alertScreenVisible", fromJS(action.payload.alarming))
          .set("states", fromJS(action.payload.array));
      } else if (action.payload.alarming) {
        return state.set("alertScreenVisible", fromJS(action.payload.alarming)).set("states", fromJS(action.payload.array));
      } else if (action.payload.calling && !state.get("duringTalk")) {
        return state.set("popupObjectId", fromJS(action.payload.calling)).set("states", fromJS(action.payload.array));
      } else {
        return state.set("states", fromJS(action.payload.array));
      }

    case Actions.SINGLE_ACTIVE_STATE_RECEIVED:
      let newStan2 = fromJS(action.payload);
      let newset2;
      if (!state.getIn(["statesActive", action.deviceID])) {
        newset2 = state.setIn(["statesActive", action.deviceID], newStan2);
      } else {
        let element = state.getIn(["statesActive", action.deviceID]);
        let element2 = element.merge(newStan2);
        newset2 = state.setIn(["statesActive", action.deviceID], element2);
      }
      return newset2;

    case Actions.SINGLE_STATE_RECEIVED:
      let newStan = fromJS(action.payload);
      let newset;
      if (!state.getIn(["states", action.deviceID])) {
        newset = state.setIn(["states", action.deviceID], newStan);
      } else {
        let element = state.getIn(["states", action.deviceID]);
        let element2 = element.merge(newStan);
        newset = state.setIn(["states", action.deviceID], element2);
      }
      //return state;
      return newset;

    case Actions.DURING_TALK:
      return state.set("duringTalk", action.payload);
    case Actions.CHANGE_INFO_BAR_VISIBILTY:
      return state.set("noConnectionBarVisible", action.payload);

    case Actions.CHANGE_CONNECTION_STATUS:
      return state.set("connectionStatus", action.payload);

    case Actions.CHANGE_TIME_MISSMATCH:
      return state.set("timeMissmatch", action.payload);

    case Actions.CHANGE_NO_CONNECTION_CRITICAL:
      return state.set("noConnectionCriticalError", action.payload);

    case Actions.CHANGE_ALERT_SCREEN_VISIBILTY:
      return state.set("alertScreenVisible", action.payload);

    case Actions.CHANGE_APP_STATE:
      return state.set("appState", action.payload);

    case Actions.CHANGE_LAST_TIMER:
      return state.set("lastTimer", action.payload);

    case Actions.CHANGE_BLOCK_LOGIN_AUTOMATICALLY:
      return state.set("blockLoginAutomatically", action.payload);

    case Actions.CHANGE_FROM_SWITCH_PROFILE:
      return state.set("fromSwitchProfile", action.payload);

    case Actions.CHANGE_POPUP_OBJECT_ID:
      return state.set("popupObjectId", fromJS(action.payload));

    case Actions.CHANGE_CURRENT_CONNECTION_PLACE:
      return state.set("currentConnection", action.payload);

    case Actions.HEATING_SCHEDULE_RECEIVED:
      let heatingSchedule = fromJS(action.payload);
      let newStatee = state.setIn(["states", action.deviceID, "heatingSchedule"], heatingSchedule);
      return newStatee;
    case Actions.ACCESS_CONTROL_RECEIVED:
      if (state.get("states").get(action.deviceID)) {
        return state.setIn(["states", action.deviceID, "accessControl"], fromJS(action.payload));
      } else {
        return state.setIn(["states", action.deviceID], fromJS({ accessControl: action.payload }));
      }
    case Actions.IR_DATA_RECEIVED:
      if (state.get("states").get(action.deviceID)) {
        return state.setIn(["states", action.deviceID, "irData"], fromJS(action.payload));
      } else {
        return state.setIn(["states", action.deviceID], fromJS({ irData: action.payload }));
      }
    case Actions.IR_STATUS_RECEIVED:
      if (state.get("states").get(action.deviceID)) {
        return state.setIn(["states", action.deviceID, "ir_status"], fromJS(action.payload));
      } else {
        return state.setIn(["states", action.deviceID], fromJS({ ir_status: action.payload }));
      }

    case Actions.LOGBOOK_DATA_RECEIVED:
      if (state.get("states").get(action.deviceID)) {
        return state.setIn(["states", action.deviceID, "logbookData"], fromJS(action.payload));
      } else {
        return state.setIn(["states", action.deviceID], fromJS({ logbookData: action.payload }));
      }

    case Actions.BAR_CHART_DATA_RECEIVED:
      if (state.get("states").get(action.deviceID)) {
        return state.setIn(["states", action.deviceID, "barChartData"], fromJS(action.payload));
      } else {
        return state.setIn(["states", action.deviceID], fromJS({ barChartData: action.payload }));
      }

    case Actions.CHART_DATA_RECEIVED:
      if (state.get("states").get(action.deviceID)) {
        return state.setIn(["states", action.deviceID, "chartData"], fromJS(action.payload));
      } else {
        return state.setIn(["states", action.deviceID], fromJS({ chartData: action.payload }));
      }

    case Actions.REMOVE_OUTDATED_HEATING_SCHEDULE:
      let newStatt = state.deleteIn(["states", action.payload, "heatingSchedule"]);
      return newStatt;
    case Actions.REMOVE_OUTDATED_ACCESS_CONTROL:
      return state.deleteIn(["states", action.payload, "accessControl"]);

    case Actions.RADIO_FAVOURITES_RECEIVED:
      let favouriteStations = fromJS(action.payload);
      let newStateee = state.setIn(["states", action.deviceID, "favouriteStations"], favouriteStations);
      return newStateee;

    case Actions.CAMERA_STATE_RECEIVED:
      let newCameraImage = fromJS(action.payload);
      return state.setIn(["states", action.deviceID], fromJS({ cameraImage: newCameraImage }));

    case Actions.INITIAL_LOADING_STATUS:
      return state.set("initialLoadingStatus", fromJS(action.payload));

    case Actions.SERVER_INFO_RECEIVED:
      return state.setIn(["server", "serverInfo"], fromJS(action.payload));

    case Actions.MQTT_VERSION_RECEIVED:
      return state.setIn(["server", "mqttVersion"], fromJS(action.payload));

    case Actions.DIAGNOSTIC_STATS_RECEIVED:
      return state.set("diagnosticStats", action.payload);

    case Actions.RECEIVED_PLAYER_INFO:
      return state.set("playerInfo", action.payload);

    case Actions.RECEIVED_DETECTED_OUTPUTS:
      return state.set("detectedOutputs", action.payload);

    case Actions.RECEIVED_QUEUE_INFO:
      return state.set("queueInfo", action.payload);

    case Actions.SERVER_UPDATE_INFO_RECEIVED:
      return state.setIn(["server", "serverUpdateInfo"], fromJS(action.payload));

    case Actions.MQTT_UPDATE_INFO_RECEIVED:
      return state.setIn(["server", "mqttUpdateInfo"], fromJS(action.payload));

    case Actions.LOGBOOK_RECEIVED:
      return state.set("logbook", fromJS(action.payload));

    case Actions.ACTIONS_LOG_RECEIVED:
      return state.set("actions_log", fromJS(action.payload));

    case Actions.MESSAGES_RECEIVED:
      return state.set("messages", fromJS(action.payload));

    case Actions.URGENT_MESSAGES_RECEIVED:
      return state.set("urgentMessages", fromJS(action.payload));

    case Actions.MESSAGES_COUNT_RECEIVED:
      return state.set("messages_count", fromJS(action.payload));

    case Actions.IRRIGATIONS_RECEIVED:
      return state.set("irrigations", fromJS(action.payload));

    case Actions.AUTOMATIZATIONS_RECEIVED:
      return state.set("automatizations", fromJS(action.payload));

    case Actions.PASSWORD_CHANGED_INFO_RECEIVED:
      return state.set("passwordChangedInfo", action.payload);

    default:
      return state;
  }
};

export default statesDataReducer;
