import { hide } from "expo-splash-screen";
import React, { Component } from "react";
import { View, StyleSheet, TouchableOpacity } from "react-native";
import mqttClient from "../../../services/mqtt";
import LinearGradient from "react-native-linear-gradient";
import ImageFromIkona0_0 from "../../../utils/ImageFromIkona0_0";
import * as Haptics from "expo-haptics";
import {
  ComponentHeaderText,
  StyledIcon,
  SwitchStyled,
  SliderStyled,
  SliderWW,
  SecondaryColorText,
  ComponentContainer,
  PowerStyled,
} from "../../UI/styledComponents";
import { t } from "../../../services/i18n";
import { connect } from "react-redux";
import { addFavouriteColorToRGBModal, removeFavouriteColorFromRGBModal } from "../../../store/actions";
import { CircularSlider } from "../regulator/CircularSlider";
import { Ionicons } from "@expo/vector-icons";
import { Slider } from "@miblanchard/react-native-slider";
import { formatDistanceToNow } from "date-fns";
import * as Localization from "expo-localization";
import { ar, cs, de, es, fr, it, pl, pt } from "date-fns/locale";
const locales = { ar, cs, de, es, fr, it, pl, pt };
let lang = Localization.locale.split("-")[0];

function clamp(num, min, max) {
  return Math.min(Math.max(num, min), max);
}

function intToHex(_int) {
  return _int.toString(16).padStart(2, "0");
}

export function kelvinToRgb(kelvin) {
  var temp = kelvin / 100;
  var r, g, b;

  if (temp < 66) {
    r = 255;
    g = -155.25485562709179 - 0.44596950469579133 * (g = temp - 2) + 104.49216199393888 * Math.log(g);
    b = temp < 20 ? 0 : -254.76935184120902 + 0.8274096064007395 * (b = temp - 10) + 115.67994401066147 * Math.log(b);
  } else {
    r = 351.97690566805693 + 0.114206453784165 * (r = temp - 55) - 40.25366309332127 * Math.log(r);
    g = 325.4494125711974 + 0.07943456536662342 * (g = temp - 50) - 28.0852963507957 * Math.log(g);
    b = 255;
  }
  return "#" + intToHex(clamp(Math.floor(r), 0, 255)) + intToHex(clamp(Math.floor(g), 0, 255)) + intToHex(clamp(Math.floor(b), 0, 255));
}

let changeHexToPercent = (hexValue) => {
  let percentValue = (hexValue * 100) / 255;
  if (percentValue < 1) {
    percentValue = Math.floor(percentValue * 10) / 10;
  } else {
    percentValue = Math.round(percentValue);
  }

  return `${percentValue} %`;
};

let changePercentToHex = (percentValue) => {
  let hexValue = Math.round((percentValue * 255) / 100);
  return hexValue;
};

class LedComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      blockProps: false,
      value: 0,
      deviceFromScene: false,
      switchValue: false,
    };
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (!nextProps.deviceInScene && !prevState.blockProps) {
      return {
        value: Number(nextProps.deviceState.get("state")),
        switchValue: (nextProps.deviceState.get("state") & 0xff) == 0 ? false : true,
      };
    } else if (nextProps.deviceInScene && !prevState.deviceFromScene) {
      return {
        deviceFromScene: true,
        value: Number(nextProps.deviceInScene.get("value")),
        switchValue: (nextProps.deviceInScene.get("value") & 0xff) == 0 ? false : true,
      };
    }
    return null;
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.state.value != nextState.value) {
      return true;
    }
    if (this.props.params_device != nextProps.params_device) {
      return true;
    }

    return false;
  }

  componentWillUnmount() {
    if (this.timeOut) {
      clearTimeout(this.timeOut);
      this.timeOut = 0;
    }
  }

  increaseOne = () => {
    const { device, params_device } = this.props;

    let customRange = false;
    if (
      device.get("typ_komponentu") == "flaga_liniowa" ||
      device.get("typ_komponentu") == "flaga_liniowa16" ||
      (device.get("typ_komponentu") == "led" && params_device && (params_device.get("params") & 0x100000) > 0)
    ) {
      customRange = true;
    }
    const value = this.state.value & 0xff;
    let newValue = value & 0xff;

    if (!customRange) {
      if (value >= 0 && value <= 3) {
        newValue = value + 1;
      } else if (value > 3 && value < 254) {
        newValue = value + changePercentToHex(1);
      }
    } else {
      if (value >= device.get("min") && value < device.get("max")) {
        newValue = value + 1;
      }
    }

    this.setState(
      {
        value: (this.state.value & 0xff00) + (newValue & 0xff),
        switchValue: newValue != 0 ? true : false,
        blockProps: true,
      },
      () => this.sendMessage(this.state.value)
    );
  };

  decreaseOne = () => {
    const { device, params_device } = this.props;

    let customRange = false;
    if (
      device.get("typ_komponentu") == "flaga_liniowa" ||
      device.get("typ_komponentu") == "flaga_liniowa16" ||
      (device.get("typ_komponentu") == "led" && params_device && (params_device.get("params") & 0x100000) > 0)
    ) {
      customRange = true;
    }
    const value = this.state.value & 0xff;
    let newValue = value & 0xff;

    if (!customRange) {
      if (value > 0 && value <= 4) {
        newValue = value - 1;
      } else if (value > 4 && value <= 255) {
        newValue = value - changePercentToHex(1);
      }
    } else {
      if (value > device.get("min") && value <= device.get("max")) {
        newValue = value - 1;
      }
    }

    this.setState(
      {
        value: (this.state.value & 0xff00) + (newValue & 0xff),
        switchValue: newValue != 0 ? true : false,
        blockProps: true,
      },
      () => this.sendMessage(this.state.value)
    );
  };

  toggleSwitchValue = () => {
    Platform.OS !== "web" && Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Light);
    let value = this.state.switchValue ? 0 : 255;
    let switchValue = this.state.switchValue ? false : true;

    let valueToSend = value & 0xff;
    const { favouritesColors } = this.props;
    if (value == 0) {
      const { device } = this.props;
      const deviceID = device.get("id");
      this.props.onAddFavouriteColorToRGBModal(deviceID, "lastColor", this.state.value & 0xff);
    } else {
      let colorOn = favouritesColors ? favouritesColors.get("lastColor") : undefined;

      if (colorOn && colorOn.get("color") !== undefined) {
        valueToSend = colorOn.get("color") & 0xff;
      }
    }

    this.setState(
      {
        switchValue,
        value: (valueToSend & 0xff) + (this.state.value & 0xff00),
        blockProps: true,
      },
      () => {
        this.sendMessage(valueToSend, false, true);
      }
    );
  };

  onSlidingStartHandler = () => {
    this.setState({ blockProps: true });
  };

  onSlidingChangeHandlerTemp = (value) => {
    let valueToSend = (this.state.value & 0xff) + ((value << 8) & 0xff00);

    if (!this.state.blockProps) {
      this.setState({ blockProps: true });
    }
    this.sendMessage(valueToSend, true);
  };

  onSlidingCompleteHandlerTemp = (value) => {
    let valueToSend = (this.state.value & 0xff) + ((value << 8) & 0xff00);
    this.setState({ value: valueToSend }, () => this.sendMessage(valueToSend));
  };

  onSlidingChangeHandler = (value) => {
    let valueToSend = (this.state.value & 0xff00) + (value & 0xff);
    if (!this.state.blockProps) {
      this.setState({ blockProps: true });
    }
    this.sendMessage(valueToSend, true);
  };

  onSlidingCompleteHandler = (value) => {
    let valueToSend = (this.state.value & 0xff00) + (value & 0xff);
    this.setState({ value: valueToSend, switchValue: value > 0 }, () => this.sendMessage(valueToSend));
  };

  runTimeout = () => {
    let updateState = () => {
      const { deviceState } = this.props;
      this.setState({
        blockProps: false,
        value: Number(deviceState.get("state")),
        switchValue: Number(deviceState.get("state")) != 0 ? true : false,
      });
    };
    this.timeOut = setTimeout(() => {
      this.timeOut = 0;
      updateState();
    }, 5000);
  };

  //clearing old timeout and starting new one, to have always 5 sec
  clearTimeoutMine = () => {
    if (this.timeOut) {
      clearTimeout(this.timeOut);
      this.timeOut = 0;
    }
    this.runTimeout();
  };

  sendMessage = (value, fromSliderMoving, onlyPower) => {
    const { device, deviceInScene, changeScene, activeSceneControl, params_device } = this.props;

    let hidePower = params_device ? (params_device.get("params") & 0x40000000) > 0 : 0;
    let hideWarmness = params_device ? ((params_device.get("params") >>> 0) & 0x80000000) != 0 : 0;

    !deviceInScene && !fromSliderMoving && this.clearTimeoutMine();

    if (deviceInScene) {
      let infoToUpdate = deviceInScene.set("value", value);
      changeScene(infoToUpdate, deviceInScene.get("temporary_id"));
    }

    let message = `/api/set/${this.props.device.get("id")}/setWW/${value}`;
    if (hidePower) {
      message = `/api/set/${this.props.device.get("id")}/setWWColdness/${(value >> 8) & 0xff}`;
    }
    if (hideWarmness || onlyPower) {
      message = `/api/set/${this.props.device.get("id")}/setWWPower/${(value >> 0) & 0xff}`;
    }
    activeSceneControl != false && mqttClient.stateChangeToSend(message, device.get("id"));
  };

  render() {
    const { device, header, readOnly, deviceInScene, deviceFromCustom, params_device, params_devices, dimensions, deviceState } = this.props;

    let sliderMinValue = 0;
    let sliderMaxValue = 255;
    let customRange = false;
    let kelvinLow = 2200;
    let kelvinHigh = 14000; // więcjej zimna
    let rgbCold = kelvinToRgb(kelvinHigh);
    let rgbWarm = kelvinToRgb(kelvinLow);
    let step = (kelvinHigh - kelvinLow) / (sliderMaxValue - sliderMinValue);

    let backgroundColorssBorder = "#ED695C";

    if (
      device.get("typ_komponentu") == "flaga_liniowa" ||
      device.get("typ_komponentu") == "flaga_liniowa16" ||
      (device.get("typ_komponentu") == "led" && params_device && (params_device.get("params") & 0x100000) > 0)
    ) {
      customRange = true;
      sliderMinValue = device.get("min");
      sliderMaxValue = device.get("max");
    }

    let hidePower = params_device ? (params_device.get("params") & 0x40000000) > 0 : 0;
    let hideWarmness = params_device ? ((params_device.get("params") >>> 0) & 0x80000000) != 0 : 0;

    let { value, switchValue } = this.state;
    let currentColor = (this.state.value & 0xff) > 0 ? kelvinToRgb((((value >> 8) & 0xff) * step + kelvinLow) | 0) : "grey";
    let showSwitch = params_device && (params_device.get("params") & 0x8000) > 0;
    let showIcons = false;
    if (
      (device.has("ikona_0") && (device.get("ikona_0") !== null || device.get("ikona_1") !== null)) ||
      (device.has("ikona0") && (device.get("ikona0") !== null || device.get("ikona1") !== null))
    ) {
      showIcons = true;
    }
    let blockColors =
      device.get("typ_komponentu") == "ledww" &&
      params_device &&
      (params_device.get("params") & 0x8000000) > 0 &&
      !this.props.deviceFromAutomatization;
    let switchContent = showIcons ? (
      <TouchableOpacity onPress={this.toggleSwitchValue} disabled={readOnly} hitSlop={{ top: 10, bottom: 10, left: 10, right: 10 }}>
        <ImageFromIkona0_0 device={device} value={switchValue} />
      </TouchableOpacity>
    ) : this.props.detailContent ? (
      <PowerStyled value={switchValue} onPress={this.toggleSwitchValue} disabled={readOnly} />
    ) : (
      <SwitchStyled style={{ marginLeft: 10 }} value={switchValue} onValueChange={this.toggleSwitchValue} disabled={readOnly} />
    );

    return this.props.detailContent ? (
      <View flexDirection="column">
        <ComponentContainer numOfColumns={1}>
          <View style={readOnly ? { opacity: 0.3, width: "100%", paddingLeft: 10 } : { opacity: 1, width: "100%", paddingLeft: 10 }}>
            <View
              flexDirection="row"
              justifyContent={"space-between"}
              alignItems="center"
              style={{ flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}
            >
              <ComponentHeaderText>{t("POWER")}</ComponentHeaderText>
              {switchContent}
            </View>
          </View>
        </ComponentContainer>
        <ComponentContainer numOfColumns={1}>
          <View style={readOnly ? { opacity: 0.3, width: "100%", paddingLeft: 10 } : { opacity: 1, width: "100%", paddingLeft: 10 }}>
            <View flexDirection="row" justifyContent="space-between">
              <ComponentHeaderText>{t("VALUE")}</ComponentHeaderText>
              <ComponentHeaderText>{!customRange ? changeHexToPercent(this.state.value & 0xff) : this.state.value}</ComponentHeaderText>
            </View>
            <Slider
              animateTransitions
              value={value & 0xff}
              minimumValue={sliderMinValue}
              maximumValue={sliderMaxValue}
              onValueChange={(value) => this.onSlidingChangeHandler(value[0])}
              onSlidingComplete={(value) => this.onSlidingCompleteHandler(value[0])}
              thumbStyle={{
                backgroundColor: currentColor,
                borderRadius: 20,
                height: 40,
                width: 40,
                borderWidth: 2,
                borderColor: "white",
              }}
              minimumTrackStyle={{
                borderRadius: 15,
                height: 25,
                backgroundColor: currentColor,
              }}
              maximumTrackStyle={{
                borderRadius: 15,
                height: 25,
              }}
            />
          </View>
        </ComponentContainer>
        <ComponentContainer numOfColumns={1}>
          <ComponentHeaderText style={{ marginBottom: 10, paddingLeft: 10 }}>{t("WARMNESS")}</ComponentHeaderText>
          <View style={readOnly || blockColors ? { opacity: 0.3, width: "100%", height: 45 } : { opacity: 1, width: "100%", height: 50 }}>
            <LinearGradient
              start={{ x: 0, y: 0 }}
              end={{ x: 1, y: 0 }}
              colors={[rgbWarm, rgbCold]}
              style={{
                margin: 5,
                borderRadius: 20,
                height: 25,
                justifyContent: "center",
                paddingVertical: 10,
                position: "absolute",
                top: 2,
                left: 0,
                right: 0,
                bottom: 0,
              }}
            ></LinearGradient>
            <Slider
              animateTransitions
              value={(value >> 8) & 0xff}
              minimumValue={sliderMinValue}
              maximumValue={sliderMaxValue}
              disabled={readOnly || blockColors}
              onValueChange={(value) => this.onSlidingChangeHandlerTemp(value[0])}
              onSlidingComplete={(value) => this.onSlidingCompleteHandlerTemp(value[0])}
              thumbStyle={{
                backgroundColor: currentColor,
                borderRadius: 20,
                height: 40,
                width: 40,
                borderWidth: 2,
                borderColor: "white",
              }}
              minimumTrackStyle={{
                borderRadius: 15,
                height: 25,
                backgroundColor: "transparent",
              }}
              maximumTrackStyle={{
                borderRadius: 15,
                height: 25,
                backgroundColor: "transparent",
              }}
            />
          </View>
        </ComponentContainer>
      </View>
    ) : (
      <View>
        {!deviceFromCustom && (
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: dimensions.get("smallTiles") ? 0 : 10,
            }}
          >
            {header}
            <View style={{ flexDirection: "row", alignItems: "center" }}>
              <TouchableOpacity
                hitSlop={{ top: 10, bottom: 10, left: 10, right: 10 }}
                disabled={readOnly}
                onPress={this.toggleSwitchValue}
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  width: dimensions.get("smallTiles") ? 40 : 24,
                  height: dimensions.get("smallTiles") ? 40 : 24,
                  borderRadius: 50,
                  padding: 5,
                  marginRight: 0,
                  backgroundColor: currentColor,
                  borderColor: backgroundColorssBorder,
                  borderWidth: 2,
                }}
              ></TouchableOpacity>
              {!dimensions.get("smallTiles") && (
                <ComponentHeaderText>{!customRange ? changeHexToPercent(this.state.value & 0xff) : this.state.value}</ComponentHeaderText>
              )}
              {showSwitch ? switchContent : null}
            </View>
          </View>
        )}
        {!dimensions.get("smallTiles") && (
          <View>
            {!hidePower && (
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                  alignContent: "center",
                }}
              >
                <TouchableOpacity disabled={readOnly} style={styles.iconButton} onPress={this.decreaseOne}>
                  <StyledIcon name={"minus-circle"} color={"primary"} size={deviceFromCustom ? 18 : 24} />
                </TouchableOpacity>

                <SliderStyled
                  value={value & 0xff}
                  minimumValue={sliderMinValue}
                  maximumValue={sliderMaxValue}
                  onValueChange={this.onSlidingChangeHandler}
                  onSlidingComplete={this.onSlidingCompleteHandler}
                  disabled={readOnly}
                />
                <TouchableOpacity disabled={readOnly} style={styles.iconButton} onPress={this.increaseOne}>
                  <StyledIcon name={"plus-circle"} size={deviceFromCustom ? 18 : 24} />
                </TouchableOpacity>
              </View>
            )}
            {!hideWarmness && (
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                  alignContent: "center",
                }}
              >
                <View style={readOnly || blockColors ? { opacity: 0.3, width: "100%" } : { opacity: 1, width: "100%" }}>
                  <LinearGradient
                    start={{ x: 0, y: 0 }}
                    end={{ x: 1, y: 0 }}
                    colors={[rgbWarm, rgbCold]}
                    style={{
                      margin: 5,
                      borderRadius: 20,
                      height: 35,
                      justifyContent: "center",
                    }}
                  >
                    <SliderWW
                      value={(value >> 8) & 0xff}
                      minimumValue={sliderMinValue}
                      maximumValue={sliderMaxValue}
                      minimumTrackTintColor={"transparent"}
                      maximumTrackTintColor={"transparent"}
                      // onSlidingStart={this.onSlidingStartHandler}
                      onValueChange={this.onSlidingChangeHandlerTemp}
                      onSlidingComplete={this.onSlidingCompleteHandlerTemp}
                      disabled={readOnly || blockColors}
                    />
                  </LinearGradient>
                </View>
              </View>
            )}
          </View>
        )}
      </View>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let favourites = state.profilesSettings.get(state.profilesSettings.get("currentProfile")).get("favouritesColors");
  return {
    favouritesColors: favourites ? favourites.get(ownProps.device.get("id").toString()) : null,
    params_devices: state.smartHomeData.get(state.profilesSettings.get("currentProfile")).get("params_devices"),
    dimensions: state.statesData.get("dimensions"),
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onAddFavouriteColorToRGBModal: (deviceID, colorPosition, color) => dispatch(addFavouriteColorToRGBModal(deviceID, colorPosition, color)),
    onRemoveFavouriteColorFromRGBModal: (deviceID, colorPosition) => dispatch(removeFavouriteColorFromRGBModal(deviceID, colorPosition)),
  };
};

const styles = StyleSheet.create({
  iconButton: {
    padding: 5,
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(LedComponent);
