import React, { Component } from "react";
import { t } from "../../../services/i18n";

import { ComponentContainer, SecondaryColorText } from "../../UI/styledComponents";
import { he } from "date-fns/locale";

const NoStateComponent = ({ header, deviceFromCustom, dimensions }) => {
  return (
    <ComponentContainer containerStyle={{ width: "100%" }}>
      {!deviceFromCustom && header}

      <SecondaryColorText
        style={{
          paddingLeft: deviceFromCustom ? 0 : 10,
          textAlign: deviceFromCustom ? "center" : null,
          paddingTop: dimensions.get("smallTiles") ? 5 : 0,
        }}
      >
        {deviceFromCustom ? "?" : t("STATE_NOT_RECEIVED")}
      </SecondaryColorText>
    </ComponentContainer>
  );
};

export default NoStateComponent;
