import React, { Component, useState } from "react";
import { View, Text, Platform } from "react-native";
import { connect } from "react-redux";
import { t } from "../../../services/i18n";

import {
  changeKeepAwakeSettings,
  changeDeviceIdVisibility,
  changeForceLandscapeOrientation,
  changeInvertedForceLandscapeOrientation,
  changeStartingPlace,
  changeBackToStartTimeout,
  changeDisableClickVibrations,
  changeShowAsList,
  changeHideLastChangeTimeOnList,
  changeDimensions,
} from "../../../store/actions";
import { ScreenView } from "../../../components/UI/screenViewX";
import { PrimaryColorText, TextInputStyled, SwitchStyled } from "../../../components/UI/styledComponents";
import * as ScreenOrientation from "expo-screen-orientation";
import { ListItemContainer } from "../styled";
import PickerSelectObjects from "../../../components/UI/PickerSelectObjects";
import PickerSelect from "../../../components/UI/PickerSelect";

const Random_PersonalizationScreen = (props) => {
  const { globalSettings, profileSettings, statesData } = props;

  const [startingPlace, setStaringPlace] = useState(profileSettings.get("startingPlace") ? profileSettings.get("startingPlace") : -4);

  const [backToStartTimeout, setBackToStartTimeout] = useState(
    profileSettings.get("backToStartTimeout") ? profileSettings.get("backToStartTimeout") : 0
  );

  const onModeValueChange = (value) => {
    setBackToStartTimeout(value);
    props.changeBackToStartTimeout(value);
  };

  const onCloseMode = (value) => {};

  const startingPlaceChanged = (value) => {
    setStaringPlace(value);
    props.changeStartingPlace(value);
  };

  const changeDeviceIdVisibilityHandler = (value) => {
    props.changeDeviceIdVisibility(value);
  };

  const changeKeepAwakeSettingsHandler = (value) => {
    props.changeKeepAwakeSettings(value);
  };

  const changeListSettings = (value) => {
    props.changeShowAsList(value);
    const newDimensions = { ...props.dimensions.toJS() };
    if (value) {
      newDimensions.numberOfColumns = 1;
      newDimensions.smallTiles = false;
      props.changeDimensions(newDimensions);
    } else {
      newDimensions.numberOfColumns = newDimensions.calculatedNumberOfColumns;
      newDimensions.smallTiles = true;
      props.changeDimensions(newDimensions);
    }
  };

  async function lockLandscapeOrientation(left) {
    try {
      if (left) {
        await ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock.LANDSCAPE_LEFT);
      } else {
        await ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock.LANDSCAPE_RIGHT);
      }
    } catch (error) {
      console.log(error, "error in 	ScreenOrientation.OrientationLock");
    }
  }

  async function unlockLandscapeOrientation() {
    await ScreenOrientation.unlockAsync();
    await ScreenOrientation.getOrientationAsync().then((orientationId) => {
      let orientation = ScreenOrientation.Orientation[orientationId];
      ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock[orientation]);
    });
  }

  const changeForceLandscapeOrientationHandler = (value) => {
    if (value) {
      lockLandscapeOrientation(false);
    } else {
      unlockLandscapeOrientation();
    }

    props.changeForceLandscapeOrientation(value);
  };

  const changeInvertedForceLandscapeOrientationHandler = (value) => {
    if (value) {
      lockLandscapeOrientation(true);
    } else {
      unlockLandscapeOrientation();
    }

    props.changeInvertedForceLandscapeOrientation(value);
  };

  const keepAwakeOn = globalSettings.get("keepAwakeOn");
  const deviceIdShown = globalSettings.get("deviceIdShown");
  const showAsList = globalSettings.get("showList");
  const hideLastChangeTimeOnList = globalSettings.get("hideLastChangeTimeOnList");
  const forceLandscapeOrientation = globalSettings.get("forceLandscapeOrientation");
  const forceInvertedLandscapeOrientation = globalSettings.get("forceInvertedLandscapeOrientation");
  const disableClickVibrations = globalSettings.get("disableClickVibrations");
  const dimensions = statesData.get("dimensions");

  const modes = [
    {
      label: "0s",
      value: 0,
    },
    {
      label: "60s",
      value: 60,
    },
    {
      label: "2min",
      value: 120,
    },
    {
      label: "5min",
      value: 300,
    },
    {
      label: "10min",
      value: 600,
    },
    {
      label: "1h",
      value: 3600,
    },
  ];

  return (
    <ScreenView>
      {dimensions.get("calculatedNumberOfColumns") === 1 && (
        <ListItemContainer>
          <PrimaryColorText>{t("account:SHOW_AS_LIST")}</PrimaryColorText>
          <SwitchStyled value={showAsList ? true : false} onValueChange={changeListSettings} />
        </ListItemContainer>
      )}

      <ListItemContainer>
        <PrimaryColorText>{t("account:HIDE_LAST_CHANGE_TIME_ON_LIST")}</PrimaryColorText>
        <SwitchStyled value={hideLastChangeTimeOnList ? true : false} onValueChange={props.changeHideLastChangeTimeOnList} />
      </ListItemContainer>

      <ListItemContainer>
        <PrimaryColorText>{t("account:KEEP_AWAKE_ON")}</PrimaryColorText>
        <SwitchStyled value={keepAwakeOn} onValueChange={changeKeepAwakeSettingsHandler} />
      </ListItemContainer>
      <ListItemContainer>
        <PrimaryColorText>{t("account:SHOW_DEVICE_ID")}</PrimaryColorText>
        <SwitchStyled value={deviceIdShown} onValueChange={changeDeviceIdVisibilityHandler} />
      </ListItemContainer>
      <ListItemContainer>
        <PrimaryColorText>{t("account:FORCE_LANDSCASPE_ORIENTATION")}</PrimaryColorText>
        <SwitchStyled value={forceLandscapeOrientation} onValueChange={changeForceLandscapeOrientationHandler} />
      </ListItemContainer>
      <ListItemContainer>
        <PrimaryColorText>{t("account:FORCE_INVERTED_LANDSCASPE_ORIENTATION")}</PrimaryColorText>
        <SwitchStyled value={forceInvertedLandscapeOrientation} onValueChange={changeInvertedForceLandscapeOrientationHandler} />
      </ListItemContainer>
      <ListItemContainer>
        <PrimaryColorText>{t("account:DISABLE_CLICK_VIBRATIONS")}</PrimaryColorText>
        <SwitchStyled
          value={disableClickVibrations ? disableClickVibrations : false}
          onValueChange={(value) => {
            props.changeDisableClickVibrations(value);
          }}
        />
      </ListItemContainer>
      <ListItemContainer>
        <PrimaryColorText style={{ marginRight: 15, flex: 1 }}>{t("account:CHOOSE_START_PLACE")}</PrimaryColorText>
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <PickerSelectObjects
            containerStyle={{ marginRight: 0, width: 100 }}
            onValueChange={startingPlaceChanged}
            value={startingPlace}
            type={"startingPlace"}
          />
        </View>
      </ListItemContainer>
      <ListItemContainer>
        <PrimaryColorText style={{ marginRight: 15, flex: 1 }}>{t("account:BACK_TO_START_TIMEOUT")}</PrimaryColorText>
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <PickerSelect
            placeholder={{
              label: t("SELECT_MODE"),
              value: null,
            }}
            items={modes}
            containerStyle={{ marginRight: 0, width: 100 }}
            onValueChange={onModeValueChange}
            onClose={onCloseMode}
            value={backToStartTimeout}
          />
        </View>
      </ListItemContainer>
    </ScreenView>
  );
};

const mapStateToProps = (state) => {
  return {
    globalSettings: state.globalSettings,
    statesData: state.statesData,
    profileSettings: state.profilesSettings.get(state.profilesSettings.get("currentProfile")),
    dimensions: state.statesData.get("dimensions"),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeKeepAwakeSettings: (keepAwakeOn) => dispatch(changeKeepAwakeSettings(keepAwakeOn)),
    changeDeviceIdVisibility: (deviceIdVisible) => dispatch(changeDeviceIdVisibility(deviceIdVisible)),
    changeForceLandscapeOrientation: (forceLandscapeOrientation) => dispatch(changeForceLandscapeOrientation(forceLandscapeOrientation)),
    changeDisableClickVibrations: (disableClickVibrations) => dispatch(changeDisableClickVibrations(disableClickVibrations)),
    changeInvertedForceLandscapeOrientation: (forceLandscapeOrientation) =>
      dispatch(changeInvertedForceLandscapeOrientation(forceLandscapeOrientation)),
    changeStartingPlace: (startingPlace) => dispatch(changeStartingPlace(startingPlace)),
    changeBackToStartTimeout: (timeout) => dispatch(changeBackToStartTimeout(timeout)),
    changeShowAsList: (showAsList) => dispatch(changeShowAsList(showAsList)),
    changeHideLastChangeTimeOnList: (hideLastChangeTimeOnList) => dispatch(changeHideLastChangeTimeOnList(hideLastChangeTimeOnList)),
    changeDimensions: (value) => dispatch(changeDimensions(value)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Random_PersonalizationScreen);
