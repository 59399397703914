import React, { Component } from "react";
import { Text, View, TouchableOpacity } from "react-native";
import { connect } from "react-redux";

import { t } from "../../services/i18n";

import { PrimaryColorText, ButtonPrimary } from "../../components/UI/styledComponents";
import { devicesForGroups } from "../../utils";
// import PickerSelectObjects from "../../components/UI/PickerSelectObjects";
// import MultiplePickerSelectObjects from "./MultiplePickerSelectObjects";
import ModalWithContent from "../../components/UI/ModalWithContent";
import PickerSelect from "../../components/UI/PickerSelect";

class AddConditionModal extends Component {
  types = [
    { label: t("conditions:RAIN_SENSOR"), value: "RAIN" },
    { label: t("conditions:TEMP_SENSOR"), value: "TEMP" },
    { label: t("conditions:HUMIDITY_SENSOR"), value: "HUM" },
    { label: t("TIME"), value: "TIME" },
    { label: t("conditions:RAIN_SENSOR_TIME"), value: "RAIN_TIME" },
    { label: t("DAYS"), value: "DAYS" },
  ];
  state = {
    selectedType: "RAIN",
    selectedDevice: null,
    buttonAddDisabled: true,
  };

  onAddHandler = () => {
    this.props.onAdd(this.state.selectedType, this.state.selectedDevice);
  };

  setDevices = (devicesType) => {
    const { devices } = this.props;
    let devicesToSend = [];

    if (devicesType === "RAIN" || devicesType === "RAIN_TIME") {
      devices.map((device) => {
        if (device && ["satel_wej", "wej", "wej_d", "wej_g", "flaga", "motion", "presence"].includes(device.get("typ_komponentu"))) {
          devicesToSend.push({
            label: device.get("opis_menu"),
            value: device.get("id"),
          });
        }
      });
    } else if (devicesType == "HUM") {
      devices.map((device) => {
        if (device && ["bit8", "bit16", "bit32", "sbit16", "lin_wej", "sensor"].includes(device.get("typ_komponentu"))) {
          devicesToSend.push({
            label: device.get("opis_menu"),
            value: device.get("id"),
          });
        }
      });
    } else if (devicesType == "TEMP") {
      devices.map((device) => {
        if (device && device.get("typ_komponentu") == "temp") {
          devicesToSend.push({
            label: device.get("opis_menu"),
            value: device.get("id"),
          });
        }
      });
    }

    //dodatkowa grupa

    return devicesToSend;
  };

  deviceChange = (selectedDevice) => {
    this.setState({
      selectedDevice,
      buttonAddDisabled: selectedDevice && this.state.selectedType ? false : true,
    });
  };

  onSelectedTypeChange = (selectedType) => {
    let buttonAddDisabled = true;
    if (selectedType == "TIME" || selectedType == "DAYS" || (selectedType && this.state.selectedDevice)) {
      buttonAddDisabled = false;
    }

    this.setState({
      selectedType,
      selectedDevice: null,
      buttonAddDisabled,
    });
  };
  render() {
    const { isVisible, onClose } = this.props;
    const { selectedType, selectedDevice, buttonAddDisabled } = this.state;
    return (
      <ModalWithContent isVisible={isVisible} title={t("conditions:ADD_CONDITION")} onClose={onClose}>
        <View style={{ paddingBottom: 16 }}>
          <PrimaryColorText style={{ paddingBottom: 4 }}>{t("conditions:TYPE")}</PrimaryColorText>
          <PickerSelect
            placeholder={{
              label: t("conditions:CHOOSE_TYPE"),
              value: null,
            }}
            items={this.types}
            onValueChange={this.onSelectedTypeChange}
            value={selectedType}
          />
        </View>
        {selectedType !== "TIME" && selectedType !== "DAYS" ? (
          <View style={{ paddingBottom: 16 }}>
            <PrimaryColorText style={{ paddingBottom: 4 }}>{t("OBJECT_NAME")}</PrimaryColorText>
            <PickerSelect
              placeholder={{
                label: t("SELECT_OBJECT_NAME"),
                value: null,
              }}
              items={this.setDevices(selectedType)}
              onValueChange={this.deviceChange}
              value={selectedDevice}
            />
          </View>
        ) : null}

        <ButtonPrimary disabled={buttonAddDisabled} onPress={this.onAddHandler}>
          {t("ADD")}
        </ButtonPrimary>
      </ModalWithContent>
    );
  }
}
const mapStateToProps = (state) => {
  let currentProfile = state.profilesSettings.get("currentProfile");
  return {
    devices: state.smartHomeData.get(currentProfile).get("devices"),
  };
};

export default connect(mapStateToProps)(AddConditionModal);
