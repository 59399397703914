import * as Actions from "../actionTypes";
import { List, Map, fromJS } from "immutable";

const initialState = Map({
	dataApi: null,
	dataAmpio: null,
	loading: false,
	error: null,
});

const weatherDataReducer = (state = initialState, action) => {
	switch (action.type) {
		case Actions.FETCH_WEATHER_SUCCESS:
			return state
				.set("dataApi", action.payload)
				.set("loading", false)
				.set("error", null);

		case Actions.FETCH_WEATHER_FAILURE:
			return state.set("loading", false).set("error", action.payload);

		case Actions.FETCH_WEATHER_PENDING:
			return state.set("loading", true);

		case Actions.WEATHER_AMPIO_RECEIVED:
			return state.set("dataAmpio", action.payload);

		default:
			return state;
	}
};

export default weatherDataReducer;
