import React, { useContext } from "react";
import {
  Text,
  View,
  TouchableOpacity,
  ScrollView,
  Image,
  TextInput,
  Switch,
  ImageBackground,
  Platform,
  TouchableWithoutFeedback,
} from "react-native";
import { connect } from "react-redux";
import Slider from "@react-native-community/slider";
//THIS SHOUDL BE USED IN WEB
// import Slider from "@react-native-community/slider";

import { t } from "../../services/i18n";
import { useTheme } from "../../../ThemeContext";
import { Feather, MaterialCommunityIcons } from "@expo/vector-icons";
import { TextMarquee } from "./TextMarqiuee";
import { NoDataAnimation } from "../animatedComponents/Loader";

export const ScreenViewWithoutScroll = (props) => {
  const { theme } = useTheme();

  return (
    <View
      style={[
        {
          alignItems: "center",
          backgroundColor: theme.APP_BACKGROUND_COLOR,
          width: "100%",
          display: "flex",
          flex: 1,
          justifyContent: "center",
        },
        props.style,
      ]}
      onLayout={props.onLayout}
    >
      {props.children}
    </View>
  );
};

const mapStateToProps = (state) => {
  return {
    dimensions: state.statesData.get("dimensions"),
  };
};

export const ComponentContainerX = (props) => {
  const { theme } = useTheme();
  let numberOfColumns = props.numOfColumns ?? props.dimensions.get("numberOfColumns");
  const opacity = props.customBg ? 0.8 : 1;

  return (
    <View
      style={[
        {
          width: props.deviceFromSketch ? "50%" : `${100 / numberOfColumns}%`,
          paddingBottom: 5,
          paddingHorizontal: numberOfColumns == 1 ? 0 : 2.5,
          borderRadius: 10,
        },
        props.containerStyle,
      ]}
    >
      <View
        style={[
          {
            paddingVertical: props.deviceFromCustom ? 5 : 8,
            paddingHorizontal: 3,
            margin: 2,

            //backgroundColor: "rgba(255, 255, 255, 0.7)",
            opacity: opacity,
            backgroundColor: theme.COMPONENT_BACKGROUND_COLOR,
            borderRadius: 10,
          },
          props.style,
        ]}
        onLayout={props.onLayout}
      >
        {props.children}
      </View>
    </View>
  );
};

export const ComponentContainer = connect(mapStateToProps)(ComponentContainerX);

export const ComponentContainerTouchableX = (props) => {
  const { theme } = useTheme();
  let numberOfColumns = props.dimensions.get("numberOfColumns");

  if (props.numberOfColumns) numberOfColumns = props.numberOfColumns;

  return (
    <TouchableOpacity
      style={[
        {
          width: `${100 / numberOfColumns}%`,
          paddingBottom: 5,
          paddingHorizontal: numberOfColumns == 1 ? 0 : 2.5,
          borderRadius: 10,
        },
        props.containerStyle,
      ]}
      onPress={props.onPress}
      onLongPress={props.onLongPress}
    >
      <View
        style={[
          {
            padding: 10,
            backgroundColor: theme.COMPONENT_BACKGROUND_COLOR,
            flexDirection: "row",
            justifyContent: "space-between",
            borderRadius: 10,
          },
          props.style,
        ]}
        onLayout={props.onLayout}
      >
        {props.children}
      </View>
    </TouchableOpacity>
  );
};

export const ComponentContainerTouchable = connect(mapStateToProps)(ComponentContainerTouchableX);

export const ComponentHeaderTextX = (props) => {
  const { theme } = useTheme();
  let numberOfColumns = props.dimensions.get("numberOfColumns");
  if (props.numberOfColumns) numberOfColumns = props.numberOfColumns;

  return (
    <View style={[{ ...props.style }]}>
      <TextMarquee
        duration={3000}
        loop
        disabled={props.tickerDisabled}
        bounce={true}
        repeatSpacer={50}
        marqueeDelay={1000}
        animationType="bounce"
        scroll={false}
        isInteraction={false}
      >
        <Text
          style={[
            {
              width: `${100 / numberOfColumns}%`,
              color: theme.TEXT_COLOR_PRIMARY,
              fontWeight: "bold",
              fontSize: props.dimensions.get("smallTiles") ? 13 : 15,
              fontFamily: theme.FONT_BOLD,
            },
            props.style,
          ]}
          numberOfLines={props.numberOfLines}
        >
          {props.children}
        </Text>
      </TextMarquee>
    </View>
  );
};

export const ComponentHeaderText = connect(mapStateToProps)(ComponentHeaderTextX);

export const PrimaryColorText = (props) => {
  const { theme } = useTheme();

  return (
    <Text
      style={[
        {
          color: theme.TEXT_COLOR_PRIMARY,
          fontWeight: props.bold ? "bold" : "normal",
          fontFamily: props.bold ? theme.FONT_BOLD : theme.FONT,
        },

        props.style,
      ]}
      numberOfLines={props.numberOfLines}
      adjustsFontSizeToFit={Platform.OS === "ios" ? props.adjustsFontSizeToFit : undefined}
    >
      {props.children}
    </Text>
  );
};

export const SecondaryColorText = (props) => {
  const { theme } = useTheme();

  return (
    <Text
      style={[
        {
          color: theme.TEXT_COLOR_SECONDARY,
          fontFamily: props.bold ? theme.FONT_BOLD : theme.FONT,
        },
        props.style,
      ]}
      numberOfLines={props.numberOfLines}
      adjustsFontSizeToFit={Platform.OS === "ios" ? props.adjustsFontSizeToFit : undefined}
    >
      {props.children}
    </Text>
  );
};

export const ComponentImage = ({ source, style, isBig }) => {
  return <Image source={source} resizeMode={"contain"} style={[{ width: isBig ? 42 : 36, height: isBig ? 42 : 36 }, style]} />;
};

export const StyledIcon = (props) => {
  const { theme } = useTheme();

  let setColor = (sendColor) => {
    let color = "";
    switch (sendColor) {
      case "primary":
        color = "ICONS_COLOR_PRIMARY";
        break;
      case "secondary":
        color = "ICONS_COLOR_SECONDARY";
        break;
      case "tabBar":
        color = "TAB_BAR_TEXT_COLOR_HIGHLITED";
        break;

      default:
        color = "ICONS_COLOR_PRIMARY";
    }
    return color;
  };

  let chosenColor = setColor(props.color);
  if (props.library === "MaterialCommunityIcons") {
    return <MaterialCommunityIcons style={[props.style]} name={props.name} color={theme[chosenColor]} size={props.size} />;
  } else {
    return <Feather style={[props.style]} name={props.name} color={theme[chosenColor]} size={props.size} />;
  }
};

export const NoDataScreen = () => {
  return (
    <ScreenViewWithoutScroll>
      <NoDataAnimation style={{ width: 300, height: 300 }} />
      <PrimaryColorText>{t("NO_DATA")}</PrimaryColorText>
    </ScreenViewWithoutScroll>
  );
};

export const TitleWithLines = (props) => {
  const { theme } = useTheme();
  return (
    <View
      style={{
        display: "flex",
        flexDirection: "row",
        marginTop: 5,
        marginBottom: 5,
        width: "100%",
        justifyContent: "center",
      }}
    >
      <View
        style={{
          borderBottomColor: theme.TEXT_COLOR_PRIMARY,
          borderBottomWidth: 1,
          width: "20%",
          maxWidth: "20%",
          marginRight: 10,
          marginBottom: 7,
          flex: 1,
        }}
      />
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {props.fromHeating && <StyledIcon name={"sun"} size={20} style={{ marginRight: 5 }} />}
        <Text
          style={{
            color: theme.TEXT_COLOR_PRIMARY,
            justifyContent: "center",
            fontFamily: theme.FONT,
          }}
          numberOfLines={1}
        >
          {props.textInside}
        </Text>
      </View>

      <View
        style={{
          borderBottomColor: theme.TEXT_COLOR_PRIMARY,
          borderBottomWidth: 1,
          width: "20%",
          maxWidth: "20%",
          marginLeft: 10,
          marginBottom: 7,
          flex: 1,
        }}
      />
    </View>
  );
};

export const TextInputStyled = (props) => {
  const { theme } = useTheme();

  return (
    <TextInput
      style={[
        {
          borderWidth: 1,
          padding: 4,
          borderColor: theme.PLACEHOLDER_BACKGROUND_COLOR,
          height: 26,
          color: theme.TEXT_COLOR_PRIMARY,
          backgroundColor: theme.APP_BACKGROUND_COLOR,
        },
        props.style,
      ]}
      secureTextEntry={props.secureTextEntry}
      placeholderTextColor={theme.TEXT_COLOR_SECONDARY}
      value={props.value}
      placeholder={props.placeholder}
      onEndEditing={props.onEndEditing}
      onBlur={props.onEndEditing}
      onChangeText={props.onChangeText}
      keyboardType={props.keyboardType}
      editable={props.editable}
      autoFocus={props.autoFocus}
      autoCapitalize={props.autoCapitalize}
    />
  );
};

export const PowerStyled = (props) => {
  const { theme } = useTheme();
  return (
    <View
      style={{
        backgroundColor: props.value ? theme.BUTTON_BACKGROUND_COLOR_PRIMARY : theme.BUTTON_BACKGROUND_COLOR_PRIMARY_DISABLED,
        height: 45,
        width: 45,
        marginBottom: -5,
        borderRadius: 100,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <TouchableOpacity onPress={props.disabled ? undefined : props.onPress} disabled={props.disabled}>
        <MaterialCommunityIcons
          color={theme.BUTTON_BACKGROUND_COLOR_SECONDARY}
          size={35}
          name={"power"}
          style={{ padding: 5, justifyContent: "center" }}
        />
      </TouchableOpacity>
    </View>
  );
};

export const SwitchStyled = (props) => {
  const { theme } = useTheme();
  return (
    <TouchableWithoutFeedback
      onPress={(event) => {
        // Stop event propagation
        event.stopPropagation();
      }}
    >
      <Switch
        value={props.value}
        style={props.style}
        onValueChange={props.disabled ? null : props.onValueChange}
        disabled={props.disabled}
        trackColor={{ false: theme.SWITCH_OFF_BACKGROUND, true: "#4ED164" }}
        ios_backgroundColor={props.value ? "#4ED164" : theme.SWITCH_OFF_BACKGROUND}
      />
    </TouchableWithoutFeedback>
  );
};

function clamp(num, min, max) {
  return Math.min(Math.max(num, min), max);
}

export const SliderWW = (props) => {
  const { theme } = useTheme();
  return (
    <Slider
      minimumTrackTintColor={props.minimumTrackTintColor}
      maximumTrackTintColor={props.maximumTrackTintColor}
      thumbTintColor={theme.SLIDER_CIRCLE_BORDER}
      step={props.step ? props.step : 1}
      value={props.value}
      minimumValue={props.minimumValue}
      maximumValue={props.maximumValue}
      // onSlidingStart={this.onSlidingStartHandler}
      onValueChange={props.onValueChange}
      onSlidingComplete={props.onSlidingComplete}
      disabled={props.disabled}
      style={props.style}
    />
  );
};

export const SliderStyled = (props) => {
  const { theme } = useTheme();

  return (
    <TouchableWithoutFeedback
      onPress={(event) => {
        // Stop event propagation
        event.stopPropagation();
      }}
    >
      <Slider
        style={{
          flex: 1,
          marginHorizontal: 5,
          alignSelf: "center",
        }}
        minimumTrackTintColor={theme.SLIDER_MIN}
        maximumTrackTintColor={theme.SLIDER_MAX}
        thumbTintColor={theme.SLIDER_CIRCLE_BORDER}
        step={props.step ? props.step : 1}
        value={props.value}
        minimumValue={props.minimumValue}
        maximumValue={props.maximumValue}
        // onSlidingStart={this.onSlidingStartHandler}
        onValueChange={props.onValueChange}
        onSlidingComplete={props.onSlidingComplete}
        disabled={props.disabled}
      />
    </TouchableWithoutFeedback>
  );
};

export const ButtonPrimary = (props) => {
  const { theme } = useTheme();
  return (
    <TouchableOpacity
      style={[
        {
          borderRadius: 15,
          paddingVertical: 5,
          paddingHorizontal: 20,
          maxWidth: 500,
          minWidth: 120,
          alignSelf: "center",
          backgroundColor: !props.disabled ? theme.BUTTON_BACKGROUND_COLOR_PRIMARY : theme.BUTTON_BACKGROUND_COLOR_PRIMARY_DISABLED,
        },

        props.style,
      ]}
      onPress={props.onPress}
      onLongPress={props.onLongPress}
      disabled={props.disabled}
    >
      <Text
        style={{
          color: "#fff",
          fontSize: 12,
          textAlign: "center",
          fontFamily: theme.FONT,
        }}
      >
        {props.children.toUpperCase()}
      </Text>
    </TouchableOpacity>
  );
};

export const ButtonSecondary = (props) => (
  <TouchableOpacity
    style={[
      {
        borderRadius: 15,
        paddingVertical: 5,
        borderWidth: 1,
        borderColor: "#f4f4f4",
        maxWidth: 500,
      },
      props.style,
    ]}
    onPress={props.onPress}
    disabled={props.disabled}
  >
    <SecondaryColorText style={[{ fontSize: 12, textAlign: "center" }, props.textStyle]}>{props.children.toUpperCase()}</SecondaryColorText>
  </TouchableOpacity>
);

export const ButtonSecondary2 = (props) => {
  const { theme } = useTheme();

  return (
    <TouchableOpacity
      style={[
        {
          borderRadius: 15,
          paddingVertical: 5,
          borderWidth: 1,
          borderColor: "#f4f4f4",
          maxWidth: 500,
          backgroundColor: theme.APP_BACKGROUND_COLOR,
        },
        props.style,
      ]}
      onPress={props.onPress}
      disabled={props.disabled}
    >
      <Text style={{ color: theme.TEXT_COLOR_PRIMARY, fontSize: 12, fontFamily: theme.FONT, textAlign: "center" }}>
        {props.children.toUpperCase()}
      </Text>
    </TouchableOpacity>
  );
};
