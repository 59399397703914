import React, { Component, useEffect, useState } from "react";
import { View, Text, Image, ActivityIndicator, Platform, StyleSheet, VirtualizedList, RefreshControl, I18nManager } from "react-native";
import { connect } from "react-redux";
import { t } from "../../../services/i18n";
import { FontAwesome, Feather } from "@expo/vector-icons";
import { ThemeContext } from "../../../../ThemeContext";
import { messagesReceived, messagesCountReceived } from "../../../store/actions";
import { ComponentContainerTouchable, ButtonPrimary, PrimaryColorText, NoDataScreen } from "../../../components/UI/styledComponents";
import ModalSimple from "../../../components/UI/ModalSimple";

import mqttClient from "../../../services/mqtt";
import { SkottieLoader } from "../../../components/animatedComponents/Loader";
let numberOfItems = 10;

const FullWidthImage = ({ uri, screenWidth }) => {
  const [height, setHeight] = useState(0); // Initial height set to 0

  useEffect(() => {
    if (uri) {
      Image.getSize(
        uri,
        (width, height) => {
          const aspectRatio = width / height;
          const calculatedHeight = screenWidth / aspectRatio;
          setHeight(calculatedHeight); // Set the height to maintain aspect ratio
        },
        (error) => {
          console.log(`Unable to retrieve image size: ${error.message}`);
        }
      );
    }
  }, [uri]);

  return (
    <View style={styles.container}>
      <Image source={{ uri }} style={{ width: screenWidth, height: height, resizeMode: "contain" }} />
    </View>
  );
};

class Message_Center_AccountScreen extends Component {
  static contextType = ThemeContext;

  state = {
    isLoading: true,
    refreshing: false,
    fetchingMore: false,
    messages: null,
    readAllDialogVisible: false,
    deleteAllDialogVisible: false,
    offset: 0,
  };

  onDetailsPressed = (message) => {
    if (message.get("state") !== 1) {
      let indexToUpdate = this.props.messages.findIndex((x) => x.get("id") === message.get("id"));

      let newInfos = this.props.messages;
      newInfos = newInfos.setIn([indexToUpdate, "state"], 1);

      this.props.messagesReceived(newInfos);

      mqttClient.readMessage(message.get("id"));

      if (this.state.messages) {
        let indexToUpdate = this.state.messages.findIndex((x) => x.get("id") === message.get("id"));
        let newInfos = this.state.messages;
        newInfos = newInfos.setIn([indexToUpdate, "state"], 1);
        this.setState({ messages: newInfos });
      }
    }
    this.props.navigation.navigate("Message_Details", {
      title: message.get("title"),
      message: message,
      aspectRatio: this.props.params_devices.get(message.get("title")) ? this.props.params_devices.get(message.get("title")).get("param1") : 1,
    });
  };

  onMarkAllAsRead = () => {
    this.setReadAllDialogVisible(false);
    let messageCopy = this.state.messages;
    if (this.state.messages) {
      this.state.messages.map((el, key) => {
        messageCopy = messageCopy.setIn([key, "state"], 1);
      });

      this.setState({ messages: messageCopy });
      mqttClient.readAllMessages();
    }
  };

  onMarkAllDeleted = () => {
    this.setDeleteAllDialogVisible(false);
    mqttClient.deleteAllMessages();
    mqttClient.getMessagesCount();
    this.setState({
      messages: null,
    });
  };

  componentDidMount() {
    this.askForMessages();
  }

  prepareMessages = async (newMessages) => {
    let messages = newMessages;
    if (this.state.fetchingMore && this.state.messages) {
      messages = this.state.messages.concat(newMessages);
    }

    this.setState({
      isLoading: false,
      refreshing: false,
      fetchingMore: false,
      messages,
    });
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.messages) {
      this.prepareMessages(nextProps.messages);
    }
  }

  setReadAllDialogVisible = (state) => {
    this.setState({
      readAllDialogVisible: state,
    });
  };

  setDeleteAllDialogVisible = (state) => {
    this.setState({
      deleteAllDialogVisible: state,
    });
  };

  askForMessages = () => {
    let { offset } = this.state;
    mqttClient.getMessagesPaginate(numberOfItems, offset);
  };
  onRefresh = () => {
    this.setState(
      {
        refreshing: true,
        offset: 0,
      },
      () => {
        this.askForMessages();
      }
    );
  };

  onEndReached = () => {
    const { messages, offset, fetchingMore } = this.state;
    if (!fetchingMore && !(messages.size <= offset) && !(messages.size < numberOfItems)) {
      this.setState(
        (prevstate) => ({
          offset: prevstate.offset + numberOfItems,
          fetchingMore: true,
        }),
        () => {
          this.askForMessages();
        }
      );
    }
  };

  render() {
    const { theme } = this.context;
    const { dimensions } = this.props;
    const { refreshing, fetchingMore, messages, readAllDialogVisible, deleteAllDialogVisible } = this.state;

    return (
      <View
        style={{
          flex: 1,
          backgroundColor: theme.APP_BACKGROUND_COLOR,
        }}
      >
        <View
          style={{
            width: "100%",
            justifyContent: "center",
            marginTop: 5,
            marginBottom: 15,
            flexDirection: "row",
          }}
        >
          <ButtonPrimary style={{ marginRight: 5 }} onPress={() => this.setReadAllDialogVisible(true)}>
            {t("account:MARK_ALL_AS_READ")}
          </ButtonPrimary>
          <ButtonPrimary style={{ marginLeft: 5 }} onPress={() => this.setDeleteAllDialogVisible(true)}>
            {t("account:DELETE_ALL")}
          </ButtonPrimary>
        </View>
        {this.state.isLoading ? (
          <View
            style={{
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <SkottieLoader style={{ width: 40, height: 40, margin: 5 }} />
          </View>
        ) : (
          messages && (
            <VirtualizedList
              data={messages}
              refreshControl={
                <RefreshControl
                  //refresh control used for the Pull to Refresh
                  refreshing={refreshing}
                  onRefresh={this.onRefresh}
                />
              }
              contentContainerStyle={{ flexGrow: 1 }}
              ListEmptyComponent={<NoDataScreen />}
              getItem={(data, index) => data.get(index)}
              getItemCount={(data) => data.size}
              onEndReached={this.onEndReached}
              onEndReachedThreshold={0.5}
              renderItem={({ item, index }) => {
                return item ? (
                  <ComponentContainerTouchable
                    key={item.get("id")}
                    containerStyle={{ width: "100%" }}
                    style={{ alignItems: "center" }}
                    onPress={() => this.onDetailsPressed(item)}
                  >
                    {item.get("state") === 0 && (
                      <View style={{ width: 20 }}>
                        <FontAwesome name={"circle"} color={"#4ED164"} size={14} />
                      </View>
                    )}
                    {item.get("state") === 2 && (
                      <View style={{ width: 20 }}>
                        <FontAwesome name={"exclamation"} color={"#4ED164"} size={14} />
                      </View>
                    )}

                    <View style={{ flex: 1, paddingRight: 20 }}>
                      <View
                        style={{
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <PrimaryColorText
                          style={{
                            fontWeight: item.get("state") !== 1 ? "bold" : null,
                          }}
                        >
                          {item.get("type") == 1 && this.props.devices && item && this.props.devices.get(item.get("title"))
                            ? this.props.devices.get(item.get("title")).get("opis_menu")
                            : item
                            ? item.get("title")
                            : null}
                        </PrimaryColorText>
                        <PrimaryColorText>{item.get("date_of_change")}</PrimaryColorText>
                      </View>

                      <View>
                        {item.get("type") === 0 ? (
                          <PrimaryColorText
                            numberOfLines={1}
                            style={{
                              fontWeight: item.get("state") !== 1 ? "bold" : null,
                            }}
                          >
                            {item.get("body")}
                          </PrimaryColorText>
                        ) : null}
                        {item.get("type") === 1 && item.get("image") ? (
                          <FullWidthImage
                            uri={`data:image/jpg;base64,${item.get("image")}`}
                            screenWidth={item.get("state") !== 1 ? dimensions.get("width") - 40 : dimensions.get("width") - 20}
                          />
                        ) : null}
                      </View>
                    </View>
                    {item.get("type") === 0 && <Feather name={I18nManager.isRTL ? "chevron-left" : "chevron-right"} color={"#999"} size={24} />}
                  </ComponentContainerTouchable>
                ) : null;
              }}
              keyExtractor={(item, index) => {
                return item ? `${item.get("id")} - ${index}` : `${index}`;
              }}
            />
          )
        )}

        {fetchingMore ? (
          <View style={{ padding: 10 }}>
            <SkottieLoader style={{ width: 23, height: 23, margin: 3 }} />
          </View>
        ) : null}

        {readAllDialogVisible && (
          <ModalSimple
            isVisible={readAllDialogVisible}
            title={"account:MARK_ALL_AS_READ"}
            description={"account:READ_MESSAGES_SURE"}
            onOK={this.onMarkAllAsRead}
            onClose={() => this.setReadAllDialogVisible(false)}
          />
        )}
        {deleteAllDialogVisible && (
          <ModalSimple
            isVisible={deleteAllDialogVisible}
            title={"account:DELETE_ALL"}
            description={"account:DELETE_ALL_SURE"}
            onOK={this.onMarkAllDeleted}
            onClose={() => this.setDeleteAllDialogVisible(false)}
          />
        )}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  singleLine: {
    paddingVertical: 5,
    paddingHorizontal: 10,
    width: "100%",
  },
});

const mapStateToProps = (state) => {
  return {
    messages: state.statesData.get("messages"),
    devices: state.smartHomeData.get(state.profilesSettings.get("currentProfile")).get("devices"),
    params_devices: state.smartHomeData.get(state.profilesSettings.get("currentProfile")).get("params_devices"),
    dimensions: state.statesData.get("dimensions"),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    messagesReceived: (messages) => dispatch(messagesReceived(messages)),
    messagesCountReceived: (messages) => dispatch(messagesCountReceived(messages)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Message_Center_AccountScreen);
