import React, { useContext } from "react";
import { Text, View, TouchableOpacity, KeyboardAvoidingView, TextInput, Platform } from "react-native";
import Modal from "react-native-modal";
import WebModal from "modal-enhanced-react-native-web";
import { t } from "../../services/i18n";
import { useTheme } from "../../../ThemeContext";
import CloudIntegration from "../../screens/Initial/CloudIntegration";

import { ButtonPrimary, TextInputStyled } from "./styledComponents";
import { MaterialCommunityIcons } from "@expo/vector-icons";

const ModalSimple = (props) => {
  const { theme } = useTheme();

  const {
    isVisible,
    title,
    description,
    placeholder,
    onInputChange,
    keyboardType,
    onClose,
    onCloseText,
    onOK,
    onOKText,
    onThirdOption,
    thirdOptionText,
    noTranslate,
    onOKdisabled,
    value,
    secureTextEntry,
    isWithCloudButton,
    device_id,
    showBiometrics,
    error,
  } = props;

  const content = error ? (
    <View
      style={{
        backgroundColor: theme.COMPONENT_BACKGROUND_COLOR,
        justifyContent: "space-between",
        borderRadius: 10,
        width: "90%",
        maxWidth: 600,
      }}
    >
      <Text
        style={{
          fontSize: 18,
          fontWeight: "bold",
          paddingTop: 15,
          color: theme.TEXT_COLOR_PRIMARY,
          textAlign: "center",
        }}
      >
        {error}
      </Text>
      <ButtonPrimary
        style={{
          margin: 15,
        }}
        onPress={onClose}
      >
        {t("CLOSE")}
      </ButtonPrimary>
    </View>
  ) : (
    <View
      style={{
        backgroundColor: theme.COMPONENT_BACKGROUND_COLOR,
        justifyContent: "space-between",
        borderRadius: 10,
        width: "90%",
        maxWidth: 600,
      }}
    >
      <View
        style={{
          justifyContent: "center",
          alignItems: "center",
          paddingHorizontal: 8,
        }}
      >
        {title && (
          <Text
            style={{
              fontSize: 18,
              fontWeight: "bold",
              paddingTop: 15,
              color: theme.TEXT_COLOR_PRIMARY,
              textAlign: "center",
            }}
          >
            {t(title)}
          </Text>
        )}
        {description && (
          <Text
            style={{
              color: theme.TEXT_COLOR_PRIMARY,
              paddingTop: 15,
              textAlign: "center",
            }}
          >
            {noTranslate ? description : t(description)}
          </Text>
        )}
        {onInputChange && (
          <View style={{ flexDirection: "row", width: "90%", marginTop: 15 }}>
            <TextInputStyled
              secureTextEntry={secureTextEntry}
              placeholder={t(placeholder)}
              onChangeText={onInputChange}
              keyboardType={keyboardType}
              autoFocus={Platform.OS === "ios" ? true : false}
              style={{ width: "80%", color: theme.TEXT_COLOR_PRIMARY, marginTop: 5 }}
              value={value}
            />
            {showBiometrics && (
              <TouchableOpacity onPress={onThirdOption} style={{ marginLeft: 5 }}>
                <MaterialCommunityIcons name="face-recognition" size={32} />
              </TouchableOpacity>
            )}
          </View>
        )}
      </View>

      <View
        style={{
          flexDirection: "row",
          borderTopColor: "#ddd",
          borderTopWidth: 1,
          width: "100%",
          borderLeftWidth: 0,
          borderLeftColor: "none",
          marginTop: 20,
        }}
      >
        {onClose && (
          <TouchableOpacity
            style={{
              flex: 1,
              alignItems: "center",
              borderRightColor: "#ddd",
              borderRightWidth: 0.3,
            }}
            onPress={onClose}
          >
            <Text
              style={{
                padding: 10,
                fontSize: 16,
                color: theme.TEXT_COLOR_PRIMARY,
              }}
            >
              {onCloseText ? onCloseText : t("CLOSE")}
            </Text>
          </TouchableOpacity>
        )}
        {isWithCloudButton && <CloudIntegration device_id={device_id} source="dashboard" onOK={onOK} />}
        {!isWithCloudButton && onOK && (
          <TouchableOpacity
            style={{
              flex: 1,
              alignItems: "center",
            }}
            onPress={onOK}
            disabled={onOKdisabled}
          >
            <Text
              style={{
                padding: 10,
                fontSize: 16,
                color: theme.TEXT_COLOR_PRIMARY,
              }}
            >
              {onOKText ? onOKText : t("OK")}
            </Text>
          </TouchableOpacity>
        )}
      </View>

      {onThirdOption && !showBiometrics && (
        <View
          style={{
            flexDirection: "row",
            borderTopColor: "#ddd",
            borderTopWidth: 1,
            width: "100%",
            borderLeftWidth: 0,
            borderLeftColor: "none",
          }}
        >
          <TouchableOpacity
            style={{
              flex: 1,
              alignItems: "center",
            }}
            onPress={onThirdOption}
          >
            <Text
              style={{
                padding: 10,
                fontSize: 16,
                color: theme.TEXT_COLOR_PRIMARY,
              }}
            >
              {thirdOptionText ? thirdOptionText : t("DONT_ASK_AGAIN")}
            </Text>
          </TouchableOpacity>
        </View>
      )}
    </View>
  );

  return isVisible ? (
    Platform.OS == "web" ? (
      <WebModal animationType="slide" transparent={true} visible={isVisible} style={{ margin: 0 }}>
        <View
          style={{
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {content}
        </View>
      </WebModal>
    ) : (
      <Modal isVisible={isVisible} style={{ alignItems: "center" }} useNativeDriver={true}>
        <KeyboardAvoidingView
          behavior={Platform.OS === "ios" ? "padding" : "height"}
          style={{
            width: "100%",
            alignItems: "center",
            marginBottom: 80,
          }}
          enabled
        >
          {content}
        </KeyboardAvoidingView>
      </Modal>
    )
  ) : null;
};

export default ModalSimple;
