import React from "react";
import { TouchableOpacity } from "react-native";
import { useNavigation } from "@react-navigation/native";

const GoToModal = (props) => {
  const navigation = useNavigation();
  return (
    <TouchableOpacity
      onPress={() => {
        navigation.navigate(`${props.screenName}`, props.params);
      }}
      style={{
        ...props.style,
        flexDirection: "row",
        alignItems: "center",
        // justifyContent: "flex-end",
      }}
    >
      {props.children}
    </TouchableOpacity>
  );
};

export default GoToModal;
