import { Map, List } from "immutable";

const devicesForGroups = (data, groupId, order) => {
	let filteredArray = [];
	if (data) {
		filteredArray = data.filter((device) => {
			if (device) {
				return device.get("id_grupy") == groupId;
			}
		});

		let devicesOrder =
			order &&
			order.get(`${groupId}`) &&
			order.get(`${groupId}`).get("devices");

		if (devicesOrder) {
			filteredArray = filteredArray.map((device) => {
				let inOrderListIndex = devicesOrder.findIndex((el) => {
					if (typeof el == "string") {
						//stare
						return el == device.get("id_obiektu");
					} else {
						//nowe
						return el.get("id") == device.get("id_obiektu");
					}
				});
				device = device.set("orderNumber", inOrderListIndex);
				let customWidth = 0;

				if (List.isList(devicesOrder)) {
					if (
						devicesOrder.get(`${inOrderListIndex}`) &&
						Map.isMap(devicesOrder.get(`${inOrderListIndex}`)) &&
						devicesOrder.get(`${inOrderListIndex}`).get("customWidth")
					) {
						customWidth = devicesOrder
							.get(`${inOrderListIndex}`)
							.get("customWidth");
					}
				}

				device = device.set("customWidth", customWidth);

				return device;
			});
			filteredArray = filteredArray.sort((x, y) => {
				return x.get("orderNumber") - y.get("orderNumber");
			});
		}
	}

	return filteredArray;
};

export default devicesForGroups;
