import React, { Component } from "react";
import { View } from "react-native";
import humanizeDuration from "humanize-duration";
import { connect } from "react-redux";

import * as Localization from "expo-localization";

import mqttClient from "../services/mqtt";
import { t } from "../services/i18n";

import { ButtonPrimary, TextInputStyled, PrimaryColorText } from "../components/UI/styledComponents";
import ModalWithContent from "../components/UI/ModalWithContent";

let lang = Localization.locale.split("-")[0];
class DeviceChangePinDialog extends Component {
  state = {
    passwordOld: "",
    passwordNew: "",
    passwordConfirm: "",
    errorMessage: "",
  };
  onInputEndEditing = () => {
    //mqttClient.updateParam(, param1);
  };

  componentDidMount() {}

  handleClose = () => {
    this.props.handleCloseDialog();
  };

  handleConfirm = () => {
    let errorMess = "";
    const { device, params_device } = this.props;
    if (this.state.passwordOld.length === 0) {
      errorMess = t("ENTER_CURRENT_PASSWORD");
    } else if (this.state.passwordNew.length === 0) {
      errorMess = t("ENTER_NEW_PASSWORD");
    } else if (this.state.passwordConfirm.length === 0) {
      errorMess = t("CONFIRM_PASSWORD");
    } else if (this.state.passwordNew !== this.state.passwordConfirm) {
      errorMess = t("CONFIRM_PASSWORD_ERROR");
    } else if (device.get("haslo") != this.state.passwordOld && device.get("typ_komponentu") != "satel_alarm") {
      errorMess = t("CURRENT_PASSWORD_ERROR");
    }
    this.setState({ errorMessage: errorMess });

    if (errorMess.length === 0) {
      let option1 = params_device ? params_device.get("params") & 0x8000 : 0;

      if (device.get("typ_komponentu") == "satel_alarm" && option1) {
        let message = `/api/set/${device.get("id")}/changePin/${this.state.passwordOld}/${this.state.passwordNew}`;
        mqttClient.stateChangeToSend(message, device.get("id"));
      } else {
        mqttClient.updatePin(device.get("id"), this.state.passwordOld, this.state.passwordNew);
      }
      this.props.handleCloseDialog();
    }
  };

  onInputEditingOld = (value) => {
    this.setState({ passwordOld: value });
  };
  onInputEditingNew = (value) => {
    this.setState({ passwordNew: value });
  };
  onInputEditingConfirm = (value) => {
    this.setState({ passwordConfirm: value });
  };

  render() {
    const { dialogVisible } = this.props;

    let content = (
      <View style={{ paddingBottom: 10, alignItems: "center" }}>
        <TextInputStyled
          style={{ height: 26, width: 200, marginTop: 10 }}
          placeholder={t("ENTER_CURRENT_PASSWORD")}
          secureTextEntry={true}
          onChangeText={this.onInputEditingOld}
          keyboardType={"numeric"}
          autoFocus={true}
        />
        <TextInputStyled
          style={{ height: 26, width: 200, marginTop: 10 }}
          placeholder={t("ENTER_NEW_PASSWORD")}
          secureTextEntry={true}
          onChangeText={this.onInputEditingNew}
          keyboardType={"numeric"}
        />
        <TextInputStyled
          style={{ height: 30, width: 200, marginTop: 10 }}
          placeholder={t("CONFIRM_PASSWORD")}
          secureTextEntry={true}
          onChangeText={this.onInputEditingConfirm}
          keyboardType={"numeric"}
        />
        <PrimaryColorText>{this.state.errorMessage}</PrimaryColorText>
        <ButtonPrimary style={{ marginTop: 10 }} onPress={this.handleConfirm}>
          {t("SAVE")}
        </ButtonPrimary>
      </View>
    );

    return (
      <ModalWithContent isVisible={dialogVisible} title={t("INFO")} onClose={this.handleClose}>
        {content}
      </ModalWithContent>
    );
  }
}

export default DeviceChangePinDialog;
