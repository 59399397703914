import React, { Component, useEffect, useState } from "react";
import { Text, View, FlatList, Button, Image, TouchableOpacity, StyleSheet, ScrollView, Keyboard, Platform } from "react-native";
import moment from "moment";
import { createSelector } from "reselect";
import Slider from "@react-native-community/slider";
import { changeSelectedSoruces } from "../../store/actions";
import { t } from "../../services/i18n";
import { Feather, FontAwesome } from "@expo/vector-icons";
import { connect } from "react-redux";
import { SliderStyled } from "../../components/UI/styledComponents";
import mqttClient from "../../services/mqtt";
import SourceChooseScreen from "./SourceChooseScreen";
import { StyledIcon, TitleWithLines, PrimaryColorText } from "../../components/UI/styledComponents";
import { ScreenView } from "../../components/UI/screenViewX";

import BarMusicPlayer from "../Account/Audio/BarMusicPlayer";

const CurrentMusicScreen = ({ navigation, changeSelectedSoruces, selectedSoruces }) => {
  const _closeScreen = (clean) => {
    if (clean) {
      changeSelectedSoruces({});
    }
    Keyboard.dismiss();
    navigation.goBack(null);
  };

  const [selected, setSelected] = useState({});

  useEffect(() => {
    setSelected(selectedSoruces);
    navigation.setOptions({
      headerRight: () => (
        <TouchableOpacity style={{ padding: 10 }} onPress={() => _closeScreen()}>
          <StyledIcon name={"x"} color={"tabBar"} size={24} />
        </TouchableOpacity>
      ),
    });
  }, []);

  return (
    <ScreenView>
      <BarMusicPlayer type={"current"} selectedSoruces={selected} />
      <SourceChooseScreen navigation={navigation} embedded={true} selected={selected} setSelected={setSelected}></SourceChooseScreen>
    </ScreenView>
  );
};

const selectDevices = (state) => state;

const devices = createSelector(selectDevices, (smartHomeData) => {
  const filteredItems = smartHomeData.filter((device) => {
    if (device) {
      return device.get("typ_komponentu") === "ip_radio";
    } else {
      return false;
    }
  });
  return filteredItems;
});

const mapStateToProps = (state) => {
  let currentProfile = state.profilesSettings.get("currentProfile");

  let possibleDevices = devices(state.smartHomeData.get(currentProfile).get("devices"));

  return {
    selectedSoruces: state.statesData.get("selectedSoruces"),
    devicesArray: possibleDevices,
    playerInfo: state.statesData.get("playerInfo"),
    queueInfo: state.statesData.get("queueInfo"),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeSelectedSoruces: (status) => dispatch(changeSelectedSoruces(status)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CurrentMusicScreen);
