import React, { Component } from "react";
import { useNavigation } from "@react-navigation/native";
import { connect } from "react-redux";
import AdjustableTextToFitSketch from "./AdjustableTextToFitSketch";

import { View, Text, TouchableOpacity, Image, Keyboard } from "react-native";
import * as LocalAuthentication from "expo-local-authentication";
import ModalSimple from "../components/UI/ModalSimple";
const GroupComponent = ({ group, showSketchFirst, viewWidth, styleToPass, showGroupDesc, stretchSketch, icon }) => {
  const navigation = useNavigation();

  const [pinDialogVisible, setPinDialogVisible] = React.useState(false);
  const [wrongPinDialogVisible, setWrongPinDialogVisible] = React.useState(false);
  const [pin, setPin] = React.useState("");

  const onPinInputHandler = (text) => {
    setPin(text);
  };

  const handleClose = () => {
    setPinDialogVisible(false);
  };
  const handleOK = () => {
    if (String(pin) == String(group.get("uwagi"))) {
      setPinDialogVisible(false);
      navigation.push("Devices_Places", {
        group,
        fromGroupComponent: true,
      });
    } else {
      setPinDialogVisible(false);
      setWrongPinDialogVisible(true);
    }
  };

  const handleBiometrics = async () => {
    Keyboard.dismiss();
    setPinDialogVisible(false);
    const result = await LocalAuthentication.authenticateAsync({ requireConfirmation: false });
    if (result && result.success) {
      navigation.push("Devices_Places", {
        group,
        fromGroupComponent: true,
      });
    }
  };

  const handleOKwrongPin = () => {
    setWrongPinDialogVisible(false);
  };

  return (
    <TouchableOpacity
      onPress={() => {
        if ((group.get("params") & 0x040) > 0 || (group.get("params") & 0x020) > 0) {
          setPin("");
          setPinDialogVisible(true);
        } else {
          navigation.push("Devices_Places", {
            group,
            fromGroupComponent: true,
          });
        }
      }}
      key={group.get("id")}
      style={[styleToPass]}
    >
      {icon && icon.get("wartoscB") ? (
        <Image
          source={{ uri: `data:image/png;base64,${icon.get("wartoscB")}` }}
          resizeMode={stretchSketch ? "stretch" : "contain"}
          style={{ width: "100%", height: "100%" }}
        />
      ) : null}
      {showGroupDesc ? (
        <AdjustableTextToFitSketch
          styleToPass={styleToPass}
          addStyle={{
            position: "absolute",
            left: 0,
            top: styleToPass.height / 2,
          }}
        >
          {group.get("opis_menu")}
        </AdjustableTextToFitSketch>
      ) : null}
      {pinDialogVisible && (
        <ModalSimple
          isVisible={pinDialogVisible}
          title={"AUTH"}
          description={"PASS_PIN"}
          placeholder={"PASS_PIN"}
          secureTextEntry={true}
          onInputChange={onPinInputHandler}
          keyboardType={"numeric"}
          onClose={handleClose}
          onOK={handleOK}
          showBiometrics={(group.get("params") & 0x040) > 0}
          onThirdOption={handleBiometrics}
        />
      )}
      {wrongPinDialogVisible && <ModalSimple isVisible={wrongPinDialogVisible} title={"AUTH"} description={"PIN_NOT"} onOK={handleOKwrongPin} />}
    </TouchableOpacity>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    group: state.smartHomeData
      .get(state.profilesSettings.get("currentProfile"))
      .get("groups")
      .get(ownProps.groupId + 1),
    icon: state.smartHomeData.get(state.profilesSettings.get("currentProfile")).get("icons").get(ownProps.iconId),
    showSketchFirst: state.globalSettings.get("showSketchFirst"),
  };
};

export default connect(mapStateToProps)(GroupComponent);
