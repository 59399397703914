import React, { useState } from "react";
import { connect } from "react-redux";
import { t } from "../../../services/i18n";
import { ScreenView } from "../../../components/UI/screenViewX";
import {
  SwitchStyled,
  NoDataScreen,
  ComponentContainer,
  PrimaryColorText,
} from "../../../components/UI/styledComponents";
import { DevicesToShowByColumns } from "../../../utils";

let typeToShow = [
  "flaga",
  "flaga_l",
  "flaga_p",
  "led",
  "ledww",
  "flaga_liniowa",
  "flaga_liniowa16",
  "przekaznik",
  "reg",
  "radio",
  "ip_radio",
  "rgb",
  "rgbw",
  "roleta",
  "roleta_procenty",
  "roleta_lamelki",
  "event",
  "ircode",
  "ir",
];

const Devices_SceneDetailsScreen = ({
  sceneInfos,
  devices,
  removeDeviceFromScene,
  changeSceneFromDevice,
}) => {
  const [activeSceneControl, setActiveSceneControl] = useState(true);

  let listOfDevices = [];

  sceneInfos &&
    sceneInfos.map((deviceInfo, index) => {
      let deviceID = deviceInfo.get("id");
      if (devices.get(deviceID)) {
        let typKomponentu = devices.get(deviceID).get("typ_komponentu");
        if (typeToShow.includes(typKomponentu)) {
          listOfDevices.push(deviceInfo);
        }
      }
    });

  let listToRender = (
    <DevicesToShowByColumns
      sceneListOfDevices={listOfDevices}
      devices={devices}
      activeSceneControl={activeSceneControl}
      changeSceneFromDevice={changeSceneFromDevice}
      removeDeviceFromScene={removeDeviceFromScene}
    />
  );

  return (
    <ScreenView>
      <ComponentContainer
        containerStyle={{ width: "100%" }}
        style={{ flexDirection: "row", alignItems: "center" }}
      >
        <PrimaryColorText style={{ flex: 1 }}>
          {t("scenes:CONTROL_STATE")}
        </PrimaryColorText>

        <SwitchStyled
          value={activeSceneControl}
          onValueChange={(value) => setActiveSceneControl(value)}
        />
      </ComponentContainer>

      {listToRender}
    </ScreenView>
  );
};

const mapStateToProps = (state) => {
  return {
    group_devices: state.smartHomeData
      .get(state.profilesSettings.get("currentProfile"))
      .get("group_devices"),
    devices: state.smartHomeData
      .get(state.profilesSettings.get("currentProfile"))
      .get("devices"),
    groups: state.smartHomeData
      .get(state.profilesSettings.get("currentProfile"))
      .get("groups"),
    params_devices: state.smartHomeData
      .get(state.profilesSettings.get("currentProfile"))
      .get("params_devices"),
  };
};

export default connect(mapStateToProps)(Devices_SceneDetailsScreen);
